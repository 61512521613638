<div class="issue-Resolution-Section">
  <div class="card flex justify-content-center border-0">
    <div class="margin mb-0">
      <!-- <app-original-rating-config></app-original-rating-config> -->
      <form [formGroup]="issueResolutionForm">
        <div class="actionPlan">
          <strong>{{ "ComplaintForm_SectionActionPlayDisplayName" | translate }}</strong>
          <span>
            <p>
              <span *ngIf="actionItemsData?.length == 0">{{ "Complaint_ActionPlan_NoItems" | translate }}</span>
              <span class="closeBtn actionBoardAlignRight"><a
                  routerLink="/issues/{{complaintData?.header?.complaintNumber}}/{{this.selectedRoleName[0]?.name}}/actionplan" class="actionBoardColor"
                  target="_blank"><i class="fa fa-link"></i> {{ "Complaint_ActionPlan_ActionBoard" | translate
                  }}</a></span>
            </p>
          </span>
          <input type="hidden" formControlName="idsOfCheckedActionItems"/>
          <div class="action-board-data" *ngIf="actionItemsData?.length > 0">
            <div class="action-plan-data" style="margin-top:50px !important;">
              <div class="table-responsive">
              <div style="margin-bottom: 10px;"><span>{{ "Complaint_ActionPlan_Checkbox_Instructions" | translate}}</span></div>
              <table class="table table-hover table-bordered table-striped text-left action-table">
                <thead>
                  <tr>
                    <th width="5%">Select</th>
                    <th width="15%">Action Type</th>
                    <th>Description</th>
                    <th width="15%">Who</th>
                    <th width="10%">When</th>
                    <th width="12%">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor='let actionData of $any(actionItemsData | slice:0:counterData)' >
                    <td>
                      <input type="checkbox" value="{{actionData.isChecked}}" [checked]="actionData.isChecked"
                      (change)="isActionItemCheckBoxSelected(actionData)"
                      [disabled]="!actionData.isChecked && CheckedCount >=6"
                      >
                    </td>
                    <td>
                      {{actionData.actionTypeName  | uppercase}}
                    </td>
                    <td class="td-action-desc">
                      <a href="/issues/{{this.complaintData?.header?.complaintNumber}}/{{this.selectedRoleName[0]?.name}}/actionplan/card/{{actionData?.id}}"
                        target="_blank" style="text-decoration: none; color: rgb(9, 63, 180);">
                      {{ actionData.actionName | shortenComplaintSubject : 100 }}</a></td>
                    <td><span [pTooltip]="actionData?.actionOwnerEmail" [escape]="false" placement="top"
                      tooltipStyleClass="toolTipClass">{{ actionData?.actionOwnerName }}</span></td>
                    <td>{{actionData?.formattedTargetDate}}</td>
                    <td>{{actionData?.status | uppercase}}</td>
                  </tr>
                </tbody>
              </table>
              <span class="more-actions-link" (click)="loadMore()" *ngIf="counterData < actionItemsData?.length ">Load more</span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="row mb-2 mt-4">
            <div class="col-md-2 col-sm-3 ps-0 pe-0 text-right rca-label">
              <!-- need to change resource value in database {{ "Complaint_EstimatedCost" | translate }}-->
              <label>Estimated Cost (K USD)<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['EstimatedCostView']?.validator"></span> </label>
            </div>
            <div class="col-md-4 col-sm-5 clear-right-padding remove-px">
              <input type="text" class="form-control raisedInput estimatedCostInput" formControlName="EstimatedCostView"
                (keydown)="checkNumeric($event);" inputWithComma [pTooltip]="estimatedCost" [escape]="false" placement="top"
                     tooltipStyleClass="toolTipClass" [ngClass]="isSubmitted && this.issueResolutionForm.controls['EstimatedCostView']?.invalid ? 'error-inputbox' : ''" />
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0 text-right rca-label">
              <label>{{ "Complaint_ImpactOnInstalledBase" | translate }}<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['ImpactOnInstalledBase']?.validator"></span> </label>
            </div>
            <div class="col-md-8 col-sm-9 remove-px rca-dropdownlist">
              <p-dropdown [options]="impactOnInstalledBaseDropDown" formControlName="ImpactOnInstalledBase"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['ImpactOnInstalledBase'].invalid ? 'error-dropdownInput' : ''"
                optionLabel="number" optionValue="value"></p-dropdown>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0 text-right rca-label">
              <label>{{ "Complaint_TotalInstalledBase" | translate }}<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['TotalInstalledBase']?.validator"></span> </label>
            </div>
            <div class="col-md-8 col-sm-9 remove-px rca-dropdownlist">
              <p-dropdown [options]="totalInstalledBaseDropDown" formControlName="TotalInstalledBase"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['TotalInstalledBase'].invalid ? 'error-dropdownInput' : ''"
                optionLabel="number" optionValue="value"></p-dropdown>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0 text-right rca-label">
              <label>{{ "Complaint_ProductAlreadyDelivered" | translate }}<span class="asteriskRequired"
                *ngIf="this.issueResolutionForm.controls['ProductAlreadyDelivered']?.validator"></span></label>
            </div>
            <div class="col-md-8 col-sm-9 remove-px rca-dropdownlist">
              <input type="hidden" formControlName="isNoofUnitsShippedRequired">
              <p-dropdown [pTooltip]="productShippedTooltip" [escape]="false" tooltipPosition="top" (onChange)="productAlreadyDeliveredChanged()"
                  tooltipStyleClass="toolTipClass" [options]="productShippedValues" placeholder="Select one"
                  [ngClass]="isSubmitted && this.issueResolutionForm.controls['ProductAlreadyDelivered'].invalid ? 'error-dropdownInput' : ''"
                  formControlName="ProductAlreadyDelivered"></p-dropdown>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0 text-right rca-label">
              <label>{{ "Complaint_NoOfUnitsShipped" | translate }}<span class="asteriskRequired"
                *ngIf="this.issueResolutionForm.controls['NoOfUnitsShipped']?.validator"></span></label>
            </div>
            <div class="col-md-8 col-sm-9 remove-px rca-dropdownlist">
              <p-dropdown [pTooltip]="noOfUnitShippedTooltip" [escape]="false" tooltipPosition="top"
              tooltipStyleClass="toolTipClass" [options]="noOfUnitShipped" placeholder="Select one"
              [ngClass]="isSubmitted && this.issueResolutionForm.controls['NoOfUnitsShipped']?.invalid ? 'error-dropdownInput' : ''"
              formControlName="NoOfUnitsShipped"></p-dropdown>
            </div>
          </div>


<!--Business Change Request 8903: RITM0623987 | Restore the field Q- Number Resolving Unit - Part 1-->
          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0 text-right rca-label">
              <label>{{ "Complaint_QNumberResolvingUnit" | translate }}<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['QNumberResolvingUnit']?.validator"></span> </label>
            </div>
            <div class="col-md-8 col-sm-9 remove-px">
              <input type="text" class="form-control raisedInput estimatedCostInput" name="q-number" [pTooltip]="qNumberTip" [escape]="false" placement="top"
                     tooltipStyleClass="toolTipClass" [ngClass]="isSubmitted && this.issueResolutionForm.controls['TotalInstalledBase'].invalid ? 'error-dropdownInput' : ''"
                formControlName="QNumberResolvingUnit" maxlength="{{maxLength.Q_NUMBER_RESOLVING}}"/>
            </div>
          </div>
        </div>
        <!-- current rating Configuration start -->
        <div class="row rating-heading">
          <h6 class="inner-heading1">{{ "ComplaintForm_CurrentRatingScoringMatrixHeader" | translate }} </h6>
        </div>

        <div class="container-fluid rating-maindiv remove-px">
          <div class="row" *ngIf="complaintCreatedDate<ncrLiveDate">
            <div class="col-md-2 col-sm-3 ms-0 ps-0 remove-px remove-ms">
              <h6 class="inner-heading1 bg-transparent remove-px ps-0">{{ "Excel_weekly_severity" | translate }}</h6>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0 ratingLabel">
              <label class="ratingLabel">Availability<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['availabilityRating']?.validator"></span> </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <p-dropdown [options]="availabilityRatingItems" formControlName="availabilityRating" optionLabel="text"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['availabilityRating'].invalid ? 'error-dropdownInput' : ''"
                optionValue="value" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">Functionality<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['functionalityRating']?.validator"></span> </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <p-dropdown [options]="functionalityRatingItems" formControlName="functionalityRating" optionLabel="text"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['functionalityRating'].invalid ? 'error-dropdownInput' : ''"
                optionValue="value" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">On Time Delivery<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['onTimeDeliveryRating']?.validator"></span> </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <p-dropdown [options]="onTimeDeliveryRatingItems" formControlName="onTimeDeliveryRating"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['onTimeDeliveryRating'].invalid ? 'error-dropdownInput' : ''"
                optionLabel="text" optionValue="value" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
            </div>
          </div>


          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">Cyber Security<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['cyberSecurityRating']?.validator"></span></label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <p-dropdown [options]="cyberSecurityRatingItems" formControlName="cyberSecurityRating" optionLabel="text"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['cyberSecurityRating'].invalid ? 'error-dropdownInput' : ''"
                optionValue="value" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
            </div>
          </div>


          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">Product Compliance<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['productComplianceRating']?.validator"></span></label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <p-dropdown [options]="productComplianceRatingItems" formControlName="productComplianceRating"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['productComplianceRating'].invalid ? 'error-dropdownInput' : ''"
                optionLabel="text" optionValue="value" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
            </div>
          </div>


          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">OHS Risk<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['ohsRiskRating']?.validator"></span> </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <p-dropdown [options]="ohsRiskRatingItems" formControlName="ohsRiskRating" optionLabel="text"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['ohsRiskRating'].invalid ? 'error-dropdownInput' : ''"
                optionValue="value" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
            </div>
          </div>


          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">Margin<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['marginRating']?.validator"></span> </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <p-dropdown [options]="marginRatingItems" formControlName="marginRating" optionLabel="text"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['marginRating'].invalid ? 'error-dropdownInput' : ''"
                optionValue="value" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
            </div>
          </div>


          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">Reputation<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['reputationRating']?.validator"></span> </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <p-dropdown [options]="reputationRatingItems" formControlName="reputationRating" optionLabel="text"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['reputationRating'].invalid ? 'error-dropdownInput' : ''"
                optionValue="value" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
            </div>
          </div>


          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">Mass media<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['massMediaRating']?.validator"></span> </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <p-dropdown [options]="massMediaRatingItems" formControlName="massMediaRating" optionLabel="text"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['massMediaRating'].invalid ? 'error-dropdownInput' : ''"
                optionValue="value" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
            </div>
          </div>


          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">Design change<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['designChangeRating']?.validator"></span> </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <p-dropdown [options]="designChangeRatingItems" formControlName="designChangeRating" optionLabel="text"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['designChangeRating'].invalid ? 'error-dropdownInput' : ''"
                optionValue="value" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
            </div>
          </div>

          <div class="row" *ngIf="complaintCreatedDate<ncrLiveDate">
            <div class="col-md-2 col-sm-3 ps-0 pe-0 remove-px">
              <h6 class="inner-heading1 bg-transparent remove-px ps-0"> Occurrence</h6>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">Instances<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['instancesRating']?.validator"></span> </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <p-dropdown [options]="instancesRatingItems" formControlName="instancesRating" optionLabel="text"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['instancesRating'].invalid ? 'error-dropdownInput' : ''"
                optionValue="value" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
            </div>
          </div>


          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">Resolution<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['resolutionRating']?.validator"></span> </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <p-dropdown [options]="resolutionRatingItems" formControlName="resolutionRating" optionLabel="text"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['resolutionRating'].invalid ? 'error-dropdownInput' : ''"
                optionValue="value" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
            </div>
          </div>


          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">{{ "Complaint_ReferredCustomerName" | translate }}<span
                  class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['affectedCustomerRating']?.validator"></span> </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <p-dropdown [options]="affectedCustomerRatingItems" formControlName="affectedCustomerRating"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['affectedCustomerRating'].invalid ? 'error-dropdownInput' : ''"
                optionLabel="text" optionValue="value" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
            </div>
          </div>


          <div class="row" *ngIf="complaintCreatedDate<ncrLiveDate">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <h6 class="inner-heading1 bg-transparent remove-px ps-0">Detection</h6>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">Control<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['controlRating']?.validator"></span> </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <p-dropdown [options]="controlRatingItems" formControlName="controlRating" optionLabel="text"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['controlRating'].invalid ? 'error-dropdownInput' : ''"
                optionValue="value" (onChange)="calculateRpnAndImpactRating()"></p-dropdown>
            </div>
          </div>


          <!-- <hr class="form-group" /> -->
          <div class="row form-group mb-2">
            <label class="col-md-2 col-sm-3 control-label clear-left-padding  pe-0 text-right rca-label">
              {{'Rating result' | translate }}<span class="asteriskRequired"
                *ngIf="this.issueResolutionForm.controls['impactRating']?.validator"></span>
            </label>
            <div class="col-md-4 col-sm-5 clear-right-padding remove-px">
              <input type="text" class="form-control raisedInput disabled" formControlName="impactRating"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['impactRating'].invalid ? 'error-inputbox' : ''"
                readonly />

            </div>
          </div>
          <div class="row form-group clear-bottom-margin mb-2" style="display: none;">
            <label class="col-md-2 col-sm-3 control-label clear-left-padding">
              {{'RPN' | translate}}:
            </label>
            <div class="col-md-2 clear-right-padding">
              <input type="text" class="form-control raisedInput" name="rpn" formControlName="rpn" readonly />
            </div>
          </div>

          <div class="row mb-2" *ngIf="manualChangeVisible">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">{{'Complaint_IsManualChange' | translate}}<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['manualChangeClass']?.validator"></span> </label>
            </div>
            <div class="col-md-4 col-sm-4 remove-px">
              <p-dropdown [options]="manualChangeClass" class="manualChangeReviewRequired" formControlName="manualChangeClass" optionLabel="dataText"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['manualChangeClass'].invalid ? 'error-dropdownInput' : ''"
                optionValue="dataValue" (onChange)="onManualDDLChange($event)"></p-dropdown>
                <input type="hidden" formControlName="manualClassUpgrade">
                <input type="hidden" formControlName="unAssignClassConfirmation">
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 control-label clear-left-padding pe-0 text-right rca-label">
              <label>{{ "Complaint_NoReportJustification" | translate }}<span class="asteriskRequired"
                  *ngIf="!justificationReadOnly"></span> </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <textarea class="form-control" formControlName="JustificationCurrent" [readOnly]="justificationReadOnly"
                [ngClass]="{ 'error-inputbox' : isSubmitted && this.issueResolutionForm.controls['JustificationCurrent']?.invalid , 'disabled' : justificationReadOnly }" maxlength="{{maxLength.JUSTIFICATION_CURRENT}}"></textarea>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel" [pTooltip]="Required8DTip" [escape]="false" placement="top"
                     tooltipStyleClass="toolTipClass">{{'Complaint_8D_Required' | translate}}<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['eightDReportRequired']?.validator"></span> </label>
            </div>
            <div class="col-md-4 col-sm-4 remove-px">
              <p-dropdown [options]="reviewAndReportRequired" class="manualChangeReviewRequired opacity-primary" optionLabel="value"
                [ngClass]="{'error-inputbox' : isSubmitted && this.issueResolutionForm.controls['eightDReportRequired'].invalid ,'disabled' : isEightDReportReadOnly }"
                optionValue="value" formControlName="eightDReportRequired"
                (onChange)="onManual8DChange($event)"></p-dropdown>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-2 col-sm-3 control-label clear-left-padding pe-0 text-right rca-label">
              <label>{{ "Complaint_EightDJustificationRequired" | translate }}<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['EightDJustificationCurrent']?.validator"></span> </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <input type="hidden" formControlName="isEightDJustificationCurrent">
              <textarea class="form-control" [readonly]="isEightDReportJustificationReadOnly"
                formControlName="EightDJustificationCurrent"
                [ngClass]="{ 'error-inputbox' : isSubmitted && this.issueResolutionForm.controls['EightDJustificationCurrent']?.invalid , 'disabled' : isEightDReportJustificationReadOnly }" maxlength="{{maxLength.EIGHT_D_JUSTIFICATION_CURRENT}}"></textarea>
            </div>
          </div>

          <div class="row mb-2" *ngIf="reviewRequired_Id">
            <div class="col-md-2 col-sm-3 ps-0 pe-0">
              <label class="ratingLabel">{{'Complaint_ReviewRequired' | translate}}<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['ReviewRequired']?.validator"></span> </label>
            </div>
            <div class="col-md-4 col-sm-4 review_Required remove-px">
              <p-dropdown [options]="reviewAndReportRequired" class="manualChangeReviewRequired" optionLabel="value" optionValue="value"
                placeholder="Select one" formControlName="ReviewRequired"
                [ngClass]="{'error-dropdownInput' : isSubmitted && this.issueResolutionForm.controls['ReviewRequired']?.invalid }"></p-dropdown>
            </div>
          </div>

          <div class="row mb-2" *ngIf="reviewRequired_Id">
            <div class="col-md-2 col-sm-3 control-label clear-left-padding pe-0 text-right rca-label">
              <label>{{ "Complaint_EightDJustificationRequired" | translate }} </label>
            </div>
            <div class="col-md-9 col-sm-9 remove-px">
              <textarea class="form-control" formControlName="reviewRequiredJustification"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['reviewRequiredJustification']?.invalid ? 'error-inputbox' : ''" maxlength="{{maxLength.REVIEW_REQUIRED_JUSTIFICATION}}"></textarea>
            </div>
          </div>


        </div>

        <p-toast position="top-right"></p-toast>


        <!-- current rating Configuration end -->

        <div class="form-check mt-4 rca-checkaction">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
            formControlName="RCACorrectiveActions" (change)="rcaValueChange()" />
          <label class="form-check-label" for="flexCheckDefault" [pTooltip]="rcaCheckBoxTip" [escape]="false" placement="top"
                 tooltipStyleClass="toolTipClass">{{ "Complaint_8DRCAIsRequiredByCustomer" | translate
            }}</label>
        </div>

        <div class="row mb-1" *ngIf="complaintCreatedDate<ncrLiveDate">
          <div class="summarize-text" style="margin-left: 0px;">
            <label>{{ "Complaint_SpecificationName" | translate }}<span class="asteriskRequired"
                *ngIf="this.issueResolutionForm.controls['SpecificationName']?.validator"></span> </label>
            <textarea pInputTextarea [autoResize]="true" class="form-control customtextfield-l custom-textarea-auto"
              [ngClass]="isSubmitted && this.issueResolutionForm.controls['SpecificationName']?.invalid ? 'error-inputbox' : ''"
              formControlName="SpecificationName" class="form-control" [pTooltip]="specificationNameTip" [escape]="false" placement="top"
                      tooltipStyleClass="toolTipClass" maxlength="{{maxLength.SPECIFICATION_NAME}}"></textarea>
          </div>
        </div>

        <div class="row mb-1" *ngIf="complaintCreatedDate<ncrLiveDate">
          <div class="summarize-text" style="margin-left: 0px;">
            <label>{{ "Complaint_HowProductFailMeetSpecification" | translate }}<span class="asteriskRequired"
                *ngIf="this.issueResolutionForm.controls['howProductFailMeetSpecification']?.validator"></span> </label>
            <textarea class="form-control customtextfield-l custom-textarea-auto" pInputTextarea [autoResize]="true"
              [ngClass]="isSubmitted && this.issueResolutionForm.controls['howProductFailMeetSpecification']?.invalid ? 'error-inputbox' : ''"
              id="" value="" formControlName="howProductFailMeetSpecification" class="form-control" [pTooltip]="failMeetSpecTip" [escape]="false" placement="top"
                      tooltipStyleClass="toolTipClass" maxlength="{{maxLength.HOW_PRODUCT_FAIL_MEET_SPEC}}"></textarea>
          </div>
        </div>

        <div class="row mb-1" *ngIf="ContainmentActionsRequiredFromIssueRes==true">
            <div class="summarize-text" style="margin-left: 0px;">
              <label>{{"Complaint_QuickFixDescription"|translate}}<span class="asteriskRequired"
                  *ngIf="this.issueResolutionForm.controls['WhatContainmentActionsWhereTaken']?.validator"></span></label>
              <input type="hidden" formControlName="ContainmentActionsExecution">
              <textarea pInputTextarea [autoResize]="true" class="form-control customtextfield-l custom-textarea-auto"
                [ngClass]="isSubmitted && (this.issueResolutionForm.controls['WhatContainmentActionsWhereTaken'].invalid || (this.issueResolutionForm.controls['WhatContainmentActionsWhereTaken']?.disabled && this.issueResolutionForm.controls['WhatContainmentActionsWhereTaken']?.validator)) ? 'error-inputbox' : ''"
                formControlName="WhatContainmentActionsWhereTaken" [pTooltip]="containtmentActiontakeTip" [escape]="false" placement="top"
                        tooltipStyleClass="toolTipClass"></textarea>
              <!-- <div *ngIf="submitted && f['WhatContainmentActionsWhereTaken'].errors" class="invalid-feedback">
                <div *ngIf="f['WhatContainmentActionsWhereTaken'].errors['required']">
                  This Field is Mandatory.
                </div>
              </div> -->
            </div>
        </div>


        <div class="row mb-1">
          <div class="summarize-text" style="margin-left: 0px;">
            <label style="padding-left: 0px;">{{ "Complaint_WhatWasRootCause" | translate }}<span
                class="asteriskRequired"></span></label>
            <textarea class="form-control customtextfield-l custom-textarea-auto" pInputTextarea [autoResize]="true"
              [ngClass]="isSubmitted && this.issueResolutionForm.controls['whatWasTheRootCauseOfThisIssue']?.invalid ? 'error-inputbox' : ''"
              id="" value="" formControlName="whatWasTheRootCauseOfThisIssue" class="form-control" [pTooltip]="rootCauseTip" [escape]="false" placement="top"
                      tooltipStyleClass="toolTipClass"></textarea>
          </div>
        </div>

        <div class="marginOnTop categorykey-field" *ngIf="complaintCreatedDate<ncrLiveDate">
          <label>{{ "Complaint_CategoryKeyword" | translate }}<span class="asteriskRequired"
              *ngIf="this.issueResolutionForm.controls['CategoryKeyword']?.validator"></span> </label>
        </div>
        <div class="category-keyword" *ngIf="complaintCreatedDate<ncrLiveDate">
          <p-multiSelect [options]="categoryData" class="p-input" defaultLabel="Select category keyword"
            [showToggleAll]="false" optionValue="dataValue" optionLabel="name" display="chip" [filter]="false"
            formControlName="CategoryKeyword" [pTooltip]="categoryKeywordYip" [escape]="false" placement="top"
                         tooltipStyleClass="toolTipClass" maxlength="{{maxLength.CATEGORY_KEYWORD}}">
            <!-- <ng-template class="multiSelectHeader" pTemplate="header">
                Select All
              </ng-template> -->
          </p-multiSelect>
        </div>

        <div class="row marginOnTop">
          <div class="summarize-text" style="margin-left: 0px;">
            <label>{{ "Complaint_HowDidWeResolveIssue" | translate }}<span class="asteriskRequired"></span></label>
            <textarea class="form-control customtextfield-l custom-textarea-auto" pInputTextarea [autoResize]="true"
              [ngClass]="isSubmitted && this.issueResolutionForm.controls['howDidWeResolveThisIssue']?.invalid ? 'error-inputbox' : ''"
              id="" value="" formControlName="howDidWeResolveThisIssue" class="form-control" [pTooltip]="resolveIssueTip" [escape]="false" placement="top"
                      tooltipStyleClass="toolTipClass"></textarea>
          </div>
        </div>



        <div class="template-link mb-1  float-end" style="text-align: end">
          <h6 *ngIf="complaintCreatedDate<ncrLiveDate">
            <a href="../../../../assets/files/DMAIC_Report_template.xlsx" target="_blank"
              download="DMAIC_Report_template.xlsx" class="templatefiles">
              <b>{{ "LinkRCATemplate" | translate }}</b>
            </a>
          </h6>
          <p-menubar [styleClass]="'eightD-action-menu'" [model]="eightDReportTemplates" [autoDisplay]="true">
          </p-menubar>
          <!-- <h6>
            <a (click)="download8DReport()" target="_blank" class="templatefiles">
              <b>{{ "LinkSecondRCATemplated" | translate }}</b>
            </a>
          </h6> -->
          <h6 *ngIf="complaintCreatedDate<ncrLiveDate">
            <a href="../../../../assets/files/PDCA _report_template.xlsx" target="_blank"
              download="PDCA _report_template.xlsx" class="templatefiles">
              <b>{{ "LinkSecondPDCATemplate" | translate }}</b>
            </a>
          </h6>
        </div>

        <!-- <div class="col-sm-12">
          <fieldset class="border-0">
              <h6 class="inner-heading1"> Attachments: </h6>
              <div class="row mb-1 attachments-section">
                  <div class="col-md-3 col-sm-3 ps-0 pe-0 text-end">
                      <label class="mt-2">Specify attachment (max 30 MB)<span class="asteriskRequired" *ngIf="this.issueResolutionForm.controls['ImpactOnInstalledBase']?.validator"></span>:</label>
                  </div>
                  <div class="col-md-8 col-sm-9 selectfile">
                      <p-fileUpload formControlName="uploadedFile" #createUploader name="files[]" chooseLabel="Select Files" [showCancelButton]="true" chooseIcon="null"
                          removeStyleClass="p-overlaypanel1" [multiple]="true" chooseStyleClass="p-overlaypanel"
                          [maxFileSize]="60000000" [auto]="true" class="" [customUpload]="true"
                          (uploadHandler)="myUploader($event)">
                          <ng-template let-file pTemplate="file">
                              <ul class="p-upload-files p-reset">
                                  <li class="p-file row" [pTooltip]="file?.name" [escape]="false" placement="top" tooltipStyleClass="toolTipClass">
                                    <div class="col-md-6 col-sm-6"> <span class="p-filename" [title]="file?.name">{{file?.name}}</span></div>
                                    <div class="col-md-6 col-sm-6"> <button type="button" class="widgetButtonMedium" (click)="removeFile(file, createUploader)"> Remove</button></div>
                                  </li>
                              </ul>
                          </ng-template>
                      </p-fileUpload>

                  </div>
              </div>
          </fieldset>
      </div>
       -->
        <div>

          <div [pTooltip]="upload8DTip" [escape]="false" placement="top"
                tooltipStyleClass="toolTipClass">
            <div class="row">
              <div class="col-md-2 col-sm-3 ps-0 pe-0 tabwidth8D">
              <h6 *ngIf="complaintCreatedDate<ncrLiveDate" class="inner-heading1 bg-transparent remove-px ps-0 upload8dHeading">
                {{ (this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.class==0 ? "ComplaintForm_UploadRCAAttachmentsSectionName" : "ComplaintForm_UploadRCAAttachmentsSectionName_ClassABI") | translate }}
              </h6>
              <h6 *ngIf="!(complaintCreatedDate<ncrLiveDate)" class="inner-heading1 bg-transparent remove-px ps-0 upload8dHeading">
                {{ "ComplaintForm_UploadRCAAttachmentsSectionName_ClassABI" | translate }}
              </h6>
            </div>
              <div class="row mb-1 specificAttach attachments-section" [ngClass]="{'error-document' : isSubmitted && this.issueResolutionForm.controls['UploadRCAAttachments']?.invalid}">
                <div class="col-md-3 col-sm-3 ps-3 pe-0 text-left rca-label" *ngIf="UploadRCAAttachments?.length > 0">
                  <label class="mt-2">{{ "ComplaintForm_UploadedAttachments" | translate }}</label>
                </div>
              </div>
                <div class="row">
                  <div class="col-md-12 col-sm-12 p-0 selectfile-rca" *ngIf="UploadRCAAttachments?.length > 0">
                    <div class="already-uploaded-attachments-main-rca">
                      <div class="row file-row"
                        *ngFor="let rcaAttachment of UploadRCAAttachments; let RCAAttachmentIndex = index;">
                        <span style="display: flex;" class="filenamewidth1">
                          <a class="fileInfo-rca" [ngClass]="checkPDCARemoved(RCAAttachmentIndex) ? 'to-be-removed' : ''"
                          pTooltip="{{rcaAttachment?.displayName}}" [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"
                          (click)="downloadAttachementFromAPI(rcaAttachment?.storeFileName,rcaAttachment?.displayName)" style="cursor: pointer;"
                           target="_blank">{{rcaAttachment?.displayName | shortenComplaintSubject : 20}}</a>
                           <span class="col-md-1 col-sm-1 text-center filenamewidth2">
                            <i class="fa fa-trash closebtn" aria-hidden="true"
                              (click)="uploadedPDCAAttachmentRemove(RCAAttachmentIndex)"> </i>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div class="row ms-0">
                      <span class="downloadAllLink">
                          <a href="{{downloadAllUrlrca}}" target="_blank">Download All</a>
                      </span>
                  </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 col-sm-3 ps-3 pe-0 text-left rca-label">
                    <label class="mt-2">{{ "ComplaintForm_SpecifyAttachment" | translate:paramForAttachment }}<span
                        class="asteriskRequired"
                        *ngIf="this.issueResolutionForm.controls['UploadRCAAttachments']?.validator"></span></label>
                  </div>
                  <input type="hidden" formControlName="UploadRCAAttachments">
                  <div class="col-md-8 col-sm-9 selectfile-rca remove-px">
                    <p-fileUpload #createUploaderPDCA name="filesPDCA[]" chooseLabel="Select Files"
                      [showCancelButton]="true" chooseIcon="pi pi-paperclip" removeStyleClass="p-overlaypanel1" [multiple]="true"
                      chooseStyleClass="p-overlaypanel" [maxFileSize]="60000000" [auto]="true" class="" required="true"
                      [customUpload]="true" (uploadHandler)="myUploaderPDCA(createUploaderPDCA,$event)"
                      [disabled]="!disableSection">
                      <ng-template let-filePDCA pTemplate="file">
                        <ul class="p-upload-files p-reset">
                          <li class="p-file row">
                            <div class="col-md-11 col-sm-11 filenamewidth1" [pTooltip]="filePDCA?.name" [escape]="false"
                              tooltipPosition="top" tooltipStyleClass="toolTipClass">
                              <span class="p-filename">{{filePDCA?.name}}</span>
                            </div>
                            <div class="col-md-1 col-sm-1 text-center filenamewidth2" [pTooltip]="removeText" [escape]="false" tooltipPosition="top"
                              tooltipStyleClass="toolTipClass">

                              <i class="fa fa-trash closebtn" aria-hidden="true"
                                (click)="removeFilePDCA(filePDCA, createUploaderPDCA)"> </i>
                            </div>
                          </li>
                        </ul>
                      </ng-template>
                    </p-fileUpload>

                  </div>
                </div>


              </div>
            </div>
          </div>


          <div class="row">
              <h6 class="inner-heading1 resattachment" style="float:left;margin-left:0px !important;width:100%;">
                {{ "ComplaintForm_AttachmentsSectionName" | translate }}
              </h6>
              <!-- <div class="container1"> -->
              <p style="margin-bottom: 0px;">
                {{ "Complaint_ResAttachmentRcaTemplateInfo" | translate }}
              </p>
              <div class="row mb-1 specificAttach attachments-section">
                <div class="col-md-3 col-sm-3 mb-1 ps-3 pe-0 text-left rca-label" *ngIf="resolutionAttachments?.length > 0">
                  <label class="mt-2">{{ "ComplaintForm_UploadedAttachments" | translate }}</label>
                </div>
                <div class="row p-0 selectfile-rca" *ngIf="imageResolutionAttachments?.length > 0">
                  <div class="col-md-12 p-0">
                    <span  style="padding-left: 10px;">Image attachments</span>
                    <div class="already-uploaded-attachments-main-rca">
                      <div class="mt-2 file-row"
                        *ngFor="let resolutionAttachment of imageResolutionAttachments; let SummaryAttachmentIndex = index;">
                        <span style="display: flex;" class="filename-text filenamewidth1">
                          <input type="checkbox"  value="{{resolutionAttachment.comment}}" [checked]="resolutionAttachment.comment"
                          (change)="isAllSelected(resolutionAttachment)"/>
                          <a class="fileInfo-rca"
                            pTooltip="<img width='350px' src='{{baseurl}}/api/v1/Attachment/Download?storeFileName={{resolutionAttachment?.storeFileName}}&displayName={{resolutionAttachment?.displayName}}' />"
                            [escape]="false" tooltipStyleClass="download-imgstyle"
                            [ngClass]="checkSummaryRemoved(resolutionAttachment?.id) ? 'to-be-removed' : ''"
                            (click)="downloadAttachementFromAPI(resolutionAttachment?.storeFileName,resolutionAttachment?.displayName)"
                            style="cursor: pointer;" target="_blank">{{resolutionAttachment?.displayName | shortenComplaintSubject : 20}}</a>
                          <span class="col-md-1 col-sm-1 text-end filenamewidth2">
                            <i class="fa fa-trash closebtn" aria-hidden="true"
                              (click)="uploadedSummaryAttachmentRemove(resolutionAttachment?.id)"> </i>
                          </span>
                        </span>

                      </div>

                    </div>
                  </div>
                </div>
                <div class="row p-0 selectfile-rca" *ngIf="otherDocResolutionAttachments?.length > 0">
                  <div class="col-md-12 p-0">
                    <span style="padding-left: 10px;">Other format attachments</span>
                    <div class="already-uploaded-attachments-main-rca">
                      <div class="mt-2 file-row"
                        *ngFor="let otherResolutionAttachment of otherDocResolutionAttachments; let SummaryAttachmentIndex = index;">
                        <span style="display: flex;" class="filename-text filenamewidth1"><a class="fileInfo-rca"
                            pTooltip="{{otherResolutionAttachment?.displayName}}" [escape]="false"
                            [ngClass]="checkSummaryRemoved(otherResolutionAttachment?.id) ? 'to-be-removed' : ''"
                            (click)="downloadAttachementFromAPI(otherResolutionAttachment?.storeFileName,otherResolutionAttachment?.displayName)"
                            style="cursor: pointer;" target="_blank">{{otherResolutionAttachment?.displayName | shortenComplaintSubject : 20}}</a>
                          <span class="col-md-1 col-sm-1 text-end filenamewidth2">
                            <i class="fa fa-trash closebtn" aria-hidden="true"
                              (click)="uploadedSummaryAttachmentRemove(otherResolutionAttachment?.id)"> </i>
                          </span>
                        </span>

                      </div>

                    </div>
                  </div>
                </div>
                <div class="row ms-1 p-1" *ngIf="resolutionAttachments?.length > 0">
                  <span class="downloadAllLink">
                    <a href="{{downloadAllUrlresolution}}" target="_blank">Download All</a>
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-sm-3 ps-3 pe-0 text-left rca-label">
                  <label class="mt-2">{{ "ComplaintForm_SpecifyAttachment" | translate:paramForAttachment }}
                    <span class="asteriskRequired"
                      *ngIf="this.issueResolutionForm.controls['resolutionAttachments']?.validator"></span>
                  </label>
                </div>
                <div class="col-md-8 col-sm-9 selectfile-rca remove-px">
                  <input type="hidden" formControlName="resolutionAttachments">
                  <p-fileUpload #createUploaderSummary name="files[]" chooseLabel="Select Files"
                    [showCancelButton]="true" chooseIcon="pi pi-paperclip" removeStyleClass="p-overlaypanel1" [multiple]="true"
                    chooseStyleClass="p-overlaypanel" [maxFileSize]="60000000" [auto]="true" class="" required="true"
                    [customUpload]="true" (uploadHandler)="myUploaderSummary(createUploaderSummary,$event)"
                    [disabled]="!disableSection">
                    <ng-template let-fileSummary pTemplate="file">
                      <ul class="p-upload-files p-reset">
                        <li class="p-file row">
                          <div class="col-md-1 col-sm-1 text-center">
                            <input *ngIf="checkFileType(fileSummary)" type="checkbox" [checked]="checkedImgFile == fileSummary"
                            (change)="selectImageToShowinPPTFile(fileSummary)"/>
                          </div>
                          <div class="col-md-10 col-sm-10 filenamewidth1" [pTooltip]="fileSummary?.name" [escape]="false"
                            tooltipPosition="top" tooltipStyleClass="toolTipClass">
                            <span class="p-filename">{{fileSummary?.name}}</span>
                          </div>
                          <div class="col-md-1 col-sm-1 text-center filenamewidth2" [pTooltip]="removeText" [escape]="false" tooltipPosition="top"
                            tooltipStyleClass="toolTipClass">
                            <!-- <button type="button" class="widgetButtoSmall attachButton"
                              (click)="removeFileSummary(fileSummary, createUploaderSummary)"> X
                            </button> -->
                            <i class="fa fa-trash closebtn" aria-hidden="true"
                              (click)="removeFileSummary(fileSummary, createUploaderSummary)"> </i>
                          </div>
                        </li>
                      </ul>
                    </ng-template>
                  </p-fileUpload>
                </div>
              </div>
              </div>
        <br />

        <!-- </div> -->
        <!-- for lesson learned -->
         <div class="row" *ngIf="!(complaintCreatedDate<lessonsLearntLiveDate)">
          <h6 class="inner-heading1 resattachment" style="float:left;margin-left:0px !important;width:100%;">
            Lessons Learned
          </h6>
          </div>
        <div class="row mb-1" *ngIf="!(complaintCreatedDate<lessonsLearntLiveDate)">
          <div class="summarize-text" style="margin-left: 0px;">
            <label style="padding-left: 0px;">{{ "Complaint_Lesson_Learnt" | translate }} <span style="margin-left: 5px;">{{'Lessons_Learnt_Onepage_Info'|translate}}</span>
            </label>
            <textarea class="form-control customtextfield-l custom-textarea-auto" pInputTextarea [autoResize]="true"
              id="" value="" maxlength="{{maxLength.LESSONS_LEARNT}}" formControlName="lessonLearnt" class="form-control"></textarea>
          </div>
        </div>
        <app-issue-resolution-lessson-learned [complaintData]="complaintDataLessonLearned"
          (lessonLearnedData)="lessonLearned($event)" *ngIf="complaintCreatedDate<lessonsLearntLiveDate"></app-issue-resolution-lessson-learned>
<div class="row">
        <div class="issue-categorisation px-0">
          <h6 class="row inner-heading1">{{ "ComplaintForm_SectionComplaintSummaryDisplayName" | translate }} </h6>
          <div class="row alignActualRU-row">
            <label class="col-sm-2 col-form-label alignActualRU">
              <!-- need to change resource value in database  {{ "Complaint_ActualCost" | translate }}-->
              Actual RU Cost (K USD)
            </label>
            <div class="col mt-2 alignActualRUInput remove-px">
              <input type="text" class="form-control estimatedCostInput" formControlName="ActualRUCost"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['ActualRUCost']?.invalid ? 'error-inputbox' : ''"
                (keydown)="checkNumeric($event);" inputWithComma [pTooltip]="actualRUTip" [escape]="false" placement="top"
                     tooltipStyleClass="toolTipClass"/>
            </div>
          </div>

          <div class="row mb-0 remove-margin-mobile">
            <div class="col-md-2 col-sm-3 ps-0 pe-0 alignActualRU">
              <label class="category-label">
                {{ "rptv_copq_category" | translate }}<span class="asteriskRequired"></span>
              </label>
            </div>
            <div class="col-md-8 col-sm-9 remove-px rca-textbox" pTooltip="{{ textDATA }}" tooltipPosition="top">
              <input type="text" class="form-control customtextfield-l resolutionCategory" [(ngModel)]="textDATA"
                name="textDATA" formControlName="COPQCategory" readonly="true" [pTooltip]="COPQCategoryTooltipText" [escape]="false" tooltipPosition="top"
                tooltipStyleClass="toolTipClass"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['COPQCategory']?.invalid ? 'error-inputbox' : ''" />
              <p-toast></p-toast>
              <button type="button" class="col-sm-1 widgetButtoSmall ms-2 mt-1 remove-ms" style="margin-top: 5px;"
                (click)="COPQPopUp()">
                {{ textDATA == "" ? "Select" : "Change" }}
              </button>
              <input type="hidden" formControlName="COPQCategoryAllData">
              <input type="hidden" formControlName="PGRCACategoryAllData">
            </div>
          </div>

          <div class="row mb-1 remove-margin-mobile">
            <div class="col-md-2 col-sm-3 ps-0 pe-0 alignActualRU">
              <label class="category-label">
                {{ "Complaint_PGCategory" | translate }} <span class="asteriskRequired"></span>
              </label>
            </div>
            <div class="col-md-8 col-sm-9 remove-px rca-textbox" [pTooltip]="pgCategoryTooltipText" [escape]="false" tooltipPosition="top">
              <input class="form-control customtextfield-l resolutionCategory"
                formControlName="pgCategory" readonly="true"
                tooltipStyleClass="toolTipClass"
                [ngClass]="isSubmitted && this.issueResolutionForm.controls['pgCategory']?.invalid ? 'error-inputbox' : ''" />
              <button type="button" class="col-sm-1 widgetButtoSmall ms-2 mt-1 remove-ms" style="margin-top: 5px;"
                (click)="PGRCAPopUp()">
                {{ issueResolutionForm.controls['pgCategory']?.value == "" ? "Select" : "Change" }}
              </button>
            </div>
          </div>

          <div class="row mb-1 remove-margin-mobile" *ngIf="isEnableSAPQN || this.complaintData?.header?.sapId">
            <div class="col-md-2 col-sm-3 ps-0 pe-0 alignActualRU">
                <label>{{ "Complaint_DefectCauseDefectCauseCodeGroup" | translate }}
                  <span class="asteriskRequired" *ngIf="issueResolutionForm.controls['DefectCauseDefectCauseCodeGroup']?.validator"></span></label>
            </div>
            <div class="col-md-8 col-sm-9 remove-px rca-textbox">
                <input type="text" readonly="true" [pTooltip]="defectCauseToolTip" [escape]="false"
                       tooltipPosition="top" tooltipStyleClass="toolTipClass"
                       class="form-control customtextfield-l mb-0 resolutionCategory"
                       [ngClass]="isSubmitted && issueResolutionForm.controls['DefectCauseDefectCauseCodeGroup'].invalid ? 'error-inputbox' : ''"
                       pInputText formControlName="DefectCauseDefectCauseCodeGroup" />
                <button class="col-sm-1 widgetButtoSmall ms-2 mt-0 remove-ms selectdefect-btn" (click)="showDefectTypePopup()"
                        [pTooltip]="issueResolutionForm.controls['DefectCauseDefectCauseCodeGroup'].value=='' ? '' : ''"
                        [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"
                        [disabled]="disableDefectCause()"
                        [ngClass]="disableDefectCause() ? 'grayButton':''">
                  {{issueResolutionForm.controls['DefectCauseDefectCauseCodeGroup'].value == "" || issueResolutionForm.controls['DefectCauseDefectCauseCodeGroup'].value == null ? "Select" :
                  "Change"}}</button>
                <!-- <input type="hidden" formControlName="defectCauseHiddenFieldRCA"> -->
            </div>
          </div>
        </div>
      </div>
        <div class="col-md-3 d-grid gap-2 d-md-flex justify-content-md-end float-end">
          <div>
            <!-- {{isResolutionOwner | json}} -->
            <button type="button"
              *ngIf="!isResolutionOwner"
              [disabled]="setButtonVisibility([1,2,3,7,8,9,10,12,11,13]) || !complaintData.areaComplaintCollection.complaintInformationSection.highImpactValidationViewModel.isConfirm ||
               isManualChangeForCurrentRating ||
              (this.complaintData?.areaComplaintCollection?.complaintInformationSection?.isContainmentActionRequired && this.complaintData?.areaComplaintCollection?.complaintInformationSection?.containmentAction?.execution==null)"
              [ngClass]="setButtonVisibility([1,2,3,7,8,9,10,12,11,13]) || !disableBtn['revertToNotAssigned_issueRes'] || !complaintData.areaComplaintCollection.complaintInformationSection.highImpactValidationViewModel.isConfirm == true || isManualChangeForCurrentRating ? 'widget-buttonlarge grayButton' : 'widget-buttonlarge'"
              (click)="revertToNotAssignEmail($event)" [disabled]="!disableBtn['revertToNotAssigned_issueRes']">
              {{ "ButtonRevertToNotAssigned" | translate }}
            </button>
          </div>
          <button *ngIf="isClassCanbeRejected"  (click)="onReject($event)"
          [ngClass]="!isManualChangeForCurrentRating ? 'widgetButtonMedium' : 'widgetButtonMedium grayButton'"
          [disabled]="isManualChangeForCurrentRating" >Reject</button>
          <!--Business Change Request 10173: UI Changes |RITM0894045  | CHG0080244 | CCRP with "Completed, Ex pending status", will allow to add attachments-->
          <button *ngIf="setButtonVisibility([3,4,5,6,7,8,9,10,11,12,13])"
            [ngClass]="setButtonVisibility([8]) == true || !disableBtn['saveBtn_issueRes']? 'widgetButtonMedium grayButton' : 'widgetButtonMedium'"
            (click)="onSave($event)" [disabled]="setButtonVisibility([8]) || !disableBtn['saveBtn_issueRes']">
            {{ "ButtonSave" | translate }}
          </button>
          <button *ngIf="setButtonVisibility([11,12]) && !IsBuVerified"
           [ngClass]="!setButtonVisibility([11,12]) || !disableBtn['verifyRes_ByBuProcessOwner']  || !complaintData.areaComplaintCollection.complaintInformationSection.highImpactValidationViewModel.isConfirm == true || IsBuVerified ? 'grayButton' : ''"
           [disabled]="!setButtonVisibility([11,12]) || !disableBtn['verifyRes_ByBuProcessOwner'] || !complaintData.areaComplaintCollection.complaintInformationSection.highImpactValidationViewModel.isConfirm || IsBuVerified"
           class="input widget-buttonlarge" type="button" (click)="VerifyResolutionByBuProcessOwner($event)">
           {{ "VerifiedByBuButton" | translate }}
          </button>
          <button *ngIf="setButtonVisibility([11,12]) && IsBuVerified"
            [ngClass]="!setButtonVisibility([11,12]) ||  !disableBtn['verifyRes_issueRes'] || !isVerifiedCoordinator || !complaintData.areaComplaintCollection.complaintInformationSection.highImpactValidationViewModel.isConfirm == true || !IsBuVerified ? 'grayButton' : ''"
            [disabled]="!setButtonVisibility([11,12]) || !disableBtn['verifyRes_issueRes'] || !isVerifiedCoordinator || !complaintData.areaComplaintCollection.complaintInformationSection.highImpactValidationViewModel.isConfirm || !IsBuVerified"
            class="input widgetButtonMedium" type="button" (click)="verifyResolution($event)">
            {{ "ButtonVerifyResolution" | translate }}
          </button>
          <button *ngIf="setButtonVisibility([11,12])"
            [ngClass]="!setButtonVisibility([11,12]) || !disableBtn['returnToRO_issueRes']  || !complaintData.areaComplaintCollection.complaintInformationSection.highImpactValidationViewModel.isConfirm == true? 'widget-buttonlarge grayButton' : 'widget-buttonlarge'"
            [disabled]="(!setButtonVisibility([11,12]) || !disableBtn['returnToRO_issueRes'] || !complaintData.areaComplaintCollection.complaintInformationSection.highImpactValidationViewModel.isConfirm)"
            class="input widget-buttonlarge" type="button" (click)="revertToResolutionOwnerEmail($event)">
            {{ "ButtonRequestAdditionalInformation" | translate }}
          </button>
          <button type="button"
            [ngClass]="setButtonVisibility([7,8,9,10,12,11,13]) || !disableBtn['resolved_issueRes'] ||   !complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpactValidationViewModel?.isConfirm == true || isManualChangeForCurrentRating? 'widgetButtonMedium grayButton' : 'widgetButtonMedium'"
            *ngIf="setButtonVisibility([4,5,6,7,8,9,10,11,12,13]) && !(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.isContainmentActionRequired && this.complaintData?.areaComplaintCollection?.complaintInformationSection?.containmentAction?.execution==null)"
            [disabled]="!disableBtn['resolved_issueRes'] || setButtonVisibility([7,8,9,10,12,11,13]) ||  !complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpactValidationViewModel?.isConfirm || isManualChangeForCurrentRating"
            (click)="resolved($event)">
            {{ "ButtonResolved" | translate }}
          </button>
          <!-- *ngIf="containtmentData() || isRoleWithContainmentActionsSupportDesk"  [disabled]="!isResolved"   [ngClass]="
                isResolved
                  ? 'input widget-buttonlarge'
                  : 'input widget-buttonlargedisabled'
              " -->
          <button type="button"
            *ngIf="setButtonVisibility([4,5,11,12]) && this.complaintData?.areaComplaintCollection?.complaintInformationSection?.isContainmentActionRequired && this.complaintData?.areaComplaintCollection?.complaintInformationSection?.containmentAction?.execution==null"
            [disabled]="!disableBtn['ButtonExecuteContainmentAction']"
            [ngClass]="!disableBtn['ButtonExecuteContainmentAction']? 'widgetButtonMedium grayButton' : 'widgetButtonMedium'"
             (click)="containmentToResolved($event)" class="input widget-buttonlarge">
            {{ "ButtonExecuteContainmentAction" | translate }}
          </button>


        </div>
      </form>
    </div>
  </div>
</div>
