import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailServiceTempService } from 'src/app/services/email-service-temp.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import orderBy from 'lodash/orderBy';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-status-update-popup',
  templateUrl: './status-update-popup.component.html',
  styleUrls: ['./status-update-popup.component.css']
})
export class StatusUpdatePopupComponent implements OnInit {
  complaintNo: string;
  userGeid: string;
  logdata: any[] = [];
  selectedIndex: number;
  logDataToShow: any[] = [];
  showAllBody = false;
  bodyFlag = false;
  @Input() complaintNumber: string;
  constructor(
    private route: ActivatedRoute,
    private sessionStorage: SessionStorageServiceService,
    private emailServiceTempService: EmailServiceTempService,
    private spinner: NgxSpinnerService,
    private config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.complaintNumber = this.config.data.complaintNumber;
    console.log('Received complaint number:', this.complaintNumber);
    this.userGeid = this.sessionStorage.getData('userInfo').geid;
    this.emailServiceTempService.getActionLogDetails(this.complaintNumber, this.userGeid).subscribe((resp) => {
      this.logdata = resp;
      let filteredLogData = this.logdata.filter(i => i.type === 4);
      this.logDataToShow = orderBy(filteredLogData, ['date'], ['desc']);
      //  this.logDataToShow = filteredLogData.length > 0 ? [filteredLogData[0]] : [];
      console.log(this.logDataToShow);
      this.spinner.hide();
    });
  }
  expandRow(index) {
    if (this.selectedIndex != index) {
      this.selectedIndex = index;
    }
  }
  collapseRow(index, event) {
    if (this.selectedIndex == index) {
      this.selectedIndex = null;
    }
    event.stopPropagation();
  }
  expandAllRows() {
    this.showAllBody = !this.showAllBody;
  }

  expandAll() {
    this.bodyFlag = !this.bodyFlag;
    this.showAllBody = this.bodyFlag;
  }

}
