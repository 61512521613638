<div class="row admin-panel">
  <div class="col-sm-12 col-md-6 left-side-panel">
    <div class="blockquote-box clearfix" *ngIf="checkAccessPermission('301')">
      <a [routerLink]="['resolving-units']">
        <div class="contener">
          <div class="square">
            <i class="hitachiicon-home"></i>
          </div>
          <h4>
            {{'admin_dashboard_ru_lbl'|translate}}
          </h4>
          <p class="text-justify adminHomelblAlignment">
            {{'admin_dashboard_ru_desc'|translate}}
          </p>
        </div>
      </a>
    </div>
    <div class="blockquote-box clearfix" *ngIf="checkAccessPermission('310')">
      <a [routerLink]= "['hubs']">
        <div class="contener">
          <div class="square ">
            <i class="hitachiicon-cubes"></i>
          </div>
          <h4>
            {{'admin_dashboard_hub_lbl'|translate}}
          </h4>
          <p class="adminHomelblAlignment">
            {{'admin_dashboard_hub_desc'|translate}}
          </p>
        </div>
      </a>
    </div>
    <div class="blockquote-box clearfix" *ngIf="checkAccessPermission('302')">
      <a [routerLink]="['processowners']">
        <div class="contener">
          <div class="square ">
            <i class="hitachiicon-report"></i>
          </div>
          <h4>
            {{'admin_dashboard_po_lbl'|translate}}
          </h4>
          <p class="adminHomelblAlignment">
            {{'admin_dashboard_po_desc'|translate}}
          </p>
        </div>
      </a>
    </div>

    <div class="blockquote-box clearfix" *ngIf="checkAccessPermission('304')">
      <a [routerLink]= "['pg-categories']">
        <div class="contener">
          <div class="square ">
            <i class="hitachiicon-list"></i>
          </div>
          <h4>
            {{'admin_dashboard_pgc_lbl'|translate}}
          </h4>
          <p class="adminHomelblAlignment">
            {{'admin_dashboard_pgc_desc'|translate}}
          </p>
        </div>
      </a>
    </div>
    <div class="blockquote-box clearfix" *ngIf="checkAccessPermission('309')">
      <a [routerLink]="['failuremode']">
        <div class="contener">
          <div class="square">
            <i class="hitachiicon-intuitive"></i>
          </div>
          <h4>
            {{'admin_dashboard_failure_modes_lbl'|translate}}
          </h4>
          <p class="adminHomelblAlignment">
            {{'admin_dashboard_failure_modes_desc'|translate}}
          </p>
        </div>
      </a>
    </div>
    <div class="blockquote-box clearfix" *ngIf="checkAccessPermission('311')">
      <a routerLink="process-steps">
        <div class="contener">
          <div class="square">
            <i class="hitachiicon-report"></i>
          </div>
          <h4>
            {{'admin_dashboard_process_steps_lbl'|translate}}
          </h4>
          <p class="adminHomelblAlignment">
            {{'admin_dashboard_process_steps_desc'|translate}}
          </p>
        </div>
      </a>
    </div>
    <div class="blockquote-box clearfix" *ngIf="checkAccessPermission('322')">
      <a [routerLink]= "['active-employee']">
        <div class="contener">
          <div class="square ">
            <i class="hitachiicon-customers"></i>
          </div>
          <h4>
            {{'admin_dashboard_ae_lbl'|translate}}
          </h4>
          <p class="text-justify adminHomelblAlignment">
            {{'admin_dashboard_ae_desc'|translate}}
          </p>
        </div>
      </a>
    </div>
  </div>

  <div class="col-sm-12 col-md-6 right-side-panel">
    <div class="blockquote-box clearfix" *ngIf="checkAccessPermission('305')">
      <a [routerLink]= "['customer-contacts']">
        <div class="contener">
          <div class="square ">
            <i class="hitachiicon-id"></i>
          </div>
          <h4>
            {{'admin_dashboard_cc_lbl'|translate}}
          </h4>
          <p class="adminHomelblAlignment">
            {{'admin_dashboard_cc_desc'|translate}}
          </p>
        </div>
      </a>
    </div>
    <div class="blockquote-box clearfix" *ngIf="checkAccessPermission('306')">
      <a [routerLink]="['translations']">
        <div class="contener">
          <div class="square ">
            <i class="hitachiicon-language"></i>
          </div>
          <h4>
            {{'admin_dashboard_trans_lbl'|translate}}
          </h4>
          <p class="adminHomelblAlignment">
            {{'admin_dashboard_trans_desc'|translate}}
          </p>
        </div>
      </a>
    </div>
   <!-- US-9870|Hide the Escalation Tab in the Admin Section
      <div class="blockquote-box clearfix" *ngIf="checkAccessPermission('303')">
      <a [routerLink]="['escalation']">
        <div class="contener">
          <div class="square ">
            <i class="hitachiicon-clock"></i>
          </div>
          <h4>
            {{/*'admin_dashboard_et_lbl'|translate*/ ''}}
          </h4>
          <p class="adminHomelblAlignment">
            {{/*'admin_dashboard_et_desc'|translate*/ ''}}
          </p>
        </div>
      </a>
    </div>-->
    <div class="blockquote-box clearfix" *ngIf="checkAccessPermission('307')">
      <a [routerLink]= "['system-users']">
        <div class="contener">
          <div class="square ">
            <i class="hitachiicon-customers"></i>
          </div>
          <h4>
            {{'admin_dashboard_su_lbl'|translate}}
          </h4>
          <p class="adminHomelblAlignment">
            {{'admin_dashboard_su_desc'|translate}}
          </p>
        </div>
      </a>
    </div>
    <div class="blockquote-box clearfix" *ngIf="checkAccessPermission('308')">
      <a [routerLink]= "['global-ru']">
        <div class="contener">
          <div class="square">
            <i class="hitachiicon-globe"></i>
          </div>
          <h4>
            {{'admin_dashboard_globru_lbl'|translate}}
          </h4>
          <p class="adminHomelblAlignment">
            {{'admin_dashboard_globru_desc'|translate}}
          </p>
        </div>
      </a>
    </div>
    <!--Accountable Person-->
    <div class="blockquote-box clearfix" *ngIf="checkAccessPermission('301')">
      <a [routerLink]= "['accountable-person']">
        <div class="contener">
          <div class="square ">
            <i class="hitachiicon-accountable"></i>
          </div>
          <h4>
            Accountable Person
          </h4>
          <p class="adminHomelblAlignment">
            This panel allows you to change the accountable person for the task.
          </p>
        </div>
      </a>
    </div>
  </div>
</div>
