<div class="container-fluid mt-2">
  <div class="head-text">You can raise two kinds of tickets if you face any issue in CCRP Application.</div>
  <div class="head-text incident"><b>INCIDENT :</b>
    A malfunction in the application is an “incident ” that may cause system to fail to perform its required functions. In other words, if an error is encountered during the normal functioning of the application , can be reported as a incident.  For example, reports such as Analyzer, All-in-one are not working, Users are not receiving an expected email notification, Values for fields such as Product, Failure Mode are not populating.</div>
  <div class="head-text incident"><b>SERVICE REQUEST :</b>
    A  service request is raised for any data updates or request for information   Ex:  Changes in Dates, Downgrading the CCRP, Change in Hub Process Owner etc.</div>

  <div class="head-text">
    <h3>How to register an incident </h3>
    <ul type="square">
      <li class="special">
        Register your case directly in the
        <a href="https://hitachienergy.service-now.com/my_it?id=sc_category&sys_id=e15706fc0a0a0aa7007fc21e1ab70c2f&catalog_id=-1&spa=1"
          title="Go to MyIS site" target="_blank"><strong>HEServiceNow</strong></a>
        (Short guide how to open an incident with HEServiceNow: <a
          href="../../../../assets/files/How to use Hitachi Energy Service Now-CCRP updated.pdf" target="_blank"
          title="Check how to create an incident"><strong>How to create an incident</strong></a>)
      </li>
      <!-- <li class="special">
              Send your support request via email to Help Desk
              <a href="mailto:ABBGlobalServiceDesk@abb.com?subject=CCRP-PG"  target="_top" title="Send email to Help Desk"><b>ABBGlobalServiceDesk@abb.com</b></a> with application name in the subject
</li> -->
      <li class="special">
        Contact the Help Desk via Teams:
        <a href="https://teams.microsoft.com/l/chat/0/0?users=28:6581fe9f-1196-451c-89da-f32b182a2516&amp;"
          target="_blank"><b>Hitachi Energy Global IS Service Desk</b></a>
      </li>

    </ul>
  </div>

  <div class="head-text">
    <h3>How to create a support request (e.g. request for new access) </h3>
    <ul type="square">
      <li class="special">
        Register your case directly in the
        <a href="https://hitachienergy.service-now.com/my_it?id=sc_category&sys_id=d0f1e3261b2f741039e54152bb4bcb83&catalog_id=-1&spa=1"
          title="Go to MyIS site" target="_blank"><b>HEServiceNow</b></a>
        (Short guide how to open support request with HEServiceNow: <a
          href="../../../../assets/files/How to use Hitachi Energy Service Now-CCRP updated.pdf" target="_blank"
          title="How to submit request"><b>How to submit request</b></a>)
      </li>
    </ul>
  </div>

  <div class="head-text">
    <h3>How to create a change request </h3>
    <ul type="square">
      <li class="special">
        Register your case directly in the
        <a href="https://eur04.safelinks.protection.outlook.com/?url=https%3A%2f%2fapps.powerapps.com%2fplay%2fe%2fdefault-7831e6d9-dc6c-4cd1-9ec6-1dc2b4133195%2fa%2fc41e6308-0613-4044-9e04-dc7f78f60aee%3FtenantId%3D7831e6d9-dc6c-4cd1-9ec6-1dc2b4133195&data=05%7C02%7Ctarun.chauhan%40hitachienergy.com%7C551a2244777843ca8cf708dcc5c9524c%7C7831e6d9dc6c4cd19ec61dc2b4133195%7C0%7C0%7C638602715678945274%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=5PaTRZe8ghGdKtDaCtaHIKy8brq%2BBJtKjw8uu%2fniLmA%3D&reserved=0"
          target="_blank"><b>link</b></a>
      </li>
    </ul>
  </div>

  <div class="head-text">
    <h3>MasterData  </h3>
    <ul type="square">
      <li class="special">
        If unit you are looking for does not appear it means it is not in Masterdata. You need to raise a service request
        <a href="https://hitachienergy.service-now.com/my_it?id=sc_cat_item&sys_id=b3582297db50b9d402974e48229619c8"
          title="Masterdata service request" target="_blank"><b>here</b></a>
      </li>
      <li class="special">
        How to raise Data Action request for master data related changes ? - please access the service now
        <a href="https://hitachipowergrids.sharepoint.com/sites/PGMasterData/SitePages/How-to-Report-an-Incident-or-Service-Action-for-Master-Data-Locations.aspx"
          title="Masterdata action request" target="_blank"><b>link</b></a>
      </li>
    </ul>
  </div>

  <div class="head-text">
    <h3>Gravity PG  </h3>
    <ul type="square">
      <li class="special">
        All tickets related to GIS/ICV/GRAVITY should be raised via
        <a href="https://hitachienergy.service-now.com/my_it" target="_blank"><b>link</b></a>
        <br>
        Application Name: GIS/ICV/GRAVITY <br>
        Service Offering: Master Data: Master Data Customer & Vendor (GIS)
      </li>
    </ul>
  </div>
</div>
