<!-- <toaster-container toaster-options="{}"> </toaster-container> -->
<form [formGroup]="adminForm">
    <div class="panel-body bg-white1 resolvingunit">
        <!-- <div class="row col-sm-12 tab-content-header">
            {{'admin_ru_rufilter_h' | translate}}
        </div> -->
        <!-- first row -->
        <div class="row">
        <div class="col-sm-4 marginForResponsive">
            <div class="lable-bold businessLabelClass">{{'admin_rufilter_division_lbl'|translate}}</div>
            <div class="businessInputClass">
                <p-dropdown [resetFilterOnHide]="true" [options]="division" optionValue="key" formControlName="business" optionLabel="value"
                [showClear]="true" [filter]="true" filterBy="value"  placeholder="Click or type to select Business" (onChange)="showSelectedHubCriteria()">
                </p-dropdown>
            </div>
        </div>
        <div class="col-sm-4 marginForResponsive">
            <div class="lable-bold businessActivityLabelClass">{{'admin_rufilter_ba_lbl'|translate}}</div>
            <div class="d-flex businessActivityButtonClass">
                <button class="btn btn-default btnallone btnFontSize allBtnClass businessAll" style="height:30px" value="null" role="button"
                    (click)="onAll()" role="button"
                    [style.backgroundColor]="this.btnColorChange0 ? '#d9d9d9': '#ffffff'">
                    <span class="fa" [ngClass]="this.btnColorChange0 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                    {{'admin_rufilter_ba_all_lbl'|translate}}
                </button>
                <button class="btn btn-default btnrightone border-start-0 btnFontSize allBtnClass businessLocal" style="height:30px" value="null" role="button"
                    (click)="onLocal()" role="button"
                    [style.backgroundColor]="this.btnColorChange1 ? '#d9d9d9': '#ffffff'">
                    <span class="fa" [ngClass]="this.btnColorChange1 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                    {{'admin_rufilter_ba_local_lbl'|translate}}
                </button>
                <button class="btn btn-default btnleftone border-start-0 btnFontSize allBtnClass businessGlobal" style="height:30px" value="null" role="button"
                    (click)="onGlobal()" role="button"
                    [style.backgroundColor]="this.btnColorChange2 ? '#d9d9d9': '#ffffff'">
                    <span class="fa" [ngClass]="this.btnColorChange2 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                    {{'admin_rufilter_ba_global_lbl'|translate}}
                </button>
            </div>
        </div>
        <div class="col-sm-4 marginForResponsive">
            <div class="lable-bold employeesLabelClass">{{'admin_rufilter_employees_lbl'|translate}}</div>
            <div class="resolvingUnitEmployeeData">
                <input type="hidden" data-placeholder="{{'rusearch_mod_employees_ph'|translate}}" />
                <p-dropdown formControlName="employee" [options]="filteredSubstitute" optionLabel="email" [showClear]="true"
                    optionValue="id" [filter]="true"  [autofocus]="false" filterPlaceholder="Enter min 3 characters..." (onFilter)="filterSubstitude($event)"
                    [autofocusFilter]="false" placeholder="Click or type to select employee" [resetFilterOnHide]="true">
                    <ng-template let-filteredSubstitute pTemplate="item">
                      <div class="flex align-items-center gap-2">
                            <div class="row">
                              <div class="col-8 text-bold"><strong>{{ filteredSubstitute?.name }}</strong></div>
                              <div class="col-4 text-end"><i>{{filteredSubstitute?.city}}({{filteredSubstitute.iso}})</i></div>
                            </div>
                            <div class="row mt-3">
                              <div class="col">{{filteredSubstitute?.email}}</div>
                            </div>
                      </div>
                  </ng-template>
                  </p-dropdown>
            </div>
        </div>
    </div>


        <!-- <div class="row mt-2">
            <div class="col-sm-4 lable-bold businessLabelClass">{{'admin_rufilter_division_lbl'|translate}}1</div>
            <div class="col-sm-4 lable-bold businessActivityLabelClass">{{'admin_rufilter_ba_lbl'|translate}}2</div>
            <div class="col-sm-4 lable-bold employeesLabelClass">{{'admin_rufilter_employees_lbl'|translate}}3</div>
        </div> -->
        <!-- <div class="row mt-0 display: block"> -->
           <!--  <div class="col-sm-4 businessInputClass">
                <p-dropdown [resetFilterOnHide]="true" [options]="division" optionValue="key" formControlName="business" optionLabel="value"
                    [filter]="true" filterBy="value" [showClear]="true" placeholder="Click or type to select Business" (onChange)="showSelectedHubCriteria()">
                </p-dropdown>
            </div> -->
            <!-- <div class="col-sm-4 d-flex businessActivityButtonClass">
                <button class="btn btn-default btnallone" style="height:30px" value="null" role="button"
                    (click)="onAll()" role="button"
                    [style.backgroundColor]="this.btnColorChange0 ? '#d9d9d9': '#ffffff'">
                    <span class="fa" [ngClass]="this.btnColorChange0 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                    {{'admin_rufilter_ba_all_lbl'|translate}}
                </button>
                <button class="btn btn-default btnrightone border-start-0" style="height:30px" value="null" role="button"
                    (click)="onLocal()" role="button"
                    [style.backgroundColor]="this.btnColorChange1 ? '#d9d9d9': '#ffffff'">
                    <span class="fa" [ngClass]="this.btnColorChange1 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                    {{'admin_rufilter_ba_local_lbl'|translate}}
                </button>
                <button class="btn btn-default btnleftone border-start-0" style="height:30px" value="null" role="button"
                    (click)="onGlobal()" role="button"
                    [style.backgroundColor]="this.btnColorChange2 ? '#d9d9d9': '#ffffff'">
                    <span class="fa" [ngClass]="this.btnColorChange2 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                    {{'admin_rufilter_ba_global_lbl'|translate}}
                </button>
            </div> -->
           <!--  <div class="col-sm-4 resolvingUnitEmployeeData">
                <input type="hidden" data-placeholder="{{'rusearch_mod_employees_ph'|translate}}" />
                <p-dropdown formControlName="employee" [options]="filteredSubstitute" optionLabel="email"
                    optionValue="id" [filter]="true"  [autofocus]="false" filterPlaceholder="Enter min 3 char..." (onFilter)="filterSubstitude($event)"
                    [autofocusFilter]="false" placeholder="Click or type to select employee" [resetFilterOnHide]="true">
                    <ng-template let-filteredSubstitute pTemplate="item">
                      <div class="flex align-items-center gap-2">
                            <div class="row">
                              <div class="col-8 text-bold"><strong>{{ filteredSubstitute?.name }}</strong></div>
                              <div class="col-4 text-end"><i>{{filteredSubstitute?.city}}({{filteredSubstitute.iso}})</i></div>
                            </div>
                            <div class="row mt-3">
                              <div class="col">{{filteredSubstitute?.email}}</div>
                            </div>
                      </div>
                  </ng-template>
                  </p-dropdown>
            </div> -->
        <!-- </div> -->
        <!-- second row -->
            <div class="row">
                <div class="col-sm-4 marginForResponsive">
                    <div class="lable-bold">{{'admin_rufilter_bu_lbl'|translate}}</div>
                    <div class="">
                        <p-dropdown [resetFilterOnHide]="true" [options]="bu" optionValue="key" formControlName="bu" optionLabel="value" [filter]="true"
                            filterBy="value" [showClear]="true" placeholder="Click or type to select BU">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-sm-4 marginForResponsive">
                    <div class="lable-bold">{{'admin_rufilter_region_lbl'|translate}}</div>
                    <div class="">
                        <p-dropdown [resetFilterOnHide]="true" [options]="regions" optionValue="key" formControlName="region" optionLabel="value"
                            [filter]="true" filterBy="value" [showClear]="true" placeholder="Click or type to select Region">
                        </p-dropdown>
                    </div>
                </div>
                <div class="col-sm-4 marginForResponsive">
                    <div class="lable-bold labelNone">&nbsp;</div>
                    <div class="d-flex">
                        <button class="btn btn-default btnall comboBtn roleButtons btnFontSize  allBtnClass" value="null" role="button" (click)="onprocessAll()"
                            [style.backgroundColor]="this.btnColorChangeprocess0 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChangeprocess0 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                                {{'admin_rufilter_emptype_all_lbl'|translate}}
                        </button>
                        <button class="btn btn-default btnright border-start-0 comboBtn roleButtonRU RUBTN btnFontSize allBtnClass" value="null" role="button" (click)="onprocessOwner()"
                            [style.backgroundColor]="this.btnColorChangeprocess1 ? '#d9d9d9': '#ffffff'">
                            <span class="fa"
                                [ngClass]="this.btnColorChangeprocess1 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                                <span class="buttonValue btnCircleORU">
                                {{'admin_rufilter_emptype_rupo_lbl'|translate}}
                            </span>
                        </button>
                        <button class="btn btn-default btnleft border-start-0 comboBtn roleButtons btnFontSize allBtnClass" value="null" role="button" (click)="onCoordinator()"
                            [style.backgroundColor]="this.btnColorChangeprocess2 ? '#d9d9d9': '#ffffff'">
                            <span class="fa btnCircleORU"
                                [ngClass]="this.btnColorChangeprocess2 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                                <span class="buttonValue">{{'admin_rufilter_emptype_coor_lbl'|translate}}</span>
                        </button>
                    </div>
                </div>
            </div>
       <!--  <div class="row mt-2 ">
            <div class="col-sm-4 lable-bold">{{'admin_rufilter_bu_lbl'|translate}}</div>
            <div class="col-sm-4 lable-bold">{{'admin_rufilter_region_lbl'|translate}}</div>
            <div class="col-sm-4 lable-bold"></div>
        </div>
        <div class="row mt-0">
            <div class="col-sm-4">
                <p-dropdown [resetFilterOnHide]="true" [options]="bu" optionValue="key" formControlName="bu" optionLabel="value" [filter]="true"
                    filterBy="value" [showClear]="true" placeholder="Click or type to select BU">
                </p-dropdown>
            </div>
            <div class="col-sm-4">
                <p-dropdown [resetFilterOnHide]="true" [options]="regions" optionValue="key" formControlName="region" optionLabel="value"
                    [filter]="true" filterBy="value" [showClear]="true" placeholder="Click or type to select Region">
                </p-dropdown>
            </div>
            <div class="col-sm-4 d-flex">
                <button class="btn btn-default btnall comboBtn" value="null" role="button" (click)="onprocessAll()"
                    [style.backgroundColor]="this.btnColorChangeprocess0 ? '#d9d9d9': '#ffffff'">
                    <span class="fa"
                        [ngClass]="this.btnColorChangeprocess0 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                    {{'admin_rufilter_emptype_all_lbl'|translate}}
                </button>
                <button class="btn btn-default btnright border-start-0 comboBtn" value="null" role="button" (click)="onprocessOwner()"
                    [style.backgroundColor]="this.btnColorChangeprocess1 ? '#d9d9d9': '#ffffff'">
                    <span class="fa"
                        [ngClass]="this.btnColorChangeprocess1 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                    {{'admin_rufilter_emptype_rupo_lbl'|translate}}
                </button>
                <button class="btn btn-default btnleft border-start-0 comboBtn" value="null" role="button" (click)="onCoordinator()"
                    [style.backgroundColor]="this.btnColorChangeprocess2 ? '#d9d9d9': '#ffffff'">
                    <span class="fa"
                        [ngClass]="this.btnColorChangeprocess2 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                    {{'admin_rufilter_emptype_coor_lbl'|translate}}
                </button>
            </div>
        </div> -->
        <!-- third row -->
        <div class="row">
            <div class="col-sm-4 marginForResponsive">
                <div class="lable-bold">{{'admin_rufilter_pg_lbl'|translate}}</div>
                <div class="">
                    <p-dropdown [resetFilterOnHide]="true" formControlName="pg" [options]="pg" optionValue="key" optionLabel="value" [filter]="true"
                        [autofocus]="false" filterBy="value" [showClear]="true" placeholder="Click or type to select PG">
                    </p-dropdown>
                </div>
            </div>
            <div class="col-sm-4 marginForResponsive">
                <div class="lable-bold">{{'admin_rufilter_country_lbl'|translate}}</div>
                <div class="">
                    <p-dropdown [resetFilterOnHide]="true" [options]="country" (click)="clearcountry($event)" optionValue="id" formControlName="country" optionLabel="name"
                        [filter]="true" [autofocus]="false" filterBy="name" [showClear]="true" multiple="true"
                        placeholder="Click or type to select Country" >
                    </p-dropdown>
                </div>
            </div>
            <div class="col-sm-4 marginForResponsive">
                <div class="lable-bold labelNone">&nbsp;</div>
                <div class="">
                    <div class="checkboxborder">
                        <input type="checkbox" formControlName="ruiamassigned" (click)="checkselected($event)" ng-disabled="isSearching"
                            value="issueClosedCheck" />
                        <span> {{ "admin_rufilter_onlymine_lbl" | translate }}.</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row mt-2">
            <div class="col-sm-4 lable-bold">{{'admin_rufilter_pg_lbl'|translate}}</div>
            <div class="col-sm-4 lable-bold">{{'admin_rufilter_country_lbl'|translate}}</div>
            <div class="col-sm-4 lable-bold"></div>
        </div>
        <div class="row mt-0">
            <div class="col-sm-4">
                <p-dropdown [resetFilterOnHide]="true" formControlName="pg" [options]="pg" optionValue="key" optionLabel="value" [filter]="true"
                    [autofocus]="false" filterBy="value" [showClear]="true" placeholder="Click or type to select PG">
                </p-dropdown>
            </div>
            <div class="col-sm-4">
                <p-dropdown [resetFilterOnHide]="true" [options]="country" (click)="clearcountry($event)" optionValue="id" formControlName="country" optionLabel="name"
                    [filter]="true" [autofocus]="false" filterBy="name" [showClear]="true" multiple="true"
                    placeholder="Click or type to select Country" >
                </p-dropdown>
            </div>
            <div class="col-sm-4">
                <div class="checkboxborder">
                    <input class="checkboxHover" type="checkbox" formControlName="ruiamassigned" (click)="checkselected($event)" ng-disabled="isSearching"
                        value="issueClosedCheck" />
                    <span> {{ "admin_rufilter_onlymine_lbl" | translate }}</span>
                </div>
            </div>
        </div> -->
        <!-- fourth row -->
        <div class="row">
            <div class="col-sm-4 marginForResponsive">
                <div class="lable-bold">
                    {{'admin_rufilter_assigned_lbl'|translate}}
                </div>
                <div class="d-flex">
                    <button class="btn btn-default btnallone comboBtn roleButtons allBtn btnFontSize allBtnClass" value="null" (click)="onClickAll()"
                        [style.backgroundColor]="this.btnColorChange3 ? '#d9d9d9': '#ffffff'">
                        <span class="fa" [ngClass]="this.btnColorChange3 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                        {{'admin_rufilter_assig_all_lbl'|translate}}

                    </button>

                    <button class="btn btn-default btnrightone border-start-0 comboBtn roleButtons assignBtn btnFontSize allBtnClass" value="true" (click)="onAssigned()"
                        [style.backgroundColor]="this.btnColorChange4 ? '#d9d9d9': '#ffffff'">
                        <span class="fa btnCircleORU" [ngClass]="this.btnColorChange4 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                        <span class="buttonValue">{{'admin_rufilter_assig_withpeople_lbl'|translate}}</span>
                    </button>

                    <button class="btn btn-default btnleftone border-start-0 comboBtn roleButtons notAssignBtn btnFontSize allBtnClass" value="false" (click)="onNotAssigned()"
                        [style.backgroundColor]="this.btnColorChange5 ? '#d9d9d9': '#ffffff'">
                        <span class="fa btnCircleORU" [ngClass]="this.btnColorChange5 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                        <span class="buttonValue">{{'admin_rufilter_assig_nothavasoc_lbl'|translate}}</span>
                    </button>
                </div>
            </div>
            <div class="col-sm-4 marginForResponsive">
                <div class="lable-bold">
                    {{'admin_rufilter_city_lbl'|translate}}
                </div>
                <div class="">
                    <p-dropdown [resetFilterOnHide]="true" formControlName="city" [options]="getCityList" optionLabel="name" optionValue="id"
                        [filter]="true" [autofocus]="false" filterBy="name" [showClear]="true"
                        placeholder="Select a city"></p-dropdown>
                </div>
            </div>
            <div class="col-sm-4 marginForResponsive">
                <div class="labelNone">&nbsp;</div>
                <div class="">
                    <div class="checkboxborder">
                        <span>
                            <input type="checkbox" formControlName="ruwithoutactive" (click)="checkselectedwithoutactive($event)"
                                value="electedwithoutactive" />
                            <span> {{ "admin_rufilter_only_without_active_ppl_lbl" | translate }}.</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row mt-2">
            <div class="col-sm-4 lable-bold">
                {{'admin_rufilter_assigned_lbl'|translate}}
            </div>
            <div class="col-sm-4 lable-bold">
                {{'admin_rufilter_city_lbl'|translate}}
            </div>
            <div class="col-sm-4"></div>
        </div>
        <div class="row mt-0">
            <div class="col-sm-4 d-flex">
                <button class="btn btn-default btnallone comboBtn" value="null" (click)="onClickAll()"
                    [style.backgroundColor]="this.btnColorChange3 ? '#d9d9d9': '#ffffff'">
                    <span class="fa" [ngClass]="this.btnColorChange3 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                    {{'admin_rufilter_assig_all_lbl'|translate}}

                </button>

                <button class="btn btn-default btnrightone border-start-0 comboBtn" value="true" (click)="onAssigned()"
                    [style.backgroundColor]="this.btnColorChange4 ? '#d9d9d9': '#ffffff'">
                    <span class="fa" [ngClass]="this.btnColorChange4 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                    {{'admin_rufilter_assig_withpeople_lbl'|translate}}
                </button>

                <button class="btn btn-default btnleftone border-start-0 comboBtn" value="false" (click)="onNotAssigned()"
                    [style.backgroundColor]="this.btnColorChange5 ? '#d9d9d9': '#ffffff'">
                    <span class="fa" [ngClass]="this.btnColorChange5 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                    {{'admin_rufilter_assig_nothavasoc_lbl'|translate}}
                </button>
            </div>
            <div class="col-sm-4 ">
                <p-dropdown [resetFilterOnHide]="true" formControlName="city" [options]="getCityList" optionLabel="name" optionValue="id"
                    [filter]="true" [autofocus]="false" filterBy="name" [showClear]="true"
                    placeholder="Select a city"></p-dropdown>
            </div>
            <div class="col-sm-4">
                <div class="checkboxborder">
                    <span>
                        <input class="checkboxHover" type="checkbox" formControlName="ruwithoutactive" (click)="checkselectedwithoutactive($event)"
                            value="electedwithoutactive" />
                        <span> {{ "admin_rufilter_only_without_active_ppl_lbl" | translate }}</span>
                    </span>
                </div>
            </div>
        </div> -->
        <!-- fifth row -->
        <div class="row">
            <div class="col-sm-4">
                <div class="labelNone">&nbsp;</div>
                <div class="labelNone">&nbsp;</div>
            </div>
            <div class="col-sm-4 marginForResponsive">
                <div class="lable-bold">
                    {{'admin_rufilter_activity_lbl'|translate}}
                </div>
                <div class="">
                    <p-dropdown [resetFilterOnHide]="true" [options]="activity" optionValue="key" formControlName="activity" optionLabel="value"
                        [filter]="true" filterBy="value" [showClear]="true"
                        placeholder="Click or type to select activity"></p-dropdown>
                </div>
            </div>
            <div class="col-sm-4 marginForResponsive">
                <div class="labelNone">&nbsp;</div>
                <div class="d-flex">
                    <button class="btn btn-default btnull comboBtn roleButtons btnFontSize allBtnClass" value="null" (click)="onAllActive()"
                        [style.backgroundColor]="this.btnColorChangeactive1 ? '#d9d9d9': '#ffffff'">
                        <span class="fa"
                            [ngClass]="this.btnColorChangeactive1 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                        {{'admin_rufilter_act_all_lbl'|translate}}
                    </button>
                    <button class="btn btn-default btn-right border-start-0 comboBtn roleButtons btnFontSize allBtnClass" value="null" (click)="onActive()"
                        [style.backgroundColor]="this.btnColorChangeactive0 ? '#d9d9d9': '#ffffff'">

                        <span class="fa btnCircleORU"
                            [ngClass]="this.btnColorChangeactive0 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>

                            <span class="buttonValue">
                                {{'admin_rufilter_act_active_lbl'|translate}}
                            </span>
                    </button>
                    <button class="btn btn-default btn-left border-start-0 comboBtn roleButtons btnFontSize allBtnClass" value="null" (click)="onInActive() "
                        [style.backgroundColor]="this.btnColorChangeactive2 ? '#d9d9d9': '#ffffff'">
                        <span class="fa btnCircleORU"
                            [ngClass]="this.btnColorChangeactive2 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                            <span class="buttonValue">{{'admin_rufilter_act_inactive_lbl'|translate}}</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- <div class="row mt-2">
            <div class="col-sm-4"></div>
            <div class="col-sm-4 lable-bold">
                {{'admin_rufilter_activity_lbl'|translate}}
            </div>
            <div class="col-sm-4"></div>
        </div>
        <div class="row mt-0">
            <div class="col-sm-4"></div>
            <div class="col-sm-4">
                <p-dropdown [resetFilterOnHide]="true" [options]="activity" optionValue="key" formControlName="activity" optionLabel="value"
                    [filter]="true" filterBy="value" [showClear]="true"
                    placeholder="Click or type to select activity"></p-dropdown>
            </div>
            <div class="col-sm-4 d-flex">
                <button class="btn btn-default btnull comboBtn" value="null" (click)="onAllActive()"
                    [style.backgroundColor]="this.btnColorChangeactive1 ? '#d9d9d9': '#ffffff'">
                    <span class="fa"
                        [ngClass]="this.btnColorChangeactive1 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                    {{'admin_rufilter_act_all_lbl'|translate}}
                </button>
                <button class="btn btn-default btn-right border-start-0 comboBtn" value="null" (click)="onActive()"
                    [style.backgroundColor]="this.btnColorChangeactive0 ? '#d9d9d9': '#ffffff'">

                    <span class="fa"
                        [ngClass]="this.btnColorChangeactive0 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>


                    {{'admin_rufilter_act_active_lbl'|translate}}

                </button>
                <button class="btn btn-default btn-left border-start-0 comboBtn" value="null" (click)="onInActive() "
                    [style.backgroundColor]="this.btnColorChangeactive2 ? '#d9d9d9': '#ffffff'">
                    <span class="fa"
                        [ngClass]="this.btnColorChangeactive2 ?  'fa-check-circle-o' : 'fa-circle-o' "></span>
                    {{'admin_rufilter_act_inactive_lbl'|translate}}
                </button>
            </div>
        </div> -->
        <!-- till here changes made for HTML responsiveness -->

        <div class="row mt-2">
            <div class="col-sm-4"></div>
            <div class="col-sm-4"></div>
            <div class="col-sm-4 div-align-items-right">
                <button class="input widgetButtonLarge BtnRU btnFontSize" (click)="clear()">{{'admin_ru_search_clear_btn' |
                    translate |uppercase}}</button>

                <button
                [disabled]="
                        !(this.adminForm.get('business').value != null && this.adminForm.get('business').value != '') &&
                        !(this.adminForm.get('region').value != null && this.adminForm.get('region').value != '') &&
                        !(this.adminForm.get('bu').value != null && this.adminForm.get('bu').value != '') &&
                        !(this.adminForm.get('pg').value != null && this.adminForm.get('pg').value != '') &&
                        !(this.adminForm.get('country').value != null && this.adminForm.get('country').value != '') &&
                        !(this.adminForm.get('city').value != null && this.adminForm.get('city').value != '') &&
                         !(this.adminForm.get('employee').value != null && this.adminForm.get('employee').value != '') &&
                        !(this.adminForm.get('activity').value != null && this.adminForm.get('activity').value != '') &&
                        (this.adminForm.get('ruiamassigned').value != true) && (this.adminForm.get('ruwithoutactive').value != true)"
                class="widgetButtonLarge BtnRU ms-1 " (click)="search()">{{'admin_ru_search_search_btn' |
                    translate |uppercase}}
                </button>

            </div>

        </div>


        <div class="row mt-2" *ngIf="showExportToExcel">
            <div class="col-sm-4"></div>
            <div class="col-sm-4"></div>
            <div class="col-sm-4 div-align-items-right">

                <div class="forExportToExcel">
                    <span class="exportcls cursor-local" onMouseOver="this.style.color='#ff0000'"
                        onMouseOut="this.style.color='#000000'" container="body" *ngIf="!IsHubsExcelGenerating"
                        (click)="clickExportToExcel()">
                        <span class="glyphicon glyphicon-save export-btn"></span>&nbsp;Export to excel
                    </span>
                    <span *ngIf="IsHubsExcelGenerating" class="forExportToExcel">
                        <i class="fa fa-circle-o-notch fa-spin"></i>&nbsp;Generating...
                    </span>
                </div>
            </div>

        </div>

        <!-- Card details start -->
        <div class="row">
            <div *ngIf="noResult" class="forNoResultText">
                {{noResultInfo}}
            </div>
            <div *ngIf="
            !(this.adminForm.get('business').value != null && this.adminForm.get('business').value != '') &&
            !(this.adminForm.get('region').value != null && this.adminForm.get('region').value != '') &&
            !(this.adminForm.get('bu').value != null && this.adminForm.get('bu').value != '') &&
            !(this.adminForm.get('pg').value != null && this.adminForm.get('pg').value != '') &&
            !(this.adminForm.get('country').value != null && this.adminForm.get('country').value != '') &&
            !(this.adminForm.get('city').value != null && this.adminForm.get('city').value != '') &&
             !(this.adminForm.get('employee').value != null && this.adminForm.get('employee').value != '') &&
            !(this.adminForm.get('activity').value != null && this.adminForm.get('activity').value != '') &&
            (this.adminForm.get('ruiamassigned').value != true) && (this.adminForm.get('ruwithoutactive').value != true)" class="forNoResultText">
                {{'admin_ru_selectsearchcriteria_msg' | translate }}
              </div>
            <div *ngIf="hideshowcard">

                <p-card>
                    <div class="flex justify-content-center cardClassResolvinUnit">
                        <div class="row">
                            <div class="col-md-2 col-sm-3 ps-0 pe-0 cardClassResolvinUnit" style="width: 47%; margin-left: 25px;"
                                *ngFor="let hubData of hubDetailsAsPerPagination; let ind = index">
                                <p-card class="p-card p-component no-padding-card mt-5 mb-5 mx-5"
                                    [style]="{ width: '100%' }">
                                    <ng-template pTemplate="header">
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                <div class="cardFirstLine">
                                                    <span class="fa admin-link"></span>
                                                    <div class="address-details-card">
                                                        <span *ngIf="flagFromSearch">
                                                            <i *ngIf="hubData.isInTransitionPeriod"
                                                                class="glyphicon glyphicon-info-sign" onmouseover=""
                                                                style="cursor: default; color: #ffb500;"
                                                                [pTooltip]="admin_ru_details_intransitionperiod_tooltip"
                                                                [escape]="false" tooltipPosition="top"
                                                                tooltipStyleClass="toolTipClass"
                                                                tooltip-trigger="mouseenter"
                                                                tooltip-placement="top"></i></span>

                                                            <i *ngIf="!hubData.isActive" class="fa fa-ban"
                                                            [pTooltip]="RUInactiveTooltip"
                                                                    [escape]="false" tooltipPosition="top"
                                                                    tooltipStyleClass="toolTipClass"
                                                                style="color:#ff0026;" tooltip-trigger="mouseenter"
                                                                tooltip-placement="top"></i>&nbsp;

                                                        <input *ngIf="flagFromSearch && hubData.canManage" type="checkbox" class="checkboxHover" value="check"
                                                            [checked]="hubData.selectedcheck" style="margin-right: 5px;"
                                                            (click)="checkselectedval($event,hubData , ind )">
                                                        <span class="glyphicon glyphicon-home"
                                                            style="margin-right: 5px;"></span>
                                                        <span>
                                                            <strong>{{hubData.divCode}}
                                                                <span *ngIf="hubData.buCode">, </span>
                                                                {{hubData.buCode}}
                                                                <span *ngIf="hubData.pgCode">,
                                                                </span>{{hubData.pgCode}}
                                                                {{hubData.pgName}}
                                                            </strong>
                                                        </span>
                                                        <div class="resolvingCardCss">
                                                            <span>
                                                                <strong *ngIf="hubData.balId">{{hubData.balId}}</strong>
                                                                <strong *ngIf="hubData.abacusCode">,</strong>
                                                            </span>
                                                            <span>
                                                                <strong *ngIf="hubData.abacusCode">
                                                                    {{hubData.abacusCode}}, {{ hubData.laName
                                                                    }}</strong>
                                                                <strong *ngIf="hubData.descriptiveName">,</strong>
                                                            </span>
                                                            <span>
                                                                <strong *ngIf="hubData.descriptiveName">
                                                                    {{hubData.descriptiveName}}</strong>
                                                                <strong *ngIf="hubData.pllName">,</strong>
                                                            </span>
                                                            <span>
                                                                <strong *ngIf="hubData.pllName">
                                                                    {{hubData.pllName}}</strong>
                                                            </span>
                                                            <span>
                                                                <strong *ngIf="hubData.isGlobal">
                                                                    , GLOBAL</strong>
                                                            </span>
                                                        </div>

                                                        <div class="resolvingCardAddressCss"
                                                            *ngIf="!hubData.isGlobal || !(hubData.address === null)">
                                                            <strong><small>{{hubData?.address?.city}},
                                                                    {{hubData?.address?.address}},
                                                                    {{hubData?.address?.countryCode}}</small></strong>
                                                            <a [pTooltip]="googlemap" [escape]="false" tooltipPosition="top"
                                                            tooltipStyleClass="tooltippgrca" href="https://maps.google.pl/?q= {{hubData?.address?.city}}, {{hubData?.address?.street}},                       {{hubData?.address?.countryCode}}"
                                                                target="_blank" class="gray-link"
                                                                uib-tooltip="Google Maps view">
                                                                <i class="hitachiicon-location"> </i>
                                                            </a>
                                                        </div>
                                                        <div *ngIf="hubData.pgCodes">
                                                            <span>
                                                                <strong>{{'cc_admin_hub_included_pgs' | translate}}:
                                                                </strong>
                                                                {{hubData.pgCodes.join(', ')}}
                                                            </span>
                                                        </div>
                                                        <span *ngIf="flagFromSearch && hubData.canManage" class="navbar-right">
                                                            <button type="button"
                                                                class="btn btn-default btn-sm btn-xs-Branding addBtnRU"
                                                                (click)="showruAddPersonPopup(hubData,filteredResolvingUnitDetails,ind)">
                                                                {{'admin_hub_addperson_btn'|translate}}
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </h4>
                                        </div>

                                    </ng-template>

                                    <ng-template pTemplate="body">
                                        <div class="" style="margin-left: -30px;">
                                            <ul>
                                                <span class="whitewell">{{'admin_ru_person_ruprocessowner_lbl' |
                                                    translate}}:</span>
                                                <!-- {{ruProcessOwnersAll | json}} -->

                                                <div *ngFor="let ruides of  hubData.ruProcessOwnerGeids">

                                                    <span *ngFor="let ruProcessOwnerdata of  ruPersonInfo">

                                                        <div class="row mt-2"
                                                            *ngIf="ruProcessOwnerdata.displayGeid==ruides || ruProcessOwnerdata.id == ruides">
                                                            <div class="col-sm-12 process-owner">
                                                                <span
                                                                    *ngIf="ruProcessOwnerdata.isActive==true && hubData.primaryRuProcessOwnerGeid==ruProcessOwnerdata.id"
                                                                    [pTooltip]="primaryRUCoordinatorTooltip"
                                                                    [escape]="false" tooltipPosition="top"
                                                                    tooltipStyleClass="toolTipClass"
                                                                    style="color: #FF0026;"
                                                                    class="glyphicon glyphicon-user"></span>

                                                                <span
                                                                    *ngIf="ruProcessOwnerdata.isActive==true && hubData.primaryRuProcessOwnerGeid!=ruProcessOwnerdata.id"
                                                                    class="glyphicon glyphicon-user"></span>

                                                                <span *ngIf="ruProcessOwnerdata.isActive==false" class="fa fa-ban"
                                                                    style="color: red"
                                                                    [pTooltip]="admin_ru_person_incative_tooltip"
                                                                    [escape]="false" tooltipPosition="top"
                                                                    tooltipStyleClass="toolTipClass"
                                                                    tooltip-trigger="mouseenter"
                                                                    tooltip-placement="top"></span>

                                                                <span onMouseOver="this.style.color='#ff0000'"
                                                                    onMouseOut="this.style.color='#000000'"
                                                                    [ngbPopover]="popContent"
                                                                    [popoverTitle]="popoverTitle"
                                                                    container="body" class="processOwner"
                                                                    (click)="gethubDataProcess(ruProcessOwnerdata, hubData)">
                                                                    {{ruProcessOwnerdata.name}}
                                                                </span>
                                                                <small>{{'admin_rudetails_ruprocowner_lbl' |
                                                                    translate}}</small>
                                                                <span *ngIf="flagFromSearch && hubData.canManage">

                                                                    <span
                                                                        *ngIf="hubData.ruProcessOwnerGeids && hubData.ruProcessOwnerGeids.length==1"
                                                                        class="glyphicon glyphicon-trash"
                                                                        [pTooltip]="deleteProcessOwnerTooltip"
                                                                        [escape]="false" tooltipPosition="top"
                                                                        tooltipStyleClass="toolTipClass"
                                                                        tooltip-trigger="mouseenter"
                                                                        tooltip-placement="top"></span>
                                                                    <span>&nbsp;</span>
                                                                    <span
                                                                        *ngIf="hubData.ruProcessOwnerGeids && hubData.ruProcessOwnerGeids.length>1"
                                                                        class="glyphicon glyphicon-trash"
                                                                        [pTooltip]="deleteProcessOwnerTooltipcheck"
                                                                        [escape]="false" tooltipPosition="top"
                                                                        tooltipStyleClass="toolTipClass"
                                                                        tooltip-trigger="mouseenter"
                                                                        tooltip-placement="top"
                                                                        (click)="showrudeletePersonPopup(ruProcessOwnerdata, hubData,1)"></span>
                                                                    <span>&nbsp;</span>
                                                                    <span>&nbsp;</span>
                                                                    <span *ngIf="ruProcessOwnerdata.isActive==true"
                                                                        class="glyphicon glyphicon-ok-sign"
                                                                        onMouseOver="this.style.color='#ff0000'"
                                                                        onMouseOut="this.style.color='#000000'"
                                                                        container="body"
                                                                        [pTooltip]="addprimaryRUCoordinatorTooltip"
                                                                        [escape]="false" tooltipPosition="top"
                                                                        tooltipStyleClass="toolTipClass"
                                                                        tooltip-trigger="mouseenter"
                                                                        tooltip-placement="top"
                                                                        (click)="showaddprimaryruPersonPopup(ruProcessOwnerdata,hubData,1)"></span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div *ngFor="let rucoordinatid of  hubData.coordinatorGeids">

                                                    <span *ngFor="let ruPcoordinatOwnerdata of ruPersonInfo">

                                                        <div class="row mt-2"
                                                            *ngIf="ruPcoordinatOwnerdata?.displayGeid==rucoordinatid || ruPcoordinatOwnerdata?.id==rucoordinatid">
                                                            <div class="col-sm-12 process-owner">


                                                                <span
                                                                    *ngIf="ruPcoordinatOwnerdata.isActive==true && hubData.primaryCoordinatorGeid==ruPcoordinatOwnerdata.id"
                                                                    [pTooltip]="primaryRUCoordinatorTooltip"
                                                                    [escape]="false" tooltipPosition="top"
                                                                    tooltipStyleClass="toolTipClass"
                                                                    style="color: #FF0026;"
                                                                    class="glyphicon glyphicon-user"></span>

                                                                <span
                                                                    *ngIf="ruPcoordinatOwnerdata.isActive==true && hubData.primaryCoordinatorGeid!=ruPcoordinatOwnerdata.id"
                                                                    class="glyphicon glyphicon-user"></span>

                                                                    <span *ngIf="ruPcoordinatOwnerdata.isActive==false" class="fa fa-ban"
                                                                    style="color: red"
                                                                    [pTooltip]="admin_ru_person_incative_tooltip"
                                                                    [escape]="false" tooltipPosition="top"
                                                                    tooltipStyleClass="toolTipClass"
                                                                    tooltip-trigger="mouseenter"
                                                                    tooltip-placement="top"></span>
                                                                <span>&nbsp;</span>


                                                                <span onMouseOver="this.style.color='#ff0000'"
                                                                    onMouseOut="this.style.color='#000000'"
                                                                    [ngbPopover]="popContent"
                                                                    [popoverTitle]="popoverTitle" [placement]="'right'"
                                                                    container="body" class="processOwner"
                                                                    (click)="gethubDataProcess(ruPcoordinatOwnerdata, hubData)">{{ruPcoordinatOwnerdata.name}}</span>
                                                                <span>&nbsp;</span>

                                                                <small class="coordinatorClass">{{'admin_rudetails_rucoord_lbl' |
                                                                    translate}}</small>


                                                                <span *ngIf="flagFromSearch && hubData.canManage">
                                                                    <span class="glyphicon glyphicon-trash"
                                                                        (click)="showrudeletePersonPopup(ruPcoordinatOwnerdata, hubData,2)"></span>

                                                                    <span>&nbsp;</span>
                                                                    <span>&nbsp;</span>
                                                                    <span class="glyphicon glyphicon-ok-sign" *ngIf="ruPcoordinatOwnerdata.isActive==true"
                                                                        onMouseOver="this.style.color='#ff0000'"
                                                                        onMouseOut="this.style.color='#000000'"
                                                                        container="body"
                                                                        [pTooltip]="addprimaryRUCoordinatorTooltip"
                                                                        [escape]="false" tooltipPosition="top"
                                                                        tooltipStyleClass="toolTipClass"
                                                                        (click)="showaddprimaryruPersonPopup(ruPcoordinatOwnerdata,hubData,2)"></span>
                                                                </span>
                                                            </div>

                                                        </div>

                                                    </span>

                                                </div>




                                                <!-- <span *ngFor="let ruPcoordinatOwnerdata of rucoordinatorsAll">
                                                    <div
                                                        *ngFor="let rucoordinatid of  ruPcoordinatOwnerdata[0].coordinatorResolvingUnitIds">

                                                        <div class="row mt-2" *ngIf="rucoordinatid==hubData.id">
                                                            <div class="col-sm-12">


                                                                <span
                                                                    *ngIf="hubData.isActive==true && hubData.primaryCoordinatorGeid==ruPcoordinatOwnerdata[0].id"
                                                                    [pTooltip]="primaryRUCoordinatorTooltip"
                                                                    [escape]="false" tooltipPosition="top"
                                                                    tooltipStyleClass="toolTipClass"
                                                                    style="color: #FF0026;"
                                                                    class="glyphicon glyphicon-user"></span>

                                                                <span
                                                                    *ngIf="hubData.isActive==true && hubData.primaryCoordinatorGeid!=ruPcoordinatOwnerdata[0].id"
                                                                    class="glyphicon glyphicon-user"></span>
                                                                <span>&nbsp;</span>

                                                                <span *ngIf="hubData.isActive==false" class="fa fa-ban"
                                                                    onMouseOver="this.style.color='#ff0000'"
                                                                    onMouseOut="this.style.color='#000000'"
                                                                    container="body"
                                                                    [pTooltip]="admin_ru_person_incative_tooltip"
                                                                    [escape]="false" tooltipPosition="top"
                                                                    tooltipStyleClass="toolTipClass"
                                                                    tooltip-trigger="mouseenter"
                                                                    tooltip-placement="top"></span>



                                                                <span onMouseOver="this.style.color='#ff0000'"
                                                                    onMouseOut="this.style.color='#000000'"
                                                                    [ngbPopover]="popContent"
                                                                    [popoverTitle]="popoverTitle" [placement]="'right'"
                                                                    container="body"
                                                                    (click)="gethubDataProcess(ruPcoordinatOwnerdata[0], hubData)">{{ruPcoordinatOwnerdata[0].name}}</span>
                                                                <span>&nbsp;</span>

                                                                <small>{{'admin_rudetails_rucoord_lbl' |
                                                                    translate}}</small>



                                                                <span class="glyphicon glyphicon-trash"
                                                                    (click)="showrudeletePersonPopup(ruPcoordinatOwnerdata[0], hubData,2)"></span>

                                                                <span>&nbsp;</span>
                                                                <span class="glyphicon glyphicon-ok-sign"
                                                                    onMouseOver="this.style.color='#ff0000'"
                                                                    onMouseOut="this.style.color='#000000'"
                                                                    container="body"
                                                                    [pTooltip]="addprimaryRUCoordinatorTooltip"
                                                                    [escape]="false" tooltipPosition="top"
                                                                    tooltipStyleClass="toolTipClass"
                                                                    (click)="showaddprimaryruPersonPopup(ruPcoordinatOwnerdata[0],hubData,2)"></span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </span> -->



                                            </ul>
                                        </div>

                                        <div class="float-end">
                                            <button role="button" class="btn btn-default btn-xs btnxs"
                                                (click)="showprocessowner(hubData)" [pTooltip]="admin_item_pg_po_tp"
                                                [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"
                                                tooltip-trigger="mouseenter" tooltip-placement="top">
                                                <i class="glyphicon glyphicon-user"></i>
                                                <i class="fa fa-angle-down" *ngIf="!hubData.showContacts"></i>
                                                <i class="fa fa-angle-up" *ngIf="hubData.showContacts"></i>
                                            </button>
                                        </div>

                                        <div class="row col-s-12 col-md-12 col-lg-12" *ngIf="hubData.showContacts">
                                            <span>
                                                {{'admin_hubdetails_hubprocowner_lbl' |translate}}:
                                            </span>
                                            <ul style="margin-bottom: 2px" class="list-unstyled-processOwner">
                                                <li *ngFor="let k of resolvingEmployeeInfo(ind); let i = index">
                                                    <span
                                                        [ngbPopover]="popContentProcessOwner"
                                                        [popoverTitle]="popoverTitleProcessOwner"
                                                        [placement]="'right'"
                                                        onMouseOver="this.style.color='#ff0000'"
                                                        onMouseOut="this.style.color='#000000'"
                                                        (click)="getProcessOwnerclick(k.id, i)"
                                                        class="processOwner">

                                                        {{k?.name ? k.name : null }}
                                                        {{this.getprocessownersDetails(this.filteredprocessownersDetails[i]?.res[i])}}

                                                </span>
                                            </li>
                                            </ul>
                                        </div>
                                    </ng-template>

                                    <ng-template pTemplate="footer">

                                    </ng-template>

                                </p-card>

                                <!-- Hover Popup start -->

                                <ng-template #popoverTitle>

                                    <span class="popover-title">
                                        {{ this.getrubsObjinfo.name }}({{this.getrubsObjinfo.displayGeid}})
                                        <!-- {{hubData.address.city}}, {{hubData.address.address}} -->
                                    </span>

                                </ng-template>

                                <ng-template #popContent style="position: relative">

                                    <div>
                                        <div>
                                            <!-- {{'admin_hubdetails_hubprocowner_lbl' |translate}}: -->
                                            <span>
                                                {{this.getrubsObjinfo.address.countryCode}},
                                                {{this.getrubsObjinfo.address.address}}
                                            </span>
                                            <a href="https://maps.google.pl/?q={{ this.getrubsObjinfo.address.countryCode}},  {{this.getrubsObjinfo.address.address}}"
                                                target="_blank" class="gray-link">
                                                <i class="hitachiicon-location"> </i>
                                            </a>
                                            <br>
                                            <span *ngIf="getrubsObjinfo.email">
                                                <i class="glyphicon glyphicon-envelope"></i>&nbsp;
                                                <a class="mail" onMouseOver="this.style.color='#ff0000'"
                                                    onMouseOut="this.style.color='#000000'"
                                                    href="mailto:{{ this.getrubsObjinfo.email}}">{{this.getrubsObjinfo?.email}}</a>
                                            </span>
                                            <br>
                                            <span *ngIf="getrubsObjinfo.phone">
                                                <i class="glyphicon glyphicon-phone"></i>&nbsp;
                                                <a class="phone" onMouseOver="this.style.color='#ff0000'"
                                                    onMouseOut="this.style.color='#000000'"
                                                    href="tel:{{this.getrubsObjinfo.phone}}">{{this.getrubsObjinfo.phone}}</a>
                                            </span>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    <br>
                                    <span> <strong><b style="font-weight: bold;">Associated RUs:</b></strong>

                                        <div class="col-sm-7">
                                            <p style="background-color: #f7f7f7;">
                                                {{'admin_hubdetails_hubprocowner_lbl' |translate}}
                                                ({{this.iscountprocessorowner}})&nbsp;
                                                <!-- <i (click)="getrubsObjinfo.phone = !getrubsObjinfo.phone"
                                                    *ngIf="getrubsObjinfo.phone" class="fa fa-angle-double-up"
                                                    title="Expand"></i> -->
                                            </p>
                                        </div>
                                        <div class="row mt-2"
                                            *ngFor="let  ruProcessOwner of ruProcessOwnerResolvingUnitdata">

                                            <div class="col-sm-7">
                                                <!-- <p style="background-color: #f7f7f7;">
                                                    {{'admin_hubdetails_hubprocowner_lbl' |translate}}
                                                    ({{this.iscountprocessorowner}})&nbsp;

                                                </p> -->
                                            </div>
                                            <div >
                                                <small class="ng-binding">
                                                    {{ruProcessOwner.divCode}},
                                                    {{ruProcessOwner.buCode}},
                                                    {{ruProcessOwner.pgCode}}
                                                    {{' ' }}
                                                    {{ruProcessOwner.balId}},
                                                    {{ruProcessOwner.abacusCode}},

                                                </small>
                                                <small class="ng-binding">
                                                    {{ruProcessOwner.laName}},
                                                    {{' ' }}
                                                    {{ruProcessOwner.address.city}},
                                                    {{ruProcessOwner.address.address}}

                                                    {{ruProcessOwner.address.countryCode}},
                                                </small>
                                            </div>
                                        </div>
                                        <p style="background-color: #f7f7f7;" >
                                            Coordinator&nbsp;({{this.iscoordinaterowner}})

                                        </p>
                                        <div class="row mt-2"
                                            *ngFor="let  rucoordinatorProcessOwner of coordinatorResolvingUnitdata">


                                            <div class="col-sm-6">
                                                <!-- <p style="background-color: #f7f7f7;">coordinator
                                                    ({{this.iscoordinaterowner}})&nbsp;

                                                </p> -->
                                            </div>
                                            <div>
                                                <small class="ng-binding">
                                                    {{rucoordinatorProcessOwner.divCode}},
                                                    {{rucoordinatorProcessOwner.buCode}},
                                                    {{rucoordinatorProcessOwner.pgCode}}
                                                    {{' ' }}
                                                    {{rucoordinatorProcessOwner.balId}},
                                                    {{rucoordinatorProcessOwner.abacusCode}},
                                                </small>
                                                <small class="ng-binding">
                                                    {{rucoordinatorProcessOwner.laName}},
                                                    {{' ' }},
                                                    {{rucoordinatorProcessOwner.address.city}},
                                                    {{rucoordinatorProcessOwner.address.address}}
                                                    {{rucoordinatorProcessOwner.address.countryCode}},
                                                </small>
                                            </div>
                                        </div>
                                    </span>
                                </ng-template>
                                <!-- Hover Popup End -->
                                <!-- process owner start hover  -->

                                <!-- {{this.getProcesOwnerhoverdata | json}} -->

                                <ng-template #popoverTitleProcessOwner>
                                    <span class="popover-title popoverTitle">
                                        {{ this.getProcesOwnerhoverdata?.name }}
                                        ({{this.getProcesOwnerhoverdata?.displayGeid}})
                                    </span>
                                </ng-template>
                                <ng-template #popContentProcessOwner style="position: relative">
                                        <div style="margin-top: -12px;">
                                            <span class="popoverCountry">
                                                {{this.getProcesOwnerhoverdata?.address?.countryCode}},
                                                {{this.getProcesOwnerhoverdata?.address?.city}},
                                                {{this.getProcesOwnerhoverdata?.address?.address}}
                                            </span>
                                            <span>
                                                <a href="https://maps.google.pl/?q={{ this.getProcesOwnerhoverdata?.address?.countryCode}}, {{ this.getProcesOwnerhoverdata?.address?.city}}, {{this.getProcesOwnerhoverdata?.address?.address}}"
                                                    target="_blank" class="gray-link" >

                                                    <i class="hitachiicon-location" > </i>
                                                </a>
                                            </span>
                                            <br>
                                            <span *ngIf=" this.getProcesOwnerhoverdata?.email">
                                                <i class="glyphicon glyphicon-envelope"></i>&nbsp;
                                                <a class="mail popoverMail" onMouseOver="this.style.color='#ff0000'"
                                                    onMouseOut="this.style.color='#000000'"
                                                    href="mailto:{{ this.getProcesOwnerhoverdata?.email}}">{{this.getProcesOwnerhoverdata?.email}}</a>
                                            </span>
                                            <br>

                                            <span *ngIf=" this.getProcesOwnerhoverdata?.phone">
                                                <i class="glyphicon glyphicon-phone"></i>&nbsp;
                                                <a class="phone popoverPhone" onMouseOver="this.style.color='#ff0000'"
                                                    onMouseOut="this.style.color='#000000'"
                                                    href="tel:{{this.getProcesOwnerhoverdata?.phone}}">{{this.getProcesOwnerhoverdata?.phone}}</a>
                                            </span>

                                        </div>
                                </ng-template>
                                <!-- process owner End hover  -->
                            </div>
                        </div>
                        <!-- Card details End -->
                    </div>
                    <div *ngIf="showExportToExcel" class="row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-4 div-align-items-center">
                            <ul class="pagination">
                                <li class="{{hasPrevious()?'':'disabled'}}" (click)="previousClicked()"><a>&laquo;</a></li>
                                <li *ngIf="showFirstPage()" (click)="gotoPage(1)"><a>1</a></li>
                                <li *ngIf="showFirstPageSeparator()"><span>...</span></li>
                                <li *ngIf="hasPrevious()" (click)="gotoPage(previousPage())"><a>{{previousPage()}}</a></li>
                                <li class="active"><a>{{currentPage}}</a></li>
                                <li *ngIf="hasNext()" (click)="gotoPage(nextPage())"><a>{{nextPage()}}</a></li>
                                <li *ngIf="showLastPageSeparator()"><span>...</span></li>
                                <li *ngIf="showLastPage()" (click)="gotoPage(pagesCount)"><a>{{pagesCount}}</a></li>
                                <li class="{{hasNext()?'':'disabled'}}"><a (click)="nextClicked()">&raquo;</a></li>
                            </ul>
                        </div>
                        <div class="col-sm-4"></div>
                    </div>
                </p-card>
            </div>
        </div>
    </div>
</form>
