import { Component, OnInit, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LazyLoadEvent, PrimeNGConfig } from 'primeng/api';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
@Component({
  selector: 'app-bal-idselection-pop-up',
  templateUrl: './bal-idselection-pop-up.component.html',
  styleUrls: ['./bal-idselection-pop-up.component.css']
})
export class BalIDSelectionPopUpComponent implements OnInit {

  first: number = 0;
  firstIndex: number = 0;
  rows: number = 6;
  buPgList: any;
  resolvingPopForm!: FormGroup;
  textboxEnabled = false;
  searchText: any;
  radioValues = 'Search by business activity location';
  isChecked: boolean = false;
  filteredCompanyDetails: any;
  newArray: any = [];
  selectedIndex = -1;
  selectedCountry: String = "";
  states: Array<any> = [];
  cities: Array<any> = [];
  companyDetails: Array<any> = [];
  selectedBu: String = "";
  pgName: Array<any> = [];
  country!: string;
  city!: string;
  activityDropdownData: any;
  getCityList: any;
  getCountryList: any;
  divisionData: any;
  totalRecords: any = 0;
  dynamicPageNumber: any;
  loading: boolean = false;
  recentlySelected=true;
  viewClicked=false;
  constructor(private fb: FormBuilder, private resolvingUnitService: ResolvingUnitServiceService, private sessionStorage: SessionStorageServiceService, private primengConfig: PrimeNGConfig,
    private readonly dialogRef: DynamicDialogRef) {
    this.resolvingUnitService.getUsers().subscribe((response) => {
      // console.log(response)
      this.companyDetails = response;
    })
    //need to pass the current user geid here to get the data, right now using the geid of session response data coming null
    this.resolvingUnitService.getResolvingUnitsRecentlySelected(this.sessionStorage.getData('userInfo').geid).subscribe((response) => {
     //  console.log("currentusergeid",this.sessionStorage.getData('userInfo').geid);
      this.filteredCompanyDetails = response.data;
      this.totalRecords = response.count;
      this.loading = false;
    })

  }

  ngOnInit(): void {
    this.recentlySelected=true;
    this.resolvingPopForm = this.fb.group({
      balIdRadioBtn: [''],
      balId: new FormControl({ value: '', disabled: true }),
      searchByBusinessLocation: ['Search by business activity location'],
      businessActivity: [''],
      local: ['local'],
      global: [''],
      business: ['PG'],
      country: [''],
      bu: [''],
      city: [''],
      pg: [''],
      activity: [''],

    });

    this.resolvingUnitService.getDivision().subscribe((response) => {
      this.divisionData = response;
    })

    const division = 'PG';
    this.resolvingUnitService.getBuList(division).subscribe((res) => {
    if(res) {
      this.buPgList = res?.data;
     //  console.log('bupglist',this.buPgList);

      this.buPgList.map((rr: any) => {
        rr.name =  rr?.value
        rr.pgName = rr?.name
      })
    }
    })

    const pg = 'PG';
    this.resolvingUnitService.getActivityBal(pg).subscribe((resp) => {
      if (resp) {
        this.activityDropdownData = resp;
        // console.log('activity',resp);

      }
    })

    this.resolvingUnitService.getAllCountry().subscribe((country) => {
      if (country) {
        this.getCountryList = country;
      }
    });

    this.resolvingPopForm.get('country')?.valueChanges.subscribe((newValue) => {
      const countryCode = newValue;
      if (countryCode) {
        this.resolvingUnitService.getAllCity(countryCode).subscribe((cities) => {
          if (cities) {
            this.getCityList = cities;
          }
        });
      }
    })


    this.primengConfig.ripple = true;
    // console.log('ngOnit', this.resolvingPopForm)

this.resolvingPopForm.get('balIdRadioBtn')?.valueChanges.subscribe(value => {
  if (value === 'balRadio') {
    this.resolvingPopForm.get('balId')?.enable();

    this.resolvingPopForm.get('country')?.disable();
    this.resolvingPopForm.get('country')?.reset();

    this.resolvingPopForm.get('city')?.disable();
    this.resolvingPopForm.get('city')?.reset();

    this.resolvingPopForm.get('activity')?.disable();
    this.resolvingPopForm.get('activity')?.reset();

    this.resolvingPopForm.get('business')?.disable();
    this.resolvingPopForm.get('business')?.reset();

    this.resolvingPopForm.get('bu')?.disable();
    this.resolvingPopForm.get('bu')?.reset();

    this.resolvingPopForm.get('pg')?.disable();
    this.resolvingPopForm.get('pg')?.reset();

    this.resolvingPopForm.get('local')?.disable();

    this.resolvingPopForm.get('global')?.disable();
  }

  else if (value === 'Search by business activity location') {
    this.resolvingPopForm.get('country')?.enable();
    this.resolvingPopForm.get('city')?.enable();
    this.resolvingPopForm.get('activity')?.enable();
    this.resolvingPopForm.get('business')?.enable();
    this.resolvingPopForm.get('bu')?.enable();
    this.resolvingPopForm.get('pg')?.enable();
    this.resolvingPopForm.get('local')?.enable();
    this.resolvingPopForm.get('global')?.enable();
  }
})

this.resolvingPopForm.get('country')?.valueChanges.subscribe((value) => {
  this.resolvingPopForm.get('city')?.reset();
})

this.resolvingPopForm.get('local')?.valueChanges
  .subscribe((selectedLocal) => {
    if (selectedLocal == 'local') {
      this.resolvingPopForm.get('country')?.enable();
      this.resolvingPopForm.get('city')?.enable();
      this.resolvingPopForm.get('activity')?.enable();
      this.resolvingPopForm.get('business')?.enable();
      this.resolvingPopForm.get('bu')?.enable();
      this.resolvingPopForm.get('pg')?.enable();
    }
    else if (selectedLocal == 'global') {
      this.resolvingPopForm.get('country')?.disable();
      this.resolvingPopForm.get('city')?.disable();
      this.resolvingPopForm.get('activity')?.disable();
    }
  });

this.resolvingPopForm.get('balIdRadioBtn')?.valueChanges.subscribe((ballId) => {
  if (ballId == 'balRadio') {
    this.resolvingPopForm.get('business')?.disable();
    this.resolvingPopForm.get('bu')?.disable();
    this.resolvingPopForm.get('pg')?.disable();
    this.resolvingPopForm.get('country')?.disable();
    this.resolvingPopForm.get('city')?.disable();
    this.resolvingPopForm.get('activity')?.disable();
    this.resolvingPopForm.controls['business'].patchValue('(PG)Power Grids');
  }
});

this.resolvingPopForm.get('searchByBusinessLocation')?.valueChanges.subscribe((ballId) => {
  if (ballId == 'Search by business activity location') {
    this.resolvingPopForm.get('business')?.enable();
    this.resolvingPopForm.get('bu')?.enable();
    this.resolvingPopForm.get('pg')?.enable();
    this.resolvingPopForm.get('country')?.enable();
    this.resolvingPopForm.get('city')?.enable();
    this.resolvingPopForm.get('activity')?.enable();
    this.resolvingPopForm.get('local')?.enable();
  }
});

  }

tableheaders: string[] = ['BAL ID', 'ABACUS', 'MgmtOrg', 'Product group', 'Address', 'Activity'];

onRadioChange() {
  const radioValue = this.resolvingPopForm.get('radio')?.value;
  this.textboxEnabled = radioValue === 'enable';
  const textboxControl = this.resolvingPopForm.get('balId');
  if (this.textboxEnabled) {
    textboxControl?.enable();
  } else {
    textboxControl?.disable();
  }
  this.resolvingPopForm.controls['balIdRadioBtn'].reset();
  this.resolvingPopForm.controls['balId'].reset();
}

checkBlank(content) {
  if (content == undefined || content == null) {
    return '';
  }
  else {
    return content;
  }
}

enableTextBox() {
  this.textboxEnabled = true;
  this.resolvingPopForm.controls['searchByBusinessLocation'].reset()
}

  loadMoreResolvingData(event: any) {
    if(this.viewClicked==true){
    let skipRecord = 0;
    const pageNumber = (event.first + 6) / 6;
    skipRecord += event.first;
    this.loading = true;
    this.viewRuPopUpDetails(pageNumber, skipRecord);
  }
  }

  viewRuPopUpDetails(pageNumber=1, skipRecord=0) {
    this.recentlySelected= false;
    const bailIdValue = this.resolvingPopForm.get('balIdRadioBtn').value == 'balRadio' ? this.resolvingPopForm.controls['balId'].value.trim() : ''
    this.viewClicked=true;
      let formatedData = {
        "take": 6,
        "skip": skipRecord,
        "page": pageNumber,
        "pageSize":6 ,
        "sort": [
        {
           "field": "BAL_Id",
            "dir": "asc"
        }
        ],
        "filter": {
        "logic": "and",
        "filters" : [
       ]
        },
        "group": [],
        };
        // console.log("====",this.textboxEnabled,bailIdValue);
        if(this.textboxEnabled === false){
          formatedData.filter.filters.push({"field": "DivisionCode", "operator": "eq", "value": "PG","siteId": "string","siteName": "string"});
          formatedData.filter.filters.push({"field": "BUCode", "operator": "eq", "value": this.checkBlank(this.resolvingPopForm.controls['bu'].value),"siteId": "string","siteName": "string"});
          formatedData.filter.filters.push({"field": "PGCode", "operator": "eq", "value": this.checkBlank(this.resolvingPopForm.controls['pg'].value),"siteId": "string","siteName": "string"});
          if(this.resolvingPopForm.get('local').value=='local'){
            formatedData.filter.filters.push({"field": "ActivityCode", "operator": "eq", value: this.checkBlank(this.resolvingPopForm.controls['activity'].value),"siteId": "string","siteName": "string"});
            formatedData.filter.filters.push({"field": "CountryCode", "operator": "eq", value: this.checkBlank(this.resolvingPopForm.controls['country'].value),"siteId": "string","siteName": "string"});
            formatedData.filter.filters.push({"field": "City", "operator": "eq", value: this.checkBlank(this.resolvingPopForm.controls['city'].value),"siteId": "string","siteName": "string"});
          }
          else{
            formatedData.filter.filters.push({"field": "IsGlobal", "operator": "eq", "value": "true"});
          }
        }
        else{
          if(bailIdValue!=''){
            formatedData.filter.filters.push({"field": "BALId", "operator": "eq", value: this.checkBlank(bailIdValue)});
          }
        }

      this.loading = true;
      this.resolvingUnitService.getResolvingUnit(formatedData).subscribe((res:any)=>{
        this.filteredCompanyDetails = res.data;
        this.totalRecords = res.count;
        this.loading = false;
      }, (err) => {
        this.loading = false;
      })
  }


pgChangeData(count: any) {
  this.resolvingPopForm.controls['pg'].reset();
  if (this.selectedBu == "") {
    this.pgName = [];
  }

  const division = 'PG';
  const buValue = this.resolvingPopForm.controls['bu'].value;
  this.resolvingUnitService.getBuPgList(division, buValue).subscribe((res) => {
    if (res) {
      this.pgName = res.data;
    }
  })
}

  private map = new Map<string, string[]>([
  ['India', ['Delhi', 'Bangalore']],
  ['Qatar', ['Doha']],
  ['Afghanistan', ['Kabul']],
  ['Bahrain', ['Manama']],
  ['Bhutan', ['Kabul']],
])

  // get countries(): string[] {
  //   return Array.from(this.map.keys());
  // }

  get citiesData(): string[] | undefined {
  return this.map.get(this.country);
}

selectedRow(index: number) {
  this.selectedIndex = index;
}

selectAndClose() {
  this.dialogRef.close(this.filteredCompanyDetails[this.selectedIndex]);
}

localChanges() {
  this.resolvingPopForm.get('local')?.valueChanges
    .subscribe(selectedLocal => {
      if (selectedLocal = 'local') {
        this.resolvingPopForm.get('country')?.enable();
        this.resolvingPopForm.get('city')?.enable();
        this.resolvingPopForm.get('activity')?.enable();
        this.resolvingPopForm.get('business')?.enable();
        this.resolvingPopForm.get('bu')?.enable();
        this.resolvingPopForm.get('pg')?.enable();
      }
      else {
      }
    })
}

globalChanges() {
  this.resolvingPopForm.get('global')?.valueChanges
    .subscribe(selectedLocal => {
      if (selectedLocal = 'global') {
        this.resolvingPopForm.get('country')?.disable();
        this.resolvingPopForm.get('city')?.disable();
        this.resolvingPopForm.get('activity')?.disable();
      }
      else {
      }
    })
}

balIdChanges() {
  this.resolvingPopForm.get('balIdRadioBtn')?.valueChanges.subscribe((ballId) => {
    if (ballId) {
      this.resolvingPopForm.get('business')?.disable();
      this.resolvingPopForm.get('bu')?.disable();
      this.resolvingPopForm.get('pg')?.disable();

    }
  })
}

/* functionality of Paginator from primeng starts */
next() {
  this.first = this.first + this.rows;
}

prev() {
  this.first = this.first - this.rows;
}

refresh() {
  this.first = 0;

}

isLastPage(): boolean {
  return this.filteredCompanyDetails
    ? this.first === this.filteredCompanyDetails.length - this.rows
    : true;
}

isFirstPage(): boolean {
  return this.filteredCompanyDetails ? this.first === 0 : true;
}

onPageChange(event: any) {
  event.first = 0;
  event.rows = 5;
  event.page = 1;
  event.pageCount = 5;
}

  // For resetting the form
  reset() {
    this.recentlySelected=true;
    this.isChecked = true;
    this.resolvingPopForm.reset();
    this.pgName = [];
    this.selectedIndex = -1;

  this.resolvingPopForm.get('balId')?.disable();
  this.resolvingPopForm.get('business')?.enable();
  this.resolvingPopForm.get('country')?.enable();
  this.resolvingPopForm.get('city')?.enable();
  this.resolvingPopForm.get('bu')?.enable();
  this.resolvingPopForm.get('pg')?.enable();
  this.resolvingPopForm.get('activity')?.enable();

  this.filteredCompanyDetails = null;
  this.resolvingPopForm.patchValue({
    business: '',
    bu: '',
    pg: '',
    country: '',
    city: '',
    activity: '',

  });

    //need to pass the current user geid here to get the data, right now using the geid of session response data coming null
    this.resolvingUnitService.getResolvingUnitsRecentlySelected(this.sessionStorage.getData('userInfo').geid).subscribe((response) => {
      // console.log(response)
      this.filteredCompanyDetails = response.data;
      this.totalRecords = response.count;
      this.loading = false;
    })

    this.resolvingPopForm.controls['searchByBusinessLocation'].patchValue('Search by business activity location')
    this.resolvingPopForm.controls['local'].patchValue('local');
    this.resolvingPopForm.controls['business'].patchValue('(PG)Power Grids');
  }

// for closing the dialog
onClose() {
  this.dialogRef.close();
}

}
