import { Component, ViewChild, OnInit, ViewEncapsulation, Input, OnChanges, SimpleChanges, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { ProcessStepPopupComponent } from '../process-step-popup/process-step-popup.component';
import { BalIDSelectionPopUpComponent } from '../bal-idselection-pop-up/bal-idselection-pop-up.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { formatDate, DatePipe } from '@angular/common';
import { FileUpload } from 'primeng/fileupload';
import { StaticvalueConstants } from 'src/app/reusables/constants/staticvalue.constants';
import { ComplaintDetailsService } from 'src/app/services/complaintDetails/complaint-details.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ComplaintStatuses, PermissionCode, RoleCode } from 'src/app/enum';
import { WorkCenterSelectionPopUpComponent } from '../work-center-selection-pop-up/work-center-selection-pop-up.component';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import { MessageService } from 'primeng/api';
import { DownloadAttachmentService } from 'src/app/services/download-attachment.service';
import { maxLengthConstants } from 'src/app/reusables/constants/maxlength.constants';
import { CountriesServiceService } from 'src/app/services/countries--service.service';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';


export enum TargetResolvingDateChangeReasonType {
  InitialTargetDate = 1,
  TargetDateAfterResubmission = 2,
  SolutionRequiresMoreTime = 3,
  FurtherInformationRequired = 4,
  SolutionReturnedToResolutionOwner = 5,
  HistoricalDataMigration = 6,
  Other = 7
}

@Component({
  selector: 'app-issue-description',
  templateUrl: './issue-description.component.html',
  styleUrls: ['./issue-description.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class IssueDescriptionComponent implements OnInit, OnChanges {
  @Input() formGroupName!: string;
  descForm!: FormGroup;
  @Input() isSubmitted: boolean = false;
  @Input() issueCaptureMainForm: FormGroup;
  @Input() complaintData !: any;
  @Input() complaintValidations: any = [];
  @Input() ActionNameForComplaints: string ;
  @Input() fieldNameForFailureMode: string = "None";
  @Input() impactData !: any;  
  ref: DynamicDialogRef;
  @ViewChild('createUploaderIssueDesc') createUploaderIssueDesc!: FileUpload;
  @Output() processStepIdValue = new EventEmitter();
  @Input() isEnableSAPQN: boolean;
  @Input() isPreviousTargetResolutionDate: boolean;

  roleNoneAttachmentDisabled : any = false;

  issueDescText = 'Enter as much detail as you can provide about the issue being raise by Customer. The more details you can provide the better resolution process will work. Note that one CCRP should be limited to one issue. If there are multiple issues then multiple CCRPs should be created';
  whatsHappeningText = 'Describe shortly what happens. e.g. IED does not TRIP, IED does not communicate, component gets burnt, memory bit changes value, display has missing lined or pixel etc.';
  problemText = 'State what the problem is and define the key terms of the problem.';
  problemText2 = 'Explain how the problem was discovered (how, who)';
  issueDetectedText = 'Select from tree in which process step was the issue detected.';
  problemDetectedText = 'Where was this problem observed the first time and where after, if happened several times. Select the BAL ID or type free text.';
  whenHappenedText = 'Please indicate when the incident happened. If this date is not known, please indicate the date the incident was detected. To indicate recurring incidents, please use the category “Instances” in the rating configuration.';
  whenHappenedBtnText = 'Please indicate when the incident happened. If this date is not known, please indicate the date the incident was detected. To indicate recurring incidents, please use the category “Instances” in the rating configuration.';
  impactText = 'Use this space to describe what is the impact on the customer and what are the customer\'s expectations with respect to the resolution of this case.';
  attachmentText = 'Check this box in case any containtment actions are necessary to protect the customer or limit the problem extent. You will be also required to provide the Containtment Actions Deadline date, by which the customer is expecting these actions.';
  containmentActionText = 'Containment action requested Date. To insert a date, click on the select button and pick the date from the Calendar. The date should be entered in the following format: YYYY-MM-DD.';
  containmentActionBtnText = 'Containment action requested Date. To insert a date, click on the select button and pick the date from the Calendar. The date should be entered in the following format: YYYY-MM-DD.';
  targetDateBtnText = 'Click on "Select" button to provide the date by which Customer is expecting a solution. Please choose a date which will provide the Resolving Unit reasonable time to address the issue. Consider the complexity of the issue.The date should be entered in the following format: YYYY-MM-DD.';
  targetDateText = 'Click on "Select" button to provide the date by which Customer is expecting a solution. Please choose a date which will provide the Resolving Unit reasonable time to address the issue. Consider the complexity of the issue.The date should be entered in the following format: YYYY-MM-DD.';
  containtmentActionRequiredText = 'Check this box in case any containment actions are necessary to protect the customer or limit the problem extent. You will be also required to provide the Containment Action Requested Date, by which the customer is expecting these actions.';
  EightDRCAIsRequiredByCustomerText = "Select this checkbox if corrective actions and a root cause analysis are required. Corrective actions and root cause analysis are mandatory by default for Class A / B / I CCRPs or where the customer has requested corrective actions and root cause analysis."
  removeText = 'Remove';
  changeDescription = 'Please provide the reason why the Target Date needs to be changed';
  workcenter = 'Use this field to provide work centre details'
  issueDescAttachment: any = [];
  informationAttachments: any = [];
  informationAttachmentsHolder: any = [];
  attachmentData = {storFileName: '',displayName: ''};
  issueAttachmentMerged: any = []
  processStep: any;
  dateChangeReason: any;
  showChangeDescription: boolean = false;
  showDateChangeReason: boolean = false;
  baseurl = environment.azure.baseUrl;
  downloadAllUrl = environment.azure.baseUrl+'api/v1/Attachment/downloadmany?storeFilesNames=';

  title = 'dialogBox';
  textDATA = '';
  textLABEL = '';
  minDate: Date = new Date();
  targetMinDate = new Date();
  maxDate: Date = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  headerSectionData: any;
  issueStatus: any;
  paramForAttachment: any;
  disableSection: any = true;
  complaintCreatedDate:any = new Date();
  ncrLiveDate:any = new Date(environment.ncrLiveDate);

  @ViewChild("calendar", { static: false }) private calendar: any;
  @ViewChild("calendarTarget", { static: false }) private calendarTarget: any;
  @ViewChild("calenderDeadline", { static: false }) private calendarDeadline: any;

  enumPermission = PermissionCode;
  enumRoleCode = RoleCode;
  enumComplaintStatuses = ComplaintStatuses;
  userAccessData = null;
  balIdPass: any;
  @Input() role : any;
  alreadyPatched: boolean = false;
  readonly maxLength = maxLengthConstants;
  countries: any[] = [];
  @Output() countryEmit = new EventEmitter();
  fillInText = 'Use "Fill in" button to set the country based on the Customer';

  constructor(
    private rootFormGroup: FormGroupDirective,
    private fb: FormBuilder,
    public dialogService: DialogService,
    private authorizationService: AuthorizationService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    public complaintDetailsService: ComplaintDetailsService,
    private resolvingUnitService: ResolvingUnitServiceService,
    private messageService: MessageService,
    private downloadAttachementService:DownloadAttachmentService,
    private countryService: CountriesServiceService,
    private localStorageService: LocalStorageServiceService,
  ) {
    this.dateChangeReason = [
      {
        "id": TargetResolvingDateChangeReasonType.SolutionRequiresMoreTime,
        "reason": "Solution requires more time"
      },
      {
        "id": TargetResolvingDateChangeReasonType.FurtherInformationRequired,
        "reason": "Further information required"
      },
      {
        "id": TargetResolvingDateChangeReasonType.Other,
        "reason": "Other"
      },
    ]
  }

  ngOnInit(): void {
    if (this.localStorageService.getFromLocalStorage('mdregion_20200117') === null) {
      this.countryService.getCountries().subscribe((Response) => {
        this.countries = Response;
      });
    }
    else {
      this.countries = this.localStorageService.getFromLocalStorage('mdregion_20200117').countries;
    }
    this.paramForAttachment = {0: '60 MB'};
    this.descForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.headerSectionData = this.rootFormGroup.control.get('headerSection') as FormGroup;
    this.descForm?.controls['deadline']?.valueChanges.subscribe((changedValue) => {
      this.targetMinDate = this.descForm?.controls['deadline'].value!='' && this.descForm?.controls['deadline'].value!=null && new Date(this.descForm?.controls['deadline'].value) > this.minDate ? new Date(this.descForm?.controls['deadline'].value) : this.minDate;
    });
    this.descForm?.controls['TargetResolvingDate']?.valueChanges.subscribe((changedValue) => {
      if (changedValue) {
        const updatedValue = this.datePipe.transform(changedValue, 'yyyy-MM-dd');
        this.descForm?.controls['targetResolutionHiddenDate'].patchValue(new Date(updatedValue));
        this.maxDate = this.descForm?.controls['targetResolutionHiddenDate'].value;
        //when target date changed from not assigned status
        if (this.complaintData?.header?.complaintStatus > 2) {
          let currentTargetDate = this.datePipe.transform(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.targetResolvingDateInitialValue, 'yyyy-MM-dd');
          if (currentTargetDate != updatedValue) {
            this.showDateChangeReason = true;
            this.descForm?.controls['targetResolvingDateChangeCount'].patchValue(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.targetResolvingDateChangeCount);
          }
          else {
            this.showDateChangeReason = false;
            this.showChangeDescription = false;
            this.descForm?.controls['targetResolvingDateChangeCount'].patchValue(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.targetResolvingDateChangeCount);
            this.descForm?.controls['dateChangeReason'].reset();
          }
        }
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.complaintValidations.length>0){
      this.setFieldsValidation(this.ActionNameForComplaints);
    }
    this.issueStatus = this.headerSectionData?.controls['issueComplaintStatus']?.value;
    this.authorizationService.userAccessDetail.subscribe(detail => {
      this.userAccessData = detail;
      this.attachmentDisabled();
    });

    if (this.complaintData != undefined && this.descForm && this.alreadyPatched==false) {
      this.complaintCreatedDate = new Date(this.complaintData?.header?.createDate);
      this.informationAttachments = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.informationAttachments;
      this.informationAttachmentsHolder = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.informationAttachments;
      this.issueAttachmentMerged = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.informationAttachments;
      this.informationAttachments.forEach(attachments => {
        this.attachmentData.storFileName = this.attachmentData?.storFileName+(this.attachmentData?.storFileName=='' ? '' : '_&_')+attachments?.storeFileName;
        this.attachmentData.displayName = this.attachmentData?.displayName+(this.attachmentData?.displayName=='' ? '' : '_&_')+attachments?.displayName;
      });
      this.downloadAllUrl = this.downloadAllUrl+encodeURIComponent(this.attachmentData?.storFileName)+'&displayNames='+encodeURIComponent(this.attachmentData?.displayName);
      const workCentre = this.complaintData?.sapComplaintInfo;
      const workCentreObj = {workcentre: workCentre?.workCentre, plant: workCentre?.plant}
      this.descForm.patchValue({
        Reason: this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.reason),
        WhatIssueDescription: this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.whatIssueDescription),
        whyIssueDescription: this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.whyIssueDescription),
        howIssueDescription: this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.howIssueDescription),
        whereIssueDescription: this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.whereIssueDescription),
        whereWasTheIssueDetectedId: this.checkBlank(this.complaintData?.whereWasTheIssueDetectedId),
        whenIssueDescription: this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.whenIssueDescription?.split("T", 1)[0]),
        customerExpectedResolutionDescription: this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.customerExpectedResolutionDescription),
        IsContainmentActionRequired: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.isContainmentActionRequired,
        deadline: this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.containmentAction?.deadline?.split("T", 1)[0]),
        ContainmentActionDescription: this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.containmentAction?.containmentActionDescription),
        ContainmentActionsId: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.containmentAction?.id,
        EightDRCAIsRequiredByCustomer: this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.eightDRCAIsRequiredByCustomer),
        containmentExecutedDate: this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.containmentAction?.execution?.split("T", 1)[0]),
        targetResolvingDateChangeCount : this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.targetResolvingDateChangeCount),
        TargetResolvingDate: this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.targetResolvingDate?.split("T", 1)[0]),
        targetResolvingDateInitialValue : this.checkBlank(this.datePipe.transform(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.targetResolvingDate, 'yyyy-MM-dd hh:mm:ss')),
        uploadedAttachments: this.issueAttachmentMerged,
        workCentreHidden: workCentreObj,
        WorkCentreandPlant: workCentreObj?.workcentre,
        CountryCodeWhereTheIssueHappen: (this.countries.filter(country => country?.id?.toLowerCase() == this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.countryCodeWhereTheIssueHappen?.toLowerCase())[0]),
      });
      this.maxDate = new Date(this.descForm?.controls['targetResolutionHiddenDate'].value);
      if(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.inWhichProcessStep!=null){
            this.setProcessStep(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.inWhichProcessStep);
      }
      this.alreadyPatched=true;
    }
  }

  setFieldsValidation(action) {
  //   console.log("if")
    for (const controlName in this.issueCaptureMainForm?.get('issueCaptureForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules,this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("else for: ", controlName);
        this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName).clearValidators();
        this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName).updateValueAndValidity();
      }
    }
    for (const controlName in this.issueCaptureMainForm?.get('issueDescriptionForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules,this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
          this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName).clearValidators();
          this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName).updateValueAndValidity();
      }
    }
    for (const controlName in this.issueCaptureMainForm?.get('ratingForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('ratingForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules,this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("else for: ", controlName);
        this.issueCaptureMainForm?.get('ratingForm').get(controlName).clearValidators();
        this.issueCaptureMainForm?.get('ratingForm').get(controlName).updateValueAndValidity();
      }
    }
    for (const controlName in this.issueCaptureMainForm?.get('additionalSubjectForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString())  && (this.userAccessData==null ? this.userAccessData==null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules,this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("else for: ", controlName);
        this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).clearValidators();
        this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).updateValueAndValidity();
      }
    }
  }
  private getValidators(rules: any[], fieldFailureModeValidation): any {
    let validators: Validators[] = [];
    let index = 0;
    for (const rule of rules) {
      index = index+1;
      if (rule.depends != null) {
        // console.log("<><><><<><>",rule.property,rule.depends,this.issueCapture.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase(), this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase() , this.issueRating.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.additionalSubject.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase())
        if(fieldFailureModeValidation!="None" && rule.depends=="Validation" && rule.value == fieldFailureModeValidation){
          validators.push(Validators.required);
        //   console.log("=======>>", rule.property, rule.depends, rule.value,);
          return validators;
        }
        else if ((this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value!=undefined && this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value!=undefined && this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value!=undefined && this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value!=undefined && this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase())) {
          validators.push(Validators.required);
        //   console.log("=======", rule.property, rule.depends, rule.value,);
          return validators;
        }
        else {
        //   console.log("======= blank", validators);
        if(index==rules.length){
          return validators;
         }
        }
      }
      else {
        validators.push(Validators.required);
        return validators;
      }
    }
  }

  containmentChange(){
  this.setFieldsValidation(this.ActionNameForComplaints);
  }

  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    }
    else {
      return content;
    }
  }

  get f() {
    return this.descForm.controls;
  }

  openCalendar(event: any, check: any) {
    this.calendar.inputfieldViewChild.nativeElement.click();
    event.stopPropagation();
  }

  openCalendarTargetDate(event: any, check: any) {
    this.calendarTarget.inputfieldViewChild.nativeElement.click();
    event.stopPropagation();
    this.targetDateText = this.descForm.controls['TargetResolvingDate'].value;
  }
  openCalenderContainmentDeadline(event: any, check: any) {
    this.calendarDeadline.inputfieldViewChild.nativeElement.click();
    event.stopPropagation();
    this.containmentActionText = this.descForm.controls['deadline'].value;
  }

  whenHappenedDateOnSelect() {
    this.whenHappenedText = 'Local: ' + formatDate(new Date(this.descForm.controls['whenIssueDescription']?.value), 'yyyy-MM-dd', 'en');
  }
  targetDeadlineDateOnSelect() {
    this.containmentActionText = 'Local: ' + formatDate(new Date(this.descForm.controls['deadline'].value), 'yyyy-MM-dd', 'en');
    this.descForm.patchValue({
      IsContainmentActionRequired: true
    });
  }

  targetResoltuionDateOnSelect() {
    this.targetDateText = 'Local: ' + formatDate(new Date(this.descForm.controls['TargetResolvingDate'].value), 'yyyy-MM-dd', 'en');
  }

  myUploader(createUploader, event) {
    let section = "issue-capture";
    for (let i = 0; i < event.files.length; i++) {
      if (i >= this.issueDescAttachment?.length) {
        let formData = new FormData();
        formData.append("files", event.files[i]);
        this.spinner.show();
        this.complaintDetailsService.issueCaptureAttachmentSave(section, formData).subscribe((response: any) => {
          let attachIdChange = response[0];
          attachIdChange.id = (attachIdChange?.id)?.toString();
          this.issueDescAttachment.push(attachIdChange);
          this.issueAttachmentMerged = [...this.informationAttachmentsHolder, ...this.issueDescAttachment];
          this.descForm.patchValue({
            uploadedAttachments: this.issueAttachmentMerged
          });
          this.spinner.hide();
        },
        (error) => {
            this.spinner.hide();
            this.messageService.add({
                severity: 'error',
                summary: error ? error : 'Something went wrong!'
            });
        });
      }
    }
  }

  uploadedAttachmentRemove(attachmentIndex) {
    // console.log("Desc uploadedAttachmentRemove=>",this.informationAttachments,attachmentIndex,(this.informationAttachmentsHolder.findIndex(attachment => attachment.id == this.informationAttachments[attachmentIndex]?.id)));
    if ((this.informationAttachmentsHolder.findIndex(attachment => attachment.id == this.informationAttachments[attachmentIndex]?.id)) == -1) {
      this.informationAttachmentsHolder.push(this.informationAttachments[attachmentIndex]);
    }
    else {
      this.informationAttachmentsHolder = this.informationAttachmentsHolder.filter((attachment) => attachment?.id !== this.informationAttachments[attachmentIndex]?.id);
      //      this.issueDescAttachment?.splice(this.issueDescAttachment?.findIndex(attachment => attachment.id == this.informationAttachments[attachmentIndex]?.id) , 1);
    }
    // console.log("=====>",this.descForm.controls['uploadedAttachments'].value)
    this.issueAttachmentMerged = [...this.informationAttachmentsHolder, ...this.issueDescAttachment]
    this.descForm.patchValue({
      uploadedAttachments: this.issueAttachmentMerged
    });
  }

  checkRemoved(attachmentIndex) {
    // console.log("Desc checkRemoved=>",(this.issueDescAttachment.indexOf(attachment => attachment.id == this.informationAttachments[attachmentIndex]?.id)))
    if ((this.informationAttachmentsHolder.findIndex(attachment => attachment.id == this.informationAttachments[attachmentIndex]?.id)) == -1) {
      return true;
    }
    else {
      return false;
    }
  }

  attachmentDisabled() {
    if(this.complaintData?.sapComplaintInfo?.sapLockStatus == true && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
      this.disableSection = false;
      return;
    }
    if (this.userAccessData) {
        switch (this.userAccessData['roleCode']) {

            case this.enumRoleCode.Complaint_Owner:
                  this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12], [210], this.userAccessData);
            break;
            case this.enumRoleCode.Complaint_Coordinator:
                  this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12], [210], this.userAccessData);
            break;
            case this.enumRoleCode.Global_CCRP_Process_Owner:
                  this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12], [210], this.userAccessData);
            break;
            case this.enumRoleCode.Support_Desk:
                  this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12], [210], this.userAccessData);
            break;
            case this.enumRoleCode.Complaint_Owner_Supervisor:
                  this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12], [210], this.userAccessData);
            break;
            case this.enumRoleCode.Complaint_Owner_Team:
                  this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12], [210], this.userAccessData);
            break;
            case this.enumRoleCode.Resolution_Owner:
                  this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12], [210], this.userAccessData);
            break;
            case this.enumRoleCode.Resolution_Owner_Team:
                  this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12], [210], this.userAccessData);
            break;
            case this.enumRoleCode.Ru_Process_Owner:
                  this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12], [210], this.userAccessData);
            break;
            case this.enumRoleCode.Resource_Editor:
                  this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12], [210], this.userAccessData);
            break;
            case this.enumRoleCode.Div_Process_Owner:
                  this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12], [210], this.userAccessData);
            break;
            case this.enumRoleCode.Bu_Process_Owner:
                  this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12], [210], this.userAccessData);
            break;
            case this.enumRoleCode.Pg_Process_Owner:
                  this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12], [210], this.userAccessData);
            break;
            case this.enumRoleCode.Hub_Process_Owner:
                  this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12], [210], this.userAccessData);
            break;
        }
        return this.disableSection
    }
    else {
      this.roleNoneAttachmentDisabled = true && this.role == 'Issue Owner';
    }
  }
  removeFile(file: File, uploader: FileUpload) {
    const index = uploader.files.indexOf(file);
    uploader.remove(event, index);
    let filename = [this.issueDescAttachment[Number(index)]?.storeFileName];
    // let filename = this.issueDescAttachment.filter(attachment=> attachment?.name==file?.name);
    this.spinner.show();
    this.complaintDetailsService.removeAttachments(filename).subscribe((res) => {
      this.issueDescAttachment = this.issueDescAttachment.filter(attachment => attachment?.storeFileName !== filename[0]);
      this.issueAttachmentMerged = [...this.informationAttachmentsHolder, ...this.issueDescAttachment];
      this.descForm.patchValue({
        uploadedAttachments: this.issueAttachmentMerged
      });
      this.spinner.hide();
    })
  }

  showComponentProcessStep() {
    this.ref = this.dialogService.open(ProcessStepPopupComponent, {
      header: 'Select process step in which the issue happened ',
      width: '70%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'process-step-pop'
    });

    this.ref.onClose.subscribe((receivedValue: any) => {
      if (receivedValue) {
        this.setProcessStep(receivedValue);
      }
    })
  }

  setProcessStep(receivedValue) {
    // console.log("Process Step:=====",receivedValue);
    this.processStep = receivedValue ? {
      "divCode": "",
      "id": receivedValue?.id,
      "identification": {
        "id": receivedValue?.id,
        "geid": "",
        "email": "",
        "cid": "",
        "giS_GUID": "",
        "email_Alias": ""
      },
      "displayLabel": receivedValue?.displayLabel,
      "tooltipInfo": receivedValue?.tooltipInfo,
      "description": receivedValue?.description,
      "isValid": true
    } : null;

 /* let parentValue = null;
  if(receivedValue?.parent) {
    parentValue = receivedValue?.parent?.displayLabel + ' / ';
  }

  if(receivedValue?.parent?.parent) {
    parentValue = receivedValue?.parent?.parent?.displayLabel + ' / ' + receivedValue?.parent?.displayLabel + ' / ';
  }

  let concatValue;
  if(parentValue) {
    concatValue = parentValue + receivedValue?.displayLabel;
  }
  else {
    concatValue = receivedValue?.displayLabel;
  }*/
  this.descForm.patchValue({
    issueStep: receivedValue?.displayLabel,
    inWhichProcessStep: this.processStep
  })
 // this.issueDetectedText = concatValue;
  this.issueDetectedText = receivedValue?.tooltipInfo ? receivedValue?.tooltipInfo : (receivedValue?.tooltipInfo ? receivedValue?.tooltipInfo : receivedValue?.data);
  if(this.issueCaptureMainForm.get('additionalSubjectForm').get('resolvingUnit').value!=''){
  const resolvingProcessId = { resolvingUnitId: this.issueCaptureMainForm.get('additionalSubjectForm').get('resolvingUnitArray').value?.id, processStepId: receivedValue?.id, isHightImpact: this.impactData?.isHightImpact ? this.impactData?.isHightImpact : false, matrixClass: this.impactData?.matrixClass ? this.impactData?.matrixClass : '' }
  this.resolvingUnitService.getCoOrdinator(resolvingProcessId).subscribe((coordinator) => {
    if (coordinator) {
      let coordinatorData = coordinator.data;
      let isAccountablePerson;
      let isRUPerson;
      let setHVDCFlag;
      if (receivedValue?.id && coordinator.isRUPerson == true) {
        let setHVDCComplaint = { IsHVDCComplaint: false, processStepId: receivedValue?.id ? receivedValue?.id : null };
        localStorage.setItem('checkHVDCComplaint', JSON.stringify(setHVDCComplaint));
        isAccountablePerson = false;
        isRUPerson = true;
        setHVDCFlag = false;
      }
      if (receivedValue?.id && coordinator.isAccountable == true) {
        var setHVDCComplaint = { IsHVDCComplaint: true, processStepId: receivedValue?.id };
        localStorage.setItem('checkHVDCComplaint', JSON.stringify(setHVDCComplaint));
        isAccountablePerson = true;
        isRUPerson = false;
        setHVDCFlag = true;
      }
      else {
        var setHVDCComplaint = { IsHVDCComplaint: false, processStepId: receivedValue?.id ? receivedValue?.id : null };
        localStorage.setItem('checkHVDCComplaint', JSON.stringify(setHVDCComplaint));
        isRUPerson = true;
        setHVDCFlag = false;
      }
      this.issueCaptureMainForm.get('additionalSubjectForm').patchValue({
        Coordinators: coordinatorData,
        hiddenIsHVDCComplaint: setHVDCFlag
      });
    }
  })

  this.resolvingUnitService
    .getVerificationTeam(resolvingProcessId)
    .subscribe((res) => {
      if (res) {
        this.issueCaptureMainForm.get('additionalSubjectForm').patchValue({
          verificationTeam: res.data
        });
      }
    });
  }
  this.processStepIdValue.emit({ processStepId: receivedValue?.id });

}

showWorkCenterPopup () {
  if(!this.balIdPass) {
    this.balIdPass = this.complaintData?.whereWasTheIssueDetectedId;
    //this.balIdPass = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.whereIssueDescription.split(',')[0];
  }

  this.ref = this.dialogService.open(WorkCenterSelectionPopUpComponent, {
    header: 'Work Center selection',
    position: 'top',
    keepInViewport: true,
    autoZIndex: true,
    styleClass: 'work-center-popup',
    data: { balId: this.balIdPass }
  });

  this.ref.onClose.subscribe((receivedValue: any) => {
    if (receivedValue) {
      this.descForm.patchValue({
        WorkCentreandPlant: receivedValue?.workcentre,
        workCentreHidden: receivedValue
      })

      this.issueCaptureMainForm?.get('additionalSubjectForm')?.patchValue({
        isQnNumberGeneratedCheck: true
      })
    }
    })
  }
  //for opening BAL Id Popup
  showBalIdComponentPopup() {
    this.ref = this.dialogService.open(BalIDSelectionPopUpComponent, {
      header: 'BAL ID selection ',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'bal-id-popup'
    });

    this.ref.onClose.subscribe((receivedValue: any) => {
      if (receivedValue) {
        this.setBalId(receivedValue);
      }
    })

  }
  setBalId(receivedValue) {
    this.balIdPass = receivedValue?.baL_Id;
    this.descForm.patchValue({
      whereIssueDescription: receivedValue?.baL_Id + ", " + receivedValue?.divisionName + ", " + receivedValue?.activity + ", " + receivedValue?.mgmtOrg + ", " + receivedValue?.productGroup + ", " + receivedValue?.address,
      whereWasTheIssueDetectedId : receivedValue?.baL_Id
   //   issueLocationData: receivedValue
      })
    this.problemDetectedText = receivedValue?.tooltipInfo;
  }


  onChangeTargetReason(event) {
    if (event.value == TargetResolvingDateChangeReasonType.Other) {
      this.showChangeDescription = true;
      this.descForm?.controls['changeReasonId'].patchValue(event.value);
    }
    else {
      this.showChangeDescription = false;
      this.descForm?.controls['changeDescription'].reset();
      this.descForm?.controls['changeReasonId'].patchValue(event.value);
    }
  }

  disableWorkCenter(){
    if(this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
      return false;
    }
    else if(this.issueCaptureMainForm?.get('headerSection')?.get('complaintStatusId')?.value < 4){
      return true;
    }
    else if(this.descForm.controls['whereIssueDescription']?.value == '' || this.descForm.controls['whereIssueDescription']?.value == null){
      return true;
    }
    else{
      return false;
    }
  }

  downloadAttachementFromAPI(storeFileName,displayName){
   this.downloadAttachementService.downloadAttachement(storeFileName,displayName);
  }

  country() {
    this.countryEmit.emit();
  }

}

