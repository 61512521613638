<div class="actionplan-edit">
<form [formGroup]="actionCreate" class="pt-2">
    <div class="row">
        <!-- Action Status -->
        <div class="col-sm-8 action-form-header">
            {{'cc_action_plan_form_status_desc' | translate}}

                <!-- Action Status -->
    <div class="spaceBottom">
        <div class="col-sm-8" [ngSwitch]="selected.statusId" name="status">
            <span *ngSwitchCase="0" class="fw-normal">{{'cc_action_plan_status_new' | translate}}</span>
            <span *ngSwitchCase="1" class="fw-normal">{{'cc_action_plan_status_todo' | translate}}</span>
            <span *ngSwitchCase="2" class="fw-normal">{{'cc_action_plan_status_in_progress' | translate}}</span>
            <span *ngSwitchCase="4" class="fw-normal">{{'cc_action_plan_status_in_progress' | translate}}</span>
            <span *ngSwitchCase="3" class="fw-normal">{{'cc_action_plan_status_closed' | translate}}</span>
        </div>
    </div>

        </div>
        <div class="col-sm-4 text-end">
            <span>
                <a class="backLocationLink" (click)="onBackLocation()">{{'cc_action_plan_back_to_ccrp' | translate}}</a>
                <a class="backLocationLink linkToActionLog" *ngIf="selected.statusId != 0" (click)="onActionLog()"><i
                        class="fa fa-link"></i>&nbsp;{{'cc_link_to_action_log' | translate}}</a>
            </span>
        </div>
    </div>



    <!-- Action Name  -->
    <div class="row">
        <div class="col-sm-8 action-form-header">
            {{'cc_action_plan_action_form_name' | translate}}
            <span [ngClass]="{'asteriskRequired actionplanedit-label':selected.statusId != 3}"></span>
        </div>
    </div>

    <!-- Action Name  -->
    <!-- isDisabled.actionName ||  -->
    <div class="row spaceBottom">
        <div class="col-sm-9"
            [ngClass]="{'has-error': !isDisabled.actionName && GetActionName.errors?.['required'] || GetActionName.errors?.['notUnique']}">
            <input formControlName="actionName" [readonly]="disableForAssignAndNotActionOwner || disabledForClosed"
                class="form-control heightOfTextBox"
                [ngClass]="{'error-inputbox' : isSubmitted && this.actionCreate.controls['actionName'].invalid, 'disabled-inputbox' :  disableForAssignAndNotActionOwner || disabledForClosed}"
                name="actionName" maxlength="{{maxLength.ACTION_NAME_RCA}}"/>
            <span class="help-block error small-error" *ngIf="GetActionName.errors?.['required']">
                {{'cc_action_name_required' | translate}}
            </span>
            <span class="help-block error small-error"
                *ngIf="GetActionName.errors?.['notUnique'] && !GetActionName.errors?.['required']">
                {{'cc_action_name_not_unique' | translate}}
            </span>
        </div>
    </div>




    <div class="row spaceBottom">
        <!-- action Types -->
        <input type="hidden" formControlName="statusId">
        <div class="col-sm-3 heightOfTextBox">
            <label class="fw-bold"> {{'cc_action_plan_action_form_type' | translate}}
                <span [ngClass]="{'asteriskRequired actionplanedit-label':(selected.statusId != 3)}"></span>
            </label>
            <p-dropdown formControlName="actionType" [options]="actionType" optionLabel="name" [filter]="true"
                [autofocus]="false" [disabled]="disableForAssignAndNotActionOwner || disabledForClosed"
                [ngClass]="{'error-dropdownInput' : isSubmitted && this.actionCreate.controls['actionType'].invalid, 'disabled-dropdown' : disableForAssignAndNotActionOwner || disabledForClosed}"
                [autofocusFilter]="false"></p-dropdown>
        </div>

        <!-- containment or RCA date -->
        <div class="col-sm-3 calendar-textfield">
            <label class="fw-bold">
                <span *ngIf="getActionType.value.id == 26">{{'cc_action_plan_rca_target_deadline' | translate}} </span>
                <span *ngIf="getActionType.value.id == 28">{{'cc_action_plan_containment_action_deadline' | translate}}
                </span>
            </label>
            <!-- <label hidden id="lblActionPlanHighLightDate">{{actionPlanHighLightDate}}</label> -->
            <p-calendar formControlName="actionPlanHighLightDate"
                *ngIf="getActionType.value.id == 26 || getActionType.value.id == 28" dateFormat="yy-mm-dd"
                [disabled]="true" class="form-control form2-control calenderStyle border-0 heightOfTextBox"
                [ngClass]="{'error-calendarInput' : isSubmitted && this.actionCreate.controls['actionPlanHighLightDate'].invalid}"
                [minDate]="minDate" [maxDate]="maxDate"></p-calendar>
        </div>
        <!-- action deadline date -->
        <div class="col-sm-3 calendar-textfield">
            <label class="fw-bold">  {{'cc_action_plan_action_form_deadline' | translate}}
                <span [ngClass]="{'asteriskRequired actionplanedit-label':(selected.statusId != 3)}"></span>
            </label>
            <div class="input-group">
                <p-calendar [showIcon]="true" formControlName="targetDate"
                    [disabled]="disableForAssignAndNotActionOwner || disabledForClosed" dateFormat="yy-mm-dd"
                    class="form-control form2-control calenderStyle border-0" [minDate]="minDate"
                    [ngClass]="{'disabled-calendar' : disableForAssignAndNotActionOwner || disabledForClosed, 'error-calendarInput' : showerror && GetTargetDate.errors?.['required']}"
                    (onSelect)="onChangeActionDeadLine()" [maxDate]="maxDate"></p-calendar>
            </div>
            <span *ngIf="GetTargetDate.touched && GetTargetDate.errors?.['invalidDate']"
                class="help-block error small-error">
                {{'cc_target_date_greater_than' | translate}} <br>
            </span>
            <!-- GetTargetDate.touched &&  -->
            <span *ngIf="showerror && GetTargetDate.errors?.['required']"
                class="help-block error small-error ">
                {{'cc_target_date_greater_required' | translate}}<br>
            </span>
            <span *ngIf="targetDateValidation && selected.type != 27" class="help-block error small-error">
                {{'cc_target_resolution_date_greater_than_validation' | translate}}
            </span>
        </div>
    </div>


    <div class="row">
        <!-- Action Owner label -->
        <div class="col-sm-9 action-form-header">
            {{'cc_action_plan_action_form_owner' | translate}}
            <span [ngClass]="{'asteriskRequired actionplanedit-label':(selected.statusId != 3)}"></span>
        </div>
    </div>

    <!-- action owner input box -->
    <div class="row spaceBottom">
        <!-- <div class="col-sm-8"
             id="ownerContainer"
             ng-class="{'has-error': !ctrl.saveMade && !ctrl.isDisabled.actionOwner && ctrl.validationRan && actionItem.actionOwner.$error.required,
                        'has-warn': (selected.statusId == 2 || selected.statusId == 4) && selected.actionOwner.deactivateDate }">
            <input ng-disabled="ctrl.isDisabled.actionOwner" name="actionOwner"
                   ng-required="true" ng-change="ctrl.disableButtonsOnReassign()" />
        </div> -->
        <div class="multiselect-field_resolutionOwner col-md-9 col-sm-9">
            <p-autoComplete [forceSelection]="true" class="resolutionOwnerCustom border-0 text-width1 mt-2"
                (onSelect)="ownerSelect($event)" formControlName="actionOwner" [multiple]="true" type="text"
                [suggestions]="filteredRecord" [ngClass]="{'disabled-autocomplete' : disableForAssignAndNotActionOwner || disabledForClosed , 'error-autocomplete' : !saveMade && !isDisabled.actionOwner && showerror && getActionOwner.errors?.['required']}"
                (completeMethod)="onRecipientChange($event)" field="selectedLabel"
                [disabled]="disableForAssignAndNotActionOwner || disabledForClosed"
                [readonly]="this.actionCreate.controls['actionOwner'].value?.length==1 ? true : false">

              <ng-template let-filteredRecord pTemplate="selectedItem">
                <div class="email-item">
                  {{filteredRecord?.selectedLabel }}
                </div>
                <!-- <span class="p-autocomplete-token-icon pi pi-times-circle ng-tns-c68-7 ng-star-inserted"
                      (click)="clearOwner()">
                </span> -->
              </ng-template>
              <ng-template let-filteredRecord pTemplate="item">
                <div class="flex align-items-center gap-2" style="font-size: 12px;">
                  <div class="row">
                    <div class="col-8"><strong>{{ filteredRecord?.promptLabel }}</strong></div>
                    <div class="col-4 text-end"><i>({{filteredRecord?.countryCode}})</i></div>
                  </div>
                  <div class="row">
                    <div class="col">{{filteredRecord?.selectedLabel}}</div>
                  </div>
                </div>
              </ng-template>
            </p-autoComplete>
            <!-- getActionOwner.touched && -->
            <span
                *ngIf="!saveMade && !isDisabled.actionOwner && showerror && getActionOwner.errors?.['required']"
                class="help-block error small-error ">
                {{ 'cc_action_plan_owner_required' | translate }}
            </span>
            <span *ngIf="(selected.statusId == 2 || selected.statusId == 4) && selected.actionOwner?.deactivateDate"
                class="help-block error small-error" style="color: #f0ad4e !important">
                {{'cc_action_plan_owner_inactive' | translate}}
            </span>
        </div>
    </div>


    <div class="row">
        <!-- Interested parties for receiving notifications -->
        <div class="col-sm-9 action-form-header">
            {{'cc_action_plan_action_form_interested_parties' | translate}}
        </div>
    </div>

    <!-- Interested parties for receiving notifications -->
    <div class="row spaceBottom">
        <div class="multiselect-field_resolutionOwner col-md-9 col-sm-9" style="min-height: 25px;">
            <p-autoComplete class="border-0 resolutionOwnerTeamCustom text-width2" formControlName="interestedParties"
                [ngClass]="{'error-autocomplete' : isSubmitted && this.actionCreate.controls['interestedParties'].invalid, 'disabled-autocomplete' : disableForAssignAndNotActionOwner || disabledForClosed}"
                [forceSelection]="true" type="email" [suggestions]="filteredRecord"
                (completeMethod)="onRecipientChange($event)" field="selectedLabel" [multiple]="true"
                [disabled]="disableForAssignAndNotActionOwner || disabledForClosed"
                [readonly]="this.actionCreate.controls['interestedParties'].value?.length==15 ? true : false">
              <ng-template let-filteredRecord pTemplate="selectedItem">
                <div class="email-item">
                  {{filteredRecord?.selectedLabel }}
                </div>
              </ng-template>
              <ng-template let-filteredRecord pTemplate="item">
                <div class="flex align-items-center gap-2" style="font-size: 12px;">
                  <div class="row">
                    <div class="col-8"><strong>{{ filteredRecord?.promptLabel }}</strong></div>
                    <div class="col-4 text-end"><i>({{filteredRecord?.countryCode}})</i></div>
                  </div>
                  <div class="row">
                    <div class="col">{{filteredRecord?.selectedLabel}}</div>
                  </div>
                </div>
              </ng-template>
            </p-autoComplete>
        </div>
    </div>

    <!-- action creator -->
    <div class="row" *ngIf="selected.statusId > 0">
        <div class="col-sm-8 action-form-header">{{'cc_action_plan_form_creator' | translate}}</div>
    </div>

    <!-- action creator -->
    <div class="row spaceBottom multiselect-field_resolutionOwner" *ngIf="selected.statusId > 0">
        <div class="col-sm-9" [ngClass]="{'has-error': !isDisabled.actionCreator}">
            <!--            <input formControlName="actionCreator" class="form-control heightOfTextBox" type="text" [disabled]="isDisabled.actionCreator" />-->
            <!--            -->
            <p-autoComplete [forceSelection]="true"
                class="resolutionOwnerCustom border-0 text-width1 mt-2 disabled-autocomplete action-creater"
                formControlName="actionCreator" [multiple]="true" type="text" [suggestions]="filteredRecord"
                (completeMethod)="onRecipientChange($event)" field="selectedLabel" [readonly]="true" [showClear]="false"
                [ngClass]="{'error-autocomplete' : isSubmitted && this.actionCreate.controls['actionCreator'].invalid}"
                [readonly]="this.actionCreate.controls['actionCreator'].value?.length==1 ? true : false">
                <ng-template let-filteredRecord pTemplate="selectedItem">
                    <div class="email-item">
                        {{filteredRecord?.selectedLabel }}</div>
                    <!-- <span class="p-autocomplete-token-icon pi pi-times-circle ng-tns-c68-7 ng-star-inserted"
                        (click)="clearCreator()"></span> -->
                </ng-template>
                <ng-template let-filteredRecord pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <div>{{ filteredRecord?.promptLabel }}</div>
                    </div>
                </ng-template>
            </p-autoComplete>
            <span class="error help-block small-error " *ngIf="!isDisabled.actionCreator">
                {{'cc_action_plan_action_creator_inactive' | translate}}
            </span>
        </div>
    </div>

    <!-- description -->
    <div class="row">
        <div class="col-sm-9 action-form-header">{{'cc_action_plan_action_form_description' | translate}}</div>
    </div>

    <div class="row spaceBottom">
        <div class="col-sm-9">
            <textarea formControlName="description" class="form-control"
                style="resize: vertical; min-height: 100px; margin-bottom: 10px;"
                [ngClass]="{'disabled-inputbox' : disableForAssignAndNotActionOwner || disabledForClosed}"
                [disabled]="isDisabled.description || disableForAssignAndNotActionOwner || disabledForClosed"></textarea>

            <input type="hidden" formControlName="files">
            <div class="col-md-3 col-sm-3 ps-0 pe-0 text-end" *ngIf="informationAttachmentsDesc?.length > 0">
                <label class="mt-2">{{ "ComplaintForm_UploadedAttachments" | translate }}</label>
            </div>
            <div class="col-md-9 col-sm-9 p-0 selectfile" *ngIf="informationAttachmentsDesc?.length > 0">
                <div class="already-uploaded-attachments-main">
                    <div class="row"
                        *ngFor="let informationAttachment of informationAttachmentsDesc; let attachmentIndex = index;">
                        <div class="col-md-11 col-sm-11"><a class="fileInfo"
                            (click)="downloadAttachementFromAPI(informationAttachment?.fileId,informationAttachment?.name)"
                            style="cursor: pointer;" [ngClass]="checkDescriptionAttchmentRemoved(attachmentIndex) ? 'to-be-removed' : ''"
                            target="_blank">{{informationAttachment?.name}}</a>
                        </div>
                        <div class="col-md-1 col-sm-1">
                            <button type="button" class="widgetButtoSmall attachButton" *ngIf="!(disableForAssignAndNotActionOwner || disabledForClosed)"
                                (click)="uploadedDescriptionAttachmentRemove(informationAttachment)"> X </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dropzone" style="min-height: 50px; border-style: dashed; border-width: 2px;">
                <p-fileUpload #createUploaderDescription name="files[]" chooseLabel="Select Files"
                    [showUploadButton]="false" [showCancelButton]="false" chooseIcon="null"
                    [disabled]="disableForAssignAndNotActionOwner || disabledForClosed" removeStyleClass="p-overlaypanel1" [multiple]="true"
                    chooseStyleClass="p-overlaypanel" [maxFileSize]="60000000" [auto]="true" class=""
                    [customUpload]="true" (uploadHandler)="myUploaderDescription(createUploaderDescription,$event)">
                    <ng-template let-file pTemplate="file">
                        <ul class="p-upload-files p-reset">
                            <li class="p-file row">
                                <div class="col-md-11 col-sm-11" [pTooltip]="file?.name" [escape]="false"
                                    tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                    <span class="p-filename">{{file?.name}}</span>
                                </div>
                                <div class="col-md-1 col-sm-1" [pTooltip]="removeText" [escape]="false"
                                    tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                    <button type="button" class="widgetButtoSmall attachButton"
                                        (click)="removeDescriptionFile(file ,createUploaderDescription)"> X </button>
                                </div>
                            </li>
                        </ul>
                    </ng-template>
                </p-fileUpload>

            </div>
            <!-- <span style="font-size: 9px; color: gray">{{'cc_actionplan_accepted_files' | translate}}: *.pdf, *.xls,
                *xlsx, *.png, *.jpeg, *.csv</span> -->
        </div>
    </div>

    <!-- activities -->
    <div class="row">
        <div class="col-sm-9 action-form-header">
            {{'cc_action_plan_action_form_activities' | translate}}
            <span [ngClass]="{'asteriskRequired actionplanedit-label':(selected.statusId == 2 || selected.statusId == 4)}"></span>
        </div>
    </div>
    <div class="row spaceBottom">
        <div class="col-sm-9"
            [ngClass]="{'has-error': !isDisabled.activities && (!selected.activities || selected.activities.length < 1) && activitiesRequired}">
            <textarea formControlName="activities" class="form-control"
                style="resize: vertical; min-height: 100px; margin-bottom: 10px;" [disabled]="isDisabled.activities || disabledForClosed"
                name="activities"
                [ngClass]="{'has-error': !isDisabled.activities && (!selected.activities || selected.activities.length < 1) && activitiesRequired , 'disabled-inputbox' : disabledForClosed}"></textarea>
            <span class="help-block error small-error"
                *ngIf="activitiesRequired && (!selected.activities || selected.activities.length < 1)">
                {{'cc_activities_required' | translate}}
            </span>
            <div class="col-md-3 col-sm-3 ps-0 pe-0 text-end" *ngIf="informationAttachments?.length > 0">
                <label class="mt-2">{{ "ComplaintForm_UploadedAttachments" | translate }}</label>
            </div>
            <div class="col-md-9 col-sm-9 p-0 selectfile" *ngIf="informationAttachments?.length > 0">
                <div class="already-uploaded-attachments-main">
                    <div class="row"
                        *ngFor="let informationAttachment of informationAttachments; let attachmentIndex = index;">
                        <div class="col-md-11 col-sm-11"><a class="fileInfo"
                            (click)="downloadAttachementFromAPI(informationAttachment?.fileId,informationAttachment?.name)"
                            style="cursor: pointer;" [ngClass]="checkActivityAttachmentRemoved(attachmentIndex) ? 'to-be-removed' : ''"
                            target="_blank">{{informationAttachment?.name}}</a>
                        </div>
                        <div class="col-md-1 col-sm-1">
                            <button type="button" class="widgetButtoSmall attachButton" *ngIf="!(selected.statusId == 0 || selected.statusId==3)"
                                (click)="uploadedActivityAttachmentRemove(informationAttachment)"> X </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dropzone" style="min-height: 50px; border-style: dashed; border-width: 2px;">
                <p-fileUpload #createUploaderActivities name="files[]" chooseLabel="Select Files"
                    [disabled]="selected.statusId == 0 || selected.statusId==3"
                    [showUploadButton]="false" [showCancelButton]="true" chooseIcon="null"
                    removeStyleClass="p-overlaypanel1" [multiple]="true" chooseStyleClass="p-overlaypanel"
                    [maxFileSize]="60000000" [auto]="true" class="" [customUpload]="true"
                    (uploadHandler)="myUploaderActivities(createUploaderActivities,$event)">
                    <ng-template let-file let-index pTemplate="file">
                        <ul class="p-upload-files p-reset">
                            <li class="p-file row">
                                <div class="col-md-11 col-sm-11" [pTooltip]="file?.name" [escape]="false"
                                    tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                    <span class="p-filename">{{file?.name}}</span>
                                </div>
                                <div class="col-md-1 col-sm-1" [pTooltip]="removeText" [escape]="false"
                                    tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                    <button type="button" class="widgetButtoSmall attachButton"
                                        (click)="removeActivityFile(file ,createUploaderActivities)"> X </button>
                                </div>
                            </li>
                        </ul>
                    </ng-template>
                </p-fileUpload>
            </div>
            <!-- <span style="font-size: 9px; color: gray">{{'cc_actionplan_accepted_files' | translate}}: *.pdf, *.xls,
                *xlsx, *.png, *.jpeg, *.csv</span> -->
        </div>
    </div>

    <!-- buttons -->
    <div class="row" style="padding-top: 40px;">
        <div class="col-sm-9 btn-primary-red">
            <!-- assign button -->
            <button type="button" style="float: right;" (click)="assignCard()"
                *ngIf="selected.statusId == 0 || selected.statusId == 1" class="btn btn-primary widgetButtoSmall">
                {{'cc_action_plan_action_form_assign' | translate | uppercase}}
            </button>

            <!-- complete button -->
            <button type="button" style="float: right;"
                *ngIf="(selected?.statusId == 2|| selected?.statusId == 4) && tokenForUser?.isActionOwner"
                (click)="completeCard()" class="btn btn-primary widgetButtoSmall" [disabled]="disableSubmitBtn">
                {{'cc_action_plan_action_form_complete' | translate | uppercase}}
            </button>

            <!-- reject button -->
            <button type="button" style="float: right; margin-right: 10px;" (click)="rejectCard()"
                class="btn btn-default widgetButtoSmall"
                *ngIf="(selected.statusId == 2 || selected.statusId == 4) && selected.isRejected == false"
                [disabled]="disableRejectBtn">
                {{'cc_action_plan_form_reject' | translate | uppercase}}
            </button>

            <!-- cancel button -->
            <button type="button" class="btn btn-default widgetButtoSmall" [disabled]="requestMade"
                (click)="returnToActionPlanBoard()">
                {{'cc_action_plan_action_form_cancel' | translate | uppercase}}
            </button>

            <!-- save button -->
            <button type="button" class="btn btn-default ms-2 widgetButtoSmall" (click)="onSave()"
                [disabled]="requestMade" *ngIf="selected.statusId != 3">
                {{'cc_action_plan_action_form_save' | translate | uppercase}}
            </button>

            <!-- reopen button -->
            <button type="button" style="float: right;" *ngIf="selected.statusId == 3" (click)="reopenCard()"
                [disabled]="requestMade" class="btn btn-primary widgetButtoSmall">
                {{'cc_action_plan_action_form_reopen' | translate | uppercase}}
            </button>
        </div>
    </div>

</form>
</div>


<p-toast position="top-right"></p-toast>
