<div class="row rejectpage">
    <div class="row">
      <div class="col-1"></div>
      <div class="col-10">
        <form [formGroup]="customerFeedbackRejectForm">
          <p-card>                     
            <div class="row mt-3">
              <span style="font-weight: bold;">Provide your Feedback</span>
              <div class="row mt-2">                
                <textarea formControlName="customerFeedbackRejectReason" class="form-control ms-2" cols="20"
                  fieldlabel="CustomerFeedback" rows="2" placement="top" tooltipStyleClass="toolTipClass">
                </textarea>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-10"></div>
              <div class="col-2">
                <button type="button" [disabled]="customerFeedbackRejectForm.invalid"
                [ngClass]=" customerFeedbackRejectForm.invalid ? 'widgetButtoSmall grayButton' : 'widgetButtoSmall'"
                style="float: right !important;" (click)="submitCustomerFeedback()">{{'ButtonSubmit'|translate}}</button>
              </div>
            </div>
          </p-card>
        </form>
      </div>
      
    </div>
  </div>
  