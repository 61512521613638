import { Component, OnInit, ComponentFactoryResolver, ViewChild, Output, EventEmitter, ElementRef, OnChanges, SimpleChanges, AfterViewInit, HostListener } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Subscription, map } from 'rxjs';
import { PopupPlaceHolderDirective } from 'src/app/reusables/directives/popup-place-holder/popup-place-holder.directive';
//import { PopupPlaceHolderDirective } from 'src/app/reusables/directives/popup-place-holder.directive';
import { ComplaintDetailsService } from 'src/app/services/complaintDetails/complaint-details.service';
import { AccessRestrictionConfidentialComponent } from '../access-restriction-confidential/access-restriction-confidential.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EmailServiceTempService } from 'src/app/services/email-service-temp.service';
import { DatePipe, ViewportScroller, formatDate } from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UsersService } from "./../../../services/users/users.service";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SubmitConfirmationPopupComponent } from '../submit-confirmation-popup/submit-confirmation-popup.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MenuItem, MessageService } from 'primeng/api';
import { SendEmailSiteActionsComponent } from '../send-email-site-actions/send-email-site-actions.component';
import { ChooseRolePopUpComponent } from '../choose-role-pop-up/choose-role-pop-up.component';
import { ReleaseNotificationsService } from 'src/app/services/notifications/release-notifications.service';
import { CountriesServiceService } from 'src/app/services/countries--service.service';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
import { NewIssueService } from 'src/app/services/newIssue/new-issue.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { DeleteDraftComponent } from '../delete-draft/delete-draft.component';
import { ComplaintModel } from '../Model/ComplaintModel';
import { UserInfoDetailsService } from 'src/app/services/userInfoDetails/user-info-details.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ButtonVisibilityServiceService } from 'src/app/services/button-visibility-service.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ComplaintStatuses, PermissionCode, RoleCode } from 'src/app/enum';
import { AuthorizationMiddleWareService } from 'src/app/services/authorization-middleware.service';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import { OriginalConfigurationService } from 'src/app/services/original-configuration.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';
import { MatrixClass } from 'src/app/models/new-issue/MatrixClass';
import { ComplaintLockWarningComponent } from 'src/app/reusables/complaint-lock-warning/complaint-lock-warning.component';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { StatusUpdatePopupComponent } from '../status-update-popup/status-update-popup.component';

@Component({
    selector: 'app-new-issue-home',
    templateUrl: './new-issue-home.component.html',
    styleUrls: ['./new-issue-home.component.css']
})
export class NewIssueHomeComponent implements OnInit, AfterViewInit {
    @ViewChild(PopupPlaceHolderDirective, { static: false }) dialogHost?: PopupPlaceHolderDirective;
    @ViewChild('captureFormValue') captureFormElement: ElementRef;
    @ViewChild('assignmentFormValue') assignmentFormElement: ElementRef;
    @ViewChild('resolvingUnitFormValue') resolvingFormElement: ElementRef;
    @ViewChild('closeFormValue') closeFormElement: ElementRef;
    @ViewChild('effectivenessFormValue') effectivenessFormElement: ElementRef;


    classValueChange: any;
    issueCaptureAccExpanded = false;
    issueAssignAccExpanded = false;
    issueResolutionAccExpanded = false;
    issueClosedAccExpanded = false;
    effSusAccExpanded = false;
    allAccExpanded = false;
    accButtonText = 'Expand All';
    private _isExpanded = false;
    disableField: any = {};
    justificationReadOnly: boolean = true;

    //complaintId
    complaintId !: number;
    complaintData !: any;
    filteredComplaint !: any;

    expandIndex: any = [0];
    role: string = 'Issue Owner';
    CCRPNo: string = '';
    confidentialCheck: boolean = false;

    ref: DynamicDialogRef;
    externalRadioCheck: boolean = false;
    internalRadioCheck: boolean = true;
    issueCaptureMainForm!: FormGroup;
    issueAssignmentForm!: FormGroup;
    issueSubmitted: boolean = false;
    // issueSubmitted: boolean = false;
    isSubmitted: boolean = false;
    users: any[] = [];
    menuItems: MenuItem[];
    notifications: any;
    progressValue: number = 0;
    complaintStatus: string;
    userAccessData = null;
    enumPermission = PermissionCode;
    enumRoleCode = RoleCode;
    enumComplaintStatuses = ComplaintStatuses;
    previousTargetResolutionDate: boolean = false;

    /* Tooltip text variable*/
    saveButtonText = 'If for any reason you cannot complete the issue at this time, use this button to save it as a Draft. Issue Capture should be completed and submitted ASAP.';
    submitButtonText = 'Once you have provided all the information you have about this issue, use the "Submit" button to send it to the selected Resolving Unit. They should confirm Resolution Owner within 24hrs.';
    now = new Date(Date.now());
    now1 = this.now.setMonth(this.now.getMonth() + 9);
    myDate1 = this.datePipe.transform(this.now1, 'yyyy-MM-dd');
    complaintNumber: string = '';
    isEffectivnessOption: string = '';
    closedBy!: string;
    ComplaintLogDates!: any;
    effectinvessDeadlineDate!: any;
    issueClosedSave: boolean = false;
    ccrpNumber: any;
    issueComplaintStatus: any;
    highImpactValidationViewModelDeadline: any;
    paramForHighImpactLabel: any;
    processStepIdInput: any;
    complaintValidations: any = [];
    ActionNameForComplaints: string = "Submit";
    fieldNameForFailureMode: string = "None";
    disableBtn: any = {};
    today = new Date();
    enableSAPQN: boolean = false;
    reviewRequired_Id: boolean = false;
    issueCaptureEnable: boolean = false;
    readonlyObj: any = {
        PrimaryComplaintOwnerReadOnly: false, substituteReadOnly: false,
        proposedResolutionOwnerReadOnly: false, PartiesReferenceReadOnly: false,
        PrimaryResolutionOwnerReadOnly: false, resolutionOwnerTeamReadOnly: false, AddNominatedPersonReadOnly: false
    };
    complaintCreatedDate:any = new Date();
    ncrLiveDate:any = new Date(environment.ncrLiveDate);

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        private route: ActivatedRoute,
        public router: Router,
        public complaintDetailsService: ComplaintDetailsService,
        private formBuilder: FormBuilder,
        private emailServiceTempService: EmailServiceTempService,
        private fb: FormBuilder,
        private usersService: UsersService,
        public dialogService: DialogService,
        public dashboardService: DashboardService,
        private datePipe: DatePipe,
        private titleService: Title,
        private newIssueService: NewIssueService,
        private sessionStorage: SessionStorageServiceService,
        private translate: TranslateService,
        private notificationsService: ReleaseNotificationsService,
        private localStorage: LocalStorageServiceService,
        private messageService: MessageService,
        private userInfoDetailsService: UserInfoDetailsService,
        private countryService: CountriesServiceService,
        public breadcrumbService: BreadcrumbService,
        public authorizationService: AuthorizationService,
        private spinner: NgxSpinnerService,
        private buttonVisibilityService: ButtonVisibilityServiceService,
        private el: ElementRef,
        private authorizationMiddleWare: AuthorizationMiddleWareService,
        private scroller: ViewportScroller,
        private resolvingUnitService: ResolvingUnitServiceService,
        private OriginalRatingService: OriginalConfigurationService,
        private toasterService: ToasterserviceService) {
        // this.getUsers();   // Commented By Ram - As there is no api : EmailList and which makes unnecessary api call
        this.notificationsService.getNotifications().subscribe(
            (response) => {
                this.notifications = response; //{description: "test" value: "false"}
            });
    }

    ngOnInit(): void {
        this.spinner.show();
        this.countryService.getCountries().subscribe((Response) => {
            this.localStorage.addToLocalStorage("mdregion", Response);
        });

            if(this.sessionStorage.getData('userInfo')?.email != undefined){
                this.userInfoDetailsService.getUserInfo(this.sessionStorage.getData('userInfo')?.email).subscribe((resp)=>{

                })
              }
              else{
                this.dashboardService.isGetGeid.subscribe(async geid =>{
                  if(geid != ""){
                    this.userInfoDetailsService.getUserInfo(this.sessionStorage.getData('userInfo')?.email).subscribe((resp)=>{

                    })
                  }
                })
              }


        //assign complaint no from route parameters
        if (this.route.snapshot.paramMap.get('id')) {
            //console.log("+++++++++++++++id data", this.route.snapshot.paramMap.get('id'));
            if (this.isAlphaNumericCheck(this.route.snapshot.paramMap.get('id'))) {
                this.complaintNumber = this.route.snapshot.paramMap.get('id');
            }
            else {
                this.complaintId = Number(this.route.snapshot.paramMap.get('id'));
            }
            //get complaint data
            //console.log("console1");

            if (this.router.url.includes('complaint/Deleted')) {
                this.dashboardService.getReadOnlyComplaintDetails(this.complaintId, this.complaintNumber).subscribe((response: ComplaintModel | any) => {
                    if (response?.exceptionCode) {
                        if (response?.exceptionCode == 'NOTFOUND') {
                            this.spinner.hide();
                            this.router.navigate(['Exceptions/ComplaintNotFound/', this.complaintNumber ? this.complaintNumber : this.complaintId])
                        }
                    }
                    else {
                        this.complaintData = response;
                        this.complaintId = response?.header?.id;
                        this.complaintNumber = response?.header?.complaintNumber;
                        this.breadcrumbService.menuItems = [
                            { label: 'NEW ISSUE' }
                        ];

                        this.issueCaptureMainForm.patchValue({
                            id: this.complaintData?.id
                        });
                        this.CCRPNo = (this.complaintData == undefined) ? '' : this.complaintData?.header?.complaintNumber;
                        this.confidentialCheck = (this.complaintData == undefined) ? false : this.complaintData?.header?.isConfidential;
                        this.headerSection?.patchValue({
                            isConfidential: (this.complaintData == undefined) ? false : this.complaintData?.header?.isConfidential,
                            issueComplaintStatus: (this.complaintData == undefined) ? 'New' : this.complaintData?.header?.complaintStatusName,
                            complaintStatusId: (this.complaintData == undefined) ? 1 : this.complaintData?.header?.complaintStatus,
                            complaintNumber: (this.complaintData == undefined) ? '' : this.complaintData?.header?.complaintNumber,
                            rowVersion: (this.complaintData == undefined) ? '' : this.complaintData?.header?.rowversion,
                            rowVersionString: (this.complaintData == undefined) ? '' : this.complaintData?.header?.rowversionString,
                            complaintCurrentRating: 'Unconfirmed'
                        });
                        this.ccrpNumber = this.headerSection.get('complaintNumber')?.value;
                        this.issueComplaintStatus = this.headerSection.get('issueComplaintStatus')?.value;
                        if (this.issueComplaintStatus == 'Completed') {
                            this.headerSection.controls['isConfidential']?.disable();
                        }
                        this.accordionSetup();
                        this.role = 'None'
                        if (this.role == 'None') {
                            this.issueCaptureMainForm?.disable();
                        }
                        this.spinner.hide();
                    }
                })
            }
            else {
                this.filteredComplaint = this.dashboardService.getComplaintData(this.complaintId, this.complaintNumber).subscribe((response: ComplaintModel | any) => {
                    if (response?.exceptionCode) {
                        this.spinner.hide();
                        if (response?.exceptionCode == 'DELETED') {
                            this.router.navigate(['Exceptions/ComplaintHasBeenDeleted/', this.complaintNumber ? this.complaintNumber : this.complaintId])
                        }
                        else if (response?.exceptionCode == 'NOTFOUND') {
                            this.router.navigate(['Exceptions/ComplaintNotFound/', this.complaintNumber ? this.complaintNumber : this.complaintId])
                        }
                        else if (response?.exceptionCode == 'CONF_OPR_NOT_ALLOWED') {
                            this.router.navigate(['Exceptions/ConfidentialNotAuthorizedOperation/', this.complaintNumber ? this.complaintNumber : this.complaintId])
                        }
                    }

                    if (response.id) {
                        this.complaintData = response;
                        this.complaintCreatedDate = new Date(this.complaintData?.header?.createDate);

                        this.complaintDetailsService.complaintValidation(this.complaintCreatedDate<this.ncrLiveDate ? false : true).subscribe((response) => {
                          this.complaintValidations = response.map((anElement) => ({
                            ...anElement,
                            complaintStatusIdsArray: anElement.complaintStatusIds.split(','),
                            roleIdsArray: anElement.roleIds.split(',')
                          }))
                        },
                          (error) => {
                            this.spinner.hide();
                            this.messageService.add({
                              severity: 'error',
                              summary: error ? error : 'Something went wrong!'
                            });
                          });


                        this.complaintId = response?.header?.id;
                        this.complaintNumber = response?.header?.complaintNumber;
                        this.titleService.setTitle(`CCRP No. ${this.complaintNumber}`);
                        this.breadcrumbService.menuItems = [
                            { label: 'ISSUE', routerLink: '/complaint' },
                            { label: response.header.complaintNumber }
                        ];
                        this.authorizationService.userAccessDetail.subscribe(async detail => {
                            this.userAccessData = detail;
                            // console.log("====", this.userAccessData);
                            this.checkPermission();
                            this.authorizationBtnDisable();
                        }, (error) => {
                            this.spinner.hide();
                            this.messageService.add({
                                severity: 'error',
                                summary: error ? error : 'Something went wrong!'
                            })
                        });
                        //  console.log("11111111111111111111", this.complaintData?.header);
                        if (this.complaintData?.header?.complaintStatus == 2) {
                          this.ActionNameForComplaints = "SaveComplaintCaptureSection";
                        }
                        else if (this.complaintData?.header?.complaintStatus == 3) {
                            this.ActionNameForComplaints = "Assign";
                        }
                        else if (this.complaintData?.header?.complaintStatus == 4 && this.complaintData?.areaComplaintCollection?.complaintInformationSection?.isContainmentActionRequired && this.complaintData?.areaComplaintCollection?.complaintInformationSection?.containmentAction?.execution == null) {
                            this.ActionNameForComplaints = "ContainmentActionExecuted"
                        }
                        else if (this.complaintData?.header?.complaintStatus == 4 || this.complaintData?.header?.complaintStatus == 5 || this.complaintData?.header?.complaintStatus == 6 || this.complaintData?.header?.complaintStatus == 11 || this.complaintData?.header?.complaintStatus == 12 || this.complaintData?.header?.complaintStatus == 9) {
                            this.ActionNameForComplaints = "Resolve";
                            //   console.log("inside-----");
                        }
                        else if (this.complaintData?.header?.complaintStatus == 7 || this.complaintData?.header?.complaintStatus == 13) {
                            this.ActionNameForComplaints = "Close";
                        }
                        else if (this.complaintData?.header?.complaintStatus == 8) {
                            this.ActionNameForComplaints = "ClosureConfirmation";
                        }
                        else {
                            this.ActionNameForComplaints = "Submit";
                        }
                        this.initialSetting();
                        let processStepId = null;
                        processStepId = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.inWhichProcessStep?.identification?.id ? this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.inWhichProcessStep?.identification?.id : null;
                        this.onGetAccountableVerifiedUsers(this.complaintData?.areaComplaintResolution?.isHVDCComplaint, processStepId);
                        this.highImpactValidationViewModelDeadline = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpactValidationViewModel?.deadlineDate;
                        this.paramForHighImpactLabel = { 0: formatDate(new Date(this.highImpactValidationViewModelDeadline), 'dd-MM-yyyy HH:mm', 'en_US') }
                        this.showClassValue();
                        this.updateProgressBar();
                        this.spinner.hide();
                        if (this.issueComplaintStatus == 'Completed') {
                            this.headerSection.controls['isConfidential']?.disable();
                        }

                        const qnNumber = this.complaintData?.sapComplaintInfo?.qnNumber;
                        if(qnNumber != null && qnNumber?.length > 0){
                            this.onSetComplaintLock();
                        }

                        if(this.complaintData?.sapComplaintInfo?.sapLockStatus == true && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
                            this.issueCaptureMainForm?.disable();
                            this.toasterService.showInfo(this.complaintData?.sapComplaintInfo?.sapLockStatusMessage);
                        }
                    }
                },
                    (err) => {
                        this.router.navigateByUrl(`**`);
                    });

            }


        }
        else {
            this.titleService.setTitle('CCRP - Create new issue');
            this.complaintId = +this.route.snapshot.paramMap.get('id')!;
            // this.progressValue = '50';
            this.breadcrumbService.menuItems = [
                { label: 'NEW ISSUE' }
            ];
          // logic for complaint validation - default NCR
          this.complaintDetailsService.complaintValidation(this.complaintCreatedDate<this.ncrLiveDate ? false : true).subscribe((response) => {
            this.complaintValidations = response.map((anElement) => ({
              ...anElement,
              complaintStatusIdsArray: anElement.complaintStatusIds.split(','),
              roleIdsArray: anElement.roleIds.split(',')
            }))
          },
            (error) => {
              this.spinner.hide();
              this.messageService.add({
                severity: 'error',
                summary: error ? error : 'Something went wrong!'
              });
            });

        }

        this.countryService.getCountries().subscribe((Response) => {
            this.localStorage.addToLocalStorage("mdregion", Response);
        }, (error) => {
            this.spinner.hide();
            this.messageService.add({
                severity: 'error',
                summary: error ? error : 'Something went wrong!'
            })
        });

        // this.newIssueService.getDPSLinkURLs().subscribe((Res) => {
        //     this.getDPSLinkURLData = Res;
        // })

        this.newIssueService.getCurrentUserDetails().subscribe(
            (response) => {
                this.spinner.hide();
                this.sessionStorage.setData('currentUser', response)

            }, (error) => {
                this.spinner.hide();
                this.messageService.add({
                    severity: 'error',
                    summary: error ? error : 'Something went wrong!'
                })
            });

        // this.getUsers();  Commented By Ram - As there is no api : EmailList and which makes unnecessary api call
        this.issueCaptureMainForm = this.fb.group({
            id: [''],
            headerSection: this.fb.group({
                issueComplaintStatus: [{ value: 'New', disabled: true }],
                complaintStatusId: [1],
                deleteReason: [''],
                rowVersion: [''],
                rowVersionString: [''],
                complaintNumber: [''],
                isConfidential: [false],
                complaintCurrentRating: [],
                issueCaptureDataPercentage: [],
                createDate: [],
                salesForceId: []
            }),
            issueCaptureForm: this.fb.group({
                PrimaryComplaintOwner: [[], /*Validators.required*/],
                substitute: [null],
                supervisor: [],
                CustomerType: ['External'],
                customerNameData: [],
                affectedCustomer: [''],
                affectedCustomerHidden: [null],
                Customer: ['', /*Validators.required*/],
                customerNameHidden: [null],
                CustomerContact: ['', /*Validators.required*/],
                customerContactNameHidden: [null],
                Subject: ['', /*Validators.required*/]
            }),
            issueDescriptionForm: this.fb.group({
                Reason: ['', /*Validators.required*/],
                WhatIssueDescription: [],
                whyIssueDescription: [],
                howIssueDescription: [],
                issueStep: [''],
                inWhichProcessStep: [''],
                whereIssueDescription: [''],
                WorkCentreandPlant: [''],
                workCentreHidden: [''],
                //issueLocationData: [''],
                whenIssueDescription: [],
                whereWasTheIssueDetectedId: [],
                customerExpectedResolutionDescription: [],
                uploadedAttachments: [],
                ContainmentActionsId: [],
                IsContainmentActionRequired: [false],
                deadline: [],
                ContainmentActionDescription: [],
                containmentExecutedDate: [],
                EightDRCAIsRequiredByCustomer: [false],
                TargetResolvingDate: ['', /*[Validators.required]*/],
                targetResolutionHiddenDate: ['', /*[Validators.required]*/],
                // isComplaintDescriptionSectionMandatory: [],
                // IsComplaintDescriptionSectionMandatory: ['']
                dateChangeReason: [],
                changeDescription: [],
                targetResolvingDateChangeCount: [],
                changeReasonId: [],
                targetResolvingDateInitialValue: [],
                CountryCodeWhereTheIssueHappen: ['', /*Validators.required*/],
                CustomerExpectationForResolution: [],
            }),
            ratingForm: this.fb.group({
                availabilityRating: [],
                functionalityRating: [],
                onTimeDeliveryRating: [],
                cyberSecurityRating: [],
                productComplianceRating: [],
                ohsRiskRating: [],
                marginRating: [],
                reputationRating: [],
                massMediaRating: [],
                designChangeRating: [],
                instancesRating: [],
                resolutionRating: [],
                affectedCustomerRating: [],
                controlRating: [],
                rpn: [],
                impactRating: [],
                classValue: [],
                classId: [0],
                HasHightImpactOnABB: [],
                IsComplaintDescriptionSectionMandatory: [null]
            }),
            additionalSubjectForm: this.fb.group({
                resolvingUnit: ['', /*Validators.required*/],
                resolvingUnitArray: [''],
                proposedResolutionOwner: [''],
                Coordinators: ['', /*Validators.required*/],
                verificationTeam: [''],
                ABBProductCategory: ['', /*Validators.required*/],
                issueProductArray: [''],
                failureMode: ['', /*Validators.required*/],
                failureModeBind: [''],
                DefectTypeandDefectCodeGroupType: [''],
                defectTypeHiddenField: [],
                defectWorkCenterHidden: [],
                isQnNumberGeneratedCheck: [''],
                sapComplaintInfoDetails: [''],
                sapDefectCauseIdAddnSub: [''],
                sapLockStatus : [],
                IsQnNumberGenerated: [false],
                defectCauseHiddenFieldAdnSub: [''],
                sapDefectCauseId: [''],
                abbProductSerialNo: [''],
                purchaseOrderNo: [''],
                abbProjectNumber: [''],
                abbProjectName: [''],
               // CountryCodeWhereTheIssueHappen: ['', /*Validators.required*/],
                quantity: [''],
                articleNumber: [''],
                itemDesignation: [''],
                abbCustomerReference: [''],
                supplier: [''],
                supplierData: [],
                deliveryDate: [''],
                commissionDate: [''],
                ManufacturingDate: [''],
                warrantyReference: [''],
                warrantyStartDate: [''],
                warrantyEndDate: [''],
                documentID: [''],
                qNumberIssue: [''],
                //ProductAlreadyDelivered: [''],
                //isNoofUnitsShippedRequired: [false],
                //NoOfUnitsShipped: [''],
                PartiesReference: [''],
                SendCopyToParties: [false],
                // uploadRCAAttachments: [],
                // resolutionAttachments: [],
                hiddenIsHVDCComplaint: [false],
                hiddenPreviousClass: [''],
                IsBUExceptionFieldsMandatory: [false],
                BuCode: [''],
                warrantyLifeCycleId:[''],
                isVerifiedSerial: [false],
                updatedSerialNosList:[],
                oldSerialNosList:[],
                dpsCCRPAppId:[],
                dpsEmailIdOfUser:[],
                modificationFlagForDps:[false]
            }),
            issueAssignmentForm: this.fb.group({
                PrimaryResolutionOwner: [''/*, [Validators.required]*/],
                resolutionOwnerTeam: [''],
                ccrpNo: [''],
                quickFixComplaint: [],
                quickFixVariant: [0],
                verifiedCheckBox: [''],
                coordinatorList: [''],
                coordinatorName: [''],
                AddNominatedPerson: [''],
                solutionWillBeValidatedByCoordinator: [true],
                QuickFixRCANotReqDescription: ['']
            }),
            issueResolutionForm: this.fb.group({
                EstimatedCostView: [''],
                ImpactOnInstalledBase: [''],
                TotalInstalledBase: [''],
                QNumberResolvingUnit: [''],
                RCACorrectiveActions: [false],
                SpecificationName: [''/*, Validators.required*/],
                howProductFailMeetSpecification: [''/*, Validators.required*/],
                ContainmentActionsExecution: [],
                WhatContainmentActionsWhereTaken: [''],
                whatWasTheRootCauseOfThisIssue: [''/*, Validators.required*/],
                CategoryKeyword: [''],
                howDidWeResolveThisIssue: [''/*, Validators.required*/],
                chipData: [''],
                Upload: [''],
                // Attachments: [''],
                ActualRUCost: [''],
                COPQCategory: [''],
                COPQCategoryAllData: [''],
                pgCategory: [''/*, Validators.required*/],
                PGRCACategoryAllData: [''],
                learn: '',
                desc: '',
                id: '',
                Rating: [''],
                issueAssigned: [''],
                selectRole: [''],
                selectRole1: [''],
                issueStatus: [''],
                availabilityRating: [''],
                functionalityRating: [''],
                onTimeDeliveryRating: [''],
                cyberSecurityRating: [''],
                productComplianceRating: [''],
                ohsRiskRating: [''],
                marginRating: [''],
                reputationRating: [''],
                massMediaRating: [''],
                designChangeRating: [''],
                instancesRating: [''],
                resolutionRating: [''],
                affectedCustomerRating: [''],
                controlRating: [''],
                impactRating: [''],
                classValue: [''],
                rpn: [''],
                JustificationCurrent: [''],
                UploadRCAAttachments: [],
                resolutionAttachments: [],
                manualChangeClass: [],
                ReviewRequired: [''],
                eightDReportRequired: [''],
                EightDJustificationCurrent: [''],
                isEightDJustificationCurrent: [false],
                reviewRequiredJustification: [''],
                DefectCauseDefectCauseCodeGroup: [''],
                // defectCauseHiddenFieldRCA: [''],
                //sapDefectCauseId: [''],
                //sapComplaintInfoDetails: [''],
                // isQnNumberGeneratedCheck: [''],
                //IsQnNumberGenerated: [false],
                //sapLockStatus : [],
                ProductAlreadyDelivered: [''],
                isNoofUnitsShippedRequired: [false],
                NoOfUnitsShipped: [''],
                manualClassUpgrade:[],
                unAssignClassConfirmation:[],
                idsOfCheckedActionItems:[],
                flagForCheckedActionItems:[false],
                lessonLearnt:['']
            }),
            issueClosedForm: this.fb.group({
                AwaitingCustomerExecution: [false],
                customerComments: [''],
                //attachments: ['', [Validators.required]],
                ExpectedExecutionDate: [''/* , Validators.required*/],
                ExpectedExecutionHiddenDate: [''],
                customerContactStatus: [''],
                closureConfirmationStatus: [''],
                hiddenClosureConfirmationStatusCode: [''],
                customerResponse: [''],
                sendFeedback: ['Yes'],
                isCustomerFeedbackJustification: [false],
                CustomerFeedbackJustification: [''],
                uploadedAcceptanceAttachments: []
            }),
            effectivenessForm: this.fb.group({
                id: [''],
                IsEffective: [''],
                DeadlineDate: [this.myDate1/*, [Validators.required]*/],
                effectiveChangedValue: [this.myDate1],
                EffectivenessReason: [''],
                reopenReason: [''],
                uploadedEffectivenessAttachments: [''],
            })
        });

        //assign complaint no from route parameters
        this.complaintId = +this.route.snapshot.paramMap.get('id')!;
        // this.initialSetting();
        //this.subjectInc(); commented unnecessary API Call - Ram

        // this.getUsers();  Commented By Ram - As there is no api : EmailList and which makes unnecessary api call
        if (this.complaintId != 0 && this.complaintData != undefined) {
            this.titleService.setTitle('CCRP No. ' + this.complaintData?.number);
            this.issueCaptureMainForm.patchValue({
                id: this.complaintId,
                // progressValue: this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.issueCaptureDataPercentage
            })
        }

        this.menuItemSetup();

        this.headerSection.get('issueComplaintStatus').valueChanges.subscribe((status) => {
            if (status) {
                this.accordionSetup();
                if (status == 'New' || status == 'Draft') {
                    this.issueRating.enable();
                }
                else {
                    this.issueRating.disable();
                    if (status == 'Completed') {
                        this.headerSection.controls['isConfidential']?.disable();
                    }
                }
                if (status == 'New' || status == 'Draft' || status == 'Empty') {
                    this.menuItems[0]?.items.splice(5, 1);
                }
                // if (status == 'In Process') {
                    // api call for check SAPQN
                    // this.newIssueService.checkSAPQNEnable('EnableSAPQN').subscribe((response) => {
                    //     if (response?.value) {
                    //         this.enableSAPQN = response?.value == 'true' ? true : false;
                    //     }
                    // })
                // }
            }
        })

        this.newIssueService.checkSAPQNEnable('EnableSAPQN').subscribe((response) => {
            if (response?.value) {
                this.enableSAPQN = response?.value == 'true' ? true : false;
            }
        })

        if (this.headerSection.get('issueComplaintStatus')?.value == 'New') {
            this.accordionSetup();
        }

        this.issueResolution.get('manualChangeClass').valueChanges.subscribe((status) => {
            if (status) {
                this.onManualClassDDLChange(status);
            }
        })

        this.issueResolution.get('eightDReportRequired').valueChanges.subscribe((status) => {
            if (status && status == 'No') {
                this.issueResolution.get('EightDJustificationCurrent').addValidators(Validators.required);
                this.issueResolution.get('EightDJustificationCurrent').updateValueAndValidity();
            }
        })
        this.issueDescription.get('issueStep')?.valueChanges.subscribe((status) => {
            if (status) { this.GetCoordinatorsForResolvingUnit(status?.id); }
        })

        this.issueCaptureMainForm.get('issueCaptureForm').valueChanges.subscribe(() => {
            this.updateProgressBar()
        });
        this.issueCaptureMainForm.get('ratingForm').valueChanges.subscribe(() => {
            this.updateProgressBar()
        });
        this.issueCaptureMainForm.get('issueDescriptionForm').valueChanges.subscribe(() => {
            this.updateProgressBar()
        });
        this.issueCaptureMainForm.get('additionalSubjectForm').valueChanges.subscribe(() => {
            this.updateProgressBar()
        });
        // this.spinner.hide();

        this.complaintDetailsService.isTimeUp.subscribe(async time =>{
            if(time !== null){
                this.openSessionTimeOutDialog();
            }
        })

        window.addEventListener('beforeunload',(event)=>{
            const qnNumber = this.complaintData?.sapComplaintInfo?.qnNumber;
            if(qnNumber != null && qnNumber?.length > 0){
                const request = {
                    "complaintId": this.complaintData?.header?.id
                }

                this.complaintDetailsService.deleteComplaintLock(request).subscribe((resp)=>{
                    if(resp){

                    }
                }, error =>{
                    this.toasterService.showError(error ? error : 'Something went wrong!')
                })

                return event.returnValue = false;
            }
            return false
        })

        //User Story 7797: RITM0438869 | Make the fields non-mandatory | Manufacturing date & warranty date
        this.issueResolution.controls['resolvingUnit']?.valueChanges.subscribe((changes) => {
          if(changes) {
            const mandateValue = this.issueResolution.controls['IsBUExceptionFieldsMandatory']?.value;
            const prodDeliveredValue = this.issueResolution.controls['productAlreadyDelivered']?.value;
            if(mandateValue && prodDeliveredValue?.value == 'Yes') {
              this.issueResolution.patchValue({
                IsBUExceptionFieldsMandatory: true
              })
              this.setFieldValidationSeparate(this.ActionNameForComplaints);
            }
            else if(prodDeliveredValue?.value == 'No'){
                  this.issueResolution.patchValue({
                      IsBUExceptionFieldsMandatory: false
                    })
                  }
              else if(!prodDeliveredValue?.value)
              {
                 this.issueResolution.patchValue({
                    IsBUExceptionFieldsMandatory: false
                 })
                 this.setFieldValidationSeparate(this.ActionNameForComplaints);
              }
          }
        })
        // window.addEventListener('unload', ()=>{
        //     setTimeout(() => {
        //     });
        // })

      this.headerSection.controls['complaintCurrentRating'].disable();
      this.headerSection.controls['complaintNumber'].disable();
    }
    //User Story 7797: RITM0438869 | Make the fields non-mandatory | Manufacturing date & warranty date
    setFieldValidationSeparate(action) {
        for (const controlName in this.issueCaptureMainForm?.get('issueResolutionForm')['controls']) {
          if(controlName == 'ProductAlreadyDelivered' || controlName == 'ManufacturingDate' || controlName == 'warrantyEndDate'|| controlName=='abbProductSerialNo'|| controlName=='quantity' || controlName=='NoOfUnitsShipped') {
            let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
            if (validationData) {
              const control = this.issueCaptureMainForm?.get('issueResolutionForm').get(controlName);
              const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));
              control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
              control.updateValueAndValidity(); // Update the validation status
            }
            else {
                this.issueCaptureMainForm?.get('issueResolutionForm').get(controlName).clearValidators();
                this.issueCaptureMainForm?.get('issueResolutionForm').get(controlName).updateValueAndValidity();
            }
          }
        }
    }

    ngOnDestroy(): void{
        //this.onDeleteComplaintLock();
        const qnNumber = this.complaintData?.sapComplaintInfo?.qnNumber;
        if(qnNumber != null && qnNumber?.length > 0){
            this.onDeleteComplaintLock();
        }
    }

    // @HostListener('window:beforeunload', ['$event'])
    // onDeleteLock() {
    //     const qnNumber = this.complaintData?.sapComplaintInfo?.qnNumber;
    //     if(qnNumber != null && qnNumber?.length > 0){
    //         this.onDeleteComplaintLock();
    //     }
    // }


    updateProgressBar() {
        const formGroup1TotalControls = Object.keys(this.issueCapture.controls).length - 3; //3 un used controls
        const formGroup2TotalControls = Object.keys(this.issueDescription.controls).length - 9; //9 controls required in next sections controls
        const formGroup3TotalControls = Object.keys(this.additionalSubject.controls).length - 1; //1 controls part of sap qan

        const formGroup1FilledControls = this.issueCaptureFilledTotalControls();
        const formGroup2FilledControls = this.issueDescriptionTotalControls();
        const formGroup3FilledControls = this.additionalSubjectTotalControls();

        // console.log(formGroup1TotalControls,formGroup2TotalControls,formGroup3TotalControls);
        // console.log(formGroup1FilledControls,formGroup2FilledControls,formGroup3FilledControls);

        let TotalFilledControls = formGroup1FilledControls + formGroup2FilledControls + formGroup3FilledControls;
        let TotalControls = formGroup1TotalControls + formGroup2TotalControls + formGroup3TotalControls;


        this.progressValue = Math.round((TotalFilledControls / TotalControls) * 100);
        if (this.progressValue > 100) {
            this.progressValue = 100;
        }
        this.headerSection.patchValue({
            issueCaptureDataPercentage: this.progressValue
        })
    }

    issueCaptureFilledTotalControls() {
        let filledContorls = 0;
        Object.values(this.issueCapture.controls).forEach(c => {
            if (c.value !== null && c.value !== '' && c.value !== undefined) {
                filledContorls++;
            }
        });
        return filledContorls;
    }
    issueDescriptionTotalControls() {
        let filledContorls = 0;
        Object.values(this.issueDescription.controls).forEach(c => {
            if (c.value !== null && c.value !== '' && c.value !== undefined) {
                filledContorls++;
            }
        });
        return filledContorls;
    }
    additionalSubjectTotalControls() {
        let filledContorls = 0;
        Object.values(this.additionalSubject.controls).forEach((c: any) => {
            if (c.value !== null && c.value !== '' && c.value !== undefined) {
                filledContorls++;
            }
        });
        return filledContorls;
    }

    ngAfterViewInit() {
        if (this.complaintValidations.length > 0) {
            setTimeout(() => {
                //   console.log("changes Detected...", this.issueCaptureMainForm)
                this.setFieldsValidation(this.ActionNameForComplaints);
            }, 8000);
        }
        if (this.headerSection.get('issueComplaintStatus')?.value == 'New') {
            this.disableBtn = {
                saveAsDraft_issueCapture: true,
                submit_issueCapture: true
            }
        }

    }

    async setFieldsValidation(action) {
        // console.log("if new issue home",action,this.complaintValidations,this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value)

        for (let controlName in this.issueCapture?.controls) {
            let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
            // console.log("))))))))))))))))))))", validationData, controlName?.toLowerCase());
            if (validationData) {
                // console.log(">>>>", this.issueCapture);
                const control = this.issueCapture.get(controlName);
                const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

                await control.setValidators(await this.getValidators(validationRules, this.fieldNameForFailureMode));
                // console.log("capture=======", control);
                await control.updateValueAndValidity(); // Update the validation status
            }
            else {
                // console.log("else for: ", controlName,);
                await this.issueCapture.get(controlName).clearValidators();
                await this.issueCapture.get(controlName).updateValueAndValidity();
            }
        }
        for (const controlName in this.issueDescription?.controls) {
            let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
            // console.log("))))))))))))))))))))", validationData);
            if (validationData) {
                // console.log(">>>>", this.issueDescription);
                const control = this.issueDescription.get(controlName);
                const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

                await control.setValidators(await this.getValidators(validationRules, this.fieldNameForFailureMode));
                //  console.log("desc=======", control);
                await control.updateValueAndValidity(); // Update the validation status
            }
            else {
                await this.issueDescription.get(controlName).clearValidators();
                await this.issueDescription.get(controlName).updateValueAndValidity();
            }
        }
        for (const controlName in this.issueRating?.controls) {
            let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
            // console.log("))))))))))))))))))))", validationData);
            if (validationData) {
                // console.log(">>>>", this.issueRating);
                const control = this.issueRating.get(controlName);
                const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

                await control.setValidators(await this.getValidators(validationRules, this.fieldNameForFailureMode));
                //  console.log("rating=======", control);
                await control.updateValueAndValidity(); // Update the validation status
            }
            else {
                // console.log("else for: ", controlName,);
                await this.issueRating.get(controlName).clearValidators();
                await this.issueRating.get(controlName).updateValueAndValidity();
            }
        }
        for (const controlName in this.additionalSubject?.controls) {
            let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
            // console.log("))))))))))))))))))))", controlName, validationData, this.fieldNameForFailureMode);
            if (validationData) {
                // console.log(">>>>", this.additionalSubject);
                const control = this.additionalSubject.get(controlName);
                const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

                await control.setValidators(await this.getValidators(validationRules, this.fieldNameForFailureMode));
                //  console.log("additional=======", control);
                await control.updateValueAndValidity(); // Update the validation status
            }
            else {
              if((controlName=='abbProductSerialNo' && this.fieldNameForFailureMode.includes('SerialNo')) || (controlName=='purchaseOrderNo' && this.fieldNameForFailureMode.includes('PurchaseOrder')) || ((controlName=='ABBProjectNumber' || controlName=='ABBProjectName') && this.fieldNameForFailureMode.includes('ABBProject'))){
                  this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).setValidators([Validators.required]);
                  this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).updateValueAndValidity();
                }
                else{
                  await this.additionalSubject.get(controlName).clearValidators();
                  await this.additionalSubject.get(controlName).updateValueAndValidity();
                }
            }
        }
        for (const controlName in this.issueAssignment?.controls) {
            let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
            // console.log("))))))))))))))))))))", validationData);
            if (validationData) {
                // console.log(">>>>", this.issueAssignment);
                const control = this.issueAssignment.get(controlName);
                const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

                await control.setValidators(await this.getValidators(validationRules, this.fieldNameForFailureMode));
                //  console.log("additional=======", control);
                await control.updateValueAndValidity(); // Update the validation status
            }
            else {
                // console.log("else for: ", controlName,);
                await this.issueAssignment.get(controlName).clearValidators();
                await this.issueAssignment.get(controlName).updateValueAndValidity();
            }
        }
        for (const controlName in this.issueResolution?.controls) {
            let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
            // console.log("))))))))))))))))))))", validationData);
            if (validationData) {
                // console.log(">>>>", this.issueResolution);
                const control = this.issueResolution.get(controlName);
                const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

                await control.setValidators(await this.getValidators(validationRules, this.fieldNameForFailureMode));
                //  console.log("additional=======", control);
                await control.updateValueAndValidity(); // Update the validation status
            }
            else {
                // console.log("else for: ", controlName,);
                await this.issueResolution.get(controlName).clearValidators();
                await this.issueResolution.get(controlName).updateValueAndValidity();
            }
        }
        for (const controlName in this.issueClosed?.controls) {
            let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
            // console.log("))))))))))))))))))))", this.complaintValidations);
            if (validationData) {
                // console.log(">>>>", this.issueClosed);
                const control = this.issueClosed.get(controlName);
                const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

                await control.setValidators(await this.getValidators(validationRules, this.fieldNameForFailureMode));
                //  console.log("additional=======", control);
                await control.updateValueAndValidity(); // Update the validation status
            }
            else {
                // console.log("else for: ", controlName,);
                await this.issueClosed.get(controlName).clearValidators();
                await this.issueClosed.get(controlName).updateValueAndValidity();
            }
        }
        for (const controlName in this.effectivenessForm?.controls) {
            let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
            // console.log("))))))))))))))))))))", validationData);
            if (validationData) {
                // console.log(">>>>", this.effectivenessForm);
                const control = this.effectivenessForm.get(controlName);
                const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

                await control.setValidators(await this.getValidators(validationRules, this.fieldNameForFailureMode));
                //   console.log("additional=======", control);
                await control.updateValueAndValidity(); // Update the validation status
            }
            else {
                // console.log("else for: ", controlName,);
                await this.effectivenessForm.get(controlName).clearValidators();
                await this.effectivenessForm.get(controlName).updateValueAndValidity();
            }
        }
    }

    failureModeChange($event) {
        this.fieldNameForFailureMode = $event;
    }

    private getValidators(rules: any[], fieldFailureModeValidation): any {
        let validators: Validators[] = [];
        let index = 0;
       // console.log(">>>>>>>>>",rules, fieldFailureModeValidation);
        for (const rule of rules) {
            index = index + 1;
            if (rule.depends != null) {
                if (!fieldFailureModeValidation.includes('None') && rule.depends == "Validation" && fieldFailureModeValidation.includes(rule.value)) {
                    validators.push(Validators.required);
                    //   console.log("=======>>", rule.property, rule.depends, rule?.value,);
                    return validators;
                }
                else if ((this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule?.value?.toLowerCase()) ||
                (this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule?.value?.toString()?.toLowerCase()) ||
                (this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule?.value?.toLowerCase()) ||
                (this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule?.value?.toString()?.toLowerCase()) ||
                (this.issueCaptureMainForm.controls["issueResolutionForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueResolutionForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule?.value?.toLowerCase()) ||
                (this.issueCaptureMainForm.controls["issueAssignmentForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueAssignmentForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule?.value?.toLowerCase()) ||
                (this.issueCaptureMainForm.controls["issueClosedForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueClosedForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule?.value?.toLowerCase()) ||
                (this.issueCaptureMainForm.controls["effectivenessForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["effectivenessForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule?.value?.toLowerCase())) {
                    validators.push(Validators.required);
                    // console.log("=======", rule.property, rule.depends, rule?.value,);
                    return validators;
                }
                else {
                    if (index == rules?.length) {
                        return validators;
                    }
                }
            }
            else {
               // console.log(">>>>>>>", rule.property, rule.property == "JustificationCurrent", this.justificationReadOnly, rule.property == "ReviewRequired", !this.reviewRequired_Id, "////////", (rule.property == "JustificationCurrent" && this.justificationReadOnly) || (rule.property == "ReviewRequired" && !this.reviewRequired_Id));
                if ((rule.property == "JustificationCurrent" && this.justificationReadOnly) || (rule.property == "ReviewRequired" && !this.reviewRequired_Id)) {
                    return validators;
                } else {
                    validators.push(Validators.required);
                    return validators;
                }
            }
        }
    }

    checkPermission() {
        let complainStatus: any = { rating: {} };
        let permissionCode: any = { rating: {} };

        if (this.userAccessData) {
            switch (this.userAccessData['roleCode']) {
                case this.enumRoleCode.Complaint_Owner:
                    // Applied status code and permission from DEV (status code - in-progress  , Current rating - class C)
                    // https://dev.ccrp.hitachienergy.com/en/Complaint/680851#NPOPR

                    complainStatus['PrimaryComplaintOwner'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PrimaryComplaintOwner'] = [201];
                    complainStatus['substitute'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['substitute'] = [201];
                    complainStatus['supervisor'] = [1, 2];
                    permissionCode['supervisor'] = [201];
                    complainStatus['CustomerType'] = [1, 2];
                    permissionCode['CustomerType'] = [201];
                    complainStatus['customerNameData'] = [1, 2];
                    permissionCode['customerNameData'] = [212];
                    complainStatus['CustomerContact'] = [1, 2, 3, 4, 5, 7, 11, 12, 13];
                    permissionCode['CustomerContact'] = [211];
                    complainStatus['customerContactNameHidden'] = [1, 2, 3, 4, 5, 7, 11, 12, 13];
                    permissionCode['customerContactNameHidden'] = [211];
                    complainStatus['affectedCustomer'] = [1, 2, 3];
                    permissionCode['affectedCustomer'] = [212];
                    complainStatus['affectedCustomerHidden'] = [1, 2, 3];
                    permissionCode['affectedCustomerHidden'] = [212];
                    complainStatus['Customer'] = [1, 2];
                    permissionCode['Customer'] = [212];
                    complainStatus['customerNameHidden'] = [1, 2];
                    permissionCode['customerNameHidden'] = [212];
                    complainStatus['Subject'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Subject'] = [210];
                    complainStatus['Reason'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Reason'] = [210];
                    complainStatus['WhatIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['WhatIssueDescription'] = [209];
                    complainStatus['whyIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whyIssueDescription'] = [209];
                    complainStatus['howIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['howIssueDescription'] = [209];
                    complainStatus['whenIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whenIssueDescription'] = [209];
                    complainStatus['inWhichProcessStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['inWhichProcessStep'] = [209];
                    complainStatus['whereIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whereIssueDescription'] = [209];
                    complainStatus['issueStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueStep'] = [209];
                    complainStatus['customerExpectedResolutionDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['customerExpectedResolutionDescription'] = [209];
                    complainStatus['uploadedAttachments'] = [];
                    permissionCode['uploadedAttachments'] = [];
                    complainStatus['ContainmentActionsId'] = [];
                    permissionCode['ContainmentActionsId'] = [];
                    complainStatus['IsContainmentActionRequired'] = [1, 2];
                    permissionCode['IsContainmentActionRequired'] = [201];
                    complainStatus['TargetResolvingDate'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['TargetResolvingDate'] = [201];
                    complainStatus['targetResolutionHiddenDate'] = [];
                    permissionCode['targetResolutionHiddenDate'] = [];
                    complainStatus['deadline'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deadline'] = [210];
                    complainStatus['ContainmentActionDescription'] = [1, 2];
                    permissionCode['ContainmentActionDescription'] = [201];
                    complainStatus['containmentExecutedDate'] = [];
                    permissionCode['containmentExecutedDate'] = [];
                    complainStatus['EightDRCAIsRequiredByCustomer'] = [1, 2];
                    permissionCode['EightDRCAIsRequiredByCustomer'] = [201];
                    complainStatus['dateChangeReason'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['dateChangeReason'] = [201];
                    complainStatus['changeDescription'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['changeDescription'] = [201];
                    permissionCode['targetResolvingDateChangeCount'] = [];
                    complainStatus['targetResolvingDateChangeCount'] = [];
                    permissionCode['changeReasonId'] = [];
                    permissionCode['changeReasonId'] = [];
                    permissionCode['targetResolvingUnitInitialValue'] = [];
                    permissionCode['targetResolvingUnitInitialValue'] = [];

                    // Sapqn
                    complainStatus['DefectTypeandDefectCodeGroupType'] = [1,2,3,4,5,11,12,7];
                    permissionCode['DefectTypeandDefectCodeGroupType'] = [209];
                    complainStatus['WorkCentreandPlant'] = [1,2,3,4,5,7,11,12];
                    permissionCode['WorkCentreandPlant'] = [209];
                    complainStatus['DefectCauseDefectCauseCodeGroup'] = [4,5,11,12];
                    permissionCode['DefectCauseDefectCauseCodeGroup'] = [203];

                    // additionalSubjectForm
                    complainStatus['resolvingUnit'] = [1, 2];
                    permissionCode['resolvingUnit'] = [214];
                    complainStatus['resolvingUnitArray'] = [1, 2];
                    permissionCode['resolvingUnitArray'] = [214];
                    complainStatus['proposedResolutionOwner'] = [1, 2];
                    permissionCode['proposedResolutionOwner'] = [201];
                    complainStatus['Coordinators'] = [1, 2]
                    permissionCode['Coordinators'] = [201]
                    complainStatus['verificationTeam'] = []
                    permissionCode['verificationTeam'] = []
                    complainStatus['ABBProductCategory'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['ABBProductCategory'] = [209];
                    complainStatus['issueProductArray'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['issueProductArray'] = [209];
                    complainStatus['failureMode'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['failureMode'] = [209];
                    complainStatus['failureModeBind'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['failureModeBind'] = [209];
                    complainStatus['abbProductSerialNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProductSerialNo'] = [209];
                    complainStatus['purchaseOrderNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['purchaseOrderNo'] = [209];
                    complainStatus['abbProjectNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectNumber'] = [209];
                    complainStatus['abbProjectName'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectName'] = [209];
                    complainStatus['CountryCodeWhereTheIssueHappen'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['CountryCodeWhereTheIssueHappen'] = [209];
                    complainStatus['quantity'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['quantity'] = [209];
                    complainStatus['articleNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['articleNumber'] = [209];
                    complainStatus['itemDesignation'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['itemDesignation'] = [209];
                    complainStatus['abbCustomerReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbCustomerReference'] = [209];
                    complainStatus['supplier'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['supplier'] = [209];
                    complainStatus['deliveryDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deliveryDate'] = [209];
                    complainStatus['commissionDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['commissionDate'] = [209];
                    complainStatus['ManufacturingDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['ManufacturingDate'] = [209];
                    complainStatus['warrantyReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyReference'] = [209];
                    complainStatus['warrantyLifeCycleId'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyLifeCycleId'] = [209];
                    complainStatus['warrantyStartDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyStartDate'] = [209];
                    complainStatus['warrantyEndDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyEndDate'] = [209];
                    complainStatus['documentID'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['documentID'] = [209];
                    complainStatus['qNumberIssue'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['qNumberIssue'] = [209];
                    complainStatus['ProductAlreadyDelivered'] = [4, 5, 11, 12];
                    permissionCode['ProductAlreadyDelivered'] = [203];
                    complainStatus['NoOfUnitsShipped'] = [4, 5, 11, 12];
                    permissionCode['NoOfUnitsShipped'] = [203];
                    complainStatus['PartiesReference'] = [1, 2];
                    permissionCode['PartiesReference'] = [201];
                    complainStatus['SendCopyToParties'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['SendCopyToParties'] = [215];
                    complainStatus['WhatContainmentActionsWhereTaken'] = [1, 2];
                    permissionCode['WhatContainmentActionsWhereTaken'] = [201];

                    complainStatus['uploadedPDCAAttachments'] = [];
                    permissionCode['uploadedPDCAAttachments'] = [];
                    complainStatus['uploadedSummaryAttachments'] = [];
                    permissionCode['uploadedSummaryAttachments'] = [];
                    complainStatus['hiddenIsHVDCComplaint'] = [];
                    permissionCode['hiddenIsHVDCComplaint'] = [];

                    // ISSUE ASSIGNMENT FORM
                    complainStatus['PrimaryResolutionOwner'] = [3, 4, 5, 11, 12];
                    permissionCode['PrimaryResolutionOwner'] = [207];
                    complainStatus['resolutionOwnerTeam'] = [3, 4, 5, 11, 12];
                    permissionCode['resolutionOwnerTeam'] = [206];
                    complainStatus['ccrpNo'] = [3, 4, 5];
                    permissionCode['ccrpNo'] = [202];
                    complainStatus['quickFixComplaint'] = [3, 4, 5];
                    permissionCode['quickFixComplaint'] = [202];
                    complainStatus['quickFixVariant'] = [3, 4, 5];
                    permissionCode['quickFixVariant'] = [202];
                    complainStatus['verifiedCheckBox'] = [3, 4, 5];
                    permissionCode['verifiedCheckBox'] = [202];
                    complainStatus['coordinatorList'] = [3, 4, 5];
                    permissionCode['coordinatorList'] = [202];
                    complainStatus['coordinatorName'] = [3, 4, 5];
                    permissionCode['coordinatorName'] = [202];
                    complainStatus['AddNominatedPerson'] = [3, 4, 5];
                    permissionCode['AddNominatedPerson'] = [202];
                    complainStatus['solutionWillBeValidatedByCoordinator'] = [3, 4, 5];
                    permissionCode['solutionWillBeValidatedByCoordinator'] = [202];
                    complainStatus['QuickFixRCANotReqDescription'] = [];
                    permissionCode['QuickFixRCANotReqDescription'] = [];

                    // ISSUE RESOULTION FORM
                    complainStatus['EstimatedCostView'] = [1, 2];
                    permissionCode['EstimatedCostView'] = [203];
                    complainStatus['ImpactOnInstalledBase'] = [1, 2];
                    permissionCode['ImpactOnInstalledBase'] = [203];
                    complainStatus['TotalInstalledBase'] = [1, 2];
                    permissionCode['TotalInstalledBase'] = [203];
                    complainStatus['QNumberResolvingUnit'] = [1, 2];
                    permissionCode['QNumberResolvingUnit'] = [203];
                    complainStatus['availabilityRating'] = [];
                    permissionCode['availabilityRating'] = [];
                    complainStatus['functionalityRating'] = [];
                    permissionCode['functionalityRating'] = [];
                    complainStatus['onTimeDeliveryRating'] = [];
                    permissionCode['onTimeDeliveryRating'] = [];
                    complainStatus['cyberSecurityRating'] = [];
                    permissionCode['cyberSecurityRating'] = [];
                    complainStatus['productComplianceRating'] = [];
                    permissionCode['productComplianceRating'] = [];
                    complainStatus['ohsRiskRating'] = [];
                    permissionCode['ohsRiskRating'] = [];
                    complainStatus['marginRating'] = [];
                    permissionCode['marginRating'] = [];
                    complainStatus['reputationRating'] = [];
                    permissionCode['reputationRating'] = [];
                    complainStatus['massMediaRating'] = [];
                    permissionCode['massMediaRating'] = [];
                    complainStatus['designChangeRating'] = [];
                    permissionCode['designChangeRating'] = [];
                    complainStatus['instancesRating'] = [];
                    permissionCode['instancesRating'] = [];
                    complainStatus['resolutionRating'] = [];
                    permissionCode['resolutionRating'] = [];
                    complainStatus['affectedCustomerRating'] = [];
                    permissionCode['affectedCustomerRating'] = [];
                    complainStatus['controlRating'] = [];
                    permissionCode['controlRating'] = [];
                    complainStatus['impactRating'] = [];
                    permissionCode['impactRating'] = [];
                    complainStatus['Rating'] = [];
                    permissionCode['Rating'] = [];
                    // complainStatus['justification'] = [1, 2];
                    // permissionCode['justification'] = [203];
                    complainStatus['ReviewRequired'] = [1, 2];
                    permissionCode['ReviewRequired'] = [203];
                    complainStatus['reviewRequiredJustification'] = [1, 2];
                    permissionCode['reviewRequiredJustification'] = [203];
                    complainStatus['eightDReportRequired'] = [3, 4, 5, 7, 11, 12];
                    permissionCode['eightDReportRequired'] = [203];
                    complainStatus['EightDJustificationCurrent'] = [3, 4, 5, 7, 11, 12];
                    permissionCode['EightDJustificationCurrent'] = [203];
                    // complainStatus['RCACorrectiveActions'] = [1, 2];
                    // permissionCode['RCACorrectiveActions'] = [203];
                    complainStatus['SpecificationName'] = [1, 2];
                    permissionCode['SpecificationName'] = [203];
                    complainStatus['howProductFailMeetSpecification'] = [1, 2];
                    permissionCode['howProductFailMeetSpecification'] = [203];
                    complainStatus['ContainmentActionsExecution'] = [];
                    permissionCode['ContainmentActionsExecution'] = [];
                    complainStatus['WhatContainmentActionsWhereTaken'] = [];
                    permissionCode['WhatContainmentActionsWhereTaken'] = [];
                    complainStatus['whatWasTheRootCauseOfThisIssue'] = [1, 2];
                    permissionCode['whatWasTheRootCauseOfThisIssue'] = [203];
                    complainStatus['CategoryKeyword'] = [1, 2];
                    permissionCode['CategoryKeyword'] = [203];
                    complainStatus['howDidWeResolveThisIssue'] = [1, 2];
                    permissionCode['howDidWeResolveThisIssue'] = [203];
                    complainStatus['JustificationCurrent'] = [1, 2];
                    permissionCode['JustificationCurrent'] = [203];
                    complainStatus['UploadRCAAttachments'] = [];
                    permissionCode['UploadRCAAttachments'] = [];
                    complainStatus['resolutionAttachments'] = [7, 13];
                    permissionCode['resolutionAttachments'] = [204];
                    // complainStatus['uploadedPDCAAttachments'] = [];
                    // permissionCode['uploadedPDCAAttachments'] = [];
                    // complainStatus['uploadedSummaryAttachments'] = [];
                    // permissionCode['uploadedSummaryAttachments'] = [];
                    complainStatus['Upload'] = [];
                    permissionCode['Upload'] = [];
                    complainStatus['learn'] = [1, 2];
                    permissionCode['learn'] = [203];
                    complainStatus['desc'] = [1, 2];
                    permissionCode['desc'] = [203];
                    complainStatus['ActualRUCost'] = [1, 2];
                    permissionCode['ActualRUCost'] = [203];
                    complainStatus['COPQCategory'] = [1, 2];
                    permissionCode['COPQCategory'] = [203];
                    complainStatus['COPQCategoryAllData'] = [1, 2];
                    permissionCode['COPQCategoryAllData'] = [203];
                    complainStatus['pgCategory'] = [1, 2];
                    permissionCode['pgCategory'] = [203];
                    complainStatus['PGRCACategoryAllData'] = [1, 2];
                    permissionCode['PGRCACategoryAllData'] = [203];
                    complainStatus['chipData'] = [];
                    permissionCode['chipData'] = [];
                    complainStatus['id'] = [];
                    permissionCode['id'] = [];
                    complainStatus['issueAssigned'] = [];
                    permissionCode['issueAssigned'] = [];
                    complainStatus['selectRole'] = [];
                    permissionCode['selectRole'] = [];
                    complainStatus['selectRole1'] = [];
                    permissionCode['selectRole1'] = [];
                    complainStatus['issueStatus'] = [];
                    permissionCode['issueStatus'] = [];
                    complainStatus['classValue'] = [];
                    permissionCode['classValue'] = [];
                    complainStatus['rpn'] = [];
                    permissionCode['rpn'] = [];
                    complainStatus['manualChangeClass'] = [4, 5, 11, 12];
                    permissionCode['manualChangeClass'] = [203];

                    // Issue Closed Form
                    complainStatus['AwaitingCustomerExecution'] = [7];
                    permissionCode['AwaitingCustomerExecution'] = [204];
                    complainStatus['customerComments'] = [7, 13];
                    permissionCode['customerComments'] = [204];
                    complainStatus['ExpectedExecutionDate'] = [7, 13];
                    permissionCode['ExpectedExecutionDate'] = [204];
                    complainStatus['customerContactStatus'] = [7, 13];
                    permissionCode['customerContactStatus'] = [204];
                    complainStatus['sendFeedback'] = [7, 13];
                    permissionCode['sendFeedback'] = [204];
                    complainStatus['CustomerFeedbackJustification'] = [7, 13];
                    permissionCode['CustomerFeedbackJustification'] = [204];
                    complainStatus['customerResponse'] = [7, 13];
                    permissionCode['customerResponse'] = [204];
                    complainStatus['uploadedAcceptanceAttachments'] = [7, 13];
                    permissionCode['uploadedAcceptanceAttachments'] = [204];

                    //Effectiveness Form
                    complainStatus['id'] = [8];
                    permissionCode['id'] = [311];
                    complainStatus['IsEffective'] = [8];
                    permissionCode['IsEffective'] = [311];
                    complainStatus['DeadlineDate'] = [8];
                    permissionCode['DeadlineDate'] = [311];
                    complainStatus['effectiveChangedValue'] = [8];
                    permissionCode['effectiveChangedValue'] = [311];
                    complainStatus['EffectivenessReason'] = [8];
                    permissionCode['EffectivenessReason'] = [311];
                    complainStatus['reopenReason'] = [8];
                    permissionCode['reopenReason'] = [311];
                    complainStatus['uploadedEffectivenessAttachments'] = [8];
                    permissionCode['uploadedEffectivenessAttachments'] = [311];
                    complainStatus['uploadedAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12]; //Hidden Field
                    permissionCode['uploadedAttachments'] = [210];

                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Complaint_Owner, complainStatus, permissionCode);
                    const Complaint_Owner = this.authorizationMiddleWare.checkSetPermissionData();
                    this.setDisableBasedOnPermission(Complaint_Owner);
                    break;
                case this.enumRoleCode.Complaint_Coordinator:

                    // Applied status code and permission from DEV (status code - in-progress  , Current rating - class C)
                    // https://dev.ccrp.hitachienergy.com/en/Complaint/680851#NPOPR
                    complainStatus['PrimaryComplaintOwner'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PrimaryComplaintOwner'] = [201];
                    complainStatus['substitute'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['substitute'] = [201];
                    complainStatus['supervisor'] = [1, 2];
                    permissionCode['supervisor'] = [201];
                    complainStatus['CustomerType'] = [1, 2];
                    permissionCode['CustomerType'] = [201];
                    complainStatus['Customer'] = [1, 2];
                    permissionCode['Customer'] = [212];
                    complainStatus['customerNameData'] = [];
                    permissionCode['customerNameData'] = [];
                    complainStatus['customerNameHidden'] = [1, 2];
                    permissionCode['customerNameHidden'] = [212];
                    complainStatus['CustomerContact'] = [1, 2, 3, 4, 5, 7, 11, 12, 13];
                    permissionCode['CustomerContact'] = [211];
                    complainStatus['customerContactNameHidden'] = [];
                    permissionCode['customerContactNameHidden'] = [];
                    complainStatus['affectedCustomer'] = [1, 2, 3];
                    permissionCode['affectedCustomer'] = [212];
                    complainStatus['affectedCustomerHidden'] = [];
                    permissionCode['affectedCustomerHidden'] = [];
                    complainStatus['Subject'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Subject'] = [210];
                    //Issue Description Form Controls
                    complainStatus['Reason'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Reason'] = [210];
                    complainStatus['WhatIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['WhatIssueDescription'] = [209];
                    complainStatus['whyIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whyIssueDescription'] = [209];
                    complainStatus['howIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['howIssueDescription'] = [209];
                    complainStatus['issueStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueStep'] = [209];
                    complainStatus['whenIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whenIssueDescription'] = [209];
                    complainStatus['inWhichProcessStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['inWhichProcessStep'] = [209];
                    complainStatus['whereIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whereIssueDescription'] = [209];
                    /* complainStatus['issueLocationArr'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueLocationArr'] = [210]; */
                    complainStatus['customerExpectedResolutionDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['customerExpectedResolutionDescription'] = [209];
                    complainStatus['uploadedAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['uploadedAttachments'] = [209];
                    complainStatus['ContainmentActionsId'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['ContainmentActionsId'] = [210];
                    complainStatus['IsContainmentActionRequired'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['IsContainmentActionRequired'] = [210];
                    complainStatus['containmentExecutedDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['containmentExecutedDate'] = [210];
                    complainStatus['EightDRCAIsRequiredByCustomer'] = [1, 2];
                    permissionCode['EightDRCAIsRequiredByCustomer'] = [201];
                    complainStatus['TargetResolvingDate'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['TargetResolvingDate'] = [201]
                    complainStatus['targetResolutionHiddenDate'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['targetResolutionHiddenDate'] = [201]
                    complainStatus['deadline'] = [1, 2];
                    permissionCode['deadline'] = [210]
                    complainStatus['ContainmentActionDescription'] = [1, 2];
                    permissionCode['ContainmentActionDescription'] = [201];
                    complainStatus['dateChangeReason'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['dateChangeReason'] = [201];
                    complainStatus['changeDescription'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['changeDescription'] = [201];
                    complainStatus['targetResolvingDateChangeCount'] = [];
                    permissionCode['targetResolvingDateChangeCount'] = [];
                    complainStatus['changeReasonId'] = [];
                    permissionCode['changeReasonId'] = [];
                    complainStatus['targetResolvingDateInitialValue'] = [];
                    permissionCode['targetResolvingDateInitialValue'] = [];

                    // additionalSubjectForm
                    complainStatus['resolvingUnit'] = [3, 4, 5];
                    permissionCode['resolvingUnit'] = [214];
                    complainStatus['resolvingUnitArray'] = [3, 4, 5];
                    permissionCode['resolvingUnitArray'] = [214];
                    complainStatus['proposedResolutionOwner'] = [1, 2];
                    permissionCode['proposedResolutionOwner'] = [201];
                    complainStatus['Coordinators'] = [1, 2]
                    permissionCode['Coordinators'] = [201];

                    // Sapqn
                    complainStatus['DefectTypeandDefectCodeGroupType'] = [3,4,5,11,12];
                    permissionCode['DefectTypeandDefectCodeGroupType'] = [209];
                    complainStatus['WorkCentreandPlant'] = [1,2,3,4,5,7,11,12];
                    permissionCode['WorkCentreandPlant'] = [209];
                    complainStatus['DefectCauseDefectCauseCodeGroup'] = [4,5,11,12];
                    permissionCode['DefectCauseDefectCauseCodeGroup'] = [203];

                    complainStatus['verificationTeam'] = []
                    permissionCode['verificationTeam'] = []
                    complainStatus['ABBProductCategory'] = [3, 4, 5, 11, 12];
                    permissionCode['ABBProductCategory'] = [209];
                    complainStatus['failureMode'] = [3, 4, 5, 11, 12];
                    permissionCode['failureMode'] = [209];
                    complainStatus['issueProductArray'] = [3, 4, 5, 11, 12];
                    permissionCode['issueProductArray'] = [209];
                    complainStatus['failureModeBind'] = [3, 4, 5, 11, 12];
                    permissionCode['failureModeBind'] = [209];

                    complainStatus['abbProductSerialNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProductSerialNo'] = [209];
                    complainStatus['purchaseOrderNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['purchaseOrderNo'] = [209];
                    complainStatus['abbProjectNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectNumber'] = [209];
                    complainStatus['abbProjectName'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectName'] = [209];
                    complainStatus['CountryCodeWhereTheIssueHappen'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['CountryCodeWhereTheIssueHappen'] = [209];
                    complainStatus['quantity'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['quantity'] = [209];
                    complainStatus['articleNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['articleNumber'] = [209];
                    complainStatus['itemDesignation'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['itemDesignation'] = [209];
                    complainStatus['abbCustomerReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbCustomerReference'] = [209];
                    complainStatus['supplier'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['supplier'] = [209];

                    complainStatus['ProductAlreadyDelivered'] = [4, 5, 11, 12];
                    permissionCode['ProductAlreadyDelivered'] = [203];
                    complainStatus['NoOfUnitsShipped'] = [4, 5, 11, 12];
                    permissionCode['NoOfUnitsShipped'] = [203];

                    complainStatus['deliveryDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deliveryDate'] = [209];
                    complainStatus['commissionDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['commissionDate'] = [209];
                    complainStatus['ManufacturingDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['ManufacturingDate'] = [209];
                    complainStatus['warrantyReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyReference'] = [209];
                    complainStatus['warrantyLifeCycleId'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyLifeCycleId'] = [209];
                    complainStatus['warrantyStartDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyStartDate'] = [209];
                    complainStatus['warrantyEndDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyEndDate'] = [209];
                    complainStatus['documentID'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['documentID'] = [209];
                    complainStatus['qNumberIssue'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['qNumberIssue'] = [209];

                    complainStatus['PartiesReference'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['PartiesReference'] = [215];
                    complainStatus['SendCopyToParties'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['SendCopyToParties'] = [215];
                    complainStatus['WhatContainmentActionsWhereTaken'] = [4,5,11,12];
                    permissionCode['WhatContainmentActionsWhereTaken'] = [203];

                    // complainStatus['uploadedPDCAAttachments'] = [];
                    // permissionCode['uploadedPDCAAttachments'] = [];
                    // complainStatus['uploadedSummaryAttachments'] = [];
                    // permissionCode['uploadedSummaryAttachments'] = [];
                    complainStatus['hiddenIsHVDCComplaint'] = [];
                    permissionCode['hiddenIsHVDCComplaint'] = [];

                    // ISSUE ASSIGNMENT FORM
                    complainStatus['PrimaryResolutionOwner'] = [3, 4, 5, 11, 12];
                    permissionCode['PrimaryResolutionOwner'] = [207];
                    complainStatus['resolutionOwnerTeam'] = [3, 4, 5, 11, 12];
                    permissionCode['resolutionOwnerTeam'] = [206];
                    complainStatus['ccrpNo'] = [3, 4, 5];
                    permissionCode['ccrpNo'] = [202];
                    complainStatus['quickFixComplaint'] = [3, 4, 5];
                    permissionCode['quickFixComplaint'] = [202];
                    complainStatus['quickFixVariant'] = [3, 4, 5];
                    permissionCode['quickFixVariant'] = [202];
                    complainStatus['verifiedCheckBox'] = [3, 4, 5];
                    permissionCode['verifiedCheckBox'] = [202];
                    complainStatus['coordinatorList'] = [3, 4, 5];
                    permissionCode['coordinatorList'] = [202];
                    complainStatus['coordinatorName'] = [3, 4, 5];
                    permissionCode['coordinatorName'] = [202];
                    complainStatus['AddNominatedPerson'] = [3, 4, 5];
                    permissionCode['AddNominatedPerson'] = [202];
                    complainStatus['solutionWillBeValidatedByCoordinator'] = [3, 4, 5];
                    permissionCode['solutionWillBeValidatedByCoordinator'] = [202];
                    complainStatus['QuickFixRCANotReqDescription'] = [];
                    permissionCode['QuickFixRCANotReqDescription'] = [];

                    // ISSUE RESOULTION FORM
                    complainStatus['EstimatedCostView'] = [4, 5, 11, 12];
                    permissionCode['EstimatedCostView'] = [203];
                    complainStatus['ImpactOnInstalledBase'] = [4, 5, 11, 12];
                    permissionCode['ImpactOnInstalledBase'] = [203];
                    complainStatus['TotalInstalledBase'] = [4, 5, 11, 12];
                    permissionCode['TotalInstalledBase'] = [203];
                    complainStatus['QNumberResolvingUnit'] = [4, 11];
                    permissionCode['QNumberResolvingUnit'] = [203];
                    complainStatus['ContainmentActionsExecution'] = [];
                    permissionCode['ContainmentActionsExecution'] = [];

                    complainStatus['availabilityRating'] = [];
                    permissionCode['availabilityRating'] = [];
                    complainStatus['functionalityRating'] = [];
                    permissionCode['functionalityRating'] = [];
                    complainStatus['onTimeDeliveryRating'] = [];
                    permissionCode['onTimeDeliveryRating'] = [];
                    complainStatus['cyberSecurityRating'] = [];
                    permissionCode['cyberSecurityRating'] = [];
                    complainStatus['productComplianceRating'] = [];
                    permissionCode['productComplianceRating'] = [];
                    complainStatus['ohsRiskRating'] = [];
                    permissionCode['ohsRiskRating'] = [];
                    complainStatus['reputationRating'] = [];
                    permissionCode['reputationRating'] = [];
                    complainStatus['marginRating'] = [];
                    permissionCode['marginRating'] = [];
                    complainStatus['massMediaRating'] = [];
                    permissionCode['massMediaRating'] = [];
                    complainStatus['designChangeRating'] = [];
                    permissionCode['designChangeRating'] = [];
                    complainStatus['instancesRating'] = [];
                    permissionCode['instancesRating'] = [];
                    complainStatus['resolutionRating'] = [];
                    permissionCode['resolutionRating'] = [];
                    complainStatus['affectedCustomerRating'] = [];
                    permissionCode['affectedCustomerRating'] = [];
                    complainStatus['controlRating'] = [];
                    permissionCode['controlRating'] = [];
                    complainStatus['impactRating'] = [];
                    permissionCode['impactRating'] = [];

                    complainStatus['Rating'] = [];
                    permissionCode['Rating'] = [];
                    complainStatus['JustificationCurrent'] = [1, 2];
                    permissionCode['JustificationCurrent'] = [203];
                    complainStatus['ReviewRequired'] = [1, 2];
                    permissionCode['ReviewRequired'] = [203];
                    complainStatus['reviewRequiredJustification'] = [1, 2];
                    permissionCode['reviewRequiredJustification'] = [203];
                    complainStatus['eightDReportRequired'] = [4, 5, 11, 12];
                    permissionCode['eightDReportRequired'] = [203];
                    complainStatus['EightDJustificationCurrent'] = [4, 5, 11, 12];
                    permissionCode['EightDJustificationCurrent'] = [203];

                    // complainStatus['RCACorrectiveActions'] = [1, 2];
                    // permissionCode['RCACorrectiveActions'] = [203];
                    complainStatus['SpecificationName'] = [4, 5, 11, 12];
                    permissionCode['SpecificationName'] = [203];
                    complainStatus['howProductFailMeetSpecification'] = [4, 5, 11, 12];
                    permissionCode['howProductFailMeetSpecification'] = [203];


                    complainStatus['whatWasTheRootCauseOfThisIssue'] = [4, 5, 11, 12];
                    permissionCode['whatWasTheRootCauseOfThisIssue'] = [203];
                    complainStatus['CategoryKeyword'] = [4, 5, 11, 12];
                    permissionCode['CategoryKeyword'] = [203];
                    complainStatus['howDidWeResolveThisIssue'] = [4, 5, 11, 12];
                    permissionCode['howDidWeResolveThisIssue'] = [203];

                    /* complainStatus['uploadedPDCAAttachments'] = [];
                    permissionCode['uploadedPDCAAttachments'] = [];
                    complainStatus['uploadedSummaryAttachments'] = [];
                    permissionCode['uploadedSummaryAttachments'] = []; */
                    complainStatus['Upload'] = [];
                    permissionCode['Upload'] = [];

                    complainStatus['learn'] = [1, 2];
                    permissionCode['learn'] = [203];
                    complainStatus['desc'] = [1, 2];
                    permissionCode['desc'] = [203];


                    complainStatus['ActualRUCost'] = [4, 5, 11, 12];
                    permissionCode['ActualRUCost'] = [203];
                    complainStatus['COPQCategory'] = [4, 5, 11, 12];
                    permissionCode['COPQCategory'] = [203];
                    complainStatus['COPQCategoryAllData'] = [1, 2];
                    permissionCode['COPQCategoryAllData'] = [203];
                    complainStatus['pgCategory'] = [4, 5, 11, 12];
                    permissionCode['pgCategory'] = [203];
                    complainStatus['PGRCACategoryAllData'] = [1, 2];
                    permissionCode['PGRCACategoryAllData'] = [203];


                    complainStatus['chipData'] = [];
                    permissionCode['chipData'] = [];
                    complainStatus['id'] = [];
                    permissionCode['id'] = [];
                    complainStatus['issueAssigned'] = [];
                    permissionCode['issueAssigned'] = [];
                    complainStatus['selectRole'] = [];
                    permissionCode['selectRole'] = [];
                    complainStatus['selectRole1'] = [];
                    permissionCode['selectRole1'] = [];
                    complainStatus['issueStatus'] = [];
                    permissionCode['issueStatus'] = [];
                    complainStatus['classValue'] = [];
                    permissionCode['classValue'] = [];
                    complainStatus['rpn'] = [];
                    permissionCode['rpn'] = [];
                    complainStatus['manualChangeClass'] = [4, 5, 11, 12];
                    permissionCode['manualChangeClass'] = [203];
                    complainStatus['UploadRCAAttachments'] = [];
                    permissionCode['UploadRCAAttachments'] = [];
                    complainStatus['resolutionAttachments'] = [];
                    permissionCode['resolutionAttachments'] = [];



                    // Issue Closed Form
                    complainStatus['AwaitingCustomerExecution'] = [7];
                    permissionCode['AwaitingCustomerExecution'] = [204];
                    complainStatus['customerComments'] = [7, 13];
                    permissionCode['customerComments'] = [204];
                    complainStatus['ExpectedExecutionDate'] = [7, 13];
                    permissionCode['ExpectedExecutionDate'] = [204];
                    complainStatus['customerContactStatus'] = [7, 13];
                    permissionCode['customerContactStatus'] = [204];
                    complainStatus['sendFeedback'] = [7, 13];
                    permissionCode['sendFeedback'] = [204];
                    complainStatus['CustomerFeedbackJustification'] = [7, 13];
                    permissionCode['CustomerFeedbackJustification'] = [204];
                    complainStatus['customerResponse'] = [7, 13];
                    permissionCode['customerResponse'] = [204];
                    complainStatus['uploadedAcceptanceAttachments'] = [7, 13];
                    permissionCode['uploadedAcceptanceAttachments'] = [204];

                    //Effectiveness Form
                    complainStatus['id'] = [8];
                    permissionCode['id'] = [311];
                    complainStatus['IsEffective'] = [8];
                    permissionCode['IsEffective'] = [311];
                    complainStatus['DeadlineDate'] = [8];
                    permissionCode['DeadlineDate'] = [311];
                    complainStatus['effectiveChangedValue'] = [8];
                    permissionCode['effectiveChangedValue'] = [311];
                    complainStatus['EffectivenessReason'] = [8];
                    permissionCode['EffectivenessReason'] = [311];
                    complainStatus['reopenReason'] = [8];
                    permissionCode['reopenReason'] = [311];
                    complainStatus['uploadedEffectivenessAttachments'] = [8];
                    permissionCode['uploadedEffectivenessAttachments'] = [311];

                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Complaint_Coordinator, complainStatus, permissionCode);
                    const permissionDataGlobalCoordinator = this.authorizationMiddleWare.checkSetPermissionData();
                    this.setDisableBasedOnPermission(permissionDataGlobalCoordinator);
                    break;
                case this.enumRoleCode.Global_CCRP_Process_Owner:
                    complainStatus['PrimaryComplaintOwner'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PrimaryComplaintOwner'] = [201];
                    complainStatus['substitute'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['substitute'] = [201];
                    complainStatus['supervisor'] = [1, 2];
                    permissionCode['supervisor'] = [201];
                    complainStatus['CustomerType'] = [1, 2];
                    permissionCode['CustomerType'] = [201];
                    complainStatus['customerNameData'] = [1, 2];
                    permissionCode['customerNameData'] = [212];
                    complainStatus['CustomerContact'] = [1, 2, 3, 4, 5, 7, 11, 12, 13];
                    permissionCode['CustomerContact'] = [211];
                    complainStatus['customerContactNameHidden'] = [1, 2, 3, 4, 5, 7, 11, 12, 13];
                    permissionCode['customerContactNameHidden'] = [211];
                    complainStatus['affectedCustomer'] = [1, 2, 3];
                    permissionCode['affectedCustomer'] = [212];
                    complainStatus['affectedCustomerHidden'] = [1, 2, 3];
                    permissionCode['affectedCustomerHidden'] = [212];
                    complainStatus['Customer'] = [1, 2];
                    permissionCode['Customer'] = [212];
                    complainStatus['customerNameHidden'] = [1, 2];
                    permissionCode['customerNameHidden'] = [212];
                    complainStatus['Subject'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Subject'] = [210];
                    complainStatus['Reason'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Reason'] = [210];
                    complainStatus['WhatIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['WhatIssueDescription'] = [209];
                    complainStatus['whyIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whyIssueDescription'] = [209];
                    complainStatus['howIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['howIssueDescription'] = [209];
                    complainStatus['whenIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whenIssueDescription'] = [209];
                    complainStatus['inWhichProcessStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['inWhichProcessStep'] = [209];
                    complainStatus['whereIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whereIssueDescription'] = [209];
                    complainStatus['issueStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueStep'] = [209];
                    complainStatus['customerExpectedResolutionDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['customerExpectedResolutionDescription'] = [209];
                    complainStatus['ContainmentActionsId'] = [];
                    permissionCode['ContainmentActionsId'] = [];
                    complainStatus['IsContainmentActionRequired'] = [];
                    permissionCode['IsContainmentActionRequired'] = [];
                    complainStatus['TargetResolvingDate'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['TargetResolvingDate'] = [201];
                    complainStatus['targetResolutionHiddenDate'] = [];
                    permissionCode['targetResolutionHiddenDate'] = [];
                    complainStatus['uploadedAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['uploadedAttachments'] = [210];
                    complainStatus['deadline'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deadline'] = [210]
                    complainStatus['ContainmentActionDescription'] = [1, 2];
                    permissionCode['ContainmentActionDescription'] = [201];
                    complainStatus['containmentExecutedDate'] = [];
                    permissionCode['containmentExecutedDate'] = [];
                    complainStatus['EightDRCAIsRequiredByCustomer'] = [1, 2];
                    permissionCode['EightDRCAIsRequiredByCustomer'] = [201];
                    complainStatus['dateChangeReason'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['dateChangeReason'] = [201];
                    complainStatus['changeDescription'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['changeDescription'] = [201];
                    permissionCode['targetResolvingDateChangeCount'] = [];
                    complainStatus['targetResolvingDateChangeCount'] = [];
                    permissionCode['changeReasonId'] = [];
                    permissionCode['changeReasonId'] = [];
                    permissionCode['targetResolvingUnitInitialValue'] = [];
                    permissionCode['targetResolvingUnitInitialValue'] = [];

                    complainStatus['DefectTypeandDefectCodeGroupType'] = [1,2,3,4,5,11,12,7];
                    permissionCode['DefectTypeandDefectCodeGroupType'] = [209];
                    complainStatus['WorkCentreandPlant'] = [1,2,3,4,5,7,11,12];
                    permissionCode['WorkCentreandPlant'] = [209];
                    complainStatus['DefectCauseDefectCauseCodeGroup'] = [3,4,5,11,12,7];
                    permissionCode['DefectCauseDefectCauseCodeGroup'] = [203];

                    // additionalSubjectForm
                    // complainStatus['ABBProductCategory'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    // permissionCode['ABBProductCategory'] = [209];
                    // complainStatus['failureMode'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    // permissionCode['failureMode'] = [209];
                    complainStatus['resolvingUnit'] = [2, 3, 4, 5, 11];
                    permissionCode['resolvingUnit'] = [214];
                    complainStatus['resolvingUnitArray'] = [1, 2];
                    permissionCode['resolvingUnitArray'] = [214];
                    complainStatus['proposedResolutionOwner'] = [1, 2];
                    permissionCode['proposedResolutionOwner'] = [201];
                    complainStatus['Coordinators'] = [1, 2]
                    permissionCode['Coordinators'] = [201]
                    complainStatus['verificationTeam'] = []
                    permissionCode['verificationTeam'] = []
                    complainStatus['ABBProductCategory'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['ABBProductCategory'] = [209];
                    complainStatus['issueProductArray'] = [];
                    permissionCode['issueProductArray'] = [];
                    complainStatus['failureMode'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['failureMode'] = [209];
                    complainStatus['failureModeBind'] = [];
                    permissionCode['failureModeBind'] = [];
                    complainStatus['abbProductSerialNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProductSerialNo'] = [209];
                    complainStatus['purchaseOrderNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['purchaseOrderNo'] = [209];
                    complainStatus['abbProjectNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectNumber'] = [209];
                    complainStatus['abbProjectName'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectName'] = [209];
                    complainStatus['CountryCodeWhereTheIssueHappen'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['CountryCodeWhereTheIssueHappen'] = [209];
                    complainStatus['quantity'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['quantity'] = [209];
                    complainStatus['articleNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['articleNumber'] = [209];
                    complainStatus['itemDesignation'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['itemDesignation'] = [209];
                    complainStatus['abbCustomerReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbCustomerReference'] = [209];
                    complainStatus['supplier'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['supplier'] = [209];
                    complainStatus['deliveryDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deliveryDate'] = [209];
                    complainStatus['commissionDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['commissionDate'] = [209];
                    complainStatus['ManufacturingDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['ManufacturingDate'] = [209];
                    complainStatus['warrantyReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyReference'] = [209];
                    complainStatus['warrantyLifeCycleId'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyLifeCycleId'] = [209];
                    complainStatus['warrantyStartDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyStartDate'] = [209];
                    complainStatus['warrantyEndDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyEndDate'] = [209];
                    complainStatus['documentID'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['documentID'] = [209];
                    complainStatus['qNumberIssue'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['qNumberIssue'] = [209];
                    // complainStatus['PartiesReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    // permissionCode['PartiesReference'] = [215];
                    // complainStatus['SendCopyToParties'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    // permissionCode['SendCopyToParties'] = [215];
                    complainStatus['ProductAlreadyDelivered'] = [4, 5, 11, 12];
                    permissionCode['ProductAlreadyDelivered'] = [203];
                    complainStatus['NoOfUnitsShipped'] = [4, 5, 11, 12];
                    permissionCode['NoOfUnitsShipped'] = [203];
                    complainStatus['PartiesReference'] = [1, 2];
                    permissionCode['PartiesReference'] = [201];
                    complainStatus['SendCopyToParties'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['SendCopyToParties'] = [209];
                    // complainStatus['uploadedPDCAAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    // permissionCode['uploadedPDCAAttachments'] = [209];
                    // complainStatus['uploadedSummaryAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    // permissionCode['uploadedSummaryAttachments'] = [209];
                    complainStatus['hiddenIsHVDCComplaint'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['hiddenIsHVDCComplaint'] = [209];

                    // ISSUE ASSIGNMENT FORM
                    complainStatus['PrimaryResolutionOwner'] = [3, 4, 5, 11, 12];
                    permissionCode['PrimaryResolutionOwner'] = [207];
                    complainStatus['resolutionOwnerTeam'] = [3, 4, 5, 11, 12];
                    permissionCode['resolutionOwnerTeam'] = [206];
                    complainStatus['ccrpNo'] = [3, 4, 5];
                    permissionCode['ccrpNo'] = [202];
                    complainStatus['quickFixComplaint'] = [3, 4, 5];
                    permissionCode['quickFixComplaint'] = [202];
                    complainStatus['quickFixVariant'] = [3, 4, 5];
                    permissionCode['quickFixVariant'] = [202];
                    complainStatus['verifiedCheckBox'] = [3, 4, 5];
                    permissionCode['verifiedCheckBox'] = [202];
                    complainStatus['coordinatorList'] = [];
                    permissionCode['coordinatorList'] = [];
                    complainStatus['coordinatorName'] = [3, 4, 5];
                    permissionCode['coordinatorName'] = [202];
                    complainStatus['AddNominatedPerson'] = [3, 4, 5];
                    permissionCode['AddNominatedPerson'] = [202];
                    complainStatus['solutionWillBeValidatedByCoordinator'] = [3, 4, 5];
                    permissionCode['solutionWillBeValidatedByCoordinator'] = [202];
                    complainStatus['QuickFixRCANotReqDescription'] = [];
                    permissionCode['QuickFixRCANotReqDescription'] = [];

                    // ISSUE RESOULTION FORM
                    complainStatus['EstimatedCostView'] = [4, 5, 11, 12];
                    permissionCode['EstimatedCostView'] = [203];
                    complainStatus['ImpactOnInstalledBase'] = [4, 5, 11, 12];
                    permissionCode['ImpactOnInstalledBase'] = [203];
                    complainStatus['TotalInstalledBase'] = [4, 5, 11, 12];
                    permissionCode['TotalInstalledBase'] = [203];
                    complainStatus['QNumberResolvingUnit'] = [1, 2];
                    permissionCode['QNumberResolvingUnit'] = [203];

                    // complainStatus['RCACorrectiveActions'] = [4, 11, 12];
                    // permissionCode['RCACorrectiveActions'] = [203];
                    complainStatus['SpecificationName'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['SpecificationName'] = [203];
                    complainStatus['howProductFailMeetSpecification'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['howProductFailMeetSpecification'] = [203];
                    complainStatus['ContainmentActionsExecution'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['ContainmentActionsExecution'] = [203];
                    complainStatus['WhatContainmentActionsWhereTaken'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['WhatContainmentActionsWhereTaken'] = [203];
                    complainStatus['whatWasTheRootCauseOfThisIssue'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['whatWasTheRootCauseOfThisIssue'] = [203];
                    complainStatus['CategoryKeyword'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['CategoryKeyword'] = [203];
                    complainStatus['howDidWeResolveThisIssue'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['howDidWeResolveThisIssue'] = [203];
                    complainStatus['chipData'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['chipData'] = [203];
                    complainStatus['Upload'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['Upload'] = [203];
                    complainStatus['ActualRUCost'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['ActualRUCost'] = [203];
                    complainStatus['COPQCategory'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['COPQCategory'] = [203];
                    complainStatus['COPQCategoryAllData'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['COPQCategoryAllData'] = [203];
                    complainStatus['pgCategory'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['pgCategory'] = [203];
                    complainStatus['PGRCACategoryAllData'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['PGRCACategoryAllData'] = [203];
                    complainStatus['learn'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['learn'] = [203];
                    complainStatus['desc'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['desc'] = [203];
                    complainStatus['id'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['id'] = [203];

                    complainStatus['Rating'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['Rating'] = [203];

                    complainStatus['issueAssigned'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['issueAssigned'] = [203];
                    complainStatus['selectRole'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['selectRole'] = [203];
                    complainStatus['selectRole1'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['selectRole1'] = [203];
                    complainStatus['issueStatus'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['issueStatus'] = [203];

                    complainStatus['availabilityRating'] = [4, 5];
                    permissionCode['availabilityRating'] = [217];
                    complainStatus['functionalityRating'] = [4, 5];
                    permissionCode['functionalityRating'] = [217];
                    complainStatus['onTimeDeliveryRating'] = [4, 5];
                    permissionCode['onTimeDeliveryRating'] = [217];
                    complainStatus['cyberSecurityRating'] = [4, 5];
                    permissionCode['cyberSecurityRating'] = [217];
                    complainStatus['productComplianceRating'] = [4, 5];
                    permissionCode['productComplianceRating'] = [217];
                    complainStatus['ohsRiskRating'] = [4, 5];
                    permissionCode['ohsRiskRating'] = [217];
                    complainStatus['reputationRating'] = [4, 5];
                    permissionCode['reputationRating'] = [217];
                    complainStatus['marginRating'] = [4, 5];
                    permissionCode['marginRating'] = [217];
                    complainStatus['massMediaRating'] = [4, 5];
                    permissionCode['massMediaRating'] = [217];
                    complainStatus['designChangeRating'] = [4, 5];
                    permissionCode['designChangeRating'] = [217];
                    complainStatus['instancesRating'] = [4, 5];
                    permissionCode['instancesRating'] = [217];
                    complainStatus['resolutionRating'] = [4, 5];
                    permissionCode['resolutionRating'] = [217];
                    complainStatus['affectedCustomerRating'] = [4, 5];
                    permissionCode['affectedCustomerRating'] = [217];
                    complainStatus['controlRating'] = [4, 5];
                    permissionCode['controlRating'] = [217];
                    complainStatus['impactRating'] = [4, 5];
                    permissionCode['impactRating'] = [217];
                    complainStatus['classValue'] = [4, 5];
                    permissionCode['classValue'] = [217];
                    complainStatus['rpn'] = [4, 5];
                    permissionCode['rpn'] = [217];
                    complainStatus['JustificationCurrent'] = [4, 5];
                    permissionCode['JustificationCurrent'] = [217];
                    /* complainStatus['uploadRCAAttachments'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['uploadRCAAttachments'] = [203];
                    complainStatus['resolutionAttachments'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['resolutionAttachments'] = [203]; */
                    complainStatus['manualChangeClass'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['manualChangeClass'] = [217];

                    complainStatus['ReviewRequired'] = [4, 5];
                    permissionCode['ReviewRequired'] = [217];
                    complainStatus['eightDReportRequired'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['eightDReportRequired'] = [203];
                    // complainStatus['uploadedSummaryAttachments'] = [3, 4, 5, 11, 12, 7];
                    // permissionCode['uploadedSummaryAttachments'] = [203];
                    complainStatus['EightDJustificationCurrent'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['EightDJustificationCurrent'] = [203];
                    complainStatus['reviewRequiredJustification'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['reviewRequiredJustification'] = [203];

                    // Issue Closed Form
                    complainStatus['AwaitingCustomerExecution'] = [7];
                    permissionCode['AwaitingCustomerExecution'] = [204];
                    complainStatus['customerComments'] = [7, 13];
                    permissionCode['customerComments'] = [204];
                    complainStatus['ExpectedExecutionDate'] = [7, 13];
                    permissionCode['ExpectedExecutionDate'] = [204];
                    complainStatus['customerContactStatus'] = [7, 13];
                    permissionCode['customerContactStatus'] = [204];
                    complainStatus['sendFeedback'] = [7, 13];
                    permissionCode['sendFeedback'] = [204];
                    complainStatus['CustomerFeedbackJustification'] = [7, 13];
                    permissionCode['CustomerFeedbackJustification'] = [204];
                    complainStatus['customerResponse'] = [7, 13];
                    permissionCode['customerResponse'] = [204];
                    complainStatus['uploadedAcceptanceAttachments'] = [7, 13];
                    permissionCode['uploadedAcceptanceAttachments'] = [204];

                    //Effectiveness Form
                    complainStatus['id'] = [8];
                    permissionCode['id'] = [311];
                    complainStatus['IsEffective'] = [8];
                    permissionCode['IsEffective'] = [311];
                    complainStatus['DeadlineDate'] = [8];
                    permissionCode['issueDaeffectivenessDeadlineDatete'] = [311];
                    complainStatus['effectiveChangedValue'] = [8];
                    permissionCode['effectiveChangedValue'] = [311];
                    complainStatus['EffectivenessReason'] = [8];
                    permissionCode['EffectivenessReason'] = [311];
                    complainStatus['reopenReason'] = [8];
                    permissionCode['reopenReason'] = [311];
                    complainStatus['uploadedEffectivenessAttachments'] = [8];
                    permissionCode['uploadedEffectivenessAttachments'] = [311];
                    complainStatus['uploadedAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12]; //Hidden Field
                    permissionCode['uploadedAttachments'] = [210];

                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Global_CCRP_Process_Owner, complainStatus, permissionCode);
                    const permissionDataGlobal = this.authorizationMiddleWare.checkSetPermissionData();
                    this.setDisableBasedOnPermission(permissionDataGlobal);
                    break;
                case this.enumRoleCode.Support_Desk:
                    // Applied status code and permission from DEV (status code - in-progress  , Current rating - class C)
                    //    https://dev.ccrp.hitachienergy.com/en/Complaint/680851#NPOPR

                    complainStatus['PrimaryComplaintOwner'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PrimaryComplaintOwner'] = [201];
                    complainStatus['substitute'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['substitute'] = [201];
                    complainStatus['supervisor'] = [1, 2];
                    permissionCode['supervisor'] = [201];
                    complainStatus['CustomerType'] = [1, 2];
                    permissionCode['CustomerType'] = [201];
                    complainStatus['Customer'] = [1, 2];
                    permissionCode['Customer'] = [212];
                    complainStatus['customerNameData'] = [];// newly formControlName added
                    permissionCode['customerNameData'] = [];
                    complainStatus['customerNameHidden'] = [1, 2];// newly formControlName added
                    permissionCode['customerNameHidden'] = [212];
                    complainStatus['CustomerContact'] = [1, 2, 3, 4, 5, 7, 11, 12, 13];
                    permissionCode['CustomerContact'] = [211];
                    complainStatus['customerContactNameHidden'] = [];// newly formControlName added
                    permissionCode['customerContactNameHidden'] = [];
                    complainStatus['affectedCustomer'] = [1, 2, 3];
                    permissionCode['affectedCustomer'] = [212];
                    complainStatus['affectedCustomerHidden'] = [];// newly formControlName added
                    permissionCode['affectedCustomerHidden'] = [];
                    complainStatus['Subject'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Subject'] = [210];
                    //Issue Description Form Controls
                    complainStatus['Reason'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Reason'] = [210];
                    complainStatus['WhatIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['WhatIssueDescription'] = [209];
                    complainStatus['whyIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whyIssueDescription'] = [209];
                    complainStatus['howIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['howIssueDescription'] = [209];
                    complainStatus['whenIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whenIssueDescription'] = [209];
                    complainStatus['inWhichProcessStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['inWhichProcessStep'] = [209];
                    complainStatus['whereIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whereIssueDescription'] = [209];
                    complainStatus['issueStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueStep'] = [209];
                    /* complainStatus['issueLocationArr'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueLocationArr'] = [210]; */
                    complainStatus['customerExpectedResolutionDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['customerExpectedResolutionDescription'] = [209];
                    complainStatus['TargetResolvingDate'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['TargetResolvingDate'] = [201]
                    complainStatus['deadline'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deadline'] = [210]
                    complainStatus['ContainmentActionDescription'] = [1, 2];
                    permissionCode['ContainmentActionDescription'] = [201];
                    complainStatus['ContainmentActionsId'] = [];
                    permissionCode['ContainmentActionsId'] = [];
                    complainStatus['uploadedAttachments'] = [];
                    permissionCode['uploadedAttachments'] = [];
                    complainStatus['IsContainmentActionRequired'] = [];
                    permissionCode['IsContainmentActionRequired'] = [];
                    complainStatus['containmentExecutedDate'] = [];
                    permissionCode['containmentExecutedDate'] = [];
                    complainStatus['EightDRCAIsRequiredByCustomer'] = [1, 2];
                    permissionCode['EightDRCAIsRequiredByCustomer'] = [201];
                    complainStatus['targetResolutionHiddenDate'] = [];
                    permissionCode['targetResolutionHiddenDate'] = [];
                    complainStatus['dateChangeReason'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['dateChangeReason'] = [201];
                    complainStatus['changeDescription'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['changeDescription'] = [201];
                    complainStatus['targetResolvingDateChangeCount'] = [];
                    permissionCode['targetResolvingDateChangeCount'] = [];
                    complainStatus['changeReasonId'] = [];
                    permissionCode['changeReasonId'] = [];
                    complainStatus['targetResolvingDateInitialValue'] = [];
                    permissionCode['targetResolvingDateInitialValue'] = []; // newly formControlName added end
                    // additionalSubjectForm
                    complainStatus['resolvingUnit'] = [2, 3];
                    permissionCode['resolvingUnit'] = [214];
                    complainStatus['resolvingUnitArray'] = [3, 4, 5];
                    permissionCode['resolvingUnitArray'] = [214];
                    complainStatus['proposedResolutionOwner'] = [1, 2];
                    permissionCode['proposedResolutionOwner'] = [201];
                    complainStatus['Coordinators'] = [1, 2]
                    permissionCode['Coordinators'] = [201]

                    complainStatus['verificationTeam'] = []
                    permissionCode['verificationTeam'] = []
                    complainStatus['ABBProductCategory'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['ABBProductCategory'] = [209];
                    complainStatus['failureMode'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['failureMode'] = [209];
                    complainStatus['issueProductArray'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['issueProductArray'] = [209];
                    complainStatus['failureModeBind'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['failureModeBind'] = [209];

                    complainStatus['DefectTypeandDefectCodeGroupType'] = [1,2,3,4,5,11,12,7];
                    permissionCode['DefectTypeandDefectCodeGroupType'] = [209];
                    complainStatus['WorkCentreandPlant'] = [1,2,3,4,5,7,11,12];
                    permissionCode['WorkCentreandPlant'] = [209];
                    complainStatus['DefectCauseDefectCauseCodeGroup'] = [3,4,5,11,12,7];
                    permissionCode['DefectCauseDefectCauseCodeGroup'] = [203];

                    complainStatus['abbProductSerialNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProductSerialNo'] = [209];
                    complainStatus['purchaseOrderNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['purchaseOrderNo'] = [209];
                    complainStatus['abbProjectNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectNumber'] = [209];
                    complainStatus['abbProjectName'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectName'] = [209];
                    complainStatus['CountryCodeWhereTheIssueHappen'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['CountryCodeWhereTheIssueHappen'] = [209];
                    complainStatus['quantity'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['quantity'] = [209];
                    complainStatus['articleNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['articleNumber'] = [209];
                    complainStatus['itemDesignation'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['itemDesignation'] = [209];
                    complainStatus['abbCustomerReference'] = [1, 2, 3, 4, 5, 7, 11, 12]; //added abb
                    permissionCode['abbCustomerReference'] = [209];
                    complainStatus['supplier'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['supplier'] = [209];

                    complainStatus['ProductAlreadyDelivered'] = [4, 5, 11, 12];
                    permissionCode['ProductAlreadyDelivered'] = [203];
                    complainStatus['NoOfUnitsShipped'] = [4, 5, 11, 12];
                    permissionCode['NoOfUnitsShipped'] = [203];

                    complainStatus['deliveryDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deliveryDate'] = [209];
                    complainStatus['commissionDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['commissionDate'] = [209];
                    complainStatus['ManufacturingDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['ManufacturingDate'] = [209];
                    complainStatus['warrantyReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyReference'] = [209];
                    complainStatus['warrantyLifeCycleId'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyLifeCycleId'] = [209];
                    complainStatus['warrantyStartDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyStartDate'] = [209];
                    complainStatus['warrantyEndDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyEndDate'] = [209];
                    complainStatus['documentID'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['documentID'] = [209];
                    complainStatus['qNumberIssue'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['qNumberIssue'] = [209];

                    complainStatus['PartiesReference'] = [1, 2];
                    permissionCode['PartiesReference'] = [201];
                    complainStatus['SendCopyToParties'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['SendCopyToParties'] = [215];
                    // complainStatus['WhatContainmentActionsWhereTaken'] = [3, 4, 5, 11, 12, 7];
                    // permissionCode['WhatContainmentActionsWhereTaken'] = [203];

                    /* complainStatus['uploadedPDCAAttachments'] = [];
                    permissionCode['uploadedPDCAAttachments'] = [];
                    complainStatus['uploadedSummaryAttachments'] = [];
                    permissionCode['uploadedSummaryAttachments'] = []; */
                    complainStatus['hiddenIsHVDCComplaint'] = [];
                    permissionCode['hiddenIsHVDCComplaint'] = [];

                    // ISSUE ASSIGNMENT FORM
                    complainStatus['PrimaryResolutionOwner'] = [3, 4, 5, 11, 12];
                    permissionCode['PrimaryResolutionOwner'] = [207];
                    complainStatus['resolutionOwnerTeam'] = [3, 4, 5, 11, 12];
                    permissionCode['resolutionOwnerTeam'] = [206];
                    complainStatus['ccrpNo'] = [3, 4, 5];
                    permissionCode['ccrpNo'] = [202];
                    complainStatus['quickFixComplaint'] = [3, 4, 5];
                    permissionCode['quickFixComplaint'] = [202];
                    complainStatus['quickFixVariant'] = [3, 4, 5];
                    permissionCode['quickFixVariant'] = [202];
                    complainStatus['verifiedCheckBox'] = [3, 4, 5];
                    permissionCode['verifiedCheckBox'] = [202];
                    complainStatus['coordinatorList'] = [3, 4, 5];
                    permissionCode['coordinatorList'] = [202];
                    complainStatus['coordinatorName'] = [3, 4, 5];
                    permissionCode['coordinatorName'] = [202];
                    complainStatus['AddNominatedPerson'] = [3, 4, 5];
                    permissionCode['AddNominatedPerson'] = [202];
                    complainStatus['solutionWillBeValidatedByCoordinator'] = [3, 4, 5];
                    permissionCode['solutionWillBeValidatedByCoordinator'] = [202];
                    complainStatus['QuickFixRCANotReqDescription'] = [];// newly formControlName added
                    permissionCode['QuickFixRCANotReqDescription'] = [];

                    // ISSUE RESOULTION FORM
                    complainStatus['EstimatedCostView'] = [4, 5, 11, 12];
                    permissionCode['EstimatedCostView'] = [203];
                    complainStatus['ImpactOnInstalledBase'] = [4, 5, 11, 12];
                    permissionCode['ImpactOnInstalledBase'] = [203];
                    complainStatus['TotalInstalledBase'] = [4, 5, 11, 12];
                    permissionCode['TotalInstalledBase'] = [203];
                    complainStatus['QNumberResolvingUnit'] = [4, 11];
                    permissionCode['QNumberResolvingUnit'] = [203];
                    complainStatus['ContainmentActionsExecution'] = [];// newly formControlName added
                    permissionCode['ContainmentActionsExecution'] = [];
                    complainStatus['WhatContainmentActionsWhereTaken'] = [3, 4, 5, 11, 12, 7];// newly formControlName added
                    permissionCode['WhatContainmentActionsWhereTaken'] = [203];

                    complainStatus['availabilityRating'] = [4, 5];
                    permissionCode['availabilityRating'] = [217];
                    complainStatus['functionalityRating'] = [4, 5];
                    permissionCode['functionalityRating'] = [217];
                    complainStatus['onTimeDeliveryRating'] = [4, 5];
                    permissionCode['onTimeDeliveryRating'] = [217];
                    complainStatus['cyberSecurityRating'] = [4, 5];
                    permissionCode['cyberSecurityRating'] = [217];
                    complainStatus['productComplianceRating'] = [4, 5];
                    permissionCode['productComplianceRating'] = [217];
                    complainStatus['ohsRiskRating'] = [4, 5];
                    permissionCode['ohsRiskRating'] = [217];
                    complainStatus['reputationRating'] = [4, 5];
                    permissionCode['reputationRating'] = [217];
                    complainStatus['marginRating'] = [4, 5];
                    permissionCode['marginRating'] = [217];
                    complainStatus['massMediaRating'] = [4, 5];
                    permissionCode['massMediaRating'] = [217];
                    complainStatus['designChangeRating'] = [4, 5];
                    permissionCode['designChangeRating'] = [217];
                    complainStatus['instancesRating'] = [4, 5];
                    permissionCode['instancesRating'] = [217];
                    complainStatus['resolutionRating'] = [4, 5];
                    permissionCode['resolutionRating'] = [217];
                    complainStatus['affectedCustomerRating'] = [4, 5];
                    permissionCode['affectedCustomerRating'] = [217];
                    complainStatus['controlRating'] = [4, 5];
                    permissionCode['controlRating'] = [217];
                    complainStatus['impactRating'] = [4, 5];
                    permissionCode['impactRating'] = [217];

                    complainStatus['Rating'] = [4, 5];
                    permissionCode['Rating'] = [217];
                    complainStatus['JustificationCurrent'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['JustificationCurrent'] = [203];
                    complainStatus['ReviewRequired'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['ReviewRequired'] = [203];
                    complainStatus['reviewRequiredJustification'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['reviewRequiredJustification'] = [203];
                    complainStatus['eightDReportRequired'] = [3, 4, 5];
                    permissionCode['eightDReportRequired'] = [203];
                    complainStatus['EightDJustificationCurrent'] = [];
                    permissionCode['EightDJustificationCurrent'] = [203];

                    // complainStatus['RCACorrectiveActions'] = [11,12,4];
                    // permissionCode['RCACorrectiveActions'] = [203];
                    complainStatus['SpecificationName'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['SpecificationName'] = [203];
                    complainStatus['howProductFailMeetSpecification'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['howProductFailMeetSpecification'] = [203];


                    complainStatus['whatWasTheRootCauseOfThisIssue'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['whatWasTheRootCauseOfThisIssue'] = [203];
                    complainStatus['CategoryKeyword'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['CategoryKeyword'] = [203];
                    complainStatus['howDidWeResolveThisIssue'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['howDidWeResolveThisIssue'] = [203];

                    /* complainStatus['uploadedPDCAAttachments'] = [];
                    permissionCode['uploadedPDCAAttachments'] = [];
                    complainStatus['uploadedSummaryAttachments'] = [];
                    permissionCode['uploadedSummaryAttachments'] = []; */
                    complainStatus['Upload'] = [];
                    permissionCode['Upload'] = [];

                    complainStatus['learn'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['learn'] = [203];
                    complainStatus['desc'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['desc'] = [203];


                    complainStatus['ActualRUCost'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['ActualRUCost'] = [203];
                    complainStatus['COPQCategory'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['COPQCategory'] = [203];
                    complainStatus['COPQCategoryAllData'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['COPQCategoryAllData'] = [203];
                    complainStatus['pgCategory'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['pgCategory'] = [203];
                    complainStatus['PGRCACategoryAllData'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['PGRCACategoryAllData'] = [203];


                    complainStatus['chipData'] = [];
                    permissionCode['chipData'] = [];
                    complainStatus['id'] = [];
                    permissionCode['id'] = [];
                    complainStatus['issueAssigned'] = [];
                    permissionCode['issueAssigned'] = [];
                    complainStatus['selectRole'] = [];
                    permissionCode['selectRole'] = [];
                    complainStatus['selectRole1'] = [];
                    permissionCode['selectRole1'] = [];
                    complainStatus['issueStatus'] = [];
                    permissionCode['issueStatus'] = [];
                    complainStatus['classValue'] = [];
                    permissionCode['classValue'] = [];
                    complainStatus['rpn'] = [];
                    permissionCode['rpn'] = [];
                    complainStatus['manualChangeClass'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['manualChangeClass'] = [203];
                    complainStatus['UploadRCAAttachments'] = [];// newly formControlName added
                    permissionCode['UploadRCAAttachments'] = [];
                    complainStatus['resolutionAttachments'] = [];// newly formControlName added
                    permissionCode['resolutionAttachments'] = [];

                    // Issue Closed Form
                    complainStatus['AwaitingCustomerExecution'] = [7];
                    permissionCode['AwaitingCustomerExecution'] = [204];
                    complainStatus['customerComments'] = [7, 13];
                    permissionCode['customerComments'] = [204];
                    complainStatus['ExpectedExecutionDate'] = [7, 13];
                    permissionCode['ExpectedExecutionDate'] = [204];
                    complainStatus['customerContactStatus'] = [7, 13];
                    permissionCode['customerContactStatus'] = [204];
                    complainStatus['sendFeedback'] = [7, 13];
                    permissionCode['sendFeedback'] = [204];
                    complainStatus['CustomerFeedbackJustification'] = [7, 13];
                    permissionCode['CustomerFeedbackJustification'] = [204];
                    complainStatus['customerResponse'] = [7, 13];
                    permissionCode['customerResponse'] = [204];
                    complainStatus['uploadedAcceptanceAttachments'] = [7, 13];
                    permissionCode['uploadedAcceptanceAttachments'] = [204];

                    //Effectiveness Form
                    complainStatus['id'] = [8];
                    permissionCode['id'] = [311];
                    complainStatus['IsEffective'] = [8];
                    permissionCode['IsEffective'] = [311];
                    complainStatus['DeadlineDate'] = [8];
                    permissionCode['DeadlineDate'] = [311];
                    complainStatus['effectiveChangedValue'] = [8];
                    permissionCode['effectiveChangedValue'] = [311];
                    complainStatus['EffectivenessReason'] = [8];
                    permissionCode['EffectivenessReason'] = [311];
                    complainStatus['reopenReason'] = [8];
                    permissionCode['reopenReason'] = [311];
                    complainStatus['uploadedEffectivenessAttachments'] = [8];
                    permissionCode['uploadedEffectivenessAttachments'] = [311];

                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Support_Desk, complainStatus, permissionCode);
                    const supportData = this.authorizationMiddleWare.checkSetPermissionData();
                    console.log(supportData);
                    this.setDisableBasedOnPermission(supportData);
                    break;
                case this.enumRoleCode.Complaint_Owner_Supervisor:
                    complainStatus['PrimaryComplaintOwner'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PrimaryComplaintOwner'] = [201];
                    complainStatus['substitute'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['substitute'] = [201];
                    complainStatus['supervisor'] = [1, 2];
                    permissionCode['supervisor'] = [201];
                    complainStatus['CustomerType'] = [1, 2];
                    permissionCode['CustomerType'] = [201];
                    complainStatus['customerNameData'] = [1, 2];
                    permissionCode['customerNameData'] = [212];
                    complainStatus['CustomerContact'] = [1, 2, 3, 4, 5, 7, 11, 12, 13];
                    permissionCode['CustomerContact'] = [211];
                    complainStatus['customerContactNameHidden'] = [1, 2, 3, 4, 5, 7, 11, 12, 13];
                    permissionCode['customerContactNameHidden'] = [211];
                    complainStatus['affectedCustomer'] = [1, 2, 3];
                    permissionCode['affectedCustomer'] = [212];
                    complainStatus['affectedCustomerHidden'] = [1, 2, 3];
                    permissionCode['affectedCustomerHidden'] = [212];
                    complainStatus['Customer'] = [1, 2];
                    permissionCode['Customer'] = [212];
                    complainStatus['customerNameHidden'] = [1, 2];
                    permissionCode['customerNameHidden'] = [212];
                    complainStatus['Subject'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Subject'] = [210];
                    complainStatus['Reason'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Reason'] = [210];
                    complainStatus['WhatIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['WhatIssueDescription'] = [209];
                    complainStatus['whyIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whyIssueDescription'] = [209];
                    complainStatus['howIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['howIssueDescription'] = [209];
                    complainStatus['whenIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whenIssueDescription'] = [209];
                    complainStatus['inWhichProcessStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['inWhichProcessStep'] = [209];
                    complainStatus['whereIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whereIssueDescription'] = [209];
                    complainStatus['issueStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueStep'] = [209];
                    complainStatus['customerExpectedResolutionDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['customerExpectedResolutionDescription'] = [209];
                    complainStatus['uploadedAttachments'] = [];
                    permissionCode['uploadedAttachments'] = [];
                    complainStatus['ContainmentActionsId'] = [];
                    permissionCode['ContainmentActionsId'] = [];
                    complainStatus['IsContainmentActionRequired'] = [1, 2];
                    permissionCode['IsContainmentActionRequired'] = [201];
                    complainStatus['TargetResolvingDate'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['TargetResolvingDate'] = [201];
                    complainStatus['targetResolutionHiddenDate'] = [];
                    permissionCode['targetResolutionHiddenDate'] = [];
                    complainStatus['deadline'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deadline'] = [210];
                    complainStatus['ContainmentActionDescription'] = [1, 2];
                    permissionCode['ContainmentActionDescription'] = [201];
                    complainStatus['containmentExecutedDate'] = [];
                    permissionCode['containmentExecutedDate'] = [];
                    complainStatus['EightDRCAIsRequiredByCustomer'] = [1, 2];
                    permissionCode['EightDRCAIsRequiredByCustomer'] = [201];
                    complainStatus['dateChangeReason'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['dateChangeReason'] = [201];
                    complainStatus['changeDescription'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['changeDescription'] = [201];
                    permissionCode['targetResolvingDateChangeCount'] = [];
                    complainStatus['targetResolvingDateChangeCount'] = [];
                    permissionCode['changeReasonId'] = [];
                    permissionCode['changeReasonId'] = [];
                    permissionCode['targetResolvingUnitInitialValue'] = [];
                    permissionCode['targetResolvingUnitInitialValue'] = [];

                    // Sapqn
                    complainStatus['DefectTypeandDefectCodeGroupType'] = [1,2,3,4,5,11,12,7];
                    permissionCode['DefectTypeandDefectCodeGroupType'] = [209];
                    complainStatus['WorkCentreandPlant'] = [1,2,3,4,5,7,11,12];
                    permissionCode['WorkCentreandPlant'] = [209];
                    complainStatus['DefectCauseDefectCauseCodeGroup'] = [4,5,11,12];
                    permissionCode['DefectCauseDefectCauseCodeGroup'] = [203];

                    // additionalSubjectForm
                    complainStatus['resolvingUnit'] = [1, 2];
                    permissionCode['resolvingUnit'] = [214];
                    complainStatus['resolvingUnitArray'] = [1, 2];
                    permissionCode['resolvingUnitArray'] = [214];
                    complainStatus['proposedResolutionOwner'] = [1, 2];
                    permissionCode['proposedResolutionOwner'] = [201];
                    complainStatus['Coordinators'] = [1, 2]
                    permissionCode['Coordinators'] = [201]
                    complainStatus['verificationTeam'] = []
                    permissionCode['verificationTeam'] = []
                    complainStatus['ABBProductCategory'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['ABBProductCategory'] = [209];
                    complainStatus['issueProductArray'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['issueProductArray'] = [209];
                    complainStatus['failureMode'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['failureMode'] = [209];
                    complainStatus['failureModeBind'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['failureModeBind'] = [209];
                    complainStatus['abbProductSerialNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProductSerialNo'] = [209];
                    complainStatus['purchaseOrderNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['purchaseOrderNo'] = [209];
                    complainStatus['abbProjectNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectNumber'] = [209];
                    complainStatus['abbProjectName'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectName'] = [209];
                    complainStatus['CountryCodeWhereTheIssueHappen'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['CountryCodeWhereTheIssueHappen'] = [209];
                    complainStatus['quantity'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['quantity'] = [209];
                    complainStatus['articleNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['articleNumber'] = [209];
                    complainStatus['itemDesignation'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['itemDesignation'] = [209];
                    complainStatus['abbCustomerReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbCustomerReference'] = [209];
                    complainStatus['supplier'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['supplier'] = [209];
                    complainStatus['deliveryDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deliveryDate'] = [209];
                    complainStatus['commissionDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['commissionDate'] = [209];
                    complainStatus['ManufacturingDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['ManufacturingDate'] = [209];
                    complainStatus['warrantyReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyReference'] = [209];
                    complainStatus['warrantyLifeCycleId'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyLifeCycleId'] = [209];
                    complainStatus['warrantyStartDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyStartDate'] = [209];
                    complainStatus['warrantyEndDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyEndDate'] = [209];
                    complainStatus['documentID'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['documentID'] = [209];
                    complainStatus['qNumberIssue'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['qNumberIssue'] = [209];
                    complainStatus['ProductAlreadyDelivered'] = [4, 5, 11, 12];
                    permissionCode['ProductAlreadyDelivered'] = [203];
                    complainStatus['NoOfUnitsShipped'] = [4, 5, 11, 12];
                    permissionCode['NoOfUnitsShipped'] = [203];
                    complainStatus['PartiesReference'] = [1, 2];
                    permissionCode['PartiesReference'] = [201];
                    complainStatus['SendCopyToParties'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['SendCopyToParties'] = [215];
                    complainStatus['WhatContainmentActionsWhereTaken'] = [1, 2];
                    permissionCode['WhatContainmentActionsWhereTaken'] = [201];

                    complainStatus['uploadedPDCAAttachments'] = [];
                    permissionCode['uploadedPDCAAttachments'] = [];
                    complainStatus['uploadedSummaryAttachments'] = [];
                    permissionCode['uploadedSummaryAttachments'] = [];
                    complainStatus['hiddenIsHVDCComplaint'] = [];
                    permissionCode['hiddenIsHVDCComplaint'] = [];

                    // ISSUE ASSIGNMENT FORM
                    complainStatus['PrimaryResolutionOwner'] = [3, 4, 5, 11, 12];
                    permissionCode['PrimaryResolutionOwner'] = [207];
                    complainStatus['resolutionOwnerTeam'] = [3, 4, 5, 11, 12];
                    permissionCode['resolutionOwnerTeam'] = [206];
                    complainStatus['ccrpNo'] = [3, 4, 5];
                    permissionCode['ccrpNo'] = [202];
                    complainStatus['quickFixComplaint'] = [3, 4, 5];
                    permissionCode['quickFixComplaint'] = [202];
                    complainStatus['quickFixVariant'] = [3, 4, 5];
                    permissionCode['quickFixVariant'] = [202];
                    complainStatus['verifiedCheckBox'] = [3, 4, 5];
                    permissionCode['verifiedCheckBox'] = [202];
                    complainStatus['coordinatorList'] = [3, 4, 5];
                    permissionCode['coordinatorList'] = [202];
                    complainStatus['coordinatorName'] = [3, 4, 5];
                    permissionCode['coordinatorName'] = [202];
                    complainStatus['AddNominatedPerson'] = [3, 4, 5];
                    permissionCode['AddNominatedPerson'] = [202];
                    complainStatus['solutionWillBeValidatedByCoordinator'] = [3, 4, 5];
                    permissionCode['solutionWillBeValidatedByCoordinator'] = [202];
                    complainStatus['QuickFixRCANotReqDescription'] = [];
                    permissionCode['QuickFixRCANotReqDescription'] = [];

                    // ISSUE RESOULTION FORM
                    complainStatus['EstimatedCostView'] = [1, 2];
                    permissionCode['EstimatedCostView'] = [203];
                    complainStatus['ImpactOnInstalledBase'] = [1, 2];
                    permissionCode['ImpactOnInstalledBase'] = [203];
                    complainStatus['TotalInstalledBase'] = [1, 2];
                    permissionCode['TotalInstalledBase'] = [203];
                    complainStatus['QNumberResolvingUnit'] = [1, 2];
                    permissionCode['QNumberResolvingUnit'] = [203];
                    complainStatus['availabilityRating'] = [];
                    permissionCode['availabilityRating'] = [];
                    complainStatus['functionalityRating'] = [];
                    permissionCode['functionalityRating'] = [];
                    complainStatus['onTimeDeliveryRating'] = [];
                    permissionCode['onTimeDeliveryRating'] = [];
                    complainStatus['cyberSecurityRating'] = [];
                    permissionCode['cyberSecurityRating'] = [];
                    complainStatus['productComplianceRating'] = [];
                    permissionCode['productComplianceRating'] = [];
                    complainStatus['ohsRiskRating'] = [];
                    permissionCode['ohsRiskRating'] = [];
                    complainStatus['marginRating'] = [];
                    permissionCode['marginRating'] = [];
                    complainStatus['reputationRating'] = [];
                    permissionCode['reputationRating'] = [];
                    complainStatus['massMediaRating'] = [];
                    permissionCode['massMediaRating'] = [];
                    complainStatus['designChangeRating'] = [];
                    permissionCode['designChangeRating'] = [];
                    complainStatus['instancesRating'] = [];
                    permissionCode['instancesRating'] = [];
                    complainStatus['resolutionRating'] = [];
                    permissionCode['resolutionRating'] = [];
                    complainStatus['affectedCustomerRating'] = [];
                    permissionCode['affectedCustomerRating'] = [];
                    complainStatus['controlRating'] = [];
                    permissionCode['controlRating'] = [];
                    complainStatus['impactRating'] = [];
                    permissionCode['impactRating'] = [];
                    complainStatus['Rating'] = [];
                    permissionCode['Rating'] = [];
                    // complainStatus['justification'] = [1, 2];
                    // permissionCode['justification'] = [203];
                    complainStatus['ReviewRequired'] = [1, 2];
                    permissionCode['ReviewRequired'] = [203];
                    complainStatus['reviewRequiredJustification'] = [1, 2];
                    permissionCode['reviewRequiredJustification'] = [203];
                    complainStatus['eightDReportRequired'] = [3, 4, 5, 7, 11, 12];
                    permissionCode['eightDReportRequired'] = [203];
                    complainStatus['EightDJustificationCurrent'] = [3, 4, 5, 7, 11, 12];
                    permissionCode['EightDJustificationCurrent'] = [203];
                    // complainStatus['RCACorrectiveActions'] = [1, 2];
                    // permissionCode['RCACorrectiveActions'] = [203];
                    complainStatus['SpecificationName'] = [1, 2];
                    permissionCode['SpecificationName'] = [203];
                    complainStatus['howProductFailMeetSpecification'] = [1, 2];
                    permissionCode['howProductFailMeetSpecification'] = [203];
                    complainStatus['ContainmentActionsExecution'] = [];
                    permissionCode['ContainmentActionsExecution'] = [];
                    complainStatus['WhatContainmentActionsWhereTaken'] = [];
                    permissionCode['WhatContainmentActionsWhereTaken'] = [];
                    complainStatus['whatWasTheRootCauseOfThisIssue'] = [1, 2];
                    permissionCode['whatWasTheRootCauseOfThisIssue'] = [203];
                    complainStatus['CategoryKeyword'] = [1, 2];
                    permissionCode['CategoryKeyword'] = [203];
                    complainStatus['howDidWeResolveThisIssue'] = [1, 2];
                    permissionCode['howDidWeResolveThisIssue'] = [203];
                    complainStatus['JustificationCurrent'] = [1, 2];
                    permissionCode['JustificationCurrent'] = [203];
                    complainStatus['UploadRCAAttachments'] = [];
                    permissionCode['UploadRCAAttachments'] = [];
                    complainStatus['resolutionAttachments'] = [7, 13];
                    permissionCode['resolutionAttachments'] = [204];
                    // complainStatus['uploadedPDCAAttachments'] = [];
                    // permissionCode['uploadedPDCAAttachments'] = [];
                    // complainStatus['uploadedSummaryAttachments'] = [];
                    // permissionCode['uploadedSummaryAttachments'] = [];
                    complainStatus['Upload'] = [];
                    permissionCode['Upload'] = [];
                    complainStatus['learn'] = [1, 2];
                    permissionCode['learn'] = [203];
                    complainStatus['desc'] = [1, 2];
                    permissionCode['desc'] = [203];
                    complainStatus['ActualRUCost'] = [1, 2];
                    permissionCode['ActualRUCost'] = [203];
                    complainStatus['COPQCategory'] = [1, 2];
                    permissionCode['COPQCategory'] = [203];
                    complainStatus['COPQCategoryAllData'] = [1, 2];
                    permissionCode['COPQCategoryAllData'] = [203];
                    complainStatus['pgCategory'] = [1, 2];
                    permissionCode['pgCategory'] = [203];
                    complainStatus['PGRCACategoryAllData'] = [1, 2];
                    permissionCode['PGRCACategoryAllData'] = [203];
                    complainStatus['chipData'] = [];
                    permissionCode['chipData'] = [];
                    complainStatus['id'] = [];
                    permissionCode['id'] = [];
                    complainStatus['issueAssigned'] = [];
                    permissionCode['issueAssigned'] = [];
                    complainStatus['selectRole'] = [];
                    permissionCode['selectRole'] = [];
                    complainStatus['selectRole1'] = [];
                    permissionCode['selectRole1'] = [];
                    complainStatus['issueStatus'] = [];
                    permissionCode['issueStatus'] = [];
                    complainStatus['classValue'] = [];
                    permissionCode['classValue'] = [];
                    complainStatus['rpn'] = [];
                    permissionCode['rpn'] = [];
                    complainStatus['manualChangeClass'] = [4, 5, 11, 12];
                    permissionCode['manualChangeClass'] = [203];

                    // Issue Closed Form
                    complainStatus['AwaitingCustomerExecution'] = [7];
                    permissionCode['AwaitingCustomerExecution'] = [204];
                    complainStatus['customerComments'] = [7, 13];
                    permissionCode['customerComments'] = [204];
                    complainStatus['ExpectedExecutionDate'] = [7, 13];
                    permissionCode['ExpectedExecutionDate'] = [204];
                    complainStatus['customerContactStatus'] = [7, 13];
                    permissionCode['customerContactStatus'] = [204];
                    complainStatus['sendFeedback'] = [7, 13];
                    permissionCode['sendFeedback'] = [204];
                    complainStatus['CustomerFeedbackJustification'] = [7, 13];
                    permissionCode['CustomerFeedbackJustification'] = [204];
                    complainStatus['customerResponse'] = [7, 13];
                    permissionCode['customerResponse'] = [204];
                    complainStatus['uploadedAcceptanceAttachments'] = [7, 13];
                    permissionCode['uploadedAcceptanceAttachments'] = [204];

                    //Effectiveness Form
                    complainStatus['id'] = [8];
                    permissionCode['id'] = [311];
                    complainStatus['IsEffective'] = [8];
                    permissionCode['IsEffective'] = [311];
                    complainStatus['DeadlineDate'] = [8];
                    permissionCode['DeadlineDate'] = [311];
                    complainStatus['effectiveChangedValue'] = [8];
                    permissionCode['effectiveChangedValue'] = [311];
                    complainStatus['EffectivenessReason'] = [8];
                    permissionCode['EffectivenessReason'] = [311];
                    complainStatus['reopenReason'] = [8];
                    permissionCode['reopenReason'] = [311];
                    complainStatus['uploadedEffectivenessAttachments'] = [8];
                    permissionCode['uploadedEffectivenessAttachments'] = [311];
                    complainStatus['uploadedAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12]; //Hidden Field
                    permissionCode['uploadedAttachments'] = [210];
                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Complaint_Owner_Supervisor, complainStatus, permissionCode);
                    const ownerSupervisor = this.authorizationMiddleWare.checkSetPermissionData();
                    this.setDisableBasedOnPermission(ownerSupervisor);
                    break;
                case this.enumRoleCode.Complaint_Owner_Team:
                    // Issue Capture Form
                    complainStatus['PrimaryComplaintOwner'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PrimaryComplaintOwner'] = [201];
                    complainStatus['substitute'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['substitute'] = [201];
                    complainStatus['supervisor'] = [1, 2];
                    permissionCode['supervisor'] = [201];
                    complainStatus['CustomerType'] = [1, 2];
                    permissionCode['CustomerType'] = [201];
                    complainStatus['customerNameData'] = [];// newly formControlName added
                    permissionCode['customerNameData'] = [];
                    complainStatus['Customer'] = [1, 2];
                    permissionCode['Customer'] = [212];
                    complainStatus['customerNameHidden'] = [1, 2];// newly formControlName added
                    permissionCode['customerNameHidden'] = [212];
                    complainStatus['CustomerContact'] = [1, 2, 3, 4, 5, 7, 11, 12, 13];
                    permissionCode['CustomerContact'] = [211];
                    complainStatus['customerContactNameHidden'] = [];// newly formControlName added
                    permissionCode['customerContactNameHidden'] = [];
                    complainStatus['affectedCustomer'] = [1, 2, 3];
                    permissionCode['affectedCustomer'] = [212];
                    complainStatus['affectedCustomerHidden'] = [];// newly formControlName added
                    permissionCode['affectedCustomerHidden'] = [];
                    complainStatus['Subject'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Subject'] = [210];
                    complainStatus['Reason'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Reason'] = [210];
                    complainStatus['WhatIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['WhatIssueDescription'] = [209];
                    complainStatus['whyIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whyIssueDescription'] = [209];
                    complainStatus['howIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['howIssueDescription'] = [209];
                    complainStatus['whenIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whenIssueDescription'] = [209];
                    complainStatus['inWhichProcessStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['inWhichProcessStep'] = [209];
                    complainStatus['whereIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whereIssueDescription'] = [209];
                    complainStatus['issueStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueStep'] = [209];
                    /* complainStatus['issueLocationArr'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueLocationArr'] = [210]; */
                    complainStatus['customerExpectedResolutionDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['customerExpectedResolutionDescription'] = [209];
                    complainStatus['TargetResolvingDate'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['TargetResolvingDate'] = [201]
                    permissionCode['ContainmentActionsId'] = [];
                    complainStatus['uploadedAttachments'] = [];
                    permissionCode['uploadedAttachments'] = [];
                    complainStatus['IsContainmentActionRequired'] = [1, 2];//US-7908 Issue Owner team can able to modify containment action field on draft stage
                    permissionCode['IsContainmentActionRequired'] = [201];//US-7908 Issue Owner team can able to modify containment action field
                    complainStatus['deadline'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deadline'] = [210]
                    complainStatus['ContainmentActionDescription'] = [1, 2];//US-7908 Issue Owner team can able to modify containment action field on Draft stage
                    permissionCode['ContainmentActionDescription'] = [201];//US-7908 Issue Owner team can able to modify containment action field
                    complainStatus['containmentExecutedDate'] = [];
                    permissionCode['containmentExecutedDate'] = [];
                    complainStatus['EightDRCAIsRequiredByCustomer'] = [1, 2];
                    permissionCode['EightDRCAIsRequiredByCustomer'] = [201];
                    complainStatus['targetResolutionHiddenDate'] = [];
                    permissionCode['targetResolutionHiddenDate'] = [];
                    complainStatus['dateChangeReason'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['dateChangeReason'] = [201];
                    complainStatus['changeDescription'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['changeDescription'] = [201];
                    complainStatus['targetResolvingDateChangeCount'] = [];
                    permissionCode['targetResolvingDateChangeCount'] = [];
                    complainStatus['changeReasonId'] = [];
                    permissionCode['changeReasonId'] = [];
                    complainStatus['targetResolvingDateInitialValue'] = [];
                    permissionCode['targetResolvingDateInitialValue'] = []; // newly formControlName added end

                    complainStatus['DefectTypeandDefectCodeGroupType'] = [1,2,3,4,5,11,12,7];
                    permissionCode['DefectTypeandDefectCodeGroupType'] = [209];
                    complainStatus['WorkCentreandPlant'] = [1,2,3,4,5,7,11,12];
                    permissionCode['WorkCentreandPlant'] = [209];
                    complainStatus['DefectCauseDefectCauseCodeGroup'] = [4,5,11,12];
                    permissionCode['DefectCauseDefectCauseCodeGroup'] = [203];


                    // additionalSubjectForm
                    complainStatus['resolvingUnit'] = [1, 2];
                    permissionCode['resolvingUnit'] = [214];
                    complainStatus['resolvingUnitArray'] = [1, 2];
                    permissionCode['resolvingUnitArray'] = [214];
                    complainStatus['proposedResolutionOwner'] = [1, 2];
                    permissionCode['proposedResolutionOwner'] = [201];
                    complainStatus['Coordinators'] = [1, 2]
                    permissionCode['Coordinators'] = [201]
                    complainStatus['verificationTeam'] = []
                    permissionCode['verificationTeam'] = []
                    complainStatus['ABBProductCategory'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['ABBProductCategory'] = [209];
                    complainStatus['issueProductArray'] = []; // newly formControlName added
                    permissionCode['issueProductArray'] = [];
                    complainStatus['failureMode'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['failureMode'] = [209];
                    complainStatus['failureModeBind'] = []; // newly formControlName added
                    permissionCode['failureModeBind'] = [];
                    complainStatus['abbProductSerialNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProductSerialNo'] = [209];
                    complainStatus['purchaseOrderNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['purchaseOrderNo'] = [209];
                    complainStatus['abbProjectNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectNumber'] = [209];
                    complainStatus['abbProjectName'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectName'] = [209];
                    complainStatus['CountryCodeWhereTheIssueHappen'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['CountryCodeWhereTheIssueHappen'] = [209];
                    complainStatus['quantity'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['quantity'] = [209];
                    complainStatus['articleNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['articleNumber'] = [209];
                    complainStatus['itemDesignation'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['itemDesignation'] = [209];
                    complainStatus['abbCustomerReference'] = [1, 2, 3, 4, 5, 7, 11, 12]; //abb added
                    permissionCode['abbCustomerReference'] = [209];
                    complainStatus['supplier'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['supplier'] = [209];
                    complainStatus['deliveryDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deliveryDate'] = [209];
                    complainStatus['commissionDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['commissionDate'] = [209];
                    complainStatus['ManufacturingDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['ManufacturingDate'] = [209];
                    complainStatus['warrantyReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyReference'] = [209];
                    complainStatus['warrantyLifeCycleId'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyLifeCycleId'] = [209];
                    complainStatus['warrantyStartDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyStartDate'] = [209];
                    complainStatus['warrantyEndDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyEndDate'] = [209];
                    complainStatus['documentID'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['documentID'] = [209];
                    complainStatus['qNumberIssue'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['qNumberIssue'] = [209];
                    complainStatus['PartiesReference'] = [1, 2];
                    permissionCode['PartiesReference'] = [201];
                    complainStatus['SendCopyToParties'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['SendCopyToParties'] = [215];
                    complainStatus['ProductAlreadyDelivered'] = [4, 5, 11, 12];
                    permissionCode['ProductAlreadyDelivered'] = [203];
                    complainStatus['NoOfUnitsShipped'] = [4, 5, 11, 12];
                    permissionCode['NoOfUnitsShipped'] = [203];
                    /* complainStatus['uploadedPDCAAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['uploadedPDCAAttachments'] = [209];
                    complainStatus['uploadedSummaryAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['uploadedSummaryAttachments'] = [209]; */
                    complainStatus['hiddenIsHVDCComplaint'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['hiddenIsHVDCComplaint'] = [209];

                    // ISSUE ASSIGNMENT FORM
                    complainStatus['PrimaryResolutionOwner'] = [3, 4, 5, 11, 12];
                    permissionCode['PrimaryResolutionOwner'] = [207];
                    complainStatus['resolutionOwnerTeam'] = [3, 4, 5, 11, 12];
                    permissionCode['resolutionOwnerTeam'] = [206];
                    complainStatus['ccrpNo'] = [3, 4, 5];
                    permissionCode['ccrpNo'] = [202];
                    complainStatus['quickFixComplaint'] = [3, 4, 5];
                    permissionCode['quickFixComplaint'] = [202];
                    complainStatus['quickFixVariant'] = [3, 4, 5];
                    permissionCode['quickFixVariant'] = [202];
                    complainStatus['verifiedCheckBox'] = [3, 4, 5];
                    permissionCode['verifiedCheckBox'] = [202];
                    complainStatus['coordinatorList'] = [3, 4, 5];
                    permissionCode['coordinatorList'] = [202];
                    complainStatus['coordinatorName'] = [3, 4, 5];
                    permissionCode['coordinatorName'] = [202];
                    complainStatus['AddNominatedPerson'] = [3, 4, 5];
                    permissionCode['AddNominatedPerson'] = [202];
                    complainStatus['solutionWillBeValidatedByCoordinator'] = [];// newly formControlName added
                    permissionCode['solutionWillBeValidatedByCoordinator'] = [];
                    complainStatus['QuickFixRCANotReqDescription'] = [];// newly formControlName added
                    permissionCode['QuickFixRCANotReqDescription'] = [];

                    // ISSUE RESOULTION FORM
                    complainStatus['EstimatedCostView'] = [1, 2];
                    permissionCode['EstimatedCostView'] = [203];
                    complainStatus['ImpactOnInstalledBase'] = [1, 2];
                    permissionCode['ImpactOnInstalledBase'] = [203];
                    complainStatus['TotalInstalledBase'] = [1, 2];
                    permissionCode['TotalInstalledBase'] = [203];
                    complainStatus['QNumberResolvingUnit'] = [1, 2];
                    permissionCode['QNumberResolvingUnit'] = [203];
                    // complainStatus['RCACorrectiveActions'] = [1, 2];
                    // permissionCode['RCACorrectiveActions'] = [203];
                    complainStatus['SpecificationName'] = [4, 5, 11, 12];
                    permissionCode['SpecificationName'] = [203];
                    complainStatus['howProductFailMeetSpecification'] = [1, 2];
                    permissionCode['howProductFailMeetSpecification'] = [203];
                    complainStatus['ContainmentActionsExecution'] = [];
                    permissionCode['ContainmentActionsExecution'] = [];
                    complainStatus['WhatContainmentActionsWhereTaken'] = [1, 2];
                    permissionCode['WhatContainmentActionsWhereTaken'] = [203];
                    complainStatus['whatWasTheRootCauseOfThisIssue'] = [1, 2];
                    permissionCode['whatWasTheRootCauseOfThisIssue'] = [203];
                    complainStatus['CategoryKeyword'] = [1, 2];
                    permissionCode['CategoryKeyword'] = [203];
                    complainStatus['howDidWeResolveThisIssue'] = [1, 2];
                    permissionCode['howDidWeResolveThisIssue'] = [203];
                    complainStatus['chipData'] = [];
                    permissionCode['chipData'] = [];
                    complainStatus['Upload'] = [1, 2];
                    permissionCode['Upload'] = [203];
                    complainStatus['ActualRUCost'] = [1, 2];
                    permissionCode['ActualRUCost'] = [203];
                    complainStatus['COPQCategory'] = [1, 2];
                    permissionCode['COPQCategory'] = [203];
                    complainStatus['COPQCategoryAllData'] = [];
                    permissionCode['COPQCategoryAllData'] = [];
                    complainStatus['pgCategory'] = [1, 2];
                    permissionCode['pgCategory'] = [203];
                    complainStatus['PGRCACategoryAllData'] = [];
                    permissionCode['PGRCACategoryAllData'] = [];
                    complainStatus['learn'] = [1, 2];
                    permissionCode['learn'] = [203];
                    complainStatus['desc'] = [1, 2];
                    permissionCode['desc'] = [203];
                    complainStatus['id'] = [];
                    permissionCode['id'] = [];
                    complainStatus['Rating'] = [];
                    permissionCode['Rating'] = [];
                    complainStatus['issueAssigned'] = [];
                    permissionCode['issueAssigned'] = [];
                    complainStatus['selectRole'] = [];
                    permissionCode['selectRole'] = [];
                    complainStatus['selectRole1'] = [];
                    permissionCode['selectRole1'] = [];
                    complainStatus['issueStatus'] = [];
                    permissionCode['issueStatus'] = [];
                    complainStatus['availabilityRating'] = [];
                    permissionCode['availabilityRating'] = [];
                    complainStatus['functionalityRating'] = [];
                    permissionCode['functionalityRating'] = [];
                    complainStatus['onTimeDeliveryRating'] = [];
                    permissionCode['onTimeDeliveryRating'] = [];
                    complainStatus['cyberSecurityRating'] = [];
                    permissionCode['cyberSecurityRating'] = [];
                    complainStatus['productComplianceRating'] = [];
                    permissionCode['productComplianceRating'] = [];
                    complainStatus['ohsRiskRating'] = [];
                    permissionCode['ohsRiskRating'] = [];
                    complainStatus['reputationRating'] = [];
                    permissionCode['reputationRating'] = [];
                    complainStatus['marginRating'] = [];
                    permissionCode['marginRating'] = [];
                    complainStatus['massMediaRating'] = [];
                    permissionCode['massMediaRating'] = [];
                    complainStatus['designChangeRating'] = [];
                    permissionCode['designChangeRating'] = [];
                    complainStatus['instancesRating'] = [];
                    permissionCode['instancesRating'] = [];
                    complainStatus['resolutionRating'] = [];
                    permissionCode['resolutionRating'] = [];
                    complainStatus['affectedCustomerRating'] = [];
                    permissionCode['affectedCustomerRating'] = [];
                    complainStatus['controlRating'] = [];
                    permissionCode['controlRating'] = [];
                    complainStatus['impactRating'] = [];
                    permissionCode['impactRating'] = [];
                    complainStatus['classValue'] = [];
                    permissionCode['classValue'] = [];
                    complainStatus['rpn'] = [];
                    permissionCode['rpn'] = [];
                    /* complainStatus['justification'] = [];
                    permissionCode['justification'] = []; */
                    /* complainStatus['uploadedPDCAAttachments'] = [];
                    permissionCode['uploadedPDCAAttachments'] = [];
                    complainStatus['uploadedSummaryAttachments'] = [];
                    permissionCode['uploadedSummaryAttachments'] = []; */
                    complainStatus['manualChangeClass'] = [4, 5, 11, 12];
                    permissionCode['manualChangeClass'] = [203];
                    complainStatus['ReviewRequired'] = [];
                    permissionCode['ReviewRequired'] = [];
                    complainStatus['eightDReportRequired'] = [];
                    permissionCode['eightDReportRequired'] = [];
                    /* complainStatus['uploadedSummaryAttachments'] = [];
                    permissionCode['uploadedSummaryAttachments'] = []; */
                    complainStatus['EightDJustificationCurrent'] = [];
                    permissionCode['EightDJustificationCurrent'] = [];
                    complainStatus['reviewRequiredJustification'] = [];
                    permissionCode['reviewRequiredJustification'] = [];
                    complainStatus['JustificationCurrent'] = [];// newly formControlName added
                    permissionCode['JustificationCurrent'] = [];
                    complainStatus['UploadRCAAttachments'] = [];// newly formControlName added
                    permissionCode['UploadRCAAttachments'] = [];
                    complainStatus['resolutionAttachments'] = [];// newly formControlName added
                    permissionCode['resolutionAttachments'] = [];

                    // Issue Closed Form
                    complainStatus['AwaitingCustomerExecution'] = [7];
                    permissionCode['AwaitingCustomerExecution'] = [204];
                    complainStatus['customerComments'] = [7, 13];
                    permissionCode['customerComments'] = [204];
                    complainStatus['ExpectedExecutionDate'] = [7, 13];
                    permissionCode['ExpectedExecutionDate'] = [204];
                    complainStatus['customerContactStatus'] = [7,13];
                    permissionCode['customerContactStatus'] = [0];
                    complainStatus['sendFeedback'] = [7, 13];
                    permissionCode['sendFeedback'] = [204];
                    complainStatus['CustomerFeedbackJustification'] = [7, 13];
                    permissionCode['CustomerFeedbackJustification'] = [204];
                    complainStatus['customerResponse'] = [];
                    permissionCode['customerResponse'] = [0];
                    complainStatus['uploadedAcceptanceAttachments'] = [7];
                    permissionCode['uploadedAcceptanceAttachments'] = [204];

                    //Effectiveness Form
                    complainStatus['id'] = [];
                    permissionCode['id'] = [];
                    complainStatus['IsEffective'] = [8];
                    permissionCode['IsEffective'] = [311];
                    complainStatus['DeadlineDate'] = [8];
                    permissionCode['DeadlineDate'] = [311];
                    complainStatus['effectiveChangedValue'] = [];
                    permissionCode['effectiveChangedValue'] = [];
                    complainStatus['EffectivenessReason'] = [8];
                    permissionCode['EffectivenessReason'] = [311];
                    complainStatus['reopenReason'] = [];
                    permissionCode['reopenReason'] = [];
                    complainStatus['uploadedEffectivenessAttachments'] = [8];
                    permissionCode['uploadedEffectivenessAttachments'] = [311];
                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Complaint_Owner_Team, complainStatus, permissionCode);
                    const permissionDataComplaintOwnerTeam = this.authorizationMiddleWare.checkSetPermissionData();
                    this.setDisableBasedOnPermission(permissionDataComplaintOwnerTeam);
                    break;
                case this.enumRoleCode.Resolution_Owner:
                    // Issue Capture Form
                    complainStatus['PrimaryComplaintOwner'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PrimaryComplaintOwner'] = [201];
                    complainStatus['substitute'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['substitute'] = [201];
                    complainStatus['supervisor'] = [1, 2];
                    permissionCode['supervisor'] = [201];
                    complainStatus['CustomerType'] = [1, 2];
                    permissionCode['CustomerType'] = [201];
                    complainStatus['customerNameData'] = [];
                    permissionCode['customerNameData'] = [];
                    complainStatus['CustomerContact'] = [1, 2];
                    permissionCode['CustomerContact'] = [211];
                    complainStatus['customerContactNameHidden'] = [];
                    permissionCode['customerContactNameHidden'] = [];
                    complainStatus['affectedCustomer'] = [1, 2, 3];
                    permissionCode['affectedCustomer'] = [212];
                    complainStatus['affectedCustomerHidden'] = [1, 2, 3];
                    permissionCode['affectedCustomerHidden'] = [212];
                    complainStatus['Customer'] = [1, 2];
                    permissionCode['Customer'] = [212];
                    complainStatus['customerNameHidden'] = [1, 2];
                    permissionCode['customerNameHidden'] = [212];
                    complainStatus['Subject'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Subject'] = [210];

                    complainStatus['DefectTypeandDefectCodeGroupType'] = [3,4,5,11,12];
                    permissionCode['DefectTypeandDefectCodeGroupType'] = [209];
                    complainStatus['WorkCentreandPlant'] = [1,2,3,4,5,7,11,12];
                    permissionCode['WorkCentreandPlant'] = [209];
                    complainStatus['DefectCauseDefectCauseCodeGroup'] = [4,5,11,12];
                    permissionCode['DefectCauseDefectCauseCodeGroup'] = [203];

                    //Issue Description
                    complainStatus['Reason'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Reason'] = [210];
                    complainStatus['WhatIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['WhatIssueDescription'] = [209];
                    complainStatus['whyIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whyIssueDescription'] = [209];
                    complainStatus['howIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['howIssueDescription'] = [209];
                    complainStatus['whenIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whenIssueDescription'] = [209];
                    complainStatus['inWhichProcessStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['inWhichProcessStep'] = [209];
                    complainStatus['whereIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whereIssueDescription'] = [209];
                    complainStatus['issueStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueStep'] = [209];
                    complainStatus['customerExpectedResolutionDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['customerExpectedResolutionDescription'] = [209];
                    complainStatus['uploadedAttachments'] = [];
                    permissionCode['uploadedAttachments'] = [];
                    complainStatus['ContainmentActionsId'] = [];
                    permissionCode['ContainmentActionsId'] = [];
                    complainStatus['IsContainmentActionRequired'] = [];
                    permissionCode['IsContainmentActionRequired'] = [];
                    complainStatus['TargetResolvingDate'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['TargetResolvingDate'] = [201];
                    complainStatus['targetResolutionHiddenDate'] = [];
                    permissionCode['targetResolutionHiddenDate'] = [];
                    complainStatus['deadline'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deadline'] = [210];
                    complainStatus['ContainmentActionDescription'] = [];
                    permissionCode['ContainmentActionDescription'] = [];
                    complainStatus['containmentExecutedDate'] = [];
                    permissionCode['containmentExecutedDate'] = [];
                    complainStatus['EightDRCAIsRequiredByCustomer'] = [1, 2];
                    permissionCode['EightDRCAIsRequiredByCustomer'] = [201];
                    complainStatus['dateChangeReason'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['dateChangeReason'] = [201];
                    complainStatus['changeDescription'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['changeDescription'] = [201];
                    permissionCode['targetResolvingDateChangeCount'] = [];
                    complainStatus['targetResolvingDateChangeCount'] = [];
                    permissionCode['changeReasonId'] = [];
                    permissionCode['changeReasonId'] = [];
                    permissionCode['targetResolvingUnitInitialValue'] = [];
                    permissionCode['targetResolvingUnitInitialValue'] = [];

                    // additionalSubjectForm
                    complainStatus['resolvingUnit'] = [1, 2];
                    permissionCode['resolvingUnit'] = [214];
                    complainStatus['resolvingUnitArray'] = [1, 2];
                    permissionCode['resolvingUnitArray'] = [214];
                    complainStatus['proposedResolutionOwner'] = [1, 2];
                    permissionCode['proposedResolutionOwner'] = [201];
                    complainStatus['Coordinators'] = [1, 2]
                    permissionCode['Coordinators'] = [201]
                    complainStatus['verificationTeam'] = []
                    permissionCode['verificationTeam'] = []
                    complainStatus['ABBProductCategory'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['ABBProductCategory'] = [209];
                    complainStatus['issueProductArray'] = [];
                    permissionCode['issueProductArray'] = [];
                    complainStatus['failureMode'] = [3, 4, 5, 11, 12];
                    permissionCode['failureMode'] = [209];
                    complainStatus['failureModeBind'] = [];
                    permissionCode['failureModeBind'] = [];

                    complainStatus['abbProductSerialNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProductSerialNo'] = [209];
                    complainStatus['purchaseOrderNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['purchaseOrderNo'] = [209];
                    complainStatus['abbProjectNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectNumber'] = [209];
                    complainStatus['abbProjectName'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectName'] = [209];
                    complainStatus['CountryCodeWhereTheIssueHappen'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['CountryCodeWhereTheIssueHappen'] = [209];
                    complainStatus['quantity'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['quantity'] = [209];
                    complainStatus['articleNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['articleNumber'] = [209];
                    complainStatus['itemDesignation'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['itemDesignation'] = [209];
                    complainStatus['abbCustomerReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbCustomerReference'] = [209];
                    complainStatus['supplier'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['supplier'] = [209];
                    complainStatus['deliveryDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deliveryDate'] = [209];
                    complainStatus['commissionDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['commissionDate'] = [209];
                    complainStatus['ManufacturingDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['ManufacturingDate'] = [209];
                    complainStatus['warrantyReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyReference'] = [209];
                    complainStatus['warrantyLifeCycleId'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyLifeCycleId'] = [209];
                    complainStatus['warrantyStartDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyStartDate'] = [209];
                    complainStatus['warrantyEndDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyEndDate'] = [209];
                    complainStatus['documentID'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['documentID'] = [209];
                    complainStatus['qNumberIssue'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['qNumberIssue'] = [209];
                    complainStatus['ProductAlreadyDelivered'] = [4, 5, 11, 12];
                    permissionCode['ProductAlreadyDelivered'] = [203];
                    complainStatus['NoOfUnitsShipped'] = [4, 5, 11, 12];
                    permissionCode['NoOfUnitsShipped'] = [203];
                    complainStatus['PartiesReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PartiesReference'] = [215];
                    complainStatus['SendCopyToParties'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['SendCopyToParties'] = [215];
                    // complainStatus['uploadedPDCAAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    // permissionCode['uploadedPDCAAttachments'] = [209];
                    // complainStatus['uploadedSummaryAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    // permissionCode['uploadedSummaryAttachments'] = [209];
                    complainStatus['hiddenIsHVDCComplaint'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['hiddenIsHVDCComplaint'] = [209];

                    // ISSUE ASSIGNMENT FORM
                    complainStatus['PrimaryResolutionOwner'] = [3, 4, 5, 11, 12];
                    permissionCode['PrimaryResolutionOwner'] = [207];
                    complainStatus['resolutionOwnerTeam'] = [3, 4, 5, 11, 12];
                    permissionCode['resolutionOwnerTeam'] = [206];
                    complainStatus['ccrpNo'] = [3, 4, 5];
                    permissionCode['ccrpNo'] = [202];
                    complainStatus['quickFixComplaint'] = [3, 4, 5];
                    permissionCode['quickFixComplaint'] = [202];
                    complainStatus['quickFixVariant'] = [3, 4, 5];
                    permissionCode['quickFixVariant'] = [202];
                    complainStatus['verifiedCheckBox'] = [3, 4, 5];
                    permissionCode['verifiedCheckBox'] = [202];
                    complainStatus['coordinatorList'] = [3, 4, 5];
                    permissionCode['coordinatorList'] = [202];
                    complainStatus['coordinatorName'] = [3, 4, 5];
                    permissionCode['coordinatorName'] = [202];
                    complainStatus['AddNominatedPerson'] = [3, 4, 5];
                    permissionCode['AddNominatedPerson'] = [202];

                    complainStatus['solutionWillBeValidatedByCoordinator'] = [];
                    permissionCode['solutionWillBeValidatedByCoordinator'] = [];
                    complainStatus['QuickFixRCANotReqDescription'] = [];
                    permissionCode['QuickFixRCANotReqDescription'] = [];

                    // ISSUE RESOULTION FORM
                    complainStatus['EstimatedCostView'] = [4, 5, 11, 12];
                    permissionCode['EstimatedCostView'] = [203];
                    complainStatus['ImpactOnInstalledBase'] = [4, 5, 11, 12];
                    permissionCode['ImpactOnInstalledBase'] = [203];
                    complainStatus['TotalInstalledBase'] = [4, 5, 11, 12];
                    permissionCode['TotalInstalledBase'] = [203];
                    complainStatus['QNumberResolvingUnit'] = [4, 11];
                    permissionCode['QNumberResolvingUnit'] = [203];

                    // complainStatus['RCACorrectiveActions'] = [11, 12, 4];
                    // permissionCode['RCACorrectiveActions'] = [203];
                    complainStatus['SpecificationName'] = [4, 5, 11, 12];
                    permissionCode['SpecificationName'] = [203];
                    complainStatus['howProductFailMeetSpecification'] = [4, 5, 11, 12];
                    permissionCode['howProductFailMeetSpecification'] = [203];
                    complainStatus['ContainmentActionsExecution'] = [];
                    permissionCode['ContainmentActionsExecution'] = [];
                    complainStatus['WhatContainmentActionsWhereTaken'] = [3, 4, 5, 11, 12, 7];// newly formControlName added
                    permissionCode['WhatContainmentActionsWhereTaken'] = [203];
                    complainStatus['whatWasTheRootCauseOfThisIssue'] = [4, 5, 11, 12];
                    permissionCode['whatWasTheRootCauseOfThisIssue'] = [203];
                    complainStatus['CategoryKeyword'] = [4, 5, 11, 12];
                    permissionCode['CategoryKeyword'] = [203];
                    complainStatus['howDidWeResolveThisIssue'] = [4, 5, 11, 12];
                    permissionCode['howDidWeResolveThisIssue'] = [203];

                    complainStatus['JustificationCurrent'] = [];
                    permissionCode['JustificationCurrent'] = [];


                    complainStatus['chipData'] = [4, 5, 11, 12];
                    permissionCode['chipData'] = [203];
                    complainStatus['Upload'] = [4, 5, 11, 12];
                    permissionCode['Upload'] = [203];
                    complainStatus['ActualRUCost'] = [4, 5, 11, 12];
                    permissionCode['ActualRUCost'] = [203];
                    complainStatus['COPQCategory'] = [4, 5, 11, 12];
                    permissionCode['COPQCategory'] = [203];
                    complainStatus['COPQCategoryAllData'] = [4, 5, 11, 12];
                    permissionCode['COPQCategoryAllData'] = [203];
                    complainStatus['pgCategory'] = [4, 5, 11, 12];
                    permissionCode['pgCategory'] = [203];
                    complainStatus['PGRCACategoryAllData'] = [4, 5, 11, 12];
                    permissionCode['PGRCACategoryAllData'] = [203];
                    complainStatus['learn'] = [4, 5, 11, 12];
                    permissionCode['learn'] = [203];
                    complainStatus['desc'] = [4, 5, 11, 12];
                    permissionCode['desc'] = [203];
                    complainStatus['id'] = [];
                    permissionCode['id'] = [];
                    complainStatus['Rating'] = [];
                    permissionCode['Rating'] = [];
                    complainStatus['issueAssigned'] = [];
                    permissionCode['issueAssigned'] = [];
                    complainStatus['selectRole'] = [];
                    permissionCode['selectRole'] = [];
                    complainStatus['selectRole1'] = [];
                    permissionCode['selectRole1'] = [];
                    complainStatus['issueStatus'] = [];
                    permissionCode['issueStatus'] = [];
                    complainStatus['availabilityRating'] = [4, 5, 11, 12];
                    permissionCode['availabilityRating'] = [203];
                    complainStatus['functionalityRating'] = [4, 5, 11, 12];
                    permissionCode['functionalityRating'] = [203];
                    complainStatus['onTimeDeliveryRating'] = [4, 5, 11, 12];
                    permissionCode['onTimeDeliveryRating'] = [203];
                    complainStatus['cyberSecurityRating'] = [4, 5, 11, 12];
                    permissionCode['cyberSecurityRating'] = [203];
                    complainStatus['productComplianceRating'] = [4, 5, 11, 12];
                    permissionCode['productComplianceRating'] = [203];
                    complainStatus['ohsRiskRating'] = [4, 5, 11, 12];
                    permissionCode['ohsRiskRating'] = [203];
                    complainStatus['reputationRating'] = [4, 5, 11, 12];
                    permissionCode['reputationRating'] = [203];
                    complainStatus['marginRating'] = [4, 5, 11, 12];
                    permissionCode['marginRating'] = [203];
                    complainStatus['massMediaRating'] = [4, 5, 11, 12];
                    permissionCode['massMediaRating'] = [203];
                    complainStatus['designChangeRating'] = [4, 5, 11, 12];
                    permissionCode['designChangeRating'] = [203];
                    complainStatus['instancesRating'] = [4, 5, 11, 12];
                    permissionCode['instancesRating'] = [203];
                    complainStatus['resolutionRating'] = [4, 5, 11, 12];
                    permissionCode['resolutionRating'] = [203];
                    complainStatus['affectedCustomerRating'] = [4, 5, 11, 12];
                    permissionCode['affectedCustomerRating'] = [203];
                    complainStatus['controlRating'] = [4, 5, 11, 12];
                    permissionCode['controlRating'] = [203];
                    complainStatus['impactRating'] = [4, 5, 11, 12];
                    permissionCode['impactRating'] = [203];
                    complainStatus['classValue'] = [];
                    permissionCode['classValue'] = [];
                    complainStatus['rpn'] = [];
                    permissionCode['rpn'] = [];
                    // complainStatus['justification'] = [];
                    // permissionCode['justification'] = [];
                    // complainStatus['uploadedPDCAAttachments'] = [];
                    // permissionCode['uploadedPDCAAttachments'] = [];
                    // complainStatus['uploadedSummaryAttachments'] = [];
                    // permissionCode['uploadedSummaryAttachments'] = [];
                    complainStatus['manualChangeClass'] = [4, 5, 11, 12];
                    permissionCode['manualChangeClass'] = [203];
                    complainStatus['ReviewRequired'] = [];
                    permissionCode['ReviewRequired'] = [];
                    complainStatus['eightDReportRequired'] = [];
                    permissionCode['eightDReportRequired'] = [];
                    // complainStatus['uploadedSummaryAttachments'] = [];
                    // permissionCode['uploadedSummaryAttachments'] = [];
                    complainStatus['EightDJustificationCurrent'] = [];
                    permissionCode['EightDJustificationCurrent'] = [];
                    complainStatus['reviewRequiredJustification'] = [];
                    permissionCode['reviewRequiredJustification'] = [];

                    // Issue Closed Form
                    complainStatus['AwaitingCustomerExecution'] = [7];
                    permissionCode['AwaitingCustomerExecution'] = [204];
                    complainStatus['customerComments'] = [7, 13];
                    permissionCode['customerComments'] = [204];
                    complainStatus['ExpectedExecutionDate'] = [7, 13];
                    permissionCode['ExpectedExecutionDate'] = [204];
                    complainStatus['customerContactStatus'] = [7,13];
                    permissionCode['customerContactStatus'] = [0];
                    complainStatus['sendFeedback'] = [7, 13];
                    permissionCode['sendFeedback'] = [204];
                    complainStatus['CustomerFeedbackJustification'] = [7, 13];
                    permissionCode['CustomerFeedbackJustification'] = [204];
                    complainStatus['customerResponse'] = [];
                    permissionCode['customerResponse'] = [0];
                    complainStatus['uploadedAcceptanceAttachments'] = [7, 13];
                    permissionCode['uploadedAcceptanceAttachments'] = [204];

                    //Effectiveness Form
                    complainStatus['id'] = [];
                    permissionCode['id'] = [];
                    complainStatus['IsEffective'] = [8];
                    permissionCode['IsEffective'] = [311];
                    complainStatus['DeadlineDate'] = [8];
                    permissionCode['DeadlineDate'] = [311];
                    complainStatus['effectiveChangedValue'] = [];
                    permissionCode['effectiveChangedValue'] = [];
                    complainStatus['EffectivenessReason'] = [8];
                    permissionCode['EffectivenessReason'] = [311];
                    complainStatus['reopenReason'] = [];
                    permissionCode['reopenReason'] = [];
                    complainStatus['uploadedEffectivenessAttachments'] = [8];
                    permissionCode['uploadedEffectivenessAttachments'] = [311];
                    complainStatus['uploadedAttachments'] = []; //Hidden Field
                    permissionCode['uploadedAttachments'] = [];

                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Resolution_Owner, complainStatus, permissionCode);
                    const permissionDataRO = this.authorizationMiddleWare.checkSetPermissionData();
                    this.setDisableBasedOnPermission(permissionDataRO);
                    break;
                case this.enumRoleCode.Resolution_Owner_Team:
                    // Issue Capture Form
                    complainStatus['PrimaryComplaintOwner'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PrimaryComplaintOwner'] = [201];
                    complainStatus['substitute'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['substitute'] = [201];
                    complainStatus['supervisor'] = [];
                    permissionCode['supervisor'] = [];
                    complainStatus['CustomerType'] = [];
                    permissionCode['CustomerType'] = [];
                    complainStatus['customerNameData'] = [];// newly formControlName added
                    permissionCode['customerNameData'] = [];
                    complainStatus['affectedCustomer'] = [1, 2, 3];
                    permissionCode['affectedCustomer'] = [212];
                    complainStatus['affectedCustomerHidden'] = [];// newly formControlName added
                    permissionCode['affectedCustomerHidden'] = [];
                    complainStatus['Customer'] = [1, 2];
                    permissionCode['Customer'] = [212];
                    complainStatus['customerNameHidden'] = [1, 2];// newly formControlName added
                    permissionCode['customerNameHidden'] = [212];
                    complainStatus['CustomerContact'] = [1, 2];
                    permissionCode['CustomerContact'] = [211];
                    complainStatus['customerContactNameHidden'] = [];// newly formControlName added
                    permissionCode['customerContactNameHidden'] = [];
                    complainStatus['Subject'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Subject'] = [210];
                    //Issue Description
                    complainStatus['Reason'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Reason'] = [210];
                    complainStatus['WhatIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['WhatIssueDescription'] = [209];
                    complainStatus['whyIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whyIssueDescription'] = [209];
                    complainStatus['howIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['howIssueDescription'] = [209];
                    complainStatus['issueStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueStep'] = [209];
                    complainStatus['inWhichProcessStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['inWhichProcessStep'] = [209];
                    complainStatus['whereIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whereIssueDescription'] = [209];
                    complainStatus['whenIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];//
                    permissionCode['whenIssueDescription'] = [209];
                    complainStatus['customerExpectedResolutionDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['customerExpectedResolutionDescription'] = [209];
                    complainStatus['uploadedAttachments'] = []; //newly added
                    permissionCode['uploadedAttachments'] = [];
                    complainStatus['ContainmentActionsId'] = []; //newly added
                    permissionCode['ContainmentActionsId'] = [];
                    complainStatus['IsContainmentActionRequired'] = []; //newly added
                    permissionCode['IsContainmentActionRequired'] = [];
                    complainStatus['deadline'] = [1, 2, 3, 4, 5, 7, 11, 12];//newly added
                    permissionCode['deadline'] = [210]
                    complainStatus['ContainmentActionDescription'] = [1, 2]; //newly added
                    permissionCode['ContainmentActionDescription'] = [201];
                    complainStatus['containmentExecutedDate'] = []; //newly added
                    permissionCode['containmentExecutedDate'] = [];
                    complainStatus['EightDRCAIsRequiredByCustomer'] = [1, 2]; //newly added
                    permissionCode['EightDRCAIsRequiredByCustomer'] = [201];
                    complainStatus['TargetResolvingDate'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['TargetResolvingDate'] = [201];
                    complainStatus['targetResolutionHiddenDate'] = []; //newly added
                    permissionCode['targetResolutionHiddenDate'] = [];
                    complainStatus['dateChangeReason'] = [1, 2, 3, 4, 5, 11, 12];   //newly added
                    permissionCode['dateChangeReason'] = [201];
                    complainStatus['changeDescription'] = [1, 2, 3, 4, 5, 11, 12]; //newly added
                    permissionCode['changeDescription'] = [201];
                    complainStatus['targetResolvingDateChangeCount'] = []; //newly added
                    permissionCode['targetResolvingDateChangeCount'] = [];
                    complainStatus['changeReasonId'] = []; //newly added
                    permissionCode['changeReasonId'] = [];
                    complainStatus['targetResolvingDateInitialValue'] = []; //newly added
                    permissionCode['targetResolvingDateInitialValue'] = [];

                    complainStatus['DefectTypeandDefectCodeGroupType'] = [3,4,5,11,12];
                    permissionCode['DefectTypeandDefectCodeGroupType'] = [209];
                    complainStatus['WorkCentreandPlant'] = [1,2,3,4,5,7,11,12];
                    permissionCode['WorkCentreandPlant'] = [209];
                    complainStatus['DefectCauseDefectCauseCodeGroup'] = [4,5,11,12];
                    permissionCode['DefectCauseDefectCauseCodeGroup'] = [203];

                    /* complainStatus['issueLocationArr'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueLocationArr'] = [210]; */

                    //Additional Subject Form Control
                    complainStatus['resolvingUnit'] = [4, 5];
                    permissionCode['resolvingUnit'] = [214];
                    complainStatus['resolvingUnitArray'] = [4, 5];
                    permissionCode['resolvingUnitArray'] = [214];
                    complainStatus['proposedResolutionOwner'] = [];
                    permissionCode['proposedResolutionOwner'] = [];
                    complainStatus['Coordinators'] = [1, 2]
                    permissionCode['Coordinators'] = [201]
                    complainStatus['verificationTeam'] = []
                    permissionCode['verificationTeam'] = []
                    complainStatus['ABBProductCategory'] = [3, 4, 5, 11, 12];
                    permissionCode['ABBProductCategory'] = [209];
                    complainStatus['issueProductArray'] = []; //newly added
                    permissionCode['issueProductArray'] = [];
                    complainStatus['failureMode'] = [3, 4, 5, 11, 12];
                    permissionCode['failureMode'] = [209];
                    complainStatus['failureModeBind'] = []; //newly added
                    permissionCode['failureModeBind'] = [];

                    complainStatus['abbProductSerialNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProductSerialNo'] = [209];
                    complainStatus['purchaseOrderNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['purchaseOrderNo'] = [209];
                    complainStatus['abbProjectNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectNumber'] = [209];
                    complainStatus['abbProjectName'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectName'] = [209];
                    complainStatus['CountryCodeWhereTheIssueHappen'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['CountryCodeWhereTheIssueHappen'] = [209];
                    complainStatus['quantity'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['quantity'] = [209];
                    complainStatus['articleNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['articleNumber'] = [209];
                    complainStatus['itemDesignation'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['itemDesignation'] = [209];
                    complainStatus['abbCustomerReference'] = [1, 2, 3, 4, 5, 7, 11, 12]; //abb added
                    permissionCode['abbCustomerReference'] = [209];
                    complainStatus['supplier'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['supplier'] = [209];
                    complainStatus['deliveryDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deliveryDate'] = [209];
                    complainStatus['commissionDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['commissionDate'] = [209];
                    complainStatus['ManufacturingDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['ManufacturingDate'] = [209];
                    complainStatus['warrantyReference'] = [1, 2, 3, 4, 5, 7, 11, 12]; //warrantyReference added
                    permissionCode['warrantyReference'] = [209];
                    complainStatus['warrantyLifeCycleId'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyLifeCycleId'] = [209];
                    complainStatus['warrantyStartDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyStartDate'] = [209];
                    complainStatus['warrantyEndDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyEndDate'] = [209];
                    complainStatus['documentID'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['documentID'] = [209];
                    complainStatus['qNumberIssue'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['qNumberIssue'] = [209];
                    complainStatus['ProductAlreadyDelivered'] = [4, 5, 11, 12];
                    permissionCode['ProductAlreadyDelivered'] = [203];
                    complainStatus['NoOfUnitsShipped'] = [4, 5, 11, 12];
                    permissionCode['NoOfUnitsShipped'] = [203];
                    complainStatus['PartiesReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PartiesReference'] = [215];
                    complainStatus['SendCopyToParties'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['SendCopyToParties'] = [215];
                    /* complainStatus['uploadedPDCAAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['uploadedPDCAAttachments'] = [209];
                    complainStatus['uploadedSummaryAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['uploadedSummaryAttachments'] = [209]; */
                    complainStatus['hiddenIsHVDCComplaint'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['hiddenIsHVDCComplaint'] = [209];

                    // ISSUE ASSIGNMENT FORM
                    complainStatus['PrimaryResolutionOwner'] = [3, 4, 5, 11, 12];
                    permissionCode['PrimaryResolutionOwner'] = [207];
                    complainStatus['resolutionOwnerTeam'] = [3, 4, 5, 11, 12];
                    permissionCode['resolutionOwnerTeam'] = [206];
                    complainStatus['ccrpNo'] = [3, 4, 5];
                    permissionCode['ccrpNo'] = [202];
                    complainStatus['quickFixComplaint'] = [3, 4, 5];
                    permissionCode['quickFixComplaint'] = [202];
                    complainStatus['quickFixVariant'] = [3, 4, 5];
                    permissionCode['quickFixVariant'] = [202];
                    complainStatus['verifiedCheckBox'] = [3, 4, 5];
                    permissionCode['verifiedCheckBox'] = [202];
                    complainStatus['coordinatorList'] = [3, 4, 5];
                    permissionCode['coordinatorList'] = [202];
                    complainStatus['coordinatorName'] = [3, 4, 5];
                    permissionCode['coordinatorName'] = [202];
                    complainStatus['AddNominatedPerson'] = [3, 4, 5];
                    permissionCode['AddNominatedPerson'] = [202];
                    complainStatus['solutionWillBeValidatedByCoordinator'] = [];// newly formControlName added
                    permissionCode['solutionWillBeValidatedByCoordinator'] = [];
                    complainStatus['QuickFixRCANotReqDescription'] = [];// newly formControlName added
                    permissionCode['QuickFixRCANotReqDescription'] = [];

                    // ISSUE RESOULTION FORM
                    complainStatus['EstimatedCostView'] = [4, 5, 11, 12];
                    permissionCode['EstimatedCostView'] = [203];
                    complainStatus['ImpactOnInstalledBase'] = [4, 5, 11, 12];
                    permissionCode['ImpactOnInstalledBase'] = [203];
                    complainStatus['TotalInstalledBase'] = [4, 5, 11, 12];
                    permissionCode['TotalInstalledBase'] = [203];
                    complainStatus['QNumberResolvingUnit'] = [4, 11];
                    permissionCode['QNumberResolvingUnit'] = [203];
                    // complainStatus['RCACorrectiveActions'] = [1, 2];
                    // permissionCode['RCACorrectiveActions'] = [203];
                    complainStatus['SpecificationName'] = [4, 5, 11, 12];
                    permissionCode['SpecificationName'] = [203];
                    complainStatus['howProductFailMeetSpecification'] = [4, 5, 11, 12];
                    permissionCode['howProductFailMeetSpecification'] = [203];
                    complainStatus['ContainmentActionsExecution'] = [4, 5, 11, 12];
                    permissionCode['ContainmentActionsExecution'] = [203];
                    complainStatus['WhatContainmentActionsWhereTaken'] = [4, 5, 11, 12];
                    permissionCode['WhatContainmentActionsWhereTaken'] = [203];
                    complainStatus['whatWasTheRootCauseOfThisIssue'] = [4, 5, 11, 12];
                    permissionCode['whatWasTheRootCauseOfThisIssue'] = [203];
                    complainStatus['CategoryKeyword'] = [4, 5, 11, 12];
                    permissionCode['CategoryKeyword'] = [203];
                    complainStatus['howDidWeResolveThisIssue'] = [4, 5, 11, 12];
                    permissionCode['howDidWeResolveThisIssue'] = [203];
                    complainStatus['chipData'] = [4, 5, 11, 12];
                    permissionCode['chipData'] = [203];
                    complainStatus['Upload'] = [4, 5, 11, 12];
                    permissionCode['Upload'] = [203];
                    complainStatus['ActualRUCost'] = [4, 5, 11, 12];
                    permissionCode['ActualRUCost'] = [203];
                    complainStatus['COPQCategory'] = [4, 5, 11, 12];
                    permissionCode['COPQCategory'] = [203];
                    complainStatus['COPQCategoryAllData'] = [4, 5, 11, 12];
                    permissionCode['COPQCategoryAllData'] = [203];
                    complainStatus['pgCategory'] = [4, 5, 11, 12];
                    permissionCode['pgCategory'] = [203];
                    complainStatus['PGRCACategoryAllData'] = [4, 5, 11, 12];
                    permissionCode['PGRCACategoryAllData'] = [203];
                    complainStatus['learn'] = [4, 5, 11, 12];
                    permissionCode['learn'] = [203];
                    complainStatus['desc'] = [4, 5, 11, 12];
                    permissionCode['desc'] = [203];
                    complainStatus['id'] = [];
                    permissionCode['id'] = [];
                    complainStatus['Rating'] = [];
                    permissionCode['Rating'] = [];
                    complainStatus['issueAssigned'] = [];
                    permissionCode['issueAssigned'] = [];
                    complainStatus['selectRole'] = [];
                    permissionCode['selectRole'] = [];
                    complainStatus['selectRole1'] = [];
                    permissionCode['selectRole1'] = [];
                    complainStatus['issueStatus'] = [];
                    permissionCode['issueStatus'] = [];
                    complainStatus['availabilityRating'] = [];
                    permissionCode['availabilityRating'] = [];
                    complainStatus['functionalityRating'] = [];
                    permissionCode['functionalityRating'] = [];
                    complainStatus['onTimeDeliveryRating'] = [];
                    permissionCode['onTimeDeliveryRating'] = [];
                    complainStatus['cyberSecurityRating'] = [];
                    permissionCode['cyberSecurityRating'] = [];
                    complainStatus['productComplianceRating'] = [];
                    permissionCode['productComplianceRating'] = [];
                    complainStatus['ohsRiskRating'] = [];
                    permissionCode['ohsRiskRating'] = [];
                    complainStatus['marginRating'] = [];
                    permissionCode['marginRating'] = [];
                    complainStatus['reputationRating'] = [];
                    permissionCode['reputationRating'] = [];
                    complainStatus['massMediaRating'] = [];
                    permissionCode['massMediaRating'] = [];
                    complainStatus['designChangeRating'] = [];
                    permissionCode['designChangeRating'] = [];
                    complainStatus['instancesRating'] = [];
                    permissionCode['instancesRating'] = [];
                    complainStatus['resolutionRating'] = [];
                    permissionCode['resolutionRating'] = [];
                    complainStatus['affectedCustomerRating'] = [];
                    permissionCode['affectedCustomerRating'] = [];
                    complainStatus['controlRating'] = [];
                    permissionCode['controlRating'] = [];
                    complainStatus['impactRating'] = [];
                    permissionCode['impactRating'] = [];
                    complainStatus['classValue'] = [];
                    permissionCode['classValue'] = [];
                    complainStatus['rpn'] = [];
                    permissionCode['rpn'] = [];
                    complainStatus['JustificationCurrent'] = []; //added current
                    permissionCode['JustificationCurrent'] = [];
                    complainStatus['UploadRCAAttachments'] = [];// newly formControlName added
                    permissionCode['UploadRCAAttachments'] = [];
                    complainStatus['resolutionAttachments'] = [];// newly formControlName added
                    permissionCode['resolutionAttachments'] = [];
                    /* complainStatus['uploadedPDCAAttachments'] = [];
                    permissionCode['uploadedPDCAAttachments'] = [];
                    complainStatus['uploadedSummaryAttachments'] = [];
                    permissionCode['uploadedSummaryAttachments'] = []; */
                    complainStatus['manualChangeClass'] = [4, 5, 11, 12];
                    permissionCode['manualChangeClass'] = [203];
                    complainStatus['ReviewRequired'] = [];
                    permissionCode['ReviewRequired'] = [];
                    complainStatus['eightDReportRequired'] = [];
                    permissionCode['eightDReportRequired'] = [];
                    /* complainStatus['uploadedSummaryAttachments'] = [];
                    permissionCode['uploadedSummaryAttachments'] = []; */
                    complainStatus['EightDJustificationCurrent'] = [];
                    permissionCode['EightDJustificationCurrent'] = [];
                    complainStatus['reviewRequiredJustification'] = [];
                    permissionCode['reviewRequiredJustification'] = [];

                    // Issue Closed Form
                    complainStatus['AwaitingCustomerExecution'] = [7];
                    permissionCode['AwaitingCustomerExecution'] = [204];
                    complainStatus['customerComments'] = [7, 13];
                    permissionCode['customerComments'] = [204];
                    complainStatus['ExpectedExecutionDate'] = [7, 13];
                    permissionCode['ExpectedExecutionDate'] = [204];
                    complainStatus['customerContactStatus'] = [7,13];
                    permissionCode['customerContactStatus'] = [0];
                    complainStatus['sendFeedback'] = [7, 13];
                    permissionCode['sendFeedback'] = [204];
                    complainStatus['CustomerFeedbackJustification'] = [7, 13];
                    permissionCode['CustomerFeedbackJustification'] = [204];
                    complainStatus['customerResponse'] = [];
                    permissionCode['customerResponse'] = [0];
                    complainStatus['uploadedAcceptanceAttachments'] = [7, 13];
                    permissionCode['uploadedAcceptanceAttachments'] = [204];

                    //Effectiveness Form
                    complainStatus['id'] = [];
                    permissionCode['id'] = [];
                    complainStatus['IsEffective'] = [8];
                    permissionCode['IsEffective'] = [311];
                    complainStatus['DeadlineDate'] = [8];
                    permissionCode['DeadlineDate'] = [311];
                    complainStatus['effectiveChangedValue'] = [];
                    permissionCode['effectiveChangedValue'] = [];
                    complainStatus['EffectivenessReason'] = [8];
                    permissionCode['EffectivenessReason'] = [311];
                    complainStatus['reopenReason'] = [];
                    permissionCode['reopenReason'] = [];
                    complainStatus['uploadedEffectivenessAttachments'] = [8];
                    permissionCode['uploadedEffectivenessAttachments'] = [311];

                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Resolution_Owner_Team, complainStatus, permissionCode);
                    const permissionDataRO_Team = this.authorizationMiddleWare.checkSetPermissionData();
                    this.setDisableBasedOnPermission(permissionDataRO_Team);
                    break;
                //                 case this.enumRoleCode.LBU_Manager:
                //                     this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.LBU_Manager);
                //                   break;
                case this.enumRoleCode.Ru_Process_Owner:
                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Ru_Process_Owner);
                    break;
                //                 case this.enumRoleCode.Div_Opex_Manager:
                //                     this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Div_Opex_Manager);
                //                   break;
                //                 case this.enumRoleCode.BU_Opex_Manager:
                //                     this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.BU_Opex_Manager);
                //                   break;
                //                 case this.enumRoleCode.PG_Opex_Manager:
                //                     this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.PG_Opex_Manager);
                //                   break;
                case this.enumRoleCode.Resource_Editor:
                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Resource_Editor);
                    break;
                //                 case this.enumRoleCode.Country_Manager:
                //                     this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Country_Manager);
                //                   break;
                case this.enumRoleCode.Process_Owner:
                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Process_Owner);
                    break;
                case this.enumRoleCode.Div_Process_Owner:
                    complainStatus['PrimaryComplaintOwner'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PrimaryComplaintOwner'] = [201];
                    complainStatus['substitute'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['substitute'] = [201];
                    complainStatus['supervisor'] = [];
                    permissionCode['supervisor'] = [];
                    complainStatus['CustomerType'] = [];
                    permissionCode['CustomerType'] = [];
                    complainStatus['customerNameData'] = [];
                    permissionCode['customerNameData'] = [];
                    complainStatus['CustomerContact'] = [1, 2];
                    permissionCode['CustomerContact'] = [211];
                    complainStatus['customerContactNameHidden'] = [];
                    permissionCode['customerContactNameHidden'] = [];
                    complainStatus['affectedCustomer'] = [1, 2, 3];
                    permissionCode['affectedCustomer'] = [212];
                    complainStatus['affectedCustomerHidden'] = [1, 2, 3];
                    permissionCode['affectedCustomerHidden'] = [212];
                    complainStatus['Customer'] = [1, 2];
                    permissionCode['Customer'] = [212];
                    complainStatus['customerNameHidden'] = [1, 2];
                    permissionCode['customerNameHidden'] = [212];
                    complainStatus['Subject'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Subject'] = [210];

                    complainStatus['Reason'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Reason'] = [210];
                    complainStatus['WhatIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['WhatIssueDescription'] = [209];
                    complainStatus['whyIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whyIssueDescription'] = [209];
                    complainStatus['howIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['howIssueDescription'] = [209];
                    complainStatus['whenIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whenIssueDescription'] = [209];
                    complainStatus['inWhichProcessStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['inWhichProcessStep'] = [209];
                    complainStatus['whereIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whereIssueDescription'] = [209];
                    complainStatus['issueStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueStep'] = [209];
                    complainStatus['customerExpectedResolutionDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['customerExpectedResolutionDescription'] = [209];
                    complainStatus['uploadedAttachments'] = [];
                    permissionCode['uploadedAttachments'] = [];
                    complainStatus['ContainmentActionsId'] = [];
                    permissionCode['ContainmentActionsId'] = [];
                    complainStatus['IsContainmentActionRequired'] = [];
                    permissionCode['IsContainmentActionRequired'] = [];
                    complainStatus['TargetResolvingDate'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['TargetResolvingDate'] = [201];
                    complainStatus['targetResolutionHiddenDate'] = [];
                    permissionCode['targetResolutionHiddenDate'] = [];
                    complainStatus['deadline'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deadline'] = [210];
                    complainStatus['ContainmentActionDescription'] = [];
                    permissionCode['ContainmentActionDescription'] = [];
                    complainStatus['containmentExecutedDate'] = [];
                    permissionCode['containmentExecutedDate'] = [];
                    complainStatus['EightDRCAIsRequiredByCustomer'] = [1, 2];
                    permissionCode['EightDRCAIsRequiredByCustomer'] = [201];
                    complainStatus['dateChangeReason'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['dateChangeReason'] = [201];
                    complainStatus['changeDescription'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['changeDescription'] = [201];
                    permissionCode['targetResolvingDateChangeCount'] = [];
                    complainStatus['targetResolvingDateChangeCount'] = [];
                    permissionCode['changeReasonId'] = [];
                    permissionCode['changeReasonId'] = [];
                    permissionCode['targetResolvingUnitInitialValue'] = [];
                    permissionCode['targetResolvingUnitInitialValue'] = [];

                    complainStatus['DefectTypeandDefectCodeGroupType'] = [3,4,5,11,12];
                    permissionCode['DefectTypeandDefectCodeGroupType'] = [209];
                    complainStatus['WorkCentreandPlant'] = [1,2,3,4,5,7,11,12];
                    permissionCode['WorkCentreandPlant'] = [209];
                    complainStatus['DefectCauseDefectCauseCodeGroup'] = [4,5,11,12];
                    permissionCode['DefectCauseDefectCauseCodeGroup'] = [203];

                    // additionalSubjectForm
                    complainStatus['resolvingUnit'] = [4, 5];
                    permissionCode['resolvingUnit'] = [214];
                    complainStatus['resolvingUnitArray'] = [1, 2];
                    permissionCode['resolvingUnitArray'] = [214];
                    complainStatus['proposedResolutionOwner'] = [];
                    permissionCode['proposedResolutionOwner'] = [];
                    complainStatus['Coordinators'] = [1, 2]
                    permissionCode['Coordinators'] = [201]
                    complainStatus['verificationTeam'] = []
                    permissionCode['verificationTeam'] = []
                    complainStatus['ABBProductCategory'] = [3, 4, 5, 11, 12];
                    permissionCode['ABBProductCategory'] = [209];
                    complainStatus['issueProductArray'] = [];
                    permissionCode['issueProductArray'] = [];
                    complainStatus['failureMode'] = [3, 4, 5, 11, 12];
                    permissionCode['failureMode'] = [209];
                    complainStatus['failureModeBind'] = [];
                    permissionCode['failureModeBind'] = [];
                    complainStatus['abbProductSerialNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProductSerialNo'] = [209];
                    complainStatus['purchaseOrderNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['purchaseOrderNo'] = [209];
                    complainStatus['abbProjectNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectNumber'] = [209];
                    complainStatus['abbProjectName'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectName'] = [209];
                    complainStatus['CountryCodeWhereTheIssueHappen'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['CountryCodeWhereTheIssueHappen'] = [209];
                    complainStatus['quantity'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['quantity'] = [209];
                    complainStatus['articleNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['articleNumber'] = [209];
                    complainStatus['itemDesignation'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['itemDesignation'] = [209];
                    complainStatus['abbCustomerReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbCustomerReference'] = [209];
                    complainStatus['supplier'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['supplier'] = [209];
                    complainStatus['deliveryDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deliveryDate'] = [209];
                    complainStatus['commissionDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['commissionDate'] = [209];
                    complainStatus['ManufacturingDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['ManufacturingDate'] = [209];
                    complainStatus['warrantyReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyReference'] = [209];
                    complainStatus['warrantyLifeCycleId'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyLifeCycleId'] = [209];
                    complainStatus['warrantyStartDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyStartDate'] = [209];
                    complainStatus['warrantyEndDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyEndDate'] = [209];
                    complainStatus['documentID'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['documentID'] = [209];
                    complainStatus['qNumberIssue'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['qNumberIssue'] = [209];
                    complainStatus['PartiesReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PartiesReference'] = [215];
                    complainStatus['SendCopyToParties'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['SendCopyToParties'] = [215];
                    complainStatus['ProductAlreadyDelivered'] = [4, 5, 11, 12];
                    permissionCode['ProductAlreadyDelivered'] = [203];
                    complainStatus['NoOfUnitsShipped'] = [4, 5, 11, 12];
                    permissionCode['NoOfUnitsShipped'] = [203];
                    // complainStatus['PartiesReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    // permissionCode['PartiesReference'] = [209];
                    complainStatus['uploadedPDCAAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['uploadedPDCAAttachments'] = [209];
                    complainStatus['uploadedSummaryAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['uploadedSummaryAttachments'] = [209];
                    complainStatus['hiddenIsHVDCComplaint'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['hiddenIsHVDCComplaint'] = [209];

                    // ISSUE ASSIGNMENT FORM
                    complainStatus['PrimaryResolutionOwner'] = [3, 4, 5, 11, 12];
                    permissionCode['PrimaryResolutionOwner'] = [207];
                    complainStatus['resolutionOwnerTeam'] = [3, 4, 5, 11, 12];
                    permissionCode['resolutionOwnerTeam'] = [206];
                    complainStatus['ccrpNo'] = [3, 4, 5];
                    permissionCode['ccrpNo'] = [202];
                    complainStatus['quickFixComplaint'] = [3, 4, 5];
                    permissionCode['quickFixComplaint'] = [202];
                    complainStatus['quickFixVariant'] = [3, 4, 5];
                    permissionCode['quickFixVariant'] = [202];
                    complainStatus['verifiedCheckBox'] = [3, 4, 5];
                    permissionCode['verifiedCheckBox'] = [202];
                    complainStatus['coordinatorList'] = [3, 4, 5];
                    permissionCode['coordinatorList'] = [202];
                    complainStatus['coordinatorName'] = [3, 4, 5];
                    permissionCode['coordinatorName'] = [202];
                    complainStatus['AddNominatedPerson'] = [3, 4, 5];
                    permissionCode['AddNominatedPerson'] = [202];
                    complainStatus['solutionWillBeValidatedByCoordinator'] = [];
                    permissionCode['solutionWillBeValidatedByCoordinator'] = [];
                    complainStatus['QuickFixRCANotReqDescription'] = [];
                    permissionCode['QuickFixRCANotReqDescription'] = [];


                    // ISSUE RESOULTION FORM
                    complainStatus['EstimatedCostView'] = [4, 5, 11, 12];
                    permissionCode['EstimatedCostView'] = [203];
                    complainStatus['ImpactOnInstalledBase'] = [4, 5, 11, 12];
                    permissionCode['ImpactOnInstalledBase'] = [203];
                    complainStatus['TotalInstalledBase'] = [4, 5, 11, 12];
                    permissionCode['TotalInstalledBase'] = [203];
                    complainStatus['QNumberResolvingUnit'] = [4, 11];
                    permissionCode['QNumberResolvingUnit'] = [203];

                    // complainStatus['RCACorrectiveActions'] = [1, 2];
                    // permissionCode['RCACorrectiveActions'] = [203];
                    complainStatus['SpecificationName'] = [4, 5, 11, 12];
                    permissionCode['SpecificationName'] = [203];
                    complainStatus['howProductFailMeetSpecification'] = [4, 5, 11, 12];
                    permissionCode['howProductFailMeetSpecification'] = [203];
                    complainStatus['ContainmentActionsExecution'] = [4, 5, 11, 12];
                    permissionCode['ContainmentActionsExecution'] = [203];
                    complainStatus['WhatContainmentActionsWhereTaken'] = [4, 5, 11, 12];
                    permissionCode['WhatContainmentActionsWhereTaken'] = [203];
                    complainStatus['whatWasTheRootCauseOfThisIssue'] = [4, 5, 11, 12];
                    permissionCode['whatWasTheRootCauseOfThisIssue'] = [203];
                    complainStatus['CategoryKeyword'] = [4, 5, 11, 12];
                    permissionCode['CategoryKeyword'] = [203];
                    complainStatus['howDidWeResolveThisIssue'] = [4, 5, 11, 12];
                    permissionCode['howDidWeResolveThisIssue'] = [203];
                    complainStatus['chipData'] = [4, 5, 11, 12];
                    permissionCode['chipData'] = [203];
                    complainStatus['Upload'] = [4, 5, 11, 12];
                    permissionCode['Upload'] = [203];
                    complainStatus['ActualRUCost'] = [4, 5, 11, 12];
                    permissionCode['ActualRUCost'] = [203];
                    complainStatus['COPQCategory'] = [4, 5, 11, 12];
                    permissionCode['COPQCategory'] = [203];
                    complainStatus['COPQCategoryAllData'] = [4, 5, 11, 12];
                    permissionCode['COPQCategoryAllData'] = [203];
                    complainStatus['pgCategory'] = [4, 5, 11, 12];
                    permissionCode['pgCategory'] = [203];
                    complainStatus['PGRCACategoryAllData'] = [4, 5, 11, 12];
                    permissionCode['PGRCACategoryAllData'] = [203];
                    complainStatus['learn'] = [4, 5, 11, 12];
                    permissionCode['learn'] = [203];
                    complainStatus['desc'] = [4, 5, 11, 12];
                    permissionCode['desc'] = [203];
                    complainStatus['id'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['id'] = [203];
                    complainStatus['Rating'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['Rating'] = [203];
                    complainStatus['issueAssigned'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['issueAssigned'] = [203];
                    complainStatus['selectRole'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['selectRole'] = [203];
                    complainStatus['selectRole1'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['selectRole1'] = [203];
                    complainStatus['issueStatus'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['issueStatus'] = [203];

                    complainStatus['availabilityRating'] = [4, 5];
                    permissionCode['availabilityRating'] = [217];
                    complainStatus['functionalityRating'] = [4, 5];
                    permissionCode['functionalityRating'] = [203];
                    complainStatus['onTimeDeliveryRating'] = [4, 5];
                    permissionCode['onTimeDeliveryRating'] = [203];
                    complainStatus['cyberSecurityRating'] = [4, 5];
                    permissionCode['cyberSecurityRating'] = [];
                    complainStatus['productComplianceRating'] = [4, 5];
                    permissionCode['productComplianceRating'] = [203];
                    complainStatus['ohsRiskRating'] = [4, 5];
                    permissionCode['ohsRiskRating'] = [203];
                    complainStatus['reputationRating'] = [4, 5];
                    permissionCode['reputationRating'] = [203];
                    complainStatus['marginRating'] = [4, 5];
                    permissionCode['marginRating'] = [203];
                    complainStatus['massMediaRating'] = [4, 5];
                    permissionCode['massMediaRating'] = [203];
                    complainStatus['designChangeRating'] = [4, 5];
                    permissionCode['designChangeRating'] = [203];
                    complainStatus['instancesRating'] = [4, 5];
                    permissionCode['instancesRating'] = [203];
                    complainStatus['resolutionRating'] = [4, 5];
                    permissionCode['resolutionRating'] = [203];
                    complainStatus['affectedCustomerRating'] = [4, 5];
                    permissionCode['affectedCustomerRating'] = [203];
                    complainStatus['controlRating'] = [4, 5];
                    permissionCode['controlRating'] = [203];
                    complainStatus['impactRating'] = [4, 5];
                    permissionCode['impactRating'] = [203];
                    complainStatus['classValue'] = [4, 5];
                    permissionCode['classValue'] = [203];
                    complainStatus['rpn'] = [4, 5];
                    permissionCode['rpn'] = [203];
                    complainStatus['JustificationCurrent'] = [];
                    permissionCode['JustificationCurrent'] = [];
                    complainStatus['UploadRCAAttachments'] = [];
                    permissionCode['UploadRCAAttachments'] = [];
                    complainStatus['resolutionAttachments'] = [];
                    permissionCode['resolutionAttachments'] = [];
                    // complainStatus['justification'] = [3, 4, 5, 11, 12, 7];
                    // permissionCode['justification'] = [203];
                    // complainStatus['uploadedPDCAAttachments'] = [3, 4, 5, 11, 12, 7];
                    // permissionCode['uploadedPDCAAttachments'] = [203];
                    // complainStatus['uploadedSummaryAttachments'] = [3, 4, 5, 11, 12, 7];
                    // permissionCode['uploadedSummaryAttachments'] = [203];
                    complainStatus['manualChangeClass'] = [3, 4, 5];
                    permissionCode['manualChangeClass'] = [203];
                    complainStatus['ReviewRequired'] = [4, 5];
                    permissionCode['ReviewRequired'] = [203];
                    complainStatus['eightDReportRequired'] = [3, 4, 5];
                    permissionCode['eightDReportRequired'] = [203];
                    // complainStatus['uploadedSummaryAttachments'] = [3, 4, 5, 11, 12, 7];
                    // permissionCode['uploadedSummaryAttachments'] = [203];
                    complainStatus['EightDJustificationCurrent'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['EightDJustificationCurrent'] = [203];
                    complainStatus['reviewRequiredJustification'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['reviewRequiredJustification'] = [203];

                    // Issue Closed Form
                    complainStatus['AwaitingCustomerExecution'] = [7];
                    permissionCode['AwaitingCustomerExecution'] = [204];
                    complainStatus['customerComments'] = [7, 13];
                    permissionCode['customerComments'] = [204];
                    complainStatus['ExpectedExecutionDate'] = [7, 13];
                    permissionCode['ExpectedExecutionDate'] = [204];
                    complainStatus['customerContactStatus'] = [7, 13];
                    permissionCode['customerContactStatus'] = [204];
                    complainStatus['sendFeedback'] = [7, 13];
                    permissionCode['sendFeedback'] = [204];
                    complainStatus['CustomerFeedbackJustification'] = [7, 13];
                    permissionCode['CustomerFeedbackJustification'] = [204];
                    complainStatus['customerResponse'] = [7, 13];
                    permissionCode['customerResponse'] = [204];
                    complainStatus['uploadedAcceptanceAttachments'] = [7, 13];
                    permissionCode['uploadedAcceptanceAttachments'] = [204];

                    //Effectiveness Form
                    complainStatus['id'] = [8];
                    permissionCode['id'] = [311];
                    complainStatus['IsEffective'] = [8];
                    permissionCode['IsEffective'] = [311];
                    complainStatus['DeadlineDate'] = [8];
                    permissionCode['DeadlineDate'] = [311];
                    complainStatus['effectiveChangedValue'] = [8];
                    permissionCode['effectiveChangedValue'] = [311];
                    complainStatus['EffectivenessReason'] = [8];
                    permissionCode['EffectivenessReason'] = [311];
                    complainStatus['reopenReason'] = [8];
                    permissionCode['reopenReason'] = [311];
                    complainStatus['uploadedEffectivenessAttachments'] = [8];
                    permissionCode['uploadedEffectivenessAttachments'] = [311];
                    complainStatus['uploadedAttachments'] = []; //Hidden Field
                    permissionCode['uploadedAttachments'] = [];

                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Div_Process_Owner, complainStatus, permissionCode);
                    const businessProcessOwnerData = this.authorizationMiddleWare.checkSetPermissionData();
                    this.setDisableBasedOnPermission(businessProcessOwnerData);
                    break;
                case this.enumRoleCode.Bu_Process_Owner:

                    // Applied status code and permission from DEV (status code - Resolved)
                    //   https://dev.ccrp.hitachienergy.com/en/Complaint/675547

                    complainStatus['PrimaryComplaintOwner'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PrimaryComplaintOwner'] = [201];
                    complainStatus['substitute'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['substitute'] = [201];
                    complainStatus['supervisor'] = [1, 2];
                    permissionCode['supervisor'] = [201];
                    complainStatus['CustomerType'] = [1, 2];
                    permissionCode['CustomerType'] = [201];
                    complainStatus['customerNameData'] = [];
                    permissionCode['customerNameData'] = [];
                    complainStatus['CustomerContact'] = [1, 2, 3, 4, 5, 7, 11, 12, 13];
                    permissionCode['CustomerContact'] = [211];
                    complainStatus['customerContactNameHidden'] = [1, 2, 3, 4, 5, 7, 11, 12, 13];
                    permissionCode['customerContactNameHidden'] = [211];
                    complainStatus['affectedCustomer'] = [1, 2, 3];
                    permissionCode['affectedCustomer'] = [212];
                    complainStatus['affectedCustomerHidden'] = [1, 2, 3];
                    permissionCode['affectedCustomerHidden'] = [212];
                    complainStatus['Customer'] = [1, 2];
                    permissionCode['Customer'] = [212];
                    complainStatus['customerNameHidden'] = [1, 2];
                    permissionCode['customerNameHidden'] = [212];
                    complainStatus['Subject'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Subject'] = [210];

                    complainStatus['Reason'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Reason'] = [210];
                    complainStatus['WhatIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['WhatIssueDescription'] = [209];
                    complainStatus['whyIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whyIssueDescription'] = [209];
                    complainStatus['howIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['howIssueDescription'] = [209];
                    complainStatus['whenIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whenIssueDescription'] = [209];
                    complainStatus['inWhichProcessStep'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['inWhichProcessStep'] = [209];
                    complainStatus['whereIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whereIssueDescription'] = [209];
                    complainStatus['issueStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueStep'] = [209];
                    complainStatus['customerExpectedResolutionDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['customerExpectedResolutionDescription'] = [209];
                    complainStatus['uploadedAttachments'] = [];
                    permissionCode['uploadedAttachments'] = [];
                    complainStatus['ContainmentActionsId'] = [];
                    permissionCode['ContainmentActionsId'] = [];
                    complainStatus['IsContainmentActionRequired'] = [];
                    permissionCode['IsContainmentActionRequired'] = [];
                    complainStatus['TargetResolvingDate'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['TargetResolvingDate'] = [201]
                    complainStatus['targetResolutionHiddenDate'] = [];
                    permissionCode['targetResolutionHiddenDate'] = [];
                    complainStatus['deadline'] = [1, 2];
                    permissionCode['deadline'] = [210]
                    complainStatus['ContainmentActionDescription'] = [1, 2];
                    permissionCode['ContainmentActionDescription'] = [201];
                    complainStatus['containmentExecutedDate'] = [];
                    permissionCode['containmentExecutedDate'] = [];
                    complainStatus['EightDRCAIsRequiredByCustomer'] = [1, 2];
                    permissionCode['EightDRCAIsRequiredByCustomer'] = [201];
                    complainStatus['dateChangeReason'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['dateChangeReason'] = [201];
                    complainStatus['changeDescription'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['changeDescription'] = [201];
                    permissionCode['targetResolvingDateChangeCount'] = [];
                    complainStatus['targetResolvingDateChangeCount'] = [];
                    permissionCode['changeReasonId'] = [];
                    permissionCode['changeReasonId'] = [];
                    permissionCode['targetResolvingUnitInitialValue'] = [];
                    permissionCode['targetResolvingUnitInitialValue'] = [];

                    // additionalSubjectForm
                    complainStatus['resolvingUnit'] = [4, 5];
                    permissionCode['resolvingUnit'] = [214];
                    // complainStatus['resolvingUnitArray'] = [3, 4, 5];
                    // permissionCode['resolvingUnitArray'] = [214];
                    complainStatus['proposedResolutionOwner'] = [1, 2];
                    permissionCode['proposedResolutionOwner'] = [201];
                    complainStatus['Coordinators'] = [1, 2];
                    permissionCode['Coordinators'] = [201];

                    complainStatus['verificationTeam'] = [];
                    permissionCode['verificationTeam'] = [];

                    complainStatus['ABBProductCategory'] = [3, 4, 5, 11, 12];
                    permissionCode['ABBProductCategory'] = [209];
                    complainStatus['issueProductArray'] = [3, 4, 5, 11, 12];
                    permissionCode['issueProductArray'] = [209];
                    complainStatus['failureMode'] = [3, 4, 5, 11, 12];
                    permissionCode['failureMode'] = [209];
                    complainStatus['failureModeBind'] = [3, 4, 5, 11, 12];
                    permissionCode['failureModeBind'] = [209];

                    complainStatus['DefectTypeandDefectCodeGroupType'] = [3,4,5,11,12];
                    permissionCode['DefectTypeandDefectCodeGroupType'] = [209];
                    complainStatus['WorkCentreandPlant'] = [1,2,3,4,5,7,11,12];
                    permissionCode['WorkCentreandPlant'] = [209];
                    complainStatus['DefectCauseDefectCauseCodeGroup'] = [4,5,11,12];
                    permissionCode['DefectCauseDefectCauseCodeGroup'] = [203];

                    complainStatus['abbProductSerialNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProductSerialNo'] = [209];
                    complainStatus['purchaseOrderNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['purchaseOrderNo'] = [209];
                    complainStatus['abbProjectNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectNumber'] = [209];
                    complainStatus['abbProjectName'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectName'] = [209];
                    complainStatus['CountryCodeWhereTheIssueHappen'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['CountryCodeWhereTheIssueHappen'] = [209];
                    complainStatus['quantity'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['quantity'] = [209];
                    complainStatus['articleNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['articleNumber'] = [209];
                    complainStatus['itemDesignation'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['itemDesignation'] = [209];
                    complainStatus['abbCustomerReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbCustomerReference'] = [209];
                    complainStatus['supplier'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['supplier'] = [209];

                    complainStatus['ProductAlreadyDelivered'] = [4, 5, 11, 12];
                    permissionCode['ProductAlreadyDelivered'] = [203];
                    complainStatus['NoOfUnitsShipped'] = [4, 5, 11, 12];
                    permissionCode['NoOfUnitsShipped'] = [203];

                    complainStatus['deliveryDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deliveryDate'] = [209];
                    complainStatus['commissionDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['commissionDate'] = [209];
                    complainStatus['ManufacturingDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['ManufacturingDate'] = [209];
                    complainStatus['warrantyReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyReference'] = [209];
                    complainStatus['warrantyLifeCycleId'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyLifeCycleId'] = [209];
                    complainStatus['warrantyStartDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyStartDate'] = [209];
                    complainStatus['warrantyEndDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyEndDate'] = [209];
                    complainStatus['documentID'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['documentID'] = [209];
                    complainStatus['qNumberIssue'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['qNumberIssue'] = [209];
                    complainStatus['PartiesReference'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['PartiesReference'] = [215];
                    complainStatus['SendCopyToParties'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['SendCopyToParties'] = [215];
                    // complainStatus['WhatContainmentActionsWhereTaken'] = [1, 2];
                    // permissionCode['WhatContainmentActionsWhereTaken'] = [201];

                    // complainStatus['uploadedPDCAAttachments'] = [];
                    // permissionCode['uploadedPDCAAttachments'] = [];
                    // complainStatus['uploadedSummaryAttachments'] = [];
                    // permissionCode['uploadedSummaryAttachments'] = [];
                    complainStatus['hiddenIsHVDCComplaint'] = [];
                    permissionCode['hiddenIsHVDCComplaint'] = [];

                    // ISSUE ASSIGNMENT FORM
                    complainStatus['PrimaryResolutionOwner'] = [3, 4, 5, 11, 12];
                    permissionCode['PrimaryResolutionOwner'] = [207];
                    complainStatus['resolutionOwnerTeam'] = [3, 4, 5, 11, 12];
                    permissionCode['resolutionOwnerTeam'] = [206];
                    complainStatus['ccrpNo'] = [3, 4, 5];
                    permissionCode['ccrpNo'] = [202];
                    complainStatus['quickFixComplaint'] = [3, 4, 5];
                    permissionCode['quickFixComplaint'] = [202];
                    complainStatus['quickFixVariant'] = [3, 4, 5];
                    permissionCode['quickFixVariant'] = [202];
                    complainStatus['verifiedCheckBox'] = [3, 4, 5];
                    permissionCode['verifiedCheckBox'] = [202];
                    complainStatus['coordinatorList'] = [3, 4, 5];
                    permissionCode['coordinatorList'] = [202];
                    complainStatus['coordinatorName'] = [3, 4, 5];
                    permissionCode['coordinatorName'] = [202];
                    complainStatus['AddNominatedPerson'] = [3, 4, 5];
                    permissionCode['AddNominatedPerson'] = [202];
                    complainStatus['solutionWillBeValidatedByCoordinator'] = [3, 4, 5];
                    permissionCode['solutionWillBeValidatedByCoordinator'] = [202];
                    complainStatus['QuickFixRCANotReqDescription'] = [];
                    permissionCode['QuickFixRCANotReqDescription'] = [];


                    // ISSUE RESOULTION FORM
                    complainStatus['EstimatedCostView'] = [4, 5, 11, 12];
                    permissionCode['EstimatedCostView'] = [203];
                    complainStatus['ImpactOnInstalledBase'] = [4, 5, 11, 12];
                    permissionCode['ImpactOnInstalledBase'] = [203];
                    complainStatus['TotalInstalledBase'] = [4, 5, 11, 12];
                    permissionCode['TotalInstalledBase'] = [203];
                    complainStatus['QNumberResolvingUnit'] = [4, 11];
                    permissionCode['QNumberResolvingUnit'] = [203];

                    complainStatus['availabilityRating'] = [4, 5];
                    permissionCode['availabilityRating'] = [217];
                    complainStatus['functionalityRating'] = [4, 5];
                    permissionCode['functionalityRating'] = [217];
                    complainStatus['onTimeDeliveryRating'] = [4, 5];
                    permissionCode['onTimeDeliveryRating'] = [217];
                    complainStatus['cyberSecurityRating'] = [4, 5];
                    permissionCode['cyberSecurityRating'] = [217];
                    complainStatus['productComplianceRating'] = [4, 5];
                    permissionCode['productComplianceRating'] = [217];
                    complainStatus['ohsRiskRating'] = [4, 5];
                    permissionCode['ohsRiskRating'] = [217];
                    complainStatus['reputationRating'] = [4, 6];
                    permissionCode['reputationRating'] = [217];
                    complainStatus['marginRating'] = [4, 5];
                    permissionCode['marginRating'] = [217];
                    complainStatus['massMediaRating'] = [4, 5];
                    permissionCode['massMediaRating'] = [217];
                    complainStatus['designChangeRating'] = [4, 5];
                    permissionCode['designChangeRating'] = [217];
                    complainStatus['instancesRating'] = [4, 5];
                    permissionCode['instancesRating'] = [217];
                    complainStatus['resolutionRating'] = [4, 5];
                    permissionCode['resolutionRating'] = [217];
                    complainStatus['affectedCustomerRating'] = [4, 5];
                    permissionCode['affectedCustomerRating'] = [217];
                    complainStatus['controlRating'] = [4, 5];
                    permissionCode['controlRating'] = [217];
                    complainStatus['impactRating'] = [4, 5];
                    permissionCode['impactRating'] = [217];

                    complainStatus['Rating'] = [4, 5];
                    permissionCode['Rating'] = [217];
                    // complainStatus['justification'] = [3, 4, 5, 11, 12, 7];
                    // permissionCode['justification'] = [203];
                    complainStatus['ReviewRequired'] = [4, 5, 11, 12];
                    permissionCode['ReviewRequired'] = [203];
                    complainStatus['reviewRequiredJustification'] = [4, 5, 11, 12];
                    permissionCode['reviewRequiredJustification'] = [203];
                    complainStatus['eightDReportRequired'] = [1, 2];
                    permissionCode['eightDReportRequired'] = [203];
                    complainStatus['EightDJustificationCurrent'] = [4, 5, 11, 12];
                    permissionCode['EightDJustificationCurrent'] = [203];

                    // complainStatus['RCACorrectiveActions'] = [1, 2];
                    // permissionCode['RCACorrectiveActions'] = [203];
                    complainStatus['SpecificationName'] = [4, 5, 11, 12];
                    permissionCode['SpecificationName'] = [203];
                    complainStatus['howProductFailMeetSpecification'] = [4, 5, 11, 12];
                    permissionCode['howProductFailMeetSpecification'] = [203];

                    complainStatus['ContainmentActionsExecution'] = [4, 5, 11, 12];
                    permissionCode['ContainmentActionsExecution'] = [203];
                    complainStatus['WhatContainmentActionsWhereTaken'] = [4, 5, 11, 12];
                    permissionCode['WhatContainmentActionsWhereTaken'] = [203];
                    complainStatus['whatWasTheRootCauseOfThisIssue'] = [4, 5, 11, 12];
                    permissionCode['whatWasTheRootCauseOfThisIssue'] = [203];
                    complainStatus['CategoryKeyword'] = [4, 5, 11, 12];
                    permissionCode['CategoryKeyword'] = [203];
                    complainStatus['howDidWeResolveThisIssue'] = [3, 4, 5, 11, 12, 7];
                    permissionCode['howDidWeResolveThisIssue'] = [203];

                    complainStatus['JustificationCurrent'] = [4, 5, 11, 12];
                    permissionCode['JustificationCurrent'] = [203];
                    complainStatus['UploadRCAAttachments'] = [4, 5, 11, 12];
                    permissionCode['UploadRCAAttachments'] = [203];
                    complainStatus['resolutionAttachments'] = [4, 5, 11, 12];
                    permissionCode['resolutionAttachments'] = [203];

                    // complainStatus['uploadedPDCAAttachments'] = [];
                    // permissionCode['uploadedPDCAAttachments'] = [];
                    // complainStatus['uploadedSummaryAttachments'] = [];
                    // permissionCode['uploadedSummaryAttachments'] = [];
                    complainStatus['Upload'] = [4, 5, 11, 12];
                    permissionCode['Upload'] = [203];

                    complainStatus['learn'] = [4, 5, 11, 12];
                    permissionCode['learn'] = [203];
                    complainStatus['desc'] = [4, 5, 11, 12];
                    permissionCode['desc'] = [203];


                    complainStatus['ActualRUCost'] = [4, 5, 11, 12];
                    permissionCode['ActualRUCost'] = [203];
                    complainStatus['COPQCategory'] = [4, 5, 11, 12];
                    permissionCode['COPQCategory'] = [203];
                    complainStatus['COPQCategoryAllData'] = [4, 5, 11, 12];
                    permissionCode['COPQCategoryAllData'] = [203];
                    complainStatus['pgCategory'] = [4, 5, 11, 12];
                    permissionCode['pgCategory'] = [203];
                    complainStatus['PGRCACategoryAllData'] = [4, 5, 11, 12];
                    permissionCode['PGRCACategoryAllData'] = [203];


                    complainStatus['chipData'] = [4, 5, 11, 12];
                    permissionCode['chipData'] = [203];
                    complainStatus['id'] = [4, 5, 11, 12];
                    permissionCode['id'] = [203];
                    complainStatus['issueAssigned'] = [4, 5, 11, 12];
                    permissionCode['issueAssigned'] = [203];
                    complainStatus['selectRole'] = [4, 5, 11, 12];
                    permissionCode['selectRole'] = [203];
                    complainStatus['selectRole1'] = [4, 5, 11, 12];
                    permissionCode['selectRole1'] = [203];
                    complainStatus['issueStatus'] = [4, 5, 11, 12];
                    permissionCode['issueStatus'] = [203];
                    complainStatus['classValue'] = [4, 5, 11, 12];
                    permissionCode['classValue'] = [203];
                    complainStatus['rpn'] = [4, 5, 11, 12];
                    permissionCode['rpn'] = [203];
                    complainStatus['manualChangeClass'] = [4, 5, 11, 12];
                    permissionCode['manualChangeClass'] = [203];

                    // Issue Closed Form
                    complainStatus['AwaitingCustomerExecution'] = [7];
                    permissionCode['AwaitingCustomerExecution'] = [204];
                    complainStatus['customerComments'] = [7, 13];
                    permissionCode['customerComments'] = [204];
                    complainStatus['ExpectedExecutionDate'] = [7, 13];
                    permissionCode['ExpectedExecutionDate'] = [204];
                    complainStatus['customerContactStatus'] = [7, 13];
                    permissionCode['customerContactStatus'] = [204];
                    complainStatus['sendFeedback'] = [7, 13];
                    permissionCode['sendFeedback'] = [204];
                    complainStatus['CustomerFeedbackJustification'] = [7, 13];
                    permissionCode['CustomerFeedbackJustification'] = [204];
                    complainStatus['customerResponse'] = [7, 13];
                    permissionCode['customerResponse'] = [204];
                    complainStatus['uploadedAcceptanceAttachments'] = [7, 13];
                    permissionCode['uploadedAcceptanceAttachments'] = [204];

                    //Effectiveness Form
                    complainStatus['id'] = [8];
                    permissionCode['id'] = [311];
                    complainStatus['IsEffective'] = [8];
                    permissionCode['IsEffective'] = [311];
                    complainStatus['DeadlineDate'] = [8];
                    permissionCode['DeadlineDate'] = [311];
                    complainStatus['effectiveChangedValue'] = [8];
                    permissionCode['effectiveChangedValue'] = [311];
                    complainStatus['EffectivenessReason'] = [8];
                    permissionCode['EffectivenessReason'] = [311];
                    complainStatus['reopenReason'] = [8];
                    permissionCode['reopenReason'] = [311];
                    complainStatus['uploadedEffectivenessAttachments'] = [8];
                    permissionCode['uploadedEffectivenessAttachments'] = [311];
                    complainStatus['uploadedAttachments'] = []; //Hidden Field
                    permissionCode['uploadedAttachments'] = [];


                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Bu_Process_Owner, complainStatus, permissionCode);
                    const permissionDataGlobalBU = this.authorizationMiddleWare.checkSetPermissionData();
                    this.setDisableBasedOnPermission(permissionDataGlobalBU);
                    break;
                case this.enumRoleCode.Pg_Process_Owner:
                    complainStatus['PrimaryComplaintOwner'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PrimaryComplaintOwner'] = [201];
                    complainStatus['substitute'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['substitute'] = [201];
                    complainStatus['supervisor'] = [1, 2];
                    permissionCode['supervisor'] = [201];
                    complainStatus['CustomerType'] = [1, 2];
                    permissionCode['CustomerType'] = [201];
                    complainStatus['customerNameData'] = [];// newly formControlName added
                    permissionCode['customerNameData'] = [];
                    complainStatus['affectedCustomer'] = [1, 2, 3];
                    permissionCode['affectedCustomer'] = [212];
                    complainStatus['affectedCustomerHidden'] = [];// newly formControlName added
                    permissionCode['affectedCustomerHidden'] = [];
                    complainStatus['Customer'] = [1, 2];
                    permissionCode['Customer'] = [201];
                    complainStatus['customerNameHidden'] = [1, 2];// newly formControlName added
                    permissionCode['customerNameHidden'] = [212];
                    complainStatus['CustomerContact'] = [1, 2];
                    permissionCode['CustomerContact'] = [201];
                    complainStatus['customerContactNameHidden'] = [];// newly formControlName added
                    permissionCode['customerContactNameHidden'] = [];
                    complainStatus['Subject'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Subject'] = [210];
                    //Issue Description Form Controls
                    complainStatus['Reason'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Reason'] = [210];
                    complainStatus['WhatIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['WhatIssueDescription'] = [209];
                    complainStatus['whyIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whyIssueDescription'] = [209];
                    complainStatus['howIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['howIssueDescription'] = [209];
                    complainStatus['issueStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueStep'] = [209];
                    complainStatus['inWhichProcessStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['inWhichProcessStep'] = [209];
                    complainStatus['whereIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whereIssueDescription'] = [209];
                    complainStatus['whenIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whenIssueDescription'] = [209];
                    /* complainStatus['issueLocationArr'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueLocationArr'] = [210]; */
                    complainStatus['customerExpectedResolutionDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['customerExpectedResolutionDescription'] = [209];

                    complainStatus['uploadedAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['uploadedAttachments'] = [210];
                    complainStatus['ContainmentActionsId'] = []; // newly formControlName added start
                    permissionCode['ContainmentActionsId'] = [];
                    complainStatus['IsContainmentActionRequired'] = []; // newly formControlName added start
                    permissionCode['IsContainmentActionRequired'] = [];
                    complainStatus['deadline'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deadline'] = [210]
                    complainStatus['ContainmentActionDescription'] = [1, 2];
                    permissionCode['ContainmentActionDescription'] = [201]
                    complainStatus['containmentExecutedDate'] = []; // newly added
                    permissionCode['containmentExecutedDate'] = [];
                    complainStatus['EightDRCAIsRequiredByCustomer'] = [1, 2]; // newly added
                    permissionCode['EightDRCAIsRequiredByCustomer'] = [201];
                    complainStatus['TargetResolvingDate'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['TargetResolvingDate'] = [201]
                    complainStatus['targetResolutionHiddenDate'] = []; // newly added
                    permissionCode['targetResolutionHiddenDate'] = [];
                    complainStatus['dateChangeReason'] = [1, 2, 3, 4, 5, 11, 12];   // newly added
                    permissionCode['dateChangeReason'] = [201];
                    complainStatus['changeDescription'] = [1, 2, 3, 4, 5, 11, 12]; // newly added
                    permissionCode['changeDescription'] = [201];
                    complainStatus['targetResolvingDateChangeCount'] = []; // newly added
                    permissionCode['targetResolvingDateChangeCount'] = [];
                    complainStatus['changeReasonId'] = []; // newly added
                    permissionCode['changeReasonId'] = [];
                    complainStatus['targetResolvingDateInitialValue'] = []; // newly added
                    permissionCode['targetResolvingDateInitialValue'] = [];
                    // additionalSubjectForm
                    complainStatus['resolvingUnit'] = [4, 5];
                    permissionCode['resolvingUnit'] = [214];
                    complainStatus['resolvingUnitArray'] = [1, 2];
                    permissionCode['resolvingUnitArray'] = [214];
                    complainStatus['proposedResolutionOwner'] = [1, 2];
                    permissionCode['proposedResolutionOwner'] = [201];
                    complainStatus['Coordinators'] = [1, 2]
                    permissionCode['Coordinators'] = [201]
                    complainStatus['verificationTeam'] = []
                    permissionCode['verificationTeam'] = []
                    complainStatus['ABBProductCategory'] = [3, 4, 5, 11, 12];
                    permissionCode['ABBProductCategory'] = [209];
                    complainStatus['issueProductArray'] = [];//newly added
                    permissionCode['issueProductArray'] = [];
                    complainStatus['failureMode'] = [3, 4, 5, 11, 12];
                    permissionCode['failureMode'] = [209];
                    complainStatus['failureModeBind'] = [];//newly added
                    permissionCode['failureModeBind'] = [];
                    complainStatus['DefectTypeandDefectCodeGroupType'] = [3,4,5,11,12];
                    permissionCode['DefectTypeandDefectCodeGroupType'] = [209];
                    complainStatus['WorkCentreandPlant'] = [1,2,3,4,5,7,11,12];
                    permissionCode['WorkCentreandPlant'] = [209];
                    complainStatus['DefectCauseDefectCauseCodeGroup'] = [4,5,11,12];
                    permissionCode['DefectCauseDefectCauseCodeGroup'] = [203];
                    complainStatus['abbProductSerialNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProductSerialNo'] = [209];
                    complainStatus['purchaseOrderNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['purchaseOrderNo'] = [209];
                    complainStatus['abbProjectNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectNumber'] = [209];
                    complainStatus['abbProjectName'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectName'] = [209];
                    complainStatus['CountryCodeWhereTheIssueHappen'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['CountryCodeWhereTheIssueHappen'] = [209];
                    complainStatus['quantity'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['quantity'] = [209];
                    complainStatus['articleNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['articleNumber'] = [209];
                    complainStatus['itemDesignation'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['itemDesignation'] = [209];
                    complainStatus['abbCustomerReference'] = [1, 2, 3, 4, 5, 7, 11, 12]; //added abb
                    permissionCode['abbCustomerReference'] = [209];
                    complainStatus['supplier'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['supplier'] = [209];
                    complainStatus['deliveryDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deliveryDate'] = [209];
                    complainStatus['commissionDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['commissionDate'] = [209];
                    complainStatus['ManufacturingDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['ManufacturingDate'] = [209];
                    complainStatus['warrantyReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyReference'] = [209];
                    complainStatus['warrantyLifeCycleId'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyLifeCycleId'] = [209];
                    complainStatus['warrantyStartDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyStartDate'] = [209];
                    complainStatus['warrantyEndDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyEndDate'] = [209];
                    complainStatus['documentID'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['documentID'] = [209];
                    complainStatus['qNumberIssue'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['qNumberIssue'] = [209];
                    complainStatus['ProductAlreadyDelivered'] = [4, 5, 11, 12];
                    permissionCode['ProductAlreadyDelivered'] = [203];
                    complainStatus['NoOfUnitsShipped'] = [4, 5, 11, 12];
                    permissionCode['NoOfUnitsShipped'] = [203];

                    complainStatus['PartiesReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PartiesReference'] = [215];
                    complainStatus['SendCopyToParties'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['SendCopyToParties'] = [215];


                    /* complainStatus['uploadedPDCAAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['uploadedPDCAAttachments'] = [209];
                    complainStatus['uploadedSummaryAttachments'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['uploadedSummaryAttachments'] = [209]; */
                    complainStatus['hiddenIsHVDCComplaint'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['hiddenIsHVDCComplaint'] = [209];

                    // ISSUE ASSIGNMENT FORM
                    complainStatus['PrimaryResolutionOwner'] = [3, 4, 5, 11, 12];
                    permissionCode['PrimaryResolutionOwner'] = [207];
                    complainStatus['resolutionOwnerTeam'] = [3, 4, 5, 11, 12];
                    permissionCode['resolutionOwnerTeam'] = [206];
                    complainStatus['ccrpNo'] = [3, 4, 5];
                    permissionCode['ccrpNo'] = [202];
                    complainStatus['quickFixComplaint'] = [3, 4, 5];
                    permissionCode['quickFixComplaint'] = [202];
                    complainStatus['quickFixVariant'] = [3, 4, 5];
                    permissionCode['quickFixVariant'] = [202];
                    complainStatus['verifiedCheckBox'] = [3, 4, 5];
                    permissionCode['verifiedCheckBox'] = [202];
                    complainStatus['coordinatorList'] = [3, 4, 5];
                    permissionCode['coordinatorList'] = [202];
                    complainStatus['coordinatorName'] = [3, 4, 5];
                    permissionCode['coordinatorName'] = [202];
                    complainStatus['AddNominatedPerson'] = [3, 4, 5];
                    permissionCode['AddNominatedPerson'] = [202];
                    complainStatus['solutionWillBeValidatedByCoordinator'] = []; // newly formControlName added
                    permissionCode['solutionWillBeValidatedByCoordinator'] = [];
                    complainStatus['QuickFixRCANotReqDescription'] = []; // newly formControlName added
                    permissionCode['QuickFixRCANotReqDescription'] = [];

                    // ISSUE RESOULTION FORM
                    complainStatus['EstimatedCostView'] = [1, 2];
                    permissionCode['EstimatedCostView'] = [203];
                    complainStatus['ImpactOnInstalledBase'] = [1, 2];
                    permissionCode['ImpactOnInstalledBase'] = [203];
                    complainStatus['TotalInstalledBase'] = [1, 2];
                    permissionCode['TotalInstalledBase'] = [203];
                    complainStatus['QNumberResolvingUnit'] = [1, 2];
                    permissionCode['QNumberResolvingUnit'] = [203];
                    // complainStatus['RCACorrectiveActions'] = [1, 2];
                    // permissionCode['RCACorrectiveActions'] = [203];
                    complainStatus['SpecificationName'] = [1, 2];
                    permissionCode['SpecificationName'] = [203];
                    complainStatus['howProductFailMeetSpecification'] = [1, 2];
                    complainStatus['SpecificationName'] = [4, 5, 11, 12];
                    permissionCode['SpecificationName'] = [203];
                    complainStatus['howProductFailMeetSpecification'] = [4, 5, 11, 12];
                    permissionCode['howProductFailMeetSpecification'] = [203];
                    complainStatus['ContainmentActionsExecution'] = [1, 2];
                    permissionCode['ContainmentActionsExecution'] = [203];
                    complainStatus['WhatContainmentActionsWhereTaken'] = [1, 2];
                    permissionCode['WhatContainmentActionsWhereTaken'] = [203];
                    complainStatus['whatWasTheRootCauseOfThisIssue'] = [1, 2];
                    permissionCode['whatWasTheRootCauseOfThisIssue'] = [203];
                    complainStatus['CategoryKeyword'] = [1, 2];
                    permissionCode['CategoryKeyword'] = [203];
                    complainStatus['howDidWeResolveThisIssue'] = [1, 2];
                    permissionCode['howDidWeResolveThisIssue'] = [203];
                    complainStatus['chipData'] = [1, 2];
                    permissionCode['chipData'] = [203];
                    complainStatus['Upload'] = [1, 2];
                    permissionCode['Upload'] = [203];
                    complainStatus['ActualRUCost'] = [1, 2];
                    permissionCode['ActualRUCost'] = [203];
                    complainStatus['COPQCategory'] = [1, 2];
                    permissionCode['COPQCategory'] = [203];
                    complainStatus['COPQCategoryAllData'] = [1, 2];
                    permissionCode['COPQCategoryAllData'] = [203];
                    complainStatus['pgCategory'] = [1, 2];
                    permissionCode['pgCategory'] = [203];
                    complainStatus['PGRCACategoryAllData'] = [1, 2];
                    permissionCode['PGRCACategoryAllData'] = [203];
                    complainStatus['learn'] = [1, 2];
                    permissionCode['learn'] = [203];
                    complainStatus['desc'] = [1, 2];
                    permissionCode['desc'] = [203];
                    complainStatus['id'] = [1, 2];
                    permissionCode['id'] = [203];
                    complainStatus['Rating'] = [1, 2];
                    permissionCode['Rating'] = [203];
                    complainStatus['issueAssigned'] = [1, 2];
                    permissionCode['issueAssigned'] = [203];
                    complainStatus['selectRole'] = [1, 2];
                    permissionCode['selectRole'] = [203];
                    complainStatus['selectRole1'] = [1, 2];
                    permissionCode['selectRole1'] = [203];
                    complainStatus['issueStatus'] = [1, 2];
                    permissionCode['issueStatus'] = [203];

                    complainStatus['availabilityRating'] = [4, 5];
                    permissionCode['availabilityRating'] = [217];
                    complainStatus['functionalityRating'] = [4, 5];
                    permissionCode['functionalityRating'] = [217];
                    complainStatus['onTimeDeliveryRating'] = [4, 5];
                    permissionCode['onTimeDeliveryRating'] = [217];
                    complainStatus['cyberSecurityRating'] = [4, 5];
                    permissionCode['cyberSecurityRating'] = [217];
                    complainStatus['productComplianceRating'] = [4, 5];
                    permissionCode['productComplianceRating'] = [217];
                    complainStatus['ohsRiskRating'] = [4, 5];
                    permissionCode['ohsRiskRating'] = [217];
                    complainStatus['reputationRating'] = [4, 5];
                    permissionCode['reputationRating'] = [217];
                    complainStatus['marginRating'] = [4, 5];
                    permissionCode['marginRating'] = [217];
                    complainStatus['massMediaRating'] = [4, 5];
                    permissionCode['massMediaRating'] = [217];
                    complainStatus['designChangeRating'] = [4, 5];
                    permissionCode['designChangeRating'] = [217];
                    complainStatus['instancesRating'] = [4, 5];
                    permissionCode['instancesRating'] = [217];
                    complainStatus['resolutionRating'] = [4, 5];
                    permissionCode['resolutionRating'] = [217];
                    complainStatus['affectedCustomerRating'] = [4, 5];
                    permissionCode['affectedCustomerRating'] = [217];
                    complainStatus['controlRating'] = [4, 5];
                    permissionCode['controlRating'] = [217];
                    complainStatus['impactRating'] = [1, 2];
                    permissionCode['impactRating'] = [217];


                    complainStatus['classValue'] = [1, 2];
                    permissionCode['classValue'] = [217];
                    complainStatus['rpn'] = [1, 2];
                    permissionCode['rpn'] = [217];
                    complainStatus['JustificationCurrent'] = [1, 2];
                    permissionCode['JustificationCurrent'] = [217];
                    /* complainStatus['uploadedPDCAAttachments'] = [1, 2];
                    permissionCode['uploadedPDCAAttachments'] = [217];
                    complainStatus['uploadedSummaryAttachments'] = [1, 2];
                    permissionCode['uploadedSummaryAttachments'] = [217]; */
                    complainStatus['manualChangeClass'] = [4, 5, 11, 12];
                    permissionCode['manualChangeClass'] = [203];
                    complainStatus['ReviewRequired'] = [1, 2];
                    permissionCode['ReviewRequired'] = [203];
                    complainStatus['eightDReportRequired'] = [1, 2];
                    permissionCode['eightDReportRequired'] = [203];
                    complainStatus['uploadedSummaryAttachments'] = [1, 2];
                    permissionCode['uploadedSummaryAttachments'] = [203];
                    complainStatus['EightDJustificationCurrent'] = [1, 2];
                    permissionCode['EightDJustificationCurrent'] = [203];
                    complainStatus['reviewRequiredJustification'] = [1, 2];
                    permissionCode['reviewRequiredJustification'] = [203];
                    complainStatus['UploadRCAAttachments'] = [];// newly formControlName added
                    permissionCode['UploadRCAAttachments'] = [];
                    complainStatus['resolutionAttachments'] = [];// newly formControlName added
                    permissionCode['resolutionAttachments'] = [];

                    // Issue Closed Form
                    complainStatus['AwaitingCustomerExecution'] = [7];
                    permissionCode['AwaitingCustomerExecution'] = [204];
                    complainStatus['customerComments'] = [7, 13];
                    permissionCode['customerComments'] = [204];
                    complainStatus['ExpectedExecutionDate'] = [7, 13];
                    permissionCode['ExpectedExecutionDate'] = [204];
                    complainStatus['customerContactStatus'] = [7, 13];
                    permissionCode['customerContactStatus'] = [204];
                    complainStatus['sendFeedback'] = [7, 13];
                    permissionCode['sendFeedback'] = [204];
                    complainStatus['CustomerFeedbackJustification'] = [7, 13];
                    permissionCode['CustomerFeedbackJustification'] = [204];
                    complainStatus['customerResponse'] = [7, 13];
                    permissionCode['customerResponse'] = [204];
                    complainStatus['uploadedAcceptanceAttachments'] = [7, 13];
                    permissionCode['uploadedAcceptanceAttachments'] = [204];

                    //Effectiveness Form
                    complainStatus['id'] = [8];
                    permissionCode['id'] = [311];
                    complainStatus['IsEffective'] = [8];
                    permissionCode['IsEffective'] = [311];
                    complainStatus['DeadlineDate'] = [8];
                    permissionCode['issueDaeffectivenessDeadlineDatete'] = [311];
                    complainStatus['effectiveChangedValue'] = [8];
                    permissionCode['effectiveChangedValue'] = [311];
                    complainStatus['EffectivenessReason'] = [8];
                    permissionCode['EffectivenessReason'] = [311];
                    complainStatus['reopenReason'] = [8];
                    permissionCode['reopenReason'] = [311];
                    complainStatus['uploadedEffectivenessAttachments'] = [8];
                    permissionCode['uploadedEffectivenessAttachments'] = [311];
                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Pg_Process_Owner, complainStatus, permissionCode);
                    const permissionDataPGProcess = this.authorizationMiddleWare.checkSetPermissionData();
                    this.setDisableBasedOnPermission(permissionDataPGProcess);
                    break;
                case this.enumRoleCode.Hub_Process_Owner:
                    // Issue Capture Form
                    complainStatus['PrimaryComplaintOwner'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['PrimaryComplaintOwner'] = [201];
                    complainStatus['substitute'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['substitute'] = [201];
                    complainStatus['supervisor'] = [1, 2];
                    permissionCode['supervisor'] = [201];
                    complainStatus['CustomerType'] = [1, 2];
                    permissionCode['CustomerType'] = [201];
                    complainStatus['customerNameData'] = [];
                    permissionCode['customerNameData'] = [];
                    complainStatus['CustomerContact'] = [1, 2];
                    permissionCode['CustomerContact'] = [211];
                    complainStatus['customerContactNameHidden'] = [];
                    permissionCode['customerContactNameHidden'] = [];
                    complainStatus['affectedCustomer'] = [1, 2, 3];
                    permissionCode['affectedCustomer'] = [212];
                    complainStatus['affectedCustomerHidden'] = [1, 2, 3];
                    permissionCode['affectedCustomerHidden'] = [212];
                    complainStatus['Customer'] = [1, 2];
                    permissionCode['Customer'] = [212];
                    complainStatus['customerNameHidden'] = [1, 2];
                    permissionCode['customerNameHidden'] = [212];
                    complainStatus['Subject'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Subject'] = [210];
                    //Issue Description
                    complainStatus['Reason'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['Reason'] = [210];
                    complainStatus['WhatIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['WhatIssueDescription'] = [209];
                    complainStatus['whyIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whyIssueDescription'] = [209];
                    complainStatus['howIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['howIssueDescription'] = [209];
                    complainStatus['whenIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whenIssueDescription'] = [209];
                    complainStatus['inWhichProcessStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['inWhichProcessStep'] = [209];
                    complainStatus['whereIssueDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['whereIssueDescription'] = [209];
                    complainStatus['issueStep'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['issueStep'] = [209];
                    complainStatus['customerExpectedResolutionDescription'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['customerExpectedResolutionDescription'] = [209];
                    complainStatus['uploadedAttachments'] = [];
                    permissionCode['uploadedAttachments'] = [];
                    complainStatus['ContainmentActionsId'] = [];
                    permissionCode['ContainmentActionsId'] = [];
                    complainStatus['IsContainmentActionRequired'] = [];
                    permissionCode['IsContainmentActionRequired'] = [];
                    complainStatus['TargetResolvingDate'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['TargetResolvingDate'] = [201];

                    complainStatus['targetResolutionHiddenDate'] = [];
                    permissionCode['targetResolutionHiddenDate'] = [];
                    complainStatus['deadline'] = [1, 2];
                    permissionCode['deadline'] = [210];
                    complainStatus['ContainmentActionDescription'] = [1, 2];
                    permissionCode['ContainmentActionDescription'] = [201];
                    complainStatus['containmentExecutedDate'] = [];
                    permissionCode['containmentExecutedDate'] = [];
                    complainStatus['EightDRCAIsRequiredByCustomer'] = [1, 2];
                    permissionCode['EightDRCAIsRequiredByCustomer'] = [201];
                    complainStatus['dateChangeReason'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['dateChangeReason'] = [201];
                    complainStatus['changeDescription'] = [1, 2, 3, 4, 5, 11, 12];
                    permissionCode['changeDescription'] = [201];
                    permissionCode['targetResolvingDateChangeCount'] = [];
                    complainStatus['targetResolvingDateChangeCount'] = [];
                    permissionCode['changeReasonId'] = [];
                    permissionCode['changeReasonId'] = [];
                    permissionCode['targetResolvingUnitInitialValue'] = [];
                    permissionCode['targetResolvingUnitInitialValue'] = [];

                    complainStatus['resolvingUnit'] = [4, 5];
                    permissionCode['resolvingUnit'] = [214];
                    complainStatus['resolvingUnitArray'] = [1, 2];
                    permissionCode['resolvingUnitArray'] = [214];
                    complainStatus['proposedResolutionOwner'] = [1, 2];
                    permissionCode['proposedResolutionOwner'] = [201];
                    complainStatus['Coordinators'] = [1, 2]
                    permissionCode['Coordinators'] = [201]
                    complainStatus['verificationTeam'] = []
                    permissionCode['verificationTeam'] = []
                    complainStatus['ABBProductCategory'] = [3, 4, 5, 11, 12];
                    permissionCode['ABBProductCategory'] = [209];

                    complainStatus['issueProductArray'] = [];
                    permissionCode['issueProductArray'] = [];
                    complainStatus['failureMode'] = [3, 4, 5, 11, 12];
                    permissionCode['failureMode'] = [209];
                    complainStatus['failureModeBind'] = [];
                    permissionCode['failureModeBind'] = [];

                    complainStatus['DefectTypeandDefectCodeGroupType'] = [3,4,5,11,12];
                    permissionCode['DefectTypeandDefectCodeGroupType'] = [209];
                    complainStatus['WorkCentreandPlant'] = [1,2,3,4,5,7,11,12];
                    permissionCode['WorkCentreandPlant'] = [209];
                    complainStatus['DefectCauseDefectCauseCodeGroup'] = [4,5,11,12];
                    permissionCode['DefectCauseDefectCauseCodeGroup'] = [203];

                    complainStatus['abbProductSerialNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProductSerialNo'] = [209];
                    complainStatus['purchaseOrderNo'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['purchaseOrderNo'] = [209];
                    complainStatus['abbProjectNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectNumber'] = [209];
                    complainStatus['abbProjectName'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbProjectName'] = [209];
                    complainStatus['CountryCodeWhereTheIssueHappen'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['CountryCodeWhereTheIssueHappen'] = [209];
                    complainStatus['quantity'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['quantity'] = [209];
                    complainStatus['articleNumber'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['articleNumber'] = [209];
                    complainStatus['itemDesignation'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['itemDesignation'] = [209];
                    complainStatus['abbCustomerReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['abbCustomerReference'] = [209];
                    complainStatus['supplier'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['supplier'] = [209];
                    complainStatus['deliveryDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['deliveryDate'] = [209];
                    complainStatus['commissionDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['commissionDate'] = [209];
                    complainStatus['ManufacturingDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['ManufacturingDate'] = [209];
                    complainStatus['warrantyReference'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyReference'] = [209];
                    complainStatus['warrantyLifeCycleId'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyLifeCycleId'] = [209];
                    complainStatus['warrantyStartDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyStartDate'] = [209];
                    complainStatus['warrantyEndDate'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['warrantyEndDate'] = [209];
                    complainStatus['documentID'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['documentID'] = [209];
                    complainStatus['qNumberIssue'] = [1, 2, 3, 4, 5, 7, 11, 12];
                    permissionCode['qNumberIssue'] = [209];
                    complainStatus['ProductAlreadyDelivered'] = [4, 5, 11, 12];
                    permissionCode['ProductAlreadyDelivered'] = [203];
                    complainStatus['NoOfUnitsShipped'] = [4, 5, 11, 12];
                    permissionCode['NoOfUnitsShipped'] = [203];
                    complainStatus['PartiesReference'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['PartiesReference'] = [215];
                    complainStatus['SendCopyToParties'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['SendCopyToParties'] = [215];
                    // complainStatus['uploadedPDCAAttachments'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    // permissionCode['uploadedPDCAAttachments'] = [215];
                    // complainStatus['uploadedSummaryAttachments'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    // permissionCode['uploadedSummaryAttachments'] = [215];
                    complainStatus['hiddenIsHVDCComplaint'] = [1, 2, 3, 4, 5, 11, 12, 7];
                    permissionCode['hiddenIsHVDCComplaint'] = [215];

                    // ISSUE ASSIGNMENT FORM
                    complainStatus['PrimaryResolutionOwner'] = [3, 4, 5, 11, 12];
                    permissionCode['PrimaryResolutionOwner'] = [207];
                    complainStatus['resolutionOwnerTeam'] = [3, 4, 5, 11, 12];
                    permissionCode['resolutionOwnerTeam'] = [206];
                    complainStatus['ccrpNo'] = [3, 4, 5];
                    permissionCode['ccrpNo'] = [202];
                    complainStatus['quickFixComplaint'] = [3, 4, 5];
                    permissionCode['quickFixComplaint'] = [202];
                    complainStatus['quickFixVariant'] = [3, 4, 5];
                    permissionCode['quickFixVariant'] = [202];
                    complainStatus['verifiedCheckBox'] = [3, 4, 5];
                    permissionCode['verifiedCheckBox'] = [202];
                    complainStatus['coordinatorList'] = [3, 4, 5];
                    permissionCode['coordinatorList'] = [202];
                    complainStatus['coordinatorName'] = [3, 4, 5];
                    permissionCode['coordinatorName'] = [202];
                    complainStatus['AddNominatedPerson'] = [3, 4, 5];
                    permissionCode['AddNominatedPerson'] = [202];
                    complainStatus['solutionWillBeValidatedByCoordinator'] = [];
                    permissionCode['solutionWillBeValidatedByCoordinator'] = [];
                    complainStatus['QuickFixRCANotReqDescription'] = [];
                    permissionCode['QuickFixRCANotReqDescription'] = [];

                    // ISSUE RESOULTION FORM
                    complainStatus['EstimatedCostView'] = [1, 2];
                    permissionCode['EstimatedCostView'] = [203];
                    complainStatus['ImpactOnInstalledBase'] = [1, 2];
                    permissionCode['ImpactOnInstalledBase'] = [203];
                    complainStatus['TotalInstalledBase'] = [1, 2];
                    permissionCode['TotalInstalledBase'] = [203];
                    complainStatus['QNumberResolvingUnit'] = [1, 2];
                    permissionCode['QNumberResolvingUnit'] = [203];

                    // complainStatus['RCACorrectiveActions'] = [1, 2];
                    // permissionCode['RCACorrectiveActions'] = [203];
                    complainStatus['SpecificationName'] = [1, 2];
                    permissionCode['SpecificationName'] = [203];
                    complainStatus['howProductFailMeetSpecification'] = [1, 2];
                    permissionCode['howProductFailMeetSpecification'] = [203];
                    complainStatus['ContainmentActionsExecution'] = [1, 2];
                    permissionCode['ContainmentActionsExecution'] = [203];
                    complainStatus['WhatContainmentActionsWhereTaken'] = [1, 2];
                    permissionCode['WhatContainmentActionsWhereTaken'] = [203];
                    complainStatus['whatWasTheRootCauseOfThisIssue'] = [1, 2];
                    permissionCode['whatWasTheRootCauseOfThisIssue'] = [203];
                    complainStatus['CategoryKeyword'] = [1, 2];
                    permissionCode['CategoryKeyword'] = [203];
                    complainStatus['howDidWeResolveThisIssue'] = [1, 2];
                    permissionCode['howDidWeResolveThisIssue'] = [203];

                    complainStatus['JustificationCurrent'] = [];
                    permissionCode['JustificationCurrent'] = [];
                    // complainStatus['uploadRCAAttachments'] = [];
                    // permissionCode['uploadRCAAttachments'] = [];
                    // complainStatus['resolutionAttachments'] = [];
                    // permissionCode['resolutionAttachments'] = [];

                    complainStatus['chipData'] = [4, 5, 11, 12];
                    permissionCode['chipData'] = [203];
                    complainStatus['Upload'] = [4, 5, 11, 12];
                    permissionCode['Upload'] = [203];
                    complainStatus['ActualRUCost'] = [1, 2];
                    permissionCode['ActualRUCost'] = [203];
                    complainStatus['COPQCategory'] = [1, 2];
                    permissionCode['COPQCategory'] = [203];
                    complainStatus['COPQCategoryAllData'] = [4, 5, 11, 12];
                    permissionCode['COPQCategoryAllData'] = [203];
                    complainStatus['pgCategory'] = [1, 2];
                    permissionCode['pgCategory'] = [203];
                    complainStatus['PGRCACategoryAllData'] = [4, 5, 11, 12];
                    permissionCode['PGRCACategoryAllData'] = [203];
                    complainStatus['learn'] = [1, 2];
                    permissionCode['learn'] = [203];
                    complainStatus['desc'] = [1, 2];
                    permissionCode['desc'] = [203];
                    complainStatus['id'] = [];
                    permissionCode['id'] = [];
                    complainStatus['Rating'] = [];
                    permissionCode['Rating'] = [];
                    complainStatus['issueAssigned'] = [];
                    permissionCode['issueAssigned'] = [];
                    complainStatus['selectRole'] = [];
                    permissionCode['selectRole'] = [];
                    complainStatus['selectRole1'] = [];
                    permissionCode['selectRole1'] = [];
                    complainStatus['issueStatus'] = [];
                    permissionCode['issueStatus'] = [];

                    complainStatus['availabilityRating'] = [];
                    permissionCode['availabilityRating'] = [];
                    complainStatus['functionalityRating'] = [];
                    permissionCode['functionalityRating'] = [];
                    complainStatus['onTimeDeliveryRating'] = [];
                    permissionCode['onTimeDeliveryRating'] = [];
                    complainStatus['cyberSecurityRating'] = [];
                    permissionCode['cyberSecurityRating'] = [];
                    complainStatus['productComplianceRating'] = [];
                    permissionCode['productComplianceRating'] = [];
                    complainStatus['ohsRiskRating'] = [];
                    permissionCode['ohsRiskRating'] = [];
                    complainStatus['reputationRating'] = [];
                    permissionCode['reputationRating'] = [];
                    complainStatus['marginRating'] = [];
                    permissionCode['marginRating'] = [];
                    complainStatus['massMediaRating'] = [];
                    permissionCode['massMediaRating'] = [];
                    complainStatus['designChangeRating'] = [];
                    permissionCode['designChangeRating'] = [];
                    complainStatus['instancesRating'] = [];
                    permissionCode['instancesRating'] = [];
                    complainStatus['resolutionRating'] = [];
                    permissionCode['resolutionRating'] = [];
                    complainStatus['affectedCustomerRating'] = [];
                    permissionCode['affectedCustomerRating'] = [];
                    complainStatus['controlRating'] = [];
                    permissionCode['controlRating'] = [];
                    complainStatus['impactRating'] = [];
                    permissionCode['impactRating'] = [];
                    complainStatus['classValue'] = [];
                    permissionCode['classValue'] = [];
                    complainStatus['rpn'] = [];
                    permissionCode['rpn'] = [];
                    complainStatus['justification'] = [];
                    permissionCode['justification'] = [];
                    // complainStatus['uploadedPDCAAttachments'] = [];
                    // permissionCode['uploadedPDCAAttachments'] = [];
                    // complainStatus['uploadedSummaryAttachments'] = [];
                    // permissionCode['uploadedSummaryAttachments'] = [];
                    complainStatus['manualChangeClass'] = [4, 5, 11, 12];
                    permissionCode['manualChangeClass'] = [203];
                    complainStatus['ReviewRequired'] = [];
                    permissionCode['ReviewRequired'] = [];
                    complainStatus['eightDReportRequired'] = [];
                    permissionCode['eightDReportRequired'] = [];
                    // complainStatus['uploadedSummaryAttachments'] = [];
                    // permissionCode['uploadedSummaryAttachments'] = [];
                    complainStatus['EightDJustificationCurrent'] = [];
                    permissionCode['EightDJustificationCurrent'] = [];
                    complainStatus['reviewRequiredJustification'] = [];
                    permissionCode['reviewRequiredJustification'] = [];

                    // Issue Closed Form
                    complainStatus['AwaitingCustomerExecution'] = [7];
                    permissionCode['AwaitingCustomerExecution'] = [204];
                    complainStatus['customerComments'] = [7, 13];
                    permissionCode['customerComments'] = [204];
                    complainStatus['ExpectedExecutionDate'] = [7, 13];
                    permissionCode['ExpectedExecutionDate'] = [204];
                    complainStatus['customerContactStatus'] = [7,13];
                    permissionCode['customerContactStatus'] = [0];
                    complainStatus['sendFeedback'] = [7, 13];
                    permissionCode['sendFeedback'] = [204];
                    complainStatus['CustomerFeedbackJustification'] = [7, 13];
                    permissionCode['CustomerFeedbackJustification'] = [204];
                    complainStatus['customerResponse'] = [];
                    permissionCode['customerResponse'] = [0];
                    complainStatus['uploadedAcceptanceAttachments'] = [7, 13];
                    permissionCode['uploadedAcceptanceAttachments'] = [204];

                    //Effectiveness Form
                    complainStatus['id'] = [];
                    permissionCode['id'] = [];
                    complainStatus['IsEffective'] = [8];
                    permissionCode['IsEffective'] = [311];
                    complainStatus['DeadlineDate'] = [8];
                    permissionCode['DeadlineDate'] = [311];
                    complainStatus['effectiveChangedValue'] = [];
                    permissionCode['effectiveChangedValue'] = [];
                    complainStatus['EffectivenessReason'] = [8];
                    permissionCode['EffectivenessReason'] = [311];
                    complainStatus['reopenReason'] = [];
                    permissionCode['reopenReason'] = [];
                    complainStatus['uploadedEffectivenessAttachments'] = [8];
                    permissionCode['uploadedEffectivenessAttachments'] = [311];
                    complainStatus['uploadedAttachments'] = []; //Hidden Field
                    permissionCode['uploadedAttachments'] = [];

                    this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Hub_Process_Owner, complainStatus, permissionCode);
                    const permissionDataHubProcessOwner = this.authorizationMiddleWare.checkSetPermissionData();
                    this.setDisableBasedOnPermission(permissionDataHubProcessOwner);
                    break;
                // case this.enumRoleCode.Management_Role:
                //     this.authorizationMiddleWare.commonMethodSetPermission(this.userAccessData, this.enumRoleCode.Management_Role);
                //    break;
                default:
                    break;
            }
        }
    }


    authorizationBtnDisable() {
        if(this.complaintData?.sapComplaintInfo?.sapLockStatus == true && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
            this['saveBtn_issueCapture'] = true;
            return;
          }

        if (this.userAccessData) {
            switch (this.userAccessData['roleCode']) {
                case this.enumRoleCode.Complaint_Owner:
                    this.disableBtn['saveAsDraft_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1], [201], this.userAccessData);
                    this.disableBtn['submit_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [201], this.userAccessData);
                    this.disableBtn['delete_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [2], [201], this.userAccessData);
                    this.disableBtn['submitSapQ_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [214], this.userAccessData);
                    this.disableBtn['saveBtn_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2, 3, 4, 5, 7, 11, 12, 13], [214], this.userAccessData);
                    break;
                case this.enumRoleCode.Complaint_Owner_Supervisor:
                  this.disableBtn['saveAsDraft_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1], [201], this.userAccessData);
                  this.disableBtn['submit_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [201], this.userAccessData);
                  this.disableBtn['delete_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [2], [201], this.userAccessData);
                  this.disableBtn['submitSapQ_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [214], this.userAccessData);
                  this.disableBtn['saveBtn_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2, 3, 4, 5, 7, 11, 12, 13], [214], this.userAccessData);
                  break;
                case this.enumRoleCode.Complaint_Coordinator:
                    this.disableBtn['saveAsDraft_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [2, 3, 4, 5, 11, 12, 7, 8], [214], this.userAccessData);
                    this.disableBtn['submitSapQ_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [214], this.userAccessData);
                    this.disableBtn['submit_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [201], this.userAccessData);
                    this.disableBtn['saveBtn_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2, 3, 4, 5, 7, 11, 12, 13], [214], this.userAccessData);
                    break;
                case this.enumRoleCode.Resolution_Owner:
                    this.disableBtn['saveBtn_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [214], this.userAccessData);
                    this.disableBtn['submitSapQ_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [214], this.userAccessData);
                    this.disableBtn['delete_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [201], this.userAccessData);
                    this.disableBtn['submit_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [201], this.userAccessData);
                    break;
                case this.enumRoleCode.Support_Desk:
                    this.disableBtn['saveBtn_issueAssign'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [2, 3, 4, 5, 11, 12, 7, 8], [214], this.userAccessData);
                    this.disableBtn['submitSapQ_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [214], this.userAccessData);
                    this.disableBtn['submit_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [201], this.userAccessData);            // case this.enumRoleCode.Global_CCRP_Process_Owner:
                    this.disableBtn['saveBtn_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2, 3, 4, 5, 7, 11, 12, 13], [214], this.userAccessData);
                    break;
                case this.enumRoleCode.Resolution_Owner_Team:
                    this.disableBtn['saveBtn_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [214], this.userAccessData);
                    this.disableBtn['submitSapQ_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [214], this.userAccessData);
                    this.disableBtn['delete_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [201], this.userAccessData);
                    this.disableBtn['submit_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [201], this.userAccessData);
                    break;

                case this.enumRoleCode.Complaint_Owner_Team:
                    this.disableBtn['saveAsDraft_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1], [201], this.userAccessData);
                    this.disableBtn['submitSapQ_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [214], this.userAccessData);
                    this.disableBtn['submit_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [201], this.userAccessData);
                    this.disableBtn['delete_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [2], [201], this.userAccessData);
                    this.disableBtn['saveBtn_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [2, 3, 4, 5, 11, 12, 7, 8], [214], this.userAccessData);
                    break;

                case this.enumRoleCode.Global_CCRP_Process_Owner:
                    this.disableBtn['saveBtn_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [2, 3, 4, 5, 11, 12, 7, 8], [214], this.userAccessData);
                    this.disableBtn['submitSapQ_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [214], this.userAccessData);
                    this.disableBtn['submit_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [201], this.userAccessData);
                    break;
                case this.enumRoleCode.Pg_Process_Owner:
                    this.disableBtn['saveBtn_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [2, 3, 4, 5, 11, 12, 7, 8], [214], this.userAccessData);
                    this.disableBtn['submitSapQ_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [214], this.userAccessData);
                    this.disableBtn['submit_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [201], this.userAccessData);
                    break;
                case this.enumRoleCode.Div_Process_Owner:
                    this.disableBtn['saveBtn_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [2, 3, 4, 5, 11, 12, 7, 8], [214], this.userAccessData);
                    this.disableBtn['submitSapQ_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [214], this.userAccessData);
                    this.disableBtn['submit_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [201], this.userAccessData);
                    break;
                case this.enumRoleCode.Bu_Process_Owner:
                    this.disableBtn['saveBtn_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [2, 3, 4, 5, 11, 12, 7, 8], [214], this.userAccessData);
                    this.disableBtn['submitSapQ_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [214], this.userAccessData);
                    this.disableBtn['submit_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [201], this.userAccessData);
                    break;
                case this.enumRoleCode.Hub_Process_Owner:
                    this.disableBtn['submit_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [201], this.userAccessData);
                    this.disableBtn['submitSapQ_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [214], this.userAccessData);
                    this.disableBtn['saveBtn_issueCapture'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [2, 3, 4, 5, 11, 12, 7, 8], [214], this.userAccessData);
                    break;
            }
        }

    }

    setDisableBasedOnPermission(permissionData) {
        // Issue capture Form
        Object.keys(this.issueCapture.controls).forEach(key => {
            if (key != 'supervisor') {
                if (permissionData.issueCaptureForm[key] == true) {
                    if (key == 'PrimaryComplaintOwner') {
                        this.readonlyObj.PrimaryComplaintOwnerReadOnly = false;
                    }
                    else if (key == 'substitute') {
                        this.readonlyObj.substituteReadOnly = false;
                    }
                    else {
                        this.issueCapture.controls[key].enable();
                    }
                }
                else if (permissionData.issueCaptureForm[key] == false) {
                    if (key == 'PrimaryComplaintOwner') {
                        this.readonlyObj.PrimaryComplaintOwnerReadOnly = true;
                        this.issueCapture.controls[key].disable();
                    }
                    else if (key == 'substitute') {
                        this.readonlyObj.substituteReadOnly = true;
                        this.issueCapture.controls[key].disable();
                    }
                    else {
                        this.issueCapture.controls[key].disable();
                    }
                }
            }

        });

        // Issue Description Form
        Object.keys(this.issueDescription.controls).forEach(key => {
            if (permissionData.issueDescriptionForm[key] == true) {
                this.issueDescription.controls[key].enable();
            }
            else if (permissionData.issueDescriptionForm[key] == false) {
                this.issueDescription.controls[key].disable();
            }
        });

        // Issue Rating Form
        Object.keys(this.issueRating.controls).forEach(key => {
            if (permissionData.ratingForm[key] == true) {
                this.issueRating.controls[key].enable();
            }
            else if (permissionData.ratingForm[key] == false) {
                this.issueRating.controls[key].disable();
            }
        });

        // Issue Additional Subject Form
        Object.keys(this.additionalSubject.controls).forEach(key => {
            if (key != 'Coordinators' && key != 'verificationTeam') {
                if (permissionData.additionalSubjectForm[key] == true) {
                    if (key == 'proposedResolutionOwner') {
                        this.readonlyObj.proposedResolutionOwnerReadOnly = false;
                    }
                    else if(key == 'PartiesReference'){
                        this.readonlyObj.PartiesReferenceReadOnly = false;
                    }
                    else {
                        this.additionalSubject.controls[key].enable();
                    }
                }
                else if (permissionData.additionalSubjectForm[key] == false) {
                    if (key == 'proposedResolutionOwner') {
                        this.readonlyObj.proposedResolutionOwnerReadOnly = true;
                       // this.additionalSubject.controls[key].disable();
                    }
                    else if(key == 'PartiesReference'){
                        this.readonlyObj.PartiesReferenceReadOnly = true;
                       // this.additionalSubject.controls[key].disable();
                    }
                    else {
                        this.additionalSubject.controls[key].disable();
                    }
                }
            }else{
              //this.additionalSubject.controls[key].disable();
            }

        });

        // Issue Assignment Form
        Object.keys(this.issueAssignment.controls).forEach(key => {
            if(key!='coordinatorList'){
            if (permissionData.issueAssignmentForm[key] == true) {
                if(key=='PrimaryResolutionOwner'){
                    this.readonlyObj.PrimaryResolutionOwnerReadOnly= false;
                }
                else if(key=='resolutionOwnerTeam'){
                    this.readonlyObj.resolutionOwnerTeamReadOnly= false;
                }
                else if(key == 'AddNominatedPerson'){
                    this.readonlyObj.AddNominatedPersonReadOnly= false;
                }
                else{
                    this.issueAssignment.controls[key].enable();
                }
            }
            else if (permissionData.issueAssignmentForm[key] == false) {
                if(key=='PrimaryResolutionOwner'){
                    this.readonlyObj.PrimaryResolutionOwnerReadOnly= true;
                    //this.issueAssignment.controls[key].disable();
                }
                else if(key=='resolutionOwnerTeam'){
                    this.readonlyObj.resolutionOwnerTeamReadOnly= true;
                    //this.issueAssignment.controls[key].disable();
                }
                else if(key == 'AddNominatedPerson'){
                    this.readonlyObj.AddNominatedPersonReadOnly= true;
                    //this.issueAssignment.controls[key].disable();
                }
                else{
                    this.issueAssignment.controls[key].disable();
                }
            }}
        });

        // Issue Resolution Form
        Object.keys(this.issueResolution.controls).forEach(key => {
            if (permissionData.issueResolutionForm[key] == true) {
                this.issueResolution.controls[key].enable();
            }
            else if (permissionData.issueResolutionForm[key] == false) {
                this.issueResolution.controls[key].disable();
            }
        });

        // Issue Closed Form
        Object.keys(this.issueClosed.controls).forEach(key => {
            if (permissionData.issueClosedForm[key] == true) {
                this.issueClosed.controls[key].enable();
            }
            else if (permissionData.issueClosedForm[key] == false) {
                this.issueClosed.controls[key].disable();
            }
        });

        // Effectiveness Form
        Object.keys(this.effectivenessForm.controls).forEach(key => {
            if (permissionData.effectivenessForm[key] == true) {
                this.effectivenessForm.controls[key].enable();
            }
            else if (permissionData.effectivenessForm[key] == false) {
                this.effectivenessForm.controls[key].disable();
            }
        });
    }

    onManualClassDDLChange(manualClass: any) {

        if (manualClass != 'None') {

          let  matrixClass = MatrixClass[this.complaintData.areaComplaintResolution.matrixCurrentRatingViewModel.class]  ?? MatrixClass.C;
          let currselectedRole =  this.userAccessData?.roleCode;
          this.issueResolution.patchValue({
            unAssignClassConfirmation: manualClass});
          if (currselectedRole == RoleCode.Global_CCRP_Process_Owner || currselectedRole == RoleCode.Support_Desk || ((currselectedRole == RoleCode.Div_Process_Owner || currselectedRole == RoleCode.Bu_Process_Owner) && (this.sessionStorage.getData('userInfo')?.email == 'klaus.lehan@hitachienergy.com' || this.sessionStorage.getData('userInfo')?.email == 'lutz-detlef.haas@hitachienergy.com'))) {
              this.issueResolution.patchValue({
                manualClassUpgrade: 'None'});
          }
          else if (matrixClass == 'C' && (manualClass == 'A' || manualClass == 'B' || manualClass == 'I')) {
            this.issueResolution.patchValue({
              manualClassUpgrade: manualClass});
          }
          else if (matrixClass == 'I' && (manualClass == 'A' || manualClass == 'B')) {
            this.issueResolution.patchValue({
              manualClassUpgrade: manualClass});
          }
          else if (matrixClass == 'B' && manualClass == 'A') {
            this.issueResolution.patchValue({
              manualClassUpgrade: manualClass});
          }
          else {
            this.issueResolution.patchValue({
              manualClassUpgrade: 'None'});
          }

            let currentRatingResponse: any = {};
            if (manualClass == 'A') {
                currentRatingResponse.class = 1;
                currentRatingResponse.classFriendlyResult = "High Impact Class A";
                currentRatingResponse.isHighImpact = true;
                currentRatingResponse.rpn = this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.rpn;

            }
            if (manualClass == 'B') {
                currentRatingResponse.class = 2;
                currentRatingResponse.classFriendlyResult = "High Impact Class B";
                currentRatingResponse.isHighImpact = true;
                currentRatingResponse.rpn = this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.rpn;
            }
            if (manualClass == 'I') {
                currentRatingResponse.class = 3;
                currentRatingResponse.classFriendlyResult = "High Impact Class I";
                currentRatingResponse.isHighImpact = true;
                currentRatingResponse.rpn = this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.rpn;
            }
            if (manualClass == 'C') {
                currentRatingResponse.class = 0;
                currentRatingResponse.classFriendlyResult = "Class C";
                currentRatingResponse.isHighImpact = false;
                currentRatingResponse.rpn = this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.rpn;
            }

            if (manualClass) {
                this.issueResolution.patchValue({
                    classValue: currentRatingResponse.class,
                    impactRating: currentRatingResponse.classFriendlyResult,
                    rpn: currentRatingResponse.rpn,
                });
              // External Bug User Story 9116: INC0549122 | CHG0063345 | If CCRP downgraded from I to C, then Field Upload 8D still remains mandatory and doesn't allow the user to submit the CCRP without 8D.
              //External Bug User Story 9118: INC0543604|CHG0063345 Class C CCRP - Verification Email should not get triggered. But in this CCRP the verification email is getting triggered.
              if (manualClass == 'C') {
                this.issueDescription.patchValue({
                  EightDRCAIsRequiredByCustomer: false
                }),
                  this.issueResolution.patchValue({
                    RCACorrectiveActions: false
                  }),
                  this.issueAssignment.patchValue({
                    solutionWillBeValidatedByCoordinator: false
                  })
              }
              else {
                this.issueDescription.patchValue({
                  EightDRCAIsRequiredByCustomer: true
                }),
                  this.issueResolution.patchValue({
                    RCACorrectiveActions: true
                  }),
                  this.issueAssignment.patchValue({
                    solutionWillBeValidatedByCoordinator: true
                  })
              }
                this.issueRating.patchValue({
                    HasHightImpactOnABB: currentRatingResponse.isHighImpact
                });

                if (manualClass != 'None') {
                    this.issueResolution.get('JustificationCurrent').addValidators(Validators.required);
                    this.issueResolution.get('JustificationCurrent').updateValueAndValidity();
                } else {
                    this.issueResolution.get('JustificationCurrent').clearValidators();
                }

                if (manualClass = 'C') {
                    this.issueResolution.get('ReviewRequired').addValidators(Validators.required);
                    this.issueResolution.get('ReviewRequired').updateValueAndValidity();
                    this.issueResolution.get('reviewRequiredJustification').addValidators(Validators.required);
                    this.issueResolution.get('reviewRequiredJustification').updateValueAndValidity();
                }
            }
        }
    }

    public get isExpanded() {
        return this._isExpanded;
    }

    public set isExpanded(value: boolean) {
        this._isExpanded = value;
    }

    expandCollapseSetup() {
        if (this.expandIndex.length == 0) {
            if (this.headerSection.get('issueComplaintStatus')?.value == 'New' || this.headerSection.get('issueComplaintStatus')?.value == 'Draft') {
                this.expandIndex = [0];
            }
            else if (this.headerSection.get('issueComplaintStatus')?.value == 'Not Assigned') {
                this.expandIndex = [0, 1];
            }
            else if (this.headerSection.get('issueComplaintStatus')?.value == 'In Process' || this.headerSection.get('issueComplaintStatus')?.value == 'In Process Verification' || this.headerSection.get('issueComplaintStatus')?.value == 'In Process Verification Overdue' || this.headerSection.get('issueComplaintStatus')?.value == 'In Process Overdue') {
                if (this.complaintData?.areaComplaintEffectiveness?.reopenReason) {
                    this.expandIndex = [0, 1, 2, 3, 4];
                }
                else {
                    this.expandIndex = [0, 1, 2];
                }
            }
            else if (this.headerSection.get('issueComplaintStatus')?.value == 'Resolved' || this.headerSection.get('issueComplaintStatus')?.value == 'Completed (Execution Pending)') {
                this.expandIndex = [0, 1, 2, 3];
            }
            else if (this.headerSection.get('issueComplaintStatus')?.value == 'Completed') {
                this.expandIndex = [0, 1, 2, 3, 4];
            }
        }
        else if (this.expandIndex.length > 0) {
            this.expandIndex = [];
        }
    }

    accordionSetup() {
        if (this.headerSection.get('issueComplaintStatus')?.value == 'New' || this.headerSection.get('issueComplaintStatus')?.value == 'Draft') {
            this.expandIndex = [0];
            this.disableField.issueCapture = false;
            this.disableField.assignment = true;
            this.disableField.rca = true;
            this.disableField.closed = true;
            this.disableField.effectiveNess = true;
        }
        else if (this.headerSection.get('issueComplaintStatus')?.value == 'Not Assigned') {
            this.expandIndex = [0, 1];
            this.disableField.issueCapture = false;
            this.disableField.assignment = false;
            this.disableField.rca = true;
            this.disableField.closed = true;
            this.disableField.effectiveNess = true;
            const userInfo = this.sessionStorage.getData('userInfo');
            const highClassApprovalEmail = environment.idsForHighClassApproval;
            if((this.role == 'Support Desk' || this.role == 'Global CCRP Process Owner' ||
            (highClassApprovalEmail.includes(userInfo.email) && this.role == 'Business Process Owner')) && this.issueRating.controls['impactRating']?.value == 'High Impact Class A') {
                this.disableField.rca = false;
            }
        }
        else if (this.headerSection.get('issueComplaintStatus')?.value == 'In Process' || this.headerSection.get('issueComplaintStatus')?.value == 'In Process Verification' || this.headerSection.get('issueComplaintStatus')?.value == 'In Process Verification Overdue' || this.headerSection.get('issueComplaintStatus')?.value == 'In Process Overdue') {
            if (this.complaintData?.areaComplaintEffectiveness?.reopenReason) {
                this.expandIndex = [0, 1, 2, 3, 4];
            }
            else {
                this.expandIndex = [0, 1, 2];
            }
            this.disableField.issueCapture = false;
            this.disableField.assignment = false;
            this.disableField.rca = false;
            this.disableField.closed = true;
            this.disableField.effectiveNess = true;
        }
        else if (this.headerSection.get('issueComplaintStatus')?.value == 'Resolved' || this.headerSection.get('issueComplaintStatus')?.value == 'Completed (Execution Pending)') {
            this.expandIndex = [0, 1, 2, 3];
            this.disableField.issueCapture = false;
            this.disableField.assignment = false;
            this.disableField.rca = false;
            this.disableField.closed = false;
            this.disableField.effectiveNess = true;
        }
        else if (this.headerSection.get('issueComplaintStatus')?.value == 'Completed') {
            this.expandIndex = [0, 1, 2, 3, 4];
            this.disableField.issueCapture = false;
            this.disableField.assignment = false;
            this.disableField.rca = false;
            this.disableField.closed = false;
            this.disableField.effectiveNess = false;
        }

    }

    onDeleteDraft() {
        this.ref = this.dialogService.open(DeleteDraftComponent, {
            header: 'Delete issue reason',
            width: '40%',
            position: 'center',
            keepInViewport: true,
            autoZIndex: true,
            styleClass: 'choose-role-pop-up'
        });
        this.ref.onClose.subscribe((result) => {
            if (result.action == true) {
                const userInfo = this.sessionStorage.getData('userInfo');
                this.headerSection.patchValue({
                    deleteReason: result.reason
                });
                const complaintformatedData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                //  console.log(complaintformatedData);
                this.complaintDetailsService.deleteDraft(complaintformatedData).subscribe((Response) => {
                    if (Response) {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Draft complaint successfully deleted.'
                        });
                        this.router.navigateByUrl('mydashboard');
                    }
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
        })
    }

    //setting top right values based on complaint
  initialSetting() {
    if (this.complaintId != 0 && this.complaintData === undefined) {
      this.router.navigate(['Exceptions/ComplaintNotFound', this.complaintId])
    }

        this.translate.get('Complaint_ComplaintOwner').subscribe((data: any) => {
            this.role = data;
        });
        this.issueCaptureMainForm.patchValue({
            id: this.complaintData?.id
        });
        this.CCRPNo = (this.complaintData == undefined) ? '' : this.complaintData?.header?.complaintNumber;
        this.confidentialCheck = (this.complaintData == undefined) ? false : this.complaintData?.header?.isConfidential;
        this.headerSection?.patchValue({
            isConfidential: (this.complaintData == undefined) ? false : this.complaintData?.header?.isConfidential,
            issueComplaintStatus: (this.complaintData == undefined) ? 'New' : this.complaintData?.header?.complaintStatusName,
            complaintStatusId: (this.complaintData == undefined) ? 1 : this.complaintData?.header?.complaintStatus,
            complaintNumber: (this.complaintData == undefined) ? '' : this.complaintData?.header?.complaintNumber,
            rowVersion: (this.complaintData == undefined) ? '' : this.complaintData?.header?.rowversion,
            rowVersionString: (this.complaintData == undefined) ? '' : this.complaintData?.header?.rowversionString,
            createDate: (this.complaintData == undefined) ? '' : this.complaintData?.header?.createDate,
            salesForceId : (this.complaintData == undefined)  ? null : this.complaintData?.header?.salesForceId
          });
        this.ccrpNumber = this.headerSection.get('complaintNumber')?.value;
        this.issueComplaintStatus = this.headerSection.get('issueComplaintStatus')?.value
        this.accordionSetup();

    if (this.issueComplaintStatus == 'New' || this.issueComplaintStatus == 'Empty') {

    } else if (this.issueComplaintStatus == 'Draft') {
      if (this.complaintData?.complaintDataAccess?.complaintRoles.length > 0) {
        let result = this.complaintData?.complaintDataAccess?.complaintRoles[0];
        this.role = result?.name;
        const userData = {
          roleCode: result?.code,
          statusCode: this.complaintData?.header?.complaintStatus,
          permissions: result.permissions
        }
        this.authorizationService.setLoggedInUserData(userData);
        this.setFieldsValidation(this.ActionNameForComplaints);
      } else {
        this.role = 'None';
        const userData = {
          roleCode: 0,
          statusCode: this.complaintData?.header?.complaintStatus,
          permissions: []
        }
        this.authorizationService.setLoggedInUserData(userData);
      }
    }
    else {
      const complaintRoles = this.complaintData?.complaintDataAccess?.complaintRoles?.some((roleName) => roleName?.name == this.role);
      if(!complaintRoles) {
        // Default role select from complaintRoles
        this.setupRole();
      }
      if(this.complaintData?.complaintDataAccess?.complaintRoles.length == 1) {
          // Default role select from complaintRoles
          this.setupRole();
      }
      else if (this.complaintData?.complaintDataAccess?.complaintRoles.length > 1) {
        this.openPopupRole();
      } else {
        this.role = 'None';
        const userData = {
          roleCode: 0,
          statusCode: this.complaintData?.header?.complaintStatus,
          permissions: []
        }
        this.authorizationService.setLoggedInUserData(userData);
      }
    }


    if (this.complaintData?.header?.complaintStatus == 8) {
      this.closedBy = this.complaintData?.areaComplaintAssignment?.caResolutionOwner?.primaryResolutionOwner?.email;
      if (this.complaintData?.areaComplaintEffectiveness?.isEffective == 2) {
        this.isEffectivnessOption = 'NotApplicable'
      } else if (this.complaintData?.areaComplaintEffectiveness?.isEffective == 0) {
        this.isEffectivnessOption = 'Yes'
      } else if (this.complaintData?.areaComplaintEffectiveness?.isEffective == 1) {
        this.isEffectivnessOption = 'No'
      }


    }
    this.complaintDetailsService.getComplaintActivityDates(this.sessionStorage.getData('userInfo')?.geid, this.CCRPNo).subscribe(
      resp => {
        this.ComplaintLogDates = resp;
      }
    )
    if (this.complaintData?.header?.complaintStatus > 7) {
      this.effectinvessDeadlineDate = this.complaintData?.areaComplaintEffectiveness?.deadlineDate;
    }

    this.progressValue = this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.issueCaptureDataPercentage;

    const userData = {
      roleCode: this.complaintData.complaintDataAccess['selectedRole'].code,
      statusCode: this.complaintData?.header?.complaintStatus,
      permissions: this.complaintData.complaintDataAccess['selectedRole'].permissions
    }
    this.authorizationService.setLoggedInUserData(userData);

  }

  setupRole() {
    this.role = this.complaintData?.complaintDataAccess?.complaintRoles[0]?.name;
    const userData = {
      roleCode: this.complaintData?.complaintDataAccess?.complaintRoles[0]?.code,
      statusCode: this.complaintData?.header?.complaintStatus,
      permissions: this.complaintData?.complaintDataAccess?.complaintRoles[0]?.permissions
    }
    this.authorizationService.setLoggedInUserData(userData);
    setTimeout(() => {
      this.checkPermission();
    }, 10);
  }



    onGetAccountableVerifiedUsers(isHVDCComplaint, processStepId) {
        let editorGeid = this.sessionStorage.getData('userInfo')?.geid;
        let accountableGeids = this.complaintData?.areaComplaintResolution?.accountablePersonGeid;
        if (editorGeid && accountableGeids) {
            let accountableArray = accountableGeids.split(',');
            let isAccountablePerson = accountableArray.find(x => x == editorGeid);
            if (/*isAccountablePerson && */isHVDCComplaint != false && processStepId) {

                let accountableVerifiedUsers = this.complaintData?.areaComplaintResolution?.accountableVerifiedUsers;
                if (accountableVerifiedUsers) {
                    // Get Accountable Verified Users.
                    this.userInfoDetailsService.getEmployeeDetails(accountableVerifiedUsers).then((resp) => {
                        if (resp) {
                              let NominatedPersons = resp;
                              let filteredRecordNominatedPerson = [];
                                NominatedPersons?.map(coordinator=>{
                                filteredRecordNominatedPerson.push({ selectedLabel: coordinator?.email, promptLabel: coordinator?.email, tooltipInfo: coordinator?.tooltipInfo, geid: coordinator?.id, identification: coordinator?.identification, role: coordinator?.role })
                                });
                                this.issueAssignment.patchValue({
                                    AddNominatedPerson: filteredRecordNominatedPerson
                                })
                            /* let propertyFullPath = "AreaComplaintAssignment_CAResolutionOwner_AccountableVerifiedUsersList";
                                 ABB.TaggedBoxAutocompleteWrapper.clear(propertyFullPath);



                                 $.each(response.Data, function (index, value) {
                                     ABB.TaggedBoxAutocompleteWrapper.addTaggedValue(propertyFullPath, value.Identification, value.DisplayLabel, value.TooltipInfo);
                                 });



                                 ABB.TaggedBoxAutocompleteWrapper.removeRequiredErrorsValue(propertyFullPath);*/
                        }
                    },
                        (error) => {
                            this.spinner.hide();
                            this.messageService.add({
                                severity: 'error',
                                summary: error ? error : 'Something went wrong!'
                            });
                        });
                    /*  function onGetAccountableVerifiedUsers(isHVDCComplaint, processStepId) {
          var editorGeid = '@Model.Header.EditorGEID';
          var accountableGeids = '@Model.AreaComplaintResolution.AccountablePersonGeid';
          if (editorGeid && accountableGeids) {
              var accountableArray = accountableGeids.split(',');
              var isAccountablePerson = accountableArray.find(x => x == editorGeid);
              if (isAccountablePerson && isHVDCComplaint != false && processStepId) {
                  var accountableVerifiedUsers = '@Model.AreaComplaintResolution.AccountableVerifiedUsers';
                  if (accountableVerifiedUsers) {
                      // Get Accountable Verified Users.
                      $.ajax({
                          url: "@CCRPRoutes.Data.GetEmployeesByGeid",
                          data: { geids: accountableVerifiedUsers },
                          success: function (response) {
                              if (response.Data) {
                                  var propertyFullPath = "AreaComplaintAssignment_CAResolutionOwner_AccountableVerifiedUsersList";
                                  ABB.TaggedBoxAutocompleteWrapper.clear(propertyFullPath);



                                  $.each(response.Data, function (index, value) {
                                      ABB.TaggedBoxAutocompleteWrapper.addTaggedValue(propertyFullPath, value.Identification, value.DisplayLabel, value.TooltipInfo);
                                  });



                                  ABB.TaggedBoxAutocompleteWrapper.removeRequiredErrorsValue(propertyFullPath);
                              }
                              if (response.Error) {
                                  toastr.error(response.Error);
                              }
                          },
                          error: function (request, status, error) {
                              toastr.error('Sorry an error occured');
                          },
                          complete: function () {
                          }
                      });
                      }
              } else {
                  $('#showAccountableVerifiedUsersList').hide();
              }
          } else {
              $('#showAccountableVerifiedUsersList').hide();
          }
  }*/
                }
            } else {
                //     $('#showAccountableVerifiedUsersList').hide();
            }
        } else {
            //    $('#showAccountableVerifiedUsersList').hide();
        }
    }

    menuItemSetup() {

        this.menuItems = [
            {
                label: 'Site Actions',
                items: [
                   // { label: 'Send message', command: () => this.openPopup() },
                   // { label: 'View activity log', command: () => this.moveToActionLog() },
                    { label: 'CCRP Summary Report', command: () => this.ccrpSummaryReport() },
                    {
                        label: 'Report Templates', items: [{ label: '8D Report', url: '/assets/reports/8D_Report_template.docx' },
                        { label: 'DMAIC Report', url: '/assets/reports/DMAIC_Report_template.xlsx' },
                        { label: 'PDCA template', url: '/assets/reports/PDCA _report_template.xlsx' }
                        ]
                    },
                    { label: 'Overview in PowerPoint', command: () => this.downloadComplaintDataPPT() },
                    { label: 'Change role', command: () => this.openPopupRole() }
                ]
            }
        ]
    }

    moveToActionLog() {
        //this.router.navigate(['complaint/'+ this.complaintData?.header?.complaintNumber + '/actionlog']);
        this.router.navigate([]).then(result => { window.open('complaint/' + this.complaintData?.header?.complaintNumber + '/actionlog', '_blank'); });
    }

    ccrpSummaryReport() {
        // this.isReportEnable = true;
        this.router.navigate([]).then(result => { window.open('issuesummary/' + this.complaintData?.header?.id, '_blank'); });

    }

    downloadComplaintDataPPT() {
        this.spinner.show();
        this.complaintDetailsService.downloadComplaintInfoPPT(this.complaintId).subscribe((response: ArrayBuffer) => {
            const blob = new Blob([response], { type: 'application/vnd.ms-powerpoint' });
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = 'Issue_overview_in_power_point';
            downloadLink.click();
            this.spinner.hide();
        },
            (error) => {
                this.spinner.hide();
                this.messageService.add({
                    severity: 'error',
                    summary: error ? error : 'Something went wrong!'
                });
            });
    }

    toggleAllAccordions() {
        if (this.expandIndex.length == 0) {
            this.expandIndex = [0, 1, 2, 3, 4];
        } else {
            this.expandIndex = [];
        }
        this.accButtonText = this.expandIndex.length ? 'Collapse All' : 'Expand All';
    }

    element: boolean = false;

    showData() {
        this.element = false;
    }
    hideData() {
        this.element = true;
    }

    confidentialSetting(status: string, setTo: boolean, id: number, restrictionKey: string, confidentialSetting?: boolean) {
        this.ref = this.dialogService.open(AccessRestrictionConfidentialComponent, {
            header: "Access restriction",
            styleClass: 'access_restriction',
            data: { status, setTo, id, restrictionKey, confidentialSetting },
            position: 'top',
            width: '40%',
            closable: false
        });
        this.ref.onClose.subscribe((res1) => {
            if (res1 == 'confirm' && status !== 'New') {
                this.newIssueService.setConfidential(this.complaintId, setTo).subscribe((res:any) => {
                    this.toasterService.showSuccess('Confidential status updated successfully');
                    this.confidentialCheck = setTo;
                    this.headerSection.patchValue({
                        rowVersionString: res?.rowVersion
                    });
                    this.complaintData.header.rowversionString = res;
                });
            }
            if (res1 == true) {
                this.headerSection.patchValue({
                    isConfidential: false
                })
                this.confidentialCheck = false;
            }
            else if (res1 == false) {
                this.headerSection.patchValue({
                    isConfidential: true
                })
                this.confidentialCheck = true;
            }
        });
    }

    onConfidentialChange(event) {
        const isConfidential = event.target?.checked;
        if (isConfidential && this.headerSection.get('issueComplaintStatus')?.value == 'New') {
            this.confidentialSetting(this.headerSection.get('issueComplaintStatus')?.value, true, this.complaintId, 'ComplaintForm_ResIsConfidentialNewComplaintConfirmationMessage', isConfidential);
        }
        else if (!isConfidential && this.headerSection.get('issueComplaintStatus')?.value == 'New') {
            this.confidentialSetting(this.headerSection.get('issueComplaintStatus')?.value, true, this.complaintId, 'ComplaintForm_ResIsNoConfidentialNewComplaintConfirmationMessage', isConfidential);
        }
        else if (isConfidential && this.headerSection.get('issueComplaintStatus')?.value !== 'New') {
            this.confidentialSetting(this.headerSection.get('issueComplaintStatus')?.value, true, this.complaintId, 'ComplaintForm_ResIsConfidentialConfirmationMessage', isConfidential);
        }
        else if (!isConfidential && this.headerSection.get('issueComplaintStatus')?.value !== 'New') {
            this.confidentialSetting(this.headerSection.get('issueComplaintStatus')?.value, false, this.complaintId, 'ComplaintForm_ResIsNoConfidentialConfirmationMessage', isConfidential);
        }
        if (isConfidential) {
            this.confidentialCheck = true;
        }
        else {
            this.confidentialCheck = false;
        }

    }

    openPopupRole(): any {
        this.ref = this.dialogService.open(ChooseRolePopUpComponent, {
            header: 'Choose Role',
            width: '25%',
            height: '35%',
            position: 'center',
            keepInViewport: true,
            autoZIndex: true,
            styleClass: 'choose-role-pop-up',
            data: { complaintRoles: this.complaintData?.complaintDataAccess?.complaintRoles }
        });
        this.ref.onClose.subscribe((result) => {
            if (result && result.length > 0) {
                this.role = result[0]?.name;
                const userData = {
                    roleCode: result[0]?.code,
                    statusCode: this.complaintData?.header?.complaintStatus,
                    permissions: result[0].permissions
                }
                this.authorizationService.setLoggedInUserData(userData);
                this.accordionSetup();
                this.setFieldsValidation(this.ActionNameForComplaints);
            }
            // this.messageService.add({ severity: 'success', summary: 'Message has been sent!' });

            if(this.complaintData?.sapComplaintInfo?.sapLockStatus == true && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
                this.issueCaptureMainForm?.disable();
                this.toasterService.showInfo(this.complaintData?.sapComplaintInfo?.sapLockStatusMessage);
            }
        })
    }

    openPopup(): any {
        this.ref = this.dialogService.open(SendEmailSiteActionsComponent, {
            header: 'Send Email',
            width: '70%',
            position: 'top',
            keepInViewport: true,
            autoZIndex: true,
            styleClass: 'send-email-site',
            data: { complaintNo: this.complaintData.header.complaintNumber, complaintId: this.complaintData.header.id, role: this.userAccessData['roleCode'] }
        });
        this.ref.onClose.subscribe((result) => {
            if (result) {
                this.messageService.add({ severity: 'success', summary: 'Message has been sent!' });
            }
        })
    }

    view() {
        // console.log("WORKING");
    }
    reset() {
        //   console.log("Working fine!");
    }
    templateDownload() {

    }
    @Output() close = new EventEmitter<void>();
    onClose() {
        this.close.emit();
    }
    emailForm: FormGroup = this.formBuilder.group({
        to: [[], [Validators.required]],
        cc: [[], [Validators.required]],
        subject: ['', [Validators.required]],
        template: ['20', [Validators.required]],
        attachment: ['', [Validators.required]],
        format: ['', [Validators.required]],
        textareaBody: ['', []]
    });
    emailList: any = [];
    onRecipientChange(event: any) {
        this.emailServiceTempService.getEmpDetails(event.query).subscribe(res => {
            this.emailList = res;
        })
    }
    subjectInc() {
        this.emailServiceTempService.getSubject(this.complaintId).subscribe((res: any) => {
            this.emailForm.get('subject').setValue(res.subject);
        });

    }
    template(temp) {
        this.emailServiceTempService.getTemplate(temp.target.value).subscribe((res: any) => {
            this.emailForm.get('to').setValue(res.defaultRecipients != null ? res.defaultRecipients.length > 0 ? res.defaultRecipients : [] : []);
            this.emailForm.get('cc').setValue(res.defaultCC != null ? res.defaultCC.length > 0 ? res.defaultCC : [] : []);
            this.emailForm.get('subject').setValue(res.subject);
            this.emailForm.get('textareaBody').setValue(res.body);
        });
    }
    emailRequest: any = {};
    // code for send mail object to backed
    sendMail() {
        this.emailRequest.to = this.emailForm.get('to')?.value.map(i => i.selectedLabel);
        this.emailRequest.cc = this.emailForm.get('cc')?.value.map(i => i.selectedLabel);
        this.emailRequest.subject = this.emailForm.get('subject')?.value;
        // this.emailService.sendEmail(this.emailList).subscribe(res => {
        //   console.log("mail send. response "+ res);
        // });

    }

    enableCustomerContactStatus() {
        const CustomerTypeStatus = this.issueCapture.controls['CustomerType']?.value;
        if (CustomerTypeStatus === 'External') {
            return true;
        }
        else {
            return false;
        }
    }

    get headerSection() {
        return this.issueCaptureMainForm.controls["headerSection"] as FormGroup;
    }

    get issueCapture() {
        return this.issueCaptureMainForm.controls["issueCaptureForm"] as FormGroup;
    }

    get issueDescription() {
        return this.issueCaptureMainForm.controls["issueDescriptionForm"] as FormGroup;
    }

    get issueRating() {
        return this.issueCaptureMainForm.controls["ratingForm"] as FormGroup;
    }

    get additionalSubject() {
        return this.issueCaptureMainForm.controls["additionalSubjectForm"] as FormGroup;
    }

    get effectivenessForm() {
        return this.issueCaptureMainForm.controls["effectivenessForm"] as FormGroup;
    }

    get issueAssignment() {
        return this.issueCaptureMainForm.controls["issueAssignmentForm"] as FormGroup;
    }

    get issueResolution() {
        return this.issueCaptureMainForm.controls["issueResolutionForm"] as FormGroup;
    }

    get issueClosed() {
        return this.issueCaptureMainForm.controls["issueClosedForm"] as FormGroup;

    }

    // Commented By Ram - As there is no api : EmailList and which makes unnecessary api call
    // getUsers() {
    //     this.usersService?.getUsers()?.subscribe((Response) => {
    //         this.users = Response;
    //     });
    // }

    checkTargetDateChange() {
        if (formatDate(new Date(this.datePipe.transform(this.issueDescription?.controls['TargetResolvingDate'].value, 'yyyy-MM-dd')), 'yyyy-MM-dd', 'en-US') == formatDate(new Date(this.datePipe.transform(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.targetResolvingDate, 'yyyy-MM-dd')), 'yyyy-MM-dd', 'en-US')) {
            return true;
        }
        else {
            return false;
        }
    }

    modifyContact() {
        // console.log('in home');
        if (this.complaintData != undefined) {
            this.onSave(true);
        }
        else {
            this.onSaveDraft(true);
        }

    }
    async onSaveDraft(modified: boolean = false) {
        //  console.log("before validation");
        this.ActionNameForComplaints = "SaveAsDraft";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        // await console.log("??????????", this.issueCaptureMainForm);
        this.spinner.show();
        this.issueSubmitted = true;
        // if (this.issueCaptureMainForm.controls?.['issueCaptureForm'].invalid || this.issueCaptureMainForm.controls?.['issueDescriptionForm'].invalid || this.issueCaptureMainForm.controls?.['ratingForm'].invalid || this.issueCaptureMainForm.controls?.['additionalSubjectForm'].invalid) {
        if (this.issueCaptureMainForm.invalid) {
            // let invalidControl = this.el.nativeElement.querySelector('.error-inputbox');
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        /*else if (this.issueDescription.controls?.['TargetResolvingDate'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != null && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'], this.today)) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }*/
        else if (this.issueDescription.controls['deadline'].value != null && this.issueDescription.controls['deadline'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != null && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'], this.issueDescription.controls['deadline'].value)) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else {
            const userInfo = this.sessionStorage.getData('userInfo');
            const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
            // console.log(complaintData);
            this.complaintDetailsService.saveAsDraft(complaintData).subscribe((res) => {
//                 console.log("save as draft res=>", res);
                if (res) {
                    const complaintId = res?.complaintWorkflowResult?.complaintId;
                    const complaintNumber = res?.complaintWorkflowResult?.complaintNumber;
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Issue ' + complaintNumber + ' has been saved as draft'
                    });
                    this.spinner.hide();
                    if (modified == true) {
                        this.router.navigate(['/admin/customer-contacts', res?.areaComplaintCollection?.customerSelectionSection?.customer?.gisIdentifier, res?.id]);
                    }
                    else {
                        this.router.navigate(['complaint', complaintId]);
                    }
                }

            },
                (error) => {
                    this.spinner.hide();
                    // this.messageService.add({
                    //     severity: 'error',
                    //     summary: error ? error : 'Something went wrong!'
                    // });
                });
        }
    }

    async onSave(modified: boolean = false) {
        this.spinner.show();
        this.issueSubmitted = true;
        this.ActionNameForComplaints = "SaveComplaintCaptureSection";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        //console.log("====",this.issueCaptureMainForm);
        /*this.issueCaptureMainForm.controls?.['issueCaptureForm'].invalid || this.issueCaptureMainForm.controls?.['issueDescriptionForm'].invalid || this.issueCaptureMainForm.controls?.['ratingForm'].invalid || this.issueCaptureMainForm.controls?.['additionalSubjectForm'].invalid */
        if (this.issueCaptureMainForm.invalid) {
            // let invalidControl = this.el.nativeElement.querySelector('.error-inputbox');
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        /*else if (this.issueDescription.controls['deadline'].value != null && this.issueDescription.controls?.['deadline'].value != '' && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['deadline'], this.today)) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (this.issueDescription.controls?.['TargetResolvingDate'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != null && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'], this.today)) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }*/
        else if (this.issueDescription.controls['deadline'].value != null && this.issueDescription.controls?.['deadline'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != null && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'], this.issueDescription.controls['deadline'].value)) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (!this.checkTargetDateChange() && this.issueDescription.controls?.['dateChangeReason'].value == null && this.complaintData?.header?.complaintStatus > 2) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
          this.spinner.hide();
          this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else {
            const userInfo = this.sessionStorage.getData('userInfo');
            if (this.complaintData) {
                this.headerSection.patchValue({
                    complaintNumber: this.complaintData.header?.complaintNumber
                });
                this.issueCaptureMainForm.patchValue({
                    id: this.complaintData.header?.id
                })
            }
            if (this.complaintData) {
                // this.patchMatrixData()
            }
            const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
            // console.log(complaintData);

            this.complaintDetailsService.onSave(complaintData).subscribe({
                next: (res) => {
                    //  console.log("save as draft res=>", res);
                    if(res?.length > 0){
                        this.spinner.hide();
                        this.toasterService.showError('Error occured in SAP system')
                    }
                    else if (res) {
                        this.headerSection?.patchValue({
                            rowVersion: res?.header?.rowversion,
                            rowVersionString: res?.header?.rowversionString
                        });
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Issue was saved successfully.'
                        });
                        if (modified == true) {
                            this.router.navigate(['/admin/customer-contacts', res?.areaComplaintCollection?.customerSelectionSection?.customer?.gisIdentifier, res?.id]);
                        }
                        else {
                            window.location.reload();
                        }
                    }

                },
                error: (error) => {
                    this.spinner.hide();
                    // this.messageService.add({
                    //     severity: 'error',
                    //     summary: error ? error : 'Something went wrong!'
                    // });
                }
            });
        }
    }

    patchMatrixData() {
        this.issueResolution.patchValue({
            EstimatedCostView: this.complaintData?.areaComplaintResolution?.estimatedCost,
            availabilityRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[0]?.selectionId,
            functionalityRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[1]?.selectionId,
            onTimeDeliveryRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[2]?.selectionId,
            cyberSecurityRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[3]?.selectionId,
            productComplianceRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[4]?.selectionId,
            ohsRiskRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[5]?.selectionId,
            marginRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[6]?.selectionId,
            reputationRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[7]?.selectionId,
            massMediaRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[8]?.selectionId,
            designChangeRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[9]?.selectionId,
            instancesRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[10]?.selectionId,
            resolutionRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[11]?.selectionId,
            affectedCustomerRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[12]?.selectionId,
            controlRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[13]?.selectionId,
            rpn: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.rpn,
            impactRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.classFriendlyResult,
            COPQCategoryAllData: this.complaintData?.areaComplaintResolution?.sectionCategorisation?.copqCategory,
            PGRCACategoryAllData: this.complaintData?.areaComplaintResolution?.sectionCategorisation?.pgCategory
        });
    }

    enableRadioCheckBtn() {
        const ratingData = this.issueRating?.controls['impactRating']?.value;
        if (ratingData === 'Unconfirmed') {
            return true;
        }
        else {
            return false;
        }
    }

    enableAdditionalNominatedPerson() {
        // const issueRatingData = this.issueRating?.controls['impactRating']?.value;
        // const issueDescData = this.issueDescription?.controls['issueStep']?.value;
        // const checkingHVDC = issueDescData?.includes('HVDC');
        // const issueCoordinatorData = this.additionalSubject?.controls['resolvingUnit']?.value;
        // const checkingPGGI = issueCoordinatorData?.includes('PGHV');
        // const productData = this.additionalSubject?.controls['ABBProductCategory']?.value;
        // const checkingProductHVDC = productData?.includes('Asset & Workforce Management');
        // if (issueRatingData === 'Unconfirmed' && checkingHVDC && checkingPGGI && checkingProductHVDC) {
        //     return true;
        // }
        // else {
        //     return false;
        // }
        const isHVDC = this.complaintData?.areaComplaintResolution?.isHVDCComplaint;
        const issueClass = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.matrixOriginalRatingViewModel?.classFriendlyResult;
        if (isHVDC && issueClass === 'Class C') {
            return true;
        }
        else {
            return false;
        }
    }

    async onSubmit($event: Event) {
        this.issueSubmitted = true;
        this.ActionNameForComplaints = "Submit";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        // console.log("><><><><",this.issueCaptureMainForm,this.issueCapture.invalid , this.issueDescription.invalid , this.issueRating.invalid , this.additionalSubject.invalid,this.issueCaptureMainForm.invalid);
        if (this.issueCaptureMainForm.invalid) {
            this.scroller.scrollToAnchor("validation-summary-errors");
            $event.preventDefault();
        }
        else if (this.issueDescription.controls['deadline'].value != null && this.issueDescription.controls?.['deadline'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != null && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'], this.issueDescription.controls['deadline'].value)) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (!this.checkTargetDateChange() && this.issueDescription.controls?.['dateChangeReason'].value == null && this.complaintData?.header?.complaintStatus > 2) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
          this.spinner.hide();
          this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if ((this.complaintData?.header?.complaintStatus == ComplaintStatuses.Draft) && (moment.utc(this.issueDescription.controls?.['TargetResolvingDate'].value).format('YYYY-MM-DD') < moment.utc(new Date()).format('YYYY-MM-DD'))) {
            this.previousTargetResolutionDate = true;
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else {
            this.ref = this.dialogService.open(SubmitConfirmationPopupComponent,
                {
                    header: "Submit issue",
                    width: '40%',
                    position: 'top',
                    keepInViewport: true,
                    autoZIndex: true, data: { classData: this.classValueChange ? this.classValueChange : 'Class C', confidential: this.headerSection.get('isConfidential')?.value },
                    styleClass: 'submit-confirmation-popup'
                });
            this.ref.onClose.subscribe((value: any) => {
                if (value == true) {
                    this.spinner.show();
                    // console.log(this.issueCaptureMainForm);
                    const userInfo = this.sessionStorage.getData('userInfo');
                    const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                    // console.log(complaintData);
                    this.complaintDetailsService.submit(complaintData).subscribe((res) => {
                        if(res?.length > 0){
                            this.spinner.hide();
                            this.toasterService.showError('Error occured in SAP system')
                        }
                        else if(res) {
                          const complaintNumber = res?.complaintWorkflowResult?.complaintNumber;
                          this.messageService.add({
                              severity: 'success',
                              summary: 'Issue ' + complaintNumber + ' submitted'
                          });
                            this.spinner.hide();
                            this.router.navigateByUrl('mydashboard');
                        }
                    },
                        (error) => {
                            this.spinner.hide();
                            this.messageService.add({
                                severity: 'error',
                                summary: error ? error : 'Something went wrong!'
                            });
                        });
                }
            }
            );
        }

    }


    reg = /([a-zA-Z]{2}-([a-zA-Z0-9]*-)?[0-9]{1,9}[a-zA-Z]*)$/;
    isAlphaNumericCheck = function (enteredValue) {
        if (this.reg.test(enteredValue)) {
            return true;
        }
        return false;
    }

    checkBlank(content) {
        if (content == undefined || content == null) {
            return '';
        }
        else {
            return content;
        }
    }

    classValue($event) {
        this.classValueChange = $event;
        this.setFieldsValidation(this.ActionNameForComplaints);
        this.showClassValue();
    }

    isResolvingUnitSelectionRecievedToNewIssueHome:boolean;

    ResolvingUnitChange($event) {
      this.isResolvingUnitSelectionRecievedToNewIssueHome = true;
    }

    processStepIdChange($event) {
        this.processStepIdInput = $event;
    }

    //from Issue Assignment
    async assignedFromAssignment(event: any) {
        this.ActionNameForComplaints = "Assign";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        // console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueAssignment.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
            // event.preventDefault();
        }
        else if (this.issueDescription.controls['deadline'].value != null && this.issueDescription.controls?.['deadline'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != null && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'], this.issueDescription.controls['deadline'].value)) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (!this.checkTargetDateChange() && this.issueDescription.controls?.['dateChangeReason'].value == null && this.complaintData?.header?.complaintStatus > 2) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                // console.log("if is here")
                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                // console.log(complaintData);
                this.complaintDetailsService.assign(complaintData).subscribe((res) => {
                    // console.log("assign=>", res);
                    if (res) {
                        this.issueSubmitted = false;
                        this.spinner.hide();
                        this.router.navigateByUrl('mydashboard');
                    }
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
            else {
                // console.log("if is here")
                this.spinner.hide();
                this.issueSubmitted = false;
            }
        }

    }


    async returnToIssueOwnerAssignment(event: any) {
        this.ActionNameForComplaints = "ReturnToCO"
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        // console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueAssignment.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
            // event.preventDefault();
        }
        else if (this.issueDescription.controls['deadline'].value != null && this.issueDescription.controls?.['deadline'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != null && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'], this.issueDescription.controls['deadline'].value)) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (!this.checkTargetDateChange() && this.issueDescription.controls?.['dateChangeReason'].value == null && this.complaintData?.header?.complaintStatus > 2) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                this.issueSubmitted = true;
                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                // console.log(complaintData);
                this.complaintDetailsService.returnToCO(complaintData).subscribe((res) => {
                    // console.log("Return to Issue Owner Assignment=>", res);
                    if (res) {
                        this.spinner.hide();
                        this.router.navigateByUrl('mydashboard');
                    }
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
            else {
                this.spinner.hide();
                this.issueSubmitted = false;
            }
        }
    }

    async saveFromAssignment(event: any) {
        this.ActionNameForComplaints = "SaveAssignmentSectionComplaint";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        //console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueAssignment.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
            // event.preventDefault();
        }
        else if (this.issueDescription.controls['deadline'].value != null && this.issueDescription.controls?.['deadline'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != null && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'], this.issueDescription.controls['deadline'].value)) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (!this.checkTargetDateChange() && this.issueDescription.controls?.['dateChangeReason'].value == null && this.complaintData?.header?.complaintStatus > 2) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                this.issueSubmitted = true;
                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                // console.log(complaintData);
                this.complaintDetailsService.saveAssignmentSectionComplaint(complaintData).subscribe((res) => {
                    // console.log("Save Assignment Section Complaint=>", res);
                    if (res) {
                        this.headerSection?.patchValue({
                            rowVersion: res?.header?.rowversion,
                            rowVersionString: res?.header?.rowversionString
                        })
                        if (res) {
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Issue was saved successfully.'
                            });
                            this.spinner.hide();
                            //this.router.navigate(['complaint', this.complaintData.header?.id]);
                            window.location.reload();
                        }

                    }
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
            else {
                this.spinner.hide();
                this.issueSubmitted = false;
            }
        }

    }
    //from RCA section
    async resolvedFromResolution(event: any) {
        this.ActionNameForComplaints = "Resolve";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
       /* this.additionalSubject.controls['warrantyEndDate'].setValidators([Validators.required]);
        this.additionalSubject.controls['warrantyEndDate'].updateValueAndValidity();
        this.additionalSubject.controls['ManufacturingDate'].setValidators([Validators.required]);
        this.additionalSubject.controls['ManufacturingDate'].updateValueAndValidity();
        this.additionalSubject.controls['quantity'].setValidators([Validators.required]);
        this.additionalSubject.controls['quantity'].updateValueAndValidity();*/
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        // console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueCaptureMainForm.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (this.issueDescription.controls['deadline'].value != null && this.issueDescription.controls?.['deadline'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != null && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'], this.issueDescription.controls['deadline'].value)) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (!this.checkTargetDateChange() && this.issueDescription.controls?.['dateChangeReason'].value == null && this.complaintData?.header?.complaintStatus > 2) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                if (this.issueResolution.controls['ActualRUCost']?.value === '') {
                    this.toasterService.showWarning('Please fill Actual RU Cost');
                }

                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                //console.log(complaintData);
                this.complaintDetailsService.resolve(complaintData).subscribe((res) => {
                    // console.log("Save Assignment Section Complaint=>", res);
                    if (res) {
                        this.issueSubmitted = false;
                        this.spinner.hide();
                        this.router.navigateByUrl('mydashboard');
                    }
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
            else {
                this.spinner.hide();
                this.issueSubmitted = false;
            }
        }

    }
    async contatinmentToResolvedFromResolution(event: any) {
        this.ActionNameForComplaints = "ContainmentActionExecuted";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        // console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueCaptureMainForm.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (this.issueResolution.controls['WhatContainmentActionsWhereTaken'].validator && this.issueResolution.controls['WhatContainmentActionsWhereTaken'].disabled && this.issueResolution.controls['WhatContainmentActionsWhereTaken'].value == "") {
            // console.log("Disabled",this.issueResolution.controls['WhatContainmentActionsWhereTaken'].validator);
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (this.issueDescription.controls['deadline'].value != null && this.issueDescription.controls?.['deadline'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != null && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'], this.issueDescription.controls['deadline'].value)) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (!this.checkTargetDateChange() && this.issueDescription.controls?.['dateChangeReason'].value == null && this.complaintData?.header?.complaintStatus > 2) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                this.complaintDetailsService.containmentActionExecuted(complaintData).subscribe((res) => {
                    this.spinner.hide();
                    window.location.reload();
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
            else {
                this.spinner.hide();
            }
        }
    }
    async revertToNotAssignedEmailPassedFromResolution(event: any) {
        this.ActionNameForComplaints = "RevertInProcToNotAssignedStatus";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        // console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueCaptureMainForm.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (this.issueDescription.controls['deadline'].value != null && this.issueDescription.controls?.['deadline'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != null && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'], this.issueDescription.controls['deadline'].value)) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (!this.checkTargetDateChange() && this.issueDescription.controls?.['dateChangeReason'].value == null && this.complaintData?.header?.complaintStatus > 2) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                this.issueSubmitted = true;
                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                // console.log(complaintData);
                this.complaintDetailsService.revertInProcToNotAssignedStatus(complaintData).subscribe((res) => {
                    // console.log("revertInProcToNotAssignedStatus=>", res);
                    if (res) {
                        this.spinner.hide();
                        this.router.navigateByUrl('mydashboard');
                    }
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
            else {
                this.spinner.hide();
                this.issueSubmitted = false;
            }
        }
    }
    async verificationPassedFromResolution(event: any) {
        this.ActionNameForComplaints = "VerifyResolution";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
       // console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueCaptureMainForm.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (this.issueDescription.controls['deadline'].value != null && this.issueDescription.controls?.['deadline'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != null && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'], this.issueDescription.controls['deadline'].value)) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (!this.checkTargetDateChange() && this.issueDescription.controls?.['dateChangeReason'].value == null && this.complaintData?.header?.complaintStatus > 2) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                this.issueSubmitted = true;
                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                // console.log(complaintData);
                this.complaintDetailsService.verifyResolution(complaintData).subscribe((res) => {
                    this.spinner.hide();
                    window.location.reload();
                    // console.log("verifyResolution=>", res);
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
            else {
                this.spinner.hide();
                this.issueSubmitted = false;
            }
        }
    }
    async savePassedFromResolution(event: any) {
        this.ActionNameForComplaints = "SaveResolutionSectionComplaint";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        // console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueCaptureMainForm.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (this.issueDescription.controls['deadline'].value != null && this.issueDescription.controls?.['deadline'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != '' && this.issueDescription.controls?.['TargetResolvingDate'].value != null && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'], this.issueDescription.controls['deadline'].value)) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if (!this.checkTargetDateChange() && this.issueDescription.controls?.['dateChangeReason'].value == null && this.complaintData?.header?.complaintStatus > 2) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                // this.issueSubmitted = true;
                if (this.issueResolution.controls['ActualRUCost']?.value === '') {
                    this.toasterService.showWarning('Please fill Actual RU Cost');
                }

                this.issueResolution.controls['ContainmentActionsExecution']?.patchValue(null);

                this.issueResolution.controls['ContainmentActionsExecution']?.patchValue(null);

                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                // console.log(complaintData);
                this.complaintDetailsService.saveResolutionSectionComplaint(complaintData).subscribe((res) => {
                    // console.log("saveResolutionSectionComplaint=>", res);
                    if (res) {
                        this.headerSection?.patchValue({
                            rowVersion: res?.header?.rowversion,
                            rowVersionString: res?.header?.rowversionString
                        })
                        if (res) {
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Issue was saved successfully.'
                            });
                            this.spinner.hide();
                            //this.router.navigate(['complaint', this.complaintData.header?.id]);
                            window.location.reload();
                        }

                    }
                    else {
                        this.spinner.hide();
                    }
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
            else {
                this.spinner.hide();
                // this.issueSubmitted = false;
            }
        }
    }
    //closedsecion
    async completedFromClosed(event: any) {
        this.ActionNameForComplaints = "Close";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        //console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueCaptureMainForm.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.issueCapture.get('customerContactNameHidden').value?.isValid==false){
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                // console.log(complaintData);
                if (complaintData?.header?.complaintStatus == 13) {
                    this.complaintDetailsService.IssueClosedCloseAfterExecutionPending(complaintData).subscribe((res) => {
                        // console.log("IssueCompleted=>", res);
                        if (res) {
                            this.headerSection?.patchValue({
                                rowVersion: res?.header?.rowversion,
                                rowVersionString: res?.header?.rowversionString
                            })
                            if (res) {
                                this.messageService.add({
                                    severity: 'success',
                                    summary: 'Issue was saved successfully.'
                                });
                                this.spinner.hide();
                                this.issueSubmitted = false;
                                //this.router.navigate(['complaint', this.complaintData.header?.id]);
                                window.location.reload();
                            }

                        }
                    },
                        (error) => {
                            this.spinner.hide();
                            this.messageService.add({
                                severity: 'error',
                                summary: error ? error : 'Something went wrong!'
                            });
                        });
                }
                else {
                    this.complaintDetailsService.close(complaintData).subscribe((res) => {
                        // console.log("IssueCompleted=>", res);
                        if (res) {
                            this.headerSection?.patchValue({
                                rowVersion: res?.header?.rowversion,
                                rowVersionString: res?.header?.rowversionString
                            })
                            if (res) {
                                this.messageService.add({
                                    severity: 'success',
                                    summary: 'Issue was saved successfully.'
                                });
                                this.spinner.hide();
                                this.issueSubmitted = false;
                                //this.router.navigate(['complaint', this.complaintData.header?.id]);
                                window.location.reload();
                            }

                        }
                    },
                        (error) => {
                            this.spinner.hide();
                            this.messageService.add({
                                severity: 'error',
                                summary: error ? error : 'Something went wrong!'
                            });
                        });
                }

            }
            else {
                this.spinner.hide();
                this.issueSubmitted = false;
            }
        }
    }


    //Event from Effectivness Section
    async confirmedFromEffectiveness(event: any) {
        this.ActionNameForComplaints = "ClosureConfirmation";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        //console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueCaptureMainForm.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                // console.log(complaintData);
                this.complaintDetailsService.closureConfirmation(complaintData).subscribe((res) => {
                    // console.log("closureConfirmation=>", res);
                    if (res) {
                        this.headerSection?.patchValue({
                            rowVersion: res?.header?.rowversion,
                            rowVersionString: res?.header?.rowversionString
                        })
                        if (res) {
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Issue was saved successfully.'
                            });
                            this.spinner.hide();
                            //this.router.navigate(['complaint', this.complaintData.header?.id]);
                            window.location.reload();
                        }

                    }
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
            else {
                this.spinner.hide();
                this.issueSubmitted = false;
            }
        }
    }
    async reopenIssueEventFromEffectiveness(event: any) {
        this.ActionNameForComplaints = "ClosureConfirmation";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        // console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueCaptureMainForm.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                if (this.issueResolution.controls['ActualRUCost']?.value === '') {
                    this.toasterService.showWarning('Please fill Actual RU Cost');
                }

                this.issueSubmitted = true;
                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                // console.log(complaintData);
                this.complaintDetailsService.reopenIssue(complaintData).subscribe((res) => {
                    // console.log("reopenIssue=>", res);
                    if (res) {
                        this.spinner.hide();
                        this.router.navigateByUrl('mydashboard');
                    }
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
            else {
                this.spinner.hide();
                this.issueSubmitted = false;
            }
        }
    }
    async saveFromEffectiveness(event: any) {
        this.ActionNameForComplaints = "ClosureConfirmation";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        // console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueCaptureMainForm.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                this.issueSubmitted = true;
                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                // console.log(complaintData);
                this.complaintDetailsService.saveEffectivenessSectionComplaint(complaintData).subscribe((res) => {
                    // console.log("saveEffectivenessSectionComplaint=>", res);
                    if (res) {
                        this.headerSection?.patchValue({
                            rowVersion: res?.header?.rowversion,
                            rowVersionString: res?.header?.rowversionString
                        })
                        if (res) {
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Issue was saved successfully.'
                            });
                            this.spinner.hide();
                            //this.router.navigate(['complaint', this.complaintData.header?.id]);
                            //Task 10217: Effectiveness & Sustainability Section, redirect to dashboard on click of save button.
                             //this.router.navigateByUrl('mydashboard');
                            window.location.reload();
                        }

                    }
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
            else {
                this.spinner.hide();
                this.issueSubmitted = false;
            }
        }
    }

    accordionCericlUpdate(status: string) {
        if (this.complaintId) {
            let complaintStatusForCircle = this.complaintData?.header?.complaintStatus;
            switch (status) {
                case 'submitted':
                    if (complaintStatusForCircle > 2) {
                        return 'rgb(0,128, 55)';
                    } else {
                        return 'rgb(255, 210, 55)';
                    }
                case 'notAssigned':
                    if (complaintStatusForCircle > 3) {
                        return 'rgb(0,128, 55)';
                    } else {
                        return 'rgb(255, 210, 55)';
                    }
                case 'inProcess':
                    if (complaintStatusForCircle > 6) {
                        return 'rgb(0,128, 55)';
                    } else {
                        return 'rgb(255, 210, 55)';
                    }
                case 'closed': if (complaintStatusForCircle > 7) {
                    if (complaintStatusForCircle == 13 || complaintStatusForCircle == 11 || complaintStatusForCircle == 12) {
                        return 'rgb(255, 210, 55)';
                    } else {
                        return 'rgb(0,128, 55)';
                    }
                } else {
                    return 'rgb(255, 210, 55)';
                }
                case 'effective': if (complaintStatusForCircle == 8) {
                    return 'rgb(0,128, 55)';
                } else {
                    return 'rgb(255, 210, 55)';
                }
            }
        }
        return '#f2f2f2'
    }

    getComplaintSectionLastDate(inputAction: string) {
        if (this.ComplaintLogDates?.length > 0) {
            //   const CloseDates = this.ComplaintLogDates?.filter(
            //     (item) =>{
            //      if(item.subject == inputAction){
            //        return true;
            //      }
            //      return false;
            //     }
            //  );
            const CloseDates = this.ComplaintLogDates?.filter(function (x) {
                return x.subject.toLowerCase().indexOf(inputAction.toLowerCase()) > -1;
            })

            return CloseDates[0]?.date;
        }
        return null;
    }

    getComplaintSectionAssignedTo(inputAction: string) {
        if (this.ComplaintLogDates?.length > 0) {
            //   const CloseDates = this.ComplaintLogDates?.filter(
            //     (item) =>{
            //      if(item.subject == inputAction){
            //        return true;
            //      }
            //      return false;
            //     }
            //  );
            const CloseDates = this.ComplaintLogDates?.filter(function (x) {
                return x.subject.toLowerCase().indexOf(inputAction.toLowerCase()) > -1;
            })

            return CloseDates[0]?.email;
        }
        return null;
    }

    // Issue Closed Save
    async saveFromIssueClosed(event: any) {
        this.ActionNameForComplaints = "SaveComplaintCloseSection";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        //console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueCaptureMainForm.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                this.issueSubmitted = true;
                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                // console.log(complaintData);
                this.complaintDetailsService.saveIssueClosed(complaintData).subscribe((res) => {
                    // console.log("Saveissueclosed=>", res);
                    if (res) {
                        this.headerSection?.patchValue({
                            rowVersion: res?.header?.rowversion,
                            rowVersionString: res?.header?.rowversionString
                        })
                        if (res) {
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Issue was saved successfully.'
                            });
                            this.spinner.hide();
                            //this.router.navigate(['complaint', this.complaintData.header?.id]);
                            window.location.reload();
                        }

                    }
                    else {
                        this.spinner.hide();
                    }
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
        }
    }
    //closedsecion
    //Issue Closed Execution Pending button functionality (External)
    async completedExecutionPending(event: any) {
        this.ActionNameForComplaints = "CloseAfterExecutionPending";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        //console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueCaptureMainForm.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.issueCapture.get('customerContactNameHidden').value?.isValid==false){
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                this.issueSubmitted = true;
                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                // console.log(complaintData);
                this.complaintDetailsService.close(complaintData).subscribe((res) => {
                    // console.log("IssueCompletedExecutionPending=>", res);
                    if (res) {
                        this.headerSection?.patchValue({
                            rowVersion: res?.header?.rowversion,
                            rowVersionString: res?.header?.rowversionString
                        })
                        if (res) {
                            this.messageService.add({
                                severity: 'success',
                                summary: 'Issue was saved successfully.'
                            });
                            this.spinner.hide();
                            //this.router.navigate(['complaint', this.complaintData.header?.id]);
                            window.location.reload();
                        }

                    }
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
            else {
                this.spinner.hide();
                this.issueSubmitted = false;
            }
        }
    }


    async returnToResolutionOwner(event: any) {
        this.ActionNameForComplaints = "CoordinatorRequestsReturnToRO";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        // console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueCaptureMainForm.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                this.issueSubmitted = true;
                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                // console.log(complaintData);
                this.complaintDetailsService.returnToRO(complaintData).subscribe((res) => {
                    // console.log("IssueCompleted=>", res);
                    if (res) {
                        this.spinner.hide();
                        this.router.navigateByUrl('mydashboard');
                    }
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
            else {
                this.spinner.hide();
                this.issueSubmitted = false;
            }
        }
    }

    setButtonVisibility(statusCodes: any) {
        const currentComplaintStatus = this.headerSection.get('complaintStatusId')?.value;
        return this.buttonVisibilityService.setButtonVisibility(statusCodes, currentComplaintStatus);
    }

    async onSubmitSapQ(event) {
        this.issueSubmitted = true;
        this.ActionNameForComplaints = "SubmitToSAP";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        // if (this.issueCaptureMainForm.controls?.['issueCaptureForm'].invalid || this.issueCaptureMainForm.controls?.['issueDescriptionForm'].invalid || this.issueCaptureMainForm.controls?.['ratingForm'].invalid || this.issueCaptureMainForm.controls?.['additionalSubjectForm'].invalid) {
        if (this.issueCaptureMainForm.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else {
            this.spinner.hide();
            const userInfo = this.sessionStorage.getData('userInfo');
            // const workCenterField = this.additionalSubject.controls['defectTypeHiddenField']?.value;
            // const defenceType = this.issueDescription.controls['workCentreHidden']?.value;
            // const sapField = {
            //     "sapDefectCause": {
            //         "defectcausecodegroup": defenceType?.causeCodeGroup ? defenceType?.causeCodeGroup:null,
            //         "codegroupdefecttype": defenceType?.defectType? defenceType?.defectType: null,
            //         "defectCause": defenceType?.causeCode? defenceType?.causeCode: null,
            //         "defectType": defenceType?.defectType? defenceType?.defectType: null,
            //         "causeCodeGroupText": defenceType?.causeCodeGroupText? defenceType?.causeCodeGroupText: null,
            //         "defectCodeGroupText": defenceType?.defectCodeGroupText? defenceType?.defectCodeGroupText: null,
            //         "defectTypeText": defenceType?.defectTypeText? defenceType?.defectTypeText: null,
            //         "defectCauseTextField": defenceType?.defectTypeText? defenceType?.defectTypeText: null,
            //         "defectCauseDefectCauseCodeGroup": null,
            //         "DefectTypeandDefectCodeGroupType": {
            //             "lowestGenericFailureModeNode": null,
            //             "validation": null,
            //             "pgCode": null,
            //             "id": defenceType?.plant? defenceType?.plant: null,
            //             "identification": {
            //                 "id": null,
            //                 "geid": null,
            //                 "email": null,
            //                 "cid": null,
            //                 "giS_GUID": null,
            //                 "email_Alias": null
            //             },
            //             "displayLabel": workCenterField?.displayLabel? workCenterField?.displayLabel: null,
            //             "description": workCenterField?.description? workCenterField?.description: null,
            //             "tooltipInfo": workCenterField?.displayLabel?.tooltipInfo? workCenterField?.displayLabel?.tooltipInfo: null,
            //             "isValid": true,
            //             "parentId": workCenterField?.parentId? workCenterField?.parentId: null,
            //             "parentName": workCenterField?.parentName? workCenterField?.parentName: null,
            //             "buCode": workCenterField?.buCode? workCenterField?.buCode: null
            //         }
            //     },
            //     "sapComplaintInfo": {
            //         "id": null,
            //         "qnNumber": "",
            //         "isQnNumberGenerated": false,
            //         "materialPlant": "string",
            //         "projectNumber": "P12347",
            //         "projectName": "P Name EVE",
            //         "actualCost": "0",
            //         "copqCode": "string",
            //         "copqCodeGroup": "string",
            //         "sapContactId": "string",
            //         "workCentre": defenceType?.workcentre,
            //         "plant": defenceType?.plant,
            //         "isActive": true,
            //         "WorkCentreandPlant": {
            //             "displayLabel": "QIL9999",
            //             "tooltipInfo": "string",
            //             "isValid": true,
            //             "identification": null
            //         },
            //         "sapLockStatus": true
            //     },
            //     "whereWasTheIssueDetectedId": defenceType?.whereWastheIssueDetectedBalID,
            //     "sapCustomerContactEmail": "vabnasd@hm.com"
            // }
            //this.additionalSubject.controls['defectWorkCenterHidden'].patchValue(sapField);
            this.additionalSubject.patchValue({
                isQnNumberGeneratedCheck: true
            });
            const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
            this.spinner.show();
            this.complaintDetailsService.submitToSAP(complaintData).subscribe((res) => {
                // console.log('completed');
                if(res?.length > 0){
                    this.spinner.hide();
                    this.issueSubmitted = false;
                    this.toasterService.showError('Error occured in SAP system')
                }
                else{
                    this.spinner.hide();
                    this.issueSubmitted = false;
                    window.location.reload();
                }
            },
                (error) => {
                    this.spinner.hide();
                    this.messageService.add({
                        severity: 'error',
                        summary: error ? error : 'Something went wrong!'
                    });
                });
        }
    }

    showClassValue() {
        if (this.complaintData != undefined) {
            let userAccessData;
            this.authorizationService.userAccessDetail.subscribe(async detail => {
                if (detail) {
                    userAccessData = detail;
                    //  console.log("home rating>>>>>",this.complaintData.areaComplaintResolution.matrixCurrentRatingViewModel.class, this.complaintData.areaComplaintResolution.matrixCurrentRatingViewModel.classFriendlyResult);
                    let currselectedRole = userAccessData?.roleCode;
                    let matrixClassABIC = this.complaintData?.areaComplaintResolution.matrixCurrentRatingViewModel.class;
                    let matrixClass = this.complaintData?.areaComplaintResolution.matrixCurrentRatingViewModel.classFriendlyResult;
                    let verification = this.complaintData?.areaComplaintResolution.matrixCurrentRatingViewModel.verificationInformation.status;
                    let complaintStatus = this.complaintData?.header.complaintStatus;
                    let isRoleExist = false;
                    if (matrixClassABIC == MatrixClass.A || verification != 'Unconfirmed') {
                        this.headerSection.patchValue({
                            complaintCurrentRating: matrixClass
                        })
                    }
                    if ((matrixClassABIC == MatrixClass.B || matrixClassABIC == MatrixClass.I || matrixClassABIC == MatrixClass.C) && (currselectedRole == RoleCode.Global_CCRP_Process_Owner || currselectedRole == RoleCode.Support_Desk || currselectedRole == RoleCode.Div_Process_Owner || currselectedRole == RoleCode.Pg_Process_Owner || currselectedRole == RoleCode.Bu_Process_Owner || currselectedRole == RoleCode.Hub_Process_Owner || currselectedRole == RoleCode.Complaint_Owner)) {
                        this.headerSection.patchValue({
                            complaintCurrentRating: matrixClass
                        })
                        isRoleExist = true;
                    }

                    if ((verification == 'Unconfirmed' || verification == null) && isRoleExist == false && matrixClassABIC != MatrixClass.A) {
                        this.headerSection.patchValue({
                            complaintCurrentRating: "Unconfirmed"
                        })
                    }
                    if (matrixClassABIC == MatrixClass.C) {
                        if ((complaintStatus == ComplaintStatuses.New || complaintStatus == ComplaintStatuses.Draft || complaintStatus == ComplaintStatuses.NotAssigned) && isRoleExist == false) {
                            this.headerSection.patchValue({
                                complaintCurrentRating: "Unconfirmed"
                            })
                        }
                        else {
                            this.headerSection.patchValue({
                                complaintCurrentRating: "Class C"
                            })
                        }
                    }
                    if (matrixClassABIC == MatrixClass.C && complaintStatus == ComplaintStatuses.NotAssigned) {
                        let complaintId = this.complaintData?.header?.id;
                        this.OriginalRatingService.getIsManualChange(complaintId).subscribe(
                            (response) => {
                                if (response == true) {
                                    this.headerSection.patchValue({
                                        complaintCurrentRating: "Class C"
                                    })
                                }
                            },
                            (err) => {
                                this.toasterService.showError('Sorry an error occured');
                            }
                        )
                    }
                }
            });


        }
    }


    async coReturnToResolutionOwner(event: any) {
        this.ActionNameForComplaints = "CoordinatorRequestsReturnToRO";
        await this.setFieldsValidation(this.ActionNameForComplaints);
        this.spinner.show();
        this.issueSubmitted = true;
        this.issueCaptureEnable = true;
        // console.log("<><><>", this.issueCaptureMainForm);
        if (this.issueCaptureMainForm.invalid) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
        }
        else if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
            this.spinner.hide();
            this.scroller.scrollToAnchor("validation-summary-errors");
          }
        else {
            this.issueCaptureEnable = false;
            if (event.submitted) {
                this.issueSubmitted = true;
                const userInfo = this.sessionStorage.getData('userInfo');
                const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
                // console.log(complaintData);
                this.complaintDetailsService.coordinatorRequestsReturnToRo(complaintData).subscribe((res) => {
                    // console.log("revertInProcToNotAssignedStatus=>", res);
                    if (res) {
                        this.spinner.hide();
                        this.router.navigateByUrl('mydashboard');
                    }
                },
                    (error) => {
                        this.spinner.hide();
                        this.messageService.add({
                            severity: 'error',
                            summary: error ? error : 'Something went wrong!'
                        });
                    });
            }
            else {
                this.spinner.hide();
                this.issueSubmitted = false;
            }
        }
    }

    GetCoordinatorsForResolvingUnit(processStepData: any) {
        let processStepId = processStepData;
        let resolvingUnitData = this.additionalSubject.controls['resolvingUnitArray']?.value;
        let resolvingUnitId = resolvingUnitData.id;

        if (processStepId && resolvingUnitId) {
            let highImpact = this.issueRating.controls['HasHightImpactOnABB']?.value;
            let matrixClass = this.issueRating.controls['impactRating']?.value;
            const resolvingProcessId = { resolvingUnitId: resolvingUnitId ? resolvingUnitId : '', processStepId: processStepId ? processStepId : '', isHightImpact: highImpact ? highImpact : false, matrixClass: matrixClass ? matrixClass : '' }
            this.resolvingUnitService.getCoOrdinator(resolvingProcessId).subscribe((response) => {
                if (response) {
                    if (processStepId && response.isAccountable == true) {
                        // Check existing HVDC Complaint and Process Step Id for AccountableVerifiedUsers
                        let checkExistingHVDCComplaint = JSON.parse(localStorage.getItem('checkHVDCComplaint'));
                        if (checkExistingHVDCComplaint) {
                            if (checkExistingHVDCComplaint.IsHVDCComplaint != false && checkExistingHVDCComplaint.processStepId) {
                                if (checkExistingHVDCComplaint.processStepId != processStepId) {
                                    // Need to clear issue Assignment Accountable verified users
                                }
                            }
                        }

                        let setHVDCComplaint = { IsHVDCComplaint: true, processStepId: processStepId };
                        localStorage.setItem('checkHVDCComplaint', JSON.stringify(setHVDCComplaint));
                        this.additionalSubject.patchValue({
                            hiddenIsHVDCComplaint: true
                        })
                    } else {
                        var setHVDCComplaint = { IsHVDCComplaint: false, processStepId: processStepId };
                        localStorage.setItem('checkHVDCComplaint', JSON.stringify(setHVDCComplaint));
                        this.additionalSubject.patchValue({
                            hiddenIsHVDCComplaint: false
                        })
                    }
                    this.additionalSubject.patchValue({
                        Coordinators: response?.data
                    });
                }
            })
        }
    }

    countryCheck() {
        let customerDataValue: any;
        if (this.issueCapture.get('CustomerType').value == 'Internal') {
            customerDataValue = this.issueCapture.get('affectedCustomerHidden').value;
        }
        if (this.issueCapture.get('CustomerType').value == 'External') {
            customerDataValue = this.issueCapture.get('customerNameHidden').value;
        }
        const guID = customerDataValue?.gisIdentifier;
        if (guID) {
            this.spinner.show();
            this.countryService.setCountryFillIn(guID).subscribe((response) => {
                if (response) {
                    let countries = this.localStorage.getFromLocalStorage('mdregion');
                    this.issueDescription.patchValue({
                        CountryCodeWhereTheIssueHappen: countries.filter(country => country?.id?.toLowerCase() == response?.countryCode?.toLowerCase())[0],
                    });
                }

                this.spinner.hide();
                this.toasterService.showSuccess('Country where the issue happened updated successfully');
            })
        }
        else {
            this.toasterService.showWarning('Please select customer to use "Fill in" button')
        }
    }

    changeJustificationReadOnly($event) {
        this.justificationReadOnly = $event;
    }

    changeReviewRequiredId($event) {
       // console.log("<><><><>", $event);
        this.reviewRequired_Id = $event;
    }

    onSetComplaintLock(){
        if(this.complaintData?.sapComplaintInfo?.sapLockStatus == false && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
            const request = {
                "complaintId": this.complaintData?.header?.id
            }

            this.complaintDetailsService.setComplaintLock(request).subscribe((resp)=>{
                if(resp){

                }
            }, (error)=>{
                this.toasterService.showError(error ? error : 'Something went wrong!')
            })
        }
    }

    onUpdateComplaintLock(){
        const request = {
            "complaintId": this.complaintData?.header?.id
        }

        this.complaintDetailsService.updateComplaintLock(request).subscribe((resp)=>{
            if(resp){

            }
        }, error =>{
            this.toasterService.showError(error ? error : 'Something went wrong!')
        })
    }

    onDeleteComplaintLock(){
        const request = {
            "complaintId": this.complaintData?.header?.id
        }

        this.complaintDetailsService.deleteComplaintLock(request).subscribe((resp)=>{
            if(resp){

            }
        }, error =>{
            this.toasterService.showError(error ? error : 'Something went wrong!')
        })
    }


    openSessionTimeOutDialog(): any {
        if(this.complaintData?.sapComplaintInfo?.sapLockStatus == false && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
            this.onDeleteComplaintLock();

            this.ref = this.dialogService.open(ComplaintLockWarningComponent, {
                header: 'Complaint Lock Warning',
                width: '50%',
                position: 'center',
                styleClass: 'system-timeout-modal',
                // data: { complaintId : this.complaintData?.header?.id }
            });

            this.ref.onClose.subscribe((resp) => {
                if (resp.result == 'ok') {
                    this.onSetComplaintLock();
                }
                else if(resp.result == 'cancel'){
                    this.router.navigate(['mydashboard'])
                }
            })
        }
    }

  async rejectComplaint(event: any) {

    this.spinner.show();
    if (event.submitted) {
      this.issueSubmitted = true;
      const userInfo = this.sessionStorage.getData('userInfo');
      const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
      // console.log(complaintData);
      this.complaintDetailsService.reject(complaintData).subscribe((res) => {
        // console.log("IssueCompletedExecutionPending=>", res);
        if (res) {
          this.headerSection?.patchValue({
            rowVersion: res?.header?.rowversion,
            rowVersionString: res?.header?.rowversionString
          })
          if (res) {
            this.messageService.add({
              severity: 'success',
              summary: 'Issue was rejected successfully.'
            });
            this.spinner.hide();
            //this.router.navigate(['complaint', this.complaintData.header?.id]);
            window.location.reload();
          }

        }
      },
        (error) => {
          this.spinner.hide();
          this.messageService.add({
            severity: 'error',
            summary: error ? error : 'Something went wrong!'
          });
        });
    }
    else {
      this.spinner.hide();
      this.issueSubmitted = false;
    }
  }

  async VerifySerialFunction(event:any){
    this.ActionNameForComplaints = event.actionName;
    await this.setFieldsValidation(this.ActionNameForComplaints);
    this.spinner.show();
    this.issueSubmitted = true;
    if(this.additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && this.additionalSubject.controls['abbProductSerialNo']?.value) {
      this.spinner.hide();
      this.scroller.scrollToAnchor("validation-summary-errors");
    }
  }

  async VerifyResolutionByBuProcessOwner(event:any){
    this.spinner.show();
    if (event.submitted) {
      this.issueSubmitted = true;
      const userInfo = this.sessionStorage.getData('userInfo');
      const complaintData = this.complaintDetailsService.getComplainData(this.issueCaptureMainForm, userInfo);
      // console.log(complaintData);
      this.complaintDetailsService.verifyResolutionByBUProcessOwner(complaintData).subscribe((res) => {
        // console.log("IssueCompletedExecutionPending=>", res);
        if (res) {
          this.headerSection?.patchValue({
            rowVersion: res?.header?.rowversion,
            rowVersionString: res?.header?.rowversionString
          })
          if (res) {
            this.messageService.add({
              severity: 'success',
              summary: 'Issue verified successfully.'
            });
            this.spinner.hide();
            //this.router.navigate(['complaint', this.complaintData.header?.id]);
            window.location.reload();
          }

        }
      },
        (error) => {
          this.spinner.hide();
          this.messageService.add({
            severity: 'error',
            summary: error ? error : 'Something went wrong!'
          });
        });
    }
    else {
      this.spinner.hide();
      this.issueSubmitted = false;
    }
  }

  openStatusUpdate(){
    const complaintNumber = this.complaintData?.header?.complaintNumber;
                { this.dialogService.open(StatusUpdatePopupComponent,
                     { header: "Status Update",
                        styleClass: 'status-update-name-pop',
                        width:'85%',
                        position: 'top',
                        data: { complaintNumber }
                      }); }
            // });
  }

  checkComplaintStatusForActionBoard(){
    const currentComplaintStatus = this.headerSection.get('complaintStatusId')?.value;
    let inprocessComplaintStatusIDs = [4,5,11,12];
    return !inprocessComplaintStatusIDs.includes(currentComplaintStatus);
  }

  getActionBoardTooltip(){
    let complaintStatusEligible = this.checkComplaintStatusForActionBoard();
    if(complaintStatusEligible){
      return 'Board gets enabled when CCRP attains In Process state';
    }else{
      return 'Please click here to navigate to Action Board';
    }
  }

openActionBoard(){
    let selectedRoleName;
    this.authorizationService.userAccessDetail.subscribe(async detail => {
      this.userAccessData = detail;
      selectedRoleName = this.complaintData?.complaintDataAccess?.complaintRoles.filter(role=> role?.id==this.userAccessData['roleCode']);
    });
    this.router.navigate([]).then(result => { window.open('/issues/' + this.complaintData?.header?.complaintNumber + `/${selectedRoleName[0]?.name}/actionplan`, '_blank'); });
  }


}
