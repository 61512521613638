import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import { AdminProcessOwnerService } from 'src/app/services/admin-process-owner.service';
import { AdminAccountablePersonService } from 'src/app/services/admin-accountable-person.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';

import { NgxSpinnerService } from 'ngx-spinner';
import { sortBy, cloneDeep } from 'lodash';
import { PrimeNGConfig, TreeNode } from 'primeng/api';
import { AdminAccountableTreeAddPopupComponent } from '../admin-accountable-tree-add-popup/admin-accountable-tree-add-popup.component';
import { AdminAccountableEditPopupComponent } from '../admin-accountable-edit-popup/admin-accountable-edit-popup.component';
import * as _ from 'lodash';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { MdRegionService } from 'src/app/services/md-region.service';

@Component({
  selector: 'app-accountable-person',
  templateUrl: './accountable-person.component.html',
  styleUrls: ['./accountable-person.component.css', '../../../../../../src/styles.css']
})
export class AccountablePersonComponent implements OnInit {
  @Output() sendBuCode: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadResults: EventEmitter<any> = new EventEmitter<any>();
  @Input('accountablesearch') accountablesearch: any;
  radioChange: any;
  radioRuassociation: boolean = null;
  division: any;
  bu: any;
  loading: boolean = false;
  acccounableForm!: FormGroup;
  country: any=[];
  pg: any;
  regions: any;
  cities: any;
  accButtonText = 'Expand All';
  private _isExpanded = false;
  expandIndex: any = [1];
  addTooltipInfo = "Add Accountable Person";
  editTooltipInfo = "View/Edit Accountable Person";
  googlemap = "Google Map View"
  nodeexpand = "Show nodes"
  btnColorChange0 = true;
  btnColorChange1 = false;
  btnColorChange2 = false;
  btnColorChange3 = true;
  btnColorChange4 = false;
  btnColorChange5 = false;
  BUHubUsage: any;
  radioPOAssignedStatus: boolean = null;
  radioHubStatus: boolean = true;
  filteredHubDetails: any;
  checkonmine: boolean = false;
  checkruwithoutactive: boolean = false;
  hubPersonInfo: any;
  activityDropdownData: any;
  getCityList: any;
  city!: string;
  ruPersonInfo: Object;
  ruProcessOwnerGeids: Array<any> = [];
  rucoordinatorGeids: Array<any> = [];
  ruFinallGeids: Array<any> = [];
  filteredResolvingUnitDetails: any;
  filteredprocessownersDetails: any;
  rupersonsinfo: any;
  processOwner: any;
  employeeInfo: any;
  processStep: any;
  files1: any = [];
  selectedNode!: TreeNode;
  ref: DynamicDialogRef
  ruid: any = [];
  totalRecords: number;
  filterSearchFinalRecord: any;
  pageSize = 10;
  itemsPerPage = 10;
  itemsToPresent: any = [];
  itemUnits: any = [];
  itemLocations: any = [];
  criteria: any = [];
  region: any = [];
  clicked:boolean=false;
  showrumessage:boolean=false;
  noResultInfo: any;
  noResult = false;
  admin_ru_details_intransitionperiod_tooltip = "Resolving Unit is in Transition Period and will be deleted soon. Please contact Masterdata for more details.";
  regionCopy: any;
  countryCopy: any;
  getCityListCopy: any;

  // pagenation
  currentPage = 1;
  pagesCount = 0;
  numberOfCardsPerPage = 10;
  hubDetailsAsPerPagination: any = [];
  updatedFilteredDetails: any = [];
  pagecard: boolean = false;

  constructor(private fb: FormBuilder,
    private resolvingUnitService: ResolvingUnitServiceService,
    private adminProcessOwnerService: AdminProcessOwnerService,
    private adminAccountablePersonService: AdminAccountablePersonService,
    private readonly dialogRef: DynamicDialogRef,
    private spinner: NgxSpinnerService,
    private dialogService: DialogService,
    private primengConfig: PrimeNGConfig,
    private breadcrumService: BreadcrumbService,
    public config: DynamicDialogConfig,
    private sessionStorage: SessionStorageServiceService,
    private lclStorageService: LocalStorageServiceService,
    private mdRegion:MdRegionService) {
    this.accountablesearch = this.config.data;
  }


  ngOnInit(): void {



    this.breadcrumService.menuItems = [

      { label: 'ADMIN PANEL', routerLink: 'admin' },

      { label: 'ACCOUNTABLE PERSON DASHBOARD' }

    ];
    this.acccounableForm = this.fb.group({
      'business': [''],
      'country': [''],
      'hubProcessOwner': [''],
      'bu': [''],
      'belongCountries': [''],
      'isAnyPOAssigned': [''],
      'pg': [''],
      'hubStatus': [''],
      'regions': [''],
      'employee': [''],
      'filteredSubstitude': [''],
      'city': [''],
      'activity': ['']
    })
    this.resolvingUnitService.getDivision().subscribe((response) => {
      if (response) {
        this.division = response;
        this.acccounableForm.patchValue({
          'business': this.division[0]['key']
        })
      }
    })
    this.acccounableForm.get('business').valueChanges.subscribe((res) => {
      if (res) {
        this.resolvingUnitService.getBuList(res).subscribe((response) => {
          if (response) {
            this.bu = response.data;
            this.acccounableForm.patchValue({
              // 'bu': this.bu[3]['key']
              'bu': this.bu.filter((obj)=> { if(obj.key === 'PGGI') return obj.key})[0]['key']
            })
          }
        })
      }
    })
    this.acccounableForm.get('bu').valueChanges.subscribe((res) => {
      if (res) {
        this.resolvingUnitService.getBU(this.acccounableForm.get('business').value, res).subscribe((response:any) => {
          if (response) {
            this.pg = response.filter(c=> ((c.key === "2215")||(c.key=== "2222")));
          }
        })
      }
    })
    // this.adminProcessOwnerService.getRegions().subscribe((response) => {
    //   this.regions = response;
    // })
    // this.adminProcessOwnerService.getRegions().subscribe((response:any) => {
    //   this.regions = response;
    //   this.regions.map((bu)=>{
    //     bu.value = '(' + bu?.key + ') - ' + bu?.value;
    //   });

    // })
    this.mdRegion.regions().then((response)=>{
      this.regions = response;
      this.regions.map((bu)=>{
        bu.value = '(' + bu?.id + ') - ' + bu?.name;
        bu.key = bu.id;
        bu.name = bu.name;
      });
      this.regionCopy = this.regions;
    })
    const allRegion = this.lclStorageService.getFromLocalStorage("mdregion_20200117");
    this.country = allRegion?.countries?.map((co) => ({id: co.id, name: '(' + co.id + ') - ' + co.name, rCode: co.rCode}));
    this.getCityList = allRegion?.cities?.map((e) => ({id: e.id, name: e.name + ' Region: ' + e.rCode + ', Country: ' + e?.cName + ' (' + e?.cCode + ')', cCode: e.cCode, cName: e.cName, rCode: e.rCode}))
    this.countryCopy = this.country;
    this.getCityListCopy = this.getCityList;

    // this.resolvingUnitService.getAllCountry().subscribe((response) => {
    //   this.country = response;

    // console.log(this.country,'country')

    // })

    // this.resolvingUnitService.getAllCountry().subscribe((response) => {
    //   this.country = response;
    //   console.log(this.country,'country')
    // })

    // this.resolvingUnitService.getAllCountry().subscribe((response) => {
    //     this.country = response;
    //     this.country = this.country.map((co) => ({id: co.id, name: '(' + co.id + ')  ' + co.name, rCode: co.rCode}));
    //   })

    this.acccounableForm.get('regions').valueChanges.subscribe((response) => {
      this.acccounableForm.controls['country'].reset();
      this.acccounableForm.controls['city'].reset();
      if (response) {
        // this.adminProcessOwnerService.getcountry(response).subscribe((response) => {
        //   if (response) {
        //     this.country = response;
        //     this.country = this.country.map((co) => ({id: co.key, name: '(' + co.key + ')  ' + co.value}));
        //   }
        // })
        let filteredCountries: any = [];
        this.countryCopy.filter((obj)=>{
          if(obj.rCode === response){
            filteredCountries.push(obj);
          }
        })
        this.country = filteredCountries;

        // this.resolvingUnitService.getAllCitybyresion(response).subscribe((cities) => {
        //   if (cities) {
        //     this.getCityList = cities;
        //     this.getCityList = this.getCityList.map((e) => ({id: e.id, name: e.name + ' Region: ' + e.rCode + ', Country: ' + e?.name + ' (' + e?.cCode + ')'}))
        //   }
        // });
        let filteredCities: any = [];
        this.getCityListCopy.filter((obj)=>{
          if(obj.rCode === response){
            filteredCities.push(obj);
          }
        })
        this.getCityList = filteredCities;
      }
      else{
        this.country = this.countryCopy;
        this.getCityList = this.getCityListCopy;
      }

    })



    this.acccounableForm.get('country')?.valueChanges.subscribe((newValue) => {
      this.acccounableForm.controls['city'].reset();
      const countryCode = newValue;
      if (countryCode) {
        // this.resolvingUnitService.getAllCity(countryCode).subscribe((cities) => {
        //   if (cities) {
        //     this.getCityList = cities;
        //     this.getCityList = this.getCityList.map((e) => ({id: e.id, name: e.name + ' Region: ' + e.rCode + ', Country: ' + e?.name + ' (' + e?.cCode + ')'}))
        //   }
        // });
        let filteredCities: any = [];
        this.getCityListCopy.filter((obj)=>{
          if(obj.cCode === countryCode){
            filteredCities.push(obj);
          }
        })
        this.getCityList = filteredCities;
      }
      else if(this.acccounableForm.get('regions').value){
        let filteredCities: any = [];
        this.getCityListCopy.filter((obj)=>{
          if(obj.rCode === this.acccounableForm.get('regions').value){
            filteredCities.push(obj);
          }
        })
        this.getCityList = filteredCities;
      }
      else{
        this.getCityList = this.getCityListCopy;
      }
    })

    this.resolvingUnitService.getActivity('1').subscribe(
      (response) => {
        this.activityDropdownData = response;
      }
    )

    this.acccounableForm.controls['business'].disable();
    this.acccounableForm.controls['bu'].disable();
  }


  // clearcountry(event) {
  //   const unselectedGuids = event;
  //   this.getCityList=null;

  // }

  public get isExpanded() {

    return this._isExpanded;

  }
  public set isExpanded(value: boolean) {
    this._isExpanded = value;
  }
  toggleAllAccordions() {

    if (this.expandIndex.length == 0) {
      this.expandIndex = [0, 1];
    } else {
      this.expandIndex = [];
    }
    this.accButtonText = this.expandIndex.length ? 'Collapse All' : 'Expand All';
  }
  getCities() {
    let countyId = this.acccounableForm.controls['country'].value;
    this.resolvingUnitService.getAllCity(countyId).subscribe((cities) => {
      if (cities) {
        this.getCityList = cities;
      }
    });
  }
  getsearch() {
    this.showrumessage=false;
    let formatData = {
      "division": "PG",
      "bu": this.acccounableForm.controls['bu'].value ? this.acccounableForm.controls['bu'].value : "PGGI",
      // "pg": this.acccounableForm.controls['pg'].value==""?null:this.adminForm.controls['pg'].value,
      // "countries": [this.checkBlank(this.acccounableForm.controls['country'].value)],this.adminForm.controls['country'].value==""?null:this.adminForm.controls['country'].value objcountry,
      "pg": this.acccounableForm.controls['pg'].value ? this.acccounableForm.controls['pg'].value : null,
      // "countries": this.acccounableForm.controls['country'].value ? this.acccounableForm.controls['country'].value : null,
      "countries": (this.acccounableForm.controls['regions'].value && this.acccounableForm.controls['country'].value == null) ? this.returnCountriesArray(this.country) : [this.checkBlank(this.acccounableForm.controls['country'].value)],
      "employee": this.acccounableForm.controls['employee'].value ? this.acccounableForm.controls['employee'].value : null,
      // "city":  this.checkBlank(this.acccounableForm.controls['city'].value),
      "city": this.acccounableForm.controls['city'].value ? this.acccounableForm.controls['city'].value : null,

      // "activity": this.checkBlank(this.acccounableForm.controls['activity'].value),
      "activity": this.acccounableForm.controls['activity'].value ? this.acccounableForm.controls['activity'].value : null,
      "isGlobal": this.radioPOAssignedStatus,
      "ruPersonType": null,
      "ruPersonAssigned": this.radioHubStatus,
      "active": true,
      "onlyMine": this.checkonmine,
      "withoutActivePeople": this.checkruwithoutactive,
      // "currentUser": "9X70827151"
      "currentUser":this.sessionStorage.getData('userInfo').geid
    }
    this.spinner.show();
    this.adminAccountablePersonService.getAccountbleSearchDetails1(formatData).then((res: any) => {
      this.totalRecords = res.ruInfo.length;
      this.accountablesearch = res.ruInfo
      this.updatedFilteredDetails = this.accountablesearch;


      if (this.accountablesearch == '' || this.accountablesearch == null) {
        this.noResult = true;
        this.noResultInfo = 'No results found';
        this.spinner.hide();
      }
      else
      {
        this.noResult = false;
      }


      this.updatedCards();
      this.pagesCount = Math.round(this.totalRecords / this.numberOfCardsPerPage);
      this.pagecard = true;
      this.spinner.hide();

      res.ruInfo.forEach(element => {
        element.ruProcessOwnerGeids.forEach((allGeid) => {
          this.ruProcessOwnerGeids.push(allGeid)
        })
      });

      res.ruInfo.forEach(element => {
        element.coordinatorGeids.forEach((allGeid) => {
          this.rucoordinatorGeids.push(allGeid)
        })
      });

      res.ruInfo.forEach(element => {
        if (element) {
          this.ruid.push(element.id)
        }
      });

      this.ruFinallGeids = this.ruProcessOwnerGeids.concat(this.rucoordinatorGeids);

      this.adminAccountablePersonService.getAccountbleruInfoDetails(this.ruFinallGeids).subscribe((res) => {
        this.rupersonsinfo = res
        this.rupersonsinfo = sortBy(this.rupersonsinfo, ['name']);
      });

      this.getAllAccountablePersonData();

    });
  }

  getAllAccountablePersonData() {
    this.spinner.show();
    try {
      for (let i = 0; i < this.hubDetailsAsPerPagination.length; i++) {
        let objdata = {
          "div": this.hubDetailsAsPerPagination[i].divCode,
          "bu": this.hubDetailsAsPerPagination[i].buCode,
          "pg": this.hubDetailsAsPerPagination[i].pgCode,
          "country": this.hubDetailsAsPerPagination[i]?.address?.countryCode,
          "isGlobal": this.hubDetailsAsPerPagination[i]?.isGlobal
        }
        this.adminAccountablePersonService.getprocessownersDetails(objdata).subscribe((res: any) => {
          this.processOwner = res
          const filteredGeId = this.processOwner.map((r) => r.geid)
          this.adminAccountablePersonService.getEmployeeInfo(filteredGeId).subscribe((res) => {
            this.employeeInfo = res
          })
        })
        this.spinner.hide();
      }
      this.adminAccountablePersonService.getProcessSteps().subscribe((files: any) => {
        if (files) {
          this.files1 = files;
          this.files1.map((res) => {
            (res.label = res.name),
              (res.data = res.flatName),
              // (res.expandedIcon = 'pi pi-folder-open'),
              // (res.collapsedIcon = 'pi pi-folder'),
              (res.children = res.children);
              (res.expanded = res.isExpanded),
              (res.id = res.id),
              (res.leaf = res.hasChildren == true ? false : true);
          });
          if (this.files1[0]?.children.length) {
            this.files1[0].children.map((child) => {
              (child.label = child.name),
                (child.data = child.flatName),
                // (child.expandedIcon = 'pi pi-folder-open'),
                // (child.collapsedIcon = 'pi pi-folder'),
                (child.children = child.children);
                (child.expanded = child.children.length > 0),
                (child.id = child.id),
                (child.leaf = child.hasChildren == true ? false : true);
            });
          }
          for (let index = 0; index < this.accountablesearch.length; index++) {
            this.accountablesearch[index].tree = this.files1;
          }
        }
      })
      this.spinner.hide();
    }
    catch (e) {
      this.spinner.hide();
    }

  }

  onAll() {
    this.radioPOAssignedStatus = null;
    this.btnColorChange0 = true;
    this.btnColorChange1 = false;
    this.btnColorChange2 = false;
    if (this.btnColorChange0 == true) {
      this.acccounableForm.get('regions')?.enable();
      this.acccounableForm.get('country')?.enable();
      this.acccounableForm.get('city')?.enable();
      this.acccounableForm.get('activity')?.enable();
    }
  }

  onLocal() {
    this.radioPOAssignedStatus = false;
    this.btnColorChange0 = false;
    this.btnColorChange1 = true;
    this.btnColorChange2 = false;
    if (this.btnColorChange1 == true) {
      this.acccounableForm.get('regions')?.enable();
      this.acccounableForm.get('country')?.enable();
      this.acccounableForm.get('city')?.enable();
      this.acccounableForm.get('activity')?.enable();
    }
  }
  onGlobal() {
    this.radioPOAssignedStatus = true;
    this.btnColorChange0 = false;
    this.btnColorChange1 = false;
    this.btnColorChange2 = true;

    if (this.btnColorChange2 == true) {
      this.acccounableForm.get('regions')?.disable();
      this.acccounableForm.get('country')?.disable();
      this.acccounableForm.get('city')?.disable();
      this.acccounableForm.get('activity')?.disable();
    }
  }

  onClickAll() {
    this.radioHubStatus = null;
    this.btnColorChange3 = true;
    this.btnColorChange4 = false;
    this.btnColorChange5 = false;
  }
  onAssigned() {
    this.radioHubStatus = true;
    this.btnColorChange3 = false;
    this.btnColorChange4 = true;
    this.btnColorChange5 = false;
  }
  onNotAssigned() {
    this.radioHubStatus = false;
    this.btnColorChange3 = false;
    this.btnColorChange4 = false;
    this.btnColorChange5 = true;
  }

  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    } else {
      return content;
    }
  }

  pgChangeData(count: any) {
    this.resolvingUnitService.getActivity('1').subscribe(
      (response) => {
        this.activityDropdownData = response;
      }
    )
  }

  nodeSelect(event: any, cardIndex: number) {
      this.adminAccountablePersonService.getProcessStepId(event.node.id).then((res) => {
        if (res) {
          const asd = cloneDeep(this.hubDetailsAsPerPagination[cardIndex]);
          const result = this.newH(res, asd.tree, event.node.id, cardIndex);
          const qwe = this.hubDetailsAsPerPagination;
          const finalResponse = qwe.map(v => {
            if (v.id === asd.id) {
              return { ...asd, tree: result };
            }
            return v;
          });
          this.hubDetailsAsPerPagination = finalResponse;
        }
      });
  }

  nodeCollapse(event: any, cardIndex: number) {
    if(event?.node?.id == -1) {
      this.hubDetailsAsPerPagination.forEach((paging) => {
        paging.tree[0].expanded = true;
      })
      const copyDetailsData = cloneDeep(this.hubDetailsAsPerPagination[cardIndex]);
      copyDetailsData.tree[0].expanded = false;
      this.hubDetailsAsPerPagination[cardIndex] = copyDetailsData;
    }
  }

  newH(newData: any, h, id, cardIndex) {
    h.forEach((node, index) => {
      if (node.id == id) {
        const New = [];
        newData.forEach((res) => {
          (res.label = res.name),
            (res.data = res.flatName),
            // (res.expandedIcon = 'pi pi-folder-open'),
            // (res.collapsedIcon = 'pi pi-folder'),
            (res.expanded = true),
            (res.children = res.children),
            (res.leaf = res.hasChildren == true ? false : true);

        });
        node.children = [...newData];
      } else if (node.children.length) {
        this.newH(newData, node.children, id, cardIndex);
      }
    });
    return h;

  }

  private enableRecursive(node: any, isSelectable: boolean) {
    node.selectable = node.disabled;
    if (node.children) {
      node.children.forEach((childNode: any) => {
        this.enableRecursive(childNode, childNode.disabled);
      });
    }
  }

  clearFilters() {
    // this.acccounableForm.reset()
    this.noResultInfo="";
   // this.clicked = true;
   // this.showrumessage=true;
    this.acccounableForm.get('regions')?.enable();
    this.acccounableForm.get('country')?.enable();
    this.acccounableForm.get('city')?.enable();
    this.acccounableForm.get('activity')?.enable();
    this.acccounableForm.controls['country'].reset()
    this.acccounableForm.controls['activity'].reset()
    this.acccounableForm.controls['belongCountries'].reset()
    this.acccounableForm.controls['pg'].reset()
    this.acccounableForm.controls['regions'].reset()
    this.acccounableForm.controls['city'].reset()
    this.btnColorChange0 = true;
    this.btnColorChange1 = false;
    this.btnColorChange2 = false;
    this.btnColorChange3 = true;
    this.btnColorChange4 = false;
    this.btnColorChange5 = false;
    this.hubDetailsAsPerPagination = [];
    this.accountablesearch = [];
    this.pagecard= false;
  }

  addChildPopUp(cardData, obj, cardIndex) {
    this.ref = this.dialogService.open(AdminAccountableTreeAddPopupComponent, {
      header: 'Add Accountable Person ',
      width: '50%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'addtreePopup addPopupAccountablePerson',
      data: {
        cardIndex: cardIndex,
        empinfo: obj,
        cardinfo: obj,
        cardData: cardData,
        ruId: this.accountablesearch
      }
    });
  }

  editChildPopUp(cardData, obj, cardIndex) {
    this.ref = this.dialogService.open(AdminAccountableEditPopupComponent, {
      header: 'View / Edit Accountable Person ',
      width: '45%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'editPopup editPopupAccountablePerson',
      data: {
        cardIndex: cardIndex,
        empinfo: obj,
        cardinfo: obj,
        cardData: cardData,
        ruId: this.accountablesearch
      }
    });
  }

  updatedCards() {
    const startIndex = (this.currentPage - 1) * this.numberOfCardsPerPage;
    const endIndex = startIndex + this.numberOfCardsPerPage;
    this.hubDetailsAsPerPagination = this.updatedFilteredDetails.slice(startIndex, endIndex);
  }

  hasPrevious() {
    return this.currentPage > 1;
  }

  previousClicked() {

    if (this.hasPrevious()) {
      this.currentPage--;
      this.updatedCards();

    }

  }

  showFirstPage() {

    return this.hasPrevious() && this.previousPage() > 1;

  }

  previousPage() {

    if (this.hasPrevious()) {
      return this.currentPage - 1;
    }

    return 0;
  }

  gotoPage(page) {
    this.spinner.show()
    this.currentPage = page;
    this.updatedCards();
    this.spinner.hide()

  }

  showFirstPageSeparator() {
    return this.currentPage > 3;
  }

  hasNext() {
    return this.currentPage < this.pagesCount;
  }

  nextPage() {
    // this.spinner.show()
    if (this.hasNext()) {
      return this.currentPage + 1;
    }
    this.spinner.hide();
    return 0;
  }

  showLastPageSeparator() {
    var result = this.hasNext() && this.nextPage() < this.pagesCount - 1;
    return result;

  }

  showLastPage() {
    return this.hasNext() && this.nextPage() < this.pagesCount;

  }

  nextClicked() {
    this.spinner.show()
    if (this.hasNext()) {
      this.spinner.show()
      this.currentPage++;
      this.updatedCards();
      this.spinner.hide()
    }
    this.spinner.hide()

  }

  returnCountriesArray(inputRegion:any){
    let outputCountries =[];
    inputRegion.forEach(country =>{
      outputCountries.push(country.id);
    })
    return outputCountries;
  }


}
