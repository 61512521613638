<form [formGroup]="emailForm">
    <p-toast></p-toast>
    <div class="send-email-section send-email-issue-assign-pop" style="padding-top: 10px;">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-12">
            <div class="row mb-2 forCloseIcon">
              <label for="colFormLabelSm" class="col-sm-1 tagColumn col-form-label-sm alignSendMail">{{ "CreateEmail_EmailTo" |
                translate }}<span class="asteriskRequired" *ngIf="this.emailForm.controls['to']?.validator"></span></label>
              <div class="col-sm-8 sendToField" style="padding: 0px;">
                <p-autoComplete [forceSelection]="true" [minLength]=2 [readonly]="true" [pTooltip]="toText"
                  [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass" [suggestions]="filteredRecord"
                  (onSelect)="selectto($event)" (onUnselect)="removeto($event)" class="substituteCustom border-0"
                  styleClass="issueAlign"  type="text" formControlName="to"
                  (completeMethod)="onRecipientChange($event)" field="selectedLabel" [multiple]="true"
                  [ngClass]="isSendProcess && this.emailForm.controls['to'].invalid ? 'error-autocomplete' : ''">
                  <ng-template let-filteredRecord pTemplate="selectedItem">
                    <div (mouseover)="toGotFocus(filteredRecord?.tooltipInfo)" (mouseout)="toLostFocus()">
                      {{filteredRecord?.selectedLabel }}</div>
                    <!-- <span class="p-autocomplete-token-icon pi pi-times-circle ng-tns-c68-7 ng-star-inserted"
                                                    (click)="clearOwner()"></span> -->
                  </ng-template>
                  <ng-template let-filteredRecord pTemplate="item">
                    <div class="flex align-items-center gap-2 mb-2">
                      <div>{{ filteredRecord?.promptLabel }}</div>
                    </div>
                  </ng-template>
                </p-autoComplete>
              </div>
            </div>
            <div class="row mb-2">
              <label for="colFormLabelSm" class="col-sm-1 tagColumn col-form-label-sm alignSendMailCc">{{ "CreateEmail_EmailCC"
                |
                translate }}<span class="asteriskRequired" *ngIf="this.emailForm.controls['cc']?.validator"></span></label>
              <div class="col-sm-8 sendToField" style="padding: 0px;">
                <p-autoComplete [forceSelection]="true" [minLength]=2 [pTooltip]="ccText" [escape]="false" tooltipPosition="top"
                  tooltipStyleClass="toolTipClass" [suggestions]="filteredRecord" (onSelect)="selectcc($event)"
                  (onUnselect)="removecc($event)" class="substituteCustom border-0" type="text" formControlName="cc"
                  [suggestions]="filteredRecord" (completeMethod)="onRecipientChange($event)" field="selectedLabel"
                  [multiple]="true"
                  [ngClass]="isSendProcess && this.emailForm.controls['cc'].invalid ? 'error-autocomplete' : ''">
                  <ng-template let-filteredRecord pTemplate="selectedItem">
                    <div (mouseover)="ccGotFocus(filteredRecord?.tooltipInfo)" (mouseout)="ccLostFocus()">
                      {{filteredRecord?.selectedLabel }}</div>
                    <!-- <span class="p-autocomplete-token-icon pi pi-times-circle ng-tns-c68-7 ng-star-inserted"
                                                    (click)="clearOwner()"></span> -->
                  </ng-template>
                  <ng-template let-filteredRecord pTemplate="item">
                    <div class="flex align-items-center gap-2 mb-2">
                      <div>{{ filteredRecord?.promptLabel }}</div>
                    </div>
                  </ng-template>
                </p-autoComplete>
              </div>
            </div>
            <div class="row mb-2 forSubject">
              <label for="colFormLabelSm" class="col-sm-1 tagColumn col-form-label-sm alignSendMailSubject">{{
                "CreateEmail_EmailSubject" | translate }}<span class="asteriskRequired"
                  *ngIf="this.emailForm.controls['subject']?.validator"></span></label>
              <div class="col-sm-8" style="padding: 0px;">
                <input [readOnly]="true" class="form-control form-control-sm customField" formControlName="subject"
                  [ngClass]="isSendProcess && this.emailForm.controls['subject'].invalid ? 'error-inputbox' : ''">
              </div>
            </div>
            <div class="row mb-2 forSubject">
              <label for="colFormLabelSm" class="col-sm-1 tagColumn col-form-label-sm alignSendMailTemp">Template<span
                  class="asteriskRequired" *ngIf="this.emailForm.controls['template']?.validator"></span></label>
              <div class="col-sm-8" style="padding: 0px;">
                <select class="form-select form-control form-control-sm customField " aria-label="Default select example"
                  formControlName="template" (change)="template($event)"
                  [ngClass]="isSendProcess && this.emailForm.controls['template'].invalid ? 'error-inputbox' : ''">
                  <!-- <option value="4">
                                                New issue returned by Resolving Unit - Urgent attention required
                                            </option>
                                            <option value="11" selected>
                                                Solution for issue returned for further action
                                            </option>
                                            <option value="16">
                                                Solution has been rejected by Process Owner/Coordinator
                                            </option>
                                            <option value="41">
                                                Issue reverted from InProcess to NotAssigned status
                                            </option> -->
                  <option value={{a.key}} *ngFor="let a of getUserMessageVariable">{{a.value}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12">
            <span style="color:red"><b>Note: </b>The email body content is prefilled by the ccrp application.
              The sender needs to review and edit the content before sending it to the recipients.
            </span>
          </div>
        </div>
        <div class="row" >
                <input type="hidden" formControlName="attachment">
                <h6 class="inner-heading1 remove-px">{{ "ComplaintForm_AttachmentsSectionName" | translate }}</h6>

                <div class="row mb-1 me-5 attachments-section" [ngClass]="{'error-document' : isSendProcess && this.emailForm.controls['attachment']?.invalid}">
                    <div class="col-md-3 col-sm-3 ps-0 pe-0">
                        <label class="mt-2">{{ "ComplaintForm_SpecifyAttachment" | translate: paramForAttachment }}<span class="asteriskRequired" *ngIf="this.emailForm.controls['attachment']?.validator"></span></label>
                    </div>
                    <div class="col-md-8 col-sm-9 selectfile_sendMail remove-px">
                        <p-fileUpload #createUploader name="files[]" chooseLabel="Select Files"
                            [showCancelButton]="true" chooseIcon="null" removeStyleClass="p-overlaypanel1"
                            [multiple]="true" chooseStyleClass="p-overlaypanel" [maxFileSize]="30000000" [auto]="true"
                            class="" [customUpload]="true" (uploadHandler)="myUploader(createUploader,$event)">
                            <ng-template let-file let-index pTemplate="file">
                                <ul class="p-upload-files p-reset file-list">
                                    <li class="p-file row">
                                        <div class="col-md-10 col-sm-10" [pTooltip]="file?.name" [escape]="false"
                                            tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                            <span class="p-filename">{{file?.name}}</span>
                                        </div>
                                        <div class="col-md-2 col-sm-2 text-end" [pTooltip]="removeText" [escape]="false"
                                            tooltipPosition="top" tooltipStyleClass="toolTipClass">
                                            <!-- <button type="button" class="widgetButtoSmall attachButton"
                                                (click)="removeFile(file ,createUploader)"> X </button> -->
                                                <i class="fa fa-window-close closebtn" aria-hidden="true" (click)="removeFile(file ,createUploader)"> </i>
                                        </div>
                                    </li>
                                </ul>
                            </ng-template>
                        </p-fileUpload>
                    </div>
                </div>
        </div><br>
        <input type="hidden" formControlName="defaultto">
        <input type="hidden" formControlName="defaultcc">
        <div class="row">
          <p-editor formControlName="textareaBody" (keypress)="OnBodyChangeEvent($event)" [style]="{height: '200px'}" [ngClass]="isSendProcess && this.emailForm.controls['textareaBody'].invalid ? 'error-editorbox' : ''"></p-editor>
        </div>
    </div>
</form>
<!-- <pre>{{emailForm.value | json}}</pre> -->
<div class="send-email-section">
    <div class="modal-footer mt-3">
        <button type="button" [ngClass]="!disableBtn['CreateEmailForm_SendEmailDialogTitle']? 'widgetButtonMedium grayButton' : 'widgetButtonMedium p-button-success'" (click)="sendMail()"
            label="Top Left">{{ "CreateEmailForm_SendEmailDialogTitle" | translate }}</button>
         <button class="input widgetButtonMedium ms-1" type="button" (click)="onClose()">{{ "ButtonExitWithoutSaving" | translate }}</button>
    </div>
</div>
