import { Component, OnInit } from '@angular/core';
import { ExternalpageEmitService } from '../externalpage-emit.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-customer-feedback-approve',
  templateUrl: './customer-feedback-approve.component.html',
  styleUrl: './customer-feedback-approve.component.css'
})
export class CustomerFeedbackApproveComponent implements OnInit {
  encodedURI: any;
  inputURI: any;
  
  constructor(private externalPageService: ExternalpageEmitService,
    private route: ActivatedRoute,
    public router: Router,
    private spinnerService: NgxSpinnerService) { }

    ngOnInit(): void {
      this.spinnerService.show();
      this.externalPageService.setExternalPageFunction(true);
      this.inputURI = this.route.snapshot.paramMap.get('id');
      const decodedURI = atob(this.inputURI);
      this.encodedURI = btoa(decodedURI);
  
      this.externalPageService.customerFeedbackApprove(this.encodedURI).subscribe(
        (data)=>{
          this.spinnerService.hide();
          window.location.href = data?.url;
        }
      )
    }
}
