<div id="myDiv" class="panel-body gray status-update-name-pop">
<div *ngIf="logDataToShow.length > 0">
  <div class="underlinestatus status-update-name-pop" *ngFor="let log of logDataToShow; let i= index" (click)="expandRow(i)">
    <div *ngIf="!(selectedIndex == i || showAllBody)" class="row" style="padding-top: 3px;">
      <div class="col-lg-3 col-md-3 col-sm-3" style="padding-left: 0">
        <span> <span class="glyphicon glyphicon-user"></span>
          <span>{{log.createdBy.email}}</span></span>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 log-subject word-wrap" style="padding-left: 0">
        <p>
          <span *ngIf="log.type == 1" class="log-subject"
            style=" background-color: #80c4e1; color: #fff;">{{'al_act_sys_lbl' | translate}}</span>
          <span *ngIf="log.type == 4" class="log-subject"
            style=" background-color: #FF7F50; color: #fff;">{{'al_act_me_msg' | translate}}</span>
          <span *ngIf="log.type == 16" class="log-subject"
            style=" background-color:red; color: #fff;">{{'al_act_ai_msg' | translate}}</span>
          <span *ngIf="log.type == 8" class="log-subject"
            style=" background-color: #5cb85c; color: #fff;">{{'al_act_un_msg' | translate}}</span>
          <span *ngIf="log.type == 2" class="log-subject"
            style=" background-color: #093fb4; color: #fff;">{{'al_act_sn_msg' | translate}}</span>
          <span *ngIf="log.type == 18" class="log-subject"
            style=" background-color: #ffb500; color: #fff;">{{'al_act_rn_msg' | translate}}</span>&nbsp;
          <!-- <span class="log-subject"  style="padding: 2px 4px; background-color: #7d48f1; color: #fff;" *ngIf="getTypeOfNotification(log.subject,log.type)=='Action Email'">{{getTypeOfNotification(log.subject,log.type)}}</span>
          <span class="log-subject"  style="padding: 2px 4px; background-color: #FF7F50; color: #fff;" *ngIf="getTypeOfNotification(log.subject,log.type)=='Reminder Email'">{{getTypeOfNotification(log.subject,log.type)}}</span>
          <span class="log-subject"  style="padding: 2px 4px; background-color: #5cb85c; color: #fff;"*ngIf="getTypeOfNotification(log.subject,log.type)=='Information Email'">{{getTypeOfNotification(log.subject,log.type)}}</span>&nbsp; -->
          <span class="action-log-subject" style="font-weight: bold;" [innerHTML]="log.subject"></span>&nbsp;

          <span *ngIf="log.isExternal && log.to">
            <br/><b>to</b>: {{log.to}}
          </span>
          <span *ngIf="log.isExternal && log.cc">
            <br/><b>cc</b>: {{log.cc}}
          </span>
          <span *ngIf="!log.isExternal" [innerHTML]="log.body | shortenComplaintSubject : 100"></span>
        </p>

        <!-- <div *ngIf="selectedIndex == i || showAllBody" class="arrow" style="display: flex;">
          <p style="width: 95%;" [innerHTML]="log.body"></p>
          <i (click)="collapseRow(i, $event)" class="fa fa-angle-double-up" title="Collapse"
            cc-disable-a-tag="isControlDisabled"></i>
        </div>       -->
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 text-right" style="padding: 0px;">
        <span class="fa fa-paperclip" *ngIf="log.attachments.length>0"></span>&nbsp;&nbsp;
        <span title="This message was sent on  {{log.date | date:'YYYY-MM-dd HH:mm:ss' }}">
          <i class="hitachiicon-calendar"> </i> {{log.date | date:'YYYY-MM-dd HH:mm:ss'}}
        </span>
      </div>
    </div>

    <div *ngIf="selectedIndex == i || showAllBody" class="row" style="background-color: #fff; padding-top: 3px;">
      <div class="row">
        <div class="col-lg-10 col-md-10 col-sm-10" style="padding: 0px;">
          <div style="display: inline">
            <span class="glyphicon glyphicon-user"></span>
            {{log.createdBy.name}} &lt;{{log.createdBy.email}}&gt;
            <span *ngIf="log.type == 1" class="log-subject"
              style=" background-color: #80c4e1; color: #fff;">{{'al_act_sys_lbl' | translate}}</span>
            <span *ngIf="log.type == 4" class="log-subject"
              style=" background-color: #FF7F50; color: #fff;">{{'al_act_me_msg' | translate}}</span>
            <span *ngIf="log.type == 16" class="log-subject"
              style=" background-color:red; color: #fff;">{{'al_act_ai_msg' | translate}}</span>
            <span *ngIf="log.type == 8" class="log-subject"
              style=" background-color: #5cb85c; color: #fff;">{{'al_act_un_msg' | translate}}</span>
            <span *ngIf="log.type == 2" class="log-subject"
              style=" background-color: #093fb4; color: #fff;">{{'al_act_sn_msg' | translate}}</span>
            <span *ngIf="log.type == 18" class="log-subject"
              style=" background-color: #ffb500; color: #fff;">{{'al_act_rn_msg' | translate}}</span>&nbsp;
              <!-- <span class="log-subject"  style="padding: 2px 4px; background-color: #7d48f1; color: #fff;" *ngIf="getTypeOfNotification(log.subject,log.type)=='Action Email'">{{getTypeOfNotification(log.subject,log.type)}}</span>
              <span class="log-subject"  style="padding: 2px 4px; background-color: #FF7F50; color: #fff;" *ngIf="getTypeOfNotification(log.subject,log.type)=='Reminder Email'">{{getTypeOfNotification(log.subject,log.type)}}</span>
              <span class="log-subject"  style="padding: 2px 4px; background-color: #5cb85c; color: #fff;"*ngIf="getTypeOfNotification(log.subject,log.type)=='Information Email'">{{getTypeOfNotification(log.subject,log.type)}}</span>&nbsp; -->
          </div>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-2 text-right" style="padding: 0px;">
          <i (click)="collapseRow(i, $event)" class="fa fa-angle-double-up" title="{{'al_collapse' | translate}}"
          cc-disable-a-tag="isControlDisabled"></i>
          <span class="fa fa-paperclip ps-1" *ngIf="log.attachments.length>0"></span>&nbsp;&nbsp;
          <span class="hitachiicon-calendar" title="{{'al_message_was_senton_msg' | translate}}"></span><span> {{log.date|date:'YYYY-MM-dd HH:mm:ss'}}</span>

          <!-- <span title="This message was sent on  {{log.date | date:'YYYY-MM-dd HH:mm:ss' }}">
          <i style="text-align: right;" class="hitachiicon-calendar"> </i> {{log.date | date:'YYYY-MM-dd HH:mm:ss'}}
          </span> -->
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12" *ngIf="log.to">
          to: {{log.to}}
        </div>
        <div class="col-lg-12" *ngIf="log.cc">
          cc: {{log.cc}}
        </div>

        <b><span [innerHTML]="log.subject"></span></b>
        <p style="width: 95%;" *ngIf="!log.isExternal" [innerHTML]="log.body"></p>
        <div *ngIf="log.attachments.length>0">
          <hr style="margin-top:4px;margin-bottom:4px">
          <span class="fa fa-paperclip"></span>&nbsp;&nbsp; | {{log.attachments.length}} {{'al_attachment_msg' | translate}} ({{'al_attachment_total' | translate}} {{ log.attachments | sumFloat | filesize }}) | <a style="text-decoration: none;" target="_blank" routerLink="{{(log.attachments[0]).url}},zip">{{'al_attachment_allaszip' | translate}}</a>

          <hr style="margin-top:4px;margin-bottom:4px">
          <ul class="list-unstyled">
              <li *ngFor="let att of log.attachments ">
                  {{att.filename}} ({{att.size | filesize}})<small>
                      <a style="text-decoration: none;" target="_blank" href="{{att.url}}"><span class="glyphicon glyphicon-download-alt"></span>{{'al_attachment_download' | translate}}</a>
                  </small>
              </li>

          </ul>
      </div>
      </div>
    </div>
  </div>
  </div>
  <div *ngIf="logDataToShow?.length == 0" class="row">
    <div class="col-lg-12 text-center my-2">
      <h5 class="text-muted">{{'al_0_toshow_msg' | translate}}</h5>
    </div>
  </div>
</div>
