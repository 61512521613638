import { Component, OnInit, OnChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import moment from 'moment';
import { ActionPlanCard } from 'src/app/models/new-issue/actionplan/ActionPlanCard';
import { DisableModel } from 'src/app/models/new-issue/actionplan/DisableModel';
import { ActionItemValidationService } from 'src/app/services/actionplan/action-item-validation.service';
import { ActionPlanService } from 'src/app/services/actionplan/action-plan.service';
import { ActionTypesService } from 'src/app/services/actionplan/action-types.service';
import { EmployeeListService } from 'src/app/services/employee-list.service';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
import { AbstractControl } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RejectCardModalComponent } from '../reject-card-modal/reject-card-modal.component';
import { UniqueActionNameWithinBoardService } from 'src/app/services/actionplan/unique-action-name-within-board.service';
import { ActionPlanPermissionsService } from 'src/app/services/actionplan/action-plan-permissions.service';
import { UsersService } from 'src/app/services/users/users.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ComplaintDetailsService } from 'src/app/services/complaintDetails/complaint-details.service';
import { FileUpload } from 'primeng/fileupload';
import { ReopenCardModalComponent } from '../reopen-card-modal/reopen-card-modal.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ComplaintModel } from './../../Model/ComplaintModel';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { DownloadAttachmentService } from 'src/app/services/download-attachment.service';
import { maxLengthConstants } from 'src/app/reusables/constants/maxlength.constants';

export function validateDateFromToday(control: AbstractControl) {
  let today: Date = new Date();
  if (new Date(control.value) < today) {

    return { invalidDate: true }
  }
  return { invalidDate: false };
}

export function uniqueName(control: AbstractControl) {

  if (control.value) {
    // this.checkUniqueness.checkUniqueActionName('FI-957258', 'Delivery of a new Motor').subscribe((resp)=>{
    //   console.log(resp);
    // })
    return { notUnique: true }
    //update based on api
    //api/actionplan/checkactionnameuniqueness?actionName=' + viewValue + '&complaintNo=' + scope.complaintNo
  }
  return null;
}

export interface IActionPlanEditScope {
  ctrl: any;
  spinnerFn: any;
  // employeesOptions: ISelectOptions;
  blocked: boolean;
  actionTypes;
  open($event, el);
  selected;
  active;
  // dzMethods;
  minDate;
  targetResolvingDate;
  containmentDeadlineDate;
  actionPlanHighLightDate;
  targetDateValidation;
};

@Component({
  selector: 'app-action-plan-card-edit',
  templateUrl: './action-plan-card-edit.component.html',
  styleUrls: ['./action-plan-card-edit.component.css']
})
export class ActionPlanCardEditComponent implements OnInit, IActionPlanEditScope, OnChanges {
  @ViewChild('createUploader') createUploaderIssueDesc!: FileUpload;
  complaintNumber;
  selectedRole;
  actionId;
  loggedInUserDetails;
  previousActionOwner;
  removeText = 'Remove';
  actionPlanAttachment: any = [];
  informationAttachments: any = [];
  informationAttachmentsHolder: any = [];
  issueAttachmentMerged: any = []

  actionPlanAttachmentDesc: any = [];
  informationAttachmentsDesc: any = [];
  informationAttachmentsHolderDesc: any = [];
  issueAttachmentMergedDesc: any = [];
  actionPlanFile: any = [];

  isDisabled: DisableModel;
  requestMade: boolean = false;
  saveMade: boolean = false;
  disableReject: boolean = false;
  activitiesRequired: boolean = false;
  public token;
  public static controllerId = "ActionPlanEditController";
  disableRejectBtn: boolean;
  disableCloseBtn: boolean;
  disableSubmitBtn: boolean;
  tokenForUser;

  render = false;
  actionNameUnique: boolean;

  public actionType;
  private allDisabled = true;
  maxDate;
  minDate = new Date();
  targetResolvingDate;
  containmentDeadlineDate;
  actionPlanHighLightDate;
  ref: DynamicDialogRef;
  complaintId: any;
  isSubmitted = true;
  actionPlanDeadlineDate = "<ul><li>Action Deadline Date is required.</li><li>Action Deadline Date has to be greater or equal to current date.</li><li>Action Deadline Date cannot be greater than Target Resolution & Verification Deadline Date.</li></ul>";

  actionCreate !: FormGroup;
  showerror: boolean = false;
  existingName: any;
  readonly maxLength = maxLengthConstants;
  baseurl = environment.azure.baseUrl;

  constructor(private route: ActivatedRoute,
    private actionPlanService: ActionPlanService,
    private actionTypesService: ActionTypesService,
    private lclStorageService: LocalStorageServiceService,
    private validationService: ActionItemValidationService,
    private router: Router,
    private employeeService: EmployeeListService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    public dialogService: DialogService,
    public checkUniqueness: UniqueActionNameWithinBoardService,
    private usersService: UsersService,
    public breadcrumbService: BreadcrumbService,
    private complaintDetailsService: ComplaintDetailsService,
    private sessionStorage: SessionStorageServiceService,
    public permissionsService: ActionPlanPermissionsService,
    private spinner: NgxSpinnerService,
    public dashboardService: DashboardService,
    private downloadAttachementService:DownloadAttachmentService
  ) {

    this.selectedRole = null;

    if (this.route.snapshot.paramMap.get('selectedRole') != undefined && this.route.snapshot.paramMap.get('selectedRole') != '') {
      this.selectedRole = this.route.snapshot.paramMap.get('selectedRole');
    }
    this.complaintNumber = this.route.snapshot.paramMap.get('complaintNumber');
    this.dashboardService.getComplaintData(this.complaintId, this.complaintNumber).subscribe((response: ComplaintModel | any) => {
      this.complaintData = response;
      this.complaintId = this.complaintData?.header?.id
      this.maxDate = new Date(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.targetResolvingDate?.split("T", 1)[0]);
    },(error) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error while fetching complaint data!'
      })
      this.actionCreate.get('actionName').setErrors({ notUnique: true });
    });

    this.actionId = this.route.snapshot.paramMap.get('id');
    this.selected = {};
    this.selected.files = [];
    this.isDisabled = new DisableModel();
    this.loggedInUserDetails = this.sessionStorage.getData('userInfo');

    this.loadActionTypes(this.actionId, this.selectedRole);

  }


  ctrl: any;
  spinnerFn: any;
  blocked: boolean;
  actionTypes: any[];
  open($event: any, el: any) {
    throw new Error('Method not implemented.');
  }
  selected: any;
  active: any;
  // dzMethods: any;
  targetDateValidation: any;
  filteredRecord: any;
  complaintData: any;
  disableForAssignAndNotActionOwner: boolean;
  disabledForClosed: boolean;
  sendData: any = {};


  ngOnInit(): void {

    this.breadcrumbService.menuItems = [
      { label: 'ACTION PLAN ' + this.complaintNumber, routerLink: (this.selectedRole != null && this.selectedRole != undefined && this.selectedRole != '') ? 'issues/' + this.complaintNumber +'/'+this.selectedRole + '/actionplan' :  'issues/' + this.complaintNumber + '/actionplan'},
      { label: 'ACTION' }
    ];

    this.actionCreate = this.formBuilder.group({
      actionName: new FormControl('', [Validators.maxLength(250)]),
      actionType: new FormControl(''),
      actionPlanHighLightDate: new FormControl(''),
      interestedParties: new FormControl(),
      targetDate: new FormControl('', Validators.required),
      actionCreator: new FormControl(''),
      actionOwner: new FormControl('', Validators.required),
      description: new FormControl(''),
      activities: new FormControl(''),
      id: new FormControl(''),
      status: new FormControl(''),
      statusId: new FormControl(0),
      files: new FormControl([]),
      createDate: new FormControl(),
      creatorGeid: new FormControl(),
      isRejected: new FormControl(),
      isReopened: new FormControl(),
      lastUpdateDate: new FormControl(),
      ownerName: new FormControl(),
      permission: new FormControl(),
      rejectReason: new FormControl(),
      rowVersion: new FormControl('')
    })

    this.actionCreate.controls['actionType'].valueChanges.subscribe((actionType) => {
      if (actionType.id == 26) {
        const targetDateValue = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.targetResolvingDate?.split("T", 1)[0];
        this.actionCreate.controls['actionPlanHighLightDate'].patchValue(targetDateValue);
      }
    });

   /* this.checkUniqueness.getComplaintData(this.complaintNumber).subscribe((resp: any) => {
      if (resp) {
        this.complaintData = resp;
        this.complaintId = resp?.id
      }
    },
    (error) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Error while fetching complaint data!'
      })
      this.actionCreate.get('actionName').setErrors({ notUnique: true });
    });*/

    this.permissionsService.getUserPermissionToken(this.actionId, null).subscribe((resp) => {
      this.tokenForUser = resp;
    })
    if (this.actionId) {
      this.actionPlanService.getActionBoardAttachments(1, this.actionId).subscribe((res: any) => {
        this.informationAttachmentsDesc = res
        this.informationAttachmentsHolderDesc = res
      })

      this.actionPlanService.getActionBoardAttachments(2, this.actionId).subscribe((res: any) => {
        this.informationAttachments = res
        this.informationAttachmentsHolder = res
      })

    }
    this.actionCreate.controls['actionCreator'].disable();
  }

  ngOnChanges() {
  }

  get formControl() {
    return this.actionCreate.controls;
  }

  get getActionOwner() {
    return this.actionCreate.get('actionOwner');
  }

  get getActionType() {
    return this.actionCreate.get('actionType');
  }

  get GetActionName() {
    return this.actionCreate.get('actionName');
  }

  get GetTargetDate() {
    return this.actionCreate.get('targetDate');
  }

  loadActionTypes(actionId, selectedRole) {
    if (actionId) {
      this.blocked = true;
      this.actionPlanService.getActionItem(actionId).subscribe(
        (resp) => {
          // if(resp.adminCreator) {
          //   resp.adminCreator.selectedLabel = resp.adminCreator?.email;
          //   resp.adminCreator.geid = resp.adminCreator?.id
          // }
          if (resp.actionCreator) {
            resp.actionCreator.selectedLabel = resp.actionCreator?.email;
            resp.actionCreator.geid = resp.actionCreator?.id
          }
          if (resp.actionOwner) {
            resp.actionOwner.selectedLabel = resp?.actionOwner?.email;
            resp.actionOwner.geid = resp?.actionOwner?.id;
          }
          if (resp.interestedParties?.length) {
            resp.interestedParties.map((party) => {
              party.selectedLabel = party?.email;
              party.geid = party?.displayGeid ? party?.displayGeid : party?.geid;
            });
          }
          console.log(resp);
          this.actionCreate.patchValue({
            actionName: resp.name,
            targetDate: resp.targetDate ? new Date(moment(resp.targetDate).format('YYYY-MM-DD')) : null,
            actionOwner: resp?.actionOwner ? [resp?.actionOwner] : [],
            actionCreator: [resp?.actionCreator],
            activities: resp.activities,
            description: resp.description,
            interestedParties: resp?.interestedParties,
            id: actionId,
            rowVersion: resp?.rowVersion,
            status: resp?.status,
            statusId: resp?.statusId,
            createDate: resp?.createDate,
            creatorGeid: resp?.creatorGeid,
            isRejected: resp?.isRejected,
            isReopened: resp?.isReopened,
            lastUpdateDate: resp?.lastUpdateDate,
            ownerName: resp?.ownerName,
            permission: resp?.permission,
            rejectReason: resp?.rejectReason
          })
          this.existingName = resp.name;

          this.selected = resp;
          this.selected.description = resp.description;
          this.selected.targetDate = resp.targetDate;
          this.selected.name = resp.name;
          this.selected.actionOwner = resp.actionOwner;
          this.selected.actionCreator = resp.actionCreator;
          this.selected.interestedParties = resp.interestedParties;
          this.previousActionOwner = resp.actionOwner;
          this.blocked = false;

          this.actionTypesService.getActionTypes(moment(new Date()).format('YYYY-MM-DD')).subscribe(
            (data) => {
              this.actionType = data;
              this.actionCreate.patchValue({
                actionType: data.find(options => options.name === resp.typeName)
              })
            }
          )
          this.runValidationCheck(actionId, selectedRole);
          setTimeout(() => {
            this.loggedInUserDetails = this.sessionStorage.getData('userInfo');
            this.disableButtonsOnReassign();
          }, 2000)
          this.validateFieldForRolesAndStatus()
        }
      )
    }
    else {
      this.actionTypesService.getActionTypes(moment(new Date()).format('YYYY-MM-DD')).subscribe(
        (resp) => {
          this.actionType = resp;
          this.selected = new ActionPlanCard();
          this.selected.type = this.actionPlanService.getNewCardType();
          switch (this.selected.type) {
            case 24:
              {
                this.actionCreate.patchValue({
                  actionType: resp.find(options => options.id === 24)
                })
              }
              break;
            case 25:
              {
                this.actionCreate.patchValue({
                  actionType: resp.find(options => options.id === 25)
                })
              }
              break;
            case 26:
              {
                this.actionCreate.patchValue({
                  actionType: resp.find(options => options.id === 26)
                })
              }
              break;
            case 27:
              {
                this.actionCreate.patchValue({
                  actionType: resp.find(options => options.id === 27)
                })
              }
              break;
            default:
              {
                this.actionCreate.patchValue({
                  actionType: resp.find(options => options.id === 28)
                })
              }
              break;
          }
          this.selected.statusId = 0;
          this.actionCreate.controls['activities'].disable();
          this.selected.files = [];
          this.runValidationCheck(actionId, selectedRole);
        }
      )
    }
  }

  validateFieldForRolesAndStatus(){
    if(this.actionCreate.get('statusId')?.value==2 && this.actionCreate.controls['actionOwner'].value![0]?.email!=this.loggedInUserDetails?.email){
      this.disableForAssignAndNotActionOwner = true;
    }
    if (this.actionCreate.get('statusId')?.value==3){
      this.disabledForClosed = true;
    }
  }

  runValidationCheck(actionId, selectedRole) {
    if(selectedRole == 'null') {
      selectedRole = 'Support Desk';
    }
    this.actionPlanService.getComplaintInformation(this.complaintNumber).subscribe(
      (resp) => {
        for (var i in resp) {
          if (i == 'targetResolvingDate')
            this.targetResolvingDate = moment(resp[i]).format('YYYY-MM-DD');
          if (i == 'containmentDeadlineDate') {
            this.containmentDeadlineDate = moment(resp[i]).format('YYYY-MM-DD');
          }
        }
        if (this.targetResolvingDate && this.selected.type == 26) {
          this.actionCreate.patchValue({
            actionPlanHighLightDate: this.targetResolvingDate
          })
        }

        if (this.selected.type == 26) {
          this.actionPlanHighLightDate = this.targetResolvingDate;
        }
        if (this.selected.type == 28) {
          this.actionPlanHighLightDate = this.containmentDeadlineDate;
        }
        if (this.selected.targetDate) {
          this.onChangeActionDeadLine();
        }
      }
    )
    this.token = this.validationService.runValidationCheck(this.isDisabled, this.selected, this.loggedInUserDetails?.email, selectedRole);
    //console.log(this.token)
  }

  onSave() {
    if (!this.actionCreate.get('actionName')?.value) {
      this.actionCreate.get('actionName').setErrors({ required: true });
    }
    else {
      this.spinner.show();
      if (this.selected.statusId !== 0) {
        const saveData = this.checkUniqueness.getSaveData(this.formControl, this.complaintNumber, this.complaintId, this.selected.statusId);
        this.checkUniqueness.actionPlanSave(saveData).subscribe((resp) => {
          if (resp) {
            this.spinner.hide();
            this.messageService.add({
              severity: 'success',
              summary: 'Action plan is updated'
            })
            //this.router.navigateByUrl('/issues/' + this.complaintNumber + '/actionplan/card/'+resp);
            window.location.reload();
          }
        }, (error) => {
          this.spinner.hide();
          this.messageService.add({
            severity: 'error',
            summary: 'Error while saving data'
          })
        });
      }
      else {
        this.checkUniqueness.checkUniqueActionName(this.complaintNumber, this.actionCreate.get('actionName')?.value).subscribe((response) => {
          this.actionCreate.get('actionName').setErrors(null);
          const saveData = this.checkUniqueness.getSaveData(this.formControl, this.complaintNumber, this.complaintId, this.selected.statusId);
          this.checkUniqueness.actionPlanSave(saveData).subscribe((resp) => {
            if (resp) {
              this.spinner.hide();
              this.messageService.add({
                severity: 'success',
                summary: 'Action plan is saved'
              })
              this.router.navigateByUrl('/issues/' + this.complaintNumber +'/'+this.selectedRole+  '/actionplan/card/'+resp);
            }
          },(error) => {
            this.spinner.hide();
            this.messageService.add({
              severity: 'error',
              summary: 'Error while saving data'
            })
          });
        }, (error) => {
          this.spinner.hide();
          this.messageService.add({
            severity: 'error',
            summary: 'Action name must be unique'
          })
          this.actionCreate.get('actionName').setErrors({ notUnique: true });
        });
      }
    }
  }

  onChangeActionDeadLine() {
    if (this.selected.targetDate) {
      var targetDate = moment.utc(this.selected.targetDate).format('YYYY-MM-DD');
      if (targetDate > this.targetResolvingDate) {
        this.targetDateValidation = true;
      }
      else {
        this.targetDateValidation = false;
      }
    }
  }

  onBackLocation() {
    this.router.navigateByUrl('/complaint/' + this.complaintId);
  }


  disableButtonsOnReassign() {
      if (this.loggedInUserDetails?.email == this.actionCreate.controls['actionOwner'].value![0]?.email
      || this.loggedInUserDetails?.email == this.actionCreate.controls['actionOwner'].value![0]?.selectedLabel) {
        this.disableRejectBtn = false;
        this.disableSubmitBtn = false;
      }
      else {
        this.disableRejectBtn = true;
        this.disableSubmitBtn = true;
      }
  }

  onRecipientChange(event: any) {
    //let filtered: any[] = [];
    let query = event.query;
    if (query.length > 1) {
      this.usersService.getFilteredUser(query).subscribe((res) => {
        this.filteredRecord = res;
      })
    }
    //this.filteredRecord = filtered;
  }

  ownerSelect(event){
    this.disableButtonsOnReassign();
  }

  clearOwner() {
    this.actionCreate.patchValue({
      actionOwner: []
    })
  }

  clearCreator() {
    this.actionCreate.patchValue({
      actionCreator: []
    })
  }


  returnToActionPlanBoard() {
    if (this.selectedRole != null && this.selectedRole != undefined && this.selectedRole != '')
      this.router.navigateByUrl('/issues/' + this.complaintNumber +'/'+this.selectedRole+  '/actionplan');
    else
   // this.onBackLocation();
      this.router.navigateByUrl('/issues/' + this.complaintNumber + '/actionplan');
  }

  onActionLog() {
    if (this.selectedRole != null && this.selectedRole != undefined && this.selectedRole != '') {
      this.router.navigateByUrl('/issues/' + this.complaintNumber +'/'+this.selectedRole+ '/actionplan/card/' + this.actionId + '/log')
    }
    else {
      //this.onBackLocation();
      this.router.navigateByUrl('/issues/' + this.complaintNumber + '/actionplan/card/' + this.actionId + '/log')
    }
  }

  // onSubmit(){

  // }

  // uniqueName(control: AbstractControl) {

  //   if(control.value)
  //   {
  //     this.checkUniqueness.checkUniqueActionName('FI-957258', 'Delivery of a new Motor').subscribe((resp)=>{
  //       console.log(resp);
  //     })
  //     return {notUnique : true}
  //     //update based on api
  //     //api/actionplan/checkactionnameuniqueness?actionName=' + viewValue + '&complaintNo=' + scope.complaintNo
  //   }
  //   return {notUnique : true}
  // }

  reopenCard() {
    var targetDate = moment.utc(this.targetResolvingDate).format('YYYY-MM-DD');
    var currentDate = moment.utc(new Date()).format('YYYY-MM-DD');

    if (currentDate > targetDate && (this.selected.type != 27 || this.selected.type != "27")) {
      this.messageService.add({
        severity: 'error',
        summary: 'Target Resolution & Verification Date already passed so cannot reopen this Action.'
      })
    }
    else {
      this.ref = this.dialogService.open(ReopenCardModalComponent, {
        header: 'Reopen Action',
        width: '80%',
        position: 'top'
      });

      this.ref.onClose.subscribe((res) => {
        if (res.result) {
          this.requestMade = true;

          //this.actionPlanService.reopenItem(this.selected.id, res.result) //update
          this.spinner.show();
          this.actionPlanService.reopenItem(this.selected.id, res.result).subscribe(() => {
            this.returnToActionPlanBoard();
            this.spinner.hide();
            this.messageService.add({
              severity: 'success',
              summary: 'Action successfully reopened.'
            })
          }, (error) => {
            this.spinner.hide();
            this.messageService.add({
              severity: 'error',
              summary: "Error while Re-opening action"
            })
          });
        }
      })
    }

  }

  assignCard() {
    if (!this.actionCreate.get('actionName')?.value) {
      this.actionCreate.get('actionName').setErrors({ required: true });
    }
    if (!this.actionCreate.get('targetDate')?.value) {
      this.actionCreate.get('targetDate').setErrors({ required: true });
    }
    if (!this.actionCreate.get('actionOwner')?.value) {
      this.actionCreate.get('actionOwner').setErrors({ required: true });
    }
    this.showerror = true;
    if (this.targetDateValidation && this.selected.type != 27) {
      this.messageService.add({
        severity: 'error',
        summary: 'Please Select Valid Action Deadline Date.'
      })
    }
    else if (this.actionCreate.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Please Fill required fields.'
      })
    }
    else {
      this.spinner.show();
      if (this.existingName != this.actionCreate.controls['actionName'].value) {
        this.checkUniqueness.checkUniqueActionName(this.complaintNumber, this.actionCreate.get('actionName')?.value).subscribe((response) => {
          this.actionCreate.get('actionName').setErrors(null);
          this.assignAction()
        }, (error: HttpErrorResponse) => {
          this.spinner.hide();
          this.messageService.add({
            severity: 'error',
            summary: 'Action name must be unique'
          })
          this.actionCreate.get('actionName').setErrors({ notUnique: true });
        });
      }
      else {
        this.assignAction();
      }
    }
  }

  assignAction() {
    //const userInfo = this.sessionStorage.getData('userInfo');
    this.requestMade = true;
    this.saveMade = false;
    this.mapFormDataToRequest();
    this.actionPlanService.assignActionCard(this.sendData).subscribe(
      (resp) => {
        this.spinner.hide();
        this.returnToActionPlanBoard();
        this.messageService.add({
          severity: 'success',
          summary: 'Assigned.'
        })
      }, (error) => {
        this.spinner.hide();
        this.messageService.add({
          severity: 'error',
          summary: error?.title
        })
      });
  }

  mapFormDataToRequest(){
    this.sendData.complaintNo = this.route.snapshot.paramMap.get('complaintNumber');
    this.sendData.id = this.route.snapshot.paramMap.get('id') == null ? 0 : this.route.snapshot.paramMap.get('id');
    this.sendData.complaintId = this.complaintData?.header?.id;
    this.sendData.type = this.actionCreate?.controls['actionType']?.value?.id?.toString();
    this.sendData.actionOwner = this.actionCreate.controls['actionOwner'].value![0];
    this.sendData.actionOwner.id = this.actionCreate.controls['actionOwner'].value![0]?.geid;
    this.sendData.name = this.actionCreate.controls['actionName'].value;
   // this.sendData.interestedParties = this.actionCreate.controls['interestedParties'].value;
    this.sendData.description = this.actionCreate.controls['description'].value;
    this.sendData.activities = this.actionCreate.controls['activities'].value;
    this.sendData.statusId = this.route.snapshot.paramMap.get('id') == null ? 0 : this.actionCreate.controls['statusId'].value;
    this.sendData.targetDate = this.actionCreate.controls['targetDate'].value ? new Date(this.actionCreate.controls['targetDate'].value).toISOString() : null;
    this.sendData.files = this.actionCreate?.controls['files']?.value ? this.actionCreate?.controls['files']?.value : [];
    if(this.actionCreate.controls['interestedParties'].value?.length > 0) {
      let partyData = this.actionCreate.controls['interestedParties'].value.map((res) => {
        const iso = res.countryCode;
        const city = res.city;
        const name = res.selectedLabel;
        const id = res.geid;
        const displayGeid = res.geid;
        const buCode = res?.level2OrgCode;
        const selectedLabel = res?.selectedLabel;
        const email = res?.selectedLabel;
        const deactivateDate = res?.deactivateDate;
        return { id, displayGeid, buCode, name, iso, city, email, deactivateDate, selectedLabel }
      });
      this.sendData.interestedParties= partyData;
    }
    else{this.sendData.interestedParties = [];
    }

    if (this.route.snapshot.paramMap.get('id') != null) {
      this.sendData.actionCreator = this.actionCreate.controls['actionCreator'].value![0];
      this.sendData.status = this.actionCreate.controls['status']?.value;
      this.sendData.rowVersion = this.actionCreate.controls['rowVersion'].value;
      this.sendData.status = this.actionCreate.controls['status'].value;
      this.sendData.statusId = this.actionCreate.controls['statusId'].value;
      this.sendData.createDate = this.actionCreate.controls['createDate'].value;
      this.sendData.creatorGeid = this.actionCreate.controls['creatorGeid'].value;
      this.sendData.isRejected = this.actionCreate.controls['isRejected'].value;
      this.sendData.isReopened = this.actionCreate.controls['isReopened'].value;
      this.sendData.lastUpdateDate = this.actionCreate.controls['lastUpdateDate'].value;
      this.sendData.ownerName = this.actionCreate.controls['ownerName'].value;
      this.sendData.permission = this.actionCreate.controls['permission'].value;
      this.sendData.rejectReason = this.actionCreate.controls['rejectReason'].value;
      this.sendData.rowVersion = this.actionCreate.controls['rowVersion'].value;
    }
  }

  completeCard() {
    this.isSubmitted = true;
    if (this.targetDateValidation && this.selected.type != 27) {
      this.messageService.add({
        severity: 'error',
        summary: 'Please Select Valid Action Deadline Date.'
      })
    }
    else if(this.actionCreate.get('activities').value=='' || this.actionCreate.get('activities').value==null
    || this.actionCreate.get('activities').value==undefined){
      this.messageService.add({
        severity: 'error',
        summary: 'Please fill all mandatory fields.'
      })
    }
    else {
      this.spinner.show();
      this.saveMade = false;
      this.mapFormDataToRequest();
      this.actionPlanService.completeItem(this.sendData).subscribe(
        (resp) => {
          this.isSubmitted = false;
          this.spinner.hide();
          this.returnToActionPlanBoard();
          this.messageService.add({
            severity: 'success',
            summary: 'Action successfully completed.'
          })
        }, (error) => {
          this.spinner.hide();
          this.messageService.add({
            severity: 'error',
            summary: 'Error while closing action'
          })
        });
    }
  }


  rejectCard() {
    this.ref = this.dialogService.open(RejectCardModalComponent, {
      header: 'Reject Action',
      width: '80%',
      position: 'top'
    });

    this.ref.onClose.subscribe((res) => {
      if (res.result) {
        this.mapFormDataToRequest();
        this.sendData.rejectReason = res.result;
        this.spinner.show();
        this.actionPlanService.rejectItem(this.sendData).subscribe(() => {
          this.returnToActionPlanBoard();
          this.spinner.hide();
          this.messageService.add({
            severity: 'success',
            summary: 'Action was rejected.'
          })
        }, (error) => {
          this.spinner.hide();
          this.messageService.add({
            severity: 'error',
            summary: "Error while rejecting action"
          })
        });
      }
    })
  }

  myUploaderDescription(createUploader, event) {
    /* forkJoin(
       event.files.map(file =>{
         let formData = new FormData();
         formData.append("fileUploadObj", file);
         this.actionPlanService.actionBoardAttachmentSave(formData).pipe(
           map((response)=> {
             let attachIdChange = response;
             attachIdChange.id = (attachIdChange?.fileId)?.toString();
             attachIdChange.sectionId = 1;
             console.log("attachIdChange==>",attachIdChange);
             return updatedProvider;
           }))
         })
     ).subscribe((p: Provider[][]) => {
       // flatten the multi-dimensional array
       const updatedProviders = [].concat(...p);

       this.updateProvidersInState(updatedProviders);
     });
 */

    for (var i = 0; i < event.files.length; i++) {
      this.spinner.show();
      if (i >= this.actionPlanAttachmentDesc?.length) {
        let formData = new FormData();
       // console.log("fileUploadObj", event.files[i]);
        formData.append("fileUploadObj", event.files[i]);
        this.actionPlanService.actionBoardAttachmentSave(formData).subscribe(async (response: any) => {
          let attachIdChange = response;
          attachIdChange.id = (attachIdChange?.fileId)?.toString();
          attachIdChange.sectionId = 1;
          this.actionPlanAttachmentDesc.push(attachIdChange);
          this.actionPlanFile = [...this.actionPlanAttachmentDesc, ...this.actionPlanAttachment]
          this.actionCreate.patchValue({
            files: this.actionPlanFile
          });
          this.spinner.hide();
        },
        (error) => {
            this.spinner.hide();
            this.messageService.add({
                severity: 'error',
                summary: error ? error : 'Something went wrong!'
            });
        });
      }
    }
  }

  removeDescriptionFile(file: File, uploader: FileUpload) {
    this.spinner.show();
    const index = uploader.files.indexOf(file);
    uploader.remove(event, index);
    let filename = this.actionPlanAttachmentDesc.filter(attachment=> attachment?.name==file?.name);
    this.actionPlanService.actionBoardRemoveAttachments(filename[0]?.fileId).subscribe((res) => {
      this.actionPlanAttachmentDesc = this.actionPlanAttachmentDesc.filter(attachment => attachment?.fileId !== filename[0]?.fileId);
      this.actionPlanFile = [...this.actionPlanAttachmentDesc, ...this.actionPlanAttachment]
      this.actionCreate.patchValue({
        files: this.actionPlanFile
      });
      this.spinner.hide();
    })
  }

  uploadedDescriptionAttachmentRemove(file) {
    this.spinner.show();
    this.actionPlanService.actionBoardRemoveAttachments(file?.fileId).subscribe((res) => {
      this.informationAttachmentsDesc = this.informationAttachmentsDesc.filter(attachment => attachment?.fileId !== file?.fileId);
      this.spinner.hide();
    })
  }

  checkDescriptionAttchmentRemoved(attachmentIndex) {
    if ((this.informationAttachmentsHolderDesc.findIndex(attachment => attachment?.fileId == this.informationAttachmentsDesc[attachmentIndex]?.fileId)) == -1) {
      return true;
    }
    else {
      return false;
    }
  }

  myUploaderActivities(createUploader, event) {
    for (var i = 0; i < event.files.length; i++) {
      this.spinner.show();
      if (i >= this.actionPlanAttachment?.length) {
        let formData = new FormData();
        formData.append("fileUploadObj", event.files[i]);
        this.actionPlanService.actionBoardAttachmentSave(formData).subscribe((response: any) => {
          let attachIdChange = response;
          attachIdChange.id = (attachIdChange?.fileId)?.toString();
          attachIdChange.sectionId = 2;
          this.actionPlanAttachment.push(attachIdChange);
          this.actionPlanFile = [...this.actionPlanAttachmentDesc, ...this.actionPlanAttachment]
          this.actionCreate.patchValue({
            files: this.actionPlanFile
          });
          this.spinner.hide();
        })
      }
    }
  }

  removeActivityFile(file: File, uploader: FileUpload) {
    this.spinner.show();
    const index = uploader.files.indexOf(file);
    uploader.remove(event, index);
    let filename = this.actionPlanAttachment.filter(attachment => attachment?.name == file?.name);
    this.actionPlanService.actionBoardRemoveAttachments(filename[0]?.fileId).subscribe((res) => {
      this.actionPlanAttachment = this.actionPlanAttachment.filter(attachment => attachment?.fileId !== filename[0]?.fileId);
      this.actionPlanFile = [...this.actionPlanAttachmentDesc, ...this.actionPlanAttachment]
      this.actionCreate.patchValue({
        files: this.actionPlanFile
      });
      this.spinner.hide();
    })
  }

  uploadedActivityAttachmentRemove(file) {
    this.spinner.show();
    this.actionPlanService.actionBoardRemoveAttachments(file?.fileId).subscribe((res) => {
      this.informationAttachments = this.informationAttachments.filter(attachment => attachment?.fileId !== file?.fileId);
      this.spinner.hide();
    })
  }

  checkActivityAttachmentRemoved(attachmentIndex) {
    if ((this.informationAttachmentsHolder.findIndex(attachment => attachment?.fileId == this.informationAttachments[attachmentIndex]?.fileId)) == -1) {
      return true;
    }
    else {
      return false;
    }
  }
  downloadAttachementFromAPI(storeFileName, displayName) {
    this.downloadAttachementService.downloadAttachement(storeFileName, displayName);
  }
}
