import { Component, OnInit, OnDestroy, Inject, LOCALE_ID, HostListener } from '@angular/core';
import { filter, Subject, takeUntil } from 'rxjs';

//msal Authentication
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration } from '@azure/msal-angular';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { UserInfoDetailsService } from './services/userInfoDetails/user-info-details.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthTokenService } from './services/auth-token.service';
import { AzureADUserStatusService } from './services/azure-aduser-status.service';
import { UserIdleService } from 'angular-user-idle';
import { environment } from 'src/environments/environment';
import { SessionStorageServiceService } from './services/session-storage-service.service';
import { EmployeeDetails, userProfileModal } from './reusables/user-info-details/user-info-details/user-info-details.component';
import { Router } from '@angular/router';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ToasterserviceService } from './services/toasterservice.service';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from './services/breadcrumb.service';
import { MdServiceService } from './services/md-service.service';
import { MdRegionService } from './services/md-region.service';
import { ExternalpageEmitService } from './components/external/externalpage-emit.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SystemTimeoutWarningModalComponent } from './reusables/system-timeout-warning-modal/system-timeout-warning-modal.component';
import { DashboardService } from './services/dashboard.service';
import { AppInsightsService } from './services/app-insights.service';
import { ComplaintDetailsService } from './services/complaintDetails/complaint-details.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  //Footer Year && Version
  currentYearForFooter: number = new Date().getFullYear();
  currentCCRPVersion: string = environment.appVersion;
  //userprofileData
  userProfileData !: any;
  currentCulture: string;
  isUserLoggedIn: boolean = false;
  private readonly _destroy = new Subject<void>();
  activeAccount!: any;
  userInformationDetails!: userProfileModal;
  employeeDetails: EmployeeDetails;
  isExternalPage: boolean = false;
  isHRGTPage: boolean = false;
  notificationLabel:string= environment.notificationLabel;
  notificationValue:string= environment.notificationValue;
  surveyText:string = 'Please Click here to give feedback on the CCRP application.' ;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadCastService: MsalBroadcastService,
    private authService: MsalService,
    private azureAdUserStatusService: AzureADUserStatusService,
    private userInfoDetails: UserInfoDetailsService,
    private translate: TranslateService,
    private authToken: AuthTokenService,
    @Inject(LOCALE_ID) private locale: string,
    private userIdle: UserIdleService,
    private sessionStorage: SessionStorageServiceService,
    private route: Router,
    private spinner: NgxSpinnerService,
    private toaster: ToasterserviceService,
    public breadcrumbService: BreadcrumbService,
    private mdServiceService: MdServiceService,
    private mdRegionService: MdRegionService,
    private externalPageService: ExternalpageEmitService,
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
    private dashboardService: DashboardService,
    private appInsightService: AppInsightsService,
    private complaintDetailsService: ComplaintDetailsService) {

    //language translation
    translate.setDefaultLang('en');
    const browserLang = locale.substring(0, 2);
    this.currentCulture = browserLang;
    this.translate.use(browserLang);
    //const browserLang = 'fr';

    // let lang = ['en', 'fr', 'dv', 'gr'];
    // const cond = lang.includes(browserLang);

    // if (cond) {
    //   this.currentCulture = browserLang;
    //   this.translate.use(browserLang);
    // }
    // else {
    //   this.currentCulture = 'en';
    //   this.translate.use('en');
    // }
  }

  home: MenuItem = { label: 'CCRP', routerLink: '/mydashboard', icon: 'null' };

  ngOnInit(): void {
    this.oldLinksRedirects()
    this.breadcrumbService.menuItems = [
      { label: 'DASHBOARD', routerLink: '/mydashboard' },
      { label: 'MY DASHBOARD' }];

    this.msalBroadCastService.inProgress$.pipe
      (filter((interactionStatus: InteractionStatus) =>
        interactionStatus == InteractionStatus.None),
        takeUntil(this._destroy))
      .subscribe(x => {
        this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0
        this.activeAccount = this.authService.instance.getAllAccounts()[0];
        if(this.sessionStorage.getData('userInfo')?.email){
          this.authToken.setAuthToken(this.sessionStorage.getData('userInfo')?.email, this.currentCulture);
        }else{
          this.authToken.setAuthToken(this.activeAccount?.username, this.currentCulture);
        }
        this.azureAdUserStatusService.isUserLoggedIn.next(this.isUserLoggedIn);
        this.UserDetails();
      })

    document.addEventListener('visibilitychange', () => this.handleVisibilityChange());

    localStorage.setItem('sessionExpiredAlertShow', 'false');
    document.addEventListener('click', this.updateLastActivityTimestamp);
    document.addEventListener('keydown', this.updateLastActivityTimestamp);

    this.updateLastActivityTimestamp();

    //this.UserDetails();

    this.msalBroadCastService.msalSubject$.subscribe({
      next : (msalSubject) =>{
        if(msalSubject.eventType === EventType.ACQUIRE_TOKEN_FAILURE){
          this.logout();
        }
      }
    })

    this.userIdle.setConfigValues({ idle: 300, timeout: 60, ping: 0 });
    //Start watching for user inactivity.
    this.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
      // console.log(count);
      this.checkUserInactivity();
      if(count == environment.sessionIdleTime){
        this.complaintDetailsService.setTimerFlag(true);
      }
      else if(count > environment.sessionLogoutTime) {
        this.logout();
      }
    });
    this.externalPageService.isSETExternalPage.subscribe(async detail => {
      if (detail === true) {
        this.isExternalPage = true;
      }
    });

    this.externalPageService.isSETHRGTPage.subscribe(async detail => {
      if (detail === true) {
        this.isHRGTPage = true;
      }
    });

  }

  oldLinksRedirects() {
    const currentPath = window.location.hash;
    if(currentPath === '#/dashboard/my') {
        this.route.navigateByUrl('mydashboard');
    }
    else if(currentPath === '#/dashboard/actionsdashboard') {
        this.route.navigateByUrl('myactionplan');
    }
    else if(currentPath === '#/dashboard') {
        this.route.navigateByUrl('mydashboard');
    }
    else if(currentPath === '#/reports') {
        this.route.navigateByUrl('reports');
    }
    else if(currentPath === '#/reports/AllInOne') {
        this.route.navigateByUrl('reports/AllInOne');
    }
    else if(currentPath === '#/reports/weeklyReport') {
        this.route.navigateByUrl('reports/weeklyreport');
    }
    else if(currentPath === '#/search/global/') {
        this.route.navigateByUrl('globalsearch');
    }
    else if(currentPath === '#/search/coordinator') {
        this.route.navigateByUrl('search/coordinator');
    }
    else if(currentPath === '#/search/processowner') {
        this.route.navigateByUrl('posearch');
    }
    else if(currentPath === '#/search/hubProcessOwner') {
        this.route.navigateByUrl('search/hubProcessOwner');
    }
    else if(currentPath === '#/help-desk') {
        this.route.navigateByUrl('helpdesk');
    }
    else if(currentPath === '#/admin') {
        this.route.navigateByUrl('admin');
    }
    else if(currentPath === '#/admin/resolving-units') {
        this.route.navigateByUrl('admin/resolving-units');
    }
    else if(currentPath === '#/admin/customer-contacts') {
        this.route.navigateByUrl('admin/customer-contacts');
    }
    else if(currentPath === '#/admin/hubs') {
        this.route.navigateByUrl('admin/hubs');
    }
    else if(currentPath === '#/admin/global-rus') {
        this.route.navigateByUrl('admin/global-ru');
    }
    else if(currentPath === '#/admin/process-owners') {
        this.route.navigateByUrl('admin/processowners');
    }
    else if(currentPath === '#/admin/acc-person') {
        this.route.navigateByUrl('admin/accountable-person');
    }
    else if(currentPath === '#/admin/pg-categories') {
        this.route.navigateByUrl('admin/pg-categories');
    }
    else if(currentPath === '#/admin/failure-modes') {
        this.route.navigateByUrl('admin/failuremode');
    }
    else if(currentPath === '#/admin/process-steps') {
        this.route.navigateByUrl('admin/process-steps');
    }
    else if(currentPath === '#/admin/active-employee') {
        this.route.navigateByUrl('admin/active-employee');
    }
    else if(currentPath === '#/admin/translations') {
        this.route.navigateByUrl('admin/translations');
    }
    else if(currentPath === '#/admin/system-users') {
        this.route.navigateByUrl('admin/system-users');
    }
    if(window.location.pathname === '/ReportViewer.aspx') {
        const params = window.location.search;
        this.route.navigateByUrl('/AllInOne/export' + params);
    }
  }

  handleVisibilityChange() {
    if(!document.hidden) {
      const lastActivityTimestamp = localStorage.getItem('lastActivityTimestamp');
      localStorage.setItem('sessionExpiredAlertShow', 'false');
      const sessionExpiredAlertShow = localStorage.getItem('sessionExpiredAlertShow');
      if(lastActivityTimestamp) {
        const currentTime = new Date().getTime();
        const idleDuration = currentTime - parseInt(lastActivityTimestamp, 10);
        if(idleDuration >= environment.sessionIdleTime * 1000 && sessionExpiredAlertShow != 'true') { // 300 second (5 minutes)
          // console.log('popup will come')
          this.showConfirmationAlert();
          localStorage.setItem('sessionExpiredAlertShow', 'true');
        }
      }
    }
  }

  // Function to update the last activity timestamp
  updateLastActivityTimestamp() {
    const now = new Date().getTime();
    localStorage.setItem('lastActivityTimestamp', now.toString());
    localStorage.setItem('sessionExpiredAlertShow', 'false');
  }

  // Check for user inactivity
  checkUserInactivity() {
      const lastActivityTimestamp = localStorage.getItem('lastActivityTimestamp');
      const sessionExpiredAlertShow = localStorage.getItem('sessionExpiredAlertShow');
      if(lastActivityTimestamp) {
        const currentTime = new Date().getTime();
        const idleDuration = currentTime - parseInt(lastActivityTimestamp, 10);

        if(idleDuration >= environment.sessionIdleTime * 1000 && sessionExpiredAlertShow != 'true') { // 300 second (5 minutes)
          // console.log('popup will come')
          this.showConfirmationAlert();
          localStorage.setItem('sessionExpiredAlertShow', 'true');
        }
      }
  }

  userAutoLogout() {
    const lastActivityTimestamp = localStorage.getItem('lastActivityTimestamp');
    if(lastActivityTimestamp) {
      const currentTime = new Date().getTime();
      const idleDuration = currentTime - parseInt(lastActivityTimestamp, 10);
      if(idleDuration >= 900000) { // After 15 minutes user will auto logout
        localStorage.removeItem('lastActivityTimestamp')
        this.logout();
      }
    }
  }

  UserDetails() {
    //setTimeout(() => this.spinner.show(), 1);

    //Email Id has to be changed to logged in user account email to : this.activeAccount?.username
    if (!this.sessionStorage.getData('userInfo')) {
      this.appInsightService.setUserId(this.activeAccount?.username);
      this.userInfoDetails.getUserInfo(this.activeAccount?.username).subscribe((resp) => {
        if (resp.geid == null) {
          this.route.navigateByUrl('hrgterror');
        } else {
          this.userInformationDetails = resp;
          this.sessionStorage.setData('userInfo', resp);

          this.userInfoDetails.getEmployeeDetails(this.sessionStorage.getData('userInfo').geid).then((resp) => {
            this.employeeDetails = resp[0];
            this.userInformationDetails.employeeDetails = this.employeeDetails;

            this.userInformationDetails.processOwnerScopes.forEach((poScope) => {
              if (!poScope.div && !poScope.bu && !poScope.pg) {
                poScope.location = true;
              } else {
                poScope.unit = true;
              }
            });
            this.userProfileData = this.userInformationDetails;
            this.sessionStorage.setData('userInfo', this.userProfileData);

            this.dashboardService.setGeid(this.sessionStorage.getData('userInfo').geid);

            // setTimeout(() => this.spinner.hide(), 1);
          },
            (error) => {
              setTimeout(() => this.spinner.hide(), 1);
              if (!this.isExternalPage) {
                this.toaster.showError('Error while fetching employee details');
              }
            }
          )
        }
      },
        (err) => {
          if (!this.isExternalPage) {
            //this.toaster.showError('Error while fetching employee details');
            this.route.navigateByUrl('maintenance');
          }
          setTimeout(() => this.spinner.hide(), 1);
        });
      this.mdServiceService.loadMdData();
      this.mdRegionService.loadMdData();
    } else {
      this.appInsightService.setUserId(this.sessionStorage.getData('userInfo')?.email);
      this.userProfileData = this.sessionStorage.getData('userInfo');
      this.mdServiceService.loadMdData();
      this.mdRegionService.loadMdData();
      //setTimeout(() => this.spinner.hide(), 1);
    }
  }

  showConfirmationAlert() {
    let dialogueBoxCount = +this.sessionStorage.getData('sessionBoxCount') || 0;

    if(dialogueBoxCount > 0){

    }else{
      dialogueBoxCount++;
      this.sessionStorage.setData('sessionBoxCount',dialogueBoxCount);
      this.ref = this.dialogService.open(SystemTimeoutWarningModalComponent, {
        header: 'Session Expire Warning',
        styleClass: 'system-timeout-modal',
        position: 'top',
        width: '50%',
      });

      this.ref.onClose.subscribe((data) => {

        if (data?.result == 'ok') {
          this.userIdle.resetTimer();
          dialogueBoxCount--;
          this.sessionStorage.setData('sessionBoxCount',dialogueBoxCount);
        }
        if (data?.result == 'none') {
          dialogueBoxCount--;
          this.sessionStorage.setData('sessionBoxCount',dialogueBoxCount);
          this.logout();
        }
        else {
          this.userIdle.resetTimer();
        }
      })
    }
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  logout() {
    this.authService.logoutRedirect();
  }

  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete();
  }

  showYammerTooltip():string{
    let temp:string = ``;
    temp+=`<span>Please Join Hitachi Energy CCRP Group in Viva Engage to get latest updates about CCRP!</span>
    <button class="btn btn-outline-primary rounded-pill border-secondary btn-sm mt-1" style="font-size:12px; min-width:80px" ><span style="background: blue;color:white !important;border-radius: 50%; margin-right:3px; padding:1px 3px">+</span><b>Join</b></button>`;
    return temp;
  }


}
