import { Component, Input, OnInit, Output, ViewChild, SimpleChanges, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';


import { IssueResolutionServiceService } from 'src/app/services/issue-resolution-service.service';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';

import { HttpClient } from '@angular/common/http';
// import { PGRCACategoryPopUpComponent } from '../pgrcacategory-pop-up/pgrcacategory-pop-up.component';
import { COPQCategoryPopUpComponent } from '../copqcategory-pop-up/copqcategory-pop-up.component';
import { PGRCACategoryPopUpComponent } from '../pgrcacategory-pop-up/pgrcacategory-pop-up.component';
import { IncidentReviewerAndImpactDataModel } from 'src/app/models/new-issue/incident-reviewer-and-impact-data.model';
import { IssueResolutionCurrentRatingDataService } from 'src/app/services/issue-resolution-current-rating-data.service';
import { StaticvalueConstants } from 'src/app/reusables/constants/staticvalue.constants';
import { ComplaintDetailsService } from 'src/app/services/complaintDetails/complaint-details.service';
import { SendEmailIssueAssignComponent } from '../send-email-issue-assign/send-email-issue-assign.component';
import { ButtonVisibilityServiceService } from 'src/app/services/button-visibility-service.service';
import { ComplaintStatuses, PermissionCode, RoleCode } from 'src/app/enum';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { CurrentratingConfirmPopupComponent } from '../currentrating-confirm-popup/currentrating-confirm-popup.component';
import { MatrixClass } from 'src/app/models/new-issue/MatrixClass';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { DefectTypePopupComponent } from '../defect-type-popup/defect-type-popup.component';
import { DefectCausePopupComponent } from '../defect-cause-popup/defect-cause-popup.component';
import { DownloadAttachmentService } from 'src/app/services/download-attachment.service';
import { maxLengthConstants } from 'src/app/reusables/constants/maxlength.constants';
import { ReopenIssuePopupComponent } from '../reopen-issue-popup/reopen-issue-popup.component';

interface category {
  name: string;
  id: number;
  sortOrder: number,
  dataValue: string,
  dataText: string,
  selected: boolean
}
interface impactOnInstalledBase {
  number: any;
  value: any;
}
interface totalInstalledBase {
  number: any;
  value: any;
}

@Component({
  selector: 'app-issue-resolution',
  templateUrl: './issue-resolution.component.html',
  styleUrls: ['./issue-resolution.component.css'],
  providers: [MessageService, DialogService],
})

export class IssueResolutionComponent implements OnInit, OnChanges, AfterViewInit {
  public impactRatingItems!: Array<{ id: string; text: string }>;
  public availabilityRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public functionalityRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public onTimeDeliveryRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public cyberSecurityRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public productComplianceRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public ohsRiskRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public marginRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public reputationRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public massMediaRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public designChangeRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public instancesRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public resolutionRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public affectedCustomerRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;
  public controlRatingItems!: Array<{
    value: number;
    text: string;
    weight: number;
  }>;

  lessonData: any;
  @Input() public formData!: IncidentReviewerAndImpactDataModel;
  public issueResolutionForm!: FormGroup;
  @Input() formGroupName!: string;
  @Input() isSubmitted: boolean = false;
  @Input() complaintData !: any;
  @Input() issueCaptureMainForm: FormGroup;
  complaintDataLessonLearned: any;
  //end//
  @Input() RCAandCorrectiveActionFromIssueRes: boolean = false;
  @Input() ContainmentActionsRequiredFromIssueRes: boolean = false;
  @Output() classOriginalValue = new EventEmitter();
  @Output() resolvedEventPassed = new EventEmitter();
  @Output() verificationPassed = new EventEmitter();
  @Output() containmentToResolvedPassed = new EventEmitter();
  @Output() revertToNotAssignedEmailPassed = new EventEmitter();
  @Output() saveDataPassed = new EventEmitter();
  @Output() revertToResolutionOwnerEmailPassed = new EventEmitter();
  @ViewChild('fileUpload') fileupload!: FileUpload;
  @Input() complaintValidations: any = [];
  @Input() ActionNameForComplaints: string = "Resolve";
  @Input() fieldNameForFailureMode: string = "None";
  @Output() changeJustificationReadOnly = new EventEmitter();
  @Output() changeReviewRequiredId = new EventEmitter();
  @Output() rejectComplaintEventPassed = new EventEmitter();
  @Output() VerifySerialEventFromRCAPassed = new EventEmitter();
  @Output() VerifyResolutionByBuProcessOwnerEventPassed = new EventEmitter();

  counterData: any;
  submitted: boolean = false;
  pageActionItems: number;
  actionItemsData: any;
  url: any = window.location.href;
  textDATA = '';
  textLABEL = '';
  COPQCategoryTooltipText = "Please select the Cost of Poor Quality (COPQ) category related to this issue.";
  pgCategoryTooltipText = "Please select the Cost of Poor Quality (COPQ) category related to this issue.";
  // textValue = '';
  estimatedCost: string = 'Estimated cost in k$';
  qNumberTip: string = 'Use this field to provide Q-field of resolving unit';
  rcaCheckBoxTip: string = 'Select this checkbox if corrective actions and a root cause analysis are required. Corrective actions and root cause analysis are mandatory by default for Class A / B / I CCRPs or where the customer has requested corrective actions and root cause analysis.';
  specificationNameTip: string = 'Please indicate the title of the specification / purchase order document that describes the technical scope we have agreed upon with the customer.';
  failMeetSpecTip: string = 'Please indicate how the product deviates from the agreed scope described in the indicated specification.';
  containtmentActiontakeTip: string = 'Are there any immediate actions that need to be taken to protect the customer? If so, use this space to describe the dangers. Include any dangers/actions relating to other products, lines or sites that this issue might affect. And include any dangers/actions relating to the management or quarantine of Work in Progress from suppliers through to customers.';
  rootCauseTip: string = 'Use this space to describe the root cause(s) of the problem. The Resolution Team should agree on which is the most likely root cause of the problem. You can use the 5 Whys part of the &quot;Fishbone & 5 Whys&quot; tool to help you structure your work. ';
  categoryKeywordYip: string = 'Please use a keyword to categorize the CCRP';
  resolveIssueTip: string = "Use this space to show what has been done to eliminate the root cause of the problem and make the solution permanent. The Resolution Team needs to agree the best long-term solution that eliminates the root cause. This should include thinking about an organized workplace (5S) and how to ensure that the problem doesn't happen again. It may involve piloting to prove that it does eliminate the root cause.";
  Required8DTip: string = "“8D Report is a mandatory field for all High Impact Class CCRP.This field is accessible only by Power Grid Business Process Owner & Head of Continuous Improvement under some exceptional cases”.";
  upload8DTip: string = 'Please note that a completed 8D report of good quality is needed to resolve a Class A / Class B / Class I CCRP';
  actualRUTip: string = 'Use this field to provide Actual Resolving Unit Cost in k$';
  defectCauseToolTip = 'Use this field to provide defect cause/defect cause code group details';
  productShippedTooltip = "Please indicate, if the affected product / service has already been delivered to the customer";
  noOfUnitShippedTooltip = "Please indicate, how many affected products have already been delivered to the customer? For affected services please select N/A.";


  selectedRole: any;
  ratingValue: any;
  preview: string = '';
  selectedRoleName : any[];

  categoryData: category[];

  selectedCategory!: category[];

  date: any;
  availabilty: any;
  Functionality: any;
  On_Time_Delivery: any;
  Cyber_Security: any;
  Product_Compliance: any;
  OHS_Risk: any;
  Margin: any;
  Reputation: any;
  Mass_media: any;
  Design_change: any;
  Instances: any;
  Affected_customer: any;
  Resolution: any;
  Control: any;
  paramForAttachment: any

  availabiltyLabel: any;
  FunctionalityLabel: any;
  On_Time_DeliveryLabel: any;
  ControlLabel: any;
  Affected_customerLabel: any;
  ResolutionLabel: any;
  InstancesLabel: any;
  Design_changeLabel: any;
  Mass_mediaLabel: any;
  ReputationLabel: any;
  MarginLabel: any;
  OHS_RiskLabel: any;
  Product_ComplianceLabel: any;
  Cyber_SecurityLabel: any;
  //isSubmitted: boolean = false;

  isAssign: boolean = false;
  issueAssigned = false;
  isResolved: boolean = false;
  isRevertToNotAssign: boolean = false;
  verifier: boolean = false;
  isContainmentClicked: boolean = false;
  issueStatusVariable: string = 'In Process';
  impactOnInstalledBaseDropDown!: impactOnInstalledBase[];
  totalInstalledBaseDropDown!: totalInstalledBase[];
  balIdPass: any;
  resolvingUnitId: any;
  isRoleCoOrdinator: boolean = false;
  buttonFlag = false;

  resolvedbuttontoShow: boolean = false;
  isRoleWithContainmentActionsSupportDesk: boolean = false;
  // for lesson learned
  [x: string]: any;
  userdata: any = [];
  addEmployee: any;
  isDeleted: boolean = false;
  isEdit: boolean = false;
  isManualChangeForCurrentRating: boolean = false;
  issueForm: UntypedFormGroup = new UntypedFormGroup({
    learn: new UntypedFormControl(''),
    desc: new UntypedFormControl(''),
  });

  availabilityRatingConf !: any[];
  functionalityRatingConf !: any[];
  onTimeDeliveryRatingConf !: any[];
  cyberSecurityRatingConf !: any[];
  productComplianceRatingConf !: any[];
  ohsRiskRatingConf !: any[];
  marginRatingConf !: any[];
  reputationRatingConf !: any[];
  massMediaRatingConf !: any[];
  designChangeRatingConf !: any[];
  instancesRatingConf !: any[];
  resolutionRatingConf !: any[];
  affectedCustomerRatingConf !: any[];
  controlRatingConf !: any[];
  removeText = 'Remove';
  issueAttachmentPDCA: any = [];
  issueAttachmentSummary: any = [];
  UploadRCAAttachments: any = [];
  resolutionAttachments: any = [];
  uploadRCAAttachmentsHolder: any = [];
  resolutionAttachmentsHolder: any = [];
  issueAttachmentPDCAMerged: any = [];
  issueAttachmentSummaryMerged: any = [];
  getMatrixTreeActiveVariable: any;
  percent: any;
  additionalSubjectData: any;
  manualChangeClass: any;
  reviewAndReportRequired: any;
  reviewRequired_Id: boolean = false;
  isEightDReportReadOnly: boolean = false;
  disableSection: any = true;
  disableRCACheckBox: boolean = false;
  highImpactDataRCA: any;
  issueStatus: any;
  @Input() isEnableSAPQN: boolean;

  enumPermission = PermissionCode;
  enumRoleCode = RoleCode;
  enumComplaintStatuses = ComplaintStatuses;
  userAccessData = null
  disableBtn: any = {};
  userInfo: any;

  justificationReadOnly: boolean = true;
  isEightDReportJustificationReadOnly: boolean = true;
  isResolutionOwner : any;
  isEightDRequiredGlobalPO : any;
  isEightDRequiredPGProcessOwner : any;
  baseurl = environment.azure.baseUrl;
  downloadAllUrlrca = environment.azure.baseUrl+'api/v1/Attachment/downloadmany?storeFilesNames=';
  downloadAllUrlresolution = environment.azure.baseUrl+'api/v1/Attachment/downloadmany?storeFilesNames=';
  manualChangeVisible: boolean = false;
  resolvingUnitBalId : any;
  readonly maxLength = maxLengthConstants;
  isVerifiedCoordinator: boolean = true;
  complaintCreatedDate:any = new Date();
  ncrLiveDate:any = new Date(environment.ncrLiveDate);
  productShippedValues = [
    'Yes',
    'No',
  ];

  noOfUnitShipped = [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11-100',
      '101-1000',
      'Over 1000',
      'N/A',
  ];

  isClassCanbeRejected:boolean=false;
  IsBuVerified:boolean = false;

  @Input() role : any;
  constructor(
    private fb: FormBuilder,
    private irService: IssueResolutionServiceService,
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    // for lesson learned
    private messageService: MessageService, // private fileService: FileService
    private rootFormGroup: FormGroupDirective,
    private complaintDetailsService: ComplaintDetailsService,
    private ircrService: IssueResolutionCurrentRatingDataService,
    public authorizationService: AuthorizationService,
    private spinner: NgxSpinnerService,
    private buttonVisibilityService: ButtonVisibilityServiceService,
    private sessionStorage: SessionStorageServiceService,
    private downloadAttachementService:DownloadAttachmentService
  ) {
    this.categoryData = [
      {
        "id": 1,
        "name": "External Audits",
        "sortOrder": 1,
        "dataValue": "1",
        "dataText": "External Audits",
        "selected": false
      },
      {
        "id": 2,
        "name": "Internal Audits",
        "sortOrder": 2,
        "dataValue": "2",
        "dataText": "Internal Audits",
        "selected": false
      },
      {
        "id": 3,
        "name": "Lessons Learned",
        "sortOrder": 3,
        "dataValue": "3",
        "dataText": "Lessons Learned",
        "selected": false
      },
      {
        "id": 4,
        "name": "Transfer of Experience",
        "sortOrder": 4,
        "dataValue": "4",
        "dataText": "Transfer of Experience",
        "selected": false
      },
      {
        "id": 5,
        "name": "Forced Outage",
        "sortOrder": 5,
        "dataValue": "5",
        "dataText": "Forced Outage",
        "selected": false
      },
      {
        "id": 6,
        "name": "Base design",
        "sortOrder": 6,
        "dataValue": "6",
        "dataText": "Base design",
        "selected": false
      },
      {
        "id": 7,
        "name": "Material Inspection",
        "sortOrder": 7,
        "dataValue": "7",
        "dataText": "Material Inspection",
        "selected": false
      }
    ];
    this.impactOnInstalledBaseDropDown = [
      { number: 'None (Single issue)', value: 'None (Single issue)' },
      { number: '> 5%', value: '> 5%' },
      { number: '5% - 10%', value: '5% - 10%' },
      { number: '10% - 50%', value: '10% - 50%' },
      { number: '50% - 90%', value: '50% - 90%' },
      { number: '90% - 100%', value: '90% - 100%' }
    ];
    // this.issueResolutionForm = fb.group({
    //   EstimatedCostView: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
    //   })
    this.totalInstalledBaseDropDown = [
      { number: 'N/A (Single issue)', value: 'N/A (Single issue)' },
      { number: '1 - 100', value: '1 - 100' },
      { number: '101 - 1000', value: '101 - 1000' },
      { number: '1001 - 10.000', value: '1001 - 10.000' },
      { number: '> 10.000', value: '> 10.000' },
    ];

    this.manualChangeClass = [
      {
        "id": 1,
        "name": "None",
        "sortOrder": 1,
        "dataValue": "None",
        "dataText": "None",
        "selected": true
      },
      {
        "id": 2,
        "name": "A",
        "sortOrder": 2,
        "dataValue": "A",
        "dataText": "A",
        "selected": false
      },
      {
        "id": 3,
        "name": "B",
        "sortOrder": 3,
        "dataValue": "B",
        "dataText": "B",
        "selected": false
      },
      {
        "id": 4,
        "name": "C",
        "sortOrder": 4,
        "dataValue": "C",
        "dataText": "C",
        "selected": false
      },
      {
        "id": 5,
        "name": "I",
        "sortOrder": 5,
        "dataValue": "I",
        "dataText": "I",
        "selected": false
      }
    ];

    this.reviewAndReportRequired = [
      { value: 'Yes' },
      { value: 'No' },
    ];
  }

  ngOnInit(): void {
    this.issueResolutionForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.paramForAttachment = { 0: '60 MB' };
    this.date = new Date();
    this.complaintDataLessonLearned = this.complaintData;
    this.percent = '100%'
    this.userInfo = this.sessionStorage.getData('userInfo');
    this.issueResolutionForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.additionalSubjectData = this.rootFormGroup.control.get('additionalSubjectForm') as FormGroup;
    this.issueResolutionForm.controls['issueAssigned']?.valueChanges.subscribe(
      (res) => {
        this.issueAssigned = res;
      }
    );

    if(this.issueResolutionForm.controls['manualChangeClass']?.value == 'None') {
      this.issueResolutionForm.controls['eightDReportRequired'].disable();
    }

    if(this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.resolvingUnit?.id != null){
      this.irService.getResolvingUnitBalId(this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.resolvingUnit?.id).subscribe((res)=>{
        if(res){
          this.resolvingUnitBalId = res.balId;
        }
      })
    }

    this.ircrService.ratingConfiguration().subscribe((response) => {
      this.getMatrixTreeActiveVariable = response;
      // console.log('response of getMatrixTree', this.getMatrixTreeActiveVariable)
      this.availabilty = this.getMatrixTreeActiveVariable.data[0].matrixSeverity;
      this.availabiltyLabel = this.getMatrixTreeActiveVariable.data[0].name;
      this.Functionality = this.getMatrixTreeActiveVariable.data[1].matrixSeverity;
      this.FunctionalityLabel = this.getMatrixTreeActiveVariable.data[1].name;
      this.On_Time_Delivery = this.getMatrixTreeActiveVariable.data[2].matrixSeverity;
      this.On_Time_DeliveryLabel = this.getMatrixTreeActiveVariable.data[2].name;
      this.Cyber_Security = this.getMatrixTreeActiveVariable.data[3].matrixSeverity;
      this.Cyber_SecurityLabel = this.getMatrixTreeActiveVariable.data[3].name;
      this.Product_Compliance = this.getMatrixTreeActiveVariable.data[4].matrixSeverity;
      this.Product_ComplianceLabel = this.getMatrixTreeActiveVariable.data[4].name;
      this.OHS_Risk = this.getMatrixTreeActiveVariable.data[5].matrixSeverity;
      this.OHS_RiskLabel = this.getMatrixTreeActiveVariable.data[5].name;
      this.Margin = this.getMatrixTreeActiveVariable.data[6].matrixSeverity;
      this.MarginLabel = this.getMatrixTreeActiveVariable.data[6].name;
      this.Reputation = this.getMatrixTreeActiveVariable.data[7].matrixSeverity;
      this.ReputationLabel = this.getMatrixTreeActiveVariable.data[7].name;
      this.Mass_media = this.getMatrixTreeActiveVariable.data[8].matrixSeverity;
      this.Mass_mediaLabel = this.getMatrixTreeActiveVariable.data[8].name;
      this.Design_change = this.getMatrixTreeActiveVariable.data[9].matrixSeverity;
      this.Design_changeLabel = this.getMatrixTreeActiveVariable.data[9].name;
      this.Instances = this.getMatrixTreeActiveVariable.data[10].matrixSeverity;
      this.InstancesLabel = this.getMatrixTreeActiveVariable.data[10].name;
      this.Resolution = this.getMatrixTreeActiveVariable.data[11].matrixSeverity;
      this.ResolutionLabel = this.getMatrixTreeActiveVariable.data[11].name;
      this.Affected_customer = this.getMatrixTreeActiveVariable.data[12].matrixSeverity;
      this.Affected_customerLabel = this.getMatrixTreeActiveVariable.data[12].name;
      this.Control = this.getMatrixTreeActiveVariable.data[13].matrixSeverity;
      this.ControlLabel = this.getMatrixTreeActiveVariable.data[13].name;

      this.availabilityRatingItems = this.availabilty.sort((a, b) => { return a.weight - b.weight })
        .filter((a) => a.isActive === true)
        .map((a) => {
          return { value: a.id, text: a.description, weight: a.weight };
        });

      this.functionalityRatingItems = this.Functionality.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.onTimeDeliveryRatingItems = this.On_Time_Delivery.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.cyberSecurityRatingItems = this.Cyber_Security.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.productComplianceRatingItems = this.Product_Compliance.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.ohsRiskRatingItems = this.OHS_Risk.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.marginRatingItems = this.Margin.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.reputationRatingItems = this.Reputation.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.massMediaRatingItems = this.Mass_media.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.designChangeRatingItems = this.Design_change.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.instancesRatingItems = this.Instances.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.resolutionRatingItems = this.Resolution.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.affectedCustomerRatingItems = this.Affected_customer.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });

      this.controlRatingItems = this.Control.sort((a, b) => { return a.weight - b.weight }).filter(
        (a) => a.isActive === true
      ).map((a) => {
        return { value: a.id, text: a.description, weight: a.weight };
      });
      if (this.complaintData == undefined) {
        this.issueResolutionForm.patchValue({
          availabilityRating: this.availabilityRatingItems[0]?.value,
          functionalityRating: this.functionalityRatingItems[0]?.value,
          onTimeDeliveryRating: this.onTimeDeliveryRatingItems[0]?.value,
          cyberSecurityRating: this.cyberSecurityRatingItems[0]?.value,
          productComplianceRating: this.productComplianceRatingItems[0]?.value,
          ohsRiskRating: this.ohsRiskRatingItems[0]?.value,
          marginRating: this.marginRatingItems[0]?.value,
          reputationRating: this.reputationRatingItems[0]?.value,
          massMediaRating: this.massMediaRatingItems[0]?.value,
          designChangeRating: this.designChangeRatingItems[0]?.value,
          instancesRating: this.instancesRatingItems[0]?.value,
          resolutionRating: this.resolutionRatingItems[0]?.value,
          affectedCustomerRating: this.affectedCustomerRatingItems[0]?.value,
          controlRating: this.controlRatingItems[0]?.value
        });
      }
      else {
        this.complaintCreatedDate = new Date(this.complaintData?.header?.createDate);
        // console.log("=====oninit");
        this.issueResolutionForm.patchValue({
          availabilityRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[0]?.selectionId,
          functionalityRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[1]?.selectionId,
          onTimeDeliveryRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[2]?.selectionId,
          cyberSecurityRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[3]?.selectionId,
          productComplianceRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[4]?.selectionId,
          ohsRiskRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[5]?.selectionId,
          marginRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[6]?.selectionId,
          reputationRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[7]?.selectionId,
          massMediaRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[8]?.selectionId,
          designChangeRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[9]?.selectionId,
          instancesRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[10]?.selectionId,
          resolutionRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[11]?.selectionId,
          affectedCustomerRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[12]?.selectionId,
          controlRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.matrixSelection[13]?.selectionId,
          rpn: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.rpn,
          impactRating: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.classFriendlyResult,
          classValue: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.class,
          JustificationCurrent: this.checkBlank(this.complaintData?.areaComplaintResolution?.justificationCurrent),
          //manualChangeClass:this.checkBlank(this.complaintData?.areaComplaintResolution?.manualChangeClass),
          ReviewRequired: this.checkBlank(this.complaintData?.areaComplaintResolution?.reviewRequired),
          eightDReportRequired: this.checkBlank(this.complaintData?.areaComplaintResolution?.eightDReportRequired),
          EightDJustificationCurrent: this.checkBlank(this.complaintData?.areaComplaintResolution?.eightDJustificationCurrent),
          reviewRequiredJustification: this.checkBlank(this.complaintData?.areaComplaintResolution?.reviewRequiredJustification)
        });
      //  this.onManualDDLChange({value: this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.classFriendlyResult?.replace('Class ','')})
        if (this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.classFriendlyResult == 'Class C') {
          // this.isEightDReportReadOnly = true;
          this.issueResolutionForm.controls['eightDReportRequired']?.disable();

        }
        if (this.complaintData?.areaComplaintResolution?.reviewRequired == 'Yes') {
          this.reviewRequired_Id = true;
          this.changeReviewRequiredId.emit(this.reviewRequired_Id);
        }
        if (this.complaintData?.areaComplaintResolution?.reviewRequired == 'No') {
          this.reviewRequired_Id = true;
          this.changeReviewRequiredId.emit(this.reviewRequired_Id);
        }
      }

    });

    this.issueResolutionForm
      .get('Rating')
      .valueChanges.subscribe((newValue) => {
        if (newValue) {
          this.ratingValue = newValue;
        }
      });
    this.issueResolutionForm
      .get('selectRole1')
      .valueChanges.subscribe((selectedRole) => {
        if (selectedRole) {
          this.selectedRole = selectedRole;
        }
      });

    const cId = this.complaintData?.header?.complaintNumber;
    this.pageActionItems = 0;
    this.irService.getActionItems(cId, this.pageActionItems).subscribe((resp) => {
      if (resp) {
        this.counterData = resp;
        this.actionItemsData = resp;
        this.actionItemsData.map((res) => {
          if (res.itemStatus == 1) {
            res.status = 'To Do';
          }
          else if (res.itemStatus == 2 || res.itemStatus == 4) {
            res.status = 'In Progress'
          }
          else if (res.itemStatus == 3) {
            res.status = 'Closed'
          }
        })
      }
    })
    // current rating

    // this.issueResolutionForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.availabilityRatingConf = this.irService.ratingConfiguration.scoringMatrixOriginalRating.selection[0].severities;
    this.availabilityRatingConf.sort((a, b) => { return a.weight - b.weight });

    this.functionalityRatingConf = this.irService.ratingConfiguration.scoringMatrixOriginalRating.selection[1].severities;
    this.functionalityRatingConf.sort((a, b) => { return a.weight - b.weight });

    this.onTimeDeliveryRatingConf = this.irService.ratingConfiguration.scoringMatrixOriginalRating.selection[2].severities;
    this.onTimeDeliveryRatingConf.sort((a, b) => { return a.weight - b.weight });

    this.cyberSecurityRatingConf = this.irService.ratingConfiguration.scoringMatrixOriginalRating.selection[3].severities;
    this.cyberSecurityRatingConf.sort((a, b) => { return a.weight - b.weight });

    this.productComplianceRatingConf = this.irService.ratingConfiguration.scoringMatrixOriginalRating.selection[4].severities;
    this.productComplianceRatingConf.sort((a, b) => { return a.weight - b.weight });

    this.ohsRiskRatingConf = this.irService.ratingConfiguration.scoringMatrixOriginalRating.selection[5].severities;
    this.ohsRiskRatingConf.sort((a, b) => { return a.weight - b.weight });

    this.marginRatingConf = this.irService.ratingConfiguration.scoringMatrixOriginalRating.selection[6].severities;
    this.marginRatingConf.sort((a, b) => { return a.weight - b.weight });

    this.reputationRatingConf = this.irService.ratingConfiguration.scoringMatrixOriginalRating.selection[7].severities;
    this.reputationRatingConf.sort((a, b) => { return a.weight - b.weight });

    this.massMediaRatingConf = this.irService.ratingConfiguration.scoringMatrixOriginalRating.selection[8].severities;
    this.massMediaRatingConf.sort((a, b) => { return a.weight - b.weight });

    this.designChangeRatingConf = this.irService.ratingConfiguration.scoringMatrixOriginalRating.selection[9].severities;
    this.designChangeRatingConf.sort((a, b) => { return a.weight - b.weight });

    this.instancesRatingConf = this.irService.ratingConfiguration.scoringMatrixOriginalRating.selection[10].severities;
    this.instancesRatingConf.sort((a, b) => { return a.weight - b.weight });

    this.resolutionRatingConf = this.irService.ratingConfiguration.scoringMatrixOriginalRating.selection[11].severities;
    this.resolutionRatingConf.sort((a, b) => { return a.weight - b.weight });

    this.affectedCustomerRatingConf = this.irService.ratingConfiguration.scoringMatrixOriginalRating.selection[12].severities;
    this.affectedCustomerRatingConf.sort((a, b) => { return a.weight - b.weight });

    this.controlRatingConf = this.irService.ratingConfiguration.scoringMatrixOriginalRating.selection[13].severities;
    this.controlRatingConf.sort((a, b) => { return a.weight - b.weight });

    this.authorizationService.userAccessDetail.subscribe(async detail => {
      this.userAccessData = detail;
      this.selectedRoleName = this.complaintData?.complaintDataAccess?.complaintRoles.filter(role=> role?.id==this.userAccessData['roleCode']);
      this.attachmentDisabled();
      this.authorizationBtnDisable();
      this.canRejectHighImpactClassA(this.selectedRoleName);
    });

    this.issueResolutionForm.controls['manualChangeClass']?.valueChanges.subscribe((status) => {
      if (status) {
        // this.onManualClassDDLChange(status);
      }
    })

  }
  canRejectHighImpactClassA(selectedRole){
    const loginEmail = this.sessionStorage.getData('userInfo')?.email;
    const emailsOfSuperUsers = environment.idsForHighClassApproval;
    const classConfirmStatus = this.complaintData.areaComplaintCollection.complaintInformationSection.highImpactValidationViewModel.isConfirm;
    const classValue = this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.class;
    if(classValue == 1 && !classConfirmStatus) {
        let validSuperUser = emailsOfSuperUsers?.includes(loginEmail);
        let RoleSelected = selectedRole[0]?.id;
        if(validSuperUser || RoleSelected == 2){
          this.isClassCanbeRejected = true;
        }else{
          this.isClassCanbeRejected = false;
        }
      }else{
        this.isClassCanbeRejected = false;
      }
  }

  rcaValueChange(){
   if(this.issueResolutionForm.get('RCACorrectiveActions').value==true){
    let radioEnableValueConversion: string;
    let linkToRCAComp: string;
    this.complaintData.areaComplaintResolution.isEightDRCARequiredCurrent=true;
    radioEnableValueConversion = '' + ((this.complaintData?.areaComplaintResolution?.isEightDRCARequiredCurrent && this.complaintData?.areaComplaintAssignment?.sectionQuickFix?.quickFixVariant==2) || (this.complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpact?.hasHightImpactOnABB==true && this.complaintData?.areaComplaintAssignment?.sectionQuickFix?.quickFixVariant==2) ? 0 : this.complaintData?.areaComplaintAssignment?.sectionQuickFix?.quickFixVariant);
        if (radioEnableValueConversion == "1") {
          linkToRCAComp = this.checkBlank(this.complaintData?.areaComplaintAssignment?.sectionQuickFix?.quickFixComplaint?.displayLabel);
        } else {
          linkToRCAComp = '';
        }
    this.issueCaptureMainForm.get('issueAssignmentForm').patchValue({
      quickFixVariant: radioEnableValueConversion,
      ccrpNo: linkToRCAComp,
    })
   }
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log("=====change");
    this.userInfo = this.sessionStorage.getData('userInfo');
    this.isResolutionOwner = this.role == "Resolution Owner" ? true : false;
    this.isEightDRequiredGlobalPO = this.role == "Global CCRP Process Owner" ? true : false;
    this.isEightDRequiredPGProcessOwner = this.role == "PG Process Owner" ? true : false;
    this.issueStatus = this.issueCaptureMainForm?.get('headerSection').get('issueComplaintStatus')?.value;

   this.setFieldsValidation(this.ActionNameForComplaints);
   const verificationCheck = this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.verificationInformation?.status;
   const classVerify = this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.classFriendlyResult;
   const complaintStatus = this.complaintData?.header?.complaintStatusName;
   const userEmail = this.userInfo?.email;

  if(complaintStatus == 'Resolved' || complaintStatus == 'Completed' || complaintStatus == 'In Process Verification' || complaintStatus == 'In Process Verification Overdue' || complaintStatus == 'Completed Execution Pending' || complaintStatus == 'Draft'){
    this.manualChangeVisible = false;
  }
  else if(complaintStatus == 'Not Assigned' && verificationCheck == 'Unconfirmed' && (classVerify == 'High Impact Class B' || classVerify == 'High Impact Class I')) {
    this.manualChangeVisible = false;
  }
  else if(this.role == 'Global CCRP Process Owner' || this.role == 'Support Desk' || environment.idsForHighClassApproval.includes(userEmail)) {
    this.manualChangeVisible = true;
  }
  else if(classVerify == 'High Impact Class B' && this.role == 'BU Process Owner') {
    this.manualChangeVisible = true
  }
  else if(classVerify == 'High Impact Class I' && this.role == 'Hub Process Owner'){
    this.manualChangeVisible = true
  }
  else if(classVerify == 'Class C' && (this.role == 'BU Process Owner' || this.role == 'Hub Process Owner')){
    this.manualChangeVisible = true
  }
  else{
    this.manualChangeVisible = false;
  }

    // RO or RO Team current rating enable disable
    const isConfirm = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpactValidationViewModel?.isConfirm;
    const isHighImpactData = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpact?.hasHightImpactOnABB;

    if((this.role == 'Resolution Owner' || this.role == 'Resolution Owner Team') && isHighImpactData && !isConfirm) {
      this.issueResolutionForm.controls['availabilityRating'].disable();
      this.issueResolutionForm.controls['functionalityRating'].disable();
      this.issueResolutionForm.controls['onTimeDeliveryRating'].disable();
      this.issueResolutionForm.controls['cyberSecurityRating'].disable();
      this.issueResolutionForm.controls['productComplianceRating'].disable();
      this.issueResolutionForm.controls['ohsRiskRating'].disable();
      this.issueResolutionForm.controls['marginRating'].disable();
      this.issueResolutionForm.controls['reputationRating'].disable();
      this.issueResolutionForm.controls['massMediaRating'].disable();
      this.issueResolutionForm.controls['designChangeRating'].disable();
      this.issueResolutionForm.controls['instancesRating'].disable();
      this.issueResolutionForm.controls['resolutionRating'].disable();
      this.issueResolutionForm.controls['affectedCustomerRating'].disable();
      this.issueResolutionForm.controls['controlRating'].disable();
    }

    this.showClassValue()
    this.hideShow8DReport();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.complaintData != undefined) {
        this.complaintCreatedDate = new Date(this.complaintData?.header?.createDate);
        const sapDefectMain = this.complaintData?.sapDefectCause;
        const sapDefectCause = {id: sapDefectMain?.defectCause, name: sapDefectMain?.defectCauseTextField, parentName: sapDefectMain?.causeCodeGroupText, parentId: sapDefectMain?.defectcausecodegroup, buCode: sapDefectMain?.buCode};
        const sapComplaintInfo = this.complaintData?.sapComplaintInfo;
        const keyWordData = this.complaintData?.areaComplaintResolution?.categoryKeywordDropdownItems;
      const selectedKeyword = this.complaintData?.areaComplaintResolution?.categoryKeyword[0]?.split(';');
      const patchingValueKeyword = this.bindMatchedValue(selectedKeyword, keyWordData);
      const removeUndefinedKeyword = patchingValueKeyword.map((value) => value?.dataValue).filter((a) => a != undefined);
        this.UploadRCAAttachments = this.complaintData?.areaComplaintResolution?.uploadRCAAttachments;
        this.uploadRCAAttachmentsHolder = this.complaintData?.areaComplaintResolution?.uploadRCAAttachments ? this.complaintData?.areaComplaintResolution?.uploadRCAAttachments : [];
        this.resolutionAttachments = this.complaintData?.areaComplaintResolution?.resolutionAttachments ? this.complaintData?.areaComplaintResolution?.resolutionAttachments : [];
        this.resolutionAttachmentsHolder = this.complaintData?.areaComplaintResolution?.resolutionAttachments;
        this.issueAttachmentPDCAMerged = this.complaintData?.areaComplaintResolution?.uploadRCAAttachments;
        this.issueAttachmentSummaryMerged = this.complaintData?.areaComplaintResolution?.resolutionAttachments;

        this.issueResolutionForm.patchValue({
          EstimatedCostView: this.checkBlank(this.complaintData?.areaComplaintResolution?.estimatedCost),
          ImpactOnInstalledBase: this.checkBlank(this.complaintData?.areaComplaintResolution?.impactOnInstalledBase),
          TotalInstalledBase: this.checkBlank(this.complaintData?.areaComplaintResolution?.totalInstalledBase),
          QNumberResolvingUnit: this.checkBlank(this.complaintData?.areaComplaintResolution?.qNumberResolvingUnit),
          RCACorrectiveActions: this.checkBlank(this.complaintData?.areaComplaintResolution?.isEightDRCARequiredCurrent),
          SpecificationName: this.checkBlank(this.complaintData?.areaComplaintResolution?.specificationName),
          howProductFailMeetSpecification: this.checkBlank(this.complaintData?.areaComplaintResolution?.howProductFailMeetSpecification),
          WhatContainmentActionsWhereTaken: this.checkBlank(this.complaintData?.areaComplaintResolution?.whatContainmentActionsWhereTaken),
          ContainmentActionsExecution: this.complaintData?.areaComplaintCollection?.complaintInformationSection?.containmentAction?.execution,
          whatWasTheRootCauseOfThisIssue: this.checkBlank(this.complaintData?.areaComplaintResolution?.whatWasTheRootCauseOfThisIssue),
          CategoryKeyword: removeUndefinedKeyword,
          howDidWeResolveThisIssue: this.checkBlank(this.complaintData?.areaComplaintResolution?.howDidWeResolveThisIssue),
          // chipData: this.checkBlank(StaticvalueConstants.CHIP_DATA),
          // Upload: [''],
          //Attachments: [''],
          ActualRUCost: this.checkBlank(this.complaintData?.areaComplaintResolution?.actualCost),
          COPQCategory: this.checkBlank(this.complaintData?.areaComplaintResolution?.sectionCategorisation?.copqCategory?.name),
          COPQCategoryAllData: this.checkBlank(this.complaintData?.areaComplaintResolution?.sectionCategorisation?.copqCategory),
          pgCategory: this.checkBlank(this.complaintData?.areaComplaintResolution?.sectionCategorisation?.pgCategory?.name),
          PGRCACategoryAllData: this.checkBlank(this.complaintData?.areaComplaintResolution?.sectionCategorisation?.pgCategory),
          UploadRCAAttachments: this.issueAttachmentPDCAMerged,
          resolutionAttachments: this.issueAttachmentSummaryMerged,
          // defectCauseHiddenFieldRCA: sapDefectCause,
          DefectCauseDefectCauseCodeGroup: sapDefectMain?.defectCauseTextField,
          //sapDefectCauseId : {id: this.complaintData?.sapDefectCause?.id, DefectCauseDefectCauseCodeGroup: this.complaintData?.sapDefectCause?.DefectCauseDefectCauseCodeGroup, DefectTypeandDefectCodeGroupType: this.complaintData?.sapDefectCause?.DefectTypeandDefectCodeGroupType},
          //sapComplaintInfoDetails: {id: sapComplaintInfo?.id, isQnNumberGenerated: sapComplaintInfo?.isQnNumberGenerated, qnNumber: sapComplaintInfo?.qnNumber},
          //isQnNumberGeneratedCheck: sapComplaintInfo?.isQnNumberGenerated,
          //IsQnNumberGenerated: sapComplaintInfo?.isQnNumberGenerated? sapComplaintInfo?.isQnNumberGenerated: false,
          //sapLockStatus: this.complaintData?.sapComplaintInfo?.sapLockStatus,
          ProductAlreadyDelivered: this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.productAlreadyDelivered ,
          NoOfUnitsShipped: this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.noOfUnitsShipped,
        })
        this.IsBuVerified = this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.class == 1? this.complaintData?.isBuVerified : true;
        this.pgCategoryTooltipText = this.complaintData?.areaComplaintResolution?.sectionCategorisation?.pgCategory?.name;
        if(this.UploadRCAAttachments?.length>0){
          let attachmentDatarca = {storFileName: '',displayName: ''};
        this.UploadRCAAttachments?.forEach(attachments => {
          attachmentDatarca.storFileName = attachmentDatarca?.storFileName+(attachmentDatarca?.storFileName=='' ? '' : '_&_')+attachments?.storeFileName;
          attachmentDatarca.displayName = attachmentDatarca?.displayName+(attachmentDatarca?.displayName=='' ? '' : '_&_')+attachments?.displayName;
        });
          this.downloadAllUrlrca = this.downloadAllUrlrca+encodeURIComponent(attachmentDatarca?.storFileName)+'&displayNames='+encodeURIComponent(attachmentDatarca?.displayName);
        }
        if(this.resolutionAttachments?.length>0){
          let attachmentDataresolution = {storFileName: '',displayName: ''};
        this.resolutionAttachments?.forEach(attachments => {
          attachmentDataresolution.storFileName = attachmentDataresolution?.storFileName+(attachmentDataresolution?.storFileName=='' ? '' : '_&_')+attachments?.storeFileName;
          attachmentDataresolution.displayName = attachmentDataresolution?.displayName+(attachmentDataresolution?.displayName=='' ? '' : '_&_')+attachments?.displayName;
        });
        this.downloadAllUrlresolution = this.downloadAllUrlrca+encodeURIComponent(attachmentDataresolution?.storFileName)+'&displayNames='+encodeURIComponent(attachmentDataresolution?.displayName);
        }

        if(this.complaintData?.sapDefectCause?.DefectCauseDefectCauseCodeGroup?.tooltipInfo != null){
          this.defectCauseToolTip = this.complaintData?.sapDefectCause?.DefectCauseDefectCauseCodeGroup?.tooltipInfo
        }
       }
    }, 3000);
    setTimeout(()=>{
      this.setFieldsValidation(this.ActionNameForComplaints);
    },8000);
  }

  attachmentDisabled() {
    if(this.complaintData?.sapComplaintInfo?.sapLockStatus == true && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
      this.disableSection = false;
      this.disableRCACheckBox = false;
      return;
    }

    if (this.userAccessData) {
      this.highImpactDataRCA = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpact?.hasHightImpactOnABB;
      switch (this.userAccessData['roleCode']) {
        case this.enumRoleCode.Complaint_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableRCACheckBox = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Coordinator:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableRCACheckBox = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Global_CCRP_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [203], this.userAccessData);
          this.disableRCACheckBox = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Support_Desk:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [203], this.userAccessData);
          this.disableRCACheckBox = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Supervisor:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableRCACheckBox = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Team:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableRCACheckBox = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableRCACheckBox = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner_Team:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableRCACheckBox = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Ru_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableRCACheckBox = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Resource_Editor:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableRCACheckBox = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Div_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableRCACheckBox = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Bu_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12, 4], [203], this.userAccessData);
          this.disableRCACheckBox = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Pg_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12, 4], [203], this.userAccessData);
          this.disableRCACheckBox = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Hub_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2], [203], this.userAccessData);
          this.disableRCACheckBox = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
      }
      if(this.disableRCACheckBox) {
        if(this.highImpactDataRCA == false) {
          this.issueResolutionForm.controls['RCACorrectiveActions'].enable();
        }
        else {
          this.issueResolutionForm.controls['RCACorrectiveActions'].disable();
        }
      }
      else {
          this.issueResolutionForm.controls['RCACorrectiveActions'].disable();
      }
      return this.disableSection
    }
  }

  showDefectTypePopup() {
    const resolvingData = this.additionalSubjectData.controls['resolvingUnitArray']?.value;
    this.resolvingUnitId = resolvingData?.id;
    const code = this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.resolvingUnit?.pgCode;
    const buCode = this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.resolvingUnit?.buCode;
    //const whereIsTheIssueBalId = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.whereIssueDescription.split(',')[0];
    const whereIsTheIssueBalId = this.complaintData?.whereWasTheIssueDetectedId;
    //const resolvingUnitBalId = this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.resolvingUnit?.tooltipInfo?.substring(8, 18);
    const defectCausePayload = {code: code, buCode: buCode, resolvingUnitBalId: this.resolvingUnitBalId, whereIsTheIssueBalId: whereIsTheIssueBalId, isDefectCause: true, isActive:true}
    // this.balIdPass = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.whereIssueDescription.split(',')[0];
    // const defectCausePayload = { parentId: '' , resolvingUnitId: this.resolvingUnitId, balId: this.balIdPass, isDefectType: true }
    this.ref = this.dialogService.open(DefectCausePopupComponent, {
      header: 'Defect Cause Selection',
      width: '60%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'defect-type-pop',
      data: { defect: defectCausePayload }
    });
    this.ref.onClose.subscribe((receivedValue: any) => {
      if(receivedValue) {
        this.issueResolutionForm.patchValue({
          DefectCauseDefectCauseCodeGroup: receivedValue?.name,
          //defectCauseHiddenFieldRCA: receivedValue
        })

        this.issueCaptureMainForm?.get('additionalSubjectForm')?.patchValue({
          isQnNumberGeneratedCheck: true,
          defectCauseHiddenFieldAdnSub : receivedValue
        })
        this.defectCauseToolTip = receivedValue?.tooltipInfo;
      }
    })
  }

  authorizationBtnDisable() {
    if(this.complaintData?.sapComplaintInfo?.sapLockStatus == true && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
      this['saveBtn_issueRes'] = true;
      this['resolved_issueRes'] = true;
      this['revertToNotAssigned_issueRes'] = true;
      this['verifyRes_issueRes'] = true;
      this['ButtonExecuteContainmentAction'] = true;
      this['returnToRO_issueRes'] = true;
      this['verifyRes_ByBuProcessOwner'] = false;
      return;
    }

    if (this.userAccessData) {
      const loginEmail = this.sessionStorage.getData('userInfo')?.email;
      const emailCoordinators = this.issueCaptureMainForm.get('issueAssignmentForm').get('coordinatorList')?.value;
      if(emailCoordinators) {
        this.isVerifiedCoordinator = emailCoordinators?.some((aa) => aa.email == loginEmail);
      }
      switch (this.userAccessData['roleCode']) {

        case this.enumRoleCode.Complaint_Owner:
          this.disableBtn['saveBtn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12], [205], this.userAccessData);
          this.disableBtn['resolved_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [203], this.userAccessData);
          this.disableBtn['revertToNotAssigned_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [213], this.userAccessData);
          this.disableBtn['verifyRes_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12], [205], this.userAccessData);
          this.disableBtn['ButtonExecuteContainmentAction'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableBtn['returnToRO_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11,12], [205], this.userAccessData);
          this.disableBtn['verifyRes_ByBuProcessOwner'] = false;
          break;
        case this.enumRoleCode.Complaint_Owner_Supervisor:
          this.disableBtn['saveBtn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12], [205], this.userAccessData);
          this.disableBtn['resolved_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [205], this.userAccessData);
          this.disableBtn['revertToNotAssigned_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [213], this.userAccessData);
          this.disableBtn['verifyRes_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12], [205], this.userAccessData);
          this.disableBtn['ButtonExecuteContainmentAction'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableBtn['returnToRO_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11,12], [205], this.userAccessData);
          this.disableBtn['verifyRes_ByBuProcessOwner'] = false;
          break;
        case this.enumRoleCode.Complaint_Coordinator:
          this.disableBtn['saveBtn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [ 4, 5, 11, 12], [203], this.userAccessData);
          this.disableBtn['resolved_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [203], this.userAccessData);
          this.disableBtn['revertToNotAssigned_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [213], this.userAccessData);
          this.disableBtn['verifyRes_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12], [205], this.userAccessData);
          this.disableBtn['ButtonExecuteContainmentAction'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableBtn['returnToRO_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11,12], [205], this.userAccessData);
          this.disableBtn['verifyRes_ByBuProcessOwner'] = false;
          if(this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.class == 0){
            this.isVerifiedCoordinator = true;
            }else{
            this.isVerifiedCoordinator = false;
          }
          break;
        case this.enumRoleCode.Resolution_Owner:
          this.disableBtn['saveBtn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12,7,13], [203], this.userAccessData);
          this.disableBtn['resolved_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [203], this.userAccessData);
          this.disableBtn['revertToNotAssigned_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [213], this.userAccessData);
          this.disableBtn['verifyRes_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12], [205], this.userAccessData);
          this.disableBtn['ButtonExecuteContainmentAction'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableBtn['returnToRO_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2], [205], this.userAccessData);
          this.disableBtn['verifyRes_ByBuProcessOwner'] = false;
          break;
        case this.enumRoleCode.Support_Desk:
          this.disableBtn['saveBtn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [203], this.userAccessData);
          this.disableBtn['resolved_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [203], this.userAccessData);
          this.disableBtn['revertToNotAssigned_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [213], this.userAccessData);
          this.disableBtn['verifyRes_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12], [205], this.userAccessData);
          this.disableBtn['ButtonExecuteContainmentAction'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [], this.userAccessData);
          this.disableBtn['returnToRO_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11,12], [205], this.userAccessData);
          this.disableBtn['verifyRes_ByBuProcessOwner'] = true;
          this.isVerifiedCoordinator = true;
          break;
        case this.enumRoleCode.Complaint_Owner_Team:
          this.disableBtn['saveBtn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [203], this.userAccessData);
          this.disableBtn['resolved_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [203], this.userAccessData);
          this.disableBtn['revertToNotAssigned_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [213], this.userAccessData);
          this.disableBtn['verifyRes_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12], [205], this.userAccessData);
          this.disableBtn['ButtonExecuteContainmentAction'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableBtn['returnToRO_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11,12], [205], this.userAccessData);
          this.disableBtn['verifyRes_ByBuProcessOwner'] = false;
          break;
        case this.enumRoleCode.Resolution_Owner_Team:
          this.disableBtn['saveBtn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableBtn['resolved_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [203], this.userAccessData);
          this.disableBtn['revertToNotAssigned_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [213], this.userAccessData);
          this.disableBtn['verifyRes_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12], [205], this.userAccessData);
          this.disableBtn['ButtonExecuteContainmentAction'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableBtn['returnToRO_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11,12], [205], this.userAccessData);
          this.disableBtn['verifyRes_ByBuProcessOwner'] = false;
          break;
        case this.enumRoleCode.Bu_Process_Owner:
          this.disableBtn['saveBtn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [203], this.userAccessData);
          this.disableBtn['resolved_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [203], this.userAccessData);
          this.disableBtn['revertToNotAssigned_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [213], this.userAccessData);
          this.disableBtn['verifyRes_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12], [205], this.userAccessData);
          this.disableBtn['ButtonExecuteContainmentAction'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableBtn['returnToRO_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11,12], [205], this.userAccessData);
          this.disableBtn['verifyRes_ByBuProcessOwner'] = true;
          if(this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.class == 2 || this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.class == 3 || this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.class == 0){
            this.isVerifiedCoordinator = true;
          }else{
            this.isVerifiedCoordinator = false;
          }
          break;
        case this.enumRoleCode.Pg_Process_Owner:
          this.disableBtn['saveBtn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [213], this.userAccessData);
          this.disableBtn['resolved_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [203], this.userAccessData);
          this.disableBtn['revertToNotAssigned_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [213], this.userAccessData);
          this.disableBtn['verifyRes_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12], [205], this.userAccessData);
          this.disableBtn['ButtonExecuteContainmentAction'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableBtn['returnToRO_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11,12], [205], this.userAccessData);
          this.disableBtn['verifyRes_ByBuProcessOwner'] = false;
          break;
        case this.enumRoleCode.Hub_Process_Owner:
          this.disableBtn['saveBtn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [], this.userAccessData);
          this.disableBtn['resolved_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [203], this.userAccessData);
          this.disableBtn['revertToNotAssigned_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [213], this.userAccessData);
          this.disableBtn['verifyRes_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12], [205], this.userAccessData);
          this.disableBtn['ButtonExecuteContainmentAction'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableBtn['returnToRO_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11,12], [205], this.userAccessData);
          this.disableBtn['verifyRes_ByBuProcessOwner'] = false;
          if(this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.class == 3 || this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.class == 0){
            this.isVerifiedCoordinator = true;
          }else{
            this.isVerifiedCoordinator = false;
          }
          break;

        case this.enumRoleCode.Global_CCRP_Process_Owner:
          this.disableBtn['saveBtn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [203], this.userAccessData);
          this.disableBtn['verifyRes_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12], [205], this.userAccessData);
          this.disableBtn['resolved_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [203], this.userAccessData);
          this.disableBtn['revertToNotAssigned_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [213], this.userAccessData);
          this.disableBtn['ButtonExecuteContainmentAction'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableBtn['returnToRO_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11,12], [205], this.userAccessData);
          this.disableBtn['verifyRes_ByBuProcessOwner'] = false;
          this.isVerifiedCoordinator = true;
          break;

        case this.enumRoleCode.Div_Process_Owner:
          this.disableBtn['saveBtn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7, 13], [203], this.userAccessData);
          this.disableBtn['verifyRes_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11, 12], [205], this.userAccessData);
          this.disableBtn['resolved_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [203], this.userAccessData);
          this.disableBtn['revertToNotAssigned_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5], [213], this.userAccessData);
          this.disableBtn['ButtonExecuteContainmentAction'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableBtn['returnToRO_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [11,12], [205], this.userAccessData);
          this.disableBtn['verifyRes_ByBuProcessOwner'] = false;
          this.isVerifiedCoordinator = true;
          break;

      }
    }
  }

  getMore() {
    const cId = this.complaintData?.header?.complaintNumber;
    this.pageActionItems = this.pageActionItems + 1;
    this.irService.getActionItems(cId, this.pageActionItems).subscribe((resp) => {
      if (resp) {
        this.counterData = resp;
        this.actionItemsData.push(...resp);
        this.actionItemsData.map((res) => {
          if (res.itemStatus == 1) {
            res.status = 'To Do';
          }
          else if (res.itemStatus == 2 || res.itemStatus == 4) {
            res.status = 'In Progress'
          }
          else if (res.itemStatus == 3) {
            res.status = 'Closed'
          }
        })
      }
    })
  }

  lessonLearned(lessonData) {
    this.lessonData = lessonData;
  }

  bindMatchedValue(keyWordData, selectedkeyWord) {
    return selectedkeyWord.map((item) => {
      const matchedItem = keyWordData?.find((element) => element == item.id)
      if (matchedItem) {
        return { ...item, matchedId: matchedItem }
      }
    })
  }

  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    }
    else {
      return content;
    }
  }

  numberWithCommas(event: Event) {
    let value1 = (event.target as HTMLInputElement)?.value;
    if (Number(value1)) {
      var parts = value1.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.issueResolutionForm.patchValue({
        EstimatedCostView: this.checkBlank(parts.join("."))
      });
    }
    else {
      this.issueResolutionForm.patchValue({
        EstimatedCostView: this.checkBlank('')
      });
    }
  }

  numberWithCommasActualRUCost(event: Event) {
    let val = (event.target as HTMLInputElement)?.value;
    if (Number(val)) {
      var parts = val.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.issueResolutionForm.patchValue({
        ActualRUCost: this.checkBlank(parts.join("."))
      });
    }
    else {
      this.issueResolutionForm.patchValue({
        ActualRUCost: this.checkBlank('')
      });
    }
  }

  myUploaderPDCA(createUploader, event) {
    let section = "issue-resolution";
    for (var i = 0; i < event.files.length; i++) {
      if (i >= this.issueAttachmentPDCA?.length) {
        this.spinner.show();
        let formData = new FormData();
        formData.append("files", event.files[i]);
        this.complaintDetailsService.issueCaptureAttachmentSave(section, formData).subscribe((response: any) => {
          if(response?.length > 0){
            let attachIdChange = response[0];
            attachIdChange.id = (attachIdChange?.id)?.toString();
          // console.log("attachIdChange==>",attachIdChange);
            this.issueAttachmentPDCA.push(attachIdChange);
            this.issueAttachmentPDCAMerged = [...this.uploadRCAAttachmentsHolder, ...this.issueAttachmentPDCA];
            this.issueResolutionForm.patchValue({
              UploadRCAAttachments: this.issueAttachmentPDCAMerged
            });
            this.spinner.hide();
          }
          else{
            this.spinner.hide();
          }
        },
        (error) => {
            this.spinner.hide();
            this.messageService.add({
                severity: 'error',
                summary: error ? error : 'Something went wrong!'
            });
        });
      }
    }
  }
  uploadedPDCAAttachmentRemove(RCAAttachmentIndex) {
    // console.log("Resolution RCA uploadedPDCAAttachmentRemove=>",(this.uploadRCAAttachmentsHolder.findIndex(attachment => attachment.id == this.UploadRCAAttachments[RCAAttachmentIndex]?.id)));
    if ((this.uploadRCAAttachmentsHolder.findIndex(attachment => attachment.id == this.UploadRCAAttachments[RCAAttachmentIndex]?.id)) == -1) {
      this.uploadRCAAttachmentsHolder.push(this.UploadRCAAttachments[RCAAttachmentIndex]);
    }
    else {
      this.uploadRCAAttachmentsHolder = this.uploadRCAAttachmentsHolder?.filter(attachment => attachment.id !== this.UploadRCAAttachments[RCAAttachmentIndex]?.id);
    }
    this.issueAttachmentPDCAMerged = [...this.uploadRCAAttachmentsHolder, ...this.issueAttachmentPDCA];
    this.issueResolutionForm.patchValue({
      UploadRCAAttachments: this.issueAttachmentPDCAMerged
    });
  }

  checkPDCARemoved(RCAAttachmentIndex) {
    //console.log("Resolution RCA checkPDCARemoved=>",(this.issueAttachmentPDCA.indexOf(attachment => attachment.id == this.UploadRCAAttachments[RCAAttachmentIndex]?.id)));
    if ((this.uploadRCAAttachmentsHolder.findIndex(attachment => attachment.id == this.UploadRCAAttachments[RCAAttachmentIndex]?.id)) == -1) {
      return true;
    }
    else {
      return false;
    }
  }
  removeFilePDCA(file: File, uploader: FileUpload) {
    const index = uploader.files.indexOf(file);
    uploader.remove(event, index);
    let filename = [this.issueAttachmentPDCA[index]?.storeFileName];
    this.spinner.show();
    this.complaintDetailsService.removeAttachments(filename).subscribe((res) => {
      this.issueAttachmentPDCA = this.issueAttachmentPDCA.filter(attachment => attachment?.storeFileName !== filename[0]);
      this.issueAttachmentPDCAMerged = [...this.uploadRCAAttachmentsHolder, ...this.issueAttachmentPDCA];
      this.issueResolutionForm.patchValue({
        UploadRCAAttachments: this.issueAttachmentPDCAMerged
      });
      this.spinner.hide();
    })
  }


  myUploaderSummary(createUploader, event) {
    let section = "issue-resolution";
    for (var i = 0; i < event.files.length; i++) {
      if (i >= this.issueAttachmentSummary?.length) {
        this.spinner.show();
        let formData = new FormData();
        formData.append("files", event.files[i]);
        this.complaintDetailsService.issueCaptureAttachmentSave(section, formData).subscribe((response: any) => {
          let attachIdChange = response[0];
          attachIdChange.id = (attachIdChange?.id)?.toString();
          // console.log("attachIdChange==>",attachIdChange);
          this.issueAttachmentSummary.push(attachIdChange);
          this.issueAttachmentSummaryMerged = [...this.resolutionAttachmentsHolder, ...this.issueAttachmentSummary];
          this.issueResolutionForm.patchValue({
            resolutionAttachments: this.issueAttachmentSummaryMerged
          });
          this.spinner.hide();
        },
        (error) => {
            this.spinner.hide();
            this.messageService.add({
                severity: 'error',
                summary: error ? error : 'Something went wrong!'
            });
        });
      }
    }
  }

  uploadedSummaryAttachmentRemove(SummaryAttachmentIndex) {
    // console.log("Summary uploadedSummaryAttachmentRemove=>",(this.resolutionAttachmentsHolder.findIndex(attachment => attachment.id == this.resolutionAttachments[SummaryAttachmentIndex]?.id)));
    if ((this.resolutionAttachmentsHolder.findIndex(attachment => attachment.id == this.resolutionAttachments[SummaryAttachmentIndex]?.id)) == -1) {
      this.resolutionAttachmentsHolder.push(this.resolutionAttachments[SummaryAttachmentIndex]);
    }
    else {
      this.resolutionAttachmentsHolder = this.resolutionAttachmentsHolder?.filter(attachment => attachment.id !== this.resolutionAttachments[SummaryAttachmentIndex]?.id);
    }
    this.issueAttachmentSummaryMerged = [...this.resolutionAttachmentsHolder, ...this.issueAttachmentSummary];
    this.issueResolutionForm.patchValue({
      resolutionAttachments: this.issueAttachmentSummaryMerged
    });
  }

  checkSummaryRemoved(SummaryAttachmentIndex) {
    // console.log("Summary checkSummaryRemoved=>",(this.issueAttachmentSummary.findIndex(attachment => attachment.id == this.resolutionAttachments[SummaryAttachmentIndex]?.id)));
    if ((this.resolutionAttachmentsHolder.findIndex(attachment => attachment.id == this.resolutionAttachments[SummaryAttachmentIndex]?.id)) == -1) {
      return true;
    }
    else {
      return false;
    }
  }

  removeFileSummary(file: File, uploader: FileUpload) {
    const index = uploader.files.indexOf(file);
    uploader.remove(event, index);
    let filename = [this.issueAttachmentSummary[index]?.storeFileName];
    this.spinner.show();
    this.complaintDetailsService.removeAttachments(filename).subscribe((res) => {
      this.issueAttachmentSummary = this.issueAttachmentSummary.filter(attachment => attachment?.storeFileName !== filename[0]);
      this.issueAttachmentSummaryMerged = [...this.resolutionAttachmentsHolder, ...this.issueAttachmentSummary];
      this.issueResolutionForm.patchValue({
        resolutionAttachments: this.issueAttachmentSummaryMerged
      });
      this.spinner.hide();
    })
  }


  isAssigned() {
    this.isAssign == true;
  }

  async resolved($event: Event) {
    this.ActionNameForComplaints = "Resolve";
   await this.setFieldsValidation(this.ActionNameForComplaints);
    if (
      this.issueResolutionForm.controls['RCACorrectiveActions']?.value == true
    )
      this.verifier = true;

    // code for Error msg (the field is mandatory to fill)
    this.isSubmitted = true;
    this.resolvedEventPassed.emit({ submitted: true })

  }
  onClickRating() {
    if (
      this.issueResolutionForm.controls['Rating']?.value == 'A' ||
      this.issueResolutionForm.controls['Rating']?.value == 'B' ||
      this.issueResolutionForm.controls['Rating']?.value == 'C' ||
      this.issueResolutionForm.controls['Rating']?.value == 'I'
    ) {
      this.isResolved = true;

      return true;
    } else {
      return false;
    }
  }

  containementButtonDisable() {
    if (
      this.selectedRole == 'Support_Desk' &&
      (this.ratingValue == 'A' ||
        this.ratingValue == 'B' ||
        this.ratingValue == 'C' ||
        this.ratingValue == 'I')
    ) {
      this.isResolved = true;
      this.isRevertToNotAssign = true;
      return true;
    }
    if (
      this.selectedRole == 'Support_Desk' &&
      (this.ratingValue == 'A' ||
        this.ratingValue == 'B' ||
        this.ratingValue == 'I')
    ) {
      this.issueStatusVariable = 'In Process Verification';
      this.isRevertToNotAssign = true;
      return true;
    }
    if (
      this.ContainmentActionsRequiredFromIssueRes == true &&
      this.selectedRole == 'Support_Desk' &&
      (this.ratingValue == 'A' ||
        this.ratingValue == 'B' ||
        this.ratingValue == 'C' ||
        this.ratingValue == 'I')
    ) {
      // this.isResolved=false;
      return true;
    } else {
      return false;
    }
  }

  showRevertToNotAssigned() { }

 async containmentToResolved($event: Event) {
    this.ActionNameForComplaints = "ContainmentActionExecuted";
   await this.setFieldsValidation(this.ActionNameForComplaints);
    // containment to resolve
    this.buttonFlag = true;
    this.isSubmitted = true;
    this.issueResolutionForm.patchValue({
      ContainmentActionsExecution: new Date()
    })
    this.containmentToResolvedPassed.emit({ submitted: true })

    if (
      this.ContainmentActionsRequiredFromIssueRes == true &&
      this.selectedRole == 'Support_Desk' &&
      (this.ratingValue == 'A' ||
        this.ratingValue == 'B' ||
        this.ratingValue == 'C' ||
        this.ratingValue == 'I')
    ) {
      this.isResolved = false;
      this.isRoleWithContainmentActionsSupportDesk = true;
      return true;

    }
    else {
      return false;
    }
  }
  async onSave($event: Event) {
    this.ActionNameForComplaints = "SaveResolutionSectionComplaint";
    await this.setFieldsValidation(this.ActionNameForComplaints);
    if (this.isManualChangeForCurrentRating) {
      this.ref = this.dialogService.open(CurrentratingConfirmPopupComponent,
        {
          header: "Update current rating",
          width: '40%',
          position: 'top',
          keepInViewport: true,
          autoZIndex: true,
          data: { manualClassChangedTo: this.issueResolutionForm.controls['classValue']?.value },
          styleClass: 'current-confirmation-popup'
        });

      this.ref.onClose.subscribe((value: any) => {
        if (value == true) {
          this.isSubmitted = true;
          this.saveDataPassed.emit({ submitted: true })
        }
      }
      );
    } else {
      this.isSubmitted = true;
      this.saveDataPassed.emit({ submitted: true })
    }
  }

  verifyResolution($event: Event) {
    // this.issueStatusVariable='In Process Verification'
    this.isSubmitted = true;
    this.verificationPassed.emit({ submitted: true })
  }

  get f() {
    return this.issueResolutionForm.controls;
  }

  COPQPopUp() {
    // this.isSubmitted = true;
    if (this.issueResolutionForm.controls['ActualRUCost']?.value == '') {
      this.messageService.add({
        severity: 'warn',
        summary: '',
        detail: 'Please fill Actual RU cost',
      });
    }

    this.ref = this.dialogService.open(COPQCategoryPopUpComponent, {
      header: 'Select COPQ category',
      width: '70%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'copq-category-pop'
    });

    this.ref.onClose.subscribe((receivedValuePGRCA: any) => {
      if (receivedValuePGRCA) {
        const concatValue = receivedValuePGRCA?.displayLabel;
        const copqData = { id: (receivedValuePGRCA?.id).toString(), name: receivedValuePGRCA?.name, parentId: receivedValuePGRCA?.parentId, identification: { id: (receivedValuePGRCA?.id)?.toString(), geid: receivedValuePGRCA?.geid, email: receivedValuePGRCA?.email, cid: (receivedValuePGRCA?.id)?.toString(), giS_GUID: '', email_Alias: '' }, displayLabel: receivedValuePGRCA?.displayLabel, tooltipInfo: receivedValuePGRCA?.tooltipInfo, isValid: true }
        this.issueResolutionForm.patchValue({
          COPQCategory: concatValue,
          COPQCategoryAllData: copqData
        });
      }
    });
  }

  PGRCAPopUp() {
    const resolvingData = this.additionalSubjectData.controls['resolvingUnitArray']?.value;
    this.resolvingUnitId = resolvingData?.id;
    const code = this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.resolvingUnit?.pgCode;
    const buCode = this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.resolvingUnit?.buCode;
    //const whereIsTheIssueBalId = this.complaintData?.areaComplaintCollection?.complaintInformationSection?.descriptionSection?.whereIssueDescription.split(',')[0];
    const whereIsTheIssueBalId = this.complaintData?.whereWasTheIssueDetectedId;
    //const resolvingUnitBalId = this.complaintData?.areaComplaintCollection?.resolvingUnitSection?.resolvingUnit?.tooltipInfo?.substring(8, 18);
    const defectCausePayload = {code: code, buCode: buCode, resolvingUnitBalId: this.resolvingUnitBalId, whereIsTheIssueBalId: whereIsTheIssueBalId, isDefectCause: false, isActive:true}

    const resolvingUnitData = this.additionalSubjectData.get('resolvingUnitArray')?.value;
    if (this.issueResolutionForm.controls['ActualRUCost']?.value == '') {
      this.messageService.add({
        severity: 'warn',
        summary: '',
        detail: 'Please fill Actual RU cost',
      });
    }
    this.ref = this.dialogService.open(PGRCACategoryPopUpComponent, {
      header: 'Select PG RCA category',
      width: '70%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      data: { pgrca: defectCausePayload },
      styleClass: 'pgrca-category-pop'
    });

    this.ref.onClose.subscribe((receivedValuePGRCA: any) => {
      if (receivedValuePGRCA) {
        this.setPGRCACategory(receivedValuePGRCA);
      }
    });
  }
  setPGRCACategory(receivedValuePGRCA) {
    const pgraData = { id: (receivedValuePGRCA?.id).toString(), name: receivedValuePGRCA?.name, parentId: receivedValuePGRCA?.parentId, identification: { id: (receivedValuePGRCA?.id)?.toString(), geid: receivedValuePGRCA?.geid, email: receivedValuePGRCA?.email, cid: (receivedValuePGRCA?.id)?.toString(), giS_GUID: '', email_Alias: '' }, displayLabel: receivedValuePGRCA?.displayLabel, tooltipInfo: receivedValuePGRCA?.tooltipInfo, isValid: true }
    const parentLabel = receivedValuePGRCA?.parent?.label;
    let toolTipData = '';
    if(parentLabel) {
      toolTipData = parentLabel + ' / ' + receivedValuePGRCA?.label
    }
    else {
      toolTipData = receivedValuePGRCA?.label
    }
    this.pgCategoryTooltipText = toolTipData;
    this.issueResolutionForm.patchValue({
      pgCategory: receivedValuePGRCA?.label,
      PGRCACategoryAllData: pgraData
    });

  }

  revertToNotAssignEmail($event: Event) {
    if (this.issueCaptureMainForm.controls["additionalSubjectForm"].get('isVerifiedSerial')?.value == false &&
      this.issueCaptureMainForm.controls["additionalSubjectForm"].get('BuCode')?.value == 'PGHV' &&
      this.issueCaptureMainForm.controls["additionalSubjectForm"].get('abbProductSerialNo')?.value) {
      this.spinner.hide();
      this.VerifySerialEventFromRCAPassed.emit({ actionName: "RevertInProcToNotAssignedStatus" });
    } else {
      this.ref = this.dialogService.open(SendEmailIssueAssignComponent, {
        header: 'Send Email',
        width: '80%',
        position: 'center',
        keepInViewport: true,
        autoZIndex: true,
        styleClass: 'send-email-issue-assign-pop',
        data: { complaintNo: this.complaintData.header.complaintNumber, complaintId: this.complaintData.header.id, role: this.userAccessData['roleCode'] }
      });

      this.ref.onClose.subscribe((receivedValue: any) => {

        if (receivedValue) {
          this.isSubmitted = true;
          this.messageService.add({ severity: 'success', summary: 'Message has been sent!' });
          this.revertToNotAssignedEmailPassed.emit({ submitted: true })
        }
      });
    }
  }
  public calculateRpnAndImpactRating() {
    let formatedData = {
      MatrixSelection: [],
      Rpn: 0,
      Class: null,
      ClassFriendlyResult: null,
      ShouldScoringMatrixBeVisible: false,
      ShouldScoringMatrixHeaderInfoBeVisible: false,
      ReviewRequired: false,
      VerificationInformation: { IsVisible: false, Status: null },
      DisplayName: null,
      matrixSelection: [
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[0].id,
            Name: this.getMatrixTreeActiveVariable.data[0].name,
            Domain: this.getMatrixTreeActiveVariable.data[0].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[0].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[0].matrixSeverity,
          SelectionId: this.issueResolutionForm.controls['availabilityRating']?.value,
          SortOrder: 1,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[1].id,
            Name: this.getMatrixTreeActiveVariable.data[1].name,
            Domain: this.getMatrixTreeActiveVariable.data[1].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[1].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[1].matrixSeverity,
          SelectionId: this.issueResolutionForm.controls['functionalityRating']?.value,
          SortOrder: 2,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[2].id,
            Name: this.getMatrixTreeActiveVariable.data[2].name,
            Domain: this.getMatrixTreeActiveVariable.data[2].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[2].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[2].matrixSeverity,
          SelectionId: this.issueResolutionForm.controls['onTimeDeliveryRating']?.value,
          SortOrder: 3,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[3].id,
            Name: this.getMatrixTreeActiveVariable.data[3].name,
            Domain: this.getMatrixTreeActiveVariable.data[3].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[3].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[3].matrixSeverity,
          SelectionId: this.issueResolutionForm.controls['cyberSecurityRating']?.value,
          SortOrder: 4,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[4].id,
            Name: this.getMatrixTreeActiveVariable.data[4].name,
            Domain: this.getMatrixTreeActiveVariable.data[4].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[4].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[4].matrixSeverity,
          SelectionId: this.issueResolutionForm.controls['productComplianceRating']?.value,
          SortOrder: 5,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[5].id,
            Name: this.getMatrixTreeActiveVariable.data[5].name,
            Domain: this.getMatrixTreeActiveVariable.data[5].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[5].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[5].matrixSeverity,
          SelectionId: this.issueResolutionForm.controls['ohsRiskRating']?.value,
          SortOrder: 6,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[6].id,
            Name: this.getMatrixTreeActiveVariable.data[6].name,
            Domain: this.getMatrixTreeActiveVariable.data[6].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[6].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[6].matrixSeverity,
          SelectionId: this.issueResolutionForm.controls['marginRating']?.value,
          SortOrder: 7,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[7].id,
            Name: this.getMatrixTreeActiveVariable.data[7].name,
            Domain: this.getMatrixTreeActiveVariable.data[7].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[7].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[7].matrixSeverity,
          SelectionId: this.issueResolutionForm.controls['reputationRating']?.value,
          SortOrder: 8,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[8].id,
            Name: this.getMatrixTreeActiveVariable.data[8].name,
            Domain: this.getMatrixTreeActiveVariable.data[8].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[8].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[8].matrixSeverity,
          SelectionId: this.issueResolutionForm.controls['massMediaRating']?.value,
          SortOrder: 9,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[9].id,
            Name: this.getMatrixTreeActiveVariable.data[9].name,
            Domain: this.getMatrixTreeActiveVariable.data[9].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[9].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[9].matrixSeverity,
          SelectionId: this.issueResolutionForm.controls['designChangeRating']?.value,
          SortOrder: 10,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[10].id,
            Name: this.getMatrixTreeActiveVariable.data[10].name,
            Domain: this.getMatrixTreeActiveVariable.data[10].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[10].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[10].matrixSeverity,
          SelectionId: this.issueResolutionForm.controls['instancesRating']?.value,
          SortOrder: 11,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[11].id,
            Name: this.getMatrixTreeActiveVariable.data[11].name,
            Domain: this.getMatrixTreeActiveVariable.data[11].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[11].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[11].matrixSeverity,
          SelectionId: this.issueResolutionForm.controls['resolutionRating']?.value,
          SortOrder: 12,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[12].id,
            Name: this.getMatrixTreeActiveVariable.data[12].name,
            Domain: this.getMatrixTreeActiveVariable.data[12].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[12].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[12].matrixSeverity,
          SelectionId: this.issueResolutionForm.controls['affectedCustomerRating']?.value,
          SortOrder: 13,
          DisplayName: null,
        },
        {
          Category: {
            Id: this.getMatrixTreeActiveVariable.data[13].id,
            Name: this.getMatrixTreeActiveVariable.data[13].name,
            Domain: this.getMatrixTreeActiveVariable.data[13].domain,
            SortOrder: this.getMatrixTreeActiveVariable.data[13].sortOrder
          },
          Severities: this.getMatrixTreeActiveVariable.data[13].matrixSeverity,
          SelectionId: this.issueResolutionForm.controls['controlRating']?.value,
          SortOrder: 14,
          DisplayName: null,
        },
      ],
      complaintId : this.complaintData?.header?.id || null
    };


    this.ircrService
      .classifyOriginalRating(formatedData)
      .subscribe((response) => {
        // console.log('classify',response);
        this.hideShow8DReportManualRating(response?.isHighImpact,response?.classFriendlyResult);
        this.justificationReadOnly = false;
        this.changeJustificationReadOnly.emit(this.justificationReadOnly);
        this.issueResolutionForm.controls['JustificationCurrent'].addValidators(Validators.required);;
        this.issueResolutionForm.controls['JustificationCurrent'].updateValueAndValidity();
        this.issueResolutionForm.patchValue({
          rpn: response?.rpn,
          //impactRating: response?.classFriendlyResult,
          classValue: response?.class,
          RCACorrectiveActions: response?.isHighImpact,
          JustificationCurrent: ''
        });
        if(response?.isHighImpact){
          this.issueResolutionForm.controls['RCACorrectiveActions'].disable();
          this.issueCaptureMainForm?.get('ratingForm').patchValue({
            IsComplaintDescriptionSectionMandatory:true
          })
        }
        else{
          this.issueResolutionForm.controls['RCACorrectiveActions'].enable();
          this.issueCaptureMainForm?.get('ratingForm').patchValue({
            IsComplaintDescriptionSectionMandatory:null
          })
          this.issueCaptureMainForm?.get('issueDescriptionForm').patchValue({
            EightDRCAIsRequiredByCustomer: false
          })
          this.complaintData.areaComplaintCollection.complaintInformationSection.EightDRCAIsRequiredByCustomer = false;
        }

        if(response?.classFriendlyResult == 'High Impact Class A' || response?.classFriendlyResult == 'Class C'){
          this.issueResolutionForm.patchValue({
            impactRating: response?.classFriendlyResult
          });
        }
        else{
          this.issueResolutionForm.patchValue({
            impactRating: 'Unconfirmed'
          });
        }

        this.issueCaptureMainForm.get('ratingForm').patchValue({
          HasHightImpactOnABB:response?.isHighImpact
        })
        this.complaintData.areaComplaintCollection.complaintInformationSection.highImpact.hasHightImpactOnABB = response?.isHighImpact;
        this.isManualChangeForCurrentRating=response?.isHighImpact;

        this.rcaValueChange();
        this.setFieldsValidation(this.ActionNameForComplaints);
        this.classOriginalValue.emit({ value: response?.classFriendlyResult });
        this.messageService.add({
          severity: 'success',
          summary: 'Rating configuration updated',
        });
      });
  }

  setButtonVisibility(statusCodes: any) {
    let currentComplaintStatus = this.complaintData?.header?.complaintStatus;
    return this.buttonVisibilityService.setButtonVisibility(statusCodes, currentComplaintStatus);
  }
  checkNumeric($event) {
    // console.log('serial No :',$event )
    if (window.event) // IE
    {
      if (($event.keyCode < 48 || $event.keyCode > 57 || ($event.key == '!' || $event.key == '@' || $event.key == '#' || $event.key == '$' || $event.key == '%' || $event.key == '^' || $event.key == '&' || $event.key == '*' || $event.key == '(' || $event.key == ')')) && $event.keyCode != 46 && $event.keyCode != 8 && $event.keyCode != 16 && $event.keyCode != 37 && $event.keyCode != 39 && $event.keyCode != 190) {
        // console.log('check 1st IF ==>')
        // this.additionalSubjectForm.patchValue({
        //   serialNo:this.additionalSubjectForm.controls['serialNo']?.value.replace($event.key,'')
        // })
        $event.returnValue = false;
        //  return false;
      }
    }
    else { // Fire Fox
      if (($event.which < 48 || $event.which > 57 || ($event.key == '!' || $event.key == '@' || $event.key == '#' || $event.key == '$' || $event.key == '%' || $event.key == '^' || $event.key == '&' || $event.key == '*' || $event.key == '(' || $event.key == ')')) && $event.which != 46 && $event.which != 8 && $event.which != 16 && $event.keyCode != 37 && $event.keyCode != 39 && $event.keyCode != 190) {
        // console.log('check 2nd IF ==>')
        // this.additionalSubjectForm.patchValue({
        //   serialNo:this.additionalSubjectForm.controls['serialNo']?.value.replace($event.key,'')
        // })
        $event.preventDefault();
        //  return false;
      }
    }
    return this.checkNumericDecimal($event);
  }

  checkNumericDecimal(event){
      const convertString = event.target.value.toString();
      const valueWithoutComma = convertString.replace(/,/g, '');
      const separator = valueWithoutComma.split('.');
      if(separator.length > 1) {
        return true;
      }
      const lengthofDecimal = separator[0].length;
      if(lengthofDecimal<13){
        return true;
      } else{
        if (event.which == 46 || event.which == 8 || event.which == 16 || event.which == 37 || event.which == 39 || event.which == 190) {
          return true;
        }
        else {
          return false;
        }
      }
  }

  onManualDDLChange(event: any) {
    let manualClass = event.value;
    if (manualClass != 'None') {
      this.justificationReadOnly = false;
      this.changeJustificationReadOnly.emit(this.justificationReadOnly);
      this.issueResolutionForm.patchValue({
        JustificationCurrent: '',
        ReviewRequired: ''
      });
      if ((manualClass == 'A' || manualClass == 'B' || manualClass == 'I')) {
        this.onShowReviewRequired(true);
        // this.isEightDReportReadOnly = false;
        this.issueResolutionForm.controls['eightDReportRequired'].enable();
        this.issueCaptureMainForm?.get('ratingForm').patchValue({
          IsComplaintDescriptionSectionMandatory:true
        })
      } else {
        this.onHideReviewRequired();
        // this.isEightDReportReadOnly = true;
        this.issueResolutionForm.controls['eightDReportRequired']?.disable();
        this.issueCaptureMainForm?.get('ratingForm').patchValue({
          IsComplaintDescriptionSectionMandatory:false
        })
      }
    } else {
      this.justificationReadOnly = true;
      this.changeJustificationReadOnly.emit(this.justificationReadOnly);
      this.onHideReviewRequired();
    }
    this.hideShow8DReportClassApplied(manualClass);
    this.onManualClassDDLChange(manualClass);
    this.setFieldsValidation(this.ActionNameForComplaints);
  }

  onShowReviewRequired(isManualChange) {
    let reviewRequiredVal = this.complaintData.areaComplaintCollection.complaintInformationSection.highImpactValidationViewModel.reviewRequired;
    this.reviewRequired_Id = true;
    this.changeReviewRequiredId.emit(this.reviewRequired_Id);
  }
  onHideReviewRequired() {
    // Assigned values to review required ddl whenn class C ccrp downgrade
    this.reviewRequired_Id = false;
    this.changeReviewRequiredId.emit(this.reviewRequired_Id);
  }

  onManual8DChange(manual8DChange: any) {
    if (manual8DChange.value == 'No') {
      this.isEightDReportJustificationReadOnly = false;
      this.issueResolutionForm.patchValue({
        isEightDJustificationCurrent: true
      })
    } else {
      this.issueResolutionForm.patchValue({
        EightDJustificationCurrent: '',
        isEightDJustificationCurrent: false
      })
      this.isEightDReportJustificationReadOnly = true;
    }
    this.setFieldsValidation(this.ActionNameForComplaints);
  }

  async setFieldsValidation(action) {
   // console.log("if resolution",action)
   for (let controlName in this.issueCaptureMainForm?.get('issueCaptureForm')['controls']) {
    let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
    if (validationData) {
        const control = this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        await control.setValidators(await this.getValidators(validationRules, this.fieldNameForFailureMode));
        await control.updateValueAndValidity(); // Update the validation status
    }
    else {
        await this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName).clearValidators();
        await this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName).updateValueAndValidity();
    }
}
for (const controlName in this.issueCaptureMainForm?.get('issueDescriptionForm')['controls']) {
    let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
    if (validationData) {
        const control = this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        await control.setValidators(await this.getValidators(validationRules, this.fieldNameForFailureMode));
        await control.updateValueAndValidity(); // Update the validation status
    }
    else {
        this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName).clearValidators();
        this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName).updateValueAndValidity();
    }
}
for (const controlName in this.issueCaptureMainForm?.get('ratingForm')['controls']) {
    let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
    if (validationData) {
        const control = this.issueCaptureMainForm?.get('ratingForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        await control.setValidators(await this.getValidators(validationRules, this.fieldNameForFailureMode));
        await control.updateValueAndValidity(); // Update the validation status
    }
    else {
        await this.issueCaptureMainForm?.get('ratingForm').get(controlName).clearValidators();
        await this.issueCaptureMainForm?.get('ratingForm').get(controlName).updateValueAndValidity();
    }
}
for (const controlName in this.issueCaptureMainForm?.get('additionalSubjectForm')['controls']) {
    let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
    if (validationData) {
        const control = this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        await control.setValidators(await this.getValidators(validationRules, this.fieldNameForFailureMode));
        await control.updateValueAndValidity(); // Update the validation status
    }
    else {
        await this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).clearValidators();
        await this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).updateValueAndValidity();
    }
}
for (const controlName in this.issueCaptureMainForm?.get('issueAssignmentForm')['controls']) {
    let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
    if (validationData) {
        const control = this.issueCaptureMainForm?.get('issueAssignmentForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        await control.setValidators(await this.getValidators(validationRules, this.fieldNameForFailureMode));
        await control.updateValueAndValidity(); // Update the validation status
    }
    else {
        await this.issueCaptureMainForm?.get('issueAssignmentForm').get(controlName).clearValidators();
        await this.issueCaptureMainForm?.get('issueAssignmentForm').get(controlName).updateValueAndValidity();
    }
}
    for (const controlName in this.issueResolutionForm?.controls) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      if (validationData) {
        const control = this.issueResolutionForm.get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(await this.getValidators(validationRules, this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        this.issueResolutionForm.get(controlName).clearValidators();
        this.issueResolutionForm.get(controlName).updateValueAndValidity();
      }
    }
  }
//User Story 7797: RITM0438869 | Make the fields non-mandatory | Manufacturing date & warranty date
setFieldValidationSeparate(action) {
  for (const controlName in this.issueCaptureMainForm?.get('issueResolutionForm')['controls']) {
    if(controlName == 'ProductAlreadyDelivered' || controlName=='NoOfUnitsShipped') {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      if (validationData) {
        const control = this.issueCaptureMainForm?.get('issueResolutionForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));
        control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
          this.issueCaptureMainForm?.get('issueResolutionForm').get(controlName).clearValidators();
          this.issueCaptureMainForm?.get('issueResolutionForm').get(controlName).updateValueAndValidity();
      }
    }
  }

  for (const controlName in this.issueCaptureMainForm?.get('additionalSubjectForm')['controls']) {
    if(controlName == 'ProductAlreadyDelivered' || controlName == 'ManufacturingDate' || controlName == 'warrantyEndDate'|| controlName=='abbProductSerialNo'|| controlName=='quantity') {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      if (validationData) {
        const control = this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));
        control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
          this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).clearValidators();
          this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).updateValueAndValidity();
      }
    }
  }
}
  private getValidators(rules: any[], fieldFailureModeValidation): any {
    let validators: Validators[] = [];
    let index = 0;
    for (const rule of rules) {
      index = index + 1;
        // console.log(this.issueCaptureMainForm,rules, rule.property,rule.depends,rule.value, rule.depends != null);
        if (rule.depends != null) {
          // console.log("111111", this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase());
          if (fieldFailureModeValidation != "None" && rule.depends == "Validation" && rule.value == fieldFailureModeValidation) {
           // console.log("222222");
            validators.push(Validators.required);
              return validators;
          }
          else if ((this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase())
          || (this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase())
          || (this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase())
          || (this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase())
          || (this.issueCaptureMainForm.controls["issueResolutionForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueResolutionForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase())
          || (this.issueCaptureMainForm.controls["issueAssignmentForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueAssignmentForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase())) {
          //  console.log("came here")
            validators.push(Validators.required);
              return validators;
          }
          else {
          //  console.log("33333");
            if(index==rules?.length){
          //    console.log("444444");
              return validators;
            }
          }
        }
        else {
        //  console.log(">>>>>>>",rule.property, rule.property=="JustificationCurrent" , this.justificationReadOnly , rule.property=="ReviewRequired" , !this.reviewRequired_Id,"////////",(rule.property=="JustificationCurrent" && this.justificationReadOnly) || (rule.property=="ReviewRequired" && !this.reviewRequired_Id));
          if((rule.property=="JustificationCurrent" && this.justificationReadOnly) || (rule.property=="ReviewRequired" && !this.reviewRequired_Id)){
          return validators;
        }
          validators.push(Validators.required);
            return validators;
        }
      }
    }


  revertToResolutionOwnerEmail($event: Event) {
    if (this.issueCaptureMainForm.controls["additionalSubjectForm"].get('isVerifiedSerial')?.value == false &&
      this.issueCaptureMainForm.controls["additionalSubjectForm"].get('BuCode')?.value == 'PGHV' &&
      this.issueCaptureMainForm.controls["additionalSubjectForm"].get('abbProductSerialNo')?.value) {
      this.spinner.hide();
      this.VerifySerialEventFromRCAPassed.emit({ actionName: "CoordinatorRequestsReturnToRO" });
    } else {
      this.ref = this.dialogService.open(SendEmailIssueAssignComponent, {
        header: 'Send Email',
        width: '80%',
        position: 'center',
        keepInViewport: true,
        autoZIndex: true,
        styleClass: 'send-email-issue-assign-pop',
        data: { complaintNo: this.complaintData.header.complaintNumber, complaintId: this.complaintData.header.id, role: this.userAccessData['roleCode'], from: 'RO' }
      });

      this.ref.onClose.subscribe((receivedValue: any) => {
        if (receivedValue) {
          this.messageService.add({ severity: 'success', summary: 'Message has been sent!' });
          this.isSubmitted = true;
          this.revertToResolutionOwnerEmailPassed.emit({ submitted: true })
        }
      });
    }
  }

  onManualClassDDLChange(manualClass: any) {
    let currentClassValue = this.complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.class;

    if (manualClass != 'None') {
      let currentRatingResponse: any = {};
      if (manualClass == MatrixClass[currentClassValue]) {
        this.isManualChangeForCurrentRating = false;
        if (manualClass == 'A') {
          this.issueResolutionForm.patchValue({
            eightDReportRequired: 'Yes',
            JustificationCurrent: '',
            EightDJustificationCurrent: '',
            reviewRequiredJustification: ''
          })
        }
        if (manualClass == 'B') {
          this.issueResolutionForm.patchValue({
            eightDReportRequired: 'Yes',
            JustificationCurrent: '',
            EightDJustificationCurrent: '',
            reviewRequiredJustification: ''
          })
        }
        if (manualClass == 'C') {
          this.issueResolutionForm.patchValue({
            eightDReportRequired: 'No',
            JustificationCurrent: '',
            EightDJustificationCurrent: '',
            reviewRequiredJustification: ''
          })
        }
        if (manualClass == 'I') {
          this.issueResolutionForm.patchValue({
            eightDReportRequired: 'Yes',
            JustificationCurrent: '',
            EightDJustificationCurrent: '',
            reviewRequiredJustification: ''
          })
        }
      } else {
        if (manualClass == 'A') {
          currentRatingResponse.classFriendlyResult = "High Impact Class A";
          this.isManualChangeForCurrentRating = true;
          this.issueResolutionForm.patchValue({
            eightDReportRequired: 'Yes',
            JustificationCurrent: '',
            EightDJustificationCurrent: '',
            reviewRequiredJustification: ''
          })
        }
        if (manualClass == 'B') {
          currentRatingResponse.classFriendlyResult = "High Impact Class B";
          this.isManualChangeForCurrentRating = true;
          this.issueResolutionForm.patchValue({
            eightDReportRequired: 'Yes',
            JustificationCurrent: '',
            EightDJustificationCurrent: '',
            reviewRequiredJustification: ''
          })
        }
        if (manualClass == 'I') {
          currentRatingResponse.classFriendlyResult = "High Impact Class I";
          this.isManualChangeForCurrentRating = false;
          this.issueResolutionForm.patchValue({
            eightDReportRequired: 'Yes',
            JustificationCurrent: '',
            EightDJustificationCurrent: '',
            reviewRequiredJustification: ''
          })
        }
        if (manualClass == 'C') {
          this.isManualChangeForCurrentRating = false;
          this.issueResolutionForm.patchValue({
            eightDReportRequired: 'No',
            JustificationCurrent: '',
            EightDJustificationCurrent: '',
            reviewRequiredJustification: ''
          })
        }
      }
    }
  }

  showClassValue(){
    if (this.complaintData != undefined) {
      let userAccessData;
      this.authorizationService.userAccessDetail.subscribe(async detail=>{
        if(detail){
          userAccessData = detail;
          let currselectedRole = userAccessData?.roleCode;
          let matrixClassABIC = this.complaintData?.areaComplaintResolution.matrixCurrentRatingViewModel.class;
          let matrixClass = this.complaintData?.areaComplaintResolution.matrixCurrentRatingViewModel.classFriendlyResult;
          let verification = this.complaintData?.areaComplaintResolution.matrixCurrentRatingViewModel.verificationInformation.status;
          let complaintStatus = this.complaintData?.header.complaintStatus;
          let isRoleExist = false;
          if (matrixClassABIC == MatrixClass.A || verification != 'Unconfirmed') {
            this.issueResolutionForm?.patchValue({
              impactRating: matrixClass
            })
          }
          if ((matrixClassABIC == MatrixClass.B || matrixClassABIC == MatrixClass.I) && (currselectedRole == RoleCode.Global_CCRP_Process_Owner || currselectedRole == RoleCode.Support_Desk || currselectedRole == RoleCode.Div_Process_Owner || currselectedRole == RoleCode.Pg_Process_Owner || currselectedRole == RoleCode.Bu_Process_Owner || currselectedRole == RoleCode.Hub_Process_Owner)) {
            this.issueResolutionForm?.patchValue({
              impactRating: matrixClass
            })
            isRoleExist = true;
          }
          if (verification == 'Unconfirmed' && isRoleExist == false && matrixClassABIC != MatrixClass.A) {
            this.issueResolutionForm?.patchValue({
              impactRating: "Unconfirmed"
            })
          }
          if (matrixClassABIC == MatrixClass.C){
            this.issueResolutionForm?.patchValue({
              impactRating: "Class C"
            })
          }
        }
      })
    }
  }
  hideShow8DReport(){
    if (this.complaintData != undefined){
      let userAccessData;
      this.authorizationService.userAccessDetail.subscribe(async detail=>{
        if(detail){
          userAccessData = detail;
          let currselectedRole = userAccessData?.roleCode;
          let matrixClassABIC = this.complaintData?.areaComplaintResolution.matrixCurrentRatingViewModel.class;
          const userEmail = this.userInfo?.email;
          if ((matrixClassABIC == MatrixClass.B || matrixClassABIC == MatrixClass.I || matrixClassABIC == MatrixClass.A) && (currselectedRole == RoleCode.Global_CCRP_Process_Owner || currselectedRole == RoleCode.Support_Desk || environment.idsForHighClassApproval.includes(userEmail))) {
            this.issueResolutionForm?.controls['eightDReportRequired']?.enable();
            this.issueResolutionForm?.controls['EightDJustificationCurrent']?.enable();
          }
          else{
            this.issueResolutionForm?.controls['eightDReportRequired']?.disable();
            this.issueResolutionForm?.controls['EightDJustificationCurrent']?.disable();
          }
        }
      })
    }
  }
  hideShow8DReportManualRating(isHighImpact, classValue){
    if(isHighImpact){
      let userAccessData;
      this.authorizationService.userAccessDetail.subscribe(async detail=>{
      if(detail){
          userAccessData = detail;
          let currselectedRole = userAccessData?.roleCode;
          const userEmail = this.userInfo?.email;
          if ((classValue == "High Impact Class A" || classValue == "High Impact Class I" || classValue == "High Impact Class B") && (currselectedRole == RoleCode.Global_CCRP_Process_Owner || currselectedRole == RoleCode.Support_Desk || environment.idsForHighClassApproval.includes(userEmail))) {
            this.issueResolutionForm?.controls['eightDReportRequired']?.enable();
            this.issueResolutionForm?.controls['EightDJustificationCurrent']?.enable();
          }
          else{
            this.issueResolutionForm?.controls['eightDReportRequired']?.disable();
            this.issueResolutionForm?.controls['EightDJustificationCurrent']?.disable();
          }
        }
      })
    }
    else{
      this.issueResolutionForm?.controls['eightDReportRequired']?.disable();
      this.issueResolutionForm?.controls['EightDJustificationCurrent']?.disable();
    }
  }
  hideShow8DReportClassApplied(classValue){
    if(classValue == 'A' || classValue == 'B' || classValue == 'I'){
      let userAccessData;
      this.authorizationService.userAccessDetail.subscribe(async detail=>{
      if(detail){
          userAccessData = detail;
          let currselectedRole = userAccessData?.roleCode;
          const userEmail = this.userInfo?.email;
          if (currselectedRole == RoleCode.Global_CCRP_Process_Owner || currselectedRole == RoleCode.Support_Desk || environment.idsForHighClassApproval.includes(userEmail)) {
            this.issueResolutionForm?.controls['eightDReportRequired']?.enable();
            this.issueResolutionForm?.controls['EightDJustificationCurrent']?.enable();
          }
        }
      })
    }
    else{
      this.issueResolutionForm?.controls['eightDReportRequired']?.disable();
      this.issueResolutionForm?.controls['EightDJustificationCurrent']?.disable();
    }
  }

  disableDefectCause(){
    if(this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
      return false;
    }
    else if(this.issueCaptureMainForm?.get('headerSection')?.get('complaintStatusId')?.value < 4){
      return true;
    }
    else if(this.issueCaptureMainForm?.get('issueDescriptionForm')?.get('whereIssueDescription')?.value == '' || this.issueCaptureMainForm?.get('issueDescriptionForm')?.get('whereIssueDescription')?.value == null){
      return true;
    }
    else{
      return false;
    }
  }

  downloadAttachementFromAPI(storeFileName, displayName) {
    this.downloadAttachementService.downloadAttachement(storeFileName, displayName);
  }

  productAlreadyDeliveredChanged() {
    this.issueResolutionForm.patchValue({
      isNoofUnitsShippedRequired: (this.issueCaptureMainForm.controls["ratingForm"].get('IsComplaintDescriptionSectionMandatory')?.value == true && this.issueResolutionForm.controls['productAlreadyDelivered']?.value?.value == 'Yes' ? true : false)
    });
    //User Story 7797: RITM0438869 | Make the fields non-mandatory | Manufacturing date & warranty date
    const buCode = this.complaintData?.header?.buCode ? this.complaintData?.header?.buCode : this.issueResolutionForm.controls['BuCode']?.value;
    const productDeliverValue = this.issueResolutionForm.controls['ProductAlreadyDelivered']?.value;
    if(buCode == 'PGHV' || buCode == 'PGTR') {
      if(productDeliverValue == 'Yes') {
         this.issueResolutionForm.patchValue({
          isNoofUnitsShippedRequired: true
        })
        this.additionalSubjectData.patchValue({
          IsBUExceptionFieldsMandatory: true
        })
        this.setFieldValidationSeparate(this.ActionNameForComplaints);
      }
      else if(productDeliverValue?.value == 'No'){
        this.issueResolutionForm.patchValue({
            isNoofUnitsShippedRequired: false
        })

        this.additionalSubjectData.patchValue({
          IsBUExceptionFieldsMandatory: false
        })
        this.setFieldValidationSeparate(this.ActionNameForComplaints);
      }
      else if(!productDeliverValue?.value)
      {
        this.issueResolutionForm.patchValue({
          isNoofUnitsShippedRequired: false
        })

        this.additionalSubjectData.patchValue({
          IsBUExceptionFieldsMandatory:false
        })
        this.setFieldValidationSeparate(this.ActionNameForComplaints);
      }
    }
    else {
      if(productDeliverValue == 'Yes' && this.issueCaptureMainForm.controls["ratingForm"].get('HasHightImpactOnABB')?.value) {
         this.issueResolutionForm.patchValue({
          isNoofUnitsShippedRequired: true
        })
      }
      else if(productDeliverValue?.value == 'No'){
        this.issueResolutionForm.patchValue({
            isNoofUnitsShippedRequired: false
        })
      }
      this.setFieldsValidation(this.ActionNameForComplaints);
    }
  }

  async onReject($event: Event){
    this.ref = this.dialogService.open(ReopenIssuePopupComponent, {
      header: 'Reject CCRP Highimpact class',
      width: '40%',
      position: 'center',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'reopen-issue-pop',
      data: true
    });
    this.isSubmitted = false;
    this.ref.onClose.subscribe((receivedValue: any) => {
      if (receivedValue) {
        this.issueResolutionForm.patchValue({
          JustificationCurrent: receivedValue
        });
        this.isSubmitted = true;
        this.rejectComplaintEventPassed.emit({ submitted: true })

      }
    })
  }

  download8DReport(){
    let complaintId= this.complaintData?.header?.id;
    this.spinner.show();
    this.downloadAttachementService.downloadEightDReport(complaintId).subscribe((response: ArrayBuffer) => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = this.date;
      downloadLink.click();
      this.spinner.hide();
    });
  }

  VerifyResolutionByBuProcessOwner($event: Event) {
    this.isSubmitted = true;
    this.VerifyResolutionByBuProcessOwnerEventPassed.emit({ submitted: true })
  }

}

