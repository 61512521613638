<div class="issue_description">
  <form [formGroup]="descForm">
    <!-- <input type="hidden" formControlName="isComplaintDescriptionSectionMandatory"> -->
    <div class="card flex justify-content-center border-0">
      <div>
        <div class="inner-heading-wrapper">
          <h6 class="inner-heading1"> {{ 'Complaint_IssueDescription_section' | translate }} </h6>
        </div>
        <div class="row mb-1">
          <div class="summarize-text">
            <label>{{ "Complaint_ComplaintReason" | translate }}<span class="asteriskRequired"></span></label>
            <textarea pInputTextarea [autoResize]="true" class="form-control customtextfield-l"
              [pTooltip]="issueDescText" [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"
              delay="500" [ngClass]="{'error-inputbox': isSubmitted && f['Reason'].errors}"
              formControlName="Reason"></textarea>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col">
            <div>
              <label>{{ "Complaint_WhatIssueDescription" | translate }}<span class="asteriskRequired"
                  *ngIf="this.descForm.controls['WhatIssueDescription']?.validator"></span></label>
            </div>
            <div>
              <textarea pInputTextarea [autoResize]="true" formControlName="WhatIssueDescription"
                [pTooltip]="whatsHappeningText" [escape]="false" tooltipPosition="top"
                [ngClass]="{'error-inputbox': isSubmitted && f['WhatIssueDescription'].errors}"
                tooltipStyleClass="toolTipClass" class="form-control textarea-height2"
                maxlength="{{maxLength.WHAT_ISSUE_DESC}}"></textarea>
            </div>
          </div>
          <div class="col calendar-cls">
            <div>
              <label>{{ "rptv_when_issuedesc" | translate }}<span class="asteriskRequired"
                  *ngIf="this.descForm.controls['whenIssueDescription']?.validator"></span></label>
            </div>
            <div class="datefield2">
              <p-calendar (onSelect)="whenHappenedDateOnSelect()" dateFormat="yy-mm-dd" [readonlyInput]="true"
                class="form-control customtextfield-l border-0 remove-padding remove-mt" #calendar
                formControlName="whenIssueDescription" [pTooltip]="whenHappenedText" [escape]="false"
                [ngClass]="{'error-calendarInput': isSubmitted && f['whenIssueDescription'].errors}" [maxDate]="minDate"
                tooltipPosition="top" tooltipStyleClass="toolTipClass" maxlength="{{maxLength.WHEN_ISSUE_DESC}}"
                (onSelect)="whenHappenedDateOnSelect()"></p-calendar>
              <!-- <button [pTooltip]="whenHappenedText" [escape]="false" placement="top"
                          tooltipStyleClass="toolTipClass" class="widgetButtoSmall ms-2 mt-2 calendar-btn"
                              (click)="openCalendar($event, 'selection')">Select</button> -->
            </div>
          </div>

        </div>
        <div class="row mb-1">
          <div class="col" *ngIf="complaintCreatedDate<ncrLiveDate">
            <div>
              <label>{{ "rptv_how_issuedesc" | translate }}<span class="asteriskRequired"
                  *ngIf="this.descForm.controls['howIssueDescription']?.validator"></span></label>
            </div>
            <div>
              <textarea formControlName="howIssueDescription" pInputTextarea [autoResize]="true"
                [pTooltip]="problemText2" [escape]="false" tooltipPosition="top"
                [ngClass]="{'error-inputbox': isSubmitted && f['howIssueDescription'].errors}"
                tooltipStyleClass="toolTipClass" class="form-control customtextfield-l"
                maxlength="{{maxLength.HOW_ISSUE_DEC}}"></textarea>
            </div>
          </div>
          <div class="col" *ngIf="complaintCreatedDate<ncrLiveDate">
            <div>
              <label>{{ "Complaint_WhyIssueDescription" | translate }}<span class="asteriskRequired"
                  *ngIf="this.descForm.controls['whyIssueDescription']?.validator"></span></label>
            </div>
            <div>
              <textarea formControlName="whyIssueDescription" pInputTextarea [autoResize]="true"
                [pTooltip]="problemText" [escape]="false" tooltipPosition="top"
                [ngClass]="{'error-inputbox': isSubmitted && f['whyIssueDescription'].errors}"
                tooltipStyleClass="toolTipClass" class="form-control textarea-height2"
                maxlength="{{maxLength.WHY_ISSUE_DESC}}"></textarea>
            </div>
          </div>
        </div>
        <div class="row mb-1">

          <div class="col">
            <div>
              <label>{{ "Complaint_InWitchProcessStepDescription" | translate }}<span class="asteriskRequired"
                  *ngIf="this.descForm.controls['inWhichProcessStep']?.validator"></span></label>
            </div>
            <div>
              <!-- <input class="form-control customtextfield-l forProcessTextBox" style="margin-left:26px;" pInputTextarea formControlName="issueStep"
                                [pTooltip]="issueDetectedText" [escape]="false" tooltipPosition="top"
                                tooltipStyleClass="toolTipClass" [readonly]="true">  -->
              <input type="hidden" formControlName="inWhichProcessStep">
              <textarea class="form-control customtextfield-l  processStepEnable productSection inputFieldDisabled"
                pInputTextarea [autoResize]="true" formControlName="issueStep" [pTooltip]="issueDetectedText"
                [escape]="false" tooltipPosition="top" tooltipPosition="top" tooltipStyleClass="toolTipClass" readonly
                [ngClass]="{'error-inputbox': isSubmitted && f['inWhichProcessStep'].errors}"> </textarea>
              <button type="button" class="widgetButtoSmall ms-2 mt-2 remove-ms" (click)="showComponentProcessStep()">
                {{ this.descForm.controls["issueStep"].value == "" ? "Select" : "Change" }}</button>
            </div>
            <!-- [disabled]="!disableBtn['issueStep']"
                        [ngClass]="!disableBtn['issueStep']?'grayButton': ''" -->
          </div>

          <div class="col">
            <div>
              <label>{{ "Complaint_WhereIssueDescription" | translate }}<span class="asteriskRequired"
                  *ngIf="this.descForm.controls['whereIssueDescription']?.validator"></span></label>
            </div>
            <div>
              <textarea class="form-control customtextfield-l whereDetected productSection inputFieldDisabled"
                pInputTextarea [autoResize]="true" formControlName="whereIssueDescription"
                [pTooltip]="problemDetectedText" [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"
                readonly
                [ngClass]="isSubmitted && this.descForm.controls['whereIssueDescription'].invalid ? 'error-inputbox' : ''"
                maxlength="{{maxLength.WHERE_ISSUE_DESC}}"></textarea>
              <button type="button" class="widgetButtoSmall ms-2 mt-2 remove-ms" (click)="showBalIdComponentPopup()">
                {{ this.descForm.controls["whereIssueDescription"].value == "" ? "Select" : "Change" }}
              </button>
            </div>
            <!-- [disabled]="!disableBtn['issueLocationArr']"
                        [ngClass]="!disableBtn['issueLocationArr']?'grayButton': ''" -->
          </div>
        </div>
        <input type="hidden" formControlName="whereWasTheIssueDetectedId">
        <div class="row country-infodesc" *ngIf="isEnableSAPQN || this.complaintData?.header?.sapId">
          <div class="col">
            <div>
              <label>{{ "Complaint_WorkCentre/Plant" | translate }}<span class="asteriskRequired"
                  *ngIf="this.descForm.controls['WorkCentreandPlant']?.validator"></span></label>
            </div>
            <div>
              <textarea class="form-control customtextfield-l productSection resolvingUnitDisabled" pInputTextarea
                [autoResize]="true" formControlName="WorkCentreandPlant" [pTooltip]="workcenter" [escape]="false"
                tooltipPosition="top" tooltipStyleClass="toolTipClass"
                [ngClass]="isSubmitted && this.descForm.controls['WorkCentreandPlant']?.invalid ? 'error-inputbox' : ''"
                readonly></textarea>
              <button type="button" class="widgetButtoSmall ms-2 mt-2 remove-ms" (click)="showWorkCenterPopup()"
                [disabled]="disableWorkCenter()" [ngClass]="disableWorkCenter() ? 'grayButton':''">
                {{ this.descForm.controls['WorkCentreandPlant']?.value == "" ||
                this.descForm.controls['WorkCentreandPlant']?.value == null ? "Select" : "Change" }}
              </button>
              <input type="hidden" formControlName="workCentreHidden">
            </div>
          </div>
          <div class="col">
            <div>
              <label>{{ "Complaint_CountryWhereTheIssueHappend" | translate }}<span class="asteriskRequired"
                    *ngIf="this.descForm.controls['CountryCodeWhereTheIssueHappen']?.validator"></span></label>
            </div>
            <div class="desccountry-selection-div">
              <p-dropdown placeholder="Please click here to select country " formControlName="CountryCodeWhereTheIssueHappen" [dropdownIcon]="'none'"
                  [filter]="true" [resetFilterOnHide]="true"
                  [ngClass]="isSubmitted && this.descForm.controls['CountryCodeWhereTheIssueHappen'].invalid ? 'error-dropdownInput' : ''"
                  [options]="countries" optionLabel="name">
                  <ng-template let-countries pTemplate="item">
                    <div class="country-item">
                      <div>{{countries.name}} &#40;{{countries.id}}&#41;</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              <button type="button" class="widgetButtoSmall ms-2 mt-2 remove-ms" [pTooltip]="fillInText"
                [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass" (click)="country()">
                {{ "ButtonFillCountryBasedOnCustomer" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row mb-1">

                </div> -->

      <div class="row">
        <div class="summarize-text">
          <div>
            <label>{{ "Complaint_WhatIsCustomerExpectedResolutionDescription" | translate }}<span
                class="asteriskRequired"
                *ngIf="this.descForm.controls['customerExpectedResolutionDescription']?.validator"></span></label>
          </div>
          <div>
            <textarea class="form-control customtextfield-l" pInputTextarea [autoResize]="true"
              formControlName="customerExpectedResolutionDescription" [pTooltip]="impactText" [escape]="false"
              placement="top"
              [ngClass]="isSubmitted && this.descForm.controls['customerExpectedResolutionDescription']?.invalid ? 'error-inputbox' : ''"
              tooltipStyleClass="toolTipClass"
              maxlength="{{maxLength.WHAT_IS_CUSTOMER_EXPECTED_RESOLUTION_DESC}}"></textarea>
          </div>
        </div>
      </div>
    </div>
    <fieldset>
       <div class="inner-heading-wrapper">
          <h6 class="inner-heading1">{{ "Complaint_IssueRequirementExpectation" | translate }}</h6>
        </div>
      <div class="row mb-2 ms-1 mt-2 containment-leftspace">
        <div class="col-md-6 check-action">
          <input [pTooltip]="containtmentActionRequiredText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass" type="checkbox" (change)="containmentChange()"
            formControlName="IsContainmentActionRequired" [escape]="false" placement="top"
            tooltipStyleClass="toolTipClass" name="Containment Actions required" class="m-2" value="True">
          <label [pTooltip]="containtmentActionRequiredText" [escape]="false" tooltipPosition="top"
            class="containment-label1">{{ "Complaint_ContainmentAction" | translate }} </label>
        </div>
        <div class="col-md-6 check-action">
          <input [pTooltip]="EightDRCAIsRequiredByCustomerText" [escape]="false" tooltipPosition="top"
            tooltipStyleClass="toolTipClass" type="checkbox" class="m-2"
            formControlName="EightDRCAIsRequiredByCustomer"> <label [pTooltip]="EightDRCAIsRequiredByCustomerText"
            [escape]="false" tooltipPosition="top"> RCA and
            corrective actions required
          </label>
        </div>
      </div>
      <div class="row mb-2 ms-1 mt-2 containement-rca-textarea containment-leftspace">
        <div class="col-md-6 check-action">
          <label class="containment-label"> {{ "Complaint_ContainmentActionDescription" | translate }} <span
            class="asteriskRequired" *ngIf="this.descForm.controls['IsContainmentActionRequired'].value == true"></span> </label>
            <textarea pInputTextarea [autoResize]="true" class="form-control ms-0"
            [ngClass]="{'error-inputbox': isSubmitted && f['ContainmentActionDescription'].errors}"
            formControlName="ContainmentActionDescription" style="min-height:65px;"
            maxlength="{{maxLength.CONTAINMENT_ACTION_EXPLAINATION}}"></textarea>
        </div>
        <div class="col-md-6 check-action">
          <label class="containment-label"> {{ "Complaint_CustomerExpectationForResolution" | translate }}</label>
            <textarea pInputTextarea [autoResize]="true" class="form-control ms-0"
            [ngClass]="{'error-inputbox': isSubmitted && f['CustomerExpectationForResolution'].errors}"
            formControlName="CustomerExpectationForResolution" style="min-height:65px;"
            maxlength="{{maxLength.CONTAINMENT_ACTION_EXPLAINATION}}"></textarea>
        </div>
      </div>
      <div class="row mb-2 ms-1 mt-2 containment-leftspace">
        <div class="col-md-6 check-action-calenderlabel calendar-inputfield date-width3">
          <div class="mb-2" style="display: flex;" *ngIf="this.descForm.controls['IsContainmentActionRequired'].value == true || !(complaintCreatedDate < ncrLiveDate)">

              <label class="containment-label mt-1">{{ "Complaint_ContainmentActionDeadLine" | translate }} <span
                  class="asteriskRequired asterik2-label"
                  *ngIf="(this.descForm.controls['IsContainmentActionRequired'].value == true || f['deadline'].errors || (this.descForm.controls?.['deadline']?.value!=null && this.descForm.controls?.['deadline']?.value!='' && this.complaintDetailsService.dateValidatorForPastDate(this.descForm.controls?.['deadline'],this.minDate)))"></span> </label>

              <p-calendar #calenderDeadline [pTooltip]="containmentActionText" [escape]="false" placement="top"
                tooltipStyleClass="toolTipClass" dateFormat="yy-mm-dd" [minDate]="minDate" [readonlyInput]="true"
                class="form-control customtextfield-l datetext-width2 border-0 ps-0 mt-0"
                [ngClass]="{'error-calendarInput': isSubmitted && (f['deadline'].errors || (this.descForm.controls?.['deadline']?.value!=null && this.descForm.controls?.['deadline']?.value!='' && this.complaintDetailsService.dateValidatorForPastDate(this.descForm.controls?.['deadline'],this.minDate)))}"
                (onSelect)="targetDeadlineDateOnSelect()" formControlName="deadline"></p-calendar>

          </div>
        </div>
        <div class="col-md-6 check-action-calenderlabel calendar-inputfield date-width3" >
          <div class="mb-1 mt-1" style="display: flex;">
              <label class="containment-label mt-1"> <span> {{ "cc_action_plan_rca_target_deadline" | translate
                  }}<span class="asteriskRequired"></span></span> </label>

              <p-calendar #calendarTarget [pTooltip]="targetDateText" [escape]="false" placement="top"
                tooltipStyleClass="toolTipClass" [readonlyInput]="true"
                class="form-control customtextfield-l datetext-width2 border-0 ps-0 mt-0" dateFormat="yy-mm-dd"
                formControlName="TargetResolvingDate" [minDate]="targetMinDate" (onSelect)="targetResoltuionDateOnSelect()"
                [ngClass]="{'error-calendarInput': isSubmitted && (f['TargetResolvingDate'].errors ||isPreviousTargetResolutionDate ||(this.descForm.controls['deadline'].value != null && this.descForm.controls?.['deadline'].value != '' && this.descForm.controls?.['TargetResolvingDate'].value != '' && this.descForm.controls?.['TargetResolvingDate'].value != null && this.complaintDetailsService.dateValidatorForPastDate(this.descForm.controls?.['TargetResolvingDate'], this.descForm.controls['deadline']?.value)))}">
              </p-calendar>
              <input type="hidden" formControlName="targetResolutionHiddenDate">


              <button [pTooltip]="targetDateText" [escape]="false" tooltipPosition="top" tooltipStyleClass="toolTipClass"
                class="widgetButtoSmall ms-2 mt-2 calendar-btn" (click)="openCalendarTargetDate($event, 'selection')"
                style="display:none;">Select</button>
              <a *ngIf=" this.complaintData?.areaComplaintCollection?.complaintInformationSection?.targetResolvingDateChangeCount > 0"
                [href]="'issues/' + this.complaintData?.header?.id + '/targetdatehistory'" class="changeLogStyle"
                target="_blank">{{'Complaint_TargetDateHistoryLink' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="row mb-2 ms-1 mt-2 containment-leftspace">
        <div class="col-md-6 check-action">
          <span>{{ "Complaint_ContainmentAction_Info" | translate }}</span>
        </div>
        <div class="col-md-6 check-action">
          <span>{{ "Complaint_TargetResolvingDateWarning2" | translate }}</span><br>
          <span>{{ "Complaint_TargetResolvingDateWarning" | translate }}</span>
          <!-- <span>{{ "Complaint_TargetResolvingDateWarning2" | translate }}</span> -->
        </div>
      </div>
      <div class="row mb-2 ms-1 mt-2 containment-leftspace" *ngIf="!(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.containmentAction?.execution==null)">
        <div class="col-md-6 check-action-calenderlabel calendar-inputfield date-width3" >
          <div class="mb-1 mt-1" style="display: flex;">
            <label class="containment-label mt-1">{{ "Complaint_ContainmentAction_Execution" | translate
            }}</label>
          <p-calendar #calendarTarget [readonlyInput]="true"
          class="form-control customtextfield-l datetext-width2 border-0 ps-0 mt-0" dateFormat="yy-mm-dd"
          formControlName="containmentExecutedDate" [minDate]="minDate">
           </p-calendar>
          </div>
        </div>
        <div class="col-md-6 check-action">
        </div>
      </div>
      <div *ngIf="showDateChangeReason" class="row mb-1 mt-1">
        <div class="col-md-3 col-sm-3 ms-4 ps-0 pe-0 text-end containment-leftspace2">
          <label class="containment-label mt-1"><span> {{ "Complaint_TargetResolvingDateLogReason" |
              translate}} <span class="asteriskRequired"></span></span></label>
        </div>
        <div class="col-md-3 col-sm-3 ps-1 mt-0 changeReason">
          <p-dropdown [options]="dateChangeReason" formControlName="dateChangeReason" optionLabel="reason"
            [ngClass]="{'error-dropdownInput': isSubmitted && showDateChangeReason && this.descForm.controls?.['dateChangeReason'].value==null}"
            placeholder="Please select reason" optionValue="id" (onChange)="onChangeTargetReason($event)"></p-dropdown>
        </div>
      </div>

      <div *ngIf="showChangeDescription" class="row mb-1 mt-1">
        <div class="col-md-3 col-sm-3 ms-4 ps-0 pe-0 text-end containment-leftspace2">
          <label class="containment-label">
            <span>
              {{
 "Complaint_TargetResolvingDateLogReasonOther" |
              translate
              }} <span class="asteriskRequired"></span>
            </span>
          </label>
        </div>
        <!-- Business Change Request 10058: RITM0871774 |  Limit  250 Characters in the Justification Field -->
        <div class="col-md-8 col-sm-8 ps-1 mt-0">
          <textarea [pTooltip]="changeDescription" pInputTextarea [autoResize]="true" [escape]="false"
            tooltipPosition="top" tooltipStyleClass="toolTipClass" class="form-control ms-0"
            formControlName="changeDescription" style="min-height:65px;" maxlength="{{maxLength.CHANGE_DESCRIPTION}}"
            [ngClass]="{'error-inputbox': isSubmitted && !this.descForm.controls?.['changeDescription'].value}"></textarea>
            <div><span>*Please note that the character limit for the Change-Description is 250.</span> </div>
        </div>
      </div>
      <input type="hidden" formControlName="targetResolvingDateChangeCount">
      <input type="hidden" formControlName="changeReasonId">
      <input type="hidden" formControlName="targetResolvingDateInitialValue">
    </fieldset>
     <div class="col-sm-12">
  <fieldset class="border-0">
    <div class="inner-heading-wrapper">
      <h6 class="inner-heading1"> {{ "ComplaintForm_AttachmentsSectionName" | translate }} </h6>
    </div>
    <div class="row mb-1 me-5 attachments-section">
      <input type="hidden" formControlName="uploadedAttachments">
      <div class="col-md-3 col-sm-3 ps-0 pe-0 text-center" *ngIf="informationAttachments?.length > 0">
        <label class="mt-2">{{ "ComplaintForm_UploadedAttachments" | translate }}</label>
      </div>
      <div class="row ms-5 mt-1" *ngIf="informationAttachments?.length > 0">
        <div class="col-md-12 p-0" *ngIf="imageAttachments?.length > 0">
          <span  style="padding-left: 10px;">Image attachments</span>
          <div class="already-uploaded-attachments-main" style="list-style-type: none;">
            <div class="mt-2 file-row" *ngFor="let ImginformationAttachment of imageAttachments; let attachmentIndex = index;">
              <span class="filename-text filenamewidth1"><a class="fileInfo"
                  pTooltip="<img width='350px' src='{{baseurl}}/api/v1/Attachment/Download?storeFileName={{ImginformationAttachment?.storeFileName}}&displayName={{ImginformationAttachment?.displayName}}' />"
                  [escape]="false" tooltipStyleClass="download-imgstyle"
                  [ngClass]="checkRemoved(ImginformationAttachment?.id) ? 'to-be-removed' : ''"
                  (click)="downloadAttachementFromAPI(ImginformationAttachment?.storeFileName,ImginformationAttachment?.displayName)"
                  style="cursor: pointer;" target="_blank">{{ImginformationAttachment?.displayName}}</a>
                <span class="col-md-1 col-sm-1 text-end filenamewidth2">
                  <!-- <button type="button" class="widgetButtoSmall attachButton" (click)="uploadedAttachmentRemove(attachmentIndex)"> X
                                                  </button> -->
                  <i class="fa fa-trash closebtn" aria-hidden="true"
                    (click)="uploadedAttachmentRemove(ImginformationAttachment?.id)"></i>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-12 p-0" *ngIf="otherDocAttachments?.length > 0">
          <span style="padding-left: 10px;">Other format attachments</span>
          <div class="already-uploaded-attachments-main">
            <div class="mt-2 file-row" *ngFor="let informationAttachment of otherDocAttachments; let attachmentIndex = index;">
              <span  class="filename-text filenamewidth1"><a class="fileInfo"
                  [ngClass]="checkRemoved(informationAttachment?.id) ? 'to-be-removed' : ''"
                  (click)="downloadAttachementFromAPI(informationAttachment?.storeFileName,informationAttachment?.displayName)"
                  style="cursor: pointer;" target="_blank">{{informationAttachment?.displayName}}</a>
                <span class="col-md-1 col-sm-1 text-end filenamewidth2">
                  <!-- <button type="button" class="widgetButtoSmall attachButton" (click)="uploadedAttachmentRemove(attachmentIndex)"> X
                                                  </button> -->
                  <i class="fa fa-trash closebtn" aria-hidden="true"
                    (click)="uploadedAttachmentRemove(informationAttachment?.id)"></i>
                </span>
              </span>
            </div>
          </div>
        </div>
          <div class="row">
            <span class="downloadAllLink">
              <a href="{{downloadAllUrl}}" target="_blank">Download All</a>
            </span>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-3 ps-0 pe-0 text-center">
        <label class="mt-2">{{ "ComplaintForm_SpecifyAttachment" | translate:paramForAttachment
          }}</label>
      </div>
      <div class="col-md-8 col-sm-9 p-0 selectfile">
        <p-fileUpload #createUploaderIssueDesc name="files[]" chooseLabel="Select Files" [showCancelButton]="true"
          chooseIcon="pi pi-paperclip" removeStyleClass="p-overlaypanel1" [multiple]="true" chooseStyleClass="p-overlaypanel"
          [maxFileSize]="60000000" [auto]="true" class="" [customUpload]="true"
          (uploadHandler)="myUploader(createUploaderIssueDesc,$event)"
          [disabled]="!disableSection && this.role != 'Issue Owner'">
          <ng-template let-file let-index pTemplate="file">
            <ul class="p-upload-files p-reset">
              <li class="p-file row">
                <div class="col-md-11 col-sm-11 filenamewidth1" [pTooltip]="file?.name" [escape]="false"
                  tooltipPosition="top" tooltipStyleClass="toolTipClass">
                  <span class="p-filename">{{file?.name}}</span>
                </div>
                <div class="col-md-1 col-sm-1 text-end filenamewidth2" [pTooltip]="removeText" [escape]="false"
                  tooltipPosition="top" tooltipStyleClass="toolTipClass">
                  <!-- <button type="button" class="widgetButtoSmall attachButton"
                                            (click)="removeFile(file ,createUploaderIssueDesc)"> X </button> -->

                  <i class="fa fa-trash closebtn" aria-hidden="true"
                    (click)="removeFile(file ,createUploaderIssueDesc)"></i>


                </div>
              </li>
            </ul>
          </ng-template>
        </p-fileUpload>

      </div>
    </div>
  </fieldset>
    </div>
  </form>
</div>
