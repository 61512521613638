import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ResolvingAddPersonPopupComponent } from '../resolving-add-person-popup/resolving-add-person-popup.component';
import { ResolvingUnitsDeletepersonComponent } from '../resolving-units-deleteperson/resolving-units-deleteperson.component';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import { ResolvingUnitsAddprimaryruinfoComponent } from '../resolving-units-addprimaryruinfo/resolving-units-addprimaryruinfo.component';
import { forEach, forEachRight, sortBy, uniq } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { LocalStorageServiceService } from 'src/app/services/local-storage-service.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';

@Component({
  selector: 'app-resolving-units',
  templateUrl: './resolving-units.component.html',
  styleUrls: ['./resolving-units.component.css', '../../../../../../src/styles.css']
})

export class ResolvingUnitsComponent implements OnInit {
  @Input('flagFromSearch') flagFromSearch: boolean = true;
  [x: string]: any;
  adminForm!: FormGroup;
  regionData: any;
  buData: any;
  radioChange: any;
  event: any;
  businessData: any;
  countriesData: any;
  customersData: any;
  filteredCustomersData: any;
  filteredSubstitute: any;
  division: any;
  bu: any;
  country: any;
  city: any;
  regions: any;
  activity: any;
  businessText: any;
  resolvingPopForm: any;
  pg: any;
  objsave: any;
  getCityList: any;
  ruProcessOwnerGeids: Array<any> = [];
  rucoordinatorGeids: Array<any> = [];
  ruFinallGeids: Array<any> = [];
  ruProcessOwnersAll: Array<any> = [];
  rucoordinatorsAll: Array<any> = [];
  @Output() filteredResolvingUnitDetails: any = [];
  filterSearchFinalRecord: any;
  checkonmine: boolean = false;
  checkruwithoutactive: boolean = false;
  rupersonsinfo: any;
  optionModel: any;
  optionModelChange = new EventEmitter<any>();
  //radio buttons
  btnColorChange0 = true;
  btnColorChangeactive0 = true;
  btnColorChangeactive1 = false;
  btnColorChange1 = false;

  btnColorChange2 = false;
  btnColorChangeactive2 = false;

  btnColorChange3 = true;

  btnColorChange4 = false;
  checkisActive: boolean = true;

  btnColorChangeprocess0 = true;
  btnColorChangeprocess1 = false;
  btnColorChangeprocess2 = false;
  btnColorChange5 = false;

  BUHubUsage: any;

  radioPOAssignedStatus: boolean = null;

  radioHubStatus: boolean = true;
  getrubsObjinfo: any;
  hideshow: boolean = false;

  expandIndex: any = [0];
  getProcessownerinfo: any;

  accButtonText = 'Expand All';
  employeeAllDetails: any = []

  private _isExpanded = true;
  radioRuassociation: boolean = null;
  radioRuprocessowner: any;
  objfindprocessowners: any;
  filteredprocessownersDetails: any = [];
  filteredGeId: any = [];
  employeeInfo: any;
  getempfindownerinfo: Array<any> = [];
  ruProcessOwnerResolvingUnitdata: Array<any> = [];
  coordinatorResolvingUnitdata: Array<any> = [];
  getcomaareBucodeinfo: any;
  showDeactivated: boolean = false;
  hideshowcard: boolean = false;
  rutdtest: Array<any> = [];
  iscountprocessorowner: number = 0;
  iscoordinaterowner: number = 0;
  isProcesscheck: boolean = false;
  getRes: any;
  getResobj: any;
  getProcesOwnerhoverdata: any;
  ruPersonInfo: any
  geids: [];
  userGeId: any;
  data: any = { guids: [] };
  indexedArray: any = [];
  hubPushedData: any = [];
  IsHubsExcelGenerating: boolean = false;
  noResultInfo: any;
  noResult = false;
  hubDataArray: any = [];
  showSelectRU: boolean = false;
  desabledSearch:boolean = false;
  ruProcessOwnerrr:any;
  ruProcessOwnercount:number=0;
  coordinatorProcessOwner:any;
  coordinatorProcessOwnercount:number=0

  // start tool tiops//
  primaryRUCoordinatorTooltip = " Primary Person";
  admin_ru_person_incative_tooltip = "This person is no longer active";
  deleteProcessOwnerTooltip = "Cannot delete the only Hub Process Owner";
  deleteProcessOwnerTooltipcheck = "Delete Process Owner";
  addprimaryRUCoordinatorTooltip = "Set Primary Person";
  admin_ru_details_intransitionperiod_tooltip = "Resolving Unit is in Transition Period and will be deleted soon. Please contact Masterdata for more details.";
  admin_item_pg_po_tp = "Show Process Owners";
  googlemap = "Google Map View";
  RUInactiveTooltip="RU inactive";

  // Emd tool tips///

  // pagination
  totalRecords: number;
  currentPage = 1;
  pagesCount = 0;
  numberOfCardsPerPage = 10;
  hubDetailsAsPerPagination: any = [];
  updatedFilteredDetails: any = [];
  showExportToExcel: boolean = false;

  constructor(private fb: FormBuilder,
  private adminService: AdminService,
  private resolvingUnitService: ResolvingUnitServiceService,
  private dialogService: DialogService,
  private spinner: NgxSpinnerService,
  private ref: DynamicDialogRef,
  private router: Router,
  private localStorageService: LocalStorageServiceService,
  private sessionStorage: SessionStorageServiceService,
  private breadcrumService: BreadcrumbService) { }

  ngOnInit(): void {
    const CurrentURL = this.router.url;
    if (CurrentURL != '/search/coordinator') {
      this.breadcrumService.menuItems = [
        { label: 'ADMIN PANEL', routerLink: 'admin' },
        { label: 'MANAGE RESOLVING UNITS' }
      ];
    }
    this.adminForm = this.fb.group({
      'business': ['PG'],
      'bu': [''],
      'region': [''],
      'pg': [''],
      'country': [''],
      'city': [''],
      'activity': [''],
      'employee': [''],
      'radioBusinessActivity': [''],
      'checkboxselectvalue': [''],
      'ruwithoutactive':[''],
      'ruiamassigned':[''],
      "hubIds": this.fb.array([]),
    })
    this.resolvingUnitService.getDivision().subscribe((response) => {

      if (response) {
        this.division = response;
      }
    })

    const division = 'PG';
    this.resolvingUnitService.getBuList(division).subscribe((res) => {
      if (res) {
        this.bu = res?.data.sort((a, b) => a.key.localeCompare(b.key));
        this.bu.map((rr: any) => {
          rr.name = rr?.value
          rr.pgName = rr?.name
        })
      }
    })

    this.resolvingUnitService.getPgForDivisionAndBu(this.adminForm.get('business').value, '').subscribe((response:any) => {
      if (response) {
        this.pg = response.sort((a, b) => a.key.localeCompare(b.key));
      }
    })

    this.adminForm.get('bu').valueChanges.subscribe((res) => {
      this.resolvingUnitService.getPgForDivisionAndBu(this.adminForm.get('business').value, res).subscribe((response) => {
        if (response) {
          this.pg = response;
        }
      })
    })

    const allRegion = this.localStorageService.getFromLocalStorage("mdregion_20200117");
    this.country = allRegion?.countries?.map((co) => ({id: co.id, name: '(' + co.id + ')  ' + co.name, rCode: co.rCode}));
    this.getCityList = allRegion?.cities?.sort((a, b) => a.name.localeCompare(b.name)).
    map((e) => ({id: e.id, name: e.name + ' Region: ' + e.rCode + ', Country: ' + e?.cName + ' (' + e?.cCode + ')'}));

    if(this.country?.length == 0) {
      this.resolvingUnitService.getAllCountry().subscribe((response) => {
        this.country = response;
        this.country = this.country.map((co) => ({id: co.id, name: '(' + co.id + ')  ' + co.name, rCode: co.rCode}));
      })
    }



      this.adminForm.get('country')?.valueChanges.subscribe((newValue) => {
        const countryCode = newValue;
        this.adminForm.controls['city'].reset();
        if (countryCode) {
          // this.resolvingUnitService.getAllCity(countryCode).subscribe((cities) => {
          //   if (cities) {

          //     this.getCityList = cities;
          //     this.getCityList = this.getCityList.map((e) => ({id: e.id, name: e.name + ' Region: ' + e.rCode + ', Country: ' + e?.cName + ' (' + e?.cCode + ')'}))
          //   }
          // });
          let cityList =  this.localStorageService.getFromLocalStorage('mdregion_20200117').cities;
          this.getCityList = cityList.filter((city)=> { return city.cCode == newValue});
          this.getCityList = this.getCityList.map((e) => ({id: e.id, name: e.name + ' Region: ' + e.rCode + ', Country: ' + e?.cName + ' (' + e?.cCode + ')'}))
        }
        else if(this.adminForm.get('region')?.value){
          let cityList =  this.localStorageService.getFromLocalStorage('mdregion_20200117').cities;
          this.getCityList = cityList.filter((city)=> { return city.rCode == this.adminForm.get('region')?.value});
          this.getCityList = this.getCityList.map((e) => ({id: e.id, name: e.name + ' Region: ' + e.rCode + ', Country: ' + e?.cName + ' (' + e?.cCode + ')'}))
        }
        else{
          this.getCityList = this.localStorageService.getFromLocalStorage('mdregion_20200117').cities;
          this.getCityList = this.getCityList.map((e) => ({id: e.id, name: e.name + ' Region: ' + e.rCode + ', Country: ' + e?.cName + ' (' + e?.cCode + ')'}))
        }
      })



    let businessText = this.adminForm.controls['activity'].value;
    this.resolvingUnitService
      .getActivity(businessText)
      .subscribe((response) => {
        this.activity = response;
      });
    this.adminService.getRegions().subscribe((response) => {
      this.regions = response;

      this.regions = this.regions.map((co) => ({key: co.key, value: '(' + co.key + ')  ' + co.value}));

    })

    this.adminForm.get('region').valueChanges.subscribe((response) => {

      this.adminForm.controls['country'].reset();
      this.adminForm.controls['city'].reset();
      if (response) {

        this.adminService.getcountry(response).subscribe((response) => {

          if (response) {
            this.country = response;
            // this.country.map((data) => {
            //   data.value = '(' + data.key + ')' + ' ' + '-' + ' ' + data.value;
            // })
            this.country = this.country.map((co) => ({id: co.key, name: '(' + co.key + ')  ' + co.value}));
          }
        })
      }
      else{

        let countryList =  this.localStorageService.getFromLocalStorage('mdregion_20200117').countries;
        this.country = countryList.map((co) => ({id: co.id, name: '(' + co.id + ')  ' + co.name}));
      }
    })


    this.adminForm.get('region').valueChanges.subscribe((response) => {

      if (response) {
            // this.resolvingUnitService.getAllCitybyresion(response).subscribe((cities) => {
            //   if (cities) {

            //     this.getCityList = cities;
            //     this.getCityList = this.getCityList.map((e) => ({id: e.id, name: e.name + ' Region: ' + e.rCode + ', Country: ' + e?.cName + ' (' + e?.cCode + ')'}))
            //   }
            // });
          let cityList =  this.localStorageService.getFromLocalStorage('mdregion_20200117').cities;
          this.getCityList = cityList.filter((city)=> { return city.rCode == response});
          this.getCityList = this.getCityList.map((e) => ({id: e.id, name: e.name + ' Region: ' + e.rCode + ', Country: ' + e?.cName + ' (' + e?.cCode + ')'}))
      }
      else{
        this.getCityList =  this.localStorageService.getFromLocalStorage('mdregion_20200117').cities;
        this.getCityList = this.getCityList.map((e) => ({id: e.id, name: e.name + ' Region: ' + e.rCode + ', Country: ' + e?.cName + ' (' + e?.cCode + ')'}))
      }
    })

    this.userGeId = this.sessionStorage.getData('userInfo')?.geid;
  }

  get hubIds(): FormArray {
    return this.adminForm.get('hubIds') as FormArray
  }

  checkselectedval(event: any, data: any, ind: any) {
    if (event.target.checked == false) {
      data.selectedcheck = false;
      const index: number = this.hubPushedData.findIndex((e) => e.id == data.id);
      if (index !== -1) {
        this.hubPushedData.splice(index, 1);
      }

      const indexHubData: number = this.hubDataArray.findIndex((e)=> e.id == data.id);
      if(indexHubData !== -1){
        this.hubDataArray.splice(indexHubData, 1);
      }

      //const indexHubIds = this.hubIds.value.findIndex((e) => e == data.id);
      //if(indexHubIds !== -1){
        //this.hubIds.push(new FormControl(this.hubIds.value.splice(indexHubIds, 1)));
        //this.hubIds.value.splice(indexHubIds, 1);
      //}
    }
    if (event.target.checked == true) {
      data.selectedCheck = true;
      this.hubIds.push(new FormControl(data.id))
      const selectedIndex = this.hubPushedData.findIndex((h) => h.id == data.id);
      if (selectedIndex == -1) {
        this.indexedArray.push(selectedIndex);
        if(data.pgCode) {
          this.hubPushedData.push({ hubsData: data.divCode + ', ' + data.buCode + ', ' + data.pgCode, id: data.id });
        }
        else {
          this.hubPushedData.push({ hubsData: data.divCode + ', ' + data.buCode , id: data.id });
        }
      }

      const selectIndexHubData = this.hubDataArray.findIndex((h)=> h.id == data.id);
      if(selectIndexHubData == -1){
        this.hubDataArray.push(data);
      }
    }
  }

  showruAddPersonPopup(hubData, filteredResolvingUnitDetails, index) {
    hubData.selectedcheck = true;
    this.hubIds.push(new FormControl(hubData.id))
    const selectedIndex = this.hubPushedData.findIndex((h) => h.id == hubData.id);
    if (selectedIndex == -1) {
      this.indexedArray.push(selectedIndex);
      if(hubData.pgCode) {
        this.hubPushedData.push({ hubsData: hubData.divCode + ', ' + hubData.buCode + ', ' + hubData.pgCode, id: hubData.id });
      }
      else {
        this.hubPushedData.push({ hubsData: hubData.divCode + ', ' + hubData.buCode , id: hubData.id });
      }

    }

    const selectedIndexHubDataArray = this.hubDataArray.findIndex((h)=> h.id == hubData.id);
    if(selectedIndexHubDataArray == -1){
      this.hubDataArray.push(hubData);
    }

    this.ref = this.dialogService.open(ResolvingAddPersonPopupComponent, {
      header: 'Add Resolving Unit person(s)',
      width: '63%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'resolvingunitaddperson-popup',
      data: {
        //cardinfo: hubData,
        cardinfo: this.hubDataArray,
        hubIds: uniq(this.hubIds.value),
        hubArray: filteredResolvingUnitDetails,
        selectedHubs: this.hubPushedData
      }
    });
    this.ref.onClose.subscribe((recevied) => {
      if (recevied) {
        this.ruProcessOwnersAll = [];
        this.rucoordinatorsAll = [];
        this.hubPushedData = [];
       // hubData.selectedcheck = false;
        this.adminForm.controls['checkboxselectvalue'].reset();
        this.search();
      }
    })
  }

  showrudeletePersonPopup(empinfo, cardinfo, ruPersonType) {
    this.gethubDataProcess(empinfo, cardinfo);
    const refee = this.dialogService.open(ResolvingUnitsDeletepersonComponent, {
      header: 'Delete person',
      width: '50%',
      position: 'top',
      keepInViewport: true,
      autoZIndex: true,
      styleClass: 'resolvingunitdeleteperson-popup',
      data: {
        empinfo: empinfo,
        cardinfo: cardinfo,
        ruPersonType: ruPersonType,
        ruProcessOwnerrr:this.ruProcessOwnerResolvingUnitdata,
        ruProcessOwnercount: this.iscountprocessorowner,
        coordinatorProcessOwner: this.coordinatorResolvingUnitdata,
        coordinatorProcessOwnercount: this.iscoordinaterowner,
        getrubsObjinfo: this.getrubsObjinfo
      }
    });
    refee.onClose.subscribe((recevied) => {
      if (recevied) {
        this.ruProcessOwnersAll = [];
        this.rucoordinatorsAll = [];
        this.search();
      }
    })
  }

  showaddprimaryruPersonPopup(empinfo, cardinfo, ruPersonType) {
    // debugger;
    this.gethubDataProcess(empinfo, cardinfo);

    const refPrimary = this.dialogService.open(ResolvingUnitsAddprimaryruinfoComponent, {
      header: 'Set Primary Person',
      width: '50%',
      position: 'top',
      keepInViewport: true,
      styleClass: 'resolvingunitaddprimaryinfo-popup',
      data: {
        empinfo: empinfo,
        cardinfo: cardinfo,
        ruPersonType: ruPersonType,

        ruProcessOwnerrr:this.ruProcessOwnerResolvingUnitdata,
        ruProcessOwnercount: this.iscountprocessorowner,
        coordinatorProcessOwner: this.coordinatorResolvingUnitdata,
        coordinatorProcessOwnercount: this.iscoordinaterowner,
        getrubsObjinfo: this.getrubsObjinfo

      }

    });
    refPrimary.onClose.subscribe((recevied) => {
      if (recevied) {
        this.ruProcessOwnersAll = [];
        this.rucoordinatorsAll = [];
        this.search();
      }
    })
  }


  showSelectedHubCriteria() {
    this.adminForm.controls['bu'].reset();
    this.adminForm.controls['pg'].reset();
    if (this.adminForm.controls['business'].value == '' || this.adminForm.controls['business'].value == null) {
      this.showSelectRU = true;
      this.hubDetailsAsPerPagination=[];
      this.showExportToExcel=false;
    }
  }

  public get isExpanded() {
    return this._isExpanded;
  }

  public set isExpanded(value: boolean) {
    this._isExpanded = value;
  }

  toggleAllAccordions() {
    if (this.expandIndex.length == 0) {
      this.expandIndex = [0];
    } else {
      this.expandIndex = [];
    }
    this.accButtonText = this.expandIndex.length ? 'Collapse All' : 'Expand All';
  }
  clear() {
    this.onActive();
    this.onprocessAll();
    this.onAll();
    this.onClickAll();
    this.hubDataArray = [];
    this.checkonmine=false;
    this.checkruwithoutactive=false;
    this.showSelectRU = false;
    this.desabledSearch=true;
    this.hideshowcard = false;
    this.noResultInfo=null;
    this.filteredResolvingUnitDetails = [];
    this.rupersonsinfo = [];
    this.ruFinallGeids = [];
    this.filteredprocessownersDetails = [];
    this.filteredGeId = [];
    this.adminForm.reset();
    this.adminForm.controls['business'].patchValue('PG');
    this.showExportToExcel = false
  }
  cleardata() {
    // this.filteredResolvingUnitDetails = [];
    this.rupersonsinfo = [];
    this.ruProcessOwnersAll = [];
    this.rucoordinatorsAll = [];
    this.ruFinallGeids = [];
    //this.filteredprocessownersDetails = [];
    //this.hubDetailsAsPerPagination=[];
    this.filteredGeId = [];
    this.hubDataArray = [];
    this.hubPushedData = [];
  }
  // getCities() {
  //   debugger
  //   let countyId = this.adminForm.controls['country'].value;
  //   this.resolvingUnitService.getAllCity(countyId).subscribe((cities) => {

  //     if (cities) {

  //       this.getCityList = cities;
  //     }
  //   });
  // }

  selectOption(option: any): void {
    this.optionModel = option;
    this.optionModelChange.emit(this.optionModel);
  }

  handleOptionChangeGlobal(selectedOption: string | null) {
    this.radioChange = selectedOption;
    if (this.radioChange == true) {
      this.adminForm.get('region')?.disable();
      this.adminForm.get('country')?.disable();
      this.adminForm.get('city')?.disable();
      this.adminForm.get('activity')?.disable();
    }
    else if (this.radioChange == false || this.radioChange == null) {
      this.adminForm.get('region')?.enable();
      this.adminForm.get('country')?.enable();
      this.adminForm.get('city')?.enable();
      this.adminForm.get('activity')?.enable();
    }
    else {
      this.radioChange = null;
    }
  }

  onAll() {
    this.radioPOAssignedStatus = null;
    this.btnColorChange0 = true;
    this.btnColorChange1 = false;
    this.btnColorChange2 = false;
    if (this.btnColorChange0 == true) {
      this.adminForm.get('region')?.enable();
      this.adminForm.get('country')?.enable();
      this.adminForm.get('city')?.enable();
      this.adminForm.get('activity')?.enable();
    }
  }

  onLocal() {
    this.radioPOAssignedStatus = false;
    this.btnColorChange0 = false;
    this.btnColorChange1 = true;
    this.btnColorChange2 = false;
    if (this.btnColorChange1 == true) {
      this.adminForm.get('region')?.enable();
      this.adminForm.get('country')?.enable();
      this.adminForm.get('city')?.enable();
      this.adminForm.get('activity')?.enable();
    }
  }

  onGlobal() {
    this.radioPOAssignedStatus = true
    this.btnColorChange0 = false;
    this.btnColorChange1 = false;
    this.btnColorChange2 = true;

    if (this.btnColorChange2 == true) {
      this.adminForm.get('region')?.disable();
      this.adminForm.get('country')?.disable();
      this.adminForm.get('city')?.disable();
      this.adminForm.get('activity')?.disable();
    }
  }


  onAllActive() {
    this.checkisActive = null;
    this.btnColorChangeactive0 = false;
    this.btnColorChangeactive1 = true;
    this.btnColorChangeactive2 = false;
  }
  onActive() {
    this.checkisActive = true;
    this.btnColorChangeactive0 = true;
    this.btnColorChangeactive1 = false;
    this.btnColorChangeactive2 = false;
  }
  onInActive() {
    this.checkisActive = false;
    this.btnColorChangeactive0 = false;
    this.btnColorChangeactive1 = false;
    this.btnColorChangeactive2 = true;
  }

  onprocessAll() {
    this.radioRuprocessowner = null;
    this.btnColorChangeprocess0 = true;
    this.btnColorChangeprocess1 = false;
    this.btnColorChangeprocess2 = false;
  }

  onprocessOwner() {
    this.radioRuprocessowner = 1;
    this.btnColorChangeprocess0 = false;
    this.btnColorChangeprocess1 = true;
    this.btnColorChangeprocess2 = false;
  }
  onCoordinator() {
    this.radioRuprocessowner = 2;
    this.btnColorChangeprocess0 = false;
    this.btnColorChangeprocess1 = false;
    this.btnColorChangeprocess2 = true;
  }

  onClickAll() {
    this.radioRuassociation = null;
    this.radioHubStatus = null;
    this.btnColorChange3 = true;
    this.btnColorChange4 = false;
    this.btnColorChange5 = false;
  }

  onAssigned() {
    this.radioRuassociation = true;
    this.radioHubStatus = true;
    this.btnColorChange3 = false;
    this.btnColorChange4 = true;
    this.btnColorChange5 = false;
  }

  onNotAssigned() {
    this.radioRuassociation = false;
    this.radioHubStatus = false;
    this.btnColorChange3 = false;
    this.btnColorChange4 = false;
    this.btnColorChange5 = true;
  }

  checkselected(event) {
    if (event.target.checked == true) {
      this.checkonmine = true;
    }
    else {
      this.checkonmine = false;
    }
  }

  checkselectedwithoutactive(event) {
    if (event.target.checked == true) {
      this.checkruwithoutactive = true;
    }
    else {
      this.checkruwithoutactive = false;
    }
  }

  filterCustomersData(event) {
    let query = event.query;
    this.filteredCustomersData = this.customersData.filter(customerData => customerData.name?.toLowerCase()?.includes(query.toLowerCase()));
  }
  filterSubstitude(event) {
    if(event.filter.length>2){
      const passingData = { wildcard: event?.filter, onlyActive: false, page: 1, size: 25 }
      this.adminService.getFilteredUser(passingData).subscribe((res) => {
        this.filteredSubstitute = res;
      })
    }
  }

  returnCountriesArray(inputRegion:any){
    let outputCountries =[];
    inputRegion.forEach(country =>{
      outputCountries.push(country.id);
    })
    return outputCountries;
  }

  search() {
    this.hubDataArray = [];
    this.currentPage = 1;
    this.cleardata();
     setTimeout(() => this.spinner.show(), 1000);
    this.objsave = {
      "limit": 0,
      "division": this.adminForm.controls['business'].value ? this.adminForm.controls['business'].value : '',
      "bu": this.adminForm.controls['bu'].value == "" ? null : this.adminForm.controls['bu'].value,
      "pg": this.adminForm.controls['pg'].value == "" ? null : this.adminForm.controls['pg'].value,

      "countries": (this.adminForm.controls['region'].value && this.adminForm.controls['country'].value == null) ? this.returnCountriesArray(this.country) : this.checkBlank(this.adminForm.controls['country'].value), //[this.adminForm.controls['country'].value == "" ? null : this.adminForm.controls['country'].value],
      "employee": this.adminForm.controls['employee'].value == "" ? null : this.adminForm.controls['employee'].value,
      "city": this.adminForm.controls['city'].value == "" ? null : this.adminForm.controls['city'].value,
      // "cities": [
      //   "string"
      // ],
      "activity": this.adminForm.controls['activity'].value == "" ? null : this.adminForm.controls['activity'].value,
      "isGlobal": this.radioPOAssignedStatus == undefined ? null : this.radioPOAssignedStatus,
      "ruPersonType": this.radioRuprocessowner == undefined ? null : this.radioRuprocessowner,
      "ruPersonAssigned": this.radioRuassociation == undefined ? null : this.radioRuassociation,
      "active": this.checkisActive,
      "onlyMine": this.checkonmine,
      "withoutActivePeople": this.checkruwithoutactive,
      "currentUser": this.sessionStorage.getData('userInfo').geid
    }

    this.adminService.getSearchResolvingUnitDetails1(this.objsave).then((res: any) => {
      this.totalRecords = res.ruInfo.length;
      this.filteredResolvingUnitDetails = res.ruInfo;
      this.updatedFilteredDetails = this.filteredResolvingUnitDetails;


      if (this.filteredResolvingUnitDetails == '' || this.filteredResolvingUnitDetails == null) {
        this.noResult = true;
        this.noResultInfo = 'No results found';
        this.hideshowcard = false;
        this.showExportToExcel = false;
        this.spinner.hide();
      }
      else
      {
        this.updatedCards();
        this.pagesCount = Math.ceil(this.totalRecords / this.numberOfCardsPerPage);
        this.hideshowcard = true;
        this.showExportToExcel = true;
        this.noResult = false;
        this.noResultInfo = null;



        for (let i = 0; i < this.filteredResolvingUnitDetails.length; i++) {
          this.filteredResolvingUnitDetails[i]["showContacts"] = false;
          this.filteredResolvingUnitDetails[i]["selectedcheck"] = false;
        }

        const geid = [];
        res.ruInfo.forEach(element => {
          element.ruProcessOwnerGeids.forEach((allGeid) => {
            geid.push(allGeid)
          })
        });

        res.ruInfo.forEach(element => {
          element.coordinatorGeids.forEach((allGeid) => {
            geid.push(allGeid)
          })
        });

        this.adminService.getRupersonsinfo(geid).subscribe((res) => {
          this.ruPersonInfo = res
          this.ruPersonInfo = sortBy(this.ruPersonInfo, ['name']);
        })
        //this.getProcessOwnerData();
      }
      setTimeout(() => this.spinner.hide(), 15000);
    })

  }


  checkBlank(content) {
    if (content == undefined || content == null || content == "") {
      return [];
    } else {
      return [content];
    }
  }
  getEmployeeDetailsPatching() {


    this.filteredResolvingUnitDetails.forEach(ru => {

      ru.ruProcessOwnerGeids.forEach(element => {
        let arrayid = [element];
        this.adminService.getRupersonsinfo(arrayid).subscribe((res) => {
          if (res?.[0].ruProcessOwnerResolvingUnitIds.includes(ru.id)) {
            this.ruProcessOwnersAll.push(res)
            this.ruProcessOwnersAll = sortBy(this.ruProcessOwnersAll, ['isActive', 'name']);
          }
        });
      });

      ru.coordinatorGeids.forEach(element => {
        let arraycordinatgeid = [element];
        this.adminService.getRupersonsinfo(arraycordinatgeid).subscribe((res) => {
          if (res?.[0].coordinatorResolvingUnitIds.includes(ru.id)) {
            this.rucoordinatorsAll.push(res)
            this.rucoordinatorsAll = sortBy(this.rucoordinatorsAll, ['isActive', 'name']);
          }
        });
      });

      // start findbyprocessowner//


      for (let m = 0; m < this.filteredResolvingUnitDetails.length; m++) {

        let objdata = {
          "div": this.filteredResolvingUnitDetails[m].divCode,
          "bu": this.filteredResolvingUnitDetails[m].buCode,
          "pg": this.filteredResolvingUnitDetails[m].pgCode,
          "country": this.filteredResolvingUnitDetails[m].address.countryCode,
          "isGlobal": this.filteredResolvingUnitDetails[m].isGlobal
        }

        this.adminService.getprocessownersDetails(objdata).subscribe(async res => {
          this.filteredprocessownersDetails = [...this.filteredprocessownersDetails, { res: res, index: m }]
          //console.log(this.filteredprocessownersDetails, "YYYYYYYY");
          if (this.filteredprocessownersDetails.length == this.filteredResolvingUnitDetails.length) {
            this.filteredprocessownersDetails?.map((r) => {
              this.filteredGeId = [...this.filteredGeId, { geid: r.res.map((rer) => rer.geid), index: r.index }]
            })
            await this.filteredGeId.map((value, ind) => {
              this.adminService.getEmployeeInfo(value.geid).subscribe(async res => {
                await this.employeeData(res, value);
              })
            })
          }
        })
      }
    })
  }

  employeeData(res, value) {
    this.employeeAllDetails = [...this.employeeAllDetails, { res: res, index: value.index }];

  }


  resolvingEmployeeInfo(index) {



    const find = this.employeeAllDetails.find((f) => f.index == index)
   if (find?.res?.length>0)
   {
    //       return find.res.map((re, i) => '<li>' + re.name + ' <span>' +this.getprocessownersDetails(this.filteredprocessownersDetails[index].res[i])+ '</span></li> ' + " " ).join("")
    //
    //       // this.getRes = find.res.map((re) => `<li>${re.name}</li>`).join("");
    //       // this.getResobj= find.res.map((re, i) => '<span>' +this.getprocessownersDetails(this.filteredprocessownersDetails[index].res[i])+ '</span>' );
    //
    //      // let getallobj=this.getRes +" " + this.getResobj;
    //        // return this.getRes;
    //
    return find?.res
    }
    else
    {
       return []
    }

  }

  getprocessownersDetails(value) {
    if (value?.div != null) {
      return value?.div;
    }
    else if (value?.bu != null) {
      return value?.bu;
    }
    else {
      return value?.pg;
    }

  }

  hubDataProcess(ruperson, hubdata) {
    if (hubdata.ruProcessOwnerGeids.includes(ruperson.displayGeid)) {
      return ruperson.name;
    }
    else {
      return ''
    }
  }
  clearcountry(event)
  {

    const unselectedGuids = event.target.innerText;
    // if(unselectedGuids!= "Click or type to select Country" )
    // {
    //   this.getCityList=null;
    // }
  }

  gethubDataProcess(empdata, hubData) {

    this.getrubsObjinfo=null;
    this.getProcesOwnerhoverdata =null;
    this.ruProcessOwnerResolvingUnitdata = [];
    this.coordinatorResolvingUnitdata = [];
    this.iscountprocessorowner=0;
    this.iscoordinaterowner=0;
    this.getrubsObjinfo = empdata;
    let userPopuphoverDetails;


  // let obj=  {
  //     "geid": empdata.displayGeid,
  //     "onlyActive": true
  //   }
  //   this.adminService.getUserpopuphovertDetails(obj).subscribe((res: any) => {
  //     userPopuphoverDetails= res;

// console.log(res,"Response");
//         userPopuphoverDetails.forEach((ruInfo) =>{
//         if (ruInfo.ruProcessOwnerGeids.indexOf(obj.geid) > -1) {
//           this.ruProcessOwnerResolvingUnitdata.push(ruInfo);
//         }
//         if (ruInfo.coordinatorGeids.indexOf(obj.geid) > -1) {
//           this.coordinatorResolvingUnitdata.push(ruInfo);
//         }


//     });
    // if (this.ruProcessOwnerResolvingUnitdata.length > 0) {
    //   this.iscountprocessorowner = this.ruProcessOwnerResolvingUnitdata.length;
    // }

    // if (this.coordinatorResolvingUnitdata.length > 0) {
    //   this.iscoordinaterowner = this.coordinatorResolvingUnitdata.length;
    // }
   // })

    empdata.ruProcessOwnerResolvingUnitIds.forEach(RuprocessId => {
      this.filteredResolvingUnitDetails.forEach(element => {
        if (element.id == RuprocessId) {
          this.ruProcessOwnerResolvingUnitdata.push(element);
        }
      });
    });


    empdata.coordinatorResolvingUnitIds.forEach(RucoordinatorId => {
      this.filteredResolvingUnitDetails.forEach(element => {
        if (element.id == RucoordinatorId) {
          this.coordinatorResolvingUnitdata.push(element);
        }
      });
    });

     if (this.ruProcessOwnerResolvingUnitdata.length > 0) {
      this.iscountprocessorowner = this.ruProcessOwnerResolvingUnitdata.length;
    }

    if (this.coordinatorResolvingUnitdata.length > 0) {
      this.iscoordinaterowner = this.coordinatorResolvingUnitdata.length;
    }

    // for (let i = 0; i < empdata.ruProcessOwnerResolvingUnitIds.length; i++) {
    //   if (hubData.id == empdata.ruProcessOwnerResolvingUnitIds[i]) {
    //     this.ruProcessOwnerResolvingUnitdata.push(hubData);
    //   }
    // }

    // for (let J = 0; J < empdata.coordinatorResolvingUnitIds.length; J++) {
    //   if (hubData.id == empdata.coordinatorResolvingUnitIds[J]) {
    //     this.coordinatorResolvingUnitdata.push(hubData);
    //   }
    //   this.iscoordinaterowner = this.coordinatorResolvingUnitdata.length;
    // }

  }

  myShowFunction() {
    this.hideshow = true;
  }

  showprocessowner(hubData) {
    if (hubData.showContacts == false) {
      hubData.showContacts = true;
    }
    else {
      hubData.showContacts = false;
    }
  }

  getProcessOwnerclick(id, index) {

    this.getrubsObjinfo=null;
    this.getProcesOwnerhoverdata =null;
    const find = this.employeeAllDetails.find((f) => f.index == index)
    if (find) {
      for (let i = 0; i < find.res.length; i++) {
        if (find.res[i].id == id) {
          this.getProcesOwnerhoverdata = (find.res[i]);
        }
      }
    }
  }

  clickExportToExcel() {
    let cultureName = "en-IN";
    let bojeexportexcel = {
      "division": "PG",
      "bu": this.adminForm.controls['bu'].value == "" ? null : this.adminForm.controls['bu'].value,
      "pg": this.adminForm.controls['pg'].value == "" ? null : this.adminForm.controls['pg'].value,
      "countries": [this.adminForm.controls['country'].value == "" ? null : this.adminForm.controls['country'].value],
      "employee": this.adminForm.controls['employee'].value == "" ? null : this.adminForm.controls['employee'].value,
      "city": this.adminForm.controls['city'].value == "" ? null : this.adminForm.controls['city'].value,
      // "cities": [
      //   "string"
      // ],
      "activity": this.adminForm.controls['activity'].value == "" ? null : this.adminForm.controls['activity'].value,
      "isGlobal": this.radioPOAssignedStatus == undefined ? null : this.radioPOAssignedStatus,
      "ruPersonType": this.radioRuprocessowner == undefined ? null : this.radioRuprocessowner,
      "ruPersonAssigned": this.radioRuassociation == undefined ? null : this.radioRuassociation,
      "active": this.checkisActive,
      "onlyMine": this.checkonmine,
      "withoutActivePeople": this.checkruwithoutactive,
      "currentUser": "9X70826606"
    }

    this.IsHubsExcelGenerating = true;
    this.adminService.getExportHubs(bojeexportexcel, cultureName).subscribe((response: ArrayBuffer) => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'RuProcessOwners';
      downloadLink.click();
      this.IsHubsExcelGenerating = false;
    });
  }

  updatedCards() {
    this.hubDetailsAsPerPagination=[];
    const startIndex = (this.currentPage - 1) * this.numberOfCardsPerPage;
    const endIndex = startIndex + this.numberOfCardsPerPage;
    this.hubDetailsAsPerPagination = this.updatedFilteredDetails.slice(startIndex, endIndex);
    this.getProcessOwnerData();
  }

  getProcessOwnerData() {
   //this.spinner.show();
    try {

        for (let m = 0; m < this.hubDetailsAsPerPagination.length; m++) {

          let objdata = {
            "div": this.hubDetailsAsPerPagination[m].divCode,
            "bu": this.hubDetailsAsPerPagination[m].buCode,
            "pg": this.hubDetailsAsPerPagination[m].pgCode,
            "country": this.hubDetailsAsPerPagination[m]?.address?.countryCode,
            "isGlobal": this.hubDetailsAsPerPagination[m]?.isGlobal
          }
          this.adminService.getprocessownersDetails(objdata).subscribe(async res => {
            this.filteredprocessownersDetails = [...this.filteredprocessownersDetails, { res: res, index: m }]
           // this.spinner.show();

            if (this.filteredprocessownersDetails.length == this.hubDetailsAsPerPagination.length) {
              this.filteredprocessownersDetails?.map((r) => {
                this.filteredGeId = [...this.filteredGeId, { geid: r.res.map((rer) => rer.geid), index: r.index }]
              })
              //this.spinner.show();
              await this.filteredGeId.map((value, ind) => {
                this.adminService.getEmployeeInfo(value.geid).subscribe(async res => {
                  await this.employeeData(res, value);
                 //this.spinner.hide();
                })
              })
            }
          });
          //this.spinner.hide();
        }
    }
    catch(e) {
        this.spinner.hide();
    }
    //this.spinner.hide();
  }

  previousPage() {
    if (this.hasPrevious()) {
      return this.currentPage - 1;
    }
    return 0;
  };

  hasPrevious() {
    return this.currentPage > 1;
  };

  previousClicked() {
    if (this.hasPrevious()) {
      this.currentPage--;
      this.updatedCards();
    }
  };

  gotoPage(page) {
    this.currentPage = page;
    this.updatedCards();
  }

  showFirstPageSeparator() {
    return this.currentPage > 3;
  };

  hasNext() {
    return this.currentPage < this.pagesCount;
  };

  nextPage() {
    if (this.hasNext()) {
      return this.currentPage + 1;
    }
    return 0;
  };

  showFirstPage() {
    return this.hasPrevious() && this.previousPage() > 1;
  };

  showLastPageSeparator() {
    var result = this.hasNext() && this.nextPage() < this.pagesCount - 1;
    return result;
  };

  showLastPage() {
    return this.hasNext() && this.nextPage() < this.pagesCount;
  };

  nextClicked() {
    if (this.hasNext()) {
      this.currentPage++;
      this.updatedCards();
    }
  };



}

