import { Component, OnInit } from '@angular/core';
import { ExternalpageEmitService } from '../externalpage-emit.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-customer-feedback-reject',
  templateUrl: './customer-feedback-reject.component.html',
  styleUrl: './customer-feedback-reject.component.css'
})
export class CustomerFeedbackRejectComponent implements OnInit{
  encodedURI: any;
  inputURI: any;
  customerFeedbackRejectForm: FormGroup;

  constructor(private externalPageService: ExternalpageEmitService,
    private route: ActivatedRoute,
    public router: Router,
    private spinnerService: NgxSpinnerService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.spinnerService.show();
    this.externalPageService.setExternalPageFunction(true);
    this.inputURI = this.route.snapshot.paramMap.get('id');
    const decodedURI = atob(this.inputURI);
    this.encodedURI = btoa(decodedURI);   
    this.customerFeedbackRejectForm = this.fb.group({
      customerFeedbackRejectReason: [,Validators.required],
    })
    this.spinnerService.hide(); 
  }
  submitCustomerFeedback(){
    let formatedData = {
      "culture": "en",
      "encodedParams": this.encodedURI,
      "customerFeedbackRejectReason": this.customerFeedbackRejectForm.controls['customerFeedbackRejectReason'].value
    }
    this.spinnerService.show();
    this.externalPageService.customerFeedbackReject(formatedData).subscribe(
      (data)=>{
        this.spinnerService.hide();   
        window.location.href = data?.url;     
      }
    )
  }
}
