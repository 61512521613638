<div class="ms-0 me-0 ps-0 pe-0">
  <!-- Personal Data note -->
  <div class="row" [formGroup]="issueCaptureMainForm">
    <p class="lockedMessageText">
      <span><i class="fa highImpactReviewPending">&#xf071;</i></span>
      {{'GDPR_do_not_use_personal_data' | translate}}
    </p>
    <!-- high impact class -->
    <p *ngIf="complaintData !== undefined && !complaintData?.areaComplaintCollection?.complaintInformationSection?.highImpactValidationViewModel?.isConfirm"
      class="lockedMessageText">
      <span><i class="fa highImpactReviewPending">&#xf071;</i></span>
      {{'Complaint_Awaiting_HighImpact_Validation' | translate:paramForHighImpactLabel}}
    </p>
    <!-- for deleted issues -->
    <p *ngIf="complaintData?.complaintDataAccess?.isLocked" class="lockedMessageText">
      <span><i class="fa fa-times deletedIssue"></i></span>
      {{complaintData?.complaintDataAccess?.readOnlyReason}}
    </p>
    <!-- for locked complaint -->
    <p *ngIf="this.complaintData?.sapComplaintInfo?.sapLockStatus == true && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true" class="lockedMessageText">
      <span><i class="fa-solid fa-lock deletedIssue"></i></span>
      {{this.complaintData?.sapComplaintInfo?.sapLockStatusMessage}}
    </p>
    <!-- top right details -->
    <div>
      <div class="newissue-header-section">
        <div class="buttonsclass">
          <div *ngIf="headerSection.get('issueComplaintStatus')?.value !== 'New'">
            <div>
              <button [disabled]="checkComplaintStatusForActionBoard()"
                [ngClass]="checkComplaintStatusForActionBoard()?'grayButton': ''" [pTooltip]="getActionBoardTooltip()"
                tooltipPosition="right" [escape]="false" class="widgetButton-Activity" (click)="openActionBoard()">Resolving Unit Action Board</button>
              <button class="widgetButton-Activity" (click)="openStatusUpdate()">Status Update</button>
            </div>
            <div>
              <button class="widgetButton-Activity" (click)="openPopup()">Send Message</button>
              <button class="widgetButton-Activity" (click)="moveToActionLog()">View Activity Log</button>
            </div>
          </div>
        </div>
        <div class="newissue-header-statusclass">
          <div class="paddingBottomSmall" formGroupName="headerSection">
            <div class="row mb-1">
              <div class="col-5 header-level fortabwidth2">
                <span class="tag-label mt-0"> {{'RoleForm_Label' | translate}} </span>
              </div>
              <div class="col-7 remove-ps fortabwidth3">
                {{role}}
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-5 header-level fortabwidth2">
                <span class="tag-label"> {{'my_actions_dashboard_ccrp_no' | translate}} </span>
              </div>
              <div class="col-7 remove-ps fortabwidth3">
                <input type="hidden" formControlName="issueCaptureDataPercentage">
                <input type="hidden" formControlName="deleteReason">
                <input type="hidden" formControlName="rowVersion">
                <input type="hidden" formControlName="rowVersionString">
                <input type="hidden" formControlName="createDate">
                <input type="hidden" formControlName="salesForceId">
                <input type="text" formControlName="complaintNumber" class="header-custom-width-100pc"
                  [attr.complaintNumber]="ccrpNumber" [attr.disabled]="true" />
                <span id="complaintNumber" style="display: none">{{ccrpNumber}}</span>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-5 header-level fortabwidth2">
                <span class="tag-label">{{ 'Complaint_ComplaintStatus' | translate }} </span>
                <span> {{issueCaptureMainForm.value.complaintStatusId | json}}</span>
              </div>
              <div class="col-7 remove-ps fortabwidth3">
                <input type="hidden" formControlName="complaintStatusId">
                <input type="text" formControlName="issueComplaintStatus" class="header-custom-width-100pc"
                  [attr.issueStatus]="issueComplaintStatus" />
                <span id="complaintStatus" style="display: none">{{issueComplaintStatus}}</span>
              </div>
            </div>
            <div class="row mb-1" *ngIf="complaintId">
              <div class="col-5 header-level fortabwidth2">
                <i *ngIf="complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.verificationInformation?.isVisible"
                  class="fa fa-info-circle infoTooltipComplaintHeader infotag"
                  pTooltip="{{complaintData?.areaComplaintResolution?.matrixCurrentRatingViewModel?.verificationInformation?.status}}"></i>
                <span class="tag-label">Current rating </span>
              </div>
              <div class="col-7 remove-ps fortabwidth3">
                <input readonly type="text" value="Unconfirmed" formControlName="complaintCurrentRating"
                  class="header-custom-width-100pc" />
              </div>
            </div>
            <div class="row mb-1" *ngIf="complaintId">
              <div class="col-5 header-level fortabwidth2">
                <span class="tag-label">RCA </span>
              </div>
              <div class="col-7 remove-ps fortabwidth3">
                <input type="text" [value]="complaintData?.header?.rcaRequiredFriendlyName"
                  class="header-custom-width-100pc" disabled />
              </div>
            </div>
            <div class="row mb-1" *ngIf="complaintId">
              <div class="col-5 header-level fortabwidth2">
                <span class="tag-label"> Solution verification </span>
              </div>
              <div class="col-7 remove-ps fortabwidth3">
                <input type="text" [value]="complaintData?.header?.solutionVerificationFriendlyName"
                  class="header-custom-width-100pc" disabled />
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-5 header-level fortabwidth2">
                <i class="fa fa-info-circle infoTooltipComplaintHeader infotag" *ngIf="!confidentialCheck"
                  pTooltip="This CCRP is not confidential. It means that it can be accessed by everyone. If content of this CCRP should be restricted, make sure you tick the checkbox."></i>
                <i class="fa fa-info-circle infoTooltipComplaintHeader infotag" *ngIf="confidentialCheck"
                  pTooltip="This CCRP is marked as confidential. It means that access to this CCRP will be restricted. If someone should be able to access this CCRP, make sure they have one of the following roles: Issue Owner, Substitute, Supervisor, Coordinator, Resolution Owner, Resolution Owner Team, Process Owners (Business, BU, PG, HUB, CCRP Global), Account Manager or Action Owner."></i>
                <label for="Header_IsConfidential" class="tag-label">{{'Complaint_Confidential' | translate}}</label>
              </div>
              <div class="col-7 remove-ps fortabwidth3">
                <input id="Header_IsConfidential" formControlName="isConfidential" class="widgetCheckBoxLabel"
                  (change)="onConfidentialChange($event)" type="checkbox" />
              </div>
            </div>
            <div class="row mb-1" *ngIf="complaintData?.header?.salesForceId">

              <div class="col-5 header-level fortabwidth2">
                <span>{{'Complaint_SFCaseNumber' | translate}} </span>
              </div>
              <div class="col-7 remove-ps fortabwidth3">
                <a class="sfdcCaseNo" [href]="this.complaintData?.salesForceComplaintInfo?.urlToCase"
                  target="_blank">{{this.complaintData?.salesForceComplaintInfo?.caseNumber}}</a>
              </div>
            </div>
            <!-- sap information -->
            <div class="row mb-1" *ngIf="complaintData?.header?.sapId">

              <div class="col-5 header-level fortabwidth2">
                <span>{{'Complaint_QnNumber' | translate}} </span>
              </div>
              <div class="col-7 remove-ps fortabwidth3">
                <a class="sfdcCaseNo" [href]="this.complaintData?.sapComplaintInfo?.urlToCase"
                  target="_blank">{{this.complaintData?.sapComplaintInfo?.qnNumber}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="container">
      <div class="row" style="display: flex;">
        <div>
          <button routerLink="/mydashboard" class="widgetButtoSmall  float-end ms-2">{{ "ButtonExitWithoutSaving" |
            translate }}</button>

          <button class="widgetButtonSmall2 float-end ms-2 mt-1" (click)="expandCollapseSetup()">
            {{ expandIndex.length > 0 ? ('al_colapse_all' | translate ) : ('al_expand_all' | translate) }}
          </button>
          <div *ngIf="headerSection.get('issueComplaintStatus')?.value !== 'New'">
            <p-menubar [styleClass]="'site-action-menu'" [model]="menuItems" [autoDisplay]="true">
            </p-menubar>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- toggle button -->
  <p class="mandatoryMessageText" style="padding-bottom: 0px;">
    <span><i class="mandatory">*</i></span>
    {{ 'Caption_Message_for_Mandatory_fields' | translate }}
  </p>
  <!-- accordion -->
  <p-accordion [expandIcon]="'pi pi-fw pi-chevron-down'" [collapseIcon]="'pi pi-fw pi-chevron-up'" [multiple]="true"
    [activeIndex]="expandIndex" class="w-full headerBack"
    [ngClass]="(headerSection.get('issueComplaintStatus')?.value == 'New' ? 'complaintNew' : 'complaintOther')">

    <p-accordionTab [selected]="isExpanded || issueCaptureEnable" [disabled]="disableField?.issueCapture">
      <ng-template pTemplate="header">
        <div class="flex align-items-center col-md-3 accord-colwidth mobile-hide-div">
          <i class="pi pi-search me-2 fw-bolder"></i>
          <span class="vertical-align-middle text-capitalize"> {{ 'ComplaintForm_SectionQ1DisplayName' | translate }}</span>
        </div>
        <div class="col-xs-1 mt-1 mobile-hide-div">
          <span id="boot-icon" class="pi pi-circle-fill" [ngStyle]="{'color':accordionCericlUpdate('submitted')}"
            style="font-size:18px;"></span>
        </div>

        <div class="col-md-3 ms-3 mobile-hide-div">
          <p-progressBar [value]="progressValue" class="customProgress">{{progressValue}}</p-progressBar>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-3 accordianDate">
          <div class="datelabel-text">
            <span class="fw-normal last-update"> Submitted Date:</span>
          </div>
          <div class="col-3">
            <span>{{getComplaintSectionLastDate('Issue submitted') | customdate}}</span>
          </div>
        </div>
        <div class="accordion-mobile-view">
          <div class="accord-mobilewidth">
            <i class="pi pi-search me-2 fw-bolder"></i>
            <span class="vertical-align-middle text-capitalize fw-bold">
              {{ 'ComplaintForm_SectionQ1DisplayName' | translate}}
            </span>

          </div>
             <p-progressBar [value]="progressValue" class="customProgress">{{progressValue}}</p-progressBar>
          <span class="fw-normal last-update"> Submitted Date: {{getComplaintSectionLastDate('Issue submitted') | customdate}}</span>
          <!-- <span>{{getComplaintSectionLastDate('Issue submitted') | customdate}}</span> -->
        </div>

      </ng-template>
      <ng-template pTemplate="content">
        <div>
          <ng-container #captureFormValue>
            <div class="validation-summary-errors" id="validation-summary-errors">
              <!-- <ul *ngIf="issueSubmitted">

              </ul> -->
              <ul
                *ngIf="issueSubmitted">
                <li class="error-lable" *ngIf="issueCapture.controls['PrimaryComplaintOwner'].invalid">Field Issue Owner
                  is
                  required</li>
                <li class="error-lable" *ngIf="issueCapture.controls['Customer'].invalid">Field Customer name is
                  required</li>
                <li class="error-lable" *ngIf="issueCapture.controls['CustomerContact'].invalid">Field Customer
                  contact name is required</li>
                  <li class="error-lable" *ngIf="this.issueCapture.get('customerContactNameHidden').value?.isValid==false && this.complaintData?.header?.complaintStatus==7">
                    Field Customer contact name is invalid</li>
                <!-- <li class="error-lable" *ngIf="issueCapture.controls['affectedCustomer'].invalid">Field Affected
                  customer is required</li> -->
                <li class="error-lable" *ngIf="issueCapture.controls['Subject'].invalid">Field Subject is required
                </li>
                <li class="error-lable" *ngIf="issueDescription.controls['Reason'].invalid">
                  Field Summarize the reason for the issue and what customer wants/expects (In English for issues
                  sent to other countries): is required</li>
                <li class="error-lable" *ngIf="issueDescription.controls['WhatIssueDescription'].invalid">
                  Field What is the problem? is required
                </li>
                <li class="error-lable" *ngIf="issueDescription.controls['whyIssueDescription'].invalid">
                  Field Why is this a problem? is required
                </li>
                <li class="error-lable" *ngIf="issueDescription.controls['howIssueDescription'].invalid">
                  Field How do we know we have a problem? is required
                </li>
                <li class="error-lable" *ngIf="issueDescription.controls['inWhichProcessStep'].invalid">
                  Field In which process step was the issue detected? is required
                </li>
                <li class="error-lable" *ngIf="issueDescription.controls['whereIssueDescription'].invalid">
                  Field Where did the issue occur? is required
                </li>
                <li class="error-lable" *ngIf="issueDescription.controls['whenIssueDescription'].invalid">
                  Field When does/did the problem happen? is required
                </li>
                <li class="error-lable"
                  *ngIf="issueDescription.controls['customerExpectedResolutionDescription'].invalid">
                  Field What is the impact on the customer? is required
                </li>
                <li class="error-lable" *ngIf="issueDescription.controls['deadline'].invalid">
                  Field Containment actions deadline is required</li>
                <!-- <li class="error-lable" *ngIf="this.issueDescription?.controls['deadline']?.value != null && this.issueDescription?.controls?.['deadline']?.value != '' && this.complaintDetailsService?.dateValidatorForPastDate(this.issueDescription?.controls?.['deadline'], this.today)">
                  Field Containment actions deadline date must be future date.
                </li> -->
                <li class="error-lable" *ngIf="issueDescription.controls['ContainmentActionDescription'].invalid">
                  Field What is the Containment Action expected from the customer is required</li>
                <li class="error-lable" *ngIf="issueDescription.controls['TargetResolvingDate'].invalid">
                  Field Target Resolution & Verification date is required</li>
                  <!-- <li *ngIf="this.issueDescription.controls['TargetResolvingDate'].value != null && this.issueDescription.controls['TargetResolvingDate'].value != '' && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'],this.today)">
                    Field Target Resolution & Verification date must be future date.
                  </li> -->
                  <li *ngIf="previousTargetResolutionDate">
                    Field Target Resolution & Verification date must be greater than or equal to current date
                  </li>
                  <li *ngIf="this.issueDescription.controls['deadline'].value != null && this.issueDescription.controls['deadline'].value !='' && this.issueDescription.controls['TargetResolvingDate'].value != null && this.issueDescription.controls['TargetResolvingDate'].value !='' && this.complaintDetailsService.dateValidatorForPastDate(this.issueDescription.controls?.['TargetResolvingDate'],this.issueDescription.controls['deadline'].value)">
                    Field Target Resolution & Verification date must be greater than or equal to Containment Actions Deadline date
                  </li>
                  <li class="error-lable" *ngIf="issueDescription?.controls['WorkCentreandPlant'].invalid">
                    Field Work Centre? is required</li>
                  <li class="error-lable" *ngIf="!this.checkTargetDateChange() && this.issueDescription.controls?.['dateChangeReason'].value==null && this.complaintData?.header?.complaintStatus > 2">
                    Date Change Reason field is required</li>
                <!-- <li class="error-lable" *ngIf="issueClosed.controls['ExpectedExecutionDate'].invalid">
                  Field expected execution date is required
                </li> -->
                <li class="error-lable" *ngIf="additionalSubject.controls['resolvingUnit'].invalid">
                  Field Resolving Unit is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['proposedResolutionOwner'].invalid">
                  Field Proposed resolution owner is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['Coordinators'].invalid">
                  Field Issue coordinator(s) is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['ABBProductCategory'].invalid">
                  Field Product is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['failureMode'].invalid">
                  Field Failure mode is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['DefectTypeandDefectCodeGroupType'].invalid">
                  Field Defect type/Defect code group type is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['abbProductSerialNo'].invalid">
                  Field Serial No is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['purchaseOrderNo'].invalid">
                  Field Purchase order No is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['abbProjectNumber'].invalid">
                  Field Project Number is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['abbProjectName'].invalid">
                  Field Project Name is required
                </li>
                <li class="error-lable" *ngIf="issueDescription.controls['CountryCodeWhereTheIssueHappen'].invalid">
                  Field Country where the issue happened is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['quantity'].invalid">
                  Field quantity is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['articleNumber'].invalid">
                  Field article Number is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['itemDesignation'].invalid">
                  Field Item designation is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['abbCustomerReference'].invalid">
                  Field Customer Reference is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['supplier'].invalid">
                  Field Supplier is required
                </li>
                <li class="error-lable" *ngIf="issueResolution?.controls['ProductAlreadyDelivered'].invalid">
                  Field Product Already Delivered? is required
                </li>
                <li class="error-lable" *ngIf="issueResolution.controls['NoOfUnitsShipped'].invalid">
                  Field Number of units already shipped is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['deliveryDate'].invalid">
                  Field Delivery Date is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['commissionDate'].invalid">
                  Field Commissioning Date is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['ManufacturingDate'].invalid">
                  Field Manufacturing Date is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['warrantyReference'].invalid">
                  Field Warranty Reference is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['warrantyStartDate'].invalid">
                  Field Warranty Start Date is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['warrantyEndDate'].invalid">
                  Field Warranty End Date is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['documentID'].invalid">
                  Field Other/Document ID is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['qNumberIssue'].invalid">
                  Field Q-number issuing organization is required
                </li>
                <li class="error-lable" *ngIf="additionalSubject.controls['PartiesReference'].invalid">
                  Field Parties connected with this issue is required
                </li>

                <li class="error-lable" *ngIf="issueAssignment?.controls['PrimaryResolutionOwner']?.invalid">
                  Field Resolution Owner is required
                </li>
                <li class="error-lable" *ngIf="issueAssignment?.controls['resolutionOwnerTeam']?.invalid">
                  Field Resolution Owner Team is required
                </li>
                <li class="error-lable" *ngIf="issueAssignment?.controls['quickFixComplaint']?.invalid">
                  Field Link to issue with the same RCA is required
                </li>
                <li class="error-lable"
                  *ngIf="issueAssignment?.controls['solutionWillBeValidatedByCoordinator']?.invalid">
                  Field Solution will be validated by coordinator is required
                </li>
                <li class="error-lable" *ngIf="issueAssignment?.controls['coordinatorList']?.invalid">
                  Field Coordinator list is required
                </li>
                <li class="error-lable" *ngIf="issueResolution?.controls['EstimatedCostView'].invalid">
                  Field Estimated cost in K$ is required
                </li>
                <li class="error-lable" *ngIf="issueResolution?.controls['JustificationCurrent'].invalid">
                  Field justification is required</li>
                <li class="error-lable" *ngIf="issueResolution?.controls['EightDJustificationCurrent'].invalid">
                    Field 8D Report justification is required</li>
                <li class="error-lable" *ngIf="issueResolution?.controls['ReviewRequired'].invalid">
                    Field Review Required is required</li>
                <li class="error-lable" *ngIf="issueResolution?.controls['SpecificationName'].invalid">
                  Field Specification Name is required
                </li>
                <li class="error-lable" *ngIf="issueResolution?.controls['howProductFailMeetSpecification'].invalid">
                  Field How does the product failed to meet the specification is required
                </li>
                <li class="error-lable" *ngIf="issueResolution?.controls['whatWasTheRootCauseOfThisIssue'].invalid">
                  Field What was the root cause of this issue? is required
                </li>
                <li class="error-lable" *ngIf="issueResolution?.controls['WhatContainmentActionsWhereTaken'].invalid || (issueResolution?.controls['WhatContainmentActionsWhereTaken'].validator && issueResolution?.controls['WhatContainmentActionsWhereTaken'].value=='')">
                  Field What containment action were taken? is required
                </li>

                <li class="error-lable" *ngIf="issueResolution?.controls['howDidWeResolveThisIssue'].invalid">
                  Field How did we resolve this issue? is required
                </li>
                <li class="error-lable" *ngIf="issueResolution?.controls['pgCategory'].invalid">
                  Field RCA category is required. If RCA Categories are not defined, please contact PG Process
                  Owner.
                </li>
                <li class="error-lable" *ngIf="issueResolution?.controls['COPQCategory'].invalid">
                  Field COPQ Category is required</li>
                  <li class="error-lable" *ngIf="issueResolution?.controls['DefectCauseDefectCauseCodeGroup'].invalid">
                    Field Defect cause/Defect cause code group is required</li>
                <!-- <li class="error-lable" *ngIf="issueResolution?.controls['pgCategory'].invalid">
                  Field PG RCA Category is required</li> -->
                  <li class="error-lable" *ngIf="issueResolution?.controls['UploadRCAAttachments'].invalid">
                    Field Upload 8D is required</li>

                <li class="error-lable" *ngIf="issueClosed?.controls['ExpectedExecutionDate'].invalid">
                  Field Expected Execution Date is required</li>

                <li class="error-lable" *ngIf="issueClosed?.controls['customerComments'].invalid">
                    Field Customer comments/ Reason to change Expected Execution Date is required</li>

                <li class="error-lable" *ngIf="issueClosed?.controls['CustomerFeedbackJustification'].invalid">
                  Field Customer Feedback Justification required</li>

                <li class="error-lable" *ngIf="effectivenessForm?.controls['DeadlineDate'].invalid">
                  Field expected execution date is required</li>
                <li class="error-lable" *ngIf="effectivenessForm?.controls['EffectivenessReason'].invalid">
                  Field Describe objective evidence as to why the corrective/preventive action was not effective or not
                  required. is required</li>
                <li class="error-lable" *ngIf="additionalSubject.controls['isVerifiedSerial']?.value == false && this.additionalSubject.controls['BuCode']?.value == 'PGHV' && additionalSubject.controls['abbProductSerialNo']?.value && this.complaintData?.areaComplaintCollection?.resolvingSubjectInformationSection?.abbProductSerialNo !== this.additionalSubject.controls['abbProductSerialNo'].value">
                  Field Serial no must be verified.</li>
              </ul>
            </div>
            <form [formGroup]="issueCaptureMainForm">
              <app-issue-capture formGroupName="issueCaptureForm" [isSubmitted]="issueSubmitted"
                [ActionNameForComplaints]="ActionNameForComplaints" [complaintData]="complaintData"
                [issueCaptureMainForm]="issueCaptureMainForm" [complaintValidations]="complaintValidations"
                [fieldNameForFailureMode]="fieldNameForFailureMode" (modifyContact)="modifyContact()"
                [readonlyObj]="readonlyObj"></app-issue-capture>
              <app-issue-description formGroupName="issueDescriptionForm" [isSubmitted]="issueSubmitted"
                [ActionNameForComplaints]="ActionNameForComplaints" [complaintData]="complaintData"
                [issueCaptureMainForm]="issueCaptureMainForm" [complaintValidations]="complaintValidations"
                [fieldNameForFailureMode]="fieldNameForFailureMode" (processStepIdValue)="processStepIdChange($event)"
                [role]="role" [isEnableSAPQN]="enableSAPQN" [impactData]="classValueChange" [isPreviousTargetResolutionDate]="previousTargetResolutionDate" (countryEmit)="countryCheck()"
                ></app-issue-description>
              <app-original-rating-config (classOriginalValue)="classValue($event)" formGroupName="ratingForm"
                [isSubmitted]="issueSubmitted" [ActionNameForComplaints]="ActionNameForComplaints"
                [complaintData]="complaintData" [issueCaptureMainForm]="issueCaptureMainForm"
                [complaintValidations]="complaintValidations"
                [fieldNameForFailureMode]="fieldNameForFailureMode"></app-original-rating-config>
              <!-- <input class="widgetButtonMedium" type="submit" value="Submit"> -->
              <app-additional-subject (isResolvingUnitSelectionPerfomred)="ResolvingUnitChange($event)" formGroupName="additionalSubjectForm" [isSubmitted]="issueSubmitted"
                [ActionNameForComplaints]="ActionNameForComplaints" [users]="users" [complaintData]="complaintData"
                [issueCaptureMainForm]="issueCaptureMainForm" [impactData]="classValueChange"
                [complaintValidations]="complaintValidations"
                [selectedProcessStepId]="processStepIdInput" [isEnableSAPQN]="enableSAPQN" [readonlyObj]="readonlyObj"
                [fieldNameForFailureMode]="fieldNameForFailureMode" (failureMode)="failureModeChange($event)"></app-additional-subject>
              <div class="row mb-1 text-right float-end">
                <!-- <div class="col">
                    <p-progressBar class="customtextfield-s mt-2 float-end w-50" value="{{progressValue}}"></p-progressBar>
                  </div> -->
                <div class="col text-end float-end">
                  <div class="d-flex">
                    <button type="button" [pTooltip]="saveButtonText" [escape]="false" tooltipPosition="top"
                      *ngIf="setButtonVisibility([2])" tooltipPosition="top" class="widgetButtoSmall  ms-2"
                      (click)="onDeleteDraft()" [ngClass]="!disableBtn['delete_issueCapture']?'grayButton': ''"
                      [disabled]="!disableBtn['delete_issueCapture']">{{ "ButtonDeleteDraftComplaint" | translate }}
                    </button>
                    <!-- {{!disableBtn['saveAsNotAssigned_issueCapture']}} -->
                    <!-- {{!disableBtn['saveBtn_issueCapture']}} -->
                    <button type="button" [pTooltip]="saveButtonText" [escape]="false" tooltipPosition="top"
                      *ngIf="setButtonVisibility([2,3,4,5,6,7,8,9,10,11,12,13])"
                      [disabled]="!disableBtn['saveBtn_issueCapture']"
                      tooltipPosition="top"
                      [ngClass]="!disableBtn['saveBtn_issueCapture'] || setButtonVisibility([8,13]) == true? 'widgetButtoSmall grayButton' : 'widgetButtoSmall'"
                      class="widgetButtoSmall  ms-2" (click)="onSave()">{{
                      "ButtonSave" | translate }} </button>
                    <button type="button" [pTooltip]="saveButtonText" [escape]="false" tooltipPosition="top"
                      *ngIf="setButtonVisibility([1])" tooltipPosition="top" class="widgetButtoSmall ms-2"
                      (click)="onSaveDraft()" [ngClass]="!disableBtn['saveAsDraft_issueCapture']?'grayButton': ''"
                      [disabled]="!disableBtn['saveAsDraft_issueCapture']">{{ "ButtonSaveAsDraft" | translate }}
                    </button>
                    <button type="submit" [pTooltip]="submitButtonText" [escape]="false" tooltipPosition="left"
                      [ngClass]="setButtonVisibility([3,4,5,6,7,8,9,10,11,12,13]) == true || this.role =='None' ? 'widgetButtoSmall grayButton' : 'widgetButtoSmall'"
                      tooltipPosition="left" class=" ms-2" *ngIf="setButtonVisibility([1,2,3,4,5,6,7,8,9,10,11,12,13])"
                      [disabled]="!disableBtn['submit_issueCapture'] || this.role =='None' " (click)="onSubmit($event)">{{
                      "ButtonSubmit" | translate }}</button>
                    <button type="submit" [pTooltip]="" [escape]="false" tooltipPosition="left" [ngClass]="!disableBtn['submitSapQ_issueCapture'] || this.complaintData?.sapComplaintInfo?.isQnNumberGenerated ? 'grayButton': ''"
                      [disabled]="!disableBtn['submitSapQ_issueCapture'] || this.complaintData?.sapComplaintInfo?.isQnNumberGenerated" *ngIf="(setButtonVisibility([4,5,6,7,8,9,10,11,12,13]) && enableSAPQN) || this.complaintData?.header?.sapId"
                      tooltipPosition="left" class=" ms-2 widgetButtonLarge" (click)="onSubmitSapQ($event)">{{ "SubmitToSAP" }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ng-container>
        </div>
      </ng-template>
    </p-accordionTab>


    <p-accordionTab [selected]="isExpanded" [disabled]="disableField?.assignment">
      <ng-template pTemplate="header">
        <div class="flex align-items-center col-md-3 accord-colwidth mobile-hide-div">
          <i class="pi pi-users me-2 fw-bolder"></i>
          <span class="vertical-align-middle text-capitalize"> {{ 'ComplaintForm_AreaComplaintAssignmentDisplayName' |
            translate }}</span>
        </div>
        <div class="col-xs-1 mt-1 mobile-hide-div">
          <span id="boot-icon" class="pi pi-circle-fill" [ngStyle]="{'color':accordionCericlUpdate('notAssigned')}"
            style="font-size:18px;"></span>
        </div>
        <div class=" col-md-3 ms-3 mobile-hide-div"><span class="fw-normal">Assigned to:
          <!-- {{getComplaintSectionAssignedTo('Issue assigned')}} -->
          {{this.complaintData?.areaComplaintAssignment?.caResolutionOwner?.primaryResolutionOwner?.email}}
          </span></div>
        <div class="col-md-2"></div>
        <div class="col-md-3 accordianDate">
          <div class="datelabel-text">
            <span class="fw-normal last-update"> Assigned Date:</span>
            <!-- <span class="fw-normal">Assigned to:
              {{complaintData?.areaComplaintAssignment?.caResolutionOwner?.primaryResolutionOwner?.email | lowercase }}
            </span> -->
          </div>
          <div class="col-3">
            <span>{{getComplaintSectionLastDate('Issue assigned') | customdate}}</span>
          </div>
        </div>

        <div class="accordion-mobile-view">
          <div class="accord-mobilewidth">
            <i class="pi pi-users me-2 fw-bold"></i>
            <span class="vertical-align-middle text-capitalize fw-bold">{{ 'ComplaintForm_AreaComplaintAssignmentDisplayName' |
              translate }}</span>
          </div>

          <span class="fw-normal last-update"> Assigned Date:{{getComplaintSectionLastDate('Issue assigned') | customdate}}</span>
          <span class="fw-normal">Assigned to:{{getComplaintSectionAssignedTo('Issue assigned')}}</span>
        </div>

      </ng-template>
      <ng-container #assignmentFormValue>
        <ng-template pTemplate="content">
          <form [formGroup]="issueCaptureMainForm">
            <app-issue-assign formGroupName="issueAssignmentForm" [classDetails]="enableAdditionalNominatedPerson()"
              [radioCheckBtnEnable]="enableRadioCheckBtn()" [users]="users"  [readonlyObj]="readonlyObj"
              (assignEventPassed)="assignedFromAssignment($event)" (saveEventPassed)="saveFromAssignment($event)"
              (returnToIssueOwnerEventPassed)="returnToIssueOwnerAssignment($event)"
              (VerifySerialEventPassed)="VerifySerialFunction($event)"
              [coordinators]="this.additionalSubject.controls['verificationTeam'].value"
              [issueCaptureMainForm]="issueCaptureMainForm" [complaintData]="complaintData"
              [fieldNameForFailureMode]="fieldNameForFailureMode" [ActionNameForComplaints]="ActionNameForComplaints"
              [complaintValidations]="complaintValidations"
              [isResolvingUnitModified]="isResolvingUnitSelectionRecievedToNewIssueHome"></app-issue-assign>
          </form>
        </ng-template>
      </ng-container>
    </p-accordionTab>

    <p-accordionTab [selected]="isExpanded" [disabled]="disableField?.rca">
      <ng-template pTemplate="header">
        <div class="flex align-items-center  col-md-3 mobile-hide-div">
          <i class="pi pi-user-edit me-2 fw-bolder"></i>
          <span class="vertical-align-middle text-capitalize"> {{ 'ComplaintForm_AreaComplaintResolutionDisplayName' |
            translate }} </span>
        </div>
        <div class="col-xs-1 mt-1 mobile-hide-div">
          <span id="boot-icon" class="pi pi-circle-fill" [ngStyle]="{'color':accordionCericlUpdate('inProcess')}"
            style="font-size:18px;"></span>
        </div>
        <div class=" col-md-3 ms-3 mobile-hide-div"><span class="fw-normal">Target Date:
            {{this.complaintData?.areaComplaintCollection?.complaintInformationSection?.targetResolvingDate |
            customdate}}</span></div>
        <div class="col-md-2"></div>
        <div class="col-md-3 accordianDate">
          <div class="datelabel-text">
            <span class="fw-normal last-update"> Resolved Date:</span>
          </div>
          <div class="col-3">
            <span>{{getComplaintSectionLastDate('Issue resolved by') | customdate}}</span>
          </div>
        </div>
        <div class="accordion-mobile-view">
          <div class="accord-mobilewidth">
            <i class="pi pi-user-edit me-2 fw-bolder"></i>
            <span class="vertical-align-middle text-capitalize fw-bold"> {{ 'ComplaintForm_AreaComplaintResolutionDisplayName' |
              translate }} </span>
          </div>
          <span class="fw-normal w-100">Target Date:
            {{this.complaintData?.areaComplaintCollection?.complaintInformationSection?.targetResolvingDate |
            customdate}}</span>
          <span class="fw-normal last-update w-100"> Resolved Date:
            {{getComplaintSectionLastDate('Issue resolved by') | customdate}}</span>
          <!-- <span>{{getComplaintSectionLastDate('Issue resolved by') | customdate}}</span> -->
        </div>

      </ng-template>
      <ng-template pTemplate="content" #resolvingUnitFormValue>
        <form [formGroup]="issueCaptureMainForm">
          <app-issue-resolution formGroupName="issueResolutionForm"
            (resolvedEventPassed)="resolvedFromResolution($event)"
            (containmentToResolvedPassed)="contatinmentToResolvedFromResolution($event)"
            (revertToNotAssignedEmailPassed)="revertToNotAssignedEmailPassedFromResolution($event)"
            (verificationPassed)="verificationPassedFromResolution($event)"
            (saveDataPassed)="savePassedFromResolution($event)"
            (changeJustificationReadOnly)="changeJustificationReadOnly($event)"
            (changeReviewRequiredId)="changeReviewRequiredId($event)"
            (revertToResolutionOwnerEmailPassed)="coReturnToResolutionOwner($event)"
            (rejectComplaintEventPassed)="rejectComplaint($event)"
            (VerifySerialEventFromRCAPassed)="VerifySerialFunction($event)"
            (VerifyResolutionByBuProcessOwnerEventPassed)="VerifyResolutionByBuProcessOwner($event)"
            [issueCaptureMainForm]="issueCaptureMainForm"
            [RCAandCorrectiveActionFromIssueRes]="issueDescription.controls['EightDRCAIsRequiredByCustomer']?.value"
            [ContainmentActionsRequiredFromIssueRes]="issueDescription.controls['IsContainmentActionRequired']?.value"
            [complaintData]="complaintData" [complaintValidations]="complaintValidations"
            [fieldNameForFailureMode]="fieldNameForFailureMode"
            [role]="role"
            [isEnableSAPQN]="enableSAPQN"
            [ActionNameForComplaints]="ActionNameForComplaints"></app-issue-resolution>
        </form>
      </ng-template>
    </p-accordionTab>

    <p-accordionTab [selected]="isExpanded" [disabled]="disableField?.closed">
      <ng-template pTemplate="header">
        <div class="flex align-items-center col-md-3 accord-colwidth mobile-hide-div">
          <i class="pi pi-check-square me-2 fw-bolder"></i>
          <span class="vertical-align-middle text-capitalize"> {{ 'ComplaintForm_AreaComplaintAcceptanceDisplayName' |
            translate }} </span>
        </div>
        <div class="col-xs-1 mt-1 mobile-hide-div">
          <span id="boot-icon" class="pi pi-circle-fill" [ngStyle]="{'color':accordionCericlUpdate('closed')}"
            style="font-size:18px;"></span>
        </div>
        <div class=" col-md-3 ms-3 mobile-hide-div"><span class="fw-normal"> Closed By: {{getComplaintSectionAssignedTo('Issue
            Close')}}</span></div>
        <div class="col-md-2"></div>
        <div class="col-md-4 accordianDate">
          <div
            *ngIf="(this.complaintData?.header?.complaintStatus==13) || (this.complaintData?.header?.complaintStatus==8)">
            <div class="datelabel-text">
              <span style="margin-left: -50px;" class="fw-normal last-update">Execution Pending
                Date:<span *ngIf="this.complaintData?.executionCompletionTaskDate">{{this.complaintData?.executionCompletionTaskDate| customdate}}</span>
                     <span *ngIf="!this.complaintData?.executionCompletionTaskDate">N/A</span>  
              </span>
            </div>
          </div>

          <div class="datelabel-text">
            <span style="margin-left:30px" class="fw-normal last-update"> Closed Date:{{getComplaintSectionLastDate('Issue Close') | customdate}}</span>
          </div>
        </div>
        <div class="accordion-mobile-view">

          <div class="accord-mobilewidth">
            <i class="pi pi-check-square me-2 fw-bolder"></i>
            <span class="vertical-align-middle text-capitalize fw-bold"> {{ 'ComplaintForm_AreaComplaintAcceptanceDisplayName' |
              translate }} </span>
          </div>

          <span class="fw-normal"> Closed By: {{getComplaintSectionAssignedTo('Issue
            Close')}}</span>
          <span class="fw-normal last-update"> Closed Date: {{getComplaintSectionLastDate('Issue Close') | customdate}}</span>
          <!-- <span>{{getComplaintSectionLastDate('Issue Close') | customdate}}</span> -->
          </div>

      </ng-template>
      <ng-template pTemplate="content" #closeFormValue>
        <form [formGroup]="issueCaptureMainForm">
          <app-issue-closed [classDetails]="enableCustomerContactStatus()" formGroupName="issueClosedForm"
            [issueCaptureMainForm]="issueCaptureMainForm" [complaintData]="complaintData"
            (completedEventPassed)="completedFromClosed($event)" (closeSaveEventPassed)="saveFromIssueClosed($event)"
            (completedExecutionPendingEventPassed)="completedExecutionPending($event)"
            [fieldNameForFailureMode]="fieldNameForFailureMode" [ActionNameForComplaints]="ActionNameForComplaints"
            (returnToROEventPassed)="returnToResolutionOwner($event)"
            (VerifySerialEventFromIssueClosePassed)="VerifySerialFunction($event)"></app-issue-closed>
        </form>
      </ng-template>
    </p-accordionTab>

    <p-accordionTab [selected]="isExpanded" [disabled]="disableField?.effectiveNess">
      <ng-template pTemplate="header">
        <div class="flex align-items-center col-md-3 accord-colwidth mobile-hide-div">
          <i class="pi pi-chart-line me-2 fw-bolder"></i>
          <span class="vertical-align-middle text-capitalize"> Effectiveness & Sustainability Verification</span>
        </div>
        <div class="col-xs-1 mt-1 mobile-hide-div">
          <span id="boot-icon" class="pi pi-circle-fill" [ngStyle]="{'color':accordionCericlUpdate('effective')}"
            style="font-size:18px;"></span>
        </div>
        <div class=" col-md-3 ms-3 mobile-hide-div"><span class="fw-normal"> Is Effective: {{isEffectivnessOption}}</span></div>
        <div class="col-md-2"></div>
        <div class="col-md-3 accordianDate">
          <div class="datelabel-text">
            <span class="fw-normal last-update"> Effectiveness Date:</span>
          </div>
          <div class="col-3">
            <span>{{ effectinvessDeadlineDate | customdate}}</span>
          </div>
        </div>

<div class="accordion-mobile-view">
  <div class="accord-mobilewidth">
    <i class="pi pi-chart-line me-2 fw-bolder"></i>
    <span class="vertical-align-middle text-capitalize fw-bold"> Effectiveness & Sustainability</span>
  </div>
 <span class="fw-normal"> Is Effective: {{isEffectivnessOption}} </span>
  <span class="fw-normal last-update"> Effectiveness Date: {{ effectinvessDeadlineDate | customdate}}</span>
  <!-- <span>{{ effectinvessDeadlineDate | customdate}}</span> -->
</div>


      </ng-template>
      <ng-template pTemplate="content" #effectivenessFormValue>
        <form [formGroup]="issueCaptureMainForm">
          <app-effectiveness-and-sustainability formGroupName="effectivenessForm"
            [issueCaptureMainForm]="issueCaptureMainForm" [complaintData]="complaintData"
            (confirmedEventPassed)="confirmedFromEffectiveness($event)"
            (reopenIssueEventPassed)="reopenIssueEventFromEffectiveness($event)"
            (saveEventPassed)="saveFromEffectiveness($event)"
            [complaintValidations]="complaintValidations"
            [role]="role"
            ></app-effectiveness-and-sustainability>
        </form>
      </ng-template>
    </p-accordionTab>

  </p-accordion>
  <!-- for confidential and nonconfidential popup -->
  <ng-template appPopupPlaceHolder></ng-template>

</div>
