import { Component, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { PrimeNGConfig } from "primeng/api";
import { DomSanitizer } from '@angular/platform-browser';
import { customerDetails } from 'src/app/reusables/modals/customerSelect.modal';
import { contacts } from 'src/app/reusables/modals/contact.modal';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CustomerNameSelectionPopupComponent } from '../customer-name-selection-popup/customer-name-selection-popup.component';
import { CustomerContactSelectionPopupComponent } from '../customer-contact-selection-popup/customer-contact-selection-popup.component';
import { UsersService } from 'src/app/services/users/users.service';
import { SessionStorageServiceService } from 'src/app/services/session-storage-service.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ComplaintStatuses, PermissionCode, RoleCode } from 'src/app/enum';
import { ButtonVisibilityServiceService } from 'src/app/services/button-visibility-service.service';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard.service';
import { maxLengthConstants } from 'src/app/reusables/constants/maxlength.constants';


@Component({
  selector: 'app-issue-capture',
  templateUrl: './issue-capture.component.html',
  styleUrls: ['./issue-capture.component.css'],
})
export class IssueCaptureComponent implements OnInit, OnChanges {
  ref: DynamicDialogRef;
  @Input() formGroupName!: string;
  issueCapture!: FormGroup;
  @Input() isSubmitted: boolean = false;
  // @Input() users: any[] = [];
  @Input() complaintData !: any
  @Input() issueCaptureMainForm: FormGroup;
  @Input() complaintValidations: any = [];
  @Input() ActionNameForComplaints: string = "Submit";
  @Input() fieldNameForFailureMode: string = "None";
  @Output() modifyContact = new EventEmitter();
  @Input() readonlyObj !:any;

  selectedOwner: string[] = [];
  filteredSubstitude: any[]
  filteredOwner: any;
  filteredSupervisor: any;
  selectedValue: any;
  issueOwnerText = "Issue Owner name. The name should be entered in the following format: firstname.lastname@hitachienergy.com";
  issueSubjectText = "Issue Subject";
  customerContactNameText = 'Use the "Select" button to add the person at the customer who has raised the issue and will be the contact for this issue.';
  customerNameText = 'Use the "Select" button to provide the information on Customer who is raising the issue.';
  affectedCustomerText = 'Use the "Select" button to provide the information on Customer who is referred to the issue.';
  internalText = "Select Internal if issue is between two units during the execution of an order or project and the issue will eventually affect the customer.";
  externalText = "Select External if issue is coming from or related to a specific customer.";
  supervisorText = "";
  substituteText = "You can add the name(s) of people who can act in your name in case you are or will not be available to respond to the Resolution Team. The name should be entered in the following format: firstname.lastname@hitachienergy.com";

  selectedCustomer: customerDetails;
  selectedAffectedCustomer: customerDetails;
  selectedCustomerContact: contacts;

  enumPermission = PermissionCode;
  enumRoleCode = RoleCode;
  enumComplaintStatuses = ComplaintStatuses;
  userAccessData = null
  disableSection: any;
  disableBtn: any = {};
  alreadyPatched: boolean = false;
  newComplaintAlreadyPatched: boolean = false;
  readonly maxLength = maxLengthConstants;

  constructor(
    private sanitizer: DomSanitizer,
    private rootFormGroup: FormGroupDirective,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    private usersService: UsersService,
    private authorizationService: AuthorizationService,
    private sessionStorage: SessionStorageServiceService,
    private buttonVisibilityService: ButtonVisibilityServiceService,
    private route: ActivatedRoute,
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    this.issueCapture = this.rootFormGroup.control?.get(this.formGroupName) as FormGroup;
    this.primengConfig.ripple = true;
    this.issueCapture.controls['PrimaryComplaintOwner'].valueChanges.subscribe(
      (status)=>{
       if(this.issueCapture?.controls['PrimaryComplaintOwner']?.value?.length == 0) {
         this.clearOwner();
       }
      }
    )
  }

  get f() {
    return this.issueCapture.controls;
  }

  clearCustomer(){
    this.issueCapture.patchValue({
      Customer: '',
      customerNameHidden: null,
      affectedCustomer: '',
      affectedCustomerHidden: null,
      CustomerContact: '',
      customerContactNameHidden: null
    });
    this.customerContactNameText = 'Use the "Select" button to add the person at the customer who has raised the issue and will be the contact for this issue.';
    this.customerNameText = 'Use the "Select" button to provide the information on Customer who is raising the issue.';
    this.affectedCustomerText = 'Use the "Select" button to provide the information on Customer who is referred to the issue.';
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (this.complaintValidations.length > 0) {
        this.setFieldsValidation(this.ActionNameForComplaints);
      }
      if (this.complaintData != undefined && this.alreadyPatched==false) {
        this.filteredSubstitude = [];
        this.complaintData?.areaComplaintCollection?.complaintOwner?.complaintOwnerSubstitutes?.map((coordinator) => {
          this.filteredSubstitude.push({ selectedLabel: coordinator?.displayLabel, promptLabel: coordinator?.email, tooltipInfo: coordinator?.tooltipInfo, geid: coordinator?.geid, identification: coordinator?.identification, role: coordinator?.role });
        });
        this.filteredOwner = [{ selectedLabel: this.complaintData?.areaComplaintCollection?.complaintOwner?.primaryComplaintOwner?.displayLabel, promptLabel: this.complaintData?.areaComplaintCollection?.complaintOwner?.primaryComplaintOwner?.email, tooltipInfo: this.complaintData?.areaComplaintCollection?.complaintOwner?.primaryComplaintOwner?.tooltipInfo, geid: this.complaintData?.areaComplaintCollection?.complaintOwner?.primaryComplaintOwner?.geid, identification: this.complaintData?.areaComplaintCollection?.complaintOwner?.primaryComplaintOwner?.identification, role: this.complaintData?.areaComplaintCollection?.complaintOwner?.primaryComplaintOwner?.role }];
        this.usersService.getSupervisor(this.complaintData?.areaComplaintCollection?.complaintOwner?.primaryComplaintOwner?.geid).subscribe((Response) => {
          this.filteredSupervisor = [Response?.Data];
          this.issueCapture.patchValue({
            supervisor: this.filteredSupervisor[0]
          });
          this.selectSupervisorTooltipText();
        });
        this.issueCapture.patchValue({
          PrimaryComplaintOwner: this.filteredOwner,
          substitute: this.filteredSubstitude,
          CustomerType: this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customerType == 0 ? 'External' : 'Internal',
          Subject: this.checkBlank(this.complaintData?.areaComplaintCollection?.complaintInformationSection?.subject),
          CustomerContact: this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customerContact == null ? '' : this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customerContact?.displayLabel,
          customerContactNameHidden: this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customerContact,
          Customer: this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customer == null ? '' : this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customer?.companyFullName,
          customerNameHidden: this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customer,
          affectedCustomer: this.complaintData?.areaComplaintCollection?.customerSelectionSection?.referredCustomer == null ? '' : this.complaintData?.areaComplaintCollection?.customerSelectionSection?.referredCustomer?.companyFullName,
          affectedCustomerHidden: this.complaintData?.areaComplaintCollection?.customerSelectionSection?.referredCustomer
        });

        if (this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customerType == 0) {
          if (this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customer != null) {
            this.setCustomer(this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customer);
          }
          if (this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customerContact != null) {
            this.setCustomerContact(this.complaintData?.areaComplaintCollection?.customerSelectionSection?.customerContact, false);
          }
        }
        else {
          if (this.complaintData?.areaComplaintCollection?.customerSelectionSection?.referredCustomer != null) {
            this.setCustomer(this.complaintData?.areaComplaintCollection?.customerSelectionSection?.referredCustomer);
          }
        }
        this.CustomerTypeEvent();
        this.selectSupervisorTooltipText();
        this.setCustomerSelectToolTip(this.issueCapture?.controls['customerNameHidden']?.value);
        this.alreadyPatched=true;
      }
      if (this.complaintData == undefined && this.route.snapshot.paramMap.get('id') == null && this.newComplaintAlreadyPatched==false) {
        if(this.sessionStorage.getData('userInfo')?.email != undefined){
          this.filterOwnerForPatch(this.sessionStorage.getData('userInfo')?.email);
        }
        else{
          this.dashboardService.isGetGeid.subscribe(async geid =>{
            if(geid != "")
            this.filterOwnerForPatch(this.sessionStorage.getData('userInfo')?.email);
          })
        }
        if (this.issueCapture?.controls['PrimaryComplaintOwner']?.value?.length > 0) {
          this.mapSupervisor();
          this.newComplaintAlreadyPatched = true;
        }
      }
    this.authorizationService.userAccessDetail.subscribe(detail => {
      this.userAccessData = detail;
      this.authorizationBtnDisable();
    });

    if (this.complaintValidations.length > 0) {
      this.setFieldsValidation(this.ActionNameForComplaints);
    }
  }

  setFieldsValidation(action) {
    //  console.log("if")
    for (const controlName in this.issueCaptureMainForm?.get('issueCaptureForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("else for: ", controlName);
        this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName).clearValidators();
        this.issueCaptureMainForm?.get('issueCaptureForm').get(controlName).updateValueAndValidity();
      }
    }
    for (const controlName in this.issueCaptureMainForm?.get('issueDescriptionForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName).clearValidators();
        this.issueCaptureMainForm?.get('issueDescriptionForm').get(controlName).updateValueAndValidity();
      }
    }
    for (const controlName in this.issueCaptureMainForm?.get('ratingForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('ratingForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("else for: ", controlName);
        this.issueCaptureMainForm?.get('ratingForm').get(controlName).clearValidators();
        this.issueCaptureMainForm?.get('ratingForm').get(controlName).updateValueAndValidity();
      }
    }
    for (const controlName in this.issueCaptureMainForm?.get('additionalSubjectForm')['controls']) {
      let validationData = this.complaintValidations.find(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())))
      // console.log(this.complaintValidations,validationData,controlName);
      if (validationData) {
        // console.log(">>>>",this.issueCapture);
        const control = this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName);
        const validationRules = this.complaintValidations.filter(complaintValidation => complaintValidation.property?.toLowerCase() == controlName?.toLowerCase() && complaintValidation.complaintAction == action && complaintValidation?.complaintStatusIdsArray?.includes(this.issueCaptureMainForm?.get('headerSection').get('complaintStatusId')?.value?.toString()) && (this.userAccessData == null ? this.userAccessData == null : complaintValidation?.roleIdsArray?.includes(this.userAccessData!['roleCode']?.toString())));

        control.setValidators(this.getValidators(validationRules, this.fieldNameForFailureMode));
        control.updateValueAndValidity(); // Update the validation status
      }
      else {
        // console.log("else for: ", controlName);
        this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).clearValidators();
        this.issueCaptureMainForm?.get('additionalSubjectForm').get(controlName).updateValueAndValidity();
      }
    }
  }
  private getValidators(rules: any[], fieldFailureModeValidation): any {
    let validators: Validators[] = [];
    let index = 0;
    for (const rule of rules) {
      index = index + 1;
      if (rule.depends != null) {
        // console.log("<><><><<><>",rule.property,rule.depends,this.issueCapture.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase(), this.issueDescription.controls![rule.depends]?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase() , this.issueRating.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase() , this.additionalSubject.controls![rule.depends]?.value?.toString()?.toLowerCase() , rule.value?.toLowerCase())
        if (fieldFailureModeValidation != "None" && rule.depends == "Validation" && rule.value == fieldFailureModeValidation) {
          validators.push(Validators.required);
          //  console.log("=======>>", rule.property, rule.depends, rule.value,);
          return validators;
        }
        else if ((this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueCaptureForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["issueDescriptionForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["ratingForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase()) || (this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value != undefined && this.issueCaptureMainForm.controls["additionalSubjectForm"].get(rule.depends)?.value?.toString()?.toLowerCase() == rule.value?.toLowerCase())) {
          validators.push(Validators.required);
          //   console.log("=======", rule.property, rule.depends, rule.value,);
          return validators;
        }
        else {
          //   console.log("======= blank", validators);
          if (index == rules.length) {
            return validators;
          }
        }
      }
      else {
        validators.push(Validators.required);
        return validators;
      }
    }
  }


  checkBlank(content) {
    if (content == undefined || content == null) {
      return '';
    }
    else {
      return content;
    }
  }
  authorizationBtnDisable() {
    if (this.userAccessData) {
      switch (this.userAccessData['roleCode']) {
        case this.enumRoleCode.Complaint_Owner:
          // this.disableBtn['affectedCustomer'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [214], this.userAccessData);
          // this.disableBtn['Customer'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [212], this.userAccessData);
          // this.disableBtn['CustomerContact'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2,3,4,5,7,11,12,13], [211], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Coordinator:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Global_CCRP_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Support_Desk:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Supervisor:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Team:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner_Team:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Ru_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Resource_Editor:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Div_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Bu_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Pg_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
        case this.enumRoleCode.Hub_Process_Owner:
          this.disableSection = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [7, 13], [204], this.userAccessData);
          break;
      }
      return this.disableSection
    }
  }

  mapSupervisor() {
    this.usersService.getSupervisor(this.issueCapture?.controls['PrimaryComplaintOwner'].value[0]?.geid).subscribe((Response) => {
      if (Response) {
        this.issueCapture.patchValue({
          supervisor: Response.Data
        });
        this.selectSupervisorTooltipText();
      }
    });
  }

  clearOwner() {
    this.issueCapture.patchValue({
      PrimaryComplaintOwner: [],
      supervisor: []
    })
    this.supervisorText = '';
  }
  /* Issue type event */
  CustomerTypeEvent() {
    this.ActionNameForComplaints = "Submit";
    this.setFieldsValidation(this.ActionNameForComplaints);
    /* if (this.issueCapture.controls['CustomerType'].value == 'External') {
       // this.issueCapture.get('affectedCustomer')?.clearValidators();
       //this.issueCapture.get('affectedCustomer')?.updateValueAndValidity();
       this.issueCapture.get('Customer')?.setValidators([Validators.required]);
       this.issueCapture.get('Customer')?.updateValueAndValidity();
       this.issueCapture.get('CustomerContact')?.setValidators([Validators.required]);
       this.issueCapture.get('CustomerContact')?.updateValueAndValidity();
     }
     else {
       this.issueCapture.get('Customer')?.clearValidators();
       this.issueCapture.get('Customer')?.updateValueAndValidity();
       this.issueCapture.get('CustomerContact')?.clearValidators();
       this.issueCapture.get('CustomerContact')?.updateValueAndValidity();
       // this.issueCapture.get('affectedCustomer')?.setValidators([Validators.required]);
       // this.issueCapture.get('affectedCustomer')?.updateValueAndValidity();
     }*/
  }
  /* Fiter Owner */
  filterOwner(query) {
    this.usersService.getFilteredUser(query).subscribe((res) => {
      this.filteredOwner = res;
    })
  }
  filterOwnerForPatch(query) {
    this.usersService.getFilteredUser(query).subscribe((res) => {
      this.filteredOwner = res;
      if (this.complaintData == undefined) {
        this.issueCapture?.patchValue({
          PrimaryComplaintOwner: this.filteredOwner,
        });
      }
      if (this.issueCapture?.controls['PrimaryComplaintOwner']?.value?.length > 0) {
        this.mapSupervisor();
        this.newComplaintAlreadyPatched = true;
      }
    })
  }
  /* Fiter Substitude */
  filterSubstitude(event) {
    let query = event.query;
    this.usersService.getFilteredUser(query).subscribe((res) => {
      this.filteredSubstitude = res
    })
    // this.filteredSubstitude = this.users.filter(user => user.promptLabel?.toLowerCase()?.includes(query.toLowerCase()))
  }
  /* Opens affected Customer Popup */
  showComponentAffectedCustomer() {
    this.ref = this.dialogService.open(CustomerNameSelectionPopupComponent, {
      header: "Customer Selection",
      styleClass: 'customer-name-pop',
      position: 'top'
    });
    this.ref.onClose.subscribe((selectedCustomer: customerDetails | any) => {
      if (selectedCustomer) {
        this.setAffectedCustomer(selectedCustomer);
        this.setCustomerSelectToolTip(selectedCustomer);
      }
    });
  }

  setAffectedCustomer(customerValue) {
    const affectedCustomer = {
      "companyFullName": customerValue?.companyFullName,
      "id": customerValue?.localCustomerId,
      "gisIdentifier": customerValue?.identifier,
      "identification": {
        "id": customerValue?.localCustomerId,
        "geid": customerValue?.guid,
        "email": customerValue?.accountManager,
        "cid": customerValue?.dbId,
        "giS_GUID": customerValue?.guid,
        "email_Alias": customerValue?.accountManager
      },
      "displayLabel": customerValue?.displayLabel,
      "tooltipInfo": customerValue?.tooltipInfo,
      "isValid": customerValue?.isValid,
      "newCustomerData": {
        "legalName": customerValue?.companyFullName,
        "commonName": customerValue?.companyFullName,
        "streetName": customerValue?.streetName,
        "city": customerValue?.city,
        "zipCode": customerValue?.ziP_Code,
        "stateDistrict": customerValue?.stateDistrict,
        "countryCode": '',
        "createdByGeid": customerValue?.guid,
        "leadingBusinessPartnerSegment": customerValue?.leadingBusinessPartnerSegment,
        "industryUsage": customerValue?.industryUsage,
        "channelClass": customerValue?.channelClass
      }
    }

    this.issueCapture.patchValue({
      affectedCustomer: affectedCustomer?.companyFullName,
      affectedCustomerHidden: affectedCustomer
    })

  }
  /* Opens Customer name Popup */
  showComponentCustomer() {
    this.ref = this.dialogService.open(CustomerNameSelectionPopupComponent, {
      header: "Customer Selection",
      styleClass: 'customer-name-pop',
      position: 'top'
    });
    this.ref.onClose.subscribe((selectedCustomer: customerDetails) => {
      if (selectedCustomer) {
        this.setCustomer(selectedCustomer);
      }
    });

  }
  /* Set customer value to field and in tooltip */
  setCustomer(customerValue) {
    this.selectedCustomer = customerValue;
    this.selectedAffectedCustomer = customerValue;
    const customerNameHiddenValues =
    {
      companyFullName: customerValue?.companyFullName,
      id: customerValue?.localCustomerId ? customerValue?.localCustomerId : (customerValue?.id && isNaN(customerValue?.id) ? customerValue?.id : null),
      gisIdentifier: customerValue?.identifier ? customerValue?.identifier : customerValue?.gisIdentifier,
      identification: {
        id: customerValue?.localCustomerId ? customerValue?.localCustomerId : (customerValue?.id && isNaN(customerValue?.id) ? customerValue?.id : null),
        geid: customerValue?.guid ? customerValue?.guid : customerValue?.gisIdentifier,
        email: customerValue?.accountManager,
        cid: customerValue?.customerValue?.dbId && customerValue?.customerValue?.dbId == -1 ? customerValue?.customerValue?.dbId : null,
        giS_GUID: customerValue?.identifier ? customerValue?.identifier : customerValue?.gisIdentifier,
        email_Alias: customerValue?.accountManager
      },
      displayLabel: customerValue?.displayLabel ? customerValue?.displayLabel : customerValue?.displayLabel,
      tooltipInfo: customerValue?.tooltipInfo ? customerValue?.tooltipInfo : customerValue?.displayLabel,
      isValid: customerValue?.isValid,
      newCustomerData: {
        "legalName": customerValue?.legalName,
        "commonName": customerValue?.commonName,
        "streetName": customerValue?.streetName,
        "city": customerValue?.city,
        "zipCode": customerValue?.ziP_Code,
        "stateDistrict": "",
        "countryCode": "",
        "createdByGeid": customerValue?.guid,
        "leadingBusinessPartnerSegment": customerValue?.leadingBusinessPartnerSegment,
        "industryUsage": customerValue?.industryUsage,
        "channelClass": customerValue?.channelClass
      }
    }

    this.issueCapture.patchValue({
      Customer: this.checkBlank(customerValue?.companyFullName),
      customerNameHidden: customerNameHiddenValues,
      affectedCustomer: this.checkBlank(customerValue?.companyFullName),
      affectedCustomerHidden: customerNameHiddenValues,
      CustomerContact: '',
      customerContactNameHidden: null
    });
    this.setCustomerSelectToolTip(customerValue);
  }

  /* Opens Customer Contact Details Popup */
  showComponentCustomerContact() {
    this.ref = this.dialogService.open(CustomerContactSelectionPopupComponent, {
      data: this.selectedCustomer,
      header: "Customer contact selection",
      position: 'top',
      styleClass: 'customer-contact-selection-pop'
    });
    this.ref.onClose.subscribe((selectedCustomerContact: contacts) => {
      if (selectedCustomerContact?.FirstName == 'modify') {
        this.modifyContact.emit();
      }
      else if (selectedCustomerContact) {
        this.setCustomerContact(selectedCustomerContact, true);
      }
    });
  }
  /* Set customer Contact and its tooltip */
  setCustomerContact(contactValue, check) {
    this.selectedCustomerContact = contactValue;
    const customerContactValues = {
      id: contactValue?.id,
      customerContactEmail: contactValue?.email ? contactValue?.email : contactValue?.customerContactEmail,
      subscribtion: null,
      identification: {
        id: contactValue?.rowId,
        geid: '',
        email: contactValue?.email,
        cid: contactValue?.customerContactId ? (contactValue?.customerContactId)?.toString() : null,
        giS_GUID: '',
        email_Alias: contactValue?.email
      },
      displayLabel: contactValue?.displayLabel,
      tooltipInfo: contactValue?.tooltipInfo,
      isValid: contactValue?.isValid,
      newCustomerContactData: {
        firstName: contactValue?.firstName,
        surname: contactValue?.surname,
        email: contactValue?.email,
        phone: contactValue?.phone,
      }
    }
    this.issueCapture.patchValue({

      CustomerContact: this.checkBlank(contactValue?.displayLabel ? contactValue?.displayLabel : (contactValue?.firstName + ' ' + this.checkBlank(contactValue?.lastName ? contactValue?.lastName : contactValue?.surname))),
      customerContactNameHidden: check ? customerContactValues : contactValue
    });
    this.customerContactNameText = contactValue?.tooltipInfo ? contactValue?.tooltipInfo : ("Name:" + this.checkBlank(contactValue?.firstName ? contactValue?.firstName : contactValue?.FirstName) + ' ' + this.checkBlank(contactValue?.lastName ? contactValue?.lastName : contactValue?.LastName) + "<br/>Phone: " + (contactValue?.phone ? contactValue?.phone : contactValue?.Phone) + "<br/>Email: " + (contactValue?.email ? contactValue?.email : contactValue?.Email));
  }

  /*for the tooltip */

  selectSupervisorTooltipText() {
    this.supervisorText = this.issueCapture?.controls['supervisor'].value?.TooltipInfo ? this.issueCapture?.controls['supervisor'].value?.TooltipInfo : this.issueCapture?.controls['supervisor'].value?.tooltipInfo;
  }

  substituteGotFocus(value) {
    this.substituteText = value;
  }
  /* When issue owner lost focus */
  substituteLostFocus() {
    this.substituteText = "You can add the name(s) of people who can act in your name in case you are or will not be available to respond to the Resolution Team. The name should be entered in the following format: firstname.lastname@hitachienergy.com";
  }
  ownerGotFocus(value) {
    this.issueOwnerText = value;
  }
  /* When issue owner lost focus */
  ownerLostFocus() {
    this.issueOwnerText = "Issue Owner name. The name should be entered in the following format: firstname.lastname@hitachienergy.com";
  }

  setCustomerSelectToolTip(selectedCustomer) {
    if (selectedCustomer) {
      this.customerNameText = selectedCustomer.tooltipInfo;
      this.affectedCustomerText = selectedCustomer.tooltipInfo
    }
  }

}
