import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, AfterViewInit, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

import { MessageService } from 'primeng/api';
import { ComplaintStatuses, PermissionCode, RoleCode } from 'src/app/enum';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { IssueResolutionServiceService } from 'src/app/services/issue-resolution-service.service';
import { ToasterserviceService } from 'src/app/services/toasterservice.service';

@Component({
  selector: 'app-issue-resolution-lessson-learned',
  templateUrl: './issue-resolution-lessson-learned.component.html',
  styleUrls: ['./issue-resolution-lessson-learned.component.css'],
  providers: [MessageService],
})
export class IssueResolutionLesssonLearnedComponent implements OnInit, OnChanges {

  @Input() complaintData !: any;
  @Output() lessonLearnedData = new EventEmitter();
  [x: string]: any;
  userdata: any = [];
  addEmployee: any;
  isDeleted: boolean = false;
  isEdit: boolean = false;
  isSubmitted: boolean = false;
  toast: any;
  lessonLearntList = new Array<any>();
  deleteLessonLearnts = [];
  updateLessonLearnts = [];
  isEditLessonLearnt = false;
  editLessonLearnt!: any;
  disableFields: any = {};
  disableBtn: any = {}
  issueForm: UntypedFormGroup = new UntypedFormGroup({
    learn: new UntypedFormControl(''),
    desc: new UntypedFormControl(''),
  });

  enumPermission = PermissionCode;
  enumRoleCode = RoleCode;
  enumComplaintStatuses = ComplaintStatuses;
  userAccessData = null

  constructor(
    private formb: FormBuilder,
    private learnIR: IssueResolutionServiceService,
    private messageService: MessageService,
    private toasterService: ToasterserviceService,
    private spinner: NgxSpinnerService,
    public authorizationService: AuthorizationService,

  ) {
    this.issueForm = this.formb.group({
      learn: '',
      desc: '',
      id: '',
      isActive: '',
      createDate: '',
      complaintId: ''

    });
  }

  ngOnInit(): void {
    const complaintId = this.complaintData?.header?.id ? this.complaintData?.header?.id : 0;
    this.getEmployeeListLessonLearned(complaintId);
    this.authorizationService.userAccessDetail.subscribe(async detail => {
      if (detail) {
        this.userAccessData = detail;
        this.disabledFormControls();
        this.authorizationBtnDisable();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.disabledFormControls();
    if(this.complaintData?.sapComplaintInfo?.sapLockStatus == true && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
      this.issueForm?.disable();
    }
  }

  disabledFormControls() {
    if(this.complaintData?.sapComplaintInfo?.sapLockStatus == true && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
      this.disableFields['learn'] = true;
      this.disableFields['desc'] = true;
      return;
    }
    if (this.userAccessData) {
      switch (this.userAccessData['roleCode']) {
        case this.enumRoleCode.Complaint_Owner:
          this.disableFields['learn'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [203], this.userAccessData);
          this.disableFields['desc'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Coordinator:
          this.disableFields['learn'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableFields['desc'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Global_CCRP_Process_Owner:
          this.disableFields['learn'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [203], this.userAccessData);
          this.disableFields['desc'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Support_Desk:
          this.disableFields['learn'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [203], this.userAccessData);
          this.disableFields['desc'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Supervisor:
          this.disableFields['learn'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [203], this.userAccessData);
          this.disableFields['desc'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Owner_Team:
          this.disableFields['learn'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [203], this.userAccessData);
          this.disableFields['desc'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner:
          this.disableFields['learn'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableFields['desc'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner_Team:
          this.disableFields['learn'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this.disableFields['desc'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Ru_Process_Owner:
          this.disableFields['learn'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [203], this.userAccessData);
          this.disableFields['desc'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Resource_Editor:
          this.disableFields['learn'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [203], this.userAccessData);
          this.disableFields['desc'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Div_Process_Owner:
          this.disableFields['learn'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2], [203], this.userAccessData);
          this.disableFields['desc'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Bu_Process_Owner:
          this.disableFields['learn'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [203], this.userAccessData);
          this.disableFields['desc'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Pg_Process_Owner:
          this.disableFields['learn'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [203], this.userAccessData);
          this.disableFields['desc'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1, 2], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Hub_Process_Owner:
          this.disableFields['learn'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2], [203], this.userAccessData);
          this.disableFields['desc'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2], [203], this.userAccessData);
          break;
      }
      return this.disableFields
    }
  }

  authorizationBtnDisable() {
    if(this.complaintData?.sapComplaintInfo?.sapLockStatus == true && this.complaintData?.sapComplaintInfo?.isQnNumberGenerated == true){
      this['submitLessonLearn_issueRes'] = true;
      this['btnResetLessonLearnt_issueRess'] = true;
      this['btnAddLessonLearnt_issueRes'] = true;
      this['btnUpdateLessonLearnt_issueRes'] = true;
      return;
    }
    if (this.userAccessData) {
      switch (this.userAccessData['roleCode']) {

        case this.enumRoleCode.Complaint_Owner:
          this['disableBtn']['submitLessonLearn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnResetLessonLearnt_issueRess'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnAddLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnUpdateLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Complaint_Coordinator:
          this['disableBtn']['submitLessonLearn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnResetLessonLearnt_issueRess'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnAddLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnUpdateLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);

          break;
        case this.enumRoleCode.Resolution_Owner:
          this['disableBtn']['submitLessonLearn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnResetLessonLearnt_issueRess'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnAddLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnUpdateLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Support_Desk:
          this['disableBtn']['submitLessonLearn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [203], this.userAccessData);
          this['disableBtn']['btnResetLessonLearnt_issueRess'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [203], this.userAccessData);
          this['disableBtn']['btnAddLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [203], this.userAccessData);
          this['disableBtn']['btnUpdateLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [203], this.userAccessData);


          break;
        case this.enumRoleCode.Complaint_Owner_Team:
          this['disableBtn']['submitLessonLearn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnResetLessonLearnt_issueRess'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnAddLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnUpdateLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Resolution_Owner_Team:
          this['disableBtn']['submitLessonLearn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnResetLessonLearnt_issueRess'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnAddLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnUpdateLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);

          break;
        case this.enumRoleCode.Bu_Process_Owner:
          this['disableBtn']['submitLessonLearn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnResetLessonLearnt_issueRess'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnAddLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnUpdateLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);

          break;
        case this.enumRoleCode.Pg_Process_Owner:
          this['disableBtn']['submitLessonLearn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnResetLessonLearnt_issueRess'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnAddLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnUpdateLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Hub_Process_Owner:
          this['disableBtn']['submitLessonLearn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2], [203], this.userAccessData);
          this['disableBtn']['btnResetLessonLearnt_issueRess'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2], [203], this.userAccessData);
          this['disableBtn']['btnAddLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2], [203], this.userAccessData);
          this['disableBtn']['btnUpdateLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [1,2], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Global_CCRP_Process_Owner:
          this['disableBtn']['submitLessonLearn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [203], this.userAccessData);
          this['disableBtn']['btnResetLessonLearnt_issueRess'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [203], this.userAccessData);
          this['disableBtn']['btnAddLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [203], this.userAccessData);
          this['disableBtn']['btnUpdateLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [3, 4, 5, 11, 12, 7], [203], this.userAccessData);
          break;
        case this.enumRoleCode.Div_Process_Owner:
          this['disableBtn']['submitLessonLearn_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnResetLessonLearnt_issueRess'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnAddLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          this['disableBtn']['btnUpdateLessonLearnt_issueRes'] = this.authorizationService.checkPermission([this.userAccessData['roleCode']], [4, 5, 11, 12], [203], this.userAccessData);
          break;
      }
    }
  }
  isEmpty(value) {
    return (value == null || value.length === 0 || value == 'undefined' || value == undefined || value == '');
  }
  onClearLessonLearnt() {
    this.issueForm.controls['learn'].setValue('');
    this.issueForm.controls['desc'].setValue('');
  }

  ButtonResetLessonLearnt() {
    this.onClearLessonLearnt();
    this.isEdit = false;
  }

  onDeleteLessonLearnt(id, index) {
    if (id) {
      this.deleteLessonLearnts.push(id);
    }
    this.lessonLearntList.splice(index, 1);
    const toast = this.messageService.add({
      severity: 'warn',
      summary: '',
      detail: 'Lesson learned deleted successfully',
    });
  }


  // Lesson Learnt Button Click
  ButtonAddLessonLearnt() {
    var lessonLearnt = this.issueForm.controls['learn'].value;
    var lessonLearntDescription = this.issueForm.controls['desc'].value;
    if (this.isEmpty(lessonLearnt) || this.isEmpty(lessonLearntDescription)) {
      const toast = this.messageService.add({
        severity: 'warn',
        summary: '',
        detail: 'Please enter required fields',
      });
    } else {

      this.lessonLearntList.push({
        'complaintId': this.complaintData.header?.id ? this.complaintData.header?.id : 0, /// We need to add complaint id here
        'lessonLearnt': lessonLearnt,
        'lessonLearntDescription': lessonLearntDescription
      });

      this.lessonLearnedData.emit(this.lessonLearntList);

      const toast = this.messageService.add({
        severity: 'success',
        summary: '',
        detail: 'Lesson learned added successfully',
      });
      this.isEditLessonLearnt = false;
      this.onClearLessonLearnt();
    }
  };

  ButtonUpdateLessonLearnt() {
    if (this.editLessonLearnt) {
      this.editLessonLearnt.lessonLearnt = this.issueForm.controls['learn'].value;
      this.editLessonLearnt.lessonLearntDescription = this.issueForm.controls['desc'].value;
      if (this.isEmpty(this.editLessonLearnt.lessonLearnt) || this.isEmpty(this.editLessonLearnt.lessonLearntDescription)) {
        const toast = this.messageService.add({
          severity: 'warn',
          summary: '',
          detail: 'Please enter required fields',
        });
      } else {
        this.lessonLearntList[this.editLessonLearnt.index].lessonLearnt = this.editLessonLearnt.lessonLearnt;
        this.lessonLearntList[this.editLessonLearnt.index].lessonLearntDescription = this.editLessonLearnt.lessonLearntDescription;

        if (this.editLessonLearnt.id) {
          this.updateLessonLearnts.push(this.editLessonLearnt);
        }
        const toast = this.messageService.add({
          severity: 'success',
          summary: '',
          detail: 'Lesson learned added successfully',
        });

        this.onClearLessonLearnt();
        this.isEdit = false;
      }
    }
  }

  formInit() {
    this.issueForm = new FormGroup({
      learn: new FormControl(''),
      desc: new FormControl('')
    })
  }


  getEmployeeListLessonLearned(complaintId) {
    this.learnIR.getEmployeeListLessonLearned(complaintId).subscribe({
      next: (res: any) => {
        this.lessonLearntList = res;
      },
      error: console.log,
    });
  }

  lessonLearnedReset() {
    this.issueForm.reset();
    this.formInit();
  }

  // edited data in table
  editdata(x: any, index: number) {
    // console.log(x);
    this.isEdit = true;
    this.isEditLessonLearnt = true;
    var tempUpdateLessonLearnt = this.lessonLearntList[index];
    tempUpdateLessonLearnt.index = index;
    this.editLessonLearnt = JSON.parse(JSON.stringify(tempUpdateLessonLearnt))
    this.issueForm.controls['learn'].setValue(x.lessonLearnt);
    this.issueForm.controls['desc'].setValue(x.lessonLearntDescription);
    this.issueForm.controls['id'].setValue(x.id);

  }


  onLessonLearntRequest() {
    this.spinner.show();
    let addLessonLearnts = [];
    for (let i = 0; i < this.lessonLearntList.length; i++) {
      if (this.lessonLearntList[i].id == null) {
        addLessonLearnts.push(this.lessonLearntList[i]);
      }
    }
    if (addLessonLearnts.length > 0 || this.updateLessonLearnts.length > 0 || this.deleteLessonLearnts.length > 0) {
      let request: any = {};

      request.complaintLessonLearnts = addLessonLearnts;
      request.updateComplaintLessonLearnts = this.updateLessonLearnts;
      request.ids = this.deleteLessonLearnts;
      this.learnIR.addLessonLearned(request).subscribe((resp) => {
        if (resp) {
          const toast = this.messageService.add({
            severity: 'success',
            summary: '',
            detail: resp.message,
          });
          addLessonLearnts = [];
          this.updateLessonLearnts = [];
          this.deleteLessonLearnts = [];
          this.spinner.hide();

        }
      },
        err => {
          this.toasterService.showError('Error Occured');
          this.spinner.hide();
        })
    }
    else {
      this.spinner.hide();
    }
  }
}
