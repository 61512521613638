import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { PrimeNGConfig } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AdminCustomerContactsService } from 'src/app/services/admin-customer-contacts.service';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import { StatusPipe } from 'src/app/reusables/pipes/status.pipe';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-advanced-customer-selection-popup',
  templateUrl: './advanced-customer-selection-popup.component.html',
  styleUrls: ['./advanced-customer-selection-popup.component.css']
})
export class AdvancedCustomerSelectionPopupComponent implements OnInit {

  advancedCustomerSelectionForm: FormGroup;
  reset: any;
  data: any = { emails: [], guids: [] };
  customers: any;
  country: any;
  filteredCustomers: any = [];
  companyName: any = [];
  filteredCustomer: any = [];
  receivedCustomerNames :any;
  first: number = 0;
  rows: number = 10;
  userinfo:any;
  userinfocount:number=0;

  totalRecords: any = 0;
  loading: boolean = false;
  viewClicked:boolean=false;
  noRecords:boolean=false;

  // pagenation
  currentPage = 1;
  pagesCount = 0;
  numberOfCardsPerPage = 10;
  hubDetailsAsPerPagination: any = [];
  updatedFilteredDetails: any = [];
  pagecard: boolean = false;


customer: boolean = false;

clearcustomername: boolean = false;




  constructor(private fb: FormBuilder,
    public config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private resolvingService: ResolvingUnitServiceService,
    private admContactService: AdminCustomerContactsService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.advancedCustomerSelectionForm = this.fb.group({
      customerName: ['',[Validators.required]],
      address: [''],
      country: [''],
      city: [''],
      zipcode: [''],
      state: [''],
      businessPartnerGUID: [''],
      tuid: [''],
      clear: ['']
    })
    this.userinfo = this.config.data;

    this.resolvingService.getAllCountry().subscribe((response) => {
      this.country = response;
      this.country.map((data) =>{
        data.name = '(' + data.id + ')'+' '+ '-' +' '+data.name;

      })
    });

    this.reset = function () {
      this.advancedCustomerSelectionForm.reset()
    };

    this.receivedCustomerNames= this.config.data;
    this.advancedCustomerSelectionForm.patchValue({
      clear: this.receivedCustomerNames
    })

    this.noRecords = false;
  }

  get customerName(){
    return this.advancedCustomerSelectionForm.get
    ('customerName');
  }

  // get clearname(){
  //   return this.advancedCustomerSelectionForm.get
  //   ('clear');
  // }

  chageval(event)
  {
    if(event.target.value=="")
    {
      this.customer=false;
      this.clearcustomername=false;
    }
    else
    {
      this.customer=true;
      this.clearcustomername=true;
    }

  }

  // clearnamechange(event)
  // {
  //   if(event.target.value=="" || this.advancedCustomerSelectionForm.get('clear').value!=null)
  //   {

  //     this.clearcustomername=false;
  //   }
  //   else
  //   {
  //     this.clearcustomername=true;
  //   }

  // }
  viewAdvancedCustomers() {
    this.first = 0;
    this.viewClicked=true;
    this.spinner.show();
    let advancedSearch = {
      "name": this.checkBlank(this.advancedCustomerSelectionForm.get('customerName').value),
      "addr": {
        "country": this.checkBlank(this.advancedCustomerSelectionForm.get('country').value),
        "city": this.checkBlank(this.advancedCustomerSelectionForm.get('city').value),
        "zipCode": this.checkBlank(this.advancedCustomerSelectionForm.get('zipcode').value),
        "state": this.checkBlank(this.advancedCustomerSelectionForm.get('state').value),
        "street" : this.checkBlank(this.advancedCustomerSelectionForm.get('address').value)
        // "partnerGuid":this.checkBlank(this.advancedCustomerSelectionForm.get('businessPartnerGUID').value),
      },
      "partnerGuid" : this.checkBlank(this.advancedCustomerSelectionForm.get('businessPartnerGUID').value),
      "tuid" : this.checkBlank(this.advancedCustomerSelectionForm.get('tuid').value),

    }
    this.admContactService.getAdvancedCustomers(advancedSearch).subscribe((res) => {
      this.filteredCustomers = res;
      this.updatedFilteredDetails=res;
      this.totalRecords = res.length;
      this.loading=false;
      this.noRecords=true;
      this.spinner.hide();
    })
  }
  checkBlank(content) {
    if (content == undefined || content == "") {
      return null;
    } else {
      return content;
    }
  }

  selectedIndex = -1;
  selectedRow(index: number) {
    this.selectedIndex = index;
    this.companyName = this.updatedFilteredDetails[this.selectedIndex];
    let count=0;
    if(this.userinfo!="" && this.userinfo!=null && this.userinfocount==0)
    {
      if(this.userinfocount==0)
      {
        for(let i=0;i<this.userinfo.length;i++)
        {
         this.filteredCustomer.push(this.userinfo[i]);
        }
        this.userinfocount= this.userinfocount + 1;

      }
    }


    if(this.filteredCustomer==null || this.filteredCustomer=="" )
    {
      this.filteredCustomer.push(this.companyName);
    }

    for(let i=0;i<this.filteredCustomer.length;i++)
    {
          if(this.filteredCustomer[i].id==this.companyName.id)
          {
            count=count + 1;

          }
    }
    if(count==0)
    {
      this.filteredCustomer.push(this.companyName);
    }
    this.advancedCustomerSelectionForm.patchValue({
      clear: this.filteredCustomer
    })

    // if(this.advancedCustomerSelectionForm.)

  }

  filterCustomerDetails(event) {
    if (event.query.length > 1) {
      let query = event.query;
      this.admContactService.getFilteredCustomerDetails(query).subscribe((res) => {
        this.filteredCustomer = res;
      })
    }
  };

  customerSelect(event) {
    this.advancedCustomerSelectionForm.controls['clear'].value.forEach(customer => {
      if (!this.data.guids.includes(customer?.id)) {
        this.data.guids.push(customer?.id)
      }
    })
  };

  customerGuidsUnselect(event) {
    const unselectedGuids = event.id;
    const index = this.data.guids.findIndex(item => item === unselectedGuids);
    if (index !== -1) {
      this.data.guids.splice(index, 1)
    }
  };

  clearAllSelectedCustomers() {
    this.userinfo=null;
    this.filteredCustomer=[];
    this.advancedCustomerSelectionForm.reset()
  };

  afterSelectClose() {
    this.ref.close({ selectedCustomer: this.advancedCustomerSelectionForm.controls['clear'].value });
  };

  onPageChange(event: any) {
    // this.first = event.first;
    // this.rows = event.rows;
if(this.viewClicked==true)
{
  let skipRecord = 0;
  const pageNumber = (event.first + 10) / 10;
  skipRecord += event.first;
  this.currentPage = pageNumber;
  this.updatedCards();
}
}
updatedCards()
{
 const startIndex = (this.currentPage - 1) * this.numberOfCardsPerPage;
 const endIndex = startIndex + this.numberOfCardsPerPage;
 this.filteredCustomers=[];
 this.filteredCustomers = this.updatedFilteredDetails.slice(startIndex, endIndex);

}
  cancelModal() {
    this.ref.close()
  };

}
