import { Component, OnInit } from '@angular/core';
import { ResolvingUnitServiceService } from 'src/app/services/resolving-unit-service.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { TreeNode } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { PgCategoryPopUpComponent } from '../pg-category-pop-up/pg-category-pop-up.component';
import { PgrcaCatAddPopUpComponent } from '../pgrca-cat-add-pop-up/pgrca-cat-add-pop-up.component';
import { PgrcaCatEditPopUpComponent } from '../pgrca-cat-edit-pop-up/pgrca-cat-edit-pop-up.component';
import { PgrcaCatDeactivatePopUpComponent } from '../pgrca-cat-deactivate-pop-up/pgrca-cat-deactivate-pop-up.component';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageService } from 'primeng/api';
import { PgrcaAdminServiceService } from '../pgrca-admin-service.service';
import { FailuremodeAuthService } from '../../failureMode/failuremode-auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-pgrcacategory',
  templateUrl: './pgrcacategory.component.html',
  styleUrls: ['./pgrcacategory.component.css'],
})
export class PGRCACategoryComponent implements OnInit {
  PGRCACategory!: FormGroup;
  division: any;
  bu: any;
  pg: any;
  PGRCAData: any;
  files1: any = [];
  selectedNode!: TreeNode;
  ref: DynamicDialogRef;
  pgrcaCatProcessOwner: any;
  pgrcaCatProcessOwnerGeid = [];
  data1: { geids: any };
  pgrcaCatProcessOwnerGeidData: any;
  public pgrcaCatConfig: any;
  pgrcaCatEmployeeInfo: any;
  isActive: boolean = false;
  addTooltipInfo="Add child";
  editTooltipInfo="Edit";
  deactivateTooltipInfo="Deactivate";
  desiredTooltip: string;
  allowManage: boolean = false;

  constructor(
    private fb: FormBuilder,
    // private resolvingUnitService: ResolvingUnitServiceService,
    private pgrcaAdminService: PgrcaAdminServiceService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    private config: DynamicDialogConfig,
    private breadcrumService: BreadcrumbService,
    private messageService: MessageService,
    private resolvingUnitService: ResolvingUnitServiceService,
    private failureModeAuthService: FailuremodeAuthService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.breadcrumService.menuItems = [

      { label: 'ADMIN PANEL', routerLink: 'admin' },

      { label: 'MANAGE RCA CATEGORIES' }

    ];
    this.pgrcaCatConfig = this.config.data;
    this.PGRCACategory = this.fb.group({
      business: ['PG'],
      bu: [''],
      pg: [''],
    });
    this.pgrcaAdminService.getDivision().subscribe((response) => {
      if (response) {
        setTimeout(() => {
          this.division = response;
        }, 1000);
      }
    });
    /* this.PGRCACategory.get('business').valueChanges.subscribe((res) => {
      if (res) {
        this.pgrcaAdminService.getBuList(res).subscribe((response) => {
          if (response) {
            this.bu = response.data;
          }
        });
      }
    }); */
    const division = 'PG';
    this.pgrcaAdminService.getBuList(division).subscribe((res) => {
      if (res) {
        setTimeout(() => {
          this.bu = res?.data.sort((a, b) => a.key.localeCompare(b.key));
          this.bu.map((rr: any) => {
            rr.name = rr?.value
            rr.pgName = rr?.name
          })
        }, 1000);
      }
    })
    /* this.PGRCACategory.get('bu').valueChanges.subscribe((res) => {
      if (res) {
        this.pgrcaAdminService
          .getBU(this.PGRCACategory.get('business').value, res)
          .subscribe((response) => {
            if (response) {
              this.pg = response;
            }
          });
      }
    }); */
    this.resolvingUnitService.getPgForDivisionAndBu(this.PGRCACategory.get('business').value, '').subscribe((response:any) => {
      if (response) {
        setTimeout(() => {
          this.pg = response.sort((a, b) => a.key.localeCompare(b.key));;
        }, 1000);
        // console.log(this.pg);
      }
    })

    this.PGRCACategory.get('bu').valueChanges.subscribe((res) => {
      if(res==null || res == "" ){
        this.PGRCACategory.patchValue({
          pg:''
        })
        this.resolvingUnitService.getPgForDivisionAndBu('PG', '').subscribe((response) => {
          if (response) {
            this.pg = response;
          }
        })
      }else{
        this.resolvingUnitService.getPgForDivisionAndBu(this.PGRCACategory.get('business').value, res).subscribe((response) => {
          if (response) {
            this.pg = response;
          }
        })
      }
    })

    this.PGRCACategory.get('business').valueChanges.subscribe((res) => {
      if(res==null){
        this.PGRCACategory.patchValue({
          pg:'',
          bu:''
        })
      }
    })

    this.PGRCACategory.get('pg').valueChanges.subscribe((res) => {
      if (res) {
        this.spinner.show();
        this.allowManage = this.failureModeAuthService.canManagePgCategory({pg: res});
        this.pgrcaAdminService
          .getDataForAdminPgrcaCat(res)
          .subscribe((files: any) => {
            if (files) {
              this.files1 = files;
              this.files1.map((res) => {
                (res.label = res.name),
                (res.data = res.flatName),
                // (res.expandedIcon = 'fa fa-caret-down'),
                // (res.collapsedIcon = 'fa fa-caret-right'),
                (res.children = res.children);
                (res.expanded = res.children.length > 0),
                (res.id = res.id),
                (res.leaf = res.hasChildren == true ? false : true);
              });
              if (this.files1[0]?.children.length) {
                this.files1[0].children.map((child) => {
                  (child.label = child.name),
                  (child.data = child.flatName),
                  // (child.expandedIcon = 'fa fa-caret-down'),
                  // (child.collapsedIcon = 'fa fa-caret-right'),
                  (child.children = child.children);
                  (child.expanded = child.children.length > 0),
                  (child.id = child.id),
                  (child.leaf = child.hasChildren == true ? false : true);
                });
              }
            }
            if (res) {
              const data = {
                division: this.PGRCACategory.get('business').value,
                bu: this.PGRCACategory.get('bu').value,
                pg: this.PGRCACategory.get('pg').value,
              };
              this.pgrcaAdminService
                .getDataForAdminPgrcaCatProcessOwner(data)
                .subscribe((res) => {
                  this.pgrcaCatProcessOwner = res;
                  this.pgrcaCatProcessOwnerGeidData = res.map((a) => a.geid);
                  for (
                    let i = 0;
                    i < this.pgrcaCatProcessOwnerGeidData.length;
                    i++
                  ) {
                    this.pgrcaCatProcessOwnerGeid.push(
                      this.pgrcaCatProcessOwnerGeidData[i]
                    );

                    this.data1 = {
                      geids: this.pgrcaCatProcessOwnerGeid.toString(),
                    };
                  }
                  this.pgrcaAdminService
                    .getDataForAdminPgrcaCatEmployeeInfo(this.data1)
                    .subscribe((res) => {
                      this.pgrcaCatEmployeeInfo = res;
                      this.spinner.hide();
                    });
                });
            }
          });
        }else{
          this.files1=[];
        }
      });
  }
  /* nodeSelectTooltip(event){
    console.log('event node unselect:>> ', event)
    if(event){
      this.desiredTooltip = 'show node';
    }
  } */
 /*  nodeUnselect(event){
    console.log('event node unselect 2:>> ', event)
    if(event){
      this.desiredTooltip = 'hide node zzz';
    }
  } */
   /* nodeTooltipOver(event){
     if(event.relatedTarget){
    console.log('event over:>> ', event)
     this.desiredTooltip = 'show node mouse 1';
        }
  }
  nodeTooltipOut(event){
    console.log('event out:>> ', event)
    this.desiredTooltip = 'show node mouse 2';
  }  */

  // For search box purpose
  nodeSelect(event: any) {
    this.spinner.show();
    const nodeselection = event.node;
    const pTooltip="{{ desiredTooltip }}"
    if (event.children) {
      event.expanded = true;
    }

    // const nodeselection = event.node;
    if (nodeselection.hasChildren) {
      // this.loading = true;
      // this.desiredTooltip = 'hide nodeee';
      this.pgrcaAdminService
        .getDataForAdminPgrcaCatSubChild(
          this.PGRCACategory.get('pg').value,
          nodeselection.id
        )
        .subscribe((res) => {
          if (res) {
            this.files1 = this.newH(res, this.files1, nodeselection.id);
            // this.loading = false;
            this.spinner.hide();
          }
        });
    }
  }
  newAddedHierarchy(newData: any, h, id) {
    h.forEach((node, index) => {
      if (node.id == id) {
        const New = [];
        newData.label = newData.name;
        newData.data = newData.flatName;
        // newData.expandedIcon = 'fa fa-caret-down';
        // newData.collapsedIcon = 'fa fa-caret-right';
        newData.children = newData.children;
        newData.expanded = newData.children.length > 0;
        newData.id = newData.id;
        newData.leaf = newData.hasChildren ;
        node.children.push(newData);
      } else if (node.children.length) {
        this.newAddedHierarchy(newData, node.children, id);
      }
    });
    return h;
  }
  newEditedHierarchy(newData: any, h, id) {
    h.forEach((node, index) => {
      if (node.id == id) {
        node.label = newData.name;
        node.name = newData.name;
        node.data = newData.flatName;
        // node.expandedIcon = 'fa fa-caret-down';
        // node.collapsedIcon = 'fa fa-caret-right';
        node.expanded = newData.children.length > 0;
        node.children = newData.children;
        node.id = newData.id;
        node.nodesToUpdate = newData.nodesToUpdate;
        node.state = newData.state;
        node.isActive = newData.isActive;
        node.leaf = (newData.hasChildren == true ? false : true);
        if(newData.nodesToUpdate.length > 0) {
          newData.nodesToUpdate.map((r) => {
            this.nodesUpdate(r, this.files1);
          })
        }
      } else if (node.children.length) {
        this.newEditedHierarchy(newData, node.children, id);
      }
    });
    return h;
  }

  nodesUpdate(id,h) {
    h.forEach((node, index) => {
      if (node.id == id) {
        node.isActive = true
      } else if (node.children.length) {
        this.nodesUpdate(id, node.children);
      }
    });
    return h;
  }

  newDeactivatedData(newData: any, h, id) {
    h.forEach((node, index) => {
      if (node.id == id) {
        node.label = newData.name;
        node.name = newData.name;
        node.data = newData.flatName;
        // node.expandedIcon = 'pi pi-folder-open';
        // node.collapsedIcon = 'pi pi-folder';
        node.expanded = newData.children.length > 0;
        node.id = newData.id;
        node.nodesToUpdate = newData.nodesToUpdate;
        node.state = newData.state;
        node.isActive = newData.isActive;
        node.leaf = (newData.hasChildren == true ? false : true);
        if(newData.nodesToUpdate.length > 0) {
          newData.nodesToUpdate.map((r) => {
            this.deActivateNodesUpdate(r, this.files1);
          })
        }
      } else if (node.children.length) {
        this.newDeactivatedData(newData, node.children, id);
      }
    });
    return h;
  }
  deActivateNodesUpdate(id,h) {
    h.forEach((node, index) => {
      if (node.id == id) {
        node.isActive = false;
      } else if (node.children.length) {
        this.deActivateNodesUpdate(id, node.children);
      }
    });
    return h;
  }

  newH(newData: any, h, id) {
    h.forEach((node, index) => {
      if (node.id == id) {
        const New = [];
        newData.map((res) => {
          (res.label = res.name),
            (res.data = res.flatName),
            // (res.expandedIcon = 'fa fa-caret-down'),
            // (res.collapsedIcon = 'fa fa-caret-right'),
            (res.children = res.children),
            (res.leaf = res.hasChildren == true ? false : true);
        });
        node.children = [...newData];
      } else if (node.children.length) {
        this.newH(newData, node.children, id);
      }
    });
    return h;
  }

  // Data are disabled
  enableAll() {
    this.files1.forEach((node: any) => {
      this.enableRecursive(node, false);
    });
  }

  private enableRecursive(node: any, isSelectable: boolean) {
    node.selectable = node.disabled;
    if (node.children) {
      node.children.forEach((childNode: any) => {
        this.enableRecursive(childNode, childNode.disabled);
      });
    }
  }

  /* Opens Customer name Popup */
  showPGPopUp(pgrcaCatProcessOwner, pgrcaCatEmployeeInfo) {
    if (pgrcaCatProcessOwner && pgrcaCatEmployeeInfo != '') {
      this.ref = this.dialogService.open(PgCategoryPopUpComponent, {
        header: 'Process Owners',
        styleClass: 'pgCategoryProcessOwner',
        position: 'top',
        width: '50%',
        data: {
          processOwner: this.pgrcaCatProcessOwner,
          employeeInfo: this.pgrcaCatEmployeeInfo,
        },
      });
    }
  }
  addChildPopUp(node, index) {
    this.ref = this.dialogService.open(PgrcaCatAddPopUpComponent, {
      header: 'Add child to' + ' ' + "'" + index.name + "'" + ' ' + 'category',
      styleClass: 'pgrcaCatAdd',
      position: 'top',
      width: '50%',
      data: {
        content: index,
        pgValue: this.PGRCACategory.get('pg').value,
        childData: this.files1,
        node: { node },
      },
    });
    this.ref.onClose.subscribe((receivedData: any) => {
      if (receivedData) {
        this.messageService.add({ severity: 'success', summary: 'PG RCA category has been added successfully.' });
        this.files1 = this.newAddedHierarchy(
          receivedData,
          this.files1,
          index.id
        );
      }
    });
  }

  editChildPopUp(index) {
    this.ref = this.dialogService.open(PgrcaCatEditPopUpComponent, {
      header: 'Edit category',
      styleClass: 'pgrcaCatEdit',
      position: 'top',
      width: '50%',
      data: {
        content: index,
        pgValue: this.PGRCACategory.get('pg').value,
      },
    });
    this.ref.onClose.subscribe((receivedEditedData: any) => {
      if(receivedEditedData){
        this.messageService.add({ severity: 'success', summary: 'PG RCA category node has been updated.' });
        this.files1 = this.newEditedHierarchy(
        receivedEditedData,
        this.files1,
        index.id
      );
      }
    });
  }

  deactivatePgrcaPopUp(index) {
    // const nodeselected = event.node;
    this.ref = this.dialogService.open(PgrcaCatDeactivatePopUpComponent, {
      header: 'Confirmation',
      styleClass: 'deactivateMainDiv',
      position: 'top',
      width: '50%',
      data: {
        content: index,
        pgValue: this.PGRCACategory.get('pg').value,
      },
    });

    this.ref.onClose.subscribe((receivedDeactivatedData: any) => {
      if(receivedDeactivatedData) {
        this.messageService.add({ severity: 'success', summary: 'PG RCA category node has been updated.' });
        this.files1 = this.newDeactivatedData(
          receivedDeactivatedData,
          this.files1,
          index.id
        );
      }
    });
  }
}
